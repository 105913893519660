import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../AuthContext";
import axios from "axios";
import iziToast from "izitoast";

const Profile = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "auto",
    });
  }, []);

  const [edit, setEdit] = useState(false);

  const handleEdit = () => {
    setEdit(!edit);
  };

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const { filepath, apipath } = useAuth();
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState({
    userId: "",
    name: "",
    lastName: "",
    email: "",
    phone: "",
    totalOrders: "",
    address: "",
    city: "",
    state: "",
    country: "",
    dob: "",
  });

  const userId = localStorage.getItem('userId');

  // Function to check if user is logged in
  const checkLoginStatus = () => {
    const token = localStorage.getItem('usertoken');
    setIsLoggedIn(!!token); // Set login status based on token presence
  };

  useEffect(() => {
  //   const fetchUserDetails = async () => {
  //     try {
  //       const response = await axios.get(apipath + `/users/details/${userId}`);
  //       if (response.data.user) {
  //         const user = response.data.user;
  //         if (user.dob) {
  //           // Format the dob to YYYY-MM-DD
  //           const formattedDob = new Date(user.dob).toISOString().split('T')[0];
  //           setUserDetails({
  //             ...user,
  //             dob: formattedDob,
  //           });
  //         } else {
  //           setUserDetails(user);
  //         }
  //       }
  //     } catch (error) {
  //       console.error("Error fetching user details:", error);
  //     }
  //   };
  const fetchUserDetails = async () => {
    try {
      const userResponse = await axios.get(apipath + `/users/details/${userId}`);
      if (userResponse.data.user) {
        const user = userResponse.data.user;
        if (user.dob) {
          const formattedDob = new Date(user.dob).toISOString().split('T')[0];
          setUserDetails(prevDetails => ({
            ...prevDetails,
            ...user,
            dob: formattedDob,
          }));
        } else {
          setUserDetails(prevDetails => ({
            ...prevDetails,
            ...user,
          }));
        }
      }
      
      // Fetch order details to get the total order count
      const orderResponse = await axios.get(apipath + `/order/details/user/${userId}`);
      if (orderResponse.data.success) {
        setUserDetails(prevDetails => ({
          ...prevDetails,
          totalOrders: orderResponse.data.orderCount,
        }));
      }
    } catch (error) {
      console.error("Error fetching user details or orders:", error);
    }
  };
  

    fetchUserDetails();
  }, [userId]);

  useEffect(() => {
    checkLoginStatus();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserDetails({
      ...userDetails,
      [name]: value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    // Convert dob to timestamp
    const { dob, ...otherDetails } = userDetails;
    const dobTimestamp = dob ? new Date(dob).getTime() : null;
    const userDetailsWithTimestamp = { ...otherDetails, dob: dobTimestamp };

    try {
      const response = await axios.put(
        apipath + `/users/details/${userId}`,
        userDetailsWithTimestamp,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        iziToast.success({
          title: 'Success',
          message: 'Profile updated successfully',
          position: 'topCenter',
          timeout: 2000,
        });
        navigate("/");
      } else {
        throw new Error('Failed to update profile');
      }
    } catch (error) {
      console.error('Error submitting user details:', error);
      iziToast.error({
        title: 'Error',
        message: 'Failed to update profile. Please try again later.',
        position: 'topCenter',
        timeout: 2000,
      });
    }
  };

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  return (
    <>
      <Navbar />

      <div className="container">
        <div className="col-lg-6 mx-auto image-header">
          <h1>Your Profile</h1>
        </div>
        <div className="col-lg-10 mx-auto congrats profile">
          <div className="d-flex justify-content-between align-items-center profile-header">
            <div className="profile-icon">
              <div>
                <img src={`${process.env.PUBLIC_URL}/imgs-calendar/profile.png`} alt="" />
              </div>
              <div className="mx-4">
                <h5>{userDetails.name}{" "}{userDetails.lastName}</h5>
                <p>{userDetails.email}</p>
              </div>
            </div>
            <div>
              <span onClick={handleEdit} className="hand-icon">Edit</span>
            </div>
          </div>
          <h5>Personal Information</h5>
          {edit ? (
            <div>
              <form className="row mt-3" onSubmit={handleSubmit}>
                <div className="col-lg-6">
                  <div className="profile-item">
                    <p>First Name</p>
                    <input
                      type="text"
                      placeholder="First Name"
                      className="form-control bg-transparent"
                      name="name"
                      value={userDetails.name}
                      onChange={handleInputChange}
                      maxLength="100"
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="profile-item">
                    <p>Last Name</p>
                    <input
                      type="text"
                      placeholder="Last Name"
                      className="form-control bg-transparent"
                      name="lastName"
                      value={userDetails.lastName}
                      onChange={handleInputChange}
                      maxLength="100"
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="profile-item">
                    <p>Email</p>
                    <input
                      type="email"
                      placeholder="Enter Email"
                      className="form-control bg-transparent"
                      name="email"
                      value={userDetails.email}
                      onChange={handleInputChange}
                      readOnly
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="profile-item">
                    <p>Phone</p>
                    <input
                      type="text"
                      placeholder="Phone"
                      className="form-control bg-transparent"
                      name="phone"
                      value={userDetails.phone}
                      onChange={handleInputChange}
                      readOnly
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="profile-item">
                    <p>Address</p>
                    <input
                      type="text"
                      placeholder="Address"
                      className="form-control bg-transparent"
                      name="address"
                      value={userDetails.address}
                      onChange={handleInputChange}
                      maxLength="100"
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="profile-item">
                    <p>City</p>
                    <input
                      type="text"
                      placeholder="City"
                      className="form-control bg-transparent"
                      name="city"
                      value={userDetails.city}
                      onChange={handleInputChange}
                      maxLength="100"
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="profile-item">
                    <p>State</p>
                    <input
                      type="text"
                      placeholder="State"
                      className="form-control bg-transparent"
                      name="state"
                      value={userDetails.state}
                      onChange={handleInputChange}
                      maxLength="100"
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="profile-item">
                    <p>Country</p>
                    <input
                      type="text"
                      placeholder="Country"
                      className="form-control bg-transparent"
                      name="country"
                      value={userDetails.country}
                      onChange={handleInputChange}
                      maxLength="100"
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="profile-item">
                    <p>Total Orders</p>
                    <input
                      type="text"
                      placeholder="Total Orders"
                      className="form-control bg-transparent"
                      name="totalOrders"
                      value={userDetails.totalOrders}
                      onChange={handleInputChange}
                      maxLength="100"
                      readOnly
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="profile-item">
                    <p>DOB</p>
                    <input
                      type="date"
                      placeholder="DOB"
                      className="form-control bg-transparent"
                      name="dob"
                      value={userDetails.dob}
                      onChange={handleInputChange}
                      maxLength="15"
                    />
                  </div>
                </div>
                <div className="congrats-button mt-4">
                  <button>
                    Update
                  </button>
                </div>
              </form>
            </div>
          ) : (
            <div className="row mt-3">
              <div className="col-lg-6">
                <div className="profile-item">
                  <p>First Name</p>
                  <p>{userDetails.name}</p>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="profile-item">
                  <p>Last Name</p>
                  <p>{userDetails.lastName}</p>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="profile-item">
                  <p>Email</p>
                  <p>{userDetails.email}</p>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="profile-item">
                  <p>Phone</p>
                  <p>{userDetails.phone}</p>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="profile-item">
                  <p>Address</p>
                  <p>{userDetails.address} </p>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="profile-item">
                  <p>City</p>
                  <p>{userDetails.city}</p>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="profile-item">
                  <p>State</p>
                  <p>{userDetails.state}</p>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="profile-item">
                  <p>Country</p>
                  <p>{userDetails.country}</p>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="profile-item">
                  <p>Total Orders</p>
                  <p>{userDetails.totalOrders}</p>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="profile-item">
                  <p>DOB</p>
                  <p>{userDetails.dob ? formatDate(userDetails.dob) : "N/A"}</p>
                </div>
              </div>
            </div>
          )}
        </div>
        {/* <div className="congrats-button mb-5">
          <Link to={`/orders/${userId}`}>
            <button>Check Orders</button>
          </Link>
        </div> */}
      </div>

      <Footer />
    </>
  );
};

export default Profile;
