import "./App.css";
import Landing from "./pages/Landing";
import { BrowserRouter as Router, Route, Routes, useParams } from "react-router-dom";
import Login from './loginReg/Login';
import Register from './loginReg/Register';
import Forgot from './loginReg/Forgot';
import Reset from './loginReg/Reset';
import Sms from './loginReg/Sms';
import Otp from './loginReg/Otp';
import Privacy from './pages/Privacy';
import Terms from './pages/Terms';
import Refund from './pages/Refund';
import Image from './pages/Image';
import Events from './pages/Events';
import Preview from './pages/Preview';
import Page1 from './PreviewPages/Page1';
import Page2 from './PreviewPages/Page2';
import Page3 from './PreviewPages/Page3';
import Page4 from './PreviewPages/Page4';
import Page15 from './PreviewPages/Page15';
import Page16 from './PreviewPages/Page16';
import Billing from './pages/Billing';
import BillSummary from './pages/BillSummary';
import Congrats from './pages/Congrats';
import Profile from './loginReg/Profile';
import Orders from './pages/Orders';
import ReactBigCalendar from './PreviewPages/ReactBigCalendar';
import Portrait from './PreviewPages/Portrait';
import Landscape from './PreviewPages/Landscape';
import AdminDashboard from './admin/AdminDashboard';
import CustomerDetails from './admin/CustomerDetails';
import ImageEdit from './pages/ImageEdit';
import CompleteOrder from './admin/CompleteOrder';
import PendingOrder from './admin/PendingOrder';
import BillingInformation from './admin/BillingInformation';
import CalendarDetails from './admin/CalendarDetails';
import January from './calendarPage/january';
import February from './calendarPage/february';
import March from './calendarPage/march';
import April from './calendarPage/april';
import May from './calendarPage/may';
import June from './calendarPage/june';
import July from './calendarPage/july';
import August from './calendarPage/august';
import September from './calendarPage/september';
import October from './calendarPage/october';
import November from './calendarPage/november';
import December from './calendarPage/december';
import OrderTrack from './pages/OrderTrack';
import PrintableCalendar from './PreviewPages/PrintableCalendar';
import PaymentPage from './pages/PaymentPage';
import GoogleCalendar from './pages/GoogleCalendar';
import ShoppingPolicy from './pages/ShoppingPolicy';
import FaqsDetails from './admin/FaqsDetails';
import FAQsAdd from './admin/FaqsAdd';
import FAQsEdit from './admin/FaqsEdit';
import CalendarPrice from './pages/CalendarPrice';
import RegOtpVerify from './loginReg/RegOtpVerify';
import ShippingAndBilling from './pages/ShippingAndBilling';
import PortraitLandscape from './PreviewPages/PortraitLandscape';
import CalendarPortrait from './PreviewPages/CalendarPortrait';
import CalendarLandscape from './PreviewPages/CalendarLandscape';
import ShippingDetails from './admin/ShippingDetails';
import Test from './PreviewPages/Test';
import AdminPrintCalendar from './PreviewPages/AdminPrintCalendar';
import MonthPage from './PreviewPages/MonthPage';
import CalendarViewPrint from './PreviewPages/CalendarViewPrint';
import PrintManagementDetails from './admin/PrintManagementDetails';
import PrintManagementAdd from './admin/PrintManagementAdd';
import RegVerification from './loginReg/RegVerification';
import PrintManagementEdit from './admin/PrintManagementEdit';
import CorporateDetails from './admin/CorporateDetails';
import CorporateAdd from './admin/CorporateAdd';
import CorporateEdit from './admin/CorporateEdit';
import CalendarTypes from './pages/CalendarTypes';
import RedeemCoupon from './pages/RedeemCoupon';
import CouponCalendarImage from './pages/CouponCalendarImage';
import CouponCalendarDate from './pages/CouponCalendarDate';
import CouponCalendarMonths from './pages/CouponCalendarMonths';
import CouponCalendarNotes1 from './pages/CouponCalendarNotes1';
import CouponCalendarNotes2 from './pages/CouponCalendarNotes2';
import CouponCongrats from './pages/CouponCongrats';
import CouponCalendarViewPrint from './PreviewPages/CouponCalendarViewPrint';
import CouponImageEdit from './pages/CouponImageEdit';
import SubAdminDashboard from './subAdmin/SubAdminDashboard';
import CompleteCalendarPrint from './subAdmin/CompleteCalendarPrint';
import CorporateCouponCode from './subAdmin/CorporateCouponCode';
import CouponViewDownload from "./subAdmin/CouponViewDownload";
import AdminCorporatePrintCalendar from "./PreviewPages/AdminCorporatePrintCalendar";
import CorporateShippingAndBilling from "./pages/CorporateShippingAndBilling";
import CorporatePreview2 from "./pages/CorporatePreview2";
import CorporatePreview1 from "./pages/CorporatePreview1";
import CorporateBillSummary from "./pages/CorporateBillSummary";


function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route exact path="/" element={<Landing />} />
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/register" element={<Register />} />
          <Route exact path="/forgot" element={<Forgot />} />
          <Route exact path="/reset" element={<Reset />} />
          <Route exact path="/otp" element={<Sms />} />
          <Route exact path="/otp-verifys" element={<Otp />} />
          <Route exact path="/otp-verify" element={<RegOtpVerify />} />
          <Route exact path="/privacy" element={<Privacy />} />
          <Route exact path="/terms" element={<Terms />} />
          <Route exact path="/refund" element={<Refund />} />
          <Route exact path="/image-upload" element={<Image />} />
          <Route exact path="/events/:calendarId" element={<Events />} />
          <Route exact path="/preview/:calendarId" element={<Preview />} />
          <Route exact path="/image-edit/:calendarId" element={<ImageEdit />} />
          <Route
            exact
            path="/billing/:calendarId/:billingId"
            element={<Billing />}
          />
          <Route
            exact
            path="/shipping-billing/:calendarId"
            element={<ShippingAndBilling />}
          />
          {/* <Route exact path="/bill-summary/:calendarId/:orderId" element={<BillSummary />} /> */}
          <Route
            path="/bill-summary/:calendarId/:orderId"
            element={<BillSummary />}
          />
          <Route exact path="/congrats/:calendarId" element={<Congrats />} />
          <Route exact path="/profile/:userId" element={<Profile />} />
          <Route exact path="/orders/:userId" element={<Orders />} />
          <Route exact path="/order-track/:orderId" element={<OrderTrack />} />
          <Route exact path="/shoppingPolicy" element={<ShoppingPolicy />} />
          <Route exact path="/calendarPrice" element={<CalendarPrice />} />
          <Route exact path="/faqsDetails" element={<FaqsDetails />} />
          <Route exact path="/fAQsAdd" element={<FAQsAdd />} />
          <Route exact path="/fAQsEdit/:faqsId" element={<FAQsEdit />} />

          <Route
            path="/:monthName/:calendarId/:shippingId"
            element={<MonthPage />}
          />

          <Route exact path="/preview1" element={<Page1 />} />
          <Route exact path="/preview2" element={<Page2 />} />
          <Route exact path="/preview3" element={<Page3 />} />
          <Route exact path="/preview4" element={<Page4 />} />
          <Route exact path="/preview15" element={<Page15 />} />
          <Route exact path="/preview16" element={<Page16 />} />
          <Route exact path="/calendar" element={<ReactBigCalendar />} />
          <Route exact path="/portrait/:calendarId" element={<Portrait />} />
          <Route exact path="/landscape/:calendarId" element={<Landscape />} />
          <Route
            exact
            path="/printableCalendar/:calendarId"
            element={<PrintableCalendar />}
          />
          <Route
            exact
            path="/adminPrintCalendar/:calendarId"
            element={<AdminPrintCalendar />}
          />
          <Route
            exact
            path="/calendarPortrait"
            element={<CalendarPortrait />}
          />
          <Route
            exact
            path="/calendarLandscape"
            element={<CalendarLandscape />}
          />
          <Route exact path="/shippingDetails" element={<ShippingDetails />} />
          <Route
            exact
            path="/print-management"
            element={<PrintManagementDetails />}
          />
          <Route
            exact
            path="/add-print-admin"
            element={<PrintManagementAdd />}
          />
          <Route
            exact
            path="/edit-print-admin/:subAdminId"
            element={<PrintManagementEdit />}
          />
          <Route exact path="/reg-verification" element={<RegVerification />} />

          {/* <Route exact path="/january/:calendarId/:billingId" element={<January />} />
          <Route exact path="/february/:calendarId/:billingId" element={<February />} />
          <Route exact path="/march/:calendarId/:billingId" element={<March />} />
          <Route exact path="/april/:calendarId/:billingId" element={<April />} />
          <Route exact path="/may/:calendarId/:billingId" element={<May />} />
          <Route exact path="/june/:calendarId/:billingId" element={<June />} />
          <Route exact path="/july/:calendarId/:billingId" element={<July />} />
          <Route exact path="/august/:calendarId/:billingId" element={<August />} />
          <Route exact path="/september/:calendarId/:billingId" element={<September />} />
          <Route exact path="/october/:calendarId/:billingId" element={<October />} />
          <Route exact path="/november/:calendarId/:billingId" element={<November />} />
          <Route exact path="/december/:calendarId/:billingId" element={<December />} /> */}
          {/* {renderRoutes()} */}

          <Route
            exact
            path="/preview1/:calendarId/:shippingId"
            element={<Page1 />}
          />
          <Route
            exact
            path="/preview2/:calendarId/:shippingId"
            element={<Page2 />}
          />
          <Route exact path="/preview3/:calendarId" element={<Page3 />} />
          <Route exact path="/preview4/:calendarId" element={<Page4 />} />
          <Route
            exact
            path="/preview15/:calendarId/:shippingId"
            element={<Page15 />}
          />
          <Route
            exact
            path="/calendar-view-print/:calendarId/:shippingId"
            element={<CalendarViewPrint />}
          />
          <Route
            exact
            path="/preview16/:calendarId/:shippingId"
            element={<Page16 />}
          />

          <Route exact path="/adminDashboard" element={<AdminDashboard />} />
          <Route exact path="/customerDetails" element={<CustomerDetails />} />
          <Route exact path="/completeOrder" element={<CompleteOrder />} />
          <Route exact path="/pendingOrder" element={<PendingOrder />} />
          <Route
            exact
            path="/billingInformation"
            element={<BillingInformation />}
          />
          <Route exact path="/calendarDetails" element={<CalendarDetails />} />
          <Route exact path="/paymentPage" element={<PaymentPage />} />
          <Route exact path="/googleCalendar" element={<GoogleCalendar />} />
          <Route
            exact
            path="/portraitLandscape/:calendarId"
            element={<PortraitLandscape />}
          />
          <Route exact path="/corporate" element={<CorporateDetails />} />
          <Route exact path="/add-corporate" element={<CorporateAdd />} />
          <Route
            exact
            path="/edit-corporate/:corporateId"
            element={<CorporateEdit />}
          />
          <Route exact path="/test" element={<Test />} />

          {/* New Feature */}

          <Route exact path="/calendar-types" element={<CalendarTypes />} />
          <Route exact path="/redeem-coupon" element={<RedeemCoupon />} />
          <Route exact path="/coupon-Image-upload" element={<CouponCalendarImage />} />
          <Route exact path="/coupon-calendar-date/:calendarId" element={<CouponCalendarDate />} />
          <Route exact path="/coupon-calendar/:monthName/:calendarId/:shippingId" element={<CouponCalendarMonths />} />
          <Route exact path="/coupon-calendar-notes1/:calendarId/:shippingId" element={<CouponCalendarNotes1 />} />
          <Route exact path="/coupon-calendar-notes2/:calendarId/:shippingId" element={<CouponCalendarNotes2 />} />
          <Route exact path="/coupon-calendar-view-download/:calendarId/:shippingId" element={<CouponCalendarViewPrint />} />
          <Route exact path="/coupon-calendar-image-edit/:calendarId" element={<CouponImageEdit />} />
          <Route exact path="/coupon-congrats/:calendarId" element={<CouponCongrats />} />

          {/* New features sub-admin */}
          <Route exact path="/sub-admin-dashboard" element={<SubAdminDashboard />} />
          <Route exact path="/complete-calendar-print" element={<CompleteCalendarPrint />} />
          <Route exact path="/corporate-coupon" element={<CorporateCouponCode />} />
          <Route exact path="/coupon-view-download/:corporateId/:index" element={<CouponViewDownload />} />
          <Route exact path="/corporate-calendar-view-download/:calendarId" element={<AdminCorporatePrintCalendar />} />
          <Route exact path="/corporate-calendar-billing-shipping/:calendarId" element={<CorporateShippingAndBilling />} />
          <Route exact path="/corporate-calendar-preview1/:calendarId/:shippingId" element={<CorporatePreview1 />} />
          <Route exact path="/corporate-calendar-preview2/:calendarId/:shippingId" element={<CorporatePreview2 />} />
          <Route exact path="/corporate-bill-pay-summary/:calendarId/:orderId" element={<CorporateBillSummary />} />

        </Routes>
      </Router>
    </div>
  );
}

export default App;
