// // import React, { useState, useCallback } from 'react';
// // import { useDropzone } from 'react-dropzone';

// // const FileUpload = () => {
// //   const [selectedFile, setSelectedFile] = useState(null);
// //   const [preview, setPreview] = useState(null);

// //   const onDrop = useCallback((acceptedFiles) => {
// //     const file = acceptedFiles[0];
// //     setSelectedFile(file);
// //     setPreview(URL.createObjectURL(file));
// //   }, []);

// //   const { getRootProps, getInputProps } = useDropzone({ onDrop });

// //   const handleUpload = async () => {
// //     if (!selectedFile) {
// //       alert("Please select a file first!");
// //       return;
// //     }

// //     // Here you can add your upload logic
// //     // Example:
// //     // const formData = new FormData();
// //     // formData.append('file', selectedFile);
// //     // await fetch('/upload', {
// //     //   method: 'POST',
// //     //   body: formData,
// //     // });

// //     alert("File uploaded successfully!");
// //   };

// //   return (
// //     <div className='upload'>
// //       <div
// //         {...getRootProps()}
// //         style={{
// //         //   border: '2px dashed #cccccc',
// //         //   borderRadius: '4px',
// //         //   padding: '20px',
// //         //   textAlign: 'center',
// //         //   cursor: 'pointer',
// //         //   marginTop: '20px'
// //         border: '2px dashed #cccccc',
// //           borderRadius: '4px',
// //           padding: '20px',
// //           textAlign: 'center',
// //           cursor: 'pointer',
// //           marginTop: '20px',
// //           position: 'relative', 
// //           display: 'flex',
// //           flexDirection: 'column',
// //           alignItems: 'center',
// //           justifyContent: 'center',
// //           height: '150px',
// //           backgroundColor: '#fafafa',
// //         }}
// //       >
// //         <input {...getInputProps()} />
// //         {/* Drag and drop a file here, or click to select a file */}
// //         {preview ? (
// //           <img src={preview} alt="Preview" style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }} />
// //         ) : (
// //           <p>Drag and drop a file here, or click to select a file</p>
// //         )}
// //       </div>
// //       {/* {preview && (
// //         <div style={{ marginTop: '20px' }}>
// //           <img src={preview} alt="Preview" style={{ width: '100%', maxWidth: '300px' }} />
// //         </div>
// //       )} */}

// //       <div className='upload-button'>
// //       <button onClick={handleUpload}>
// //         Upload
// //       </button>
// //       </div>
// //     </div>
// //   );
// // };

// // export default FileUpload;


// import React, { useState, useCallback } from 'react';
// import { useDropzone } from 'react-dropzone';

// const FileUpload = ({ onFileSelect }) => {
//   const [selectedFile, setSelectedFile] = useState(null);
//   const [preview, setPreview] = useState(null);

//   const onDrop = useCallback((acceptedFiles) => {
//     const file = acceptedFiles[0];
//     setSelectedFile(file);
//     setPreview(URL.createObjectURL(file));
//     onFileSelect(file);
//   }, [onFileSelect]);

//   const { getRootProps, getInputProps } = useDropzone({ onDrop });

//   return (
//     <div className='upload'>
//       <div
//         {...getRootProps()}
//         style={{
//           border: '2px dashed #cccccc',
//           borderRadius: '4px',
//           padding: '20px',
//           textAlign: 'center',
//           cursor: 'pointer',
//           marginTop: '20px',
//           position: 'relative', 
//           display: 'flex',
//           flexDirection: 'column',
//           alignItems: 'center',
//           justifyContent: 'center',
//           height: '150px',
//           backgroundColor: '#fafafa',
//         }}
//       >
//         <input {...getInputProps()} />
//         {preview ? (
//           <img src={preview} alt="Preview" style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }} />
//         ) : (
//           <p>Drag and drop a file here, or click to select a file</p>
//         )}
//       </div>
//     </div>
//   );
// };

// export default FileUpload;

import React, { useState, useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';

const FileUpload = ({ onFileSelect, currentImage }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [preview, setPreview] = useState(currentImage);

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    setSelectedFile(file);
    const filePreview = URL.createObjectURL(file);
    setPreview(filePreview);
    onFileSelect(file);
  }, [onFileSelect]);

  useEffect(() => {
    setPreview(currentImage);
  }, [currentImage]);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <div className='upload'>
      <div
        {...getRootProps()}
        style={{
          border: '2px dashed #cccccc',
          borderRadius: '4px',
          padding: '20px',
          textAlign: 'center',
          cursor: 'pointer',
          margin: '20px 0',
          position: 'relative', 
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '150px',
          backgroundColor: '#fafafa',
        }}
      >
        <input {...getInputProps()} />
        {preview ? (
          <img src={preview} alt="Preview" style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }} />
        ) : (
          <p>Drag and drop a file here, or click to select a file</p>
        )}
      </div>
    </div>
  );
};

export default FileUpload;

