// // import React, { useState } from "react";
// // import axios from "axios";
// // import iziToast from "izitoast";
// // import { useAuth } from "../AuthContext";

// // const PaymentPage = () => {
// //   const { apipath } = useAuth();
// //   const [formData, setFormData] = useState({
// //     name: "shubham",
// //     number: "9216722442",
// //     MID: 'MID' + Date.now(),
// //     transactionId: 'T' + Date.now(),
// //     amount: 1  // Amount in INR
// //   });

// //   const handleChange = (e) => {
// //     const { name, value } = e.target;
// //     setFormData({
// //       ...formData,
// //       [name]: value,
// //     });
// //   };

// //   const handlePayment = async (e) => {
// //     e.preventDefault();
// //     try {
// //       const response = await axios.post(apipath + '/payment/order', formData);
// //       if (response.data.success) {
// //         window.location.href = response.data.instrumentResponse.redirectInfo.url;
// //       } else {
// //         iziToast.error({
// //           message: 'Payment initiation failed',
// //           position: 'topCenter',
// //         });
// //       }
// //     } catch (error) {
// //       console.error('Error initiating payment:', error);
// //       iziToast.error({
// //         message: 'An error occurred during payment initiation',
// //         position: 'topCenter',
// //       });
// //     }
// //   };

// //   return (
// //     <div className="container">
// //       <h1>Payment</h1>
// //       <form onSubmit={handlePayment}>
// //         <div className="form-group">
// //           <label htmlFor="name">Name</label>
// //           <input
// //             type="text"
// //             id="name"
// //             name="name"
// //             value={formData.name}
// //             onChange={handleChange}
// //             required
// //           />
// //         </div>
// //         <div className="form-group">
// //           <label htmlFor="number">Mobile Number</label>
// //           <input
// //             type="text"
// //             id="number"
// //             name="number"
// //             value={formData.number}
// //             onChange={handleChange}
// //             required
// //           />
// //         </div>
// //         <div className="form-group">
// //           <label htmlFor="MUID">User ID</label>
// //           <input
// //             type="text"
// //             id="MUID"
// //             name="MUID"
// //             value={formData.MUID}
// //             onChange={handleChange}
// //             required
// //           />
// //         </div>
// //         <div className="form-group">
// //           <label htmlFor="transactionId">Transaction ID</label>
// //           <input
// //             type="text"
// //             id="transactionId"
// //             name="transactionId"
// //             value={formData.transactionId}
// //             onChange={handleChange}
// //             required
// //           />
// //         </div>
// //         <div className="form-group">
// //           <label htmlFor="amount">Amount (INR)</label>
// //           <input
// //             type="number"
// //             id="amount"
// //             name="amount"
// //             value={formData.amount}
// //             onChange={handleChange}
// //             required
// //           />
// //         </div>
// //         <button type="submit">Pay</button>
// //       </form>
// //     </div>
// //   );
// // };

// // export default PaymentPage;


import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'
import iziToast from "izitoast";
import { useAuth } from "../AuthContext";

const PaymentPage = () => {
  const { apipath } = useAuth();
      const [formData, setFormData] = useState({
          transactionId: 'T' + Date.now(),
          MUID: 'M22WEJPPWNL1G',
          name:"shubham",
          amount:1,
          number:'9945372099',
      });
  
      const handleChange = (e) => {
          const { name, value } = e.target;
          setFormData({ ...formData, [name]: value });
      };
  
      const handleSubmit = async (e) => {
          e.preventDefault();
  
          try {
              const response = await axios.post(apipath + '/payment/order', formData);
              if (response.data.success) {
              } else {
                  alert('Payment initiation failed');
              }
          } catch (error) {
              console.error('Error initiating payment:', error);
              alert('Error initiating payment');
          }
      };
  
      return (
          <form onSubmit={handleSubmit}>
              <div>
                  <label>Transaction ID:</label>
                  <input type="text" name="transactionId" value={formData.transactionId} onChange={handleChange} required />
              </div>
              <div>
                  <label>MUID:</label>
                  <input type="text" name="MUID" value={formData.MUID} onChange={handleChange} required />
              </div>
              <div>
                  <label>Name:</label>
                  <input type="text" name="name" value={formData.name} onChange={handleChange} required />
              </div>
              <div>
                  <label>Amount (in INR):</label>
                  <input type="number" name="amount" value={formData.amount} onChange={handleChange} required />
              </div>
              <div>
                  <label>Mobile Number:</label>
                  <input type="text" name="number" value={formData.number} onChange={handleChange} required />
              </div>
              <button type="submit">Pay Now</button>
          </form>
      );
  };
  
  export default PaymentPage;
  

// import React, { useState } from 'react';
// import axios from 'axios';
// import { useNavigate } from 'react-router-dom'
// import iziToast from "izitoast";
// import { useAuth } from "../AuthContext";

// const PaymentPage = () => {
//   const [amount, setAmount] = useState('');
//   const [userId, setUserId] = useState('');
//   const { apipath } = useAuth();

//   const handlePayment = async (e) => {
//     e.preventDefault();

//     try {
//     //   const response = await axios.post('http://localhost:3001/create-payment', {
//         const response = await axios.post(apipath + '/payment/order', {

//         amount,
//         userId
//       });

//       if (response.data.success) {
//         // Handle successful payment response
//         console.log('Payment Successful:', response.data);
//       } else {
//         // Handle payment failure
//         console.log('Payment Failed:', response.data);
//       }
//     } catch (error) {
//       console.error('Error making payment:', error);
//     }
//   };

//   return (
//     <form onSubmit={handlePayment}>
//       <div>
//         <label>Amount:</label>
//         <input
//           type="number"
//           value={amount}
//           onChange={(e) => setAmount(e.target.value)}
//           required
//         />
//       </div>
//       <div>
//         <label>User ID:</label>
//         <input
//           type="text"
//           value={userId}
//           onChange={(e) => setUserId(e.target.value)}
//           required
//         />
//       </div>
//       <button type="submit">Pay Now</button>
//     </form>
//   );
// };

// export default PaymentPage;
