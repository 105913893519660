// import React, { useEffect, useState } from 'react';

// const CLIENT_ID = '701589161071-0nbckman8f0mh4df6fdht4h6u6m75lne.apps.googleusercontent.com';
// const API_KEY = 'AIzaSyAjfBYZVOqpBtwp6Ty55hL9-eXAVD6rKUs';
// const DISCOVERY_DOC = 'https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest';
// const SCOPES = 'https://www.googleapis.com/auth/calendar.readonly';

// const GoogleCalendar = () => {
//   const [gapiInited, setGapiInited] = useState(false);
//   const [gisInited, setGisInited] = useState(false);
//   const [tokenClient, setTokenClient] = useState(null);
//   const [events, setEvents] = useState([]);

//   useEffect(() => {
//     const gapiScript = document.createElement('script');
//     gapiScript.src = 'https://apis.google.com/js/api.js';
//     gapiScript.onload = gapiLoaded;
//     document.body.appendChild(gapiScript);

//     const gisScript = document.createElement('script');
//     gisScript.src = 'https://accounts.google.com/gsi/client';
//     gisScript.onload = gisLoaded;
//     document.body.appendChild(gisScript);

//     return () => {
//       document.body.removeChild(gapiScript);
//       document.body.removeChild(gisScript);
//     };
//   }, []);

//   const gapiLoaded = () => {
//     window.gapi.load('client', initializeGapiClient);
//   };

//   const initializeGapiClient = async () => {
//     await window.gapi.client.init({
//       apiKey: API_KEY,
//       discoveryDocs: [DISCOVERY_DOC],
//     });
//     setGapiInited(true);
//     maybeEnableButtons();
//   };

//   const gisLoaded = () => {
//     const client = window.google.accounts.oauth2.initTokenClient({
//       client_id: CLIENT_ID,
//       scope: SCOPES,
//       callback: '', // defined later
//     });
//     setTokenClient(client);
//     setGisInited(true);
//     maybeEnableButtons();
//   };

//   const maybeEnableButtons = () => {
//     if (gapiInited && gisInited) {
//       document.getElementById('authorize_button').style.visibility = 'visible';
//     }
//   };

//   const handleAuthClick = () => {
//     tokenClient.callback = async (resp) => {
//       if (resp.error !== undefined) {
//         throw resp;
//       }
//       document.getElementById('signout_button').style.visibility = 'visible';
//       document.getElementById('authorize_button').innerText = 'Refresh';
//       await listUpcomingEvents();
//     };

//     if (window.gapi.client.getToken() === null) {
//       tokenClient.requestAccessToken({ prompt: 'consent' });
//     } else {
//       tokenClient.requestAccessToken({ prompt: '' });
//     }
//   };

//   const handleSignoutClick = () => {
//     const token = window.gapi.client.getToken();
//     if (token !== null) {
//       window.google.accounts.oauth2.revoke(token.access_token);
//       window.gapi.client.setToken('');
//       setEvents([]);
//       document.getElementById('authorize_button').innerText = 'Authorize';
//       document.getElementById('signout_button').style.visibility = 'hidden';
//     }
//   };

//   const listUpcomingEvents = async () => {
//     let response;
//     try {
//       const request = {
//         calendarId: 'primary',
//         timeMin: new Date().toISOString(),
//         showDeleted: false,
//         singleEvents: true,
//         maxResults: 10,
//         orderBy: 'startTime',
//       };
//       response = await window.gapi.client.calendar.events.list(request);
//     } catch (err) {
//       setEvents([{ summary: err.message }]);
//       return;
//     }

//     const events = response.result.items;
//     if (!events || events.length === 0) {
//       setEvents([{ summary: 'No events found.' }]);
//       return;
//     }

//     setEvents(events);
//   };

//   return (
//     <div>
//       <p>Google Calendar API Quickstart</p>
//       <button id="authorize_button" onClick={handleAuthClick} style={{ visibility: 'hidden' }}>Authorize</button>
//       <button id="signout_button" onClick={handleSignoutClick} style={{ visibility: 'hidden' }}>Sign Out</button>
//       <pre id="content" style={{ whiteSpace: 'pre-wrap' }}>
//         {events.map((event, index) => (
//           <div key={index}>{event.summary} ({event.start.dateTime || event.start.date})</div>
//         ))}
//       </pre>
//     </div>
//   );
// };

// export default GoogleCalendar;

import React from 'react';

const GoogleCalendarEmbed = () => {
  const calendarSrc = "https://calendar.google.com/calendar/embed?src=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&ctz=your_timezone";

  return (
    <div>
      <h1>Google Calendar Embed</h1>
      <iframe 
        src={calendarSrc}
        style={{ border: 0 }}
        width="800"
        height="600"
        frameBorder="0"
        scrolling="no"
        title="Google Calendar"
      ></iframe>
    </div>
  );
};

export default GoogleCalendarEmbed;
