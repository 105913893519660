import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useAuth } from "../AuthContext";
import axios from "axios";

const Page1 = () => {
  const { calendarId, shippingId } = useParams();
  const [shipping, setShipping] = useState([]);
  const { apipath } = useAuth();

  useEffect(() => {
    fetchShipping();
  }, []);

  const fetchShipping = async () => {
    try {
      const response = await axios.get(
        apipath + `/shipping/details/${shippingId}`
      );
      setShipping(response.data.shippingInformation);
    } catch (error) {
      console.error("Error fetching courses:", error);
    }
  };
  return (
    <>
      <div className="container preview">
        <div className="top-buttons">
          {/* <Link to={`/events/${calendarId}/${billingId}`}> */}
          {/* <Link to={`/shipping-billing/${calendarId}`}>
         <button className="pre-button">
              {" "}
              <i className="bi bi-chevron-left"></i> Previous
            </button>
          </Link> */}
          .
          <Link to={`/preview2/${calendarId}/${shippingId}`}>
            <button className="next-button">
              {" "}
              Next <i className="bi bi-chevron-right"></i>
            </button>
          </Link>
        </div>
        <div className="preview-center">
          <h1>
            PERSONALIZED CALENDAR FOR <span> {shipping.firstName}</span>
          </h1>
        </div>
        <div className="preview-footer">
          <Link to="www.GiftOnly4U.com">
            <h3>www.GiftOnly4U.com</h3>
          </Link>
          <div className="text-center">
            <p className="cursive">
              simply a{" "}
              <span className="font-strong">
                <strong>PRESSIdeas</strong>
              </span>{" "}
              initiative
            </p>
            <img
              src={`${process.env.PUBLIC_URL}/imgs-calendar/logo1.png`}
              alt=""
              className=""
            />
          </div>
          <Link className="text-decoration-none text-black">
            <h6 className="font-new">www.pressideas.com</h6>
          </Link>
        </div>
      </div>
    </>
  );
};

export default Page1;
