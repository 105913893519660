import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../AuthContext";

const CalendarPrice = () => {
    const [isLoggedIns, setIsLoggedIns] = useState(false);
    const { logout,apipath } = useAuth();
    const userId = localStorage.getItem('userId');
    const location = useLocation();
    const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "auto",
    });
  }, []);
  
    // Function to check if user is logged in
    const checkLoginStatus = () => {
        const token = localStorage.getItem('token');
        setIsLoggedIns(!!token);
      };
    
      useEffect(() => {
        checkLoginStatus();
      }, []);
    
      const isActive = (path) => {
        return location.pathname === path ? 'active' : '';
      };

      
   // Redirect to login if not authenticated
   const handleProductClick = () => {
    const token = localStorage.getItem('token');
  
    if (token) {
      navigate(`/calendar-types`);
    } else {
      localStorage.setItem('redirectAfterLogin', `/calendar-types`);
      navigate('/login');
    }
  };

  return (
    <>
      <Navbar />

      <div className="container privacy">
   <div className="row">
    <div className="col-lg-4">
    <h1>Your Personalised Calendar Price</h1>
    <img src={`${process.env.PUBLIC_URL}/imgs-calendar/calendarpricing.jpg`} alt="" />
    
       <p>All inclusive price including shipping and gst is
       only ₹295 anywhere in India.</p>
                <button className="first-btn me-3"  onClick={handleProductClick}>Buy Now{" "}<i class="bi bi-basket2"></i></button>

    </div>
   </div>
        </div>
      <Footer />
    </>
  );
};

export default CalendarPrice;
