// // // import React, { useEffect, useState } from "react";
// // // import jsPDF from "jspdf";
// // // import html2canvas from "html2canvas";
// // // import { generateYearMonths } from "./generateYearMonths";
// // // import { Link, useParams } from "react-router-dom";
// // // import axios from "axios";
// // // import { useAuth } from "../AuthContext";
// // // import moment from "moment";
// // // import ReactPreviewPCalendar from "./ReactPreviewPCalendar";
// // // import ReactPreviewLCalendar from "./ReactPreviewLCalendar";

// // // const PrintableCalendar = () => {
// // //   const [calendarData, setCalendarData] = useState(null);
// // //   const [googleCalendarEvents, setGoogleCalendarEvents] = useState([]);
// // //   const [loader, setLoader] = useState(false);
// // //   const { calendarId } = useParams();
// // //   const { filepath, apipath } = useAuth();
// // //   const [shippingId, setShippingId] = useState("");
  
// // //   const [shippingData, setShippingData] = useState({
// // //     firstName: "",
// // //     lastName: "",
// // //     phone: "",
// // //     alternatePhone: "",
// // //     address: "",
// // //     city: "",
// // //     state: "",
// // //     country: "",
// // //     pinCode: "",
// // //     paymentMethod: "UPI",
// // //     additionalInformation: "",
// // //     calendar_id: calendarId || "",
// // //   });

// // //   const token = localStorage.getItem('token');

// // //   useEffect(() => {
// // //     const fetchCalendarData = async () => {
// // //       try {
// // //         const response = await axios.get(
// // //           `${apipath}/calendar/details/${calendarId}`
// // //         );
// // //         setCalendarData(response.data.calendar);
// // //         setShippingId(response.data.calendar.shipping_id);
// // //       } catch (error) {
// // //         console.error("Error fetching calendar data:", error);
// // //       }
// // //     };

// // //     const fetchShipping = async () => {
// // //       try {
// // //         if (shippingId) {
// // //           const response = await axios.get(`${apipath}/shipping/details/${shippingId}`, {
// // //             headers: {
// // //               'Authorization': `Bearer ${token}`,
// // //             }
// // //           });
// // //           setShippingData(response.data.shippingInformation);
// // //         }
// // //       } catch (error) {
// // //         console.error('Error fetching shipping details:', error);
// // //       }
// // //     };

// // //     fetchCalendarData();
// // //     fetchShipping();
// // //   }, [calendarId, apipath, shippingId, token]);

  
// // //   useEffect(() => {
// // //     if (calendarData) {
// // //       const fetchGoogleCalendarEvents = async () => {
// // //         try {
// // //           const months = generateYearMonths(calendarData.startYearMonth);

// // //           const eventsPromises = months.map((month) => {
// // //             const year = month.year();
// // //             const monthIndex = month.month();
// // //             const timeMin = new Date(year, monthIndex, 1).toISOString();
// // //             const timeMax = new Date(year, monthIndex + 1, 0).toISOString();

// // //             return fetch(
// // //               `https://www.googleapis.com/calendar/v3/calendars/en.indian%23holiday%40group.v.calendar.google.com/events?key=AIzaSyAjfBYZVOqpBtwp6Ty55hL9-eXAVD6rKUs&timeMin=${timeMin}&timeMax=${timeMax}`
// // //             )
// // //               .then((response) => response.json())
// // //               .then((data) =>
// // //                 data.items.map((event) => ({
// // //                   start: new Date(event.start.date || event.start.dateTime),
// // //                   end: new Date(event.end.date || event.end.dateTime),
// // //                   title: event.summary,
// // //                   month: monthIndex,
// // //                 }))
// // //               );
// // //           });

// // //           const allEvents = await Promise.all(eventsPromises);
// // //           const mergedEvents = allEvents
// // //             .flat()
// // //             .sort((a, b) => new Date(a.start) - new Date(b.start));

// // //           setGoogleCalendarEvents(mergedEvents);
// // //         } catch (error) {
// // //           console.error("Error fetching Google Calendar events:", error);
// // //         }
// // //       };

// // //       fetchGoogleCalendarEvents();
// // //     }
// // //   }, [calendarData]);

// // //   const handleDownloadPDF = async () => {
// // //     setLoader(!loader);
// // //     if (!calendarData) return;

// // //     try {
// // //       const pdf = new jsPDF("p", "mm", "a4");
// // //       const months = generateYearMonths(calendarData.startYearMonth);

// // //       for (let monthIndex = 0; monthIndex < months.length; monthIndex++) {
// // //         const month = months[monthIndex];
// // //         const element = document.querySelector(`#month-${monthIndex}`);

// // //         // Load the month image
// // //         const monthName = month.format("MMMM").toLowerCase();
// // //         const monthPicKey = `${monthName}Pic`;
// // //         const imageSrc =
// // //           calendarData[monthPicKey] && calendarData[monthPicKey] !== "null"
// // //             ? filepath + calendarData[monthPicKey]
// // //             : `${process.env.PUBLIC_URL}/imgs-calendar/l.png`;

// // //         // Function to load an image
// // //         const loadImage = (src) => {
// // //           return new Promise((resolve, reject) => {
// // //             const img = new Image();
// // //             img.crossOrigin = "Anonymous"; // Handle CORS issues if loading from external sources
// // //             img.src = src;
// // //             img.onload = () => resolve(img);
// // //             img.onerror = (error) => {
// // //               console.error(" loading image from ${src}:", error);
// // //               reject(error);
// // //             };
// // //           });
// // //         };

// // //         if (monthIndex > 0) {
// // //           pdf.addPage();
// // //         }

// // //         // Add the month image to PDF
// // //         const monthImg = await loadImage(imageSrc);
// // //         const monthImgData = monthImg.src;

// // //         const pdfWidth = pdf.internal.pageSize.getWidth();
// // //         const imgProps = pdf.getImageProperties(monthImgData);
// // //         const pdfImgHeight = (imgProps.height * pdfWidth) / imgProps.width;

// // //         pdf.addImage(monthImgData, "JPG", 0, 0, pdfWidth, pdfImgHeight);

// // //         if (element) {
// // //           // Convert HTML element to image
// // //           const canvas = await html2canvas(element, { scale: 2 });
// // //           const imgData = canvas.toDataURL("image/jpg");

// // //           // Add the HTML image to PDF
// // //           const imgPropsHTML = pdf.getImageProperties(imgData);
// // //           const pdfHTMLImgHeight =
// // //             (imgPropsHTML.height * pdfWidth) / imgPropsHTML.width;

// // //           pdf.addImage(
// // //             imgData,
// // //             "JPEG",
// // //             0,
// // //             pdfImgHeight,
// // //             pdfWidth,
// // //             pdfHTMLImgHeight
// // //           );
// // //         }

// // //         // Add month title
// // //         pdf.setFontSize(16);
// // //         pdf.text(month.format("MMMM YYYY"), 105, 10, { align: "center" });

// // //         // Add events and holidays (handling omitted for brevity)
// // //       }

// // //       pdf.save("calendar.pdf");
// // //       console.log("PDF saved successfully.");
// // //       setLoader(false);
// // //     } catch (error) {
// // //       console.error("Error generating PDF:", error);
// // //     }
// // //   };

// // //   // Utility function to group items into pairs
// // //   const groupItems = (items) => {
// // //     const grouped = [];
// // //     for (let i = 0; i < items.length; i += 2) {
// // //       grouped.push(items.slice(i, i + 2));
// // //     }
// // //     return grouped;
// // //   };

// // //   const months = generateYearMonths(
// // //     calendarData ? calendarData.startYearMonth : new Date()
// // //   );

// // //   return (
// // //     <div>
// // //       <div className="container">
// // //         <div className="congrats-button previewC-button">
// // //           <Link to="/">
// // //             <button> Back</button>
// // //           </Link>
// // //           {!loader && (
// // //             <button onClick={handleDownloadPDF}>
// // //               <i className="bi bi-download"></i> Download PDF
// // //             </button>
// // //           )}
// // //           {loader && (
// // //             <div className="loader-p">
// // //               <div className="loader"></div>
// // //             </div>
// // //           )}
// // //         </div>
// // //       </div>
// // //       <div>
// // //         <div className="">
// // //           <div
// // //             className="container-fluid"
// // //             style={{
// // //               width: "794px",
// // //               height: "1000px",
// // //               border: "1px solid black",
// // //               display: "flex",
// // //               alignItems: "center",
// // //               justifyContent: "center",
// // //               marginBottom: "10px",
// // //             }}
// // //           >
// // //             <div
// // //               style={{
// // //                 width: "585.29px",
// // //                 height: "441.29px",
// // //                 border: "1px dotted black",
// // //                 display: "flex",
// // //                 justifyContent: "center",
// // //                 alignItems: "center",
// // //               }}
// // //             >
// // //               <div
// // //                 style={{
// // //                   width: "566.4px",
// // //                   height: "422.4px",
// // //                   border: "1px dotted black",
// // //                   // display: "flex",
// // //                   // justifyContent: "center",
// // //                   // alignItems: "center",
// // //                   position: "relative",
// // //                 }}
// // //               >
// // //                 <div
// // //                   style={{
// // //                     display: "flex",
// // //                     justifyContent: "center",
// // //                     alignItems: "center",
// // //                     position: "absolute",
// // //                     top: "50%",
// // //                     left: "50%",
// // //                     transform: "translate(-50%, -50%)",
// // //                     zIndex: 10,
// // //                     width: "400px",
// // //                   }}
// // //                 >
// // //                   <img
// // //                     src={`${process.env.PUBLIC_URL}/imgs-calendar/transparent-logo.png`}
// // //                     alt=""
// // //                     style={{
// // //                       width: "400px",
// // //                       height: "100%",
// // //                       transform: "rotate(-15deg)",
// // //                       // opacity: "0.8",
// // //                     }}
// // //                   />
// // //                 </div>
// // //                 <div
// // //                   style={{
// // //                     height: "60%",
// // //                     display: "flex",
// // //                     justifyContent: "center",
// // //                     alignItems: "center",
// // //                     fontFamily: '"Advent Pro", sans-serif',
// // //                     fontSize: "36px",
// // //                     fontWeight: "500",
// // //                     textAlign: "center",
// // //                     color: "#000",
// // //                   }}
// // //                 >
// // //                   <h1 style={{ width: "80%" }}>
// // //                     PERSONALIZED CALENDAR FOR{" "}
// // //                     <span style={{ color: "#ff3868" }}>JOHN</span>
// // //                   </h1>
// // //                 </div>
// // //                 <div
// // //                   style={{
// // //                     display: "flex",
// // //                     justifyContent: "center",
// // //                     alignItems: "center",
// // //                     flexDirection: "column",
// // //                   }}
// // //                 >
// // //                   <Link to="www.GiftOnly4U.com">
// // //                     <h3
// // //                       style={{
// // //                         fontFamily: '"Carrois Gothic", sans-serif',
// // //                         fontSize: "28px",
// // //                         fontWeight: 400,
// // //                         lineHeight: "38px",
// // //                         textAlign: "center",
// // //                       }}
// // //                     >
// // //                       www.GiftOnly4U.com
// // //                     </h3>
// // //                   </Link>
// // //                   <div
// // //                     style={{
// // //                       width: "200px",
// // //                       display: "flex",
// // //                       justifyContent: "center",
// // //                       alignItems: "center",
// // //                     }}
// // //                   >
// // //                     <img
// // //                       src={`${process.env.PUBLIC_URL}/imgs-calendar/Layout11.png`}
// // //                       alt=""
// // //                       style={{
// // //                         width: "100%",
// // //                         height: "100%",
// // //                         objectFit: "contain",
// // //                       }}
// // //                     />
// // //                   </div>
// // //                   <Link
// // //                     style={{
// // //                       textDecoration: "none",
// // //                       color: "black",
// // //                       fontFamily: '"Carrois Gothic", sans-serif',
// // //                       fontSize: "18px",
// // //                       fontWeight: 500,
// // //                     }}
// // //                   >
// // //                     <h6>www.pressideas.com</h6>
// // //                   </Link>
// // //                 </div>
// // //               </div>
// // //             </div>
// // //           </div>
// // //           <div
// // //             className="container-fluid"
// // //             style={{
// // //               width: "794px",
// // //               height: "1050px",
// // //               border: "1px solid black",
// // //               display: "flex",
// // //               alignItems: "center",
// // //               justifyContent: "center",
// // //               marginBottom: "10px",
// // //             }}
// // //           >
// // //             <div
// // //               style={{
// // //                 width: "585.29px",
// // //                 height: "441.29px",
// // //                 border: "1px dotted black",
// // //                 display: "flex",
// // //                 justifyContent: "center",
// // //                 alignItems: "center",
// // //               }}
// // //             >
// // //               <div
// // //                 style={{
// // //                   width: "566.4px",
// // //                   height: "422.4px",
// // //                   border: "1px dotted black",
// // //                   display: "flex",
// // //                   justifyContent: "center",
// // //                   alignItems: "center",
// // //                   flexDirection: "column",
// // //                   position: "relative",
// // //                 }}
// // //               >
// // //                 <div
// // //                   style={{
// // //                     display: "flex",
// // //                     justifyContent: "center",
// // //                     alignItems: "center",
// // //                     position: "absolute",
// // //                     top: "50%",
// // //                     left: "50%",
// // //                     transform: "translate(-50%, -50%)",
// // //                     zIndex: 10,
// // //                     width: "400px",
// // //                   }}
// // //                 >
// // //                   <img
// // //                     src={`${process.env.PUBLIC_URL}/imgs-calendar/transparent-logo.png`}
// // //                     alt=""
// // //                     style={{
// // //                       width: "400px",
// // //                       height: "100%",
// // //                       transform: "rotate(-15deg)",
// // //                       // opacity: "0.8",
// // //                     }}
// // //                   />
// // //                 </div>
// // //                 <div
// // //                   style={{
// // //                     height: "60%",
// // //                     display: "flex",
// // //                     justifyContent: "center",
// // //                     alignItems: "center",
// // //                     fontFamily: '"Advent Pro", sans-serif',
// // //                     fontSize: "36px",
// // //                     fontWeight: "500",
// // //                     textAlign: "center",
// // //                     color: "#000",
// // //                   }}
// // //                 >
// // //                   <div>
// // //                     <h2
// // //                       style={{
// // //                         fontFamily: '"Carrois Gothic", sans-serif',
// // //                         fontSize: "32px",
// // //                         fontWeight: 400,
// // //                         lineHeight: "48px",
// // //                         paddingBottom: "2%",
// // //                       }}
// // //                     >
// // //                       <u>By Courier</u>
// // //                     </h2>
// // //                     <p
// // //                       style={{
// // //                         fontFamily: '"Carrois Gothic", sans-serif',
// // //                         fontSize: "13px",
// // //                         fontWeight: 400,
// // //                         lineHeight: "18px",
// // //                         color: "#565656",
// // //                         textAlign: "left",
// // //                       }}
// // //                     >
// // //                       To, <br />
// // //                       {shippingData.firstName}{" "}{shippingData.lastName}<br />
// // //                       {shippingData.address}
// // //                       <br />
// // //                       {shippingData.city}{", "} {shippingData.state} <br />
// // //                       {shippingData.additionalInformation}
// // //                       <br />
// // //                       Pincode : {shippingData.pinCode} <br />
// // //                       +91-{shippingData.phone} <br />{shippingData.alternatePhone}
// // //                     </p>
// // //                   </div>
// // //                 </div>
// // //                 <div
// // //                   style={{
// // //                     width: "90%",
// // //                     display: "flex",
// // //                     justifyContent: "end",
// // //                   }}
// // //                 >
// // //                   <p
// // //                     style={{
// // //                       fontFamily: '"Carrois Gothic", sans-serif',
// // //                       fontSize: "13px",
// // //                       fontWeight: 400,
// // //                       lineHeight: "20px",
// // //                       color: "#636060",
// // //                       textAlign: "right",
// // //                     }}
// // //                   >
// // //                     If undelivered, return to: <br />
// // //                     <b>PRESSIdeas</b>, Mr.Mohinder Gupta/Nitin <br />
// // //                     Building Number 57, G-8, Manjusha Building, <br />
// // //                     Nehru Place, Delhi - 110019 (Near Main Market) <br />
// // //                   </p>
// // //                 </div>
// // //               </div>
// // //             </div>
// // //           </div>
// // //           {calendarData &&
// // //             months.map((month, index) => {
// // //               const monthName = month.format("MMMM").toLowerCase();
// // //               const monthPicKey = `${monthName}Pic`;
// // //               const viewTypeKey = `${monthName}ViewType`;
// // //               const isPortrait = calendarData[viewTypeKey] === "Vertical";

// // //               const imageSrc =
// // //                 calendarData[monthPicKey] &&
// // //                 calendarData[monthPicKey] !== "null"
// // //                   ? filepath + calendarData[monthPicKey]
// // //                   : `${process.env.PUBLIC_URL}/imgs-calendar/l.png`;

// // //               const events = [];
// // //               const eventItems = [];
// // //               for (let i = 1; i <= 4; i++) {
// // //                 const eventDateKey = `${monthName}Event${i}Date`;
// // //                 const eventCaptionKey = `${monthName}Event${i}Caption`;
// // //                 const eventColorKey = `${monthName}Event${i}Color`;
// // //                 if (calendarData[eventDateKey]) {
// // //                   events.push({
// // //                     start: new Date(
// // //                       month.year(),
// // //                       month.month(),
// // //                       calendarData[eventDateKey]
// // //                     ),
// // //                     end: new Date(
// // //                       month.year(),
// // //                       month.month(),
// // //                       calendarData[eventDateKey]
// // //                     ),
// // //                     title: calendarData[eventCaptionKey] || "",
// // //                   });
// // //                   eventItems.push(
// // //                     <li key={i} style={{ color: calendarData[eventColorKey] }}>
// // //                       {calendarData[eventDateKey]} -{" "}
// // //                       {calendarData[eventCaptionKey]}
// // //                     </li>
// // //                   );
// // //                 }
// // //               }

// // //               const monthHolidays = googleCalendarEvents.filter(
// // //                 (event) => event.month === month.month()
// // //               );
// // //               const groupedEventItems = groupItems(eventItems);
// // //               const groupedHolidays = groupItems(monthHolidays);

// // //               return (
// // //                 <div
// // //                   key={index}
// // //                   id={`month-${index}`}
// // //                   // className={`row mb-4 ${
// // //                   //   isPortrait ? "portrait" : "landscape"
// // //                   // }`}
// // //                   className="mb-4"
// // //                 >
// // //                   {/* <div className="watermark">
// //                   //   <img
// //                   //     src={`${process.env.PUBLIC_URL}/imgs-calendar/transparent-logo.png`}
// //                   //     alt=""
// //                   //   />
// //                   // </div> */}
// // //                   {isPortrait ? (
// // //                     <div
// // //                       className="container-fluid"
// // //                       style={{
// // //                         width: "794px",
// // //                         height: "1050px",
// // //                         border: "1px solid black",
// // //                         display: "flex",
// // //                         alignItems: "center",
// // //                         justifyContent: "center",
// // //                       }}
// // //                     >
// // //                       <div
// // //                         style={{
// // //                           width: "585.29px",
// // //                           height: "441.29px",
// // //                           border: "1px dotted black",
// // //                           display: "flex",
// // //                           justifyContent: "center",
// // //                           alignItems: "center",
// // //                         }}
// // //                       >
// // //                         <div
// // //                           className=""
// // //                           style={{
// // //                             width: "566.4px",
// // //                             height: "422.4px",
// // //                             border: "1px dotted black",
// // //                             display: "flex",
// // //                             justifyContent: "center",
// // //                             position: "relative",
// // //                           }}
// // //                         >
// // //                           <div
// // //                             style={{
// // //                               display: "flex",
// // //                               justifyContent: "center",
// // //                               alignItems: "center",
// // //                               position: "absolute",
// // //                               top: "50%",
// // //                               left: "50%",
// // //                               transform: "translate(-50%, -50%)",
// // //                               zIndex: 10,
// // //                               width: "400px",
// // //                             }}
// // //                           >
// // //                             <img
// // //                               src={`${process.env.PUBLIC_URL}/imgs-calendar/transparent-logo.png`}
// // //                               alt=""
// // //                               style={{
// // //                                 width: "400px",
// // //                                 height: "100%",
// // //                                 transform: "rotate(-15deg)",
// // //                                 // opacity: "0.8",
// // //                               }}
// // //                             />
// // //                           </div>
// // //                           <div
// // //                             style={{
// // //                               width: "50%",
// // //                               height: "100%",
// // //                               display: "flex",
// // //                               justifyContent: "center",
// // //                             }}
// // //                           >
// // //                             <img
// // //                               src={imageSrc}
// // //                               alt={month.format("MMMM")}
// // //                               onError={(e) => {
// // //                                 e.target.src = `${process.env.PUBLIC_URL}/imgs-calendar/p.png`;
// // //                               }}
// // //                               style={{
// // //                                 height: "100%",
// // //                                 width: "100%",
// // //                                 objectFit: "contain",
// // //                                 // border: "1px solid black",
// // //                               }}
// // //                             />
// // //                           </div>
// // //                           <div
// // //                             className=""
// // //                             style={{ width: "50%", padding: "5px" }}
// // //                           >
// // //                             <h1
// // //                               style={{
// // //                                 textAlign: "right",
// // //                                 marginBottom: "10px",
// // //                                 fontSize: "22px",
// // //                                 color: "#e50044",
// // //                                 fontWeight: "600",
// // //                               }}
// // //                             >
// // //                               {month.format("MMMM YYYY")}
// // //                             </h1>
// // //                             <ReactPreviewPCalendar
// // //                               year={month.year()}
// // //                               month={month.month()}
// // //                               googleCalendarEvents={googleCalendarEvents.filter(
// // //                                 (event) => event.month === month.month()
// // //                               )}
// // //                               view="vertical"
// // //                               calendarData={calendarData}
// // //                             />
// //                             // <div
// //                             //   style={{
// //                             //     listStyle: "none",
// //                             //     display: "flex",
// //                             //     fontSize: "11px",
// //                             //     fontWeight: "600",
// //                             //     flexWrap: "wrap",
// //                             //     marginTop: "20px",
// //                             //   }}
// //                             // >
// //                             //   {groupedEventItems.map((pair, index) => (
// //                             //     <li
// //                             //       key={index}
// //                             //       style={{
// //                             //         marginRight: "5px",
// //                             //         fontSize: "11px",
// //                             //       }}
// //                             //     >
// //                             //       {pair[0]}
// //                             //       {pair[1]}
// //                             //     </li>
// //                             //   ))}
// //                             // </div>
// // //                             <div
// // //                               style={{
// // //                                 listStyle: "none",
// // //                                 display: "flex",
// // //                                 fontSize: "11px",
// // //                                 fontWeight: "600",
// // //                                 flexWrap: "wrap",
// // //                               }}
// // //                             >
// // //                               {groupedHolidays.map((pair, index) => (
// // //                                 <li
// // //                                   key={index}
// // //                                   style={{
// // //                                     marginRight: "5px",
// // //                                     fontSize: "9px",
// // //                                     fontWeight: "600",
// // //                                   }}
// // //                                 >
// // //                                   <span>
// // //                                     {moment(pair[0].start).format("DD")} -{" "}
// // //                                     {pair[0].title}
// // //                                   </span>
// // //                                   {pair[1] && (
// // //                                     <span>
// // //                                       {moment(pair[1].start).format("DD")} -{" "}
// // //                                       {pair[1].title}
// // //                                     </span>
// // //                                   )}
// // //                                 </li>
// // //                               ))}
// // //                             </div>
// // //                           </div>
// // //                         </div>
// // //                       </div>
// // //                     </div>
// // //                   ) : (
// // //                     <div
// // //                       className="container-fluid"
// // //                       style={{
// // //                         width: "794px",
// // //                         height: "1050px",
// // //                         border: "1px solid black",
// // //                         display: "flex",
// // //                         alignItems: "center",
// // //                         justifyContent: "center",
// // //                       }}
// // //                     >
// // //                       <div
// // //                         style={{
// // //                           width: "585.29px",
// // //                           height: "441.29px",
// // //                           border: "1px dotted black",
// // //                           display: "flex",
// // //                           justifyContent: "center",
// // //                           alignItems: "center",
// // //                         }}
// // //                       >
// // //                         <div
// // //                           style={{
// // //                             width: "566.4px",
// // //                             height: "422.4px",
// // //                             border: "1px dotted black",
// // //                             display: "flex",
// // //                             justifyContent: "center",
// // //                             alignItems: "center",
// // //                             position: "relative",
// // //                           }}
// // //                         >
// // //                           <div
// // //                             style={{
// // //                               display: "flex",
// // //                               justifyContent: "center",
// // //                               alignItems: "center",
// // //                               position: "absolute",
// // //                               top: "50%",
// // //                               left: "50%",
// // //                               transform: "translate(-50%, -50%)",
// // //                               zIndex: 10,
// // //                               width: "400px",
// // //                             }}
// // //                           >
// // //                             <img
// // //                               src={`${process.env.PUBLIC_URL}/imgs-calendar/transparent-logo.png`}
// // //                               alt=""
// // //                               style={{
// // //                                 width: "100%",
// // //                                 height: "100%",
// // //                                 transform: "rotate(-15deg)",
// // //                                 // opacity: "0.8",
// // //                               }}
// // //                             />
// // //                           </div>
// // //                           <div style={{ height: "100%", width: "100%" }}>
// // //                             <div
// // //                               style={{
// // //                                 width: "100%",
// // //                                 height: "70%",
// // //                                 display: "flex",
// // //                                 justifyContent: "center",
// // //                               }}
// // //                             >
// //                               // <img
// //                               //   src={imageSrc}
// //                               //   alt={month.format("MMMM")}
// //                               //   onError={(e) => {
// //                               //     e.target.src = `${process.env.PUBLIC_URL}/imgs-calendar/l.png`;
// //                               //   }}
// //                               //   style={{
// //                               //     height: "100%",
// //                               //     width: "100%",
// //                               //     objectFit: "contain",
// //                               //     border: "1px solid black",
// //                               //   }}
// //                               // />
// // //                             </div>
// // //                             <div
// // //                               style={{
// // //                                 width: "100%",
// // //                                 padding: "0 10px",
// // //                                 display: "flex",
// // //                                 justifyContent: "space-between",
// // //                               }}
// // //                             >
// // //                               <div
// // //                                 style={{ width: "50%" }}
// // //                               >
// // //                                 <h1
// // //                                   style={{
// // //                                     textAlign: "left",
// // //                                     margin: "5px 0",
// // //                                     fontSize: "12px",
// // //                                     color: "#e50044",
// // //                                     fontWeight: "600",
// // //                                   }}
// // //                                 >
// // //                                   {month.format("MMMM YYYY")}
// // //                                 </h1>
// // //                                 <ReactPreviewLCalendar
// // //                                   year={month.year()}
// // //                                   month={month.month()}
// // //                                   googleCalendarEvents={googleCalendarEvents.filter(
// // //                                     (event) => event.month === month.month()
// // //                                   )}
// // //                                   calendarData={calendarData}
// // //                                 />
// // //                               </div>
// // //                               <div
// // //                                 style={{ width: "40%" }}
// // //                               >
// // //                                 <div
// // //                                   style={{
// // //                                     listStyle: "none",
// // //                                     display: "flex",
// // //                                     justifyContent: "end",
// // //                                     fontSize: "10px",
// // //                                     fontWeight: "600",
// // //                                     flexWrap: "wrap",
// // //                                     marginTop: "20px",
// // //                                   }}
// // //                                 >
// // //                                   {groupedEventItems.map((pair, index) => (
// // //                                     <li
// // //                                       key={index}
// // //                                       style={{
// // //                                         marginRight: "5px",
// // //                                         fontSize: "9px",
// // //                                         fontWeight: "600",
// // //                                       }}
// // //                                     >
// // //                                       {pair[0]}
// // //                                       {pair[1]}
// // //                                     </li>
// // //                                   ))}
// // //                                 </div>
// // //                                 <div
// // //                                   style={{
// // //                                     listStyle: "none",
// // //                                     display: "flex",
// // //                                     justifyContent: "end",
// // //                                     fontSize: "10px",
// // //                                     fontWeight: "600",
// // //                                     flexWrap: "wrap",
// // //                                   }}
// // //                                 >
// // //                                   {groupedHolidays.map((pair, index) => (
// // //                                     <li
// // //                                       key={index}
// // //                                       style={{
// // //                                         marginRight: "5px",
// // //                                         fontSize: "8px",
// // //                                         fontWeight: "600",
// // //                                         textAlign:"right"
// // //                                       }}
// // //                                     >
// // //                                       <span style={{marginRight: "5px", textAlign: "right"}}>
// // //                                         {moment(pair[0].start).format("DD")} -{" "}
// // //                                         {pair[0].title}
// // //                                       </span>
// // //                                       {pair[1] && (
// // //                                         <span style={{marginRight: "5px", textAlign: "right"}}>
// // //                                           {moment(pair[1].start).format("DD")} -{" "}
// // //                                           {pair[1].title}
// // //                                         </span>
// // //                                       )}
// // //                                     </li>
// // //                                   ))}
// // //                                 </div>
// // //                               </div>
// // //                             </div>
// // //                           </div>
// // //                         </div>
// // //                       </div>
// // //                     </div>
// // //                   )}
// // //                 </div>
// // //               );
// // //             })}
// // //           <div
// // //             className="container-fluid"
// // //             style={{
// // //               width: "794px",
// // //               height: "1050px",
// // //               border: "1px solid black",
// // //               display: "flex",
// // //               alignItems: "center",
// // //               justifyContent: "center",
// // //               marginBottom: "10px",
// // //             }}
// // //           >
// // //             <div
// // //               style={{
// // //                 width: "585.29px",
// // //                 height: "441.29px",
// // //                 border: "1px dotted black",
// // //                 display: "flex",
// // //                 justifyContent: "center",
// // //                 alignItems: "center",
// // //               }}
// // //             >
// // //               <div
// // //                 style={{
// // //                   width: "566.4px",
// // //                   height: "422.4px",
// // //                   border: "1px dotted black",
// // //                   display: "flex",
// // //                   justifyContent: "center",
// // //                   alignItems: "center",
// // //                   position: "relative",
// // //                 }}
// // //               >
// // //                 <div
// // //                   style={{
// // //                     display: "flex",
// // //                     justifyContent: "center",
// // //                     alignItems: "center",
// // //                     position: "absolute",
// // //                     top: "50%",
// // //                     left: "50%",
// // //                     transform: "translate(-50%, -50%)",
// // //                     zIndex: 10,
// // //                     width: "400px",
// // //                   }}
// // //                 >
// // //                   <img
// // //                     src={`${process.env.PUBLIC_URL}/imgs-calendar/transparent-logo.png`}
// // //                     alt=""
// // //                     style={{
// // //                       width: "400px",
// // //                       height: "100%",
// // //                       transform: "rotate(-15deg)",
// // //                       // opacity: "0.8",
// // //                     }}
// // //                   />
// // //                 </div>
// // //                 <img
// // //                   src={`${process.env.PUBLIC_URL}/imgs-calendar/layout31.png`}
// // //                   alt=""
// // //                   style={{ width: "100%", height: "100%" }}
// // //                 />
// // //               </div>
// // //             </div>
// // //           </div>
// // //           <div
// // //             className="container-fluid"
// // //             style={{
// // //               width: "794px",
// // //               height: "1050px",
// // //               border: "1px solid black",
// // //               display: "flex",
// // //               alignItems: "center",
// // //               justifyContent: "center",
// // //             }}
// // //           >
// // //             <div
// // //               style={{
// // //                 width: "585.29px",
// // //                 height: "441.29px",
// // //                 border: "1px dotted black",
// // //                 display: "flex",
// // //                 justifyContent: "center",
// // //                 alignItems: "center",
// // //               }}
// // //             >
// // //               <div
// // //                 style={{
// // //                   width: "566.4px",
// // //                   height: "422.4px",
// // //                   border: "1px dotted black",
// // //                   display: "flex",
// // //                   justifyContent: "center",
// // //                   alignItems: "center",
// // //                   position: "relative",
// // //                 }}
// // //               >
// // //                 <div
// // //                   style={{
// // //                     display: "flex",
// // //                     justifyContent: "center",
// // //                     alignItems: "center",
// // //                     position: "absolute",
// // //                     top: "50%",
// // //                     left: "50%",
// // //                     transform: "translate(-50%, -50%)",
// // //                     zIndex: 10,
// // //                     width: "400px",
// // //                   }}
// // //                 >
// // //                   <img
// // //                     src={`${process.env.PUBLIC_URL}/imgs-calendar/transparent-logo.png`}
// // //                     alt=""
// // //                     style={{
// // //                       width: "400px",
// // //                       height: "100%",
// // //                       transform: "rotate(-15deg)",
// // //                       // opacity: "0.8",
// // //                     }}
// // //                   />
// // //                 </div>
// // //                 <img
// // //                   src={`${process.env.PUBLIC_URL}/imgs-calendar/layout31.png`}
// // //                   alt=""
// // //                   style={{ width: "100%", height: "100%" }}
// // //                 />
// // //               </div>
// // //             </div>
// // //           </div>
// // //         </div>
// // //       </div>
// // //     </div>
// // //   );
// // // };

// // // export default PrintableCalendar;


// import React, { useEffect, useRef, useState } from 'react';
// import html2canvas from 'html2canvas';
// import jsPDF from 'jspdf';
// import ReactPreviewLCalendar from './ReactPreviewLCalendar';
// import ReactPreviewPCalendar from './ReactPreviewPCalendar';
// import { useParams } from 'react-router-dom';
// import { useAuth } from '../AuthContext';
// import axios from 'axios';
// import { generateYearMonths } from './generateYearMonths';
// import { Link } from 'react-router-dom';

// const AdminPrintCalendar = () => {
//   const containerRefs = useRef([]);
//   const [calendarData, setCalendarData] = useState(null);
//   const [googleCalendarEvents, setGoogleCalendarEvents] = useState([]);
//   const { calendarId } = useParams();
//   const { filepath, apipath } = useAuth();
//   const [shippingId, setShippingId] = useState('');

//   const [shippingData, setShippingData] = useState({
//     firstName: '',
//     lastName: '',
//     phone: '',
//     alternatePhone: '',
//     address: '',
//     city: '',
//     state: '',
//     country: '',
//     pinCode: '',
//     paymentMethod: 'UPI',
//     additionalInformation: '',
//     calendar_id: calendarId || '',
//   });

//   const [isLoading, setIsLoading] = useState(false); // Loader state

//   const token = localStorage.getItem('token');

//   useEffect(() => {
//     const fetchCalendarData = async () => {
//       try {
//         const response = await axios.get(`${apipath}/calendar/details/${calendarId}`);
//         setCalendarData(response.data.calendar);
//         setShippingId(response.data.calendar.shipping_id);
//       } catch (error) {
//         console.error('Error fetching calendar data:', error);
//       }
//     };

//     const fetchShipping = async () => {
//       try {
//         if (shippingId) {
//           const response = await axios.get(`${apipath}/shipping/details/${shippingId}`, {
//             headers: {
//               Authorization: `Bearer ${token}`,
//             },
//           });
//           setShippingData(response.data.shippingInformation);
//         }
//       } catch (error) {
//         console.error('Error fetching shipping details:', error);
//       }
//     };

//     fetchCalendarData();
//     fetchShipping();
//   }, [calendarId, apipath, shippingId, token]);

//   useEffect(() => {
//     if (calendarData) {
//       const fetchGoogleCalendarEvents = async () => {
//         try {
//           const months = generateYearMonths(calendarData.startYearMonth);

//           const eventsPromises = months.map((month) => {
//             const year = month.year();
//             const monthIndex = month.month();
//             const timeMin = new Date(year, monthIndex, 1).toISOString();
//             const timeMax = new Date(year, monthIndex + 1, 0).toISOString();

//             return fetch(
//               `https://www.googleapis.com/calendar/v3/calendars/en.indian%23holiday%40group.v.calendar.google.com/events?key=AIzaSyAjfBYZVOqpBtwp6Ty55hL9-eXAVD6rKUs&timeMin=${timeMin}&timeMax=${timeMax}`
//             )
//               .then((response) => response.json())
//               .then((data) =>
//                 data.items.map((event) => ({
//                   start: new Date(event.start.date || event.start.dateTime),
//                   end: new Date(event.end.date || event.end.dateTime),
//                   title: event.summary,
//                   month: monthIndex,
//                 }))
//               );
//           });

//           const allEvents = await Promise.all(eventsPromises);
//           const mergedEvents = allEvents
//             .flat()
//             .sort((a, b) => new Date(a.start) - new Date(b.start));

//           setGoogleCalendarEvents(mergedEvents);
//         } catch (error) {
//           console.error('Error fetching Google Calendar events:', error);
//         }
//       };

//       fetchGoogleCalendarEvents();
//     }
//   }, [calendarData]);

//   const downloadPDF = async () => {
//     setIsLoading(true); // Start loading
  
//     try {
//       // Check if there are elements to capture
//       if (!containerRefs.current.length) {
//         console.error('No elements to capture.');
//         return;
//       }
  
//       const pdf = new jsPDF({
//         orientation: 'portrait',
//         unit: 'in',
//         format: [8.9, 8.8], // PDF size
//       });
  
//       // Path to the watermark image
//       const watermarkSrc = `${process.env.PUBLIC_URL}/imgs-calendar/transparent-logo.png`;
  
//       // Load the watermark image
//       const watermarkImg = new Image();
//       watermarkImg.src = watermarkSrc;
  
//       // Wait for the watermark image to load
//       await new Promise((resolve) => {
//         watermarkImg.onload = resolve;
//       });
  
//       const watermarkWidthInches = 5; // Adjust as needed (in inches)
//       const watermarkHeightInches = (watermarkImg.height / watermarkImg.width) * watermarkWidthInches; // Maintain aspect ratio
  
//       for (let i = 0; i < containerRefs.current.length; i++) {
//         const element = containerRefs.current[i];
  
//         // Ensure that the element is loaded and visible
//         if (!element) {
//           console.error('Element not found or not visible.');
//           continue;
//         }
  
//         try {
//           const canvas = await html2canvas(element, {
//             scale: 2, // Increase the scale for higher quality
//             useCORS: true, // Enable CORS to handle image loading issues
//             allowTaint: true, // Allow cross-origin images
//             logging: false, // Disable logging for a cleaner output
//           });
  
//           const imgData = canvas.toDataURL('image/png');
  
//           // Dimensions of the container in inches
//           const containerWidth = 8.9; // PDF width
//           const containerHeight = 8.8; // PDF height
  
//           // Image dimensions
//           const imgWidth = canvas.width / 96; // Convert px to inches
//           const imgHeight = canvas.height / 96; // Convert px to inches
  
//           // Calculate aspect ratios
//           const imgAspectRatio = imgWidth / imgHeight;
//           const containerAspectRatio = containerWidth / containerHeight;
  
//           let finalImgWidth, finalImgHeight;
  
//           if (imgAspectRatio > containerAspectRatio) {
//             // Image is wider relative to the container
//             finalImgWidth = containerWidth;
//             finalImgHeight = containerWidth / imgAspectRatio;
//           } else {
//             // Image is taller relative to the container
//             finalImgHeight = containerHeight;
//             finalImgWidth = containerHeight * imgAspectRatio;
//           }
  
//           // Center the image on the page
//           const x = (containerWidth - finalImgWidth) / 2;
//           const y = (containerHeight - finalImgHeight) / 2;
  
//           // Add image to PDF
//           if (i > 0) pdf.addPage(); // Add a new page for each subsequent container
//           pdf.addImage(imgData, 'PNG', x, y, finalImgWidth, finalImgHeight);
  
//           // Center watermark on every page
//           const watermarkX = (containerWidth - watermarkWidthInches) / 2;
//           const watermarkY = (containerHeight - watermarkHeightInches) / 2;
          
  
//           pdf.addImage(watermarkImg, 'PNG', watermarkX, watermarkY, watermarkWidthInches, watermarkHeightInches);

  
//         } catch (error) {
//           console.error('Error capturing element:', error);
//         }
//       }
  
//       // Save the PDF
//       pdf.save('download.pdf');
//     } catch (error) {
//       console.error('Error generating PDF:', error);
//     } finally {
//       setIsLoading(false); // End loading
//     }
//   };
  
  
//   // Utility function to group items into pairs
//   const groupItems = (items) => {
//     const grouped = [];
//     for (let i = 0; i < items.length; i += 2) {
//       grouped.push(items.slice(i, i + 2));
//     }
//     return grouped;
//   };

//   const months = generateYearMonths(calendarData ? calendarData.startYearMonth : new Date());

//   return (
//     <div>
//       <div className="container custom-container">
//         <div>
//           <Link to="/" className='back-link'> Back </Link>
//         </div>
//         <div>
//           <button onClick={downloadPDF} disabled={isLoading}>Download PDF {isLoading && <span className="loader"></span>} </button>
//         </div>
//       </div>

//    {/* Display loader when isLoading is true */}

//       {calendarData &&
//         months.map((month, index) => {
//           const monthName = month.format('MMMM').toLowerCase();
//           const monthPicKey = `${monthName}Pic`;
//           const viewTypeKey = `${monthName}ViewType`;
//           const isPortrait = calendarData[viewTypeKey] === 'Horizontal';

//           const imageSrc =
//             calendarData[monthPicKey] && calendarData[monthPicKey] !== 'null'
//               ? filepath + calendarData[monthPicKey]
//               : `${process.env.PUBLIC_URL}/imgs-calendar/l.png`;

//           const events = [];
//           const eventItems = [];
//           for (let i = 1; i <= 4; i++) {
//             const eventDateKey = `${monthName}Event${i}Date`;
//             const eventCaptionKey = `${monthName}Event${i}Caption`;
//             const eventColorKey = `${monthName}Event${i}Color`;
//             if (calendarData[eventDateKey]) {
//               events.push({
//                 start: new Date(month.year(), month.month(), calendarData[eventDateKey]),
//                 end: new Date(month.year(), month.month(), calendarData[eventDateKey]),
//                 title: calendarData[eventCaptionKey] || '',
//               });
//               eventItems.push(
//                 <li key={i} style={{ color: calendarData[eventColorKey] }}>
//                   {calendarData[eventDateKey]} - {calendarData[eventCaptionKey]}
//                 </li>
//               );
//             }
//           }

//           const monthHolidays = googleCalendarEvents.filter(
//             (event) => event.month === month.month()
//           );
//           const groupedEventItems = groupItems(eventItems);
//           const groupedHolidays = groupItems(monthHolidays);
   

//           return (

            

//             <div className="print-pdf" key={index}>

//               <div className="pdf-inner-pages">
//                 {/* Content Pages */}
//                 <div
//   id={`month-${index}`}
//   className="print-pdf"
//   key={index}
//   ref={(el) => (containerRefs.current[index] = el)}
// >
  
//   {/* <div className={`watermark`}>
//     <img src={`${process.env.PUBLIC_URL}/imgs-calendar/transparent-logo.png`} alt="Watermark Logo" />
//   </div> */}
//   {isPortrait ? (
//     <div className="horizontal-container">
//       <div className="img-box">
//         <img
//           src={imageSrc}
//           alt={month.format('MMMM')}
//           onError={(e) => {
//             e.target.src = `${process.env.PUBLIC_URL}/imgs-calendar/l.png`;
//           }}
//         />
//       </div>
//       <div className="calendar-box">
//         <div className="horizontal-calendar">
//           <h1>{month.format("MMMM YYYY")}</h1>
//           <ReactPreviewLCalendar
//             year={month.year()}
//             month={month.month()}
//             googleCalendarEvents={googleCalendarEvents.filter(
//               (event) => event.month === month.month()
//             )}
//             calendarData={calendarData}
//           />
//         </div>
//         <div className="horizontal-event">
//           <div className="pdf-event">
//             {groupedEventItems.map((pair, index) => (
//               <p
//                 key={index}
//                 style={{
//                   marginRight: "5px",
//                   fontSize: "11px",
//                 }}
//               >
//                 {pair[0]}
//                 {pair[1]}
//               </p>
//             ))}
//           </div>
//           <div className='pdf-holiday'>
//             {groupedHolidays.map((pair, index) => (
//               <li
//                 key={index}
//                 style={{
//                   marginRight: "5px",
//                   fontSize: "11px",
//                 }}
//               >
//                 {pair[0].title}
//                 {pair[1] && pair[1].title}
//               </li>
//             ))}
//           </div>
//         </div>
//       </div>
//     </div>
//   ) : (
//     <div className="vertical-container">
//       <div className="vertical-img-box">
//         <img
//           src={imageSrc}
//           alt={month.format('MMMM')}
//           onError={(e) => {
//             e.target.src = `${process.env.PUBLIC_URL}/imgs-calendar/p.png`;
//           }}
//         />
//       </div>
//       <div className="vertical-calendar-box">
//         <h1>{month.format("MMMM YYYY")}</h1>
//         <div className="vertical-calendar">
//           <ReactPreviewPCalendar
//             year={month.year()}
//             month={month.month()}
//             googleCalendarEvents={googleCalendarEvents.filter(
//               (event) => event.month === month.month()
//             )}
//             calendarData={calendarData}
//           />
//         </div>
//         <div className="vertical-event">
//           <div className='pdf-event'>
//             {groupedEventItems.map((pair, index) => (
//               <p
//                 key={index}
//                 style={{
//                   marginRight: "5px",
//                   fontSize: "11px",
//                 }}
//               >
//                 {pair[0]}
//                 {pair[1]}
//               </p>
//             ))}
//           </div>
//           <div className='pdf-holiday'>
//             {groupedHolidays.map((pair, index) => (
//               <li
//                 key={index}
//                 style={{
//                   marginRight: "5px",
//                   fontSize: "11px",
//                 }}
//               >
//                 {pair[0].title}
//                 {pair[1] && pair[1].title}
//               </li>
//             ))}
//           </div>
//         </div>
//       </div>
//     </div>
//   )}
// </div>
//               </div>
//             </div>
//           );
//         })}
//   <div className="bottom-layout-box">
// <div className='inner-layout'>
// <div className="layout-imgbox" ref={el => containerRefs.current[14] = el}>
//             <img   src={`${process.env.PUBLIC_URL}/imgs-calendar/layout31.png`} alt="" />
//           </div>
//           <div className="layout-imgbox" ref={el => containerRefs.current[15] = el}>
//             <img   src={`${process.env.PUBLIC_URL}/imgs-calendar/layout31.png`} alt="" />
//           </div>
// </div>
//   </div>
//     </div>
//   );
// };

// export default AdminPrintCalendar;



import React, { useEffect, useRef, useState } from 'react';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import ReactPreviewLCalendar from './ReactPreviewLCalendar';
import ReactPreviewPCalendar from './ReactPreviewPCalendar';
import { useParams } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import axios from 'axios';
import { generateYearMonths } from './generateYearMonths';
import { Link } from 'react-router-dom';
import moment from 'moment';

const PrintableCalendar = () => {
  const containerRefs = useRef([]);
  const [calendarData, setCalendarData] = useState(null);
  const [googleCalendarEvents, setGoogleCalendarEvents] = useState([]);
  const { calendarId } = useParams();
  const { filepath, apipath } = useAuth();
  const [shippingId, setShippingId] = useState('');

  const [shippingData, setShippingData] = useState({
    firstName: '',
    lastName: '',
    phone: '',
    alternatePhone: '',
    address: '',
    city: '',
    state: '',
    country: '',
    pinCode: '',
    paymentMethod: 'UPI',
    additionalInformation: '',
    calendar_id: calendarId || '',
  });

  const [isLoading, setIsLoading] = useState(false); // Loader state

  const token = localStorage.getItem('token');

  useEffect(() => {
    const fetchCalendarData = async () => {
      try {
        const response = await axios.get(`${apipath}/calendar/details/${calendarId}`);
        setCalendarData(response.data.calendar);
        setShippingId(response.data.calendar.shipping_id);
      } catch (error) {
        console.error('Error fetching calendar data:', error);
      }
    };

    const fetchShipping = async () => {
      try {
        if (shippingId) {
          const response = await axios.get(`${apipath}/shipping/details/${shippingId}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          setShippingData(response.data.shippingInformation);
        }
      } catch (error) {
        console.error('Error fetching shipping details:', error);
      }
    };

    fetchCalendarData();
    fetchShipping();
  }, [calendarId, apipath, shippingId, token]);

  useEffect(() => {
    if (calendarData) {
      const fetchGoogleCalendarEvents = async () => {
        try {
          const months = generateYearMonths(calendarData.startYearMonth);

          const eventsPromises = months.map((month) => {
            const year = month.year();
            const monthIndex = month.month();
            const timeMin = new Date(year, monthIndex, 1).toISOString();
            const timeMax = new Date(year, monthIndex + 1, 0).toISOString();

            return fetch(
              `https://www.googleapis.com/calendar/v3/calendars/en.indian%23holiday%40group.v.calendar.google.com/events?key=AIzaSyAjfBYZVOqpBtwp6Ty55hL9-eXAVD6rKUs&timeMin=${timeMin}&timeMax=${timeMax}`
            )
              .then((response) => response.json())
              .then((data) =>
                data.items.map((event) => ({
                  start: new Date(event.start.date || event.start.dateTime),
                  end: new Date(event.end.date || event.end.dateTime),
                  title: event.summary,
                  month: monthIndex,
                }))
              );
          });

          const allEvents = await Promise.all(eventsPromises);
          const mergedEvents = allEvents
            .flat()
            .sort((a, b) => new Date(a.start) - new Date(b.start));

          setGoogleCalendarEvents(mergedEvents);
        } catch (error) {
          console.error('Error fetching Google Calendar events:', error);
        }
      };

      fetchGoogleCalendarEvents();
    }
  }, [calendarData]);

  const downloadPDF = async () => {
    setIsLoading(true); // Start loading

    try {
      // Check if there are elements to capture
      if (!containerRefs.current.length) {
        console.error('No elements to capture.');
        return;
      }

      const pdf = new jsPDF({
        orientation: 'portrait',
        unit: 'in',
        format: [8.9, 8.8], // PDF size
      });
      // Temporarily add no-border class to upper-layout-box elements
      // const originalBorders = [];
      // containerRefs.current.forEach((el, index) => {
      //   if (el && el.querySelector('.upper-textbox')) {
      //     const upperTextbox = el.querySelector('.upper-textbox');
      //     originalBorders[index] = upperTextbox.style.border;
      //     upperTextbox.style.border = 'none';
      //   }
      // });
      // const originalBorders = [];
      // containerRefs.current.forEach((el, index) => {
      //   if (el && el.querySelector('.upper-textbox')) {
      //     const upperTextbox = el.querySelector('.upper-textbox');
      //     originalBorders[index] = upperTextbox.style.border;
      //     upperTextbox.style.border = 'none';
      //   }
      // });
      const originalBorders = [];
      const originalFontSizes = [];
      
      containerRefs.current.forEach((el, index) => {
        if (el && el.querySelector('.upper-textbox')) {
          const upperTextbox = el.querySelector('.upper-textbox');
          // Save original border styles
          originalBorders[index] = upperTextbox.style.border;
          upperTextbox.style.border = 'none';
    
          // Save original font sizes
          originalFontSizes[index] = {
            h5: upperTextbox.querySelector('h5')?.style.fontSize,
            h6: upperTextbox.querySelector('h6')?.style.fontSize,
            p: upperTextbox.querySelectorAll('p').forEach(p => p.style.fontSize)
          };
    
          // Increase font sizes
          if (upperTextbox.querySelector('h5')) {
            upperTextbox.querySelector('h5').style.fontSize = '27px'; // Increase font size
          }
          if (upperTextbox.querySelector('h6')) {
            upperTextbox.querySelector('h6').style.fontSize = '24px'; // Increase font size
          }
          upperTextbox.querySelectorAll('p').forEach(p => p.style.fontSize = '20px'); // Increase font size
        }
      });
      for (let i = 0; i < containerRefs.current.length; i++) {
        const element = containerRefs.current[i];

        // Ensure that the element is loaded and visible
        if (!element) {
          console.error('Element not found or not visible.');
          continue;
        }

        try {
          const canvas = await html2canvas(element, {
            scale: 2, // Increase the scale for higher quality
            useCORS: true, // Enable CORS to handle image loading issues
            allowTaint: true, // Allow cross-origin images
            logging: false, // Disable logging for a cleaner output
          });

          const imgData = canvas.toDataURL('image/png');

          // Dimensions of the container in inches
          const containerWidth = 8.9; // PDF width
          const containerHeight = 8.8; // PDF height

          // Image dimensions
          const imgWidth = canvas.width / 96; // Convert px to inches
          const imgHeight = canvas.height / 96; // Convert px to inches

          // Calculate aspect ratios
          const imgAspectRatio = imgWidth / imgHeight;
          const containerAspectRatio = containerWidth / containerHeight;

          let finalImgWidth, finalImgHeight;

          if (imgAspectRatio > containerAspectRatio) {
            // Image is wider relative to the container
            finalImgWidth = containerWidth;
            finalImgHeight = containerWidth / imgAspectRatio;
          } else {
            // Image is taller relative to the container
            finalImgHeight = containerHeight;
            finalImgWidth = containerHeight * imgAspectRatio;
          }

          // Center the image on the page
          const x = (containerWidth - finalImgWidth) / 2;
          const y = (containerHeight - finalImgHeight) / 2;

          // Add image to PDF
          if (i > 0) pdf.addPage(); // Add a new page for each subsequent container
          pdf.addImage(imgData, 'PNG', x, y, finalImgWidth, finalImgHeight);
        } catch (error) {
          console.error('Error capturing element:', error);
        }
      }

      // Save the PDF
      pdf.save('download.pdf');
    } catch (error) {
      console.error('Error generating PDF:', error);
    } finally {
      setIsLoading(false); // End loading
    }
  };

  // Utility function to group items into pairs
  const groupItems = (items) => {
    const grouped = [];
    for (let i = 0; i < items.length; i += 2) {
      grouped.push(items.slice(i, i + 2));
    }
    return grouped;
  };

   // Utility function to group items into pairs
   const groupItem = (items) => {
    const grouped = [];
    for (let i = 0; i < items.length; i += 1) {
      grouped.push(items.slice(i, i + 1));
    }
    return grouped;
  };

  const months = generateYearMonths(calendarData ? calendarData.startYearMonth : new Date());
console.log(calendarData)
  return (
    <div>
      <div className="container custom-container sticky-top">
      <div>
        <button className="pre-button">
        <Link to="/" className='back-link'> Back </Link>
          </button>
        </div>
        <div>
          <button onClick={downloadPDF} disabled={isLoading}>Download PDF {isLoading && <span className="loader"></span>} </button>
        </div>
      </div>

      {/* Display loader when isLoading is true */}

      <div className="upper-layout-box" ref={el => containerRefs.current[0] = el}  // Remove border
      >
<div className="upper-textbox">
      <div className='user-detail-name'>
      <h6>PERSONALIZED CALENDAR FOR   <span>{shippingData.firstName}</span> </h6>
      </div>
      <div className="company-basic-detail">
       <div>
       <Link to="/" className='link-class' > www.GiftOnly4U.com </Link>
       </div>
        <img src={`${process.env.PUBLIC_URL}/imgs-calendar/layout11.png`} width={270} height={85} alt="logo-img" />
        <div>
          <Link  to="" className='press-class' >  www.pressideas.com </Link>
        </div>
      </div>
      </div>
</div>

<div className="upper-layout-box" ref={el => containerRefs.current[1] = el}  // Remove border
>
<div className="upper-textbox">
      <div className='shipping-pdf-info'>
      <h5>By Courier </h5>
      <h6>
      To,<br/>
      {shippingData.firstName} {" "}{shippingData.lastName}<br/>
      {shippingData.address}<br/>
      {shippingData.city}{" "}{shippingData.state}{" "}<br/>
      {shippingData.additionalInformation}
      </h6>
      <div className='address-num'>
      <p>Pincode :{shippingData.pinCode}</p>
      <p>+91-{shippingData.phone}</p>
      </div>
      </div>


<div className="undelivered-box">
<h6>If undelivered, return to:</h6>
<p><b>PRESSIdeas</b>, Mr.Mohinder Gupta/Nitin
Building Number 57, G-8, Manjusha Building,
Nehru Place, Delhi - 110019 (Near Main Market)</p>

</div>

      </div>
</div>

     
      {calendarData &&
  months.map((month, index) => {
    const monthName = month.format('MMMM').toLowerCase();
    const monthPicKey = `${monthName}Pic`;
    const viewTypeKey = `${monthName}ViewType`;
    const isPortrait = calendarData[viewTypeKey] === 'Horizontal'; 
    const preview = `${monthName}Preview`;
    const isPreview = calendarData[preview] === 'normal';

    const imageSrc =
      calendarData[monthPicKey] && calendarData[monthPicKey] !== 'null'
        ? filepath + calendarData[monthPicKey]
        : `${process.env.PUBLIC_URL}/imgs-calendar/l.png`;

    const events = [];
    const eventItems = [];
    for (let i = 1; i <= 4; i++) {
      const eventDateKey = `${monthName}Event${i}Date`;
      const eventCaptionKey = `${monthName}Event${i}Caption`;
      const eventColorKey = `${monthName}Event${i}Color`;
      if (calendarData[eventDateKey]) {
        events.push({
          start: new Date(month.year(), month.month(), calendarData[eventDateKey]),
          end: new Date(month.year(), month.month(), calendarData[eventDateKey]),
          title: calendarData[eventCaptionKey] || '',
        });
        eventItems.push(
          <li key={i} style={{ color: calendarData[eventColorKey] }}>
            {calendarData[eventDateKey]}{" "}{calendarData[eventCaptionKey]}
          </li>
        );
      }
    }

    {/* const monthHolidays = googleCalendarEvents.filter(
      (event) => event.month === month.month()
    ); */}
    
    const monthHolidays = googleCalendarEvents.filter(
    (event) => moment(event.start).month() === month.month()
  ).slice(0, 4); 

    const groupedEventItems = groupItems(eventItems);
    const groupedHolidays = groupItem(monthHolidays);

    return (
      
      <div className="print-pdf" key={index}>
        <div className="pdf-inner-pages">

          
          <div
            id={`month-${index}`}
            className="print-pdf"
            key={index}
            ref={(el) => (containerRefs.current[2+index] = el)}
            style={{ position: 'relative', width: '100%', height: '100%' }}
          >
            <div className="watermark">
              <img src={`${process.env.PUBLIC_URL}/imgs-calendar/transparent-logo.png`} alt="Watermark Logo" />
            </div>
            {isPortrait ? (
              <div className="horizontal-container">
              {isPreview ? (
                <div className="img-box1">
                  <img
                    src={imageSrc}
                    alt={month.format('MMMM')}
                    onError={(e) => {
                      e.target.src = `${process.env.PUBLIC_URL}/imgs-calendar/l.png`;
                    }}
                  />
                </div>
              ) : (
                <div className="img-box2">
                  <img
                    src={imageSrc}
                    alt={month.format('MMMM')}
                    onError={(e) => {
                      e.target.src = `${process.env.PUBLIC_URL}/imgs-calendar/l.png`;
                    }}
                  />
                </div>
              )}
                <div className="calendar-box">
                  <div className="horizontal-calendar">
                    <h1>{month.format("MMMM YYYY")}</h1>
                    <ReactPreviewLCalendar
                      year={month.year()}
                      month={month.month()}
                      googleCalendarEvents={googleCalendarEvents.filter(
                        (event) => event.month === month.month()
                      )}
                      calendarData={calendarData}
                    />
                  </div>
                  <div className="horizontal-event">
                    <div className="pdf-event">
                      {groupedEventItems.map((pair, index) => (
                        <p
                          key={index}
                          style={{
                            marginRight: "5px",
                            fontSize: "11px",
                          }}
                        >
                          {pair[0]}
                          {pair[1]}
                        </p>
                      ))}
                    </div>
                    <div className='pdf-holiday'>
                      {groupedHolidays.map((pair, index) => (
                        <li
                          key={index}
                          style={{
                            marginRight: "5px",
                            fontSize: "11px",
                          }}
                        >
                          <span>
                          {moment(pair[0].start).format("DD")}{" "}
                                    {pair[0].title}
                                  </span>
                                  {/* {pair[1] && (
                                    <span>
                                      {moment(pair[1].start).format("DD")}{" "}
                                      {pair[1].title}
                                    </span>
                                  )} */}
                        </li>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="vertical-container">
                {/* <div className="vertical-img-box">
                  <img
                    src={imageSrc}
                    alt={month.format('MMMM')}
                    onError={(e) => {
                      e.target.src = `${process.env.PUBLIC_URL}/imgs-calendar/p.png`;
                    }}
                  />
                </div> */}

                {isPreview ? (
                <div className="vertical-img-box1">
                <img
                    src={imageSrc}
                    alt={month.format('MMMM')}
                    onError={(e) => {
                      e.target.src = `${process.env.PUBLIC_URL}/imgs-calendar/p.png`;
                    }}
                  />
                </div>
              ) : (
                <div className="vertical-img-box2">
                <img
                    src={imageSrc}
                    alt={month.format('MMMM')}
                    onError={(e) => {
                      e.target.src = `${process.env.PUBLIC_URL}/imgs-calendar/p.png`;
                    }}
                  />
                </div>
              )}

                <div className="vertical-calendar-box">
                  <h1>{month.format("MMMM YYYY")}</h1>
                  <div className="vertical-calendar">
                    <ReactPreviewPCalendar
                      year={month.year()}
                      month={month.month()}
                      googleCalendarEvents={googleCalendarEvents.filter(
                        (event) => event.month === month.month()
                      )}
                      view="vertical"
                      calendarData={calendarData}
                    />
                  </div>
                  <div className="vertical-event">
                    <div className='pdf-event'>
                      {groupedEventItems.map((pair, index) => (
                        <p
                          key={index}
                          style={{
                            marginRight: "5px",
                            fontSize: "11px",
                          }}
                        >
                          {pair[0]}
                          {pair[1]}
                        </p>
                      ))}
                    </div>
                    <div className='pdf-holiday'>
                      {groupedHolidays.map((pair, index) => (
                        <li
                          key={index}
                          style={{
                            marginRight: "5px",
                            fontSize: "11px",
                          }}
                        >
                          <span>
                                    {moment(pair[0].start).format("DD")}{" "}
                                    {pair[0].title}
                                  </span>
                                  {/* {pair[1] && (
                                    <span>
                                      {moment(pair[1].start).format("DD")}{" "}
                                      {pair[1].title}
                                    </span>
                                  )} */}
                        </li>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  })}

<div className="bottom-layout-box">
  <div className='inner-layout'>
    <div className="layout-imgbox" ref={el => containerRefs.current[14] = el}>
      <img src={`${process.env.PUBLIC_URL}/imgs-calendar/layout31.png`} alt="" />
    </div>
    <div className="layout-imgbox" ref={el => containerRefs.current[15] = el}>
      <img src={`${process.env.PUBLIC_URL}/imgs-calendar/layout31.png`} alt="" />
    </div>
  </div>
</div>


    </div>
  );
};

export default PrintableCalendar;