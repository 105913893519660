import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import AdminSidebar from './AdminSidebar';
import { useAuth } from '../AuthContext';
import $ from 'jquery';

const ShippingDetails = () => {
  const [shippingDetails, setShippingDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { apipath } = useAuth();
  
  const dataTableRef = useRef();


  useEffect(() => {
    fetchShippingDetails();
  }, []);

  useEffect(() => {
    $(dataTableRef.current).DataTable();
  },[]);

const fetchShippingDetails = async () => {
    try {
      const response = await axios.get(apipath+'/shipping/details');
      setShippingDetails(response.data.shippingInformation);
    } catch (error) {
      console.error('Error fetching shipping:', error);
    }
  };

  const handleDelete = async (id) => {
    try {
      setIsLoading(true);
      await axios.delete(apipath + `shipping/details/${id}`);
      await fetchShippingDetails(); // Refresh orders after deletion
      setIsLoading(false);
    } catch (error) {
      console.error('Error deleting shipping:', error);
      setIsLoading(false);
    }
  };

  return (
    <>
      <AdminSidebar />
      <div className="home">
        <div className="toggle-sidebar" style={{ display: "none" }}>
          <i className="bi bi-menu"></i>
        </div>
        <div className="dashboard">
          <div className="dashboard-header">
            <h1>Shipping Information Management</h1>
          </div>

          <div className="container-fluid">
            {/* <div className="input-group search-group">
              <div className="input-group-prepend search-prepend">
                <span className="input-group-text search-text" id="basic-addon1">
                  <i className="bi bi-search"></i>
                </span>
              </div>
              <input
                type="text"
                className="form-control search-control"
                placeholder="Search by name"
                aria-label="Search"
                aria-describedby="basic-addon1"
              />
            </div> */}
            <div className="row foot-tablerow">
              <div className="col-lg-11 maintable-column mx-auto">
                <div className="container mt-4 overflow-auto">
                  <table id="datatable" className="table" ref={dataTableRef}>
                    <thead>
                      <tr className="tr1">
                        <th>S.No.</th>
                        <th>Name</th>
                        <th>Phone No.</th>
                        <th>User Id</th>
                        <th>Calendar Id</th>
                        <th>Date</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {shippingDetails.map((shipping, index) => (
                        <tr key={shipping._id}>
                          <td className='text-center'>{index + 1}</td>
                          <td>{shipping.firstName}</td>
                          <td>{shipping.phone}</td>
                          <td>{shipping.user_id}</td>
                          <td>{shipping.calendar_id}</td>
                          <td>{new Date(shipping.createdAt).toLocaleDateString()}</td>
                          <td>
                            {/* <i className="fa-regular fa-pen-to-square"></i> &nbsp; */}
                            <i 
                              className="fa-solid fa-trash"
                              onClick={() => handleDelete(shipping._id)}
                              style={{ cursor: 'pointer' }}
                            ></i>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  {isLoading && <p>Loading...</p>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShippingDetails;
