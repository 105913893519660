import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import AdminSidebar from './AdminSidebar';
import iziToast from 'izitoast';
import { useAuth } from '../AuthContext';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import 'bootstrap/dist/js/bootstrap.bundle.min';


const BillingInformation = () => {
  const [pendingOrders, setPendingOrders] = useState([]);
  const [userDetails, setUserDetails] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const { apipath } = useAuth();
  const [billingDetails, setBillingDetails] = useState({
    billingNo: '',
    billingDate: ''
  });
  const [selectedOrderId, setSelectedOrderId] = useState(''); // For storing the selected order ID

  const dataTableRef = useRef();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchPendingOrders();
  if (!loading && pendingOrders.length > 0) {
    $(dataTableRef.current).DataTable();
}
}, [loading, pendingOrders]);

  const fetchPendingOrders = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(`${apipath}/order/details`);
      const orders = response.data.order; // Ensure this is an array
      const filteredOrders = orders.filter(order => order.orderStatus == "Dispatched");

      // Fetch user details for all orders
      const userPromises = filteredOrders.map(order =>
        axios.get(`${apipath}/users/details/${order.user_id}`)
          .then(userResponse => ({
            orderId: order._id,
            userName: userResponse.data.user.name,
            userNumber: userResponse.data.user.phone,
            userEmail: userResponse.data.user.email
          }))
      );

      const users = await Promise.all(userPromises);
      const userDetailsMap = users.reduce((acc, user) => {
        acc[user.orderId] = user;
        return acc;
      }, {});

      setUserDetails(userDetailsMap);
      setPendingOrders(filteredOrders);
      setIsLoading(false); 
      setLoading(false);
    } catch (error) {
      console.error('Error fetching pending orders:', error);
      setIsLoading(false);
      setLoading(false);
    }
  };

  const handleDelete = async (orderId, calendarId) => {
    try {
      setIsLoading(true);

      // Delete the order details
      await axios.delete(`${apipath}/order/details/${orderId}`);
      console.log('Order deleted successfully');

      // Delete the associated calendar details
      await axios.delete(`${apipath}/calendar/details/${calendarId}`);
      console.log('Calendar details deleted successfully');

      // Refresh orders after deletion
      await fetchPendingOrders();

      setIsLoading(false);
    } catch (error) {
      console.error('Error deleting order or calendar details:', error);
      setIsLoading(false);
    }
  };


  const handleToggleStatus = async (id, field, value) => {
    try {
      setIsLoading(true);

      // Prepare update data
      const updateData = { [field]: value };
      if (field === 'pickup' && value) {
        updateData.orderStatus = 'Completed';
      }

      // Send the update request
      const response = await axios.put(`${apipath}/order/details/${id}`, updateData);

      if (response.status === 200) {
        // Fetch the updated order details to get the orderId
        const updatedOrderResponse = await axios.get(`${apipath}/order/details/${id}`);
        const updatedOrder = updatedOrderResponse.data.order; // Ensure correct access to data

        if (!updatedOrder) {
          throw new Error('Order data not found');
        }

        console.log('updatedOrder : ', updatedOrder);

        // Get the current date and time
        const now = new Date();
        const dispatchDate = now.toISOString(); // Use ISO 8601 format for compatibility

        // Construct data for email notifications
        const pickupData = {
          userEmail: userDetails[id]?.userEmail || 'calendar@giftonly4u.com',
          name: userDetails[id]?.userName || 'Default Name',
          courierName: updatedOrder.courierName || 'Default Courier',
          awbNo: updatedOrder.courierId || 'Default AWB Number',
          orderId: updatedOrder.orderId || 'defaultOrderId',
          dispatchDate: dispatchDate, // Use the current date and time
        };

        console.log('pickup Data:', pickupData);

        if (field === 'pickup' && value) {
          await sendEmail(pickupData, `${apipath}/order/pickup`);
        }

        await fetchPendingOrders();
        setIsLoading(false);

        iziToast.success({
          message: "Delivery successful",
          position: "topCenter",
        });
      } else {
        iziToast.error({
          message: "Delivery failed",
          position: "topCenter",
        });
      }
    } catch (error) {
      console.error(`Error updating ${field} status:`, error);
      setIsLoading(false);
      iziToast.error({
        message: "An error occurred",
        position: "topCenter",
      });
    }
  };

  const handleSaveChanges = async () => {
    try {
      // Ensure selectedOrderId is correctly set
      if (!selectedOrderId) {
        throw new Error('No order selected');
      }

      // Ensure all fields are provided
      if (!billingDetails.billingNo || !billingDetails.billingDate) {
        throw new Error('Billing number and date must be filled');
      }

      const updateData = {
        // courierId: billingDetails.courierId,
        // courierName: billingDetails.courierName,
        billingNo: billingDetails.billingNo,       // Include the billing number
        billingDate: billingDetails.billingDate,   // Include the billing date
      };

      // Send the update request
      const response = await axios.put(`${apipath}/order/details/${selectedOrderId}`, updateData);

      if (response.status === 200) {
        iziToast.success({
          message: "Billing details updated successfully",
          position: "topCenter",
        });

        // Optionally fetch updated order details
        await fetchPendingOrders();

        setTimeout(() => {
          window.location.reload();  // Reload after the update
        }, 4000);
      } else {
        iziToast.error({
          message: "Billing update failed",
          position: "topCenter",
        });
      }
    } catch (error) {
      console.error('Error saving billing details:', error);

      iziToast.error({
        message: `Error: ${error.message || "An error occurred"}`,
        position: "topCenter",
      });
    }
  };


  // Utility function for sending email
  const sendEmail = async (data, apiUrl) => {
    try {
      console.log("Sending data:", data);
      const response = await axios.post(apiUrl, data);
      if (response.status !== 201) {
        throw new Error(`Failed to send email: ${response.statusText}`);
      }
    } catch (error) {
      console.error(`Error sending email to ${apiUrl}:`, error);
      iziToast.error({
        message: `Error sending email to ${apiUrl}: ${error.message}`,
        position: "topCenter",
      });
      throw error; // Rethrow to handle in the main function
    }
  };



  return (
    <>
      <AdminSidebar />
      <div className="home">
        <div className="toggle-sidebar" style={{ display: "none" }}>
          <i className="bi bi-menu"></i>
        </div>
        <div className="dashboard">
          <div className="dashboard-header">
            <h1>Billing Orders Information Management</h1>
          </div>
          <div className="container-fluid">
            <div className="row foot-tablerow">
              <div className="col-lg-12 maintable-column mx-auto">
                <div className="container mt-4 overflow-auto">
                  <table id="datatable" className="table" ref={dataTableRef}>
                    <thead>
                      <tr className="tr1">
                        <th>Order S.No.</th>
                        <th>Order Id</th>
                        <th>Date</th>
                        <th>Name</th>
                        <th>Number</th>
                        <th>Payment</th>
                        <th>Confirmed</th>
                        <th>Dispatched</th>
                        {/* <th>OntheWay</th> */}
                        <th>Delivered</th>
                        <th>Billing Detail</th>
                        <th>Status</th>
                        <th>Download</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {pendingOrders.map((order, index) => {
                        const user = userDetails[order._id] || {};
                        return (
                          <tr key={order._id}>
                            <td className='text-center'>{index + 1}</td>
                            <td>{order.orderId}</td>
                            <td>{new Date(order.orderDate).toLocaleDateString()}</td>
                            <td>{user.userName || 'N/A'}</td>
                            <td>{user.userNumber || 'N/A'}</td>
                            <td className="success">{order.paymentStatus}</td>
                            <td>
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  checked={order.orderConfirmed}
                                  onChange={() => handleToggleStatus(order._id, 'orderConfirmed', !order.orderConfirmed)}
                                />
                                <span className="slider round"></span>
                              </label>
                            </td>
                            <td>
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  checked={order.dispatched}
                                  onChange={() => handleToggleStatus(order._id, 'dispatched', !order.dispatched)}
                                />
                                <span className="slider round"></span>
                              </label>
                            </td>
                            <td>
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  checked={order.pickup}
                                  onChange={() => handleToggleStatus(order._id, 'pickup', !order.pickup)}
                                />
                                <span className="slider round"></span>
                              </label>
                            </td>
                            <td>
                              <i
                                className="bi bi-pencil-square"
                                data-bs-toggle="modal"
                                data-bs-target="#exampleModal"
                                onClick={() => {
                                  setSelectedOrderId(order._id);
                                  setBillingDetails({
                                    billingNo: order.billingNo || '',
                                    billingDate: order.billingDate || ''
                                  });
                                }}
                              ></i>
                            </td>
                            <td className="pending"><span>{order.orderStatus}</span></td>
                            <td>
        {order.orderType === "user" ? (
          <Link to={`/adminPrintCalendar/${order.calendar_id}`}>
            <i className="bi bi-download"></i>
          </Link>
        ) : order.orderType === "corporate" ? (
          <Link to={`/corporate-calendar-view-download/${order.calendar_id}`}>
            <i className="bi bi-download"></i>
          </Link>
        ) : null}
      </td>
                            <td>
                              <i className="bi bi-trash" onClick={() => handleDelete(order._id, order.calendar_id)}></i>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          {/* Modal */}
          <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">Billing Details</h5>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                  <div className="mb-3">
                    <label htmlFor="billingNo" className="form-label">Billing No.</label>
                    <input
                      type="text"
                      className="form-control"
                      id="billingNo"
                      value={billingDetails.billingNo || ''} // Make sure billingNo is not undefined
                      onChange={(e) => setBillingDetails({ ...billingDetails, billingNo: e.target.value })}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="billingDate" className="form-label">Billing Date</label>
                    <input
                      type="date"
                      className="form-control"
                      id="billingDate"
                      value={billingDetails.billingDate || ''} // Ensure billingDate has a default value
                      onChange={(e) => setBillingDetails({ ...billingDetails, billingDate: e.target.value })}
                    />
                  </div>

                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                  <button type="button" className="btn btn-primary" onClick={handleSaveChanges}>Save changes</button>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </>
  );
};

export default BillingInformation;
