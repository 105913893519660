import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../AuthContext";
import iziToast from "izitoast";

const AdminSidebar = () => {
  const [isActive, setIsActive] = useState(false);
  const navigate = useNavigate();

  const toggleSidebar = () => {
    setIsActive(!isActive);
  };

  const location = useLocation();

  const adminToken = localStorage.getItem("admintoken");
  const adminname = localStorage.getItem("adminname");
  const adminemail = localStorage.getItem("adminemail");
  const admintoken = localStorage.getItem("admintoken");
  if (admintoken === null || admintoken === "") {
    navigate("/");
  }

  // const { adminLogout } = useAuth();
  // if (!adminToken) {
  //   adminLogout();
  // }
  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("name");
    localStorage.removeItem("userId");
    localStorage.removeItem("email");
    localStorage.removeItem("admintoken");
    localStorage.removeItem("adminname");
    localStorage.removeItem("adminemail");
    localStorage.removeItem("adminId");
    // adminLogout();
    // logout();

    iziToast.success({
      message: "Logout successful",
      position: "topCenter",
    });
    // navigate("/");
    window.location.href = "/login";
  };

  const isTabActive = (path) => {
    return location.pathname === path ? "activeTab" : "";
  };

  return (
    <>
      <div className={`sidebar ${isActive ? "close" : ""}`}>
        <Link className="logo-box text-decoration-none" onClick={toggleSidebar}>
          <img
            src={`${process.env.PUBLIC_URL}/imgs-calendar/logo1.png`}
            alt=""
          />
        </Link>

        <ul className="sidebar-list">
          <li>
            <div className="title">
              <Link
                to="/adminDashboard"
                className={`link ${isTabActive("/dashboard")}`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1em"
                  height="1em"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                    d="M8.557 2.75H4.682A1.932 1.932 0 0 0 2.75 4.682v3.875a1.942 1.942 0 0 0 1.932 1.942h3.875a1.942 1.942 0 0 0 1.942-1.942V4.682A1.942 1.942 0 0 0 8.557 2.75m10.761 0h-3.875a1.942 1.942 0 0 0-1.942 1.932v3.875a1.943 1.943 0 0 0 1.942 1.942h3.875a1.942 1.942 0 0 0 1.932-1.942V4.682a1.932 1.932 0 0 0-1.932-1.932M8.557 13.5H4.682a1.943 1.943 0 0 0-1.932 1.943v3.875a1.932 1.932 0 0 0 1.932 1.932h3.875a1.942 1.942 0 0 0 1.942-1.932v-3.875a1.942 1.942 0 0 0-1.942-1.942m8.818-.001a3.875 3.875 0 1 0 0 7.75a3.875 3.875 0 0 0 0-7.75"
                  />
                </svg>
                <span className="name">Dashboard</span>
              </Link>
            </div>
          </li>
          <li>
            <div className="title">
              <Link
                to="/customerDetails"
                className={`link ${isTabActive("/product")}`}
              >
                <i class="bi bi-people"></i>

                <span className="name">Customer Management</span>
              </Link>
            </div>
          </li>
          <li>
            <div className="title">
              <Link
                to="/pendingOrder"
                className={`link ${isTabActive("/product")}`}
              >
                <i class="bi bi-truck"></i>
                <span className="name">Pending Order Management</span>
              </Link>
            </div>
          </li>
          <li>
            <div className="title">
              <Link
                to="/billingInformation"
                className={`link ${isTabActive("/product")}`}
              >
                <i class="bi bi-cash"></i>
                <span className="name">Billing Management</span>
              </Link>
            </div>
          </li>

          <li>
            <div className="title">
              <Link
                to="/completeOrder"
                className={`link ${isTabActive("/product")}`}
              >
                <i class="bi bi-basket2"></i>
                <span className="name">Completed Order</span>
              </Link>
            </div>
          </li>
          <li>
            <div className="title">
              <Link
                to="/print-management"
                className={`link ${isTabActive("/product")}`}
              >
                <i class="bi bi-briefcase"></i>
                <span className="name">Printer Management </span>
              </Link>
            </div>
          </li>
          <li>
            <div className="title">
              <Link
                to="/corporate"
                className={`link ${isTabActive("/product")}`}
              >
                <i class="bi bi-building-fill"></i>
                <span className="name">Corporate Management</span>
              </Link>
            </div>
          </li>
          {/* <li>
            <div className="title">
              <Link to="/calendarDetails" className={`link ${isTabActive('/product')}`}>
              <i class="bi bi-calendar-check"></i>
                <span className="name">Calendars Management</span>
              </Link>
            </div>
          </li> */}
          {/* <li>
            <div className="title">
              <Link to="/shippingDetails" className={`link ${isTabActive('/product')}`}>
              <i class="bi bi-calendar-check"></i>
                <span className="name">Shipping Management</span>
              </Link>
            </div>
          </li> */}

          <hr />
          <center>
            <h5>CMS Section</h5>
          </center>
          <hr />
          <li>
            <div className="title">
              <Link
                to="/faqsDetails"
                className={`link ${isTabActive("/settings")}`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1em"
                  height="1em"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    d="m9.25 22l-.4-3.2q-.325-.125-.612-.3t-.563-.375L4.7 19.375l-2.75-4.75l2.575-1.95Q4.5 12.5 4.5 12.338v-.675q0-.163.025-.338L1.95 9.375l2.75-4.75l2.975 1.25q.275-.2.575-.375t.6-.3l.4-3.2h5.5l.4 3.2q.325.125.613.3t.562.375l2.975-1.25l2.75 4.75l-2.575 1.95q.025.175.025.338v.674q0 .163-.05.338l2.575 1.95l-2.75 4.75l-2.95-1.25q-.275.2-.575.375t-.6.3l-.4 3.2zm2.8-6.5q1.45 0 2.475-1.025T15.55 12t-1.025-2.475T12.05 8.5q-1.475 0-2.488 1.025T8.55 12t1.013 2.475T12.05 15.5"
                  />
                </svg>
                <span className="name">FAQs Management</span>
              </Link>
            </div>
          </li>
          <hr />
          <li onClick={handleLogout}>
            <div className="title">
              <Link className="link">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1em"
                  height="1em"
                  viewBox="0 0 24 24"
                  onClick={handleLogout}
                >
                  <path
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="m19 12l-4-4m4 4l-4 4m4-4H9m5 9a9 9 0 1 1 0-18"
                  />
                </svg>
                <span className="name" onClick={handleLogout}>
                  Logout
                </span>
              </Link>
            </div>
          </li>
          <li className="side-profile d-flex bg-white">
            {/* <div className="profile-img">
                <img src="assets/profile.png" alt="" />
            </div> */}
            <div className="side-name">
              <h3>{adminname}</h3>
              <h5>{adminemail}</h5>
            </div>
          </li>
        </ul>
      </div>
    </>
  );
};

export default AdminSidebar;
