import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { useAuth } from "../AuthContext";

const Orders = () => {
  const [activeTab, setActiveTab] = useState("pending");
  const [orders, setOrders] = useState([]);
  const { userId } = useParams();
  const navigate = useNavigate(); // Initialize useNavigate
  const { apipath } = useAuth();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  
  // Function to check if user is logged in
  const checkLoginStatus = () => {
   const token = localStorage.getItem('token');
   setIsLoggedIn(!!token); // Set login status based on token presence
};

useEffect(() => {
   checkLoginStatus(); // Check login status on component mount
}, []);

  useEffect(() => {
    fetchOrderDetails();
  }, []);

  const fetchOrderDetails = async () => {
    try {
      const response = await axios.get(
        apipath + `/order/details/user/${userId}`
      );
      setOrders(response.data.order);
    } catch (error) {
      console.error("Error fetching order details:", error);
    }
  };

  const openTab = (tabName) => {
    setActiveTab(tabName);
  };

  const handleOrderClick = (order) => {
    if (order.orderStatus === "Pending") {
      navigate(`/bill-summary/${order.calendar_id}/${order._id}`); // Pass both calendarId and orderId
    } else {
      navigate(`/order-track/${order._id}`);
    }
  };

  const renderOrderCard = (order) => (
    <div className="col-lg-8 mx-auto order-card" key={order._id} onClick={() => handleOrderClick(order)}>
      <div className="order-c">
        <span>Photography Calendar</span>
        <span>Rs. {order.totalPrice}</span>
      </div>
      <p className="order-span">Order ID : <span>{order.orderId}</span></p>
      <p className="order-span">Order Date : <span>{order.orderDate}</span></p>
      <p className="order-span">
    Order Status: 
    <span className={order.orderStatus === 'Pending' ? 'red-text' : ''}>
        {order.orderStatus}
    </span>
</p>
    </div>
  );

  const pendingOrders = orders.filter(order => order.orderStatus !== "Completed");
  const pastOrders = orders.filter(order => order.orderStatus === "Completed");

  return (
    <>
      <Navbar isLoggedIn={isLoggedIn}/>

      <div className="container">
        <div className="col-lg-6 mx-auto image-header">
          <h1>My Orders</h1>
        </div>
        <div className="row">
          <div className="col-lg-4 tab-order mx-auto">
            <button
              className={`order-link ${activeTab === "pending" && "active"}`}
              onClick={() => openTab("pending")}
            >
              Orders in Progress
            </button>
            <button
              className={`order-link ${activeTab === "past" && "active"}`}
              onClick={() => openTab("past")}
            >
              Past Orders
            </button>
          </div>
        </div>
        <div
          id="pending"
          className={`tab-order-content ${activeTab === "pending" ? "active" : ""}`}
        >
          <div className="row order-row pb-5">
            {pendingOrders.map(renderOrderCard)}
          </div>
        </div>
        <div
          id="past"
          className={`tab-order-content ${activeTab === "past" ? "active" : ""}`}
        >
          <div className="row order-row pb-5">
            {pastOrders.map(renderOrderCard)}
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Orders;
