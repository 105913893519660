import React, { useEffect, useRef, useState } from 'react'
import axios from 'axios';
import AdminSidebar from './AdminSidebar';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import $ from 'jquery';

const FaqsDetails = () => {

    const navigate = useNavigate();
    const [faqs, setFaqs] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const { apipath } = useAuth();

    const dataTableRef = useRef();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchFaqs();
    }, []);

    useEffect(() => {
        fetchFaqs();
        if (!loading && faqs.length > 0) {
            $(dataTableRef.current).DataTable();
        }
    }, [loading, faqs]);

    const fetchFaqs = async () => {
        try {
            const response = await axios.get(apipath + '/faqs/details');
            setFaqs(response.data.faqs);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching faqs:', error);
            setLoading(false);
        }
    };

    const handleDelete = async (id) => {
        try {
            setIsLoading(true);
            await axios.delete(apipath + `/faqs/details/${id}`);
            await fetchFaqs(); // Refresh courses after deletion
            setIsLoading(false);
        } catch (error) {
            console.error('Error deleting faqs:', error);
            setIsLoading(false);
        }
    };

    const handleUpdate = async (id) => {
        try {
            // Redirect to the user details page with the productId as a parameter
            navigate(`/faqsEdit/${id}`);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <>
            <AdminSidebar />
            <div className="home">
                <div class="toggle-sidebar" style={{ display: "none" }}>
                    <i class="bi bi-menu"></i>
                </div>
                <div className="dashboard">
                    <div className="dashboard-header">
                        <h1>Faqs Management</h1>
                    </div>

                    <div className="container-fluid">
                    <div className='filter-flex'>
                        {/* <div class="input-group search-group mt-2">
                            <div class="input-group-prepend search-prepend">
                                <span class="input-group-text search-text" id="basic-addon1">
                                    <i className="bi bi-search"></i>
                                </span>
                            </div>
                            <input
                                type="text"
                                class="form-control search-control"
                                placeholder="Search by name"
                                aria-label="Username"
                                aria-describedby="basic-addon1"
                            />
                        </div> */}
                        <div><Link to="/faqsAdd"> <button className="btn btn-primary"> Add FAQs
              </button></Link></div>
            </div>
                        <div className="row foot-tablerow">
                            <div className="col-lg-11 maintable-column mx-auto">
                                <div className="container mt-4 overflow-auto">
                                    <table id="datatable" className="table" ref={dataTableRef}>
                                        <thead>
                                            <tr className="tr1">
                                                <th>Faqs S.No.</th>
                                                <th> Heading</th>
                                                <th>Text</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {faqs.map((faq, index) => (
                                                <tr key={faq._id}>
                                                    <td className='text-center'>{index + 1}</td>
                                                    <td>{faq.heading}</td>
                                                    <td>{faq.text}</td>
                                                    <td>
                                                    <button className="table-btn" onClick={() => handleUpdate(faq._id)}><i className="fa-regular fa-pen-to-square"></i></button> {" "}                          
                                                     <button className="table-btn" onClick={() => handleDelete(faq._id)}><i className="fa-solid fa-trash"></i> </button>
                                                     </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FaqsDetails