import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useAuth } from "../AuthContext";
import axios from "axios";
import { format } from "date-fns";

const Page2 = () => {
  const { calendarId, shippingId } = useParams();
  const [shipping, setShipping] = useState([]);
  const [startMonth, setStartMonth] = useState("");
  const { apipath } = useAuth();

  useEffect(() => {
    fetchShipping();
  }, []);

  const fetchShipping = async () => {
    try {
      const response = await axios.get(
        apipath + `/shipping/details/${shippingId}`
      );
      setShipping(response.data.shippingInformation);
    } catch (error) {
      console.error("Error fetching courses:", error);
    }
  };

  useEffect(() => {
    const fetchCalendarData = async () => {
      try {
        const response = await axios.get(
          apipath + `/calendar/details/${calendarId}`
        );
        const calendarData = response.data.calendar;
        // Extract the startYearMonth and format it
        const startYearMonth = new Date(calendarData.startYearMonth);
        const formattedMonth = format(startYearMonth, "MMMM"); // Format to 'January', 'February', etc.
        setStartMonth(formattedMonth.toLowerCase());
        console.log("Calendar details: ", calendarData);
      } catch (error) {
        console.error("Error fetching calendar data:", error);
      }
    };

    fetchCalendarData();
  }, [calendarId, apipath]);

  return (
    <>
      <div className="container preview">
        <div className="top-buttons">
          <Link to={`/preview1/${calendarId}/${shippingId}`}>
            <button className="pre-button">
              {" "}
              <i className="bi bi-chevron-left"></i> Previous
            </button>
          </Link>
          {/* <Link to={`/preview3/${calendarId}`}> */}
          {/* <Link to={`/january/${calendarId}/${billingId}`}> */}

          <Link to={`/${startMonth}/${calendarId}/${shippingId}`}>
            <button className="next-button">
              {" "}
              Next <i className="bi bi-chevron-right"></i>
            </button>
          </Link>
        </div>
        <div className="preview2-center">
          <div>
            <h2>
              <u>By Courier</u>
            </h2>
            <p className="address-1">
              To, <br />
              {shipping.firstName} {shipping.lastName} <br />
              {shipping.houseNo} {shipping.address}
              <br />
              {shipping.landmark}
              <br />
              {shipping.city} {shipping.state} <br />
              {shipping.additionalInformation}
              <br />
              Pincode :{shipping.pinCode} <br />
              +91-{shipping.phone} <br />
            </p>
          </div>
        </div>
        <div className="d-flex justify-content-end address">
          <p>
            If undelivered, return to: <br />
            <b>PRESSIdeas</b>, Mr.Mohinder Gupta/Nitin <br />
            Building Number 57, G-8, Manjusha Building, <br />
            Nehru Place, Delhi - 110019 <br />
          </p>
        </div>
      </div>
    </>
  );
};

export default Page2;
