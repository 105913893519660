import React, { useState } from "react";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import CouponPopupForm from "./CouponPopupForm";

const CouponPreviewLCalendar = ({
  year,
  month,
  googleCalendarEvents,
  view,
  calendarData,
}) => {
  const startDate = moment([year, month]);
  const endDate = startDate.clone().endOf("month");
  const [selectedDate, setSelectedDate] = useState(null); // State to store the clicked date
  const [currentEvents, setCurrentEvents] = useState([]); // Events for selected date
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  // Generate days for the calendar grid
  const generateCalendarDays = () => {
    const days = [];
    let currentDay = startDate.clone().startOf("week");

    while (currentDay.isBefore(endDate.clone().endOf("week"))) {
      days.push(currentDay.clone());
      currentDay.add(1, "day");
    }

    return days;
  };

  const calendarDays = generateCalendarDays();

  // Group events by date
  const eventsByDate = googleCalendarEvents.reduce((acc, event) => {
    const date = moment(event.start).format("YYYY-MM-DD");
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(event);
    return acc;
  }, {});

  // Function to get the color for a date based on specific events and public holidays
  const getDateColor = (day) => {
    // Ensure that only dates in the current month are highlighted
    if (day.month() !== month) {
      // Return null for dates outside of the current month
      return null;
    }

    const dayOfMonth = day.date();
    const monthName = moment.months()[month].toLowerCase();

    // Check if the day is a public holiday
    const dateString = day.format("YYYY-MM-DD");
    if (eventsByDate[dateString]) {
      return "blue"; // Set color for public holidays
    }

    // Check for other specific events in the current month
    for (let i = 1; i <= 4; i++) {
      if (parseInt(calendarData[`${monthName}Event${i}Date`]) === dayOfMonth) {
        return calendarData[`${monthName}Event${i}Color`] || "#000000"; // Default to black if no color is defined
      }
    }

    // No color for this day
    return null;
  };

  // Handle the click on a calendar date
  const handleDateClick = (day) => {
    // Only allow clicks on dates in the current month
    if (day.month() !== month) {
      return; // Exit early if the date is outside of the current month
    }
    const selectedDateString = day.format("DD");
    setSelectedDate(selectedDateString); // Set the selected date

    // Get the events for the selected date
    const eventsForSelectedDate = eventsByDate[selectedDateString] || [];
    setCurrentEvents(eventsForSelectedDate); // Update the state with current events

    setIsPopupOpen(true); // Open the popup
  };

  // Render table headers
  const renderTableHeaders = () => (
    <tr className="calendar-h">
      {[
        "S",
        "M",
        "T",
        "W",
        "T",
        "F",
        "S",
        "S",
        "M",
        "T",
        "W",
        "T",
        "F",
        "S",
      ].map((day) => (
        <th
          key={day}
          className="imp"
          style={{
            color: "#1a1818",
            backgroundColor: "#afcb06",
            textTransform: "uppercase",
            fontWeight: "bold",
            fontFamily: "Bahnschrift",
            fontSize: "12px",
            // padding: "5px 0px",
          }}
        >
          {day}
        </th>
      ))}
    </tr>
  );

  // Render table cells
  const renderTableCells = () => {
    const weeks = [];
    let days = [];

    calendarDays.forEach((day, index) => {
      if (index % 14 === 0 && days.length > 0) {
        weeks.push(days);
        days = [];
      }

      const date = day.format("YYYY-MM-DD");
      days.push(
        <td
          key={date}
          className={`imp-td ${day.month() !== month ? "gray" : "black"}`}
          onClick={() => handleDateClick(day)}
        >
          {/* <div className="date">{day.date()}</div> */}
          <div className="date" style={{ color: getDateColor(day) }}>
            {day.date()}
          </div>
          {eventsByDate[date] &&
            eventsByDate[date].map((event, idx) => (
              <div key={idx} className="event">
                {/* {event.title} */}
              </div>
            ))}
        </td>
      );
    });

    weeks.push(days); // push the last week

    return weeks.map((week, idx) => <tr key={idx}>{week}</tr>);
  };

  return (
    <div style={{ width: "100%" }}>
      <style>
        {`
                .gray {
                        color: #b9b9b9 !important;
                    }
                    .black {
                        color: #1a1818;
                    }
                .imp {
                  background-color: #afcb06 !important;
                  text-align: center !important;
                  padding: 0px 0px !important;
                  width: 100% !important;
                }
                  .imp-td {
                    font-weight: Bold;
                     fontFamily: Bahnschrift,
                    font-size: 16px;
                    padding:  5px 5px !important;
                    text-align: center !important;
                    border: 1px solid #D9d9D9;
                  }
                  .calendar-h th:nth-child(7n + 1) {
                        color: #e3000e !important;
                    }
                  .imp-td:nth-child(7n + 1) {
                          color: #e3000e;}
                  .imp-td:nth-child(7n + 7) {
                          color: #ef7c00;
                    }
                  .calendar-h th:nth-child(7n + 7) {
                    color: #ef7c00 !important;
                    }

              `}
      </style>
      <table>
        <thead>{renderTableHeaders()}</thead>
        <tbody>{renderTableCells()}</tbody>
      </table>

      {/* Popup Form */}
      <CouponPopupForm
        isOpen={isPopupOpen}
        onClose={() => setIsPopupOpen(false)}
        monthName={moment.months()[month].toLowerCase()}
        currentEvents={currentEvents} // Pass the current events
        selectedDate={selectedDate}
      />
    </div>
  );
};

export default CouponPreviewLCalendar;
