import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { useAuth } from "../AuthContext";

const Landing = () => {
  const navigate = useNavigate();
  const [showItem, setShowItem] = useState(null);
  const { apipath } = useAuth();
  const handleClick = (index) => {
    setShowItem(showItem === index ? null : index);
  };

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  // Function to check if user is logged in
  const checkLoginStatus = () => {
    const token = localStorage.getItem("token");
    setIsLoggedIn(!!token);
  };

  useEffect(() => {
    checkLoginStatus(); // Check login status on component mount
  }, []);

  // Redirect to login if not authenticated
  // Function to handle add to cart click
  const handleProductClick = () => {
    const token = localStorage.getItem("token");

    if (token) {
      navigate(`/calendar-types`);
    } else {
      localStorage.setItem("redirectAfterLogin", `/calendar-types`);
      navigate("/login");
    }
  };

  const [faqs, setFaqs] = useState([]);

  useEffect(() => {
    fetchFaqs();
  }, []);

  const fetchFaqs = async () => {
    try {
      const response = await axios.get(apipath + "/faqs/details");
      setFaqs(response.data.faqs);
    } catch (error) {
      console.error("Error fetching faqs:", error);
    }
  };

  return (
    <>
      <Navbar isLoggedIn={isLoggedIn} />

      {/* MAIN BANNER */}
      <div className="container-fluid main ">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 main-content">
              <h1>
                Let's treasure our good times with a personalized printed desk
                calendar
              </h1>
              <p>
                With our application, you create your calendar online with your
                own pictures and also mark the calendar dates with your personal
                events. We print and courier it to your address of choice in
                India.
              </p>
              <div className="main-button">
                <button
                  onClick={() => handleProductClick()}
                  style={{ cursor: "pointer" }}
                >
                  Create Your Calendar
                </button>
              </div>
              {/* <div className="main-text">
                <p className=" pt-4 p-0">Calendar Size: 6 x 4.5 inches</p>
                <p className="p-0">Calendar Size: 9 x 6 inches</p>
              </div> */}
            </div>
          </div>
        </div>
      </div>

      {/* STEPS */}

      <div className="container steps">
        <div className="steps-header">
          <h1>Personalized Photo Calendars In Just 5 Steps</h1>
        </div>
        <div className="row steps-row">
          <div className="col-lg-3 mx-auto">
            <div className="steps-card">
              <span>1</span>
              <h2>Create Account</h2>
              <p>
                Create a user account with personal information and secure
                credentials{" "}
              </p>
            </div>
          </div>
          <div className="col-lg-3 mx-auto">
            <div className="steps-card">
              <span>2</span>
              <h2>Select Images</h2>
              <p>
                Upload calendar images to store and manage event visuals
                <br />
              </p>
            </div>
          </div>

          <div className="col-lg-3 mx-auto">
            <div className="steps-card">
              <span>3</span>
              <h2>Add Events</h2>
              <p>
                Add events to your calendar to keep track of important dates{" "}
              </p>
            </div>
          </div>
        </div>
        <div className="row steps-row2">
          <div className="col-lg-3">
            <div className="steps-card">
              <span>4</span>
              <h2>Select Layout</h2>
              <p>
                Preview the calendar and select the layout best suited for your
                need
                <br />
                <br />
              </p>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="steps-card">
              <span>5</span>
              <h2>Payment and Print</h2>
              <p>
                Fill the payment details and your calendar will be delivered to
                your doorstep
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* DESIGN */}

      <div className="container-fluid design">
        <div className="steps-header">
          <h1>Design Your Very Own Custom Calendar With Photos</h1>
          <p>
            Keep every birthday, anniversary and festival close to your view!
          </p>
          <div className="row">
            <div className="col-lg-8 mx-auto design-img">
              <img src="imgs-calendar/Group 1000001140.png" alt="" />
            </div>
          </div>
          <div className="main-button main-center">
            <button
              onClick={() => handleProductClick()}
              style={{ cursor: "pointer" }}
            >
              Create Your Calendar
            </button>
          </div>
        </div>
      </div>

      {/* Event */}

      <div className="container-fluid event">
        <div className="steps-header">
          <h1>Special Events Marked To Remember !</h1>
          <p className="col-lg-6 mx-auto">
            Customized calendars with important dates, photos and special
            messages
          </p>
          <div className="row">
            <div className="col-lg-8 mx-auto design-img">
              <img src="imgs-calendar/Group 1000003938.png" alt="" />
            </div>
          </div>
          <div className="main-button main-center mt-5">
            <button
              onClick={() => handleProductClick()}
              style={{ cursor: "pointer" }}
            >
              Create Your Calendar
            </button>
          </div>
        </div>
      </div>

      {/* Service */}

      <div className="container-fluid service">
        <div className="container">
          <div className="steps-header">
            <h1>Our Service</h1>
            <p>
              Your photo calendars come with a 100% guarantee of happiness and
              satisfaction
            </p>
          </div>
          <div className="row service-row">
            <div className="col-lg-3 sc">
              <div className="service-card">
                <img src="imgs-calendar/ion_pricetags.png" alt="" />
                <h2>Low Prices, Best Quality</h2>
                <p>
                  We never compromise on the quality of your photo calendars
                </p>
              </div>
            </div>
            <div className="col-lg-3 sc">
              <div className="service-card">
                <img
                  src="imgs-calendar/solar_delivery-bold-duotone.png"
                  alt=""
                />
                <h2>Fast Shipping</h2>
                <p>
                  We dispatch it by courier and dispatch details are shared via
                  email.
                </p>
              </div>
            </div>
            <div className="col-lg-3 sc">
              <div className="service-card">
                <img
                  src="imgs-calendar/fluent-emoji-high-contrast_wrapped-gift.png"
                  alt=""
                />
                <h2>Returns</h2>
                <p>
                  In case of calendar damage in transit, we shall replace the
                  same FOC.
                </p>
              </div>
            </div>
            <div className="col-lg-3 sc">
              <div className="service-card">
                <img
                  src="imgs-calendar/fluent_shield-task-20-filled.png"
                  alt=""
                />
                <h2>Secure Payment</h2>
                <p>Our PhonePe payment gateway is secured and tamper proof.</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* FAQ */}

      <div className="container faq">
        <div className="steps-header">
          <h1>FAQ's</h1>
        </div>
        <div className="row">
          <div className="mx-auto col-lg-8 faq-c">
            <div className="faq-content">
              {faqs.map((faq, index) => (
                <div className="f-card" key={index}>
                  <div className="d-flex justify-content-between">
                    <h3>{faq.heading}</h3>
                    <i
                      className="bi bi-chevron-down"
                      onClick={() => handleClick(index)}
                    ></i>
                  </div>
                  {showItem === index && (
                    <div>
                      <p>{faq.text}</p>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Landing;
