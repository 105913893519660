import React from "react";

const CalendarPortrait = () => {
  const events = [
    "01 - New Year",
    "02 - Valentine's Day",
    "03 - St. Patrick's Day",
    "04 - Easter",
    "05 - Event",
  ];

  const events2 = [
    "01 - New Year",
    "02 - Valentine's Day",
    "03 - St. Patrick's Day",
    "04 - Easter",
    "05 - Event",
  ];
  return (
    <>
      <div
        className="container"
        style={{
          width: "585.29px",
          height: "441.29px",
          border: "1px solid black",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            width: "566.4px",
            height: "422.4px",
            border: "1px solid black",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
          }}
        >
          <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 10,
          }}
        >
          <img
            src={`${process.env.PUBLIC_URL}/imgs-calendar/transparent-logo.png`}
            alt=""
            style={{
              width:"400px",
              height: "100%",
              transform: "rotate(-15deg)",
              // opacity: "0.8",
            }}
          />
        </div>
          <div style={{ display: "flex", height: "100%", width: "100%" }}>
            <div
              style={{
                width: "50%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <img
                src={`${process.env.PUBLIC_URL}/imgs-calendar/p.png`}
                alt=""
                style={{
                  height: "100%",
                  width: "100%",
                  objectFit: "contain",
                  border: "1px solid black",
                }}
              />
            </div>
            <div style={{ width: "50%", padding: "0 10px" }}>
              <h1
                style={{
                  textAlign: "right",
                  marginBottom: "10px",
                  fontSize: "22px",
                  color: "#e50044",
                  fontWeight: "600",
                }}
              >
                January 2024
              </h1>
              <table
                style={{
                  width: "100%",
                  height: "50%",
                }}
              >
                <style>
                  {`
                .important {
                  background-color: #afcb06 !important; 
                  text-align: center !important;
                  padding: 5px 0 !important;
                }
                  .td {
                    color: #1a1818;
                    font-weight: 600;
                    font-family: "Inter", sans-serif;
                    font-size: 18px;
                    padding: 5px 0 !important;
                    text-align: center !important;
                    border: 1px solid #D9d9D9;
                  }
              `}
                </style>
                <tr>
                  <th
                    className="important"
                    style={{
                      color: "#e3000e",
                      backgroundColor: "#afcb06",
                      textTransform: "uppercase",
                      fontWeight: "bold",
                      fontFamily: '"Inter", sans-serif',
                      fontSize: "20px",
                      // padding: "5px 0px",
                    }}
                  >
                    S
                  </th>
                  <th
                    className="important"
                    style={{
                      color: "#1a1818",
                      backgroundColor: "#afcb06",
                      textTransform: "uppercase",
                      fontWeight: "bold",
                      fontFamily: '"Inter", sans-serif',
                      fontSize: "20px",
                      // padding: "5px 0px ",
                    }}
                  >
                    M
                  </th>
                  <th
                    className="important"
                    style={{
                      color: "#1a1818",
                      backgroundColor: "#afcb06",
                      textTransform: "uppercase",
                      fontWeight: "bold",
                      fontFamily: '"Inter", sans-serif',
                      fontSize: "20px",
                      // padding: "5px 0px",
                    }}
                  >
                    T
                  </th>
                  <th
                    className="important"
                    style={{
                      color: "#1a1818",
                      backgroundColor: "#afcb06",
                      textTransform: "uppercase",
                      fontWeight: "bold",
                      fontFamily: '"Inter", sans-serif',
                      fontSize: "20px",
                      // padding: "5px 0px ",
                    }}
                  >
                    W
                  </th>
                  <th
                    className="important"
                    style={{
                      color: "#1a1818",
                      backgroundColor: "#afcb06",
                      textTransform: "uppercase",
                      fontWeight: "bold",
                      fontFamily: '"Inter", sans-serif',
                      fontSize: "20px",
                      // padding: "5px 0px ",
                    }}
                  >
                    T
                  </th>
                  <th
                    className="important"
                    style={{
                      color: "#1a1818",
                      backgroundColor: "#afcb06",
                      textTransform: "uppercase",
                      fontWeight: "bold",
                      fontFamily: '"Inter", sans-serif',
                      fontSize: "20px",
                    }}
                  >
                    F
                  </th>
                  <th
                    className="important"
                    style={{
                      color: "#ef7c00",
                      backgroundColor: "#afcb06",
                      textTransform: "uppercase",
                      fontWeight: "bold",
                      fontFamily: '"Inter", sans-serif',
                      fontSize: "20px",
                      padding: "0px 5px",
                    }}
                  >
                    S
                  </th>
                </tr>
                <tr>
                  <td className="td" style={{ color: "#e3000e" }}>
                    1
                  </td>
                  <td className="td">2</td>
                  <td className="td">3</td>
                  <td className="td">4</td>
                  <td className="td">5</td>
                  <td className="td">6</td>
                  <td className="td" style={{ color: "#ef7c00" }}>
                    7
                  </td>
                </tr>
                <tr>
                  <td className="td" style={{ color: "#e3000e" }}>
                    8
                  </td>
                  <td className="td">9</td>
                  <td className="td">10</td>
                  <td className="td">11</td>
                  <td className="td">12</td>
                  <td className="td">13</td>
                  <td className="td" style={{ color: "#ef7c00" }}>
                    14
                  </td>
                </tr>
                <tr>
                  <td className="td" style={{ color: "#e3000e" }}>
                    15
                  </td>
                  <td className="td">16</td>
                  <td className="td">17</td>
                  <td className="td">18</td>
                  <td className="td">19</td>
                  <td className="td">20</td>
                  <td className="td" style={{ color: "#ef7c00" }}>
                    21
                  </td>
                </tr>
                <tr>
                  <td className="td" style={{ color: "#e3000e" }}>
                    22
                  </td>
                  <td className="td">23</td>
                  <td className="td">24</td>
                  <td className="td">25</td>
                  <td className="td">26</td>
                  <td className="td">27</td>
                  <td className="td" style={{ color: "#ef7c00" }}>
                    28
                  </td>
                </tr>
                <tr>
                  <td className="td" style={{ color: "#e3000e" }}>
                    29
                  </td>
                  <td className="td">30</td>
                  <td className="td">31</td>
                  <td className="td"></td>
                  <td className="td"></td>
                  <td className="td" style={{}}></td>
                  <td className="td" style={{ color: "#ef7c00" }}></td>
                </tr>
              </table>
              <div
                style={{
                  listStyle: "none",
                  display: "flex",
                  fontSize: "11px",
                  fontWeight: "600",
                  flexWrap: "wrap",
                  marginTop: "20px",
                }}
              >
                {events2.map((event, index) => (
                  <li key={index} style={{ marginRight: "5px" }}>
                    {event}
                  </li>
                ))}
              </div>
              <div
                style={{
                  listStyle: "none",
                  display: "flex",
                  fontSize: "11px",
                  fontWeight: "600",
                  flexWrap: "wrap",
                  marginTop: "5px",
                }}
              >
                {events.map((event, index) => (
                  <li key={index} style={{ marginRight: "5px", color: "blue" }}>
                    {event}
                  </li>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CalendarPortrait;
