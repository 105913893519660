import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useAuth } from "../AuthContext";
import axios from "axios";

const CorporatePreview1 = () => {
  const { calendarId } = useParams();
  const [shipping, setShipping] = useState([]);
  const [calendarData, setCalendarData] = useState("");
  const [shippingId, setShippingId] = useState("");
  const [corporateCode, setCorporateCode] = useState("");
  const [corporateCodeData, setCorporateData] = useState("");
  const { filepath, apipath } = useAuth();
  //   const [shippingData, setShippingData] = useState({
  //     firstName: "",
  //     lastName: "",
  //     phone: "",
  //     alternatePhone: "",
  //     address: "",
  //     city: "",
  //     state: "",
  //     country: "",
  //     pinCode: "",
  //     paymentMethod: "UPI",
  //     additionalInformation: "",
  //     calendar_id: calendarId || "",
  //   });

  const token = localStorage.getItem("token");
  //   useEffect(() => {
  //     fetchShipping();
  //   }, []);

  //   const fetchShipping = async () => {
  //     try {
  //       const response = await axios.get(
  //         apipath + `/shipping/details/${shippingId}`
  //       );
  //       setShipping(response.data.shippingInformation);
  //     } catch (error) {
  //       console.error("Error fetching courses:", error);
  //     }
  //   };

  useEffect(() => {
    const fetchCalendarData = async () => {
      try {
        const response = await axios.get(
          `${apipath}/calendar/details/${calendarId}`
        );
        setCalendarData(response.data.calendar);
        setShippingId(response.data.calendar.shipping_id);
        setCorporateCode(response.data.calendar.corporate_code);
      } catch (error) {
        console.error("Error fetching calendar data:", error);
      }
    };

    const fetchShipping = async () => {
      try {
        if (shippingId) {
          const response = await axios.get(
            `${apipath}/shipping/details/${shippingId}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setShipping(response.data.shippingInformation);
        }
      } catch (error) {
        console.error("Error fetching shipping details:", error);
      }
    };

    const fetchCorporateData = async () => {
      try {
        const response = await axios.get(
          `${apipath}/corporate/couponCode/details/${corporateCode}`
        );
        setCorporateData(response.data.corporate);
      } catch (error) {
        console.error("Error fetching corporate data:", error);
      }
    };

    fetchCalendarData();
    fetchShipping();
    fetchCorporateData();
  }, [calendarId, apipath, shippingId, token]);

  return (
    <>
      <div className="container preview">
        <div className="top-buttons">
          {/* <Link to={`/events/${calendarId}/${billingId}`}> */}
          {/* <Link to={`/shipping-billing/${calendarId}`}>
         <button className="pre-button">
              {" "}
              <i className="bi bi-chevron-left"></i> Previous
            </button>
          </Link> */}
          .
          <Link to={`/corporate-calendar-preview2/${calendarId}/${shippingId}`}>
            <button className="next-button">
              {" "}
              Next <i className="bi bi-chevron-right"></i>
            </button>
          </Link>
        </div>
        <div className="user-detail-name preview-center-coupon">
          <h1>
            PERSONALIZED CALENDAR FOR <span>{shipping.firstName}</span>{" "}
          </h1>
        </div>

        <div className="">
          <div>
            {/* <Link to="/" className="link-class preview-footer">
                {" "}
                www.GiftOnly4U.com{" "}
              </Link> */}
            <div className="text-center">
              <p className="cursive-coupon">
                <strong>With best compliments from</strong>
              </p>
              <img
                src={filepath + calendarData.corporateLogoPic}
                alt="company-logo"
                className="calender-logo-print"
              />
            </div>
            <Link to="" className="press-class-coupon preview-footer">
              {" "}
              {corporateCodeData.corporateWebSiteName}{" "}
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default CorporatePreview1;
