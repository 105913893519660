// import React, { useEffect, useState, useRef } from "react";
// import { Link, useNavigate, useParams } from "react-router-dom";
// import { useAuth } from "../AuthContext";
// import axios from "axios";
// import { format, parseISO } from 'date-fns';
// import iziToast from "izitoast";
// import moment from "moment";
// import ReactPreviewPCalendar from "../PreviewPages/ReactPreviewPCalendar";
// import ReactPreviewLCalendar from "../PreviewPages/ReactPreviewLCalendar";
// import { Modal } from "react-bootstrap";
// import { addMonths } from 'date-fns'; // date-fns can help manage date calculations


// const MonthPage = () => {

//     const [show, setShow] = useState(true);
//     const { calendarId, shippingId, monthName } = useParams();
//     const [shipping, setShipping] = useState([]);
//     const [calendarData, setCalendarData] = useState(null);
//     const [selectedMonth, setSelectedMonth] = useState(0);
//     const [googleCalendarEvents, setGoogleCalendarEvents] = useState([]);
//     const [isFullWidth, setIsFullWidth] = useState(false);
//     const [formData, setFormData] = useState({});
//     const [monthIndex, setMonthIndex] = useState(0);
//     const navigate = useNavigate();
//     const { filepath, apipath } = useAuth();
//     const fileInputRef = useRef(null);
//     const token = localStorage.getItem('token');

//     // Function to handle opening the modal after a 2 second delay
//     useEffect(() => {
//         const timer = setTimeout(() => {
//             setShow(true);
//         }, 1000);

//         // Cleanup the timer if the component is unmounted
//         return () => clearTimeout(timer);
//     }, []);

//     useEffect(() => {
//         const fetchCalendarData = async () => {
//             try {
//                 const response = await axios.get(`${apipath}/calendar/details/${calendarId}`);
//                 const data = response.data.calendar;

//                 setCalendarData(data);
//                 setFormData(data);

//                 // Ensure monthName is defined and not empty before proceeding
//                 if (monthName && typeof monthName === 'string') {
//                     const monthKey = monthName.toLowerCase(); // Safely use toLowerCase() after checking
//                     setSelectedMonth(Object.keys(data).indexOf(monthKey));

//                     // Determine if the view type should be 'full' or 'normal'
//                     setIsFullWidth(data[`${monthKey}Preview`] === "full");
//                 } else {
//                     console.error("Invalid monthName:", monthName); // Log the issue for debugging
//                 }
//             } catch (error) {
//                 console.error("Error fetching calendar data:", error);
//             }
//         };

//         fetchCalendarData();
//     }, [calendarId, apipath, monthName]);

//     useEffect(() => {
//         if (calendarData) {
//             const startYear = new Date(calendarData.startYearMonth).getFullYear();
//             const monthIndex = new Date(Date.parse(`${monthName} 1, ${startYear}`)).getMonth(); // Convert monthName to index

//             const fetchGoogleCalendarEvents = async (year, month) => {
//                 try {
//                     const timeMin = new Date(year, month, 1).toISOString();
//                     const timeMax = new Date(year, month + 1, 0).toISOString();
//                     const response = await fetch(
//                         `https://www.googleapis.com/calendar/v3/calendars/en.indian%23holiday%40group.v.calendar.google.com/events?key=AIzaSyAjfBYZVOqpBtwp6Ty55hL9-eXAVD6rKUs&timeMin=${timeMin}&timeMax=${timeMax}`
//                     );
//                     const data = await response.json();
//                     const events = data.items
//                         .map((event) => ({
//                             start: new Date(event.start.date || event.start.dateTime),
//                             end: new Date(event.end.date || event.end.dateTime),
//                             title: event.summary,
//                         }))
//                         .sort((a, b) => new Date(a.start) - new Date(b.start));
//                     setGoogleCalendarEvents(events);
//                 } catch (error) {
//                     console.error("Error fetching Google Calendar events:", error);
//                 }
//             };

//             fetchGoogleCalendarEvents(startYear, monthIndex);
//         }
//     }, [calendarData, monthName]);

//     const handleButtonClick = () => {
//         if (fileInputRef.current) {
//             fileInputRef.current.click();
//         }
//     };

//     const handleFileChange = async (event) => {
//         const file = event.target.files[0];
//         if (!file) return;

//         const allowedTypes = ['image/jpeg', 'image/png', 'image/gif'];
//         if (!allowedTypes.includes(file.type)) {
//             iziToast.error({
//                 message: 'Please upload a valid image file (JPG, PNG, GIF)',
//                 position: 'topCenter',
//             });
//             return;
//         }
//         if (file.size > 20 * 1024 * 1024) { // 20MB
//             iziToast.error({
//                 message: 'File size should not exceed 20 MB',
//                 position: 'topCenter',
//             });
//             return;
//         }

//         const reader = new FileReader();
//         reader.readAsDataURL(file);
//         reader.onload = async () => {
//             const img = new Image();
//             img.src = reader.result;
//             img.onload = () => {
//                 const width = img.width;
//                 const height = img.height;
//                 const viewType = width > height ? "Horizontal" : (width < height ? "Vertical" : "Square");

//                 setFormData((prevState) => ({
//                     ...prevState,
//                     [`${monthName.toLowerCase()}Pic`]: file,
//                     [`${monthName.toLowerCase()}ViewType`]: viewType,
//                 }));

//                 const formdata = new FormData();
//                 formdata.append(`${monthName.toLowerCase()}Pic`, file);
//                 formdata.append(`${monthName.toLowerCase()}ViewType`, viewType);

//                 axios.put(`${apipath}/calendar/details/${calendarId}`, formdata, {
//                     headers: { Authorization: `Bearer ${token}` }
//                 })
//                     .then(response => {
//                         if (response.status === 200) {
//                             iziToast.success({ message: "Image update successful", position: "topCenter" });
//                             window.location.reload();
//                         } else {
//                             iziToast.error({ message: "Image update failed", position: "topCenter" });
//                         }
//                     })
//                     .catch(error => {
//                         console.error("Error updating image details:", error);
//                         iziToast.error({ message: "An error occurred", position: "topCenter" });
//                     });
//             };
//         };
//     };

//     const handleUpdate = async (newViewType) => {
//         try {
//             const formdata = { [`${monthName.toLowerCase()}Preview`]: newViewType };
//             const response = await axios.put(
//                 `${apipath}/calendar/details/${calendarId}`,
//                 formdata,
//                 {
//                     headers: { 'Authorization': `Bearer ${token}` },
//                 }
//             );

//             if (response.status === 200) {
//                 iziToast.success({
//                     message: "View update successful",
//                     position: "topCenter",
//                 });
//             } else {
//                 iziToast.error({
//                     message: "View update failed",
//                     position: "topCenter",
//                 });
//             }
//         } catch (error) {
//             console.error("Error updating view details:", error);
//             iziToast.error({
//                 message: "An error occurred",
//                 position: "topCenter",
//             });
//         }
//     };

//     // Handle checkbox change to toggle view type
//     const handleCheckboxChange = () => {
//         // Toggle view type
//         const newViewType = isFullWidth ? "normal" : "full";
//         setIsFullWidth(!isFullWidth);

//         // Update form data
//         setFormData((prevFormData) => {
//             const updatedFormData = {
//                 ...prevFormData,
//                 [`${monthName.toLowerCase()}Preview`]: newViewType
//             };

//             // Log the updated form data after setting state
//             console.log(`${monthName.toLowerCase()}Preview: ${updatedFormData[`${monthName.toLowerCase()}Preview`]}`);
//             return updatedFormData;
//         });

//         // Handle update with new view type
//         handleUpdate(newViewType);
//     };

//     if (!calendarData) {
//         return <div>Loading...</div>;
//     }

//     const groupHolidays = (holidays, limit = 4) => {
//         const limitedHolidays = holidays.slice(0, limit); // Limit the number of holidays to 4
//         const grouped = [];

//         for (let i = 0; i < limitedHolidays.length; i += 1) {
//             grouped.push(limitedHolidays.slice(i, i + 1));
//         }

//         return grouped;
//     };

//     const groupedHolidays = groupHolidays(googleCalendarEvents);

//     // Conditional styles for vertical-img-box
//     // const verticalImgStyles = isFullWidth ? { objectFit: "cover", border: "8px solid #d9d9d9" } : { objectFit: "contain" };
//     const verticalImgStyles = isFullWidth ? { objectFit: "cover" } : { objectFit: "contain" };
//     console.log("verticalImgStyles:", verticalImgStyles);
//     console.log("isFullWidth:", isFullWidth);

//     const monthsArray = ["january", "february", "march", "april", "may", "june", "july", "august", "september", "october", "november", "december"];
//     const currentMonthIndex = monthsArray.indexOf(monthName.toLowerCase());
//     const previousMonth = monthsArray[(currentMonthIndex + 11) % 12];
//     const nextMonth = monthsArray[(currentMonthIndex + 1) % 12];

//     const startYearMonth = new Date(calendarData.startYearMonth);
//     const startYear = startYearMonth.getFullYear();
//     const startMonth = startYearMonth.getMonth(); // 0 for January, 1 for February, etc.

//     // Convert month name to lowercase and find its index in the array
//     const monthKey = monthName.toLowerCase();
//     const monthNumber = monthsArray.indexOf(monthKey);
//     console.log("monthKey : ", monthKey);

//     // Generate all months and years based on the startYearMonth
//     const yearMonthArray = [];
//     for (let i = 0; i < 12; i++) {
//         const currentMonthIndex = (startMonth + i) % 12;
//         const currentYear = startYear + Math.floor((startMonth + i) / 12);
//         const monthNameInLoop = monthsArray[currentMonthIndex];
//         yearMonthArray.push({ monthName: monthNameInLoop, currentYear });
//     }

//     // Filter the array to find the selected month
//     const selectedMonthYear = yearMonthArray.find(({ monthName: month }) => month === monthName.toLowerCase());


//     const monthViewType = calendarData[`${monthKey}ViewType`];
//     const imgStyles = isFullWidth ? { objectFit: "cover", width: "100%" } : {};
//     console.log("imgStyles:", imgStyles);
//     console.log("isFullWidth:", isFullWidth);

//     const monthPic = (() => {
//         const fileObject = formData[`${monthKey}Pic`];
//         if (fileObject instanceof File) {
//             return URL.createObjectURL(fileObject);
//         } else if (calendarData[`${monthKey}Pic`] && calendarData[`${monthKey}Pic`] !== "null") {
//             return filepath + calendarData[`${monthKey}Pic`];
//         } else {
//             // return `${process.env.PUBLIC_URL}/imgs-calendar/${monthKey.charAt(0)}.png`;
//         }
//     })();

//     // Extracting and rendering event data dynamically
//     const events = [1, 2, 3, 4].map((i) => ({
//         date: calendarData[`${monthKey}Event${i}Date`],
//         caption: calendarData[`${monthKey}Event${i}Caption`],
//         color: calendarData[`${monthKey}Event${i}Color`],
//     })).filter(event => event.date && event.caption); // Filtering out empty events

//     // Handlers for previous and next buttons
//     const handlePrevious = () => {
//         setMonthIndex(prev => {
//             const newIndex = prev - 1;
//             console.log("New Month Index after Previous Click:", newIndex);
//             return newIndex;
//         });
//         // setShow(true);
//         const timer = setTimeout(() => {
//             setShow(true);
//         }, 1000);

//         // Cleanup the timer if the component is unmounted
//         return () => clearTimeout(timer);
//     };

//     const handleNext = () => {
//         setMonthIndex(prev => {
//             const updatedLastMonthIndex = (startMonth + 11) % 12; // Last month in the 12-month cycle
//             // Compare updatedCurrentMonthIndex with updatedLastMonthIndex
//             if (currentMonthIndex === updatedLastMonthIndex) {
//                 // Navigate if the last month is reached
//                 navigate(`/preview15/${calendarId}/${shippingId}`);
//             } else {
//                 const newIndex = prev + 1;
//                 console.log("New Month Index after Next Click:", newIndex);
//                 return newIndex;
//             }
//         });
//         // setShow(true);
//         const timer = setTimeout(() => {
//             setShow(true);
//         }, 1000);

//         // Cleanup the timer if the component is unmounted
//         return () => clearTimeout(timer);
//     };

//     const handleClose = () => setShow(false);

//     // const handleSkip = () => {
//     //     handleClose(); // Close the modal
//     //     setTimeout(() => {
//     //       navigate(`/${nextMonth}/${calendarId}/${shippingId}`); // Navigate after modal is closed
//     //     }, 200); // Delay to allow modal to close smoothly
//     //   };

//     const capitalizeFirstLetter = (string) => {
//         return string.charAt(0).toUpperCase() + string.slice(1);
//       };

//     return (

//         <div className="container preview">
//             <div className="top-buttons">
//                 {/* Previous Button */}
//                 <Link
//                     to={`/${previousMonth}/${calendarId}/${shippingId}`}
//                     onClick={handlePrevious}
//                     style={{
//                         visibility:
//                             selectedMonthYear?.monthName.toLowerCase() === monthsArray[new Date(calendarData.startYearMonth).getMonth()] &&
//                                 selectedMonthYear?.currentYear === new Date(calendarData.startYearMonth).getFullYear()
//                                 ? "hidden"
//                                 : "visible",
//                     }}
//                 >
//                     <button className="pre-button">
//                         <i className="bi bi-chevron-left"></i> Previous
//                     </button>
//                 </Link>

//                 {/* Next Button */}
//                 <Link to={`/${nextMonth}/${calendarId}/${shippingId}`} onClick={handleNext}>
//                     <button className="next-button">
//                         Next <i className="bi bi-chevron-right"></i>
//                     </button>
//                 </Link>
//             </div>

//             {/* Modal */}
//             <Modal show={show} onHide={handleClose} centered>
//                 <Modal.Header closeButton>
//                     <Modal.Title>Add Events</Modal.Title>
//                 </Modal.Header>
//                 <Modal.Body>
//                     <div className="modal-content1">
//                         <p>Please click on dates in '{capitalizeFirstLetter(monthKey)}' Month to Add Events and Birthdays (Max 4 in a month)</p>
//                     </div>
//                 </Modal.Body>
//                 <Modal.Footer>
//                     <button className="modal-footer-btn" onClick={handleClose}>
//                         Continue
//                     </button>
//                     {/* <Link to={`/${nextMonth}/${calendarId}/${shippingId}`} onClick={handleNext} onClick={handleClose}>
//                         <button className="modal-footer-btn">
//                             Skep
//                         </button>
//                     </Link> */}
//                     {/* <Link onClick={(e) => {
//     e.preventDefault(); // Prevent the default navigation
//     handleSkip(); // Close modal and navigate
//   }}>
//   <button className="modal-footer-btn">
//     Skip
//   </button>
//   </Link> */}
//                 </Modal.Footer>
//             </Modal>



//             <div className="switch-width pt-3 pb-3">
//                 <div className="form-check">
//                     <input
//                         className="form-check-input"
//                         type="checkbox"
//                         value=""
//                         id="flexCheckChecked"
//                         checked={isFullWidth}
//                         onChange={handleCheckboxChange} // Set handler for change event
//                     />
//                     <label className="form-check-label" htmlFor="flexCheckChecked">
//                         Fit Image to Full Width
//                     </label>
//                 </div>
//             </div>

//             {monthViewType === "Horizontal" ? (
//                 <div className="outer-container-box">
//                     <div className="horizontal-container">
//                         <div className="img-box">
//                             <img
//                                 style={imgStyles}
//                                 // src={monthPic}
//                                 src={monthPic ? monthPic : '/imgs-calendar/l.png'} 
//                                 alt="month"
//                             />
//                         </div>
//                         <div className="calendar-box">
//                             <div className="horizontal-calendar">
//                                 {selectedMonthYear ? (
//                                     <h1>{selectedMonthYear.monthName.charAt(0).toUpperCase() + selectedMonthYear.monthName.slice(1)} {selectedMonthYear.currentYear}</h1>
//                                 ) : (
//                                     <p>Month not found.</p>
//                                 )}
//                                 <ReactPreviewLCalendar
//                                     year={startYear}
//                                     month={monthNumber}
//                                     googleCalendarEvents={googleCalendarEvents}
//                                     view={"Horizontal"}
//                                     calendarData={calendarData}
//                                 />

//                             </div>
//                             <div className="horizontal-event">
//                                 <div className="pdf-event">
//                                     <div>
//                                         {events.slice(0, 2).map((event, index) => (
//                                             <li key={index} style={{ color: event.color }}>
//                                                 {event.date} {event.caption}
//                                             </li>
//                                         ))}
//                                     </div>
//                                     <div>
//                                         {events.slice(2, 4).map((event, index) => (
//                                             <li key={index} style={{ color: event.color }}>
//                                                 {event.date} {event.caption}
//                                             </li>
//                                         ))}
//                                     </div>
//                                 </div>

//                                 <div className="pdf-holiday">
//                                     {groupedHolidays.map((pair, index) => (
//                                         <li
//                                             key={index}
//                                             style={{
//                                                 marginRight: "5px",
//                                                 fontSize: "11px",
//                                             }}
//                                         >
//                                             <span>
//                                                 {moment(pair[0].start).format("DD")} {" "}
//                                                 {pair[0].title}
//                                             </span>
//                                         </li>
//                                     ))}
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             ) : (
//                 <div className="outer-container-box">
//                     <div className="vertical-container">
//                         <div className="vertical-img-box">
//                             <img
//                                 // src={monthPic}
//                                 src={monthPic ? monthPic : '/imgs-calendar/p.png'} 
//                                 alt="month" 
//                                 style={verticalImgStyles}
//                             />
//                         </div>
//                         <div className="vertical-calendar-box">
//                             {selectedMonthYear ? (
//                                 <h1>{selectedMonthYear.monthName.charAt(0).toUpperCase() + selectedMonthYear.monthName.slice(1)} {selectedMonthYear.currentYear}</h1>
//                             ) : (
//                                 <p>Month not found.</p>
//                             )}
//                             <div className="vertical-calendar">
//                                 <ReactPreviewPCalendar
//                                     year={startYear}
//                                     month={monthNumber}
//                                     googleCalendarEvents={googleCalendarEvents}
//                                     view={"Vertical"}
//                                     calendarData={calendarData}
//                                 />
//                             </div>
//                             <div className="vertical-event month-event">
//                                 <div className="pdf-event">
//                                     <div>
//                                         {events.slice(0, 2).map((event, index) => (
//                                             <li key={index} style={{ color: event.color }}>
//                                                 {event.date} {event.caption}
//                                             </li>
//                                         ))}
//                                     </div>
//                                     <div>
//                                         {events.slice(2, 4).map((event, index) => (
//                                             <li key={index} style={{ color: event.color }}>
//                                                 {event.date} {event.caption}
//                                             </li>
//                                         ))}
//                                     </div>
//                                 </div>
//                                 <div className="pdf-holiday">
//                                     {groupedHolidays.map((pair, index) => (
//                                         <li
//                                             key={index}
//                                             style={{
//                                                 marginRight: "5px",
//                                                 fontSize: "11px",
//                                             }}
//                                         >
//                                             <span>
//                                                 {moment(pair[0].start).format("DD")} {" "}
//                                                 {pair[0].title}
//                                             </span>
//                                         </li>
//                                     ))}
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>)}


//             <div className="top-buttons pt-4">
//                 <div className="replace-img">
//                     <button onClick={handleButtonClick}>Replace Image {" "}<i class="bi bi-card-image"></i></button>
//                 </div>
//                 <input
//                     type="file"
//                     ref={fileInputRef}
//                     style={{ display: 'none' }}
//                     accept="image/*"
//                     onChange={handleFileChange}
//                 />

//                 <Link to={`/image-edit/${calendarId}`} className="edit-img">
//                     <button><i class="bi bi-pencil"></i> {''} Edit</button>
//                 </Link>
//             </div>
//         </div>

//     );
// };

// export default MonthPage;



import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import { useAuth } from "../AuthContext";
import axios from "axios";
import { format, parseISO } from 'date-fns';
import iziToast from "izitoast";
import moment from "moment";
import ReactPreviewPCalendar from "../PreviewPages/ReactPreviewPCalendar";
import ReactPreviewLCalendar from "../PreviewPages/ReactPreviewLCalendar";
import { Modal } from "react-bootstrap";
import { addMonths } from 'date-fns'; // date-fns can help manage date calculations


const MonthPage = () => {

    const [show, setShow] = useState(true);
    const { calendarId, shippingId, monthName } = useParams();
    const [shipping, setShipping] = useState([]);
    const [calendarData, setCalendarData] = useState(null);
    const [selectedMonth, setSelectedMonth] = useState(0);
    const [googleCalendarEvents, setGoogleCalendarEvents] = useState([]);
    const [isFullWidth, setIsFullWidth] = useState(false);
    const [formData, setFormData] = useState({});
    const [monthIndex, setMonthIndex] = useState(0);
    const navigate = useNavigate();
    const { filepath, apipath } = useAuth();
    const fileInputRef = useRef(null);
    const token = localStorage.getItem('token');
    const params = useLocation();
    const searchParams = new URLSearchParams(params.search);
    const monthlast = searchParams.get("nextMonth");
    console.log("nextMonth", monthlast);


    // Function to handle opening the modal after a 2 second delay
    useEffect(() => {
        const timer = setTimeout(() => {
            setShow(true);
        }, 1000);

        // Cleanup the timer if the component is unmounted
        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        const fetchCalendarData = async () => {
            try {
                const response = await axios.get(`${apipath}/calendar/details/${calendarId}`);
                const data = response.data.calendar;

                setCalendarData(data);
                setFormData(data);

                // Ensure monthName is defined and not empty before proceeding
                if (monthName && typeof monthName === 'string') {
                    const monthKey = monthName.toLowerCase(); // Safely use toLowerCase() after checking
                    setSelectedMonth(Object.keys(data).indexOf(monthKey));

                    // Determine if the view type should be 'full' or 'normal'
                    setIsFullWidth(data[`${monthKey}Preview`] === "full");
                } else {
                    console.error("Invalid monthName:", monthName); // Log the issue for debugging
                }
            } catch (error) {
                console.error("Error fetching calendar data:", error);
            }
        };

        fetchCalendarData();
    }, [calendarId, apipath, monthName]);

    useEffect(() => {
        if (calendarData) {
            const startYear = new Date(calendarData.startYearMonth).getFullYear();
            const monthIndex = new Date(Date.parse(`${monthName} 1, ${startYear}`)).getMonth(); // Convert monthName to index

            const fetchGoogleCalendarEvents = async (year, month) => {
                try {
                    const timeMin = new Date(year, month, 1).toISOString();
                    const timeMax = new Date(year, month + 1, 0).toISOString();
                    const response = await fetch(
                        `https://www.googleapis.com/calendar/v3/calendars/en.indian%23holiday%40group.v.calendar.google.com/events?key=AIzaSyAjfBYZVOqpBtwp6Ty55hL9-eXAVD6rKUs&timeMin=${timeMin}&timeMax=${timeMax}`
                    );
                    const data = await response.json();
                    const events = data.items
                        .map((event) => ({
                            start: new Date(event.start.date || event.start.dateTime),
                            end: new Date(event.end.date || event.end.dateTime),
                            title: event.summary,
                        }))
                        .sort((a, b) => new Date(a.start) - new Date(b.start));
                    setGoogleCalendarEvents(events);
                } catch (error) {
                    console.error("Error fetching Google Calendar events:", error);
                }
            };

            fetchGoogleCalendarEvents(startYear, monthIndex);
        }
    }, [calendarData, monthName]);

    const handleButtonClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        if (!file) return;

        const allowedTypes = ['image/jpeg', 'image/png', 'image/gif'];
        if (!allowedTypes.includes(file.type)) {
            iziToast.error({
                message: 'Please upload a valid image file (JPG, PNG, GIF)',
                position: 'topCenter',
            });
            return;
        }
        if (file.size > 20 * 1024 * 1024) { // 20MB
            iziToast.error({
                message: 'File size should not exceed 20 MB',
                position: 'topCenter',
            });
            return;
        }

        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = async () => {
            const img = new Image();
            img.src = reader.result;
            img.onload = () => {
                const width = img.width;
                const height = img.height;
                const viewType = width > height ? "Horizontal" : (width < height ? "Vertical" : "Square");

                setFormData((prevState) => ({
                    ...prevState,
                    [`${monthName.toLowerCase()}Pic`]: file,
                    [`${monthName.toLowerCase()}ViewType`]: viewType,
                }));

                const formdata = new FormData();
                formdata.append(`${monthName.toLowerCase()}Pic`, file);
                formdata.append(`${monthName.toLowerCase()}ViewType`, viewType);

                axios.put(`${apipath}/calendar/details/${calendarId}`, formdata, {
                    headers: { Authorization: `Bearer ${token}` }
                })
                    .then(response => {
                        if (response.status === 200) {
                            iziToast.success({ message: "Image update successful", position: "topCenter" });
                            window.location.reload();
                        } else {
                            iziToast.error({ message: "Image update failed", position: "topCenter" });
                        }
                    })
                    .catch(error => {
                        console.error("Error updating image details:", error);
                        iziToast.error({ message: "An error occurred", position: "topCenter" });
                    });
            };
        };
    };

    const handleUpdate = async (newViewType) => {
        try {
            const formdata = { [`${monthName.toLowerCase()}Preview`]: newViewType };
            const response = await axios.put(
                `${apipath}/calendar/details/${calendarId}`,
                formdata,
                {
                    headers: { 'Authorization': `Bearer ${token}` },
                }
            );

            if (response.status === 200) {
                iziToast.success({
                    message: "View update successful",
                    position: "topCenter",
                });
            } else {
                iziToast.error({
                    message: "View update failed",
                    position: "topCenter",
                });
            }
        } catch (error) {
            console.error("Error updating view details:", error);
            iziToast.error({
                message: "An error occurred",
                position: "topCenter",
            });
        }
    };

    // Handle checkbox change to toggle view type
    const handleCheckboxChange = () => {
        // Toggle view type
        const newViewType = isFullWidth ? "normal" : "full";
        setIsFullWidth(!isFullWidth);

        // Update form data
        setFormData((prevFormData) => {
            const updatedFormData = {
                ...prevFormData,
                [`${monthName.toLowerCase()}Preview`]: newViewType
            };

            // Log the updated form data after setting state
            console.log(`${monthName.toLowerCase()}Preview: ${updatedFormData[`${monthName.toLowerCase()}Preview`]}`);
            return updatedFormData;
        });

        // Handle update with new view type
        handleUpdate(newViewType);
    };

    if (!calendarData) {
        return <div>Loading...</div>;
    }

    const groupHolidays = (holidays, limit = 4) => {
        const limitedHolidays = holidays.slice(0, limit); // Limit the number of holidays to 4
        const grouped = [];

        for (let i = 0; i < limitedHolidays.length; i += 1) {
            grouped.push(limitedHolidays.slice(i, i + 1));
        }

        return grouped;
    };

    const groupedHolidays = groupHolidays(googleCalendarEvents);

    // Conditional styles for vertical-img-box
    // const verticalImgStyles = isFullWidth ? { objectFit: "cover", border: "8px solid #d9d9d9" } : { objectFit: "contain" };
    const verticalImgStyles = isFullWidth ? { objectFit: "cover" } : { objectFit: "contain" };
    console.log("verticalImgStyles:", verticalImgStyles);
    console.log("isFullWidth:", isFullWidth);

    const monthsArray = ["january", "february", "march", "april", "may", "june", "july", "august", "september", "october", "november", "december"];
    const currentMonthIndex = monthsArray.indexOf(monthName.toLowerCase());
    const previousMonth = monthsArray[(currentMonthIndex + 11) % 12];
    const nextMonth = monthsArray[(currentMonthIndex + 1) % 12];

    const startYearMonth = new Date(calendarData.startYearMonth);
    const startYear = startYearMonth.getFullYear();
    const startMonth = startYearMonth.getMonth(); // 0 for January, 1 for February, etc.

    // Convert month name to lowercase and find its index in the array
    const monthKey = monthName.toLowerCase();
    const monthNumber = monthsArray.indexOf(monthKey);
    console.log("monthKey : ", monthKey);

    // Generate all months and years based on the startYearMonth
    const yearMonthArray = [];
    for (let i = 0; i < 12; i++) {
        const currentMonthIndex = (startMonth + i) % 12;
        const currentYear = startYear + Math.floor((startMonth + i) / 12);
        const monthNameInLoop = monthsArray[currentMonthIndex];
        yearMonthArray.push({ monthName: monthNameInLoop, currentYear });
    }

    // Filter the array to find the selected month
    const selectedMonthYear = yearMonthArray.find(({ monthName: month }) => month === monthName.toLowerCase());

    const monthViewType = calendarData[`${monthKey}ViewType`];
    const imgStyles = isFullWidth ? { objectFit: "cover", width: "100%" } : {};
    console.log("imgStyles:", imgStyles);
    console.log("isFullWidth:", isFullWidth);

    const monthPic = (() => {
        const fileObject = formData[`${monthKey}Pic`];
        if (fileObject instanceof File) {
            return URL.createObjectURL(fileObject);
        } else if (calendarData[`${monthKey}Pic`] && calendarData[`${monthKey}Pic`] !== "null") {
            return filepath + calendarData[`${monthKey}Pic`];
        } else {
            // return `${process.env.PUBLIC_URL}/imgs-calendar/${monthKey.charAt(0)}.png`;
        }
    })();

    // Extracting and rendering event data dynamically
    const events = [1, 2, 3, 4].map((i) => ({
        date: calendarData[`${monthKey}Event${i}Date`],
        caption: calendarData[`${monthKey}Event${i}Caption`],
        color: calendarData[`${monthKey}Event${i}Color`],
    })).filter(event => event.date && event.caption); // Filtering out empty events

    // Handlers for previous and next buttons
    const handlePrevious = () => {
        setMonthIndex(prev => {
            const newIndex = prev - 1;
            console.log("New Month Index after Previous Click:", newIndex);
            return newIndex;
        });
        // setShow(true);
        const timer = setTimeout(() => {
            setShow(true);
        }, 1000);

        // Cleanup the timer if the component is unmounted
        return () => clearTimeout(timer);
    };

    const handleNext = () => {
        const updatedLastMonthIndex = (startMonth + 11) % 12;

        if (currentMonthIndex === updatedLastMonthIndex) {
            console.log("Navigation will now occur");
            navigate(`/preview15/${calendarId}/${shippingId}`);
        } else {
            navigate(`/${nextMonth}/${calendarId}/${shippingId}`);
            setMonthIndex(prev => {
                const newIndex = prev + 1;
                console.log("New Month Index after Next Click:", newIndex);
                return newIndex;
            });

            setTimeout(() => {
                setShow(true);
            }, 1000);
        }
    };

    const handleClose = () => setShow(false);

    // const handleSkip = () => {
    //     handleClose(); // Close the modal
    //     setTimeout(() => {
    //       navigate(`/${nextMonth}/${calendarId}/${shippingId}`); // Navigate after modal is closed
    //     }, 200); // Delay to allow modal to close smoothly
    //   };

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    return (

        <div className="container preview">
            <div className="top-buttons">
                {/* Previous Button */}
                <Link
                    to={`/${previousMonth}/${calendarId}/${shippingId}`}
                    onClick={handlePrevious}
                    style={{
                        visibility:
                            selectedMonthYear?.monthName.toLowerCase() === monthsArray[new Date(calendarData.startYearMonth).getMonth()] &&
                                selectedMonthYear?.currentYear === new Date(calendarData.startYearMonth).getFullYear()
                                ? "hidden"
                                : "visible",
                    }}
                >
                    <button className="pre-button">
                        <i className="bi bi-chevron-left"></i> Previous
                    </button>
                </Link>

                {/* Next Button */}
                {/* <Link to={`/${nextMonth}/${calendarId}/${shippingId}`} onClick={handleNext}>
                    <button className="next-button">
                        Next <i className="bi bi-chevron-right"></i>
                    </button>
                </Link> */}
                <button className="next-button" onClick={handleNext}>
                    Next <i className="bi bi-chevron-right"></i>
                </button>

            </div>

            {/* Modal */}
            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Add Events</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="modal-content1">
                        <p>Please click on dates in '{capitalizeFirstLetter(monthKey)}' Month to Add Events and Birthdays (Max 4 in a month)</p>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="modal-footer-btn" onClick={handleClose}>
                        Continue
                    </button>
                    <button className="modal-footer-btn" onClick={handleNext}>
                        Skip to next month <i className="bi bi-chevron-right"></i>
                    </button>
                    {/* <Link onClick={(e) => {
    e.preventDefault(); // Prevent the default navigation
    handleSkip(); // Close modal and navigate
  }}>
  <button className="modal-footer-btn">
    Skip
  </button>
  </Link> */}
                </Modal.Footer>
            </Modal>



            <div className="switch-width pt-3 pb-3">
                <div className="form-check">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckChecked"
                        checked={isFullWidth}
                        onChange={handleCheckboxChange} // Set handler for change event
                    />
                    <label className="form-check-label" htmlFor="flexCheckChecked">
                        Fit Image to Full Width
                    </label>
                </div>
            </div>

            {monthViewType === "Horizontal" ? (
                <div className="outer-container-box">
                    <div className="horizontal-container">
                        <div className="img-box">
                            <img
                                style={imgStyles}
                                // src={monthPic}
                                src={monthPic ? monthPic : '/imgs-calendar/l.png'}
                                alt="month"
                            />
                        </div>
                        <div className="calendar-box">
                            <div className="horizontal-calendar">
                                {selectedMonthYear ? (
                                    <h1>{selectedMonthYear.monthName.charAt(0).toUpperCase() + selectedMonthYear.monthName.slice(1)} {selectedMonthYear.currentYear}</h1>
                                ) : (
                                    <p>Month not found.</p>
                                )}
                                <ReactPreviewLCalendar
                                    year={startYear}
                                    month={monthNumber}
                                    googleCalendarEvents={googleCalendarEvents}
                                    view={"Horizontal"}
                                    calendarData={calendarData}
                                />

                            </div>
                            <div className="horizontal-event">
                                <div className="pdf-event">
                                    <div>
                                        {events.slice(0, 2).map((event, index) => (
                                            <li key={index} style={{ color: event.color }}>
                                                {event.date} {event.caption}
                                            </li>
                                        ))}
                                    </div>
                                    <div>
                                        {events.slice(2, 4).map((event, index) => (
                                            <li key={index} style={{ color: event.color }}>
                                                {event.date} {event.caption}
                                            </li>
                                        ))}
                                    </div>
                                </div>

                                <div className="pdf-holiday">
                                    {groupedHolidays.map((pair, index) => (
                                        <li
                                            key={index}
                                            style={{
                                                marginRight: "5px",
                                                fontSize: "11px",
                                            }}
                                        >
                                            <span>
                                                {moment(pair[0].start).format("DD")} {" "}
                                                {pair[0].title}
                                            </span>
                                        </li>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="outer-container-box">
                    <div className="vertical-container">
                        <div className="vertical-img-box">
                            <img
                                // src={monthPic}
                                src={monthPic ? monthPic : '/imgs-calendar/p.png'}
                                alt="month"
                                style={verticalImgStyles}
                            />
                        </div>
                        <div className="vertical-calendar-box">
                            {selectedMonthYear ? (
                                <h1>{selectedMonthYear.monthName.charAt(0).toUpperCase() + selectedMonthYear.monthName.slice(1)} {selectedMonthYear.currentYear}</h1>
                            ) : (
                                <p>Month not found.</p>
                            )}
                            <div className="vertical-calendar">
                                <ReactPreviewPCalendar
                                    year={startYear}
                                    month={monthNumber}
                                    googleCalendarEvents={googleCalendarEvents}
                                    view={"Vertical"}
                                    calendarData={calendarData}
                                />
                            </div>
                            <div className="vertical-event month-event">
                                <div className="pdf-event">
                                    <div>
                                        {events.slice(0, 2).map((event, index) => (
                                            <li key={index} style={{ color: event.color }}>
                                                {event.date} {event.caption}
                                            </li>
                                        ))}
                                    </div>
                                    <div>
                                        {events.slice(2, 4).map((event, index) => (
                                            <li key={index} style={{ color: event.color }}>
                                                {event.date} {event.caption}
                                            </li>
                                        ))}
                                    </div>
                                </div>
                                <div className="pdf-holiday">
                                    {groupedHolidays.map((pair, index) => (
                                        <li
                                            key={index}
                                            style={{
                                                marginRight: "5px",
                                                fontSize: "11px",
                                            }}
                                        >
                                            <span>
                                                {moment(pair[0].start).format("DD")} {" "}
                                                {pair[0].title}
                                            </span>
                                        </li>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>)}


            <div className="top-buttons pt-4">
                <div className="replace-img">
                    <button onClick={handleButtonClick}>Replace Image {" "}<i class="bi bi-card-image"></i></button>
                </div>
                <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    accept="image/*"
                    onChange={handleFileChange}
                />

                <Link to={`/image-edit/${calendarId}`} className="edit-img">
                    <button><i class="bi bi-pencil"></i> {''} Edit</button>
                </Link>
            </div>
        </div>

    );
};

export default MonthPage;