import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import FileUpload from "../components/FileUpload";
import { useNavigate, useParams } from "react-router-dom";
import iziToast from "izitoast";
import axios from "axios";
import { useAuth } from "../AuthContext";

const months = [
  "january", "february", "march", "april", "may", "june",
  "july", "august", "september", "october", "november", "december"
];

const initialState = months.reduce((state, month) => {
  state[month] = month.charAt(0).toUpperCase() + month.slice(1);
  state[`${month}Pic`] = null;
  for (let i = 1; i <= 4; i++) {
    state[`${month}Event${i}Date`] = "";
    state[`${month}Event${i}Caption`] = "";
    state[`${month}Event${i}Color`] = "";
  }
  state.errors = {};
  return state;
}, { startYearMonth: "" });

const CouponImageEdit = () => {
   const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loader, setLoader] = useState(false);

  // Function to check if user is logged in
  const checkLoginStatus = () => {
    const token = localStorage.getItem('token');
    setIsLoggedIn(!!token); // Set login status based on token presence
  };

  useEffect(() => {
    checkLoginStatus(); // Check login status on component mount
  }, []);

  const { filepath, apipath } = useAuth();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "auto",
    });
  }, []);

  const [formData, setFormData] = useState(initialState);
  const { calendarId } = useParams();

  useEffect(() => {
    setIsLoggedIn(!!token);
  }, [token]);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
    fetchCalendarEdit();
  }, []);

  const handleFileSelect = (month, file) => {
    // Check if the file is an image and validate its size
    const allowedTypes = ['image/jpeg', 'image/png', 'image/gif'];
    if (!allowedTypes.includes(file.type)) {
      iziToast.error({
        message: 'Please upload a valid image file (JPG, PNG, GIF)',
        position: 'topCenter',
      });
      return;
    }
    if (file.size > 10 * 1024 * 1024) { // 10MB
      iziToast.error({
        message: 'File size should not exceed 10 MB',
        position: 'topCenter',
      });
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event) => {
      const img = new window.Image(); // Explicitly reference window.Image
      img.src = event.target.result;
      img.onload = () => {
        const width = img.width;
        const height = img.height;
        let viewType = "";

        if (width > height) {
          viewType = "Horizontal";
        } else if (width < height) {
          viewType = "Vertical";
        } else {
          viewType = "Square";
        }

        setFormData((prevState) => ({
          ...prevState,
          [`${month}Pic`]: file,
          [`${month}ViewType`]: viewType,
          errors: { ...prevState.errors, [month]: false }, // Clear error if valid
        }));
      };
    };
  };

  const removeFile = (month) => {
    setFormData((prevState) => ({
      ...prevState,
      [`${month}Pic`]: null,
      [`${month}ViewType`]: "",
      errors: { ...prevState.errors, [month]: true }, // Set error state
    }));
  };
  const fetchCalendarEdit = async () => {
    try {
      const response = await axios.get(`${apipath}/calendar/details/${calendarId}`);
      setFormData((prevState) => ({
        ...response.data.calendar,
        errors: prevState.errors // Preserve error state
      }));
    } catch (error) {
      console.error("Error fetching calendar details:", error);
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();

    // Validate that all image fields are filled
    for (let month of months) {
      if (!formData[`${month}Pic`]) {
        iziToast.error({
          message: `Please upload an image for ${month.charAt(0).toUpperCase() + month.slice(1)}`,
          position: 'topCenter',
        });
        return;
      }
    }

    const formdata = new FormData();
    Object.keys(formData).forEach(key => formdata.append(key, formData[key]));

    try {
      const response = await axios.put(`${apipath}/calendar/details/${calendarId}`, formdata, {
        headers: { Authorization: `Bearer ${token}` }
      });
      if (response.status === 200) {
        iziToast.success({ message: "Image update successful", position: "topCenter" });
        navigate(`/coupon-calendar-date/${calendarId}`);
      }else {
        iziToast.error({ message: "Image update failed", position: "topCenter" });
      }
    } catch (error) {
      console.error("Error updating image details:", error);
      iziToast.error({ message: "An error occurred", position: "topCenter" });
    }
  };

  return (
    <>
      <Navbar isLoggedIn={isLoggedIn} />
      <div className="container">
        <div className="col-lg-6 mx-auto image-header">
          <h1>Let’s Make your own personal Calendar</h1>
          <div className="progress-container">
            <div className="progress-line"></div>
            <div className="circle active" id="step1">1</div>
            <div className="circle" id="step2">2</div>
            <div className="circle" id="step3">3</div>
          </div>
        </div>
        <div className="image-content">
          <h3>Upload Your Images :</h3>
          <p className="note">(Ideal image size for landscape : 1920*450px and for portrait : 650*450px)</p>
          <form onSubmit={handleUpdate} className="row image-row py-3">
            {months.map(month => (
              <div className={`col-sm-6 col-md-3 col-lg-3 ${formData.errors[month] ? "error" : ""}`} key={month}>
                <div className="img-input">
                  <FileUpload
                    onFileSelect={file => handleFileSelect(month, file)}
                    currentImage={
                      formData[`${month}Pic`]
                        ? typeof formData[`${month}Pic`] === "string"
                          ? filepath + formData[`${month}Pic`]
                          : URL.createObjectURL(formData[`${month}Pic`])
                        : null
                    }
                  />
                  <h6>{month.charAt(0).toUpperCase() + month.slice(1)}</h6>
                </div>
              </div>
            ))}
            <div className="image-button col-lg-12">
              <button type="submit" onClick={() => setLoader(!loader)}>
                Continue to Next Step
              </button>
            </div>
            {loader && (
              <div className="col-lg-12 loader-c">
                <div className="loader"></div>
              </div>
            )}
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default CouponImageEdit;

