import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import iziToast from "izitoast";
import { useAuth } from "../AuthContext";

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const { apipath } = useAuth();

  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const handleVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch(apipath + "/users/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      const result = await response.json();
      localStorage.setItem("token", result.token);
      console.log(result);

      if (result.success === true) {
        iziToast.success({
          message: "Login successful",
          position: "topCenter",
        });
        if (result.user.role === "admin") {
          localStorage.setItem("admintoken", result.token);
          localStorage.setItem("adminname", result.user.name);
          localStorage.setItem("adminemail", result.user.email);
          localStorage.setItem("adminId", result.user._id);
          navigate("/adminDashboard");
        } 
        else if (result.user.role === "subadmin") {
          localStorage.setItem("subadmintoken", result.token);
          localStorage.setItem("subadminname", result.user.name);
          localStorage.setItem("subadminemail", result.user.email);
          localStorage.setItem("subadminId", result.user._id);
          navigate("/sub-admin-dashboard");
        }else {
          localStorage.setItem("name", result.user.name);
          localStorage.setItem("email", result.user.email);
          localStorage.setItem("userId", result.user._id);
          localStorage.setItem("token", result.token);

          const redirectTo = localStorage.getItem("redirectAfterLogin") || "/";
          localStorage.removeItem("redirectAfterLogin");
          navigate(redirectTo);
        }
      } else {
        iziToast.error({
          message: "Login failed : " + result.error,
          position: "topCenter",
        });
        console.error("Login failed:", result.error);
      }
    } catch (error) {
      iziToast.error({
        message: "Error during login",
        position: "topCenter",
      });
      console.error("Error during login:", error);
    }
  };

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  return (
    <>
      <div className="container-fluid login">
        <div className="row login-row">
          <div className="col-lg-6">
            <div className="img-login">
              <img src="imgs-calendar/password.png" alt="" />
            </div>
          </div>
          <div className="col-lg-6 login-right">
            <h1>Login</h1>
            <p>Welcome back to Press Ideas</p>
            <form onSubmit={handleSubmit}>
              <div className="login-item col-lg-8 mx-auto">
                <label htmlFor="name">Username</label>
                <input
                  type="email"
                  placeholder="Enter your email"
                  name="email"
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="login-item col-lg-8 mx-auto">
                <label htmlFor="name">Password</label>
                <div className="input-group login-group">
                  <input
                    type={showPassword ? "text" : "password"}
                    className="form-control"
                    placeholder="Enter your password"
                    aria-label="password"
                    aria-describedby="basic-addon2"
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                     maxLength="16"
                    required
                  />
                  <div className="input-group-append login-append">
                    <span className="input-group-text" id="basic-addon2">
                      <i
                        className={
                          showPassword ? "bi bi-eye-slash" : "bi bi-eye"
                        }
                        onClick={handleVisibility}
                      ></i>
                    </span>
                  </div>
                </div>

                <div className="forgot d-flex justify-content-between pt-2">
                  <Link to="/reg-verification" className="text-decoration-none">
                    <p>Account Verify ?</p>
                  </Link>
                  <Link to="/forgot" className="text-decoration-none">
                    <p>Forgot Password ?</p>
                  </Link>
                </div>
                {/* <div className="forgot d-flex justify-content-start">
                 
                </div> */}
              </div>
              <div className="login-button col-lg-8 mx-auto">
                {/* <Link to="/profile"> */}
                <button>Login</button>
                {/* </Link> */}
              </div>
              <p className="account col-lg-8 mx-auto">
                Don't have an Account ?{" "}
                <Link to="/register" className="text-decoration-none">
                  <span>Register</span>
                </Link>
              </p>
              {/* <p className="pt-3 col-lg-8 mx-auto">or Login Using</p>
              <div className="d-flex justify-content-center">
                <Link to="/">
                  <img src="imgs-calendar/fb.png" alt="" className="mx-3" />
                </Link>
                <Link to="/">
                  <img src="imgs-calendar/gmail.png" alt="" className="mx-3" />
                </Link>
                <Link to="/otp">
                  <img src="imgs-calendar/text.png" alt="" className="mx-3" />
                </Link>
              </div> */}
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
