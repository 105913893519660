// import React, { useEffect, useState } from "react";
// import { Link, useParams } from "react-router-dom";
// import ReactBigCalendar from "./ReactBigCalendar";
// import { useAuth } from "../AuthContext";

// const Landscape = () => {
//   const { calendarId } = useParams();
//   const { filepath } = useAuth();
//   const [calendarData, setCalendarData] = useState(null);

//   useEffect(() => {
//     const fetchCalendarData = async () => {
//       try {
//         const response = await fetch(`http://localhost:4000/api/v1/calendar/details/${calendarId}`);
//         const data = await response.json();
//         setCalendarData(data.calendar);
//         console.log("Calender details : ", data.calendar);
//       } catch (error) {
//         console.error("Error fetching calendar data:", error);
//       }
//     };

//     fetchCalendarData();
//   }, [calendarId]);

//   if (!calendarData) {
//     return <div>Loading...</div>;
//   }

//   return (
//     <div className="container preview">
//       <div className="top-buttons">
//         <Link to={`/landscape/${calendarId}`}>
//           <button>
//             {" "}
//             <i className="bi bi-chevron-left"></i> Previous
//           </button>
//         </Link>
//         {/* <Link to={`/preview3/${calendarId}`}> */}
//         <Link to={`/portrait/${calendarId}`}>
//           <button>
//             {" "}
//             Next <i className="bi bi-chevron-right"></i>
//           </button>
//         </Link>
//       </div>
//       <div className="container-fluid landscape">
//         <div className="row">
//           <div className="col-lg-12">

//             <img src={calendarData.januaryPic !== '' && calendarData.januaryPic !== null ? filepath + calendarData.januaryPic : `${process.env.PUBLIC_URL}/imgs-calendar/l.png`} alt="January" />
//           </div>
//           <div className="col-lg-12 pt-2">
//             <h1>{calendarData.january} 2024</h1>
//             <div className="land row">
//               <div className="col-lg-8">
//                 <ReactBigCalendar type={2} />
//               </div>
//               <div className="col-lg-4">
//                 <div className="public-holiday-land your-events">
//                   <div><li style={{ color: calendarData.januaryEvent1Color }}>{calendarData.januaryEvent1Date} - {calendarData.januaryEvent1Caption}</li>
//                     <li style={{ color: calendarData.januaryEvent2Color }}>{calendarData.januaryEvent2Date} - {calendarData.januaryEvent2Caption}</li></div>
//                   <div><li style={{ color: calendarData.januaryEvent3Color }}>{calendarData.januaryEvent3Date} - {calendarData.januaryEvent3Caption}</li>
//                     <li style={{ color: calendarData.januaryEvent4Color }}>{calendarData.januaryEvent4Date} - {calendarData.januaryEvent4Caption}</li></div>
//                 </div>
//                 <div className="public-holiday-land">
//                   <div>
//                     <li>01, New Year</li>
//                     <li>01, New Year</li>
//                     <li>01, New Year</li>
//                   </div>
//                   <div>
//                     <li>01, New Year</li>
//                     <li>01, New Year</li>
//                     <li>01, New Year</li>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Landscape;



// import React, { useEffect, useState } from "react";
// import { Link, useParams } from "react-router-dom";
// import ReactBigCalendar from "./ReactBigCalendar";
// import { useAuth } from "../AuthContext";

// const Landscape = () => {
//   const { calendarId } = useParams();
//   const { filepath } = useAuth();
//   const [calendarData, setCalendarData] = useState(null);
//   const [holidays, setHolidays] = useState([]);

//   useEffect(() => {
//     const fetchCalendarData = async () => {
//       try {
//         const response = await fetch(`http://localhost:4000/api/v1/calendar/details/${calendarId}`);
//         const data = await response.json();
//         setCalendarData(data.calendar);
//         console.log("Calendar details: ", data.calendar);
        
//         if (data.calendar.startYearMonth) {
//           const yearMonth = data.calendar.startYearMonth;
//           const year = yearMonth.substring(0, 4);
//           const month = yearMonth.substring(4, 6);
//           fetchHolidays(year, month);
//         }
//       } catch (error) {
//         console.error("Error fetching calendar data:", error);
//       }
//     };

//     const fetchHolidays = async (year, month) => {
//       try {
//         const response = await fetch(`https://www.googleapis.com/calendar/v3/calendars/en.usa%23holiday%40group.v.calendar.google.com/events?key=AIzaSyAjfBYZVOqpBtwp6Ty55hL9-eXAVD6rKUs&timeMin=${year}-${month}-01T00:00:00Z&timeMax=${year}-${(parseInt(month) + 1).toString().padStart(2, '0')}-01T00:00:00Z`);
//         const data = await response.json();
//         setHolidays(data.items || []);
//         console.log("Fetched holidays: ", data.items);
//       } catch (error) {
//         console.error("Error fetching holidays:", error);
//       }
//     };

//     fetchCalendarData();
//   }, [calendarId]);
//   const startYear = new Date(calendarData.startYearMonth).getFullYear();
//   if (!calendarData) {
//     return <div>Loading...</div>;
//   }

//   return (
//     <div className="container preview">
//       <div className="top-buttons">
//         <Link to={`/landscape/${calendarId}`}>
//           <button>
//             {" "}
//             <i className="bi bi-chevron-left"></i> Previous
//           </button>
//         </Link>
//         <Link to={`/portrait/${calendarId}`}>
//           <button>
//             {" "}
//             Next <i className="bi bi-chevron-right"></i>
//           </button>
//         </Link>
//       </div>
//       <div className="container-fluid landscape">
//         <div className="row">
//           <div className="col-lg-12">
//             <img src={calendarData.januaryPic !== '' && calendarData.januaryPic !== null ? filepath + calendarData.januaryPic : `${process.env.PUBLIC_URL}/imgs-calendar/l.png`} alt="January" />
//           </div>
//           <div className="col-lg-12 pt-2">
//             <h1>{calendarData.january} {startYear}</h1>
//             <div className="land row">
//               <div className="col-lg-8">
//                 <ReactBigCalendar type={2} />
//               </div>
//               <div className="col-lg-4">
//                 <div className="public-holiday-land your-events">
//                   <div>
//                     <li style={{ color: calendarData.januaryEvent1Color }}>{calendarData.januaryEvent1Date} - {calendarData.januaryEvent1Caption}</li>
//                     <li style={{ color: calendarData.januaryEvent2Color }}>{calendarData.januaryEvent2Date} - {calendarData.januaryEvent2Caption}</li>
//                   </div>
//                   <div>
//                     <li style={{ color: calendarData.januaryEvent3Color }}>{calendarData.januaryEvent3Date} - {calendarData.januaryEvent3Caption}</li>
//                     <li style={{ color: calendarData.januaryEvent4Color }}>{calendarData.januaryEvent4Date} - {calendarData.januaryEvent4Caption}</li>
//                   </div>
//                 </div>
//                 <div className="public-holiday-land">
//                   {holidays.length > 0 ? holidays.map((holiday, index) => (
//                     <div key={index}>
//                       <li>{new Date(holiday.start.date || holiday.start.dateTime).toLocaleDateString()} - {holiday.summary}</li>
//                     </div>
//                   )) : <div>No holidays found</div>}
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Landscape;

import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import ReactBigCalendar from "./ReactBigCalendar";
import { useAuth } from "../AuthContext";

const Landscape = () => {
  const { calendarId } = useParams();
  const { filepath } = useAuth();
  const [calendarData, setCalendarData] = useState(null);
  const [holidays, setHolidays] = useState([]);
  const [months, setMonths] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(null);

  useEffect(() => {
    const fetchCalendarData = async () => {
      try {
        const response = await fetch(`http://localhost:4000/api/v1/calendar/details/${calendarId}`);
        const data = await response.json();
        setCalendarData(data.calendar);
        console.log("Calendar details: ", data.calendar);

        if (data.calendar.startYearMonth) {
          const yearMonth = new Date(data.calendar.startYearMonth);
          const year = yearMonth.getFullYear();
          const month = yearMonth.getMonth(); // 0-based month

          const generatedMonths = generateMonths(year, month, 12);
          setMonths(generatedMonths);
          setSelectedMonth(generatedMonths[0]); // Set the first month as the selected month
          
          fetchHolidays(year, month);
        }
      } catch (error) {
        console.error("Error fetching calendar data:", error);
      }
    };

    fetchCalendarData();
  }, [calendarId]);

  const fetchHolidays = async (year, month) => {
    try {
      const response = await fetch(
        `https://www.googleapis.com/calendar/v3/calendars/en.indian%23holiday%40group.v.calendar.google.com/events?key=AIzaSyAjfBYZVOqpBtwp6Ty55hL9-eXAVD6rKUs`
      );
      const data = await response.json();
      setHolidays(data.items || []);
      console.log("Fetched holidays: ", data.items);
    } catch (error) {
      console.error("Error fetching holidays:", error);
    }
  };

  const generateMonths = (year, startMonth, count) => {
    const monthsArray = [];
    for (let i = 0; i < count; i++) {
      const date = new Date(year, startMonth + i);
      monthsArray.push({ year: date.getFullYear(), month: date.getMonth() + 1 }); // 1-based month
    }
    return monthsArray;
  };

  const handleMonthChange = (event) => {
    const selected = months.find(m => m.year === parseInt(event.target.value.split("-")[0]) && m.month === parseInt(event.target.value.split("-")[1]));
    setSelectedMonth(selected);
    fetchHolidays(selected.year, selected.month - 1); // Fetch holidays for the selected month
  };

  if (!calendarData || months.length === 0 || !selectedMonth) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container preview">
      <div className="top-buttons">
        <Link to={`/landscape/${calendarId}`}>
          <button>
            {" "}
            <i className="bi bi-chevron-left"></i> Previous
          </button>
        </Link>
        <Link to={`/portrait/${calendarId}`}>
          <button>
            {" "}
            Next <i className="bi bi-chevron-right"></i>
          </button>
        </Link>
      </div>
      <div className="container-fluid landscape">
        <div className="row">
          <div className="col-lg-12">
            <img
              src={
                calendarData.januaryPic !== "" && calendarData.januaryPic !== null
                  ? filepath + calendarData.januaryPic
                  : `${process.env.PUBLIC_URL}/imgs-calendar/l.png`
              }
              alt="January"
            />
          </div>
          <div className="col-lg-12 pt-2">
            <h1>{new Date(selectedMonth.year, selectedMonth.month - 1).toLocaleString('default', { month: 'long' })} {selectedMonth.year}</h1>
            <div>
              <label htmlFor="month-select">Select Month: </label>
              <select id="month-select" onChange={handleMonthChange}>
                {months.map((month, index) => (
                  <option key={index} value={`${month.year}-${month.month.toString().padStart(2, '0')}`}>
                    {new Date(month.year, month.month - 1).toLocaleString('default', { month: 'long' })} {month.year}
                  </option>
                ))}
              </select>
            </div>
            <div className="land row">
              <div className="col-lg-8">
                <ReactBigCalendar type={2} year={selectedMonth.year} month={selectedMonth.month} holidays={holidays} />
              </div>
              <div className="col-lg-4">
                <div className="public-holiday-land your-events">
                  <div>
                    <li style={{ color: calendarData.januaryEvent1Color }}>{calendarData.januaryEvent1Date} - {calendarData.januaryEvent1Caption}</li>
                    <li style={{ color: calendarData.januaryEvent2Color }}>{calendarData.januaryEvent2Date} - {calendarData.januaryEvent2Caption}</li>
                  </div>
                  <div>
                    <li style={{ color: calendarData.januaryEvent3Color }}>{calendarData.januaryEvent3Date} - {calendarData.januaryEvent3Caption}</li>
                    <li style={{ color: calendarData.januaryEvent4Color }}>{calendarData.januaryEvent4Date} - {calendarData.januaryEvent4Caption}</li>
                  </div>
                </div>
                <div className="public-holiday-land">
                  {holidays.length > 0 ? holidays.map((holiday, index) => (
                    <div key={index}>
                      <li>{new Date(holiday.start.date || holiday.start.dateTime).toLocaleDateString()} - {holiday.summary}</li>
                    </div>
                  )) : <div>No holidays found</div>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Landscape;


// // // // // import React, { useEffect, useState } from "react";
// // // // // import { Link, useParams } from "react-router-dom";
// // // // // import ReactBigCalendar from "./ReactBigCalendar";
// // // // // import { useAuth } from "../AuthContext";
// // // // // import moment from "moment";
// // // // // import { generateYearMonths } from "./generateYearMonths";

// // // // // const Landscape = () => {
// // // // //   const { calendarId } = useParams();
// // // // //   const { filepath } = useAuth();
// // // // //   const [calendarData, setCalendarData] = useState(null);
// // // // //   const startDate = "2024-08-01T00:00:00.000Z"; // Replace with your desired start date

// // // // //   useEffect(() => {
// // // // //     const fetchCalendarData = async () => {
// // // // //       try {
// // // // //         const response = await fetch(`https://giftonly4u.com:4000/api/v1/calendar/details/${calendarId}`);
// // // // //         const data = await response.json();
// // // // //         setCalendarData(data.calendar);
// // // // //         console.log("Calendar details : ", data.calendar);
// // // // //       } catch (error) {
// // // // //         console.error("Error fetching calendar data:", error);
// // // // //       }
// // // // //     };

// // // // //     fetchCalendarData();
// // // // //   }, [calendarId]);

// // // // //   if (!calendarData) {
// // // // //     return <div>Loading...</div>;
// // // // //   }

// // // // //   const months = generateYearMonths(startDate);

// // // // //   return (
// // // // //     <div className="container preview">
// // // // //       <div className="top-buttons">
// // // // //         <Link to={`/landscape/${calendarId}`}>
// // // // //           <button>
// // // // //             {" "}
// // // // //             <i className="bi bi-chevron-left"></i> Previous
// // // // //           </button>
// // // // //         </Link>
// // // // //         <Link to={`/portrait/${calendarId}`}>
// // // // //           <button>
// // // // //             {" "}
// // // // //             Next <i className="bi bi-chevron-right"></i>
// // // // //           </button>
// // // // //         </Link>
// // // // //       </div>
// // // // //       <div className="container-fluid landscape">
// // // // //         {months.map((month, index) => (
// // // // //           <div key={index} className="row">
// // // // //             <div className="col-lg-12">
// // // // //               <img
// // // // //                 src={
// // // // //                   calendarData[`${month.format("MMMM").toLowerCase()}Pic`] !== "" &&
// // // // //                   calendarData[`${month.format("MMMM").toLowerCase()}Pic`] !== null
// // // // //                     ? filepath + calendarData[`${month.format("MMMM").toLowerCase()}Pic`]
// // // // //                     : `${process.env.PUBLIC_URL}/imgs-calendar/l.png`
// // // // //                 }
// // // // //                 alt={month.format("MMMM")}
// // // // //               />
// // // // //             </div>
// // // // //             <div className="col-lg-12 pt-2">
// // // // //               <h1>{month.format("MMMM")} 2024</h1>
// // // // //               <div className="land row">
// // // // //                 <div className="col-lg-8">
// // // // //                   <ReactBigCalendar
// // // // //                     type={2}
// // // // //                     events={[
// // // // //                       {
// // // // //                         start: new Date(2024, month.month(), 10),
// // // // //                         end: new Date(2024, month.month(), 10),
// // // // //                         title: calendarData[`${month.format("MMMM").toLowerCase()}Event1Caption`],
// // // // //                       },
// // // // //                       {
// // // // //                         start: new Date(2024, month.month(), 16),
// // // // //                         end: new Date(2024, month.month(), 16),
// // // // //                         title: calendarData[`${month.format("MMMM").toLowerCase()}Event2Caption`],
// // // // //                       },
// // // // //                       {
// // // // //                         start: new Date(2024, month.month(), 22),
// // // // //                         end: new Date(2024, month.month(), 22),
// // // // //                         title: calendarData[`${month.format("MMMM").toLowerCase()}Event3Caption`],
// // // // //                       },
// // // // //                       {
// // // // //                         start: new Date(2024, month.month(), 25),
// // // // //                         end: new Date(2024, month.month(), 25),
// // // // //                         title: calendarData[`${month.format("MMMM").toLowerCase()}Event4Caption`],
// // // // //                       },
// // // // //                     ]}
// // // // //                   />
// // // // //                 </div>
// // // // //                 <div className="col-lg-4">
// // // // //                   <div className="public-holiday-land your-events">
// // // // //                     <div>
// // // // //                       <li style={{ color: calendarData[`${month.format("MMMM").toLowerCase()}Event1Color`] }}>
// // // // //                         {calendarData[`${month.format("MMMM").toLowerCase()}Event1Date`]} - {calendarData[`${month.format("MMMM").toLowerCase()}Event1Caption`]}
// // // // //                       </li>
// // // // //                       <li style={{ color: calendarData[`${month.format("MMMM").toLowerCase()}Event2Color`] }}>
// // // // //                         {calendarData[`${month.format("MMMM").toLowerCase()}Event2Date`]} - {calendarData[`${month.format("MMMM").toLowerCase()}Event2Caption`]}
// // // // //                       </li>
// // // // //                     </div>
// // // // //                     <div>
// // // // //                       <li style={{ color: calendarData[`${month.format("MMMM").toLowerCase()}Event3Color`] }}>
// // // // //                         {calendarData[`${month.format("MMMM").toLowerCase()}Event3Date`]} - {calendarData[`${month.format("MMMM").toLowerCase()}Event3Caption`]}
// // // // //                       </li>
// // // // //                       <li style={{ color: calendarData[`${month.format("MMMM").toLowerCase()}Event4Color`] }}>
// // // // //                         {calendarData[`${month.format("MMMM").toLowerCase()}Event4Date`]} - {calendarData[`${month.format("MMMM").toLowerCase()}Event4Caption`]}
// // // // //                       </li>
// // // // //                     </div>
// // // // //                   </div>
// // // // //                   <div className="public-holiday-land">
// // // // //                     <div>
// // // // //                       <li>01, New Year</li>
// // // // //                       <li>01, New Year</li>
// // // // //                       <li>01, New Year</li>
// // // // //                     </div>
// // // // //                     <div>
// // // // //                       <li>01, New Year</li>
// // // // //                       <li>01, New Year</li>
// // // // //                       <li>01, New Year</li>
// // // // //                     </div>
// // // // //                   </div>
// // // // //                 </div>
// // // // //               </div>
// // // // //             </div>
// // // // //           </div>
// // // // //         ))}
// // // // //       </div>
// // // // //       <hr/>
// // // // //     </div>
// // // // //   );
// // // // // };

// // // // // export default Landscape;

// // // // import React, { useEffect, useState } from "react";
// // // // import { Link, useParams } from "react-router-dom";
// // // // import ReactBigCalendar from "./ReactBigCalendar";
// // // // import { useAuth } from "../AuthContext";
// // // // import moment from "moment";
// // // // import { generateYearMonths } from "./generateYearMonths";

// // // // const Landscape = () => {
// // // //   const { calendarId } = useParams();
// // // //   const { filepath } = useAuth();
// // // //   const [calendarData, setCalendarData] = useState(null);
// // // //   const [startDate, setStartDate] = useState(null);

// // // //   useEffect(() => {
// // // //     const fetchCalendarData = async () => {
// // // //       try {
// // // //         const response = await fetch(`https://giftonly4u.com:4000/api/v1/calendar/details/${calendarId}`);
// // // //         const data = await response.json();
// // // //         setCalendarData(data.calendar);
// // // //         setStartDate(data.calendar.startYearMonth); // Set the startDate from the API response
// // // //         console.log("Calendar details : ", data.calendar);
// // // //       } catch (error) {
// // // //         console.error("Error fetching calendar data:", error);
// // // //       }
// // // //     };

// // // //     fetchCalendarData();
// // // //   }, [calendarId]);

// // // //   if (!calendarData || !startDate) {
// // // //     return <div>Loading...</div>;
// // // //   }

// // // //   const months = generateYearMonths(startDate);

// // // //   return (
// // // //     <div className="container preview">
// // // //       <div className="top-buttons">
// // // //         <Link to={`/landscape/${calendarId}`}>
// // // //           <button>
// // // //             {" "}
// // // //             <i className="bi bi-chevron-left"></i> Previous
// // // //           </button>
// // // //         </Link>
// // // //         <Link to={`/portrait/${calendarId}`}>
// // // //           <button>
// // // //             {" "}
// // // //             Next <i className="bi bi-chevron-right"></i>
// // // //           </button>
// // // //         </Link>
// // // //       </div>
// // // //       <div className="container-fluid landscape">
// // // //         {months.map((month, index) => (
// // // //           <div key={index} className="row">
// // // //             <div className="col-lg-12">
// // // //               <img
// // // //                 src={
// // // //                   calendarData[`${month.format("MMMM").toLowerCase()}Pic`] !== "" &&
// // // //                   calendarData[`${month.format("MMMM").toLowerCase()}Pic`] !== null
// // // //                     ? filepath + calendarData[`${month.format("MMMM").toLowerCase()}Pic`]
// // // //                     : `${process.env.PUBLIC_URL}/imgs-calendar/l.png`
// // // //                 }
// // // //                 alt={month.format("MMMM")}
// // // //               />
// // // //             </div>
// // // //             <div className="col-lg-12 pt-2">
// // // //               <h1>{month.format("MMMM")} 2024</h1>
// // // //               <div className="land row">
// // // //                 <div className="col-lg-8">
// // // //                   <ReactBigCalendar
// // // //                     type={2}
// // // //                     events={[
// // // //                       {
// // // //                         start: new Date(2024, month.month(), 10),
// // // //                         end: new Date(2024, month.month(), 10),
// // // //                         title: calendarData[`${month.format("MMMM").toLowerCase()}Event1Caption`],
// // // //                       },
// // // //                       {
// // // //                         start: new Date(2024, month.month(), 16),
// // // //                         end: new Date(2024, month.month(), 16),
// // // //                         title: calendarData[`${month.format("MMMM").toLowerCase()}Event2Caption`],
// // // //                       },
// // // //                       {
// // // //                         start: new Date(2024, month.month(), 22),
// // // //                         end: new Date(2024, month.month(), 22),
// // // //                         title: calendarData[`${month.format("MMMM").toLowerCase()}Event3Caption`],
// // // //                       },
// // // //                       {
// // // //                         start: new Date(2024, month.month(), 25),
// // // //                         end: new Date(2024, month.month(), 25),
// // // //                         title: calendarData[`${month.format("MMMM").toLowerCase()}Event4Caption`],
// // // //                       },
// // // //                     ]}
// // // //                   />
// // // //                 </div>
// // // //                 <div className="col-lg-4">
// // // //                   <div className="public-holiday-land your-events">
// // // //                     <div>
// // // //                       <li style={{ color: calendarData[`${month.format("MMMM").toLowerCase()}Event1Color`] }}>
// // // //                         {calendarData[`${month.format("MMMM").toLowerCase()}Event1Date`]} - {calendarData[`${month.format("MMMM").toLowerCase()}Event1Caption`]}
// // // //                       </li>
// // // //                       <li style={{ color: calendarData[`${month.format("MMMM").toLowerCase()}Event2Color`] }}>
// // // //                         {calendarData[`${month.format("MMMM").toLowerCase()}Event2Date`]} - {calendarData[`${month.format("MMMM").toLowerCase()}Event2Caption`]}
// // // //                       </li>
// // // //                     </div>
// // // //                     <div>
// // // //                       <li style={{ color: calendarData[`${month.format("MMMM").toLowerCase()}Event3Color`] }}>
// // // //                         {calendarData[`${month.format("MMMM").toLowerCase()}Event3Date`]} - {calendarData[`${month.format("MMMM").toLowerCase()}Event3Caption`]}
// // // //                       </li>
// // // //                       <li style={{ color: calendarData[`${month.format("MMMM").toLowerCase()}Event4Color`] }}>
// // // //                         {calendarData[`${month.format("MMMM").toLowerCase()}Event4Date`]} - {calendarData[`${month.format("MMMM").toLowerCase()}Event4Caption`]}
// // // //                       </li>
// // // //                     </div>
// // // //                   </div>
// // // //                   <div className="public-holiday-land">
// // // //                     <div>
// // // //                       <li>01, New Year</li>
// // // //                       <li>01, New Year</li>
// // // //                       <li>01, New Year</li>
// // // //                     </div>
// // // //                     <div>
// // // //                       <li>01, New Year</li>
// // // //                       <li>01, New Year</li>
// // // //                       <li>01, New Year</li>
// // // //                     </div>
// // // //                   </div>
// // // //                 </div>
// // // //               </div>
// // // //             </div>
// // // //           </div>
// // // //         ))}
// // // //       </div>
// // // //       <hr />
// // // //     </div>
// // // //   );
// // // // };

// // // // export default Landscape;

// // // import React, { useEffect, useState } from "react";
// // // import { Link, useParams } from "react-router-dom";
// // // import ReactBigCalendar from "./ReactBigCalendar";
// // // import { useAuth } from "../AuthContext";
// // // import { generateYearMonths } from "./generateYearMonths";
// // // import moment from "moment";

// // // const Landscape = () => {
// // //   const { calendarId } = useParams();
// // //   const { filepath } = useAuth();
// // //   const [calendarData, setCalendarData] = useState(null);
// // //   const [startDate, setStartDate] = useState(null);

// // //   useEffect(() => {
// // //     const fetchCalendarData = async () => {
// // //       try {
// // //         const response = await fetch(`https://giftonly4u.com:4000/api/v1/calendar/details/${calendarId}`);
// // //         const data = await response.json();
// // //         setCalendarData(data.calendar);
// // //         setStartDate(data.calendar.startYearMonth); // Set the startDate from the API response
// // //         console.log("Calendar details : ", data.calendar);
// // //       } catch (error) {
// // //         console.error("Error fetching calendar data:", error);
// // //       }
// // //     };

// // //     fetchCalendarData();
// // //   }, [calendarId]);

// // //   if (!calendarData || !startDate) {
// // //     return <div>Loading...</div>;
// // //   }

// // //   const months = generateYearMonths(startDate);

// // //   return (
// // //     <div className="container preview">
// // //       <div className="top-buttons">
// // //         <Link to={`/landscape/${calendarId}`}>
// // //           <button>
// // //             {" "}
// // //             <i className="bi bi-chevron-left"></i> Previous
// // //           </button>
// // //         </Link>
// // //         <Link to={`/portrait/${calendarId}`}>
// // //           <button>
// // //             {" "}
// // //             Next <i className="bi bi-chevron-right"></i>
// // //           </button>
// // //         </Link>
// // //       </div>
// // //       <div className="container-fluid landscape">
// // //         {months.map((month, index) => (
// // //           <div key={index} className="row">
// // //             <div className="col-lg-12">
// // //               <img
// // //                 src={
// // //                   calendarData[`${month.format("MMMM").toLowerCase()}Pic`] !== "" &&
// // //                   calendarData[`${month.format("MMMM").toLowerCase()}Pic`] !== null
// // //                     ? filepath + calendarData[`${month.format("MMMM").toLowerCase()}Pic`]
// // //                     : `${process.env.PUBLIC_URL}/imgs-calendar/l.png`
// // //                 }
// // //                 alt={month.format("MMMM")}
// // //               />
// // //             </div>
// // //             <div className="col-lg-12 pt-2">
// // //               <h1>{month.format("MMMM YYYY")}</h1>
// // //               <div className="land row">
// // //                 <div className="col-lg-8">
// // //                   <ReactBigCalendar
// // //                     type={2}
// // //                     events={[
// // //                       {
// // //                         start: new Date(2026, month.month(), calendarData[`${month.format("MMMM").toLowerCase()}Event1Date`]),
// // //                         end: new Date(2026, month.month(), calendarData[`${month.format("MMMM").toLowerCase()}Event1Date`]),
// // //                         title: calendarData[`${month.format("MMMM").toLowerCase()}Event1Caption`],
// // //                       },
// // //                       {
// // //                         start: new Date(2026, month.month(), calendarData[`${month.format("MMMM").toLowerCase()}Event2Date`]),
// // //                         end: new Date(2026, month.month(), calendarData[`${month.format("MMMM").toLowerCase()}Event2Date`]),
// // //                         title: calendarData[`${month.format("MMMM").toLowerCase()}Event2Caption`],
// // //                       },
// // //                       {
// // //                         start: new Date(2026, month.month(), calendarData[`${month.format("MMMM").toLowerCase()}Event3Date`]),
// // //                         end: new Date(2026, month.month(), calendarData[`${month.format("MMMM").toLowerCase()}Event3Date`]),
// // //                         title: calendarData[`${month.format("MMMM").toLowerCase()}Event3Caption`],
// // //                       },
// // //                       {
// // //                         start: new Date(2026, month.month(), calendarData[`${month.format("MMMM").toLowerCase()}Event4Date`]),
// // //                         end: new Date(2026, month.month(), calendarData[`${month.format("MMMM").toLowerCase()}Event4Date`]),
// // //                         title: calendarData[`${month.format("MMMM").toLowerCase()}Event4Caption`],
// // //                       },
// // //                     ]}
// // //                   />
// // //                 </div>
// // //                 <div className="col-lg-4">
// // //                   <div className="public-holiday-land your-events">
// // //                     <div>
// // //                       <li style={{ color: calendarData[`${month.format("MMMM").toLowerCase()}Event1Color`] }}>
// // //                         {calendarData[`${month.format("MMMM").toLowerCase()}Event1Date`]} - {calendarData[`${month.format("MMMM").toLowerCase()}Event1Caption`]}
// // //                       </li>
// // //                       <li style={{ color: calendarData[`${month.format("MMMM").toLowerCase()}Event2Color`] }}>
// // //                         {calendarData[`${month.format("MMMM").toLowerCase()}Event2Date`]} - {calendarData[`${month.format("MMMM").toLowerCase()}Event2Caption`]}
// // //                       </li>
// // //                     </div>
// // //                     <div>
// // //                       <li style={{ color: calendarData[`${month.format("MMMM").toLowerCase()}Event3Color`] }}>
// // //                         {calendarData[`${month.format("MMMM").toLowerCase()}Event3Date`]} - {calendarData[`${month.format("MMMM").toLowerCase()}Event3Caption`]}
// // //                       </li>
// // //                       <li style={{ color: calendarData[`${month.format("MMMM").toLowerCase()}Event4Color`] }}>
// // //                         {calendarData[`${month.format("MMMM").toLowerCase()}Event4Date`]} - {calendarData[`${month.format("MMMM").toLowerCase()}Event4Caption`]}
// // //                       </li>
// // //                     </div>
// // //                   </div>
// // //                   <div className="public-holiday-land">
// // //                     <div>
// // //                       <li>01, New Year</li>
// // //                       <li>01, New Year</li>
// // //                       <li>01, New Year</li>
// // //                     </div>
// // //                     <div>
// // //                       <li>01, New Year</li>
// // //                       <li>01, New Year</li>
// // //                       <li>01, New Year</li>
// // //                     </div>
// // //                   </div>
// // //                 </div>
// // //               </div>
// // //             </div>
// // //           </div>
// // //         ))}
// // //       </div>
// // //       <hr />
// // //     </div>
// // //   );
// // // };

// // // export default Landscape;

// // import React, { useEffect, useState } from "react";
// // import { Link, useParams } from "react-router-dom";
// // import ReactBigCalendar from "./ReactBigCalendar";
// // import { useAuth } from "../AuthContext";
// // import { generateYearMonths } from "./generateYearMonths";
// // import moment from "moment";

// // const Landscape = () => {
// //   const { calendarId } = useParams();
// //   const { filepath } = useAuth();
// //   const [calendarData, setCalendarData] = useState(null);
// //   const [startDate, setStartDate] = useState(null);

// //   useEffect(() => {
// //     const fetchCalendarData = async () => {
// //       try {
// //         const response = await fetch(`https://giftonly4u.com:4000/api/v1/calendar/details/${calendarId}`);
// //         const data = await response.json();
// //         setCalendarData(data.calendar);
// //         setStartDate(data.calendar.startYearMonth); // Set the startDate from the API response
// //         console.log("Calendar details : ", data.calendar);
// //       } catch (error) {
// //         console.error("Error fetching calendar data:", error);
// //       }
// //     };

// //     fetchCalendarData();
// //   }, [calendarId]);

// //   if (!calendarData || !startDate) {
// //     return <div>Loading...</div>;
// //   }

// //   const months = generateYearMonths(startDate);

// //   return (
// //     <div className="container preview">
// //       <div className="top-buttons">
// //         <Link to={`/landscape/${calendarId}`}>
// //           <button>
// //             {" "}
// //             <i className="bi bi-chevron-left"></i> Previous
// //           </button>
// //         </Link>
// //         <Link to={`/portrait/${calendarId}`}>
// //           <button>
// //             {" "}
// //             Next <i className="bi bi-chevron-right"></i>
// //           </button>
// //         </Link>
// //       </div>
// //       <div className="container-fluid landscape">
// //         {months.map((month, index) => {
// //           const monthName = month.format("MMMM").toLowerCase();
// //           return (
// //             <div key={index} className="row">
// //               <div className="col-lg-12">
// //                 <img
// //                   src={
// //                     calendarData[`${monthName}Pic`] !== "" &&
// //                     calendarData[`${monthName}Pic`] !== null
// //                       ? filepath + calendarData[`${monthName}Pic`]
// //                       : `${process.env.PUBLIC_URL}/imgs-calendar/l.png`
// //                   }
// //                   alt={month.format("MMMM")}
// //                 />
// //               </div>
// //               <div className="col-lg-12 pt-2">
// //                 <h1>{month.format("MMMM YYYY")}</h1>
// //                 <div className="land row">
// //                   <div className="col-lg-8">
// //                     <ReactBigCalendar
// //                       type={2}
// //                       events={[
// //                         {
// //                           start: new Date(month.year(), month.month(), calendarData[`${monthName}Event1Date`]),
// //                           end: new Date(month.year(), month.month(), calendarData[`${monthName}Event1Date`]),
// //                           title: calendarData[`${monthName}Event1Caption`],
// //                         },
// //                         {
// //                           start: new Date(month.year(), month.month(), calendarData[`${monthName}Event2Date`]),
// //                           end: new Date(month.year(), month.month(), calendarData[`${monthName}Event2Date`]),
// //                           title: calendarData[`${monthName}Event2Caption`],
// //                         },
// //                         {
// //                           start: new Date(month.year(), month.month(), calendarData[`${monthName}Event3Date`]),
// //                           end: new Date(month.year(), month.month(), calendarData[`${monthName}Event3Date`]),
// //                           title: calendarData[`${monthName}Event3Caption`],
// //                         },
// //                         {
// //                           start: new Date(month.year(), month.month(), calendarData[`${monthName}Event4Date`]),
// //                           end: new Date(month.year(), month.month(), calendarData[`${monthName}Event4Date`]),
// //                           title: calendarData[`${monthName}Event4Caption`],
// //                         },
// //                       ]}
// //                     />
// //                   </div>
// //                   <div className="col-lg-4">
// //                     <div className="public-holiday-land your-events">
// //                       <div>
// //                         <li style={{ color: calendarData[`${monthName}Event1Color`] }}>
// //                           {calendarData[`${monthName}Event1Date`]} - {calendarData[`${monthName}Event1Caption`]}
// //                         </li>
// //                         <li style={{ color: calendarData[`${monthName}Event2Color`] }}>
// //                           {calendarData[`${monthName}Event2Date`]} - {calendarData[`${monthName}Event2Caption`]}
// //                         </li>
// //                       </div>
// //                       <div>
// //                         <li style={{ color: calendarData[`${monthName}Event3Color`] }}>
// //                           {calendarData[`${monthName}Event3Date`]} - {calendarData[`${monthName}Event3Caption`]}
// //                         </li>
// //                         <li style={{ color: calendarData[`${monthName}Event4Color`] }}>
// //                           {calendarData[`${monthName}Event4Date`]} - {calendarData[`${monthName}Event4Caption`]}
// //                         </li>
// //                       </div>
// //                     </div>
// //                     <div className="public-holiday-land">
// //                       <div>
// //                         <li>01, New Year</li>
// //                         <li>01, New Year</li>
// //                         <li>01, New Year</li>
// //                       </div>
// //                       <div>
// //                         <li>01, New Year</li>
// //                         <li>01, New Year</li>
// //                         <li>01, New Year</li>
// //                       </div>
// //                     </div>
// //                   </div>
// //                 </div>
// //               </div>
// //             </div>
// //           );
// //         })}
// //       </div>
// //       <hr />
// //     </div>
// //   );
// // };

// // export default Landscape;

// import React, { useEffect, useState } from "react";
// import { Link, useParams } from "react-router-dom";
// import ReactBigCalendar from "./ReactBigCalendar";
// import { useAuth } from "../AuthContext";
// import { generateYearMonths } from "./generateYearMonths";
// import moment from "moment";

// const Landscape = () => {
//   const { calendarId } = useParams();
//   const { filepath } = useAuth();
//   const [calendarData, setCalendarData] = useState(null);
//   const [startDate, setStartDate] = useState(null);

//   useEffect(() => {
//     const fetchCalendarData = async () => {
//       try {
//         const response = await fetch(`https://giftonly4u.com:4000/api/v1/calendar/details/${calendarId}`);
//         const data = await response.json();
//         setCalendarData(data.calendar);
//         setStartDate(data.calendar.startYearMonth); // Set the startDate from the API response
//         console.log("Calendar details: ", data.calendar);
//       } catch (error) {
//         console.error("Error fetching calendar data:", error);
//       }
//     };

//     fetchCalendarData();
//   }, [calendarId]);

//   if (!calendarData || !startDate) {
//     return <div>Loading...</div>;
//   }

//   const months = generateYearMonths(startDate);

//   return (
//     <div className="container preview">
//       <div className="top-buttons">
//         <Link to={`/landscape/${calendarId}`}>
//           <button>
//             <i className="bi bi-chevron-left"></i> Previous
//           </button>
//         </Link>
//         <Link to={`/portrait/${calendarId}`}>
//           <button>
//             Next <i className="bi bi-chevron-right"></i>
//           </button>
//         </Link>
//       </div>
//       <div className="container-fluid landscape">
//         {months.map((month, index) => {
//           const monthName = month.format("MMMM").toLowerCase();
//           return (
//             <div key={index} className="row">
//               <div className="col-lg-12">
//                 <img
//                   src={
//                     calendarData[`${monthName}Pic`] !== "" &&
//                     calendarData[`${monthName}Pic`] !== null
//                       ? filepath + calendarData[`${monthName}Pic`]
//                       : `${process.env.PUBLIC_URL}/imgs-calendar/l.png`
//                   }
//                   alt={month.format("MMMM")}
//                 />
//               </div>
//               <div className="col-lg-12 pt-2">
//                 <h1>{month.format("MMMM YYYY")}</h1>
//                 <div className="land row">
//                   <div className="col-lg-8">
//                     <ReactBigCalendar
//                       type={2}
//                       events={[
//                         {
//                           start: new Date(month.year(), month.month(), calendarData[`${monthName}Event1Date`]),
//                           end: new Date(month.year(), month.month(), calendarData[`${monthName}Event1Date`]),
//                           title: calendarData[`${monthName}Event1Caption`],
//                         },
//                         {
//                           start: new Date(month.year(), month.month(), calendarData[`${monthName}Event2Date`]),
//                           end: new Date(month.year(), month.month(), calendarData[`${monthName}Event2Date`]),
//                           title: calendarData[`${monthName}Event2Caption`],
//                         },
//                         {
//                           start: new Date(month.year(), month.month(), calendarData[`${monthName}Event3Date`]),
//                           end: new Date(month.year(), month.month(), calendarData[`${monthName}Event3Date`]),
//                           title: calendarData[`${monthName}Event3Caption`],
//                         },
//                         {
//                           start: new Date(month.year(), month.month(), calendarData[`${monthName}Event4Date`]),
//                           end: new Date(month.year(), month.month(), calendarData[`${monthName}Event4Date`]),
//                           title: calendarData[`${monthName}Event4Caption`],
//                         },
//                       ]}
//                     />
//                   </div>
//                   <div className="col-lg-4">
//                     <div className="public-holiday-land your-events">
//                       <div>
//                         <li style={{ color: calendarData[`${monthName}Event1Color`] }}>
//                           {calendarData[`${monthName}Event1Date`]} - {calendarData[`${monthName}Event1Caption`]}
//                         </li>
//                         <li style={{ color: calendarData[`${monthName}Event2Color`] }}>
//                           {calendarData[`${monthName}Event2Date`]} - {calendarData[`${monthName}Event2Caption`]}
//                         </li>
//                       </div>
//                       <div>
//                         <li style={{ color: calendarData[`${monthName}Event3Color`] }}>
//                           {calendarData[`${monthName}Event3Date`]} - {calendarData[`${monthName}Event3Caption`]}
//                         </li>
//                         <li style={{ color: calendarData[`${monthName}Event4Color`] }}>
//                           {calendarData[`${monthName}Event4Date`]} - {calendarData[`${monthName}Event4Caption`]}
//                         </li>
//                       </div>
//                     </div>
//                     <div className="public-holiday-land">
//                       <div>
//                         <li>01, New Year</li>
//                         <li>01, New Year</li>
//                         <li>01, New Year</li>
//                       </div>
//                       <div>
//                         <li>01, New Year</li>
//                         <li>01, New Year</li>
//                         <li>01, New Year</li>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           );
//         })}
//       </div>
//       <hr />
//     </div>
//   );
// };

// export default Landscape;
// import React, { useEffect, useState } from "react";
// import { useParams } from "react-router-dom";
// import PrintableCalendar from "./PrintableCalendar";
// import { useAuth } from "../AuthContext";

// const Landscape = () => {
//   const { calendarId } = useParams();
//   const { filepath,apipath } = useAuth();
//   const [calendarData, setCalendarData] = useState(null);
//   const [startDate, setStartDate] = useState(null);

//   useEffect(() => {
//     const fetchCalendarData = async () => {
//       try {
//         const response = await fetch(apipath + `/calendar/details/${calendarId}`);
//         const data = await response.json();
//         setCalendarData(data.calendar);
//         setStartDate(data.calendar.startYearMonth); // Set the startDate from the API response
//         console.log("Calendar details: ", data.calendar);
//       } catch (error) {
//         console.error("Error fetching calendar data:", error);
//       }
//     };

//     fetchCalendarData();
//   }, [calendarId]);

//   if (!calendarData || !startDate) {
//     return <div>Loading...</div>;
//   }

//   return (
//     <div className="container preview">
//       <PrintableCalendar calendarData={calendarData} startDate={startDate} filepath={filepath} />
//     </div>
//   );
// };

// export default Landscape;
