// // import React, { useEffect, useState } from "react";
// // import Navbar from "../components/Navbar";
// // import Footer from "../components/Footer";
// // import { Link, useParams } from "react-router-dom";
// // import { useAuth } from "../AuthContext";
// // import iziToast from "izitoast";

// // const Congrats = () => {
// //   const { calendarId } = useParams();
// //   const { apipath } = useAuth();
// //   const userEmail = localStorage.getItem('email');
// //   const userId = localStorage.getItem('userId');
// //   console.log("userEmail :",userEmail);
  

// //   useEffect(() => {
// //     window.scrollTo({
// //       top: 0,
// //       left: 0,
// //       behavior: "auto",
// //     });

// //     // Auto send confirmation email
// //     if (userEmail) {
// //       fetch(`${apipath}/order/confirm`, {
// //         method: "POST",
// //         headers: {
// //           "Content-Type": "application/json",
// //         },
// //         body: JSON.stringify({ userEmail }),
// //       })
// //         .then((response) => {
// //           if (!response.ok) {
// //             return response.text().then((text) => {
// //               console.error("Response Text:", text); // Log the raw response
// //               throw new Error('Failed to send confirmation email.');
// //             });
// //           }
// //           return response.json();
// //         })
// //         .then((data) => {
// //           iziToast.success({
// //             title: "Success",
// //             message: data.message || "Payment successful. Confirmation email sent.",
// //           });
// //         })
// //         .catch((error) => {
// //           console.error("Error:", error);
// //           iziToast.error({
// //             title: "Error",
// //             message: error.message || "Failed to send confirmation email.",
// //           });
// //         });      
// //     }
// //   }, [apipath, userEmail]);

// //   return (
// //     <>
// //       <Navbar />

// //       <div className="container">
// //         <div className="col-lg-10 mx-auto congrats">
// //           <div className="congrats-img d-flex justify-content-center">
// //             <img src={`${process.env.PUBLIC_URL}/imgs-calendar/congrats.png`} width="150" alt="" />
// //           </div>
// //           <h1>Congratulations !!</h1>
// //           <h6>Your payment was successful</h6>
// //           <h4>Click Below to download your calendar</h4>
// //           <div className="congrats-button">
// //             <Link to={`/printableCalendar/${calendarId}`}>
// //               <button>
// //                 <i className="bi bi-download"></i> Download
// //               </button>
// //             </Link>
// //           </div>
// //         </div>
// //       </div>

// //       <Footer />
// //     </>
// //   );
// // };

// // export default Congrats;

// import React, { useEffect, useState } from "react";
// import Navbar from "../components/Navbar";
// import Footer from "../components/Footer";
// import { Link, useParams } from "react-router-dom";
// import { useAuth } from "../AuthContext";
// import axios from "axios";
// import iziToast from "izitoast";

// const Congrats = () => {
//   const { calendarId } = useParams();
//   const { apipath } = useAuth();
//   const userEmail = localStorage.getItem('email');
//   const userId = localStorage.getItem('userId');
//   const [loading, setLoading] = useState(false);
//   const [orderDetails, setOrderDetails] = useState(null);
//   const [error, setError] = useState(null);
  
//   useEffect(() => {
//     window.scrollTo({
//       top: 0,
//       left: 0,
//       behavior: "auto",
//     });

//     // Fetch order details and update the order
//     const fetchAndUpdateOrderDetails = async () => {
//       setLoading(true);
//       try {
//         // Fetch order details
//         const response = await axios.get(`${apipath}/calendar/details/${calendarId}`);
//         const orderId = response.calendar.order_id;
//         console.log("orderId : ",orderId);

//         // Update order details
//         await axios.put(`${apipath}/order/details/${orderId}`, {
//           ...orderDetails,
//           calendar_id: calendarId,
//           orderStatus: "Shipping",
//           orderConfirmed: true,
//         });

//         // Show success message
//         iziToast.success({
//           title: "Success",
//           message: "Order details updated successfully.",
//         });

//         // Fetch updated order details
//         setOrderDetails(orderDetails);
//       } catch (error) {
//         console.error("Error:", error);
//         iziToast.error({
//           title: "Error",
//           message: error.message || "Failed to update order details.",
//         });
//         setError(error.message);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchAndUpdateOrderDetails();

//     // Auto send confirmation email
//     if (userEmail) {
//       fetch(`${apipath}/order/confirm`, {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify({ userEmail }),
//       })
//         .then((response) => {
//           if (!response.ok) {
//             return response.text().then((text) => {
//               console.error("Response Text:", text); // Log the raw response
//               throw new Error('Failed to send confirmation email.');
//             });
//           }
//           return response.json();
//         })
//         .then((data) => {
//           iziToast.success({
//             title: "Success",
//             message: data.message || "Payment successful. Confirmation email sent.",
//           });
//         })
//         .catch((error) => {
//           console.error("Error:", error);
//           iziToast.error({
//             title: "Error",
//             message: error.message || "Failed to send confirmation email.",
//           });
//         });
//     }
//   }, [apipath, userEmail, calendarId]);

//   return (
//     <>
//       <Navbar />

//       <div className="container">
//         <div className="col-lg-10 mx-auto congrats">
//           <div className="congrats-img d-flex justify-content-center">
//             <img src={`${process.env.PUBLIC_URL}/imgs-calendar/congrats.png`} width="150" alt="" />
//           </div>
//           <h1>Congratulations !!</h1>
//           <h6>Your payment was successful</h6>
//           <h4>Click Below to download your calendar</h4>
//           <div className="congrats-button">
//             <Link to={`/printableCalendar/${calendarId}`}>
//               <button>
//                 <i className="bi bi-download"></i> Download
//               </button>
//             </Link>
//           </div>
//         </div>
//       </div>

//       <Footer />
//     </>
//   );
// };

// export default Congrats;

// import React, { useEffect, useState } from "react";
// import Navbar from "../components/Navbar";
// import Footer from "../components/Footer";
// import { Link, useParams } from "react-router-dom";
// import { useAuth } from "../AuthContext";
// import axios from "axios";
// import iziToast from "izitoast";

// const Congrats = () => {
//   const { calendarId } = useParams();
//   const { apipath } = useAuth();
//   const userEmail = localStorage.getItem('email');
//   const userId = localStorage.getItem('userId');
//   const [loading, setLoading] = useState(false);
//   const [orderDetails, setOrderDetails] = useState(null);
//   const [error, setError] = useState(null);
  
//   useEffect(() => {
//     window.scrollTo({
//       top: 0,
//       left: 0,
//       behavior: "auto",
//     });

//     // Fetch order details and update the order
//     const fetchAndUpdateOrderDetails = async () => {
//       setLoading(true);
//       try {
//         // Fetch calendar details
//         const response = await axios.get(`${apipath}/calendar/details/${calendarId}`);
//         const calendar = response.data.calendar;
//         const orderId = calendar.order_id;

//         if (!orderId) {
//           throw new Error('Order ID not found in calendar details.');
//         }

//         // Update order details
//         const updatedOrderResponse = await axios.put(`${apipath}/order/details/${orderId}`, {
//           ...orderDetails,
//           calendar_id: calendarId,
//           orderStatus: "Shipping",
//           orderConfirmed: true,
//           paymentStatus: "Completed",
//         });

//         // Show success message
//         iziToast.success({
//           title: "Success",
//           message: "Order details updated successfully.",
//         });

//         // Fetch updated order details
//         setOrderDetails(updatedOrderResponse.data);
//       } catch (error) {
//         console.error("Error:", error);
//         iziToast.error({
//           title: "Error",
//           message: error.message || "Failed to update order details.",
//         });
//         setError(error.message);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchAndUpdateOrderDetails();

//     // Auto send confirmation email
//     if (userEmail) {
//       fetch(`${apipath}/order/confirm`, {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify({ userEmail }),
//       })
//         .then((response) => {
//           if (!response.ok) {
//             return response.text().then((text) => {
//               console.error("Response Text:", text); // Log the raw response
//               throw new Error('Failed to send confirmation email.');
//             });
//           }
//           return response.json();
//         })
//         .then((data) => {
//           iziToast.success({
//             title: "Success",
//             message: data.message || "Payment successful. Confirmation email sent.",
//           });
//         })
//         .catch((error) => {
//           console.error("Error:", error);
//           iziToast.error({
//             title: "Error",
//             message: error.message || "Failed to send confirmation email.",
//           });
//         });
//     }
//   }, [apipath, userEmail, calendarId]);

//   return (
//     <>
//       <Navbar />

//       <div className="container">
//         <div className="col-lg-10 mx-auto congrats">
//           <div className="congrats-img d-flex justify-content-center">
//             <img src={`${process.env.PUBLIC_URL}/imgs-calendar/congrats.png`} width="150" alt="" />
//           </div>
//           <h1>Congratulations !!</h1>
//           <h6>Your payment was successful</h6>
//           <h4>Click Below to download your calendar</h4>
//           <div className="congrats-button">
//             <Link to={`/printableCalendar/${calendarId}`}>
//               <button>
//                 <i className="bi bi-download"></i> Download
//               </button>
//             </Link>
//           </div>
//         </div>
//       </div>

//       <Footer />
//     </>
//   );
// };

// export default Congrats;


import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Link, useParams } from "react-router-dom";
import { useAuth } from "../AuthContext";
import axios from "axios";
import iziToast from "izitoast";

const Congrats = () => {
  const { calendarId } = useParams();
  const { apipath } = useAuth();
  const userEmail = localStorage.getItem('email');
  const userId = localStorage.getItem('userId');
  const userName = localStorage.getItem('name') || 'Customer';
  
  const [loading, setLoading] = useState(false);
  const [orderDetails, setOrderDetails] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "auto",
    });

    // Fetch order details and update the order
    const fetchAndUpdateOrderDetails = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${apipath}/calendar/details/${calendarId}`);
        const calendar = response.data.calendar;
        const orderId = calendar.order_id;
    
        if (!orderId) {
          throw new Error('Order ID not found in calendar details.');
        }
    
        const updatedOrderResponse = await axios.put(`${apipath}/order/details/${orderId}`, {
          ...orderDetails,
          calendar_id: calendarId,
          orderStatus: "Shipping",
          orderConfirmed: true,
          paymentStatus: "Completed",
        });
    
        iziToast.success({
          title: "Success",
          message: "Order details updated successfully.",
        });
    
        const updatedOrder = updatedOrderResponse.data.order;
        setOrderDetails(updatedOrder);
    
        const orderResponse = await axios.get(`${apipath}/order/details/${orderId}`);
        const order = orderResponse.data.order.orderId;
        const now = new Date();
        const dispatchDate = now.toISOString();
    
        if (userEmail) {
          const payload = {
            userEmail: userEmail,
            name: localStorage.getItem('name') || 'Customer',
            orderId: order,
            dispatchDate: dispatchDate,
          };

          const emailResponse = await fetch(`${apipath}/order/confirm`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
          });
    
          if (!emailResponse.ok) {
            const errorText = await emailResponse.text();
            console.error("Response Text:", errorText);
            throw new Error('Failed to send confirmation email.');
          }
    
          const emailData = await emailResponse.json();
          iziToast.success({
            title: "Success",
            message: emailData.message || "Payment successful. Confirmation email sent.",
          });
        }
      } catch (error) {
        console.error("Error:", error);
        iziToast.error({
          title: "Error",
          message: error.message || "Failed to update order details.",
        });
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };
    

    fetchAndUpdateOrderDetails();
  }, [apipath, calendarId, userEmail]);

  return (
    <>
      <Navbar />

      <div className="container">
        <div className="col-lg-10 mx-auto congrats">
          <div className="congrats-img d-flex justify-content-center">
            <img src={`${process.env.PUBLIC_URL}/imgs-calendar/congrats.png`} width="150" alt="" />
          </div>
          <h1>Congratulations !!</h1>
          <h6>Your payment was successful</h6>
          {/* <h4>Click Below to download your calendar</h4> */}
          {/* <div className="congrats-button">
            <Link to={`/printableCalendar/${calendarId}`}>
              <button>
                <i className="bi bi-download"></i> Download
              </button>
            </Link>
          </div> */}
          <h6>Please check email. We shall keep you posted on your Email as we proceeds through the production 
            of you calendar till it is couriered to your shipping address</h6>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Congrats;
