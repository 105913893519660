import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import AdminSidebar from './AdminSidebar';
import iziToast from 'izitoast';
import { useAuth } from '../AuthContext';
import $ from 'jquery';
import { Link } from 'react-router-dom';

const CompleteOrder = () => {
  const [completeOrders, setCompleteOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { apipath } = useAuth();
  const [userDetails, setUserDetails] = useState({});
  const [loading, setLoading] = useState(true);


  const dataTableRef = useRef();

  useEffect(() => {
    fetchCompleteOrders();
  }, []);

  useEffect(() => {
    fetchCompleteOrders();
  if (!loading && completeOrders.length > 0) {
    $(dataTableRef.current).DataTable();
}
}, [loading, completeOrders]);

  const fetchCompleteOrders = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(apipath + '/order/details');
      const completedOrders = response.data.order.filter(order => order.orderStatus === "Completed");

       // Fetch user details for all orders
       const userPromises = completedOrders.map(order =>
        axios.get(`${apipath}/users/details/${order.user_id}`)
          .then(userResponse => ({
            orderId: order._id,
            userName: userResponse.data.user.name, 
            userNumber: userResponse.data.user.phone,
            userId: userResponse.data.user._id,
            userEmail: userResponse.data.user.email,
          }))
      );

       const users = await Promise.all(userPromises);
      const userDetailsMap = users.reduce((acc, user) => {
        acc[user.orderId] = user;
        return acc;
      }, {});

      setUserDetails(userDetailsMap);
      setCompleteOrders(completedOrders);
      setIsLoading(false);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching complete orders:', error);
      setIsLoading(false);
      setLoading(false);
    }
  };

  const handleDelete = async (orderId, calendarId) => {
    try {
      setIsLoading(true);
  
      // Delete the order details
      await axios.delete(`${apipath}/order/details/${orderId}`);
      console.log('Order deleted successfully');
  
      // Delete the associated calendar details
      await axios.delete(`${apipath}/calendar/details/${calendarId}`);
      console.log('Calendar details deleted successfully');
  
      // Refresh orders after deletion
      await fetchCompleteOrders();
      
      setIsLoading(false);
    } catch (error) {
      console.error('Error deleting order or calendar details:', error);
      setIsLoading(false);
    }
  };

  const handleToggleStatus = async (id, field, value) => {
    try {
      setIsLoading(true);
      const updateData = { [field]: value };
      if (field === 'pickup' && !value) {
        updateData.orderStatus = 'Shipped';
      }
      const response = await axios.put(apipath + `/order/details/${id}`, updateData);
      await fetchCompleteOrders();
      setIsLoading(false);

      if (response.status === 200) {
        iziToast.success({
          message: "Order update successful",
          position: "topCenter",
        });
      } else {
        iziToast.error({
          message: "Order update failed",
          position: "topCenter",
        });
      }
    } catch (error) {
      console.error(`Error updating ${field} status:`, error);
      setIsLoading(false);
      iziToast.error({
        message: "An error occurred",
        position: "topCenter",
      });
    }
  };

  return (
    <>
      <AdminSidebar />
      <div className="home">
        <div className="toggle-sidebar" style={{ display: "none" }}>
          <i className="bi bi-menu"></i>
        </div>
        <div className="dashboard">
          <div className="dashboard-header">
            <h1>Completed Orders Management</h1>
          </div>

          <div className="container-fluid">
            <div className="row foot-tablerow">
              <div className="col-lg-11 maintable-column mx-auto">
                <div className="container mt-4 overflow-auto">
                  <table id="datatable" className="table" ref={dataTableRef}>
                    <thead>
                      <tr className="tr1">
                        <th>Order S.No.</th>
                        <th>Order Id</th>
                        <th>Date</th>
                        <th>Name</th>
                        <th>User Id</th>
                        <th>Calendar Id</th>
                        <th>Number</th>
                        <th>Email</th>
                        <th>Courier Name</th>
                        <th>Courier No.</th>
                        <th>Dispatch Date</th>
                        <th>Delivery Date</th>
                        <th>Billing No.</th>
                        <th>Billing Date</th>
                        <th>Payment Confirm</th>
                        {/* <th>Confirmed</th>
                        <th>Dispatched</th> */}
                        {/* <th>Pickup</th>  */}
                        {/* <th>Delivered</th> */}
                        <th>Status</th>
                        <th>Download</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {completeOrders.map((order, index) => {
                        const user = userDetails[order._id] || {};
                        return (
                        <tr key={order._id}>
                          <td className='text-center'>{index + 1}</td>
                          <td>{order.orderId}</td>
                          <td>{new Date(order.orderDate).toLocaleDateString()}</td>
                          <td>{user.userName || 'N/A'}</td>
                          <td>{user.userId }</td>
                          <td>{order.calendar_id }</td>
                          <td>{user.userNumber }</td>
                          <td>{user.userEmail }</td>
                          <td>{order.courierName }</td>
                          <td>{order.courierId }</td>
                          <td>{new Date(order.orderDate).toLocaleDateString()}</td>
                          <td>{new Date(order.orderPlacedDate).toLocaleDateString()}</td>
                          <td>{order.billingNo }</td>
                          <td>{new Date(order.billingDate).toLocaleDateString()}</td>
                          <td className="success">{order.paymentStatus}</td>
                          {/* <td>
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={order.orderConfirmed}
                                disabled
                              />
                              <span className="slider round"></span>
                            </label>
                          </td>
                          <td>
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={order.dispatched}
                                disabled
                              />
                              <span className="slider round"></span>
                            </label>
                          </td>
                          <td>
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={order.pickup}
                                onChange={() => handleToggleStatus(order._id, 'pickup', !order.pickup)}
                              />
                              <span className="slider round"></span>
                            </label>
                          </td> */}
                          <td className="success"><span>{order.orderStatus}</span></td>
                          <td>
        {order.orderType === "user" ? (
          <Link to={`/adminPrintCalendar/${order.calendar_id}`}>
            <i className="bi bi-download"></i>
          </Link>
        ) : order.orderType === "corporate" ? (
          <Link to={`/corporate-calendar-view-download/${order.calendar_id}`}>
            <i className="bi bi-download"></i>
          </Link>
        ) : null}
      </td>
                          <td>
                          <i className="bi bi-trash" onClick={() => handleDelete(order._id, order.calendar_id)}></i>
                          </td>
                        </tr>
                      );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CompleteOrder;
