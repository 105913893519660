import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import iziToast from "izitoast";

const CouponCalendarNotes2 = () => {
  const { calendarId, shippingId } = useParams();
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };

  const handleApproveClick = (e) => {
    if (!isChecked) {
      e.preventDefault();
      iziToast.error({
        message: "Please agree to the terms and conditions before proceeding.",
        position: "topCenter",
      });
    }
  };

  return (
    <>
      <div className="container preview">
        <div className="top-buttons">
          <Link to={`/coupon-calendar-notes1/${calendarId}/${shippingId}`}>
            <button className="pre-button">
              <i className="bi bi-chevron-left"></i> Previous
            </button>
          </Link>
        </div>
      </div>
      {/* <div className="container prev-img-last">
        <img
          src={`${process.env.PUBLIC_URL}/imgs-calendar/layout31.png`}
          alt=""
        />
      </div> */}
      <div className="container notepad">
        <div className="notepad-container-coupon">
          <div className="header">
            <h2>Notes</h2>
          </div>
          <div className="notepad-body"></div>
        </div>
      </div>

      {/* <div className="container prev-img">
        <img src={`${process.env.PUBLIC_URL}/imgs-calendar/layout31.png`} alt="" style={{ height: '75vh', border:'2px solid gray', width:'85vw' }} />
      </div> */}

      <div className="container preview-agree pt-3">
        <input
          type="checkbox"
          name=""
          id=""
          checked={isChecked}
          onChange={handleCheckboxChange}
        />
        <Link to="/terms" target="_blank"><label htmlFor="">I agree to the terms and conditions</label></Link>
        <div className="d-flex justify-content-between py-3">
          <Link to={`/coupon-calendar-image-edit/${calendarId}`}>
            <button className="a1">Edit</button>
          </Link>
          <Link
            to={
              isChecked ? `/coupon-calendar-view-download/${calendarId}/${shippingId}` : "#"
            }
          >
            {/* <Link to={isChecked ? `/printableCalendar/${calendarId}` : "#"}> */}
            <button className="a2" onClick={handleApproveClick}>
              Approve
            </button>
          </Link>
        </div>
      </div>
    </>
  );
};

export default CouponCalendarNotes2;
