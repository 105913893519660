// import React, { useEffect, useState } from "react";
// import { Link, useParams } from "react-router-dom";
// import ReactBigCalendar from "./ReactBigCalendar";
// import { useAuth } from "../AuthContext";

// const PortraitLandscape = () => {
//     const { calendarId } = useParams();
//     const { filepath } = useAuth();
//     const [calendarData, setCalendarData] = useState(null);

//     useEffect(() => {
//         const fetchCalendarData = async () => {
//             try {
//                 const response = await fetch(`https://giftonly4u.com:4000/api/v1/calendar/details/${calendarId}`);
//                 const data = await response.json();
//                 setCalendarData(data.calendar);
//             } catch (error) {
//                 console.error("Error fetching calendar data:", error);
//             }
//         };

//         fetchCalendarData();
//     }, [calendarId]);

//     return (
//         <>
//             <div className="container preview">
//                 <div className="top-buttons">
//                     <Link to={`/landscape/${calendarId}`}>
//                         <button>
//                             {" "}
//                             <i className="bi bi-chevron-left"></i> Previous
//                         </button>
//                     </Link>
//                     <Link to={`/portrait/${calendarId}`}>
//                         <button>
//                             {" "}
//                             Next <i className="bi bi-chevron-right"></i>
//                         </button>
//                     </Link>
//                 </div>
//                 <div className="container-fluid portrait">
//                     <div className="row">
//                         <div className="col-lg-6">
//                             <img src={`${process.env.PUBLIC_URL}/imgs-calendar/p.png`} alt="January" />
//                         </div>
//                         <div className="col-lg-6 pt-2">
//                             <h1>jan 2024</h1>
//                             <ReactBigCalendar type={1} />
//                             <div className='public-holiday your-events'>
//                                 <div><li >1-jan</li>
//                                     <li >2-jan</li></div>
//                                 <div><li>12 -jan</li>
//                                     <li>20 -jan</li></div>
//                             </div>
//                             <div className='public-holiday'>
//                                 <div>
//                                     <li>01, New Year</li>
//                                     <li>01, New Year</li>
//                                     <li>01, New Year</li>
//                                 </div>
//                                 <div>
//                                     <li>01, New Year</li>
//                                     <li>01, New Year</li>
//                                     <li>01, New Year</li>
//                                 </div>
//                             </div>

//                         </div>
//                     </div>
//                 </div>
//                 <div className="container-fluid landscape">
//                     <div className="row">
//                         <div className="col-lg-12">
//                             <img src={`${process.env.PUBLIC_URL}/imgs-calendar/l.png`} alt="January" />
//                         </div>
//                         <div className="col-lg-12 pt-2">
//                             <h1>jan 2024</h1>
//                             <div className="land row">
//                                 <div className="col-lg-8">
//                                     <ReactBigCalendar type={2} />
//                                 </div>
//                                 <div className="col-lg-4">
//                                     <div className="public-holiday-land your-events">
//                                         <div><li >1-jan</li>
//                                             <li >2-jan</li></div>
//                                         <div><li>12 -jan</li>
//                                             <li>20 -jan</li></div>
//                                     </div>
//                                     <div className="public-holiday-land">
//                                         <div>
//                                             <li>01, New Year</li>
//                                             <li>01, New Year</li>
//                                             <li>01, New Year</li>
//                                         </div>
//                                         <div>
//                                             <li>01, New Year</li>
//                                             <li>01, New Year</li>
//                                             <li>01, New Year</li>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </>
//     );
// };

// export default PortraitLandscape;


// import React, { useEffect, useState } from "react";
// import { Link, useParams } from "react-router-dom";
// import ReactBigCalendar from "./ReactBigCalendar";
// import { useAuth } from "../AuthContext";
// import moment from "moment";
// import { generateYearMonths } from "./generateYearMonths";
// import { toPng } from 'html-to-image';
// import jsPDF from 'jspdf';

// const PortraitLandscape = () => {
//     const { calendarId } = useParams();
//     const { filepath, apipath } = useAuth();
//     const [calendarData, setCalendarData] = useState(null);
//     const startDate = "2024-08-01T00:00:00.000Z"; // Replace with your desired start date

//     useEffect(() => {
//         const fetchCalendarData = async () => {
//             try {
//                 const response = await fetch(apipath + `/calendar/details/${calendarId}`);
//                 const data = await response.json();
//                 setCalendarData(data.calendar);
//                 console.log("Calendar details : ", data.calendar);
//             } catch (error) {
//                 console.error("Error fetching calendar data:", error);
//             }
//         };

//         fetchCalendarData();
//     }, [calendarId]);

//     const handleDownload = async () => {
//         const doc = new jsPDF();
//         const months = generateYearMonths(startDate);

//         for (let i = 0; i < months.length; i++) {
//             const month = months[i];
//             const node = document.getElementById(`capture-${i}`);

//             try {
//                 const dataUrl = await toPng(node);
//                 doc.addImage(dataUrl, 'PNG', 10, 10, 190, 0);
//                 if (i !== months.length - 1) {
//                     doc.addPage();
//                 }
//             } catch (error) {
//                 console.error('Error capturing the image:', error);
//             }
//         }

//         doc.save('calendar-view.pdf');
//     };

//     if (!calendarData) {
//         return <div>Loading...</div>;
//     }

//     const months = generateYearMonths(startDate);
//     const isPortrait = true;  // You can change this logic to set the view type dynamically based on your data or conditions

//     return (
//         <div className="container preview">
//             <div className="top-buttons">
//                 <Link to={`/landscape/${calendarId}`}>
//                     <button>
//                         {" "}
//                         <i className="bi bi-chevron-left"></i> Previous
//                     </button>
//                 </Link>
//                 <Link to={`/portrait/${calendarId}`}>
//                     <button>
//                         {" "}
//                         Next <i className="bi bi-chevron-right"></i>
//                     </button>
//                 </Link>
//             </div>
//             {isPortrait ? (
//                 <div className="container-fluid portrait">
//                     {months.map((month, index) => (
//                         <div key={index} id={`capture-${index}`} className="row">
//                             <div className="col-lg-6">
//                                 <img
//                                     src={
//                                         calendarData[`${month.format("MMMM").toLowerCase()}Pic`] !== "" &&
//                                             calendarData[`${month.format("MMMM").toLowerCase()}Pic`] !== null
//                                             ? filepath + calendarData[`${month.format("MMMM").toLowerCase()}Pic`]
//                                             : `${process.env.PUBLIC_URL}/imgs-calendar/p.png`
//                                     }
//                                     alt={month.format("MMMM")}
//                                 />
//                             </div>
//                             <div className="col-lg-6 pt-2">
//                                 <h1>{month.format("MMMM")} 2024</h1>
//                                 <ReactBigCalendar
//                                     type={1}
//                                     events={[
//                                         {
//                                             start: new Date(2024, month.month(), 10),
//                                             end: new Date(2024, month.month(), 10),
//                                             title: calendarData[`${month.format("MMMM").toLowerCase()}Event1Caption`],
//                                         },
//                                         {
//                                             start: new Date(2024, month.month(), 16),
//                                             end: new Date(2024, month.month(), 16),
//                                             title: calendarData[`${month.format("MMMM").toLowerCase()}Event2Caption`],
//                                         },
//                                         {
//                                             start: new Date(2024, month.month(), 22),
//                                             end: new Date(2024, month.month(), 22),
//                                             title: calendarData[`${month.format("MMMM").toLowerCase()}Event3Caption`],
//                                         },
//                                         {
//                                             start: new Date(2024, month.month(), 25),
//                                             end: new Date(2024, month.month(), 25),
//                                             title: calendarData[`${month.format("MMMM").toLowerCase()}Event4Caption`],
//                                         },
//                                     ]}
//                                 />
//                                 <div className='public-holiday your-events'>
//                                     <div>
//                                         <li style={{ color: calendarData[`${month.format("MMMM").toLowerCase()}Event1Color`] }}>
//                                             {calendarData[`${month.format("MMMM").toLowerCase()}Event1Date`]} - {calendarData[`${month.format("MMMM").toLowerCase()}Event1Caption`]}
//                                         </li>
//                                         <li style={{ color: calendarData[`${month.format("MMMM").toLowerCase()}Event2Color`] }}>
//                                             {calendarData[`${month.format("MMMM").toLowerCase()}Event2Date`]} - {calendarData[`${month.format("MMMM").toLowerCase()}Event2Caption`]}
//                                         </li>
//                                     </div>
//                                     <div>
//                                         <li style={{ color: calendarData[`${month.format("MMMM").toLowerCase()}Event3Color`] }}>
//                                             {calendarData[`${month.format("MMMM").toLowerCase()}Event3Date`]} - {calendarData[`${month.format("MMMM").toLowerCase()}Event3Caption`]}
//                                         </li>
//                                         <li style={{ color: calendarData[`${month.format("MMMM").toLowerCase()}Event4Color`] }}>
//                                             {calendarData[`${month.format("MMMM").toLowerCase()}Event4Date`]} - {calendarData[`${month.format("MMMM").toLowerCase()}Event4Caption`]}
//                                         </li>
//                                     </div>
//                                 </div>
//                                 <div className='public-holiday'>
//                                     <div>
//                                         <li>01, New Year</li>
//                                         <li>01, New Year</li>
//                                         <li>01, New Year</li>
//                                     </div>
//                                     <div>
//                                         <li>01, New Year</li>
//                                         <li>01, New Year</li>
//                                         <li>01, New Year</li>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     ))}
//                 </div>
//             ) : (
//                 <div className="container-fluid landscape">
//                     {months.map((month, index) => (
//                         <div key={index} id={`capture-${index}`} className="row">
//                             <div className="col-lg-12">
//                                 <img
//                                     src={
//                                         calendarData[`${month.format("MMMM").toLowerCase()}Pic`] !== "" &&
//                                             calendarData[`${month.format("MMMM").toLowerCase()}Pic`] !== null
//                                             ? filepath + calendarData[`${month.format("MMMM").toLowerCase()}Pic`]
//                                             : `${process.env.PUBLIC_URL}/imgs-calendar/l.png`
//                                     }
//                                     alt={month.format("MMMM")}
//                                 />
//                             </div>
//                             <div className="col-lg-12 pt-2">
//                                 <h1>{month.format("MMMM")} 2024</h1>
//                                 <div className="land row">
//                                     <div className="col-lg-8">
//                                         <ReactBigCalendar
//                                             type={1}
//                                             events={[
//                                                 {
//                                                     start: new Date(2024, month.month(), 10),
//                                                     end: new Date(2024, month.month(), 10),
//                                                     title: calendarData[`${month.format("MMMM").toLowerCase()}Event1Caption`],
//                                                 },
//                                                 {
//                                                     start: new Date(2024, month.month(), 16),
//                                                     end: new Date(2024, month.month(), 16),
//                                                     title: calendarData[`${month.format("MMMM").toLowerCase()}Event2Caption`],
//                                                 },
//                                                 {
//                                                     start: new Date(2024, month.month(), 22),
//                                                     end: new Date(2024, month.month(), 22),
//                                                     title: calendarData[`${month.format("MMMM").toLowerCase()}Event3Caption`],
//                                                 },
//                                                 {
//                                                     start: new Date(2024, month.month(), 25),
//                                                     end: new Date(2024, month.month(), 25),
//                                                     title: calendarData[`${month.format("MMMM").toLowerCase()}Event4Caption`],
//                                                 },
//                                             ]}
//                                         />
//                                     </div>
//                                     <div className="col-lg-4">
//                                         <div className="public-holiday-land your-events">
//                                             <div>
//                                                 <li style={{ color: calendarData[`${month.format("MMMM").toLowerCase()}Event1Color`] }}>
//                                                     {calendarData[`${month.format("MMMM").toLowerCase()}Event1Date`]} - {calendarData[`${month.format("MMMM").toLowerCase()}Event1Caption`]}
//                                                 </li>
//                                                 <li style={{ color: calendarData[`${month.format("MMMM").toLowerCase()}Event2Color`] }}>
//                                                     {calendarData[`${month.format("MMMM").toLowerCase()}Event2Date`]} - {calendarData[`${month.format("MMMM").toLowerCase()}Event2Caption`]}
//                                                 </li>
//                                             </div>
//                                             <div>
//                                                 <li style={{ color: calendarData[`${month.format("MMMM").toLowerCase()}Event3Color`] }}>
//                                                     {calendarData[`${month.format("MMMM").toLowerCase()}Event3Date`]} - {calendarData[`${month.format("MMMM").toLowerCase()}Event3Caption`]}
//                                                 </li>
//                                                 <li style={{ color: calendarData[`${month.format("MMMM").toLowerCase()}Event4Color`] }}>
//                                                     {calendarData[`${month.format("MMMM").toLowerCase()}Event4Date`]} - {calendarData[`${month.format("MMMM").toLowerCase()}Event4Caption`]}
//                                                 </li>
//                                             </div>
//                                         </div>
//                                         <div className="public-holiday-land">
//                                             <div>
//                                                 <li>01, New Year</li>
//                                                 <li>01, New Year</li>
//                                                 <li>01, New Year</li>
//                                             </div>
//                                             <div>
//                                                 <li>01, New Year</li>
//                                                 <li>01, New Year</li>
//                                                 <li>01, New Year</li>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     ))}
//                 </div>
//             )}
//         </div>
//     );
// };

// export default PortraitLandscape;

import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import ReactBigCalendar from "./ReactBigCalendar";
import { useAuth } from "../AuthContext";
import moment from "moment";
import { generateYearMonths } from "./generateYearMonths";
import { toPng } from 'html-to-image';
import jsPDF from 'jspdf';

const PortraitLandscape = () => {
    const { calendarId } = useParams();
    const { filepath, apipath } = useAuth();
    const [calendarData, setCalendarData] = useState(null);
    const [viewType, setViewType] = useState("portrait"); // "portrait" or "landscape"
    const startDate = "2024-08-01T00:00:00.000Z"; // Replace with your desired start date

    useEffect(() => {
        const fetchCalendarData = async () => {
            try {
                const response = await fetch(apipath + `/calendar/details/${calendarId}`);
                const data = await response.json();
                setCalendarData(data.calendar);
                console.log("Calendar details : ", data.calendar);
                determineViewType(data.calendar);
            } catch (error) {
                console.error("Error fetching calendar data:", error);
            }
        };

        const determineViewType = async (calendar) => {
            const imagePromises = Object.keys(calendar).map(async (key) => {
                if (key.endsWith("Pic")) {
                    const imagePath = filepath + calendar[key];
                    return getImageDimensions(imagePath);
                }
                return null;
            });

            const imageDimensions = await Promise.all(imagePromises);
            const isPortrait = imageDimensions.every(dim => dim && dim.height >= dim.width);
            setViewType(isPortrait ? "portrait" : "landscape");
        };

        const getImageDimensions = (url) => {
            return new Promise((resolve) => {
                const img = new Image();
                img.onload = () => {
                    resolve({ width: img.width, height: img.height });
                };
                img.onerror = () => {
                    resolve(null);
                };
                img.src = url;
            });
        };

        fetchCalendarData();
    }, [calendarId, filepath, apipath]);

    const handleDownload = async () => {
        const doc = new jsPDF();
        const months = generateYearMonths(startDate);

        for (let i = 0; i < months.length; i++) {
            const month = months[i];
            const node = document.getElementById(`capture-${i}`);

            try {
                const dataUrl = await toPng(node);
                doc.addImage(dataUrl, 'PNG', 10, 10, 190, 0);
                if (i !== months.length - 1) {
                    doc.addPage();
                }
            } catch (error) {
                console.error('Error capturing the image:', error);
            }
        }

        doc.save('calendar-view.pdf');
    };

    if (!calendarData) {
        return <div>Loading...</div>;
    }

    const months = generateYearMonths(startDate);

    return (
        <div className="container preview">
            <div className="top-buttons">
                <Link to={`/landscape/${calendarId}`}>
                    <button>
                        {" "}
                        <i className="bi bi-chevron-left"></i> Previous
                    </button>
                </Link>
                <Link to={`/portrait/${calendarId}`}>
                    <button>
                        {" "}
                        Next <i className="bi bi-chevron-right"></i>
                    </button>
                </Link>
            </div>
            {viewType === "portrait" ? (
                <div className="container-fluid portrait">
                    {months.map((month, index) => (
                        <div key={index} id={`capture-${index}`} className="row">
                            <div className="col-lg-6">
                                <img
                                    src={
                                        calendarData[`${month.format("MMMM").toLowerCase()}Pic`] !== "" &&
                                            calendarData[`${month.format("MMMM").toLowerCase()}Pic`] !== null
                                            ? filepath + calendarData[`${month.format("MMMM").toLowerCase()}Pic`]
                                            : `${process.env.PUBLIC_URL}/imgs-calendar/p.png`
                                    }
                                    alt={month.format("MMMM")}
                                />
                            </div>
                            <div className="col-lg-6 pt-2">
                                <h1>{month.format("MMMM")} 2024</h1>
                                <ReactBigCalendar
                                    type={1}
                                    events={[
                                        {
                                            start: new Date(2024, month.month(), 10),
                                            end: new Date(2024, month.month(), 10),
                                            title: calendarData[`${month.format("MMMM").toLowerCase()}Event1Caption`],
                                        },
                                        {
                                            start: new Date(2024, month.month(), 16),
                                            end: new Date(2024, month.month(), 16),
                                            title: calendarData[`${month.format("MMMM").toLowerCase()}Event2Caption`],
                                        },
                                        {
                                            start: new Date(2024, month.month(), 22),
                                            end: new Date(2024, month.month(), 22),
                                            title: calendarData[`${month.format("MMMM").toLowerCase()}Event3Caption`],
                                        },
                                        {
                                            start: new Date(2024, month.month(), 25),
                                            end: new Date(2024, month.month(), 25),
                                            title: calendarData[`${month.format("MMMM").toLowerCase()}Event4Caption`],
                                        },
                                    ]}
                                />
                                <div className='public-holiday your-events'>
                                    <div>
                                        <li style={{ color: calendarData[`${month.format("MMMM").toLowerCase()}Event1Color`] }}>
                                            {calendarData[`${month.format("MMMM").toLowerCase()}Event1Date`]} - {calendarData[`${month.format("MMMM").toLowerCase()}Event1Caption`]}
                                        </li>
                                        <li style={{ color: calendarData[`${month.format("MMMM").toLowerCase()}Event2Color`] }}>
                                            {calendarData[`${month.format("MMMM").toLowerCase()}Event2Date`]} - {calendarData[`${month.format("MMMM").toLowerCase()}Event2Caption`]}
                                        </li>
                                    </div>
                                    <div>
                                        <li style={{ color: calendarData[`${month.format("MMMM").toLowerCase()}Event3Color`] }}>
                                            {calendarData[`${month.format("MMMM").toLowerCase()}Event3Date`]} - {calendarData[`${month.format("MMMM").toLowerCase()}Event3Caption`]}
                                        </li>
                                        <li style={{ color: calendarData[`${month.format("MMMM").toLowerCase()}Event4Color`] }}>
                                            {calendarData[`${month.format("MMMM").toLowerCase()}Event4Date`]} - {calendarData[`${month.format("MMMM").toLowerCase()}Event4Caption`]}
                                        </li>
                                    </div>
                                </div>
                                <div className='public-holiday'>
                                    <div>
                                        <li>01, New Year</li>
                                        <li>01, New Year</li>
                                        <li>01, New Year</li>
                                    </div>
                                    <div>
                                        <li>01, New Year</li>
                                        <li>01, New Year</li>
                                        <li>01, New Year</li>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            ) : (
                <div className="container-fluid landscape">
                    {months.map((month, index) => (
                        <div key={index} id={`capture-${index}`} className="row">
                            <div className="col-lg-12">
                                <img
                                    src={
                                        calendarData[`${month.format("MMMM").toLowerCase()}Pic`] !== "" &&
                                            calendarData[`${month.format("MMMM").toLowerCase()}Pic`] !== null
                                            ? filepath + calendarData[`${month.format("MMMM").toLowerCase()}Pic`]
                                            : `${process.env.PUBLIC_URL}/imgs-calendar/l.png`
                                    }
                                    alt={month.format("MMMM")}
                                />
                            </div>
                            <div className="col-lg-12 pt-2">
                                <h1>{month.format("MMMM")} 2024</h1>
                                <div className="land row">
                                    <div className="col-lg-8">
                                        <ReactBigCalendar
                                            type={1}
                                            events={[
                                                {
                                                    start: new Date(2024, month.month(), 10),
                                                    end: new Date(2024, month.month(), 10),
                                                    title: calendarData[`${month.format("MMMM").toLowerCase()}Event1Caption`],
                                                },
                                                {
                                                    start: new Date(2024, month.month(), 16),
                                                    end: new Date(2024, month.month(), 16),
                                                    title: calendarData[`${month.format("MMMM").toLowerCase()}Event2Caption`],
                                                },
                                                {
                                                    start: new Date(2024, month.month(), 22),
                                                    end: new Date(2024, month.month(), 22),
                                                    title: calendarData[`${month.format("MMMM").toLowerCase()}Event3Caption`],
                                                },
                                                {
                                                    start: new Date(2024, month.month(), 25),
                                                    end: new Date(2024, month.month(), 25),
                                                    title: calendarData[`${month.format("MMMM").toLowerCase()}Event4Caption`],
                                                },
                                            ]}
                                        />
                                    </div>
                                    <div className="col-lg-4">
                                        <div className='public-holiday your-events'>
                                            <div>
                                                <li style={{ color: calendarData[`${month.format("MMMM").toLowerCase()}Event1Color`] }}>
                                                    {calendarData[`${month.format("MMMM").toLowerCase()}Event1Date`]} - {calendarData[`${month.format("MMMM").toLowerCase()}Event1Caption`]}
                                                </li>
                                                <li style={{ color: calendarData[`${month.format("MMMM").toLowerCase()}Event2Color`] }}>
                                                    {calendarData[`${month.format("MMMM").toLowerCase()}Event2Date`]} - {calendarData[`${month.format("MMMM").toLowerCase()}Event2Caption`]}
                                                </li>
                                            </div>
                                            <div>
                                                <li style={{ color: calendarData[`${month.format("MMMM").toLowerCase()}Event3Color`] }}>
                                                    {calendarData[`${month.format("MMMM").toLowerCase()}Event3Date`]} - {calendarData[`${month.format("MMMM").toLowerCase()}Event3Caption`]}
                                                </li>
                                                <li style={{ color: calendarData[`${month.format("MMMM").toLowerCase()}Event4Color`] }}>
                                                    {calendarData[`${month.format("MMMM").toLowerCase()}Event4Date`]} - {calendarData[`${month.format("MMMM").toLowerCase()}Event4Caption`]}
                                                </li>
                                            </div>
                                        </div>
                                        <div className='public-holiday'>
                                            <div>
                                                <li>01, New Year</li>
                                                <li>01, New Year</li>
                                                <li>01, New Year</li>
                                            </div>
                                            <div>
                                                <li>01, New Year</li>
                                                <li>01, New Year</li>
                                                <li>01, New Year</li>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            )}
            <div className="text-center">
                <button onClick={handleDownload}>Download</button>
            </div>
        </div>
    );
};

export default PortraitLandscape;
