import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import iziToast from 'izitoast';
import { useAuth } from '../AuthContext';
import $ from 'jquery';
import { Link } from 'react-router-dom';
import SubAdminSidebar from './SubAdminSidebar';

const CorporateCouponCode = () => {
  const [corporateCoupons, setCorporateCoupon] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { filepath, apipath } = useAuth();
  const [loading, setLoading] = useState(true);

  const dataTableRef = useRef();
  const subadminId = localStorage.getItem('subadminId');

  useEffect(() => {
    fetchCorporateCoupon();
  if (!loading && corporateCoupons.length > 0) {
    $(dataTableRef.current).DataTable();
}
}, [loading, corporateCoupons]);

  const fetchCorporateCoupon = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(apipath + '/corporate/details');
      const corporateCoupon = response.data.corporate;

      const filteredCoupons = corporateCoupon.filter(
        (corporate) => corporate.assignPrinterId === subadminId
      );
      
      setCorporateCoupon(filteredCoupons);
      setIsLoading(false);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching corporate coupon:', error);
      setIsLoading(false);
      setLoading(false);
    }
  };

  const handleToggleStatus = async (id, subIndex, value) => {
    try {
      setIsLoading(true);
      console.log("Start",subIndex)
      console.log("Start",value)
      console.log("Start",id)
      // Fetch the current corporate data to get the full couponCodesStatus array
      const { data } = await axios.get(`${apipath}/corporate/details/${id}`);
      const currentCouponCodesStatus = data.corporate.couponCodesStatus || [];

      // Create a copy of couponCodesStatus and update only the target index
      const updatedCouponCodesStatus = [...currentCouponCodesStatus];
      updatedCouponCodesStatus[subIndex] = value;

      // Prepare the update payload
      const updateData = {
        couponCodesStatus: updatedCouponCodesStatus
      };

      const response = await axios.put(`${apipath}/corporate/details/${id}`, updateData);

      iziToast.success({
        message: "Download Status updated successfully",
        position: "topCenter",
      });
    } catch (error) {
      console.error(`Error updating status at index ${subIndex}:`, error);
      setIsLoading(false);
      iziToast.error({
        message: "An error occurred",
        position: "topCenter",
      });
    }
};

  return (
    <>
      <SubAdminSidebar />
      <div className="home">
        <div className="toggle-sidebar" style={{ display: "none" }}>
          <i className="bi bi-menu"></i>
        </div>
        <div className="dashboard">
          <div className="dashboard-header">
            <h1>Corporate Coupon Management</h1>
          </div>

          <div className="container-fluid">
            <div className="row foot-tablerow">
              <div className="col-lg-11 maintable-column mx-auto">
                <div className="container mt-4 overflow-auto">
                  <table id="datatable" className="table" ref={dataTableRef}>
                    <thead>
                      <tr className="tr1">
                        <th>Corporate S.No.</th>
                        <th>Date</th>
                        <th>Name</th>
                        <th>Coupon QTY</th>
                        <th>Status</th>
                        <th>Download</th>
                      </tr>
                    </thead>
                    <tbody>
                    {corporateCoupons.map((corporate, index) => (
                        corporate.couponQuantity.map((qty, subIndex) => (
                          <tr key={`${corporate._id}-${subIndex}`}>
                            <td className='text-center'>{index + subIndex + 1}</td>
                            <td>{new Date(corporate.createdAt[subIndex]).toLocaleDateString()}</td>
                            <td>{corporate.name}</td>
                            <td>{qty}</td>
                            <td>
                              <label className="switch">
                                {/* <input
                                  type="checkbox"
                                  checked={corporate.couponCodesStatus[subIndex]}
                                  onChange={() =>
                                    handleToggleStatus(
                                      corporate._id,
                                      'Downloaded',
                                      !corporate.couponCodesStatus[subIndex]
                                    )
                                  }
                                /> */}
                                
          <input
            type="checkbox"
            checked={corporate.couponCodesStatus[subIndex]}
            onChange={() =>
              handleToggleStatus(
                corporate._id,
                subIndex,
                !corporate.couponCodesStatus[subIndex] // Toggle value
              )
            }
          />
                                <span className="slider round"></span>
                              </label>
                            </td>
                            <td>
                              <Link to={`/coupon-view-download/${corporate._id}/${subIndex}`}>
                                <i className="bi bi-download"></i>
                              </Link>
                            </td>
                          </tr>
                        ))
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CorporateCouponCode;
