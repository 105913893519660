import React, { useEffect } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";

const Privacy = () => {

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "auto",
    });
  }, []);

  return (
    <>
      <Navbar />

      <div className="container privacy">
        <h1>Privacy Policy</h1>
        <p>PRESSIdeas, the owner of <a href="https://www.giftonly4u.com" className="line-remove" target="_blank" rel="noopener noreferrer">www.giftonly4u.com</a>, recognises the importance of maintaining your privacy. We value your privacy and appreciate your trust in us and hence will never share, sell, rent, swap or authorize any third party to use your e-mail address for commercial purposes without your consent, written and/or spoken. We will not share your Social Media Platform Data to any third party without your consent, written or verbal.</p>
      </div>

      <Footer />
    </>
  );
};

export default Privacy;
