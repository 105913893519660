import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { useNavigate, useParams } from "react-router-dom";
import iziToast from "izitoast";
import axios from "axios";
import { useAuth } from "../AuthContext";
import { v4 as uuidv4 } from 'uuid';

const BillSummary = () => {
  const { calendarId, orderId } = useParams();
  const navigate = useNavigate();
  const { apipath } = useAuth();
  const [formData, setFormData] = useState({
    _id: "",
    qty: 1,
    orderStatus: "Pending",
    paymentStatus: "Completed",
    orderAmount: 0,
    shippingPrice: 0,
    totalPrice: 295,
    orderId: "",
    orderConfirmed: true,
    dispatched: true,
    OntheWay: false,
    pickup: false,
    calendar_id: calendarId || "",
  });

  const [userDetails, setUserDetails] = useState({
    name: "",
    phone: "",
  });

  const userId = localStorage.getItem('userId');
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const checkLoginStatus = () => {
    const token = localStorage.getItem('token');
    setIsLoggedIn(!!token);
  };

  const fetchUsers = async () => {
    try {
      const userResponse = await axios.get(apipath + `/users/details/${userId}`);
      setUserDetails(userResponse.data.user);
    } catch (error) {
      console.error('Error fetching user details:', error);
    }
  };

  useEffect(() => {
    checkLoginStatus();
    fetchUsers();
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "auto",
    });

    fetchOrderDetails();
  }, [orderId, calendarId]);

  const fetchOrderDetails = async () => {
    try {
      const response = await axios.get(apipath + `/order/details/${orderId}`);
      setFormData({
        ...response.data.order,
        calendar_id: calendarId || "", 
        orderStatus: "Shipping",
        paymentStatus: "Completed",
        orderConfirmed: true,
        dispatched: true,
      });
    } catch (error) {
      console.error("Error fetching order details:", error);
      iziToast.error({
        message: 'Failed to fetch order details',
        position: 'topCenter',
      });
    }
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    if (!formData._id) {
      iziToast.error({
        message: 'Order ID is missing',
        position: 'topCenter',
      });
      return;
    }

    initiatePayment();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };


  const initiatePayment = async () => {
    const transactionId = uuidv4();
    const MUID = uuidv4().slice(0, 10);
  
    const paymentRequest = {
      name: userDetails.name,
      amount: formData.totalPrice,
      number: userDetails.phone,
      MUID: MUID,
      transactionId: transactionId,
      calendar_id: calendarId || "",
      _id: orderId || "",
      // redirectUrl: `${apipath}/payment-result/${calendarId}?transactionId=${transactionId}`
    };
  
    // const initiatePayment = async () => {
    //   // Generate a uuid and slice to ensure it is exactly 35 characters
    //   const transactionId = uuidv4().replace(/-/g, "").slice(0, 35); 
    //   const MUID = uuidv4().slice(0, 10);
    
    //   const paymentRequest = {
    //     name: userDetails.name,
    //     amount: formData.totalPrice,
    //     number: userDetails.phone,
    //     MUID: MUID,
    //     transactionId: transactionId,
    //     calendar_id: calendarId || "",
    //     _id: orderId || "",
    //     // redirectUrl: `${apipath}/payment-result/${calendarId}?transactionId=${transactionId}`
    //   };
    try {
      const response = await axios.post(apipath + "/payment/order", paymentRequest);
  
      if (response.status === 200) {
        const paymentData = response.data.data;
        iziToast.success({
          message: 'Redirecting to payment gateway...',
          position: 'topCenter',
        });
  
        // Redirect to payment gateway URL
        window.location.href = paymentData.instrumentResponse.redirectInfo.url;
      } else {
        iziToast.error({
          message: 'Payment initiation failed',
          position: 'topCenter',
        });
      }
    } catch (error) {
      console.error('Error initiating payment:', error);
      iziToast.error({
        message: 'An error occurred during payment initiation',
        position: 'topCenter',
      });
    }
  };

  
  return (
    <>
      <Navbar />
      <div className="container">
        <div className="col-lg-6 mx-auto image-header">
          <h1>Your Order Summary</h1>
        </div>
        <div className="image-content">
          <div className="row image-row py-3">
            <div className="col-lg-8 mx-auto">
            <h3>Check your order summary :</h3>
              <form onSubmit={handleRegister}>
                <div className="summary-card">
                  <h4>Personalized Calendar Order details :</h4>
                  <div className="bottom-line">
                    <span>Product Quantity</span>
                    <span>{formData.qty}</span>
                  </div>
                  <div className="bottom-line">
                    <span>Price including shipping and gst</span>
                    <span>₹ {formData.totalPrice}</span>
                  </div>
                  <div className="bottom-line">
                    <span>Total Price</span>
                    <span>₹ {formData.totalPrice}</span>
                  </div>
                  <h4 className="mt-4">Payment Partners :</h4>
                  <div className="px-3">
                    <img src={`${process.env.PUBLIC_URL}/imgs-calendar/phonepe.png`} alt="PhonePe" />
                  </div>
                </div>
                <div className="image-button">
                  <button type="submit">Continue To Payment</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default BillSummary;

