import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import iziToast from "izitoast";
import { useAuth } from "../AuthContext";
import OtpTimer from "otp-timer";

const RegOtpVerify = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const { apipath } = useAuth();
  const { emailId } = useParams();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "auto",
    });
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const emailFromParams = urlParams.get("email");
    if (emailFromParams) {
      setEmail(emailFromParams);
    }
  }, []);

  const handleOtpChange = (event) => {
    setOtp(event.target.value);
  };

  const handleResendOtp = () => {
    console.log("resend otp");
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    fetch(apipath + "/mail/reg-verify-otp", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, otp }),
    })
      .then((response) => {
        if (!response.ok) {
          return response.json().then(err => {
            throw new Error(err.message || 'Incorrect otp. Please enter valid Otp');
          });
        }
        return response.json();
      })
      .then((data) => {
        iziToast.success({
          title: "Success",
          message: data.message || "OTP verified successfully",
        });
        navigate("/login");
      })
      .catch((error) => {
        console.error("Error:", error);
        iziToast.error({
          title: "Error",
          message: error.message || "Failed to verify OTP",
        });
      });
  };

  return (
    <div className="container-fluid login" >
      <div className="row login-row">
        <div className="col-lg-6">
          <div className="img-login">
            <img
              src={`${process.env.PUBLIC_URL}/imgs-calendar/password.png`}
              alt=""
            />
          </div>
        </div>
        <div className="col-lg-6 login-right">
          <h1>Verify OTP</h1>
          <p>Please enter OTP</p>
          <form onSubmit={handleSubmit} className="pt-4">
            <div className="login-item col-lg-8 mx-auto">
              <label htmlFor="email">Email</label>
              <input
                type="text"
                id="email"
                placeholder="Enter Your email"
                value={email}
                readOnly
                required
              />
            </div>
            <div className="login-item col-lg-8 mx-auto">
              <label htmlFor="otp">Enter OTP</label>
              <input
                type="text"
                id="otp"
                placeholder="Enter OTP sent on your email ID provided"
                value={otp}
                onChange={handleOtpChange}
                maxLength="6"
                                    autoComplete="off"
                                    onKeyPress={(event) => {
                                        if (event.charCode < 48 || event.charCode > 57 || event.target.value.length >= 15) {
                                            event.preventDefault();
                                        }
                                    }}
                required
              />
              <div className="forgot d-flex justify-content-end mt-1">
              
                  
                  <OtpTimer
                    minutes={0}
                    seconds={60}
                    text={<span className="otp-timer-text">Resend OTP In : </span>}
                    ButtonText={<span className="otp-timer-button">Resend OTP</span>}
                    textColor={"#38a3a5"}
                    buttonColor={"#38a3a5"}
                    background={"#fff"}
                    resend={handleResendOtp}
                  />
                
              </div>
            </div>
            <div className="login-button col-lg-8 mx-auto">
              <button>OTP Verify</button>
            </div>
            <p className="account col-lg-8 mx-auto">
              Remembered your password?{" "}
              <Link to="/login" className="text-decoration-none">
                <span>Login</span>
              </Link>
            </p>
          </form>
        </div>
      </div>
    </div>
  );
};

export default RegOtpVerify;
