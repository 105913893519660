// import moment from "moment";

// export const generateYearMonths = (startDate) => {
//   const start = moment(startDate);
//   const end = start.clone().add(1, "year").add(1, "month");
//   const months = [];

//   while (start.isBefore(end)) {
//     months.push(start.clone());
//     start.add(1, "month");
//   }

//   return months;
// };

import moment from "moment";

export const generateYearMonths = (startYearMonth) => {
  const start = moment(startYearMonth);
  const months = [];
  for (let i = 0; i < 12; i++) {
    months.push(start.clone().add(i, "months"));
  }
  return months;
};
