import React, { useState } from 'react';
import AdminSidebar from './AdminSidebar';
import iziToast from "izitoast";
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import validator from "validator";
import axios from 'axios';

const CorporateAdd = () => {
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();
    const { apipath } = useAuth();
    const [logoImageUrl, setLogoImageUrl] = useState(null);

    const [formData, setFormData] = useState({
        name: '',
        companyName: "",
        phone: '',
        email: '',
        city: '',
        state: '',
        country: '',
        address: '',
        pinCode: '',
        logoImage: '',
        price: '',
        couponQuantity: 0,
        corporateWebSiteName: '',
    });

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: type === 'checkbox' ? checked : value,
        }));

        if (errors[name]) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                [name]: '',
            }));
        }
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const previewUrl = URL.createObjectURL(file);
            setFormData((prevDetails) => ({
                ...prevDetails,
                logoImage: file,
            }));
            setLogoImageUrl(previewUrl);
        }
    };

    const handleRegister = async (e) => {
        e.preventDefault();

        const validationErrors = {};

        // Perform form validation
        if (!formData.name) validationErrors.name = 'Name is required';
        if (!formData.companyName) validationErrors.companyName = 'Company Name is required';
        if (!formData.email) {
            validationErrors.email = 'Email is required';
        } else if (!validator.isEmail(formData.email)) {
            validationErrors.email = 'Invalid email address';
        }
        if (!formData.phone) validationErrors.phone = 'Phone number is required';
        if (!formData.city) validationErrors.city = 'City is required';
        if (!formData.state) validationErrors.state = 'State is required';
        if (!formData.country) validationErrors.country = 'Country is required';
        if (!formData.address) validationErrors.address = 'Address is required';
        if (!formData.pinCode) validationErrors.pinCode = 'Pin Code is required';
        if (!formData.price) validationErrors.price = 'Price is required';
        if (!formData.couponQuantity) validationErrors.couponQuantity = 'Coupon Quantity is required';
        if (!formData.corporateWebSiteName) validationErrors.corporateWebSiteName = 'Corporate WebSite Name is required';

        setErrors(validationErrors);

        if (Object.keys(validationErrors).length > 0) return;


        // Create a FormData object to handle file uploads
        const formDataToSend = new FormData();

        // Append form data to FormData object
        formDataToSend.append('name', formData.name);
        formDataToSend.append('companyName', formData.companyName);
        formDataToSend.append('email', formData.email);
        formDataToSend.append('phone', formData.phone);
        formDataToSend.append('pinCode', formData.pinCode);
        formDataToSend.append('address', formData.address);
        formDataToSend.append('city', formData.city);
        formDataToSend.append('state', formData.state);
        formDataToSend.append('country', formData.country);
        formDataToSend.append('price', formData.price);
        formDataToSend.append('couponQuantity', formData.couponQuantity);
        formDataToSend.append('corporateWebSiteName', formData.corporateWebSiteName);
        formDataToSend.append('logoImage', formData.logoImage);

        try {
            // Send the POST request to upload the form data including the file
            const response = await axios.post(`${apipath}/corporate/add`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );

            if (response.status === 201) {
                iziToast.success({
                    title: 'Success',
                    message: 'Corporate added successfully',
                    position: 'topCenter',
                    timeout: 2000,
                });
                navigate('/corporate');
            } else if (response.status === 400) {
                iziToast.error({
                    title: 'Error',
                    message: 'Email or Mobile No. already exists. Please try another email or mobile no.',
                    position: 'topCenter',
                });
            } else {
                iziToast.error({
                    title: 'Error',
                    message: 'Corporate add failed. Please try again later.',
                    position: 'topCenter',
                });
            }
        } catch (error) {
            console.error("Corporate add error:", error);
            iziToast.error({
                title: 'Error',
                message: 'Corporate add failed. Please try again later.',
                position: 'topCenter',
            });
        }
    };


    return (
        <>
            <AdminSidebar />
            <div className="home">
                <div className="dashboard">
                    <div className="dashboard-header">
                        <h1>Add Corporate</h1>
                    </div>
                    <div className="container-fluid mt-3">
                        <form className="row login-form" onSubmit={handleRegister}>
                            {/* Existing fields */}
                            <div className="mb-3 col-lg-6">
                                <label htmlFor="companyName" className='mb-2'>Company Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Company name"
                                    name="companyName"
                                    value={formData.companyName}
                                    onChange={handleChange}
                                    maxLength="100"
                                // required
                                />
                                {errors.companyName && <div className="text-danger">{errors.companyName}</div>}
                            </div>

                            <div className="mb-3 col-lg-6">
                                <label htmlFor="name" className='mb-2'>Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter name"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                    maxLength="100"
                                // required
                                />
                                {errors.name && <div className="text-danger">{errors.name}</div>}
                            </div>

                            <div className="mb-3 col-lg-6">
                                <label htmlFor="phone" className='mb-2'>Phone Number</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter phone number"
                                    name="phone"
                                    value={formData.phone}
                                    onChange={handleChange}
                                    maxLength="10"
                                    autoComplete="off"
                                    onKeyPress={(event) => {
                                        if (event.charCode < 48 || event.charCode > 57 || event.target.value.length >= 15) {
                                            event.preventDefault();
                                        }
                                    }}
                                // required
                                />
                                {errors.phone && <div className="text-danger">{errors.phone}</div>}
                            </div>

                            <div className="mb-3 col-lg-6">
                                <label htmlFor="email" className='mb-2'>Email</label>
                                <input
                                    type="email"
                                    className="form-control"
                                    placeholder="Enter email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    maxLength="100"
                                // required
                                />
                                {errors.email && <div className="text-danger">{errors.email}</div>}
                            </div>

                            <div className="mb-3 col-lg-6">
                                <label htmlFor="city" className='mb-2'>City</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter city"
                                    name="city"
                                    value={formData.city}
                                    onChange={handleChange}
                                    maxLength="100"
                                // required
                                />
                                {errors.city && <div className="text-danger">{errors.city}</div>}
                            </div>
                            <div className="mb-3 col-lg-6">
                                <label htmlFor="state" className='mb-2'>State</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter state"
                                    name="state"
                                    value={formData.state}
                                    onChange={handleChange}
                                    maxLength="100"
                                // required
                                />
                                {errors.state && <div className="text-danger">{errors.state}</div>}
                            </div>
                            <div className="mb-3 col-lg-6">
                                <label htmlFor="country" className='mb-2'>Country</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter country"
                                    name="country"
                                    value={formData.country}
                                    onChange={handleChange}
                                    maxLength="100"
                                // required
                                />
                                {errors.country && <div className="text-danger">{errors.country}</div>}
                            </div>
                            <div className="mb-3 col-lg-6">
                                <label htmlFor="address" className='mb-2'>Address</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter address"
                                    name="address"
                                    value={formData.address}
                                    onChange={handleChange}
                                    maxLength="100"
                                // required
                                />
                                {errors.address && <div className="text-danger">{errors.address}</div>}
                            </div>

                            <div className="mb-3 col-lg-6">
                                <label htmlFor="pinCode" className='mb-2'>Pin Code</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter pin code"
                                    name="pinCode"
                                    value={formData.pinCode}
                                    onChange={handleChange}
                                    maxLength="6"
                                    autoComplete="off"
                                    onKeyPress={(event) => {
                                        if (event.charCode < 48 || event.charCode > 57 || event.target.value.length >= 15) {
                                            event.preventDefault();
                                        }
                                    }}
                                // required
                                />
                                {errors.pinCode && <div className="text-danger">{errors.pinCode}</div>}
                            </div>

                            <div className="mb-3 col-lg-6">
                                <label htmlFor="price" className='mb-2'>Price</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter price"
                                    name="price"
                                    value={formData.price}
                                    onChange={handleChange}
                                    maxLength="5"
                                    autoComplete="off"
                                    onKeyPress={(event) => {
                                        if (event.charCode < 48 || event.charCode > 57 || event.target.value.length >= 15) {
                                            event.preventDefault();
                                        }
                                    }}
                                // required
                                />
                                {errors.price && <div className="text-danger">{errors.price}</div>}
                            </div>

                            <div className="mb-3 col-lg-6">
                                <label htmlFor="couponQuantity" className='mb-2'>Coupon Quantity</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter coupon quantity"
                                    name="couponQuantity"
                                    value={formData.couponQuantity}
                                    onChange={handleChange}
                                    maxLength="5"
                                    autoComplete="off"
                                    onKeyPress={(event) => {
                                        if (event.charCode < 48 || event.charCode > 57 || event.target.value.length >= 15) {
                                            event.preventDefault();
                                        }
                                    }}
                                // required
                                />
                                {errors.couponQuantity && <div className="text-danger">{errors.couponQuantity}</div>}
                            </div>

                            <div className="mb-3 col-lg-6">
                                <label htmlFor="corporateWebSiteName" className='mb-2'>Corporate WebSite Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter corporate WebSite Name"
                                    name="corporateWebSiteName"
                                    value={formData.corporateWebSiteName}
                                    onChange={handleChange}
                                    maxLength="100"
                                />
                                {errors.corporateWebSiteName && <div className="text-danger">{errors.corporateWebSiteName}</div>}
                            </div>

                            <div className="change-profile pt-4 pb-4">
                                <img
                                    src={logoImageUrl || `${process.env.PUBLIC_URL}/imgs-calendar/images.png`}
                                    width={150}
                                    height={100}
                                    style={{ borderRadius: '35px' }}
                                    alt="profile-img"
                                    onClick={() => document.getElementById('logoImageInput').click()}
                                />
                                <h6>Upload your corporate logo by clicking here,</h6><h6> This logo will appear on the calendar.</h6>
                                <input
                                    className="form-control mt-4"
                                    type="file"
                                    id="logoImageInput"
                                    name="logoImage"
                                    onChange={(e) => handleFileChange(e, 'logoImage')}
                                    style={{ display: 'none' }}
                                />
                            </div>
                            <div className="mb-3 col-lg-12">
                                <button type="submit" className="btn btn-primary">Register</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CorporateAdd;
