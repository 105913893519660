import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import iziToast from "izitoast";
import { useAuth } from "../AuthContext";

const RegVerification = () => {

  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [otpSent, setOtpSent] = useState(false); // State to track if OTP has been sent
  const { apipath } = useAuth();
  
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "auto",
    });
  }, []);
  
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const emailFromParams = urlParams.get("email");
    if (emailFromParams) {
      setEmail(emailFromParams);
    }
  }, []);
  
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleOtpChange = (event) => {
    setOtp(event.target.value);
  };

  const handleResendOtp = (event) => {
    event.preventDefault();
    // Send request to resend OTP
    fetch(apipath + '/mail/forgot-password', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ email }),
    })
      .then(response => {
        if (!response.ok) {
          return response.json().then(err => {
            throw new Error(err.message || 'Incorrect email');
          });
        }
        return response.json();
      })
      .then(data => {
        iziToast.success({
          title: 'Success',
          message: 'OTP sent successfully',
        });
        setOtpSent(true); // Set OTP sent state to true
      })
      .catch(error => {
        console.error('Error:', error);
        iziToast.error({
          title: 'Error',
          message: error.message || 'Failed to resend OTP, Please check your Email id',
        });
      });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    fetch(apipath + '/mail/verify-otp', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ email, otp }),
    })
      .then(response => {
        if (!response.ok) {
          return response.json().then(err => {
            throw new Error(err.message || 'Incorrect otp. Please enter valid Otp');
          });
        }
        return response.json();
      })
      .then(data => {
        iziToast.success({
          title: 'Success',
          message: data.message || 'OTP verified successfully',
        });
        // navigate("/otp-verify", { state: { email } }); // Pass email to the reset page
        navigate("/login"); // Pass email to the reset page
      })
      .catch(error => {
        console.error('Error:', error);
        iziToast.error({
          title: 'Error',
          message: error.message || 'Failed to verify OTP',
        });
      });
  };

  return (
    <>
      <div className="container-fluid login">
        <div className="row login-row">
          <div className="col-lg-6">
            <div className="img-login">
              <img src="imgs-calendar/password.png" alt="" />
            </div>
          </div>
          <div className="col-lg-6 login-right">
            <h1>Verify Account ?</h1>
            <p>No Problem, We can fix that</p>
            <form onSubmit={handleSubmit} className="pt-4">
              <div className="login-item col-lg-8 mx-auto">
                <label htmlFor="name">Enter Email </label>
                <input type="text"
                  placeholder="Enter Your email"
                  value={email}
                  onChange={handleEmailChange}
                  readOnly={otpSent}
                  required />
              </div>
              {otpSent && (
                <div className="login-item col-lg-8 mx-auto">
                  <label htmlFor="name">Enter OTP </label>
                  <input type="text"
                     id="otp"
                      placeholder="Enter OTP sent on your email ID provided"
                      value={otp}
                      onChange={handleOtpChange}
                      maxLength="6"
                                    autoComplete="off"
                                    onKeyPress={(event) => {
                                        if (event.charCode < 48 || event.charCode > 57 || event.target.value.length >= 15) {
                                            event.preventDefault();
                                        }
                                    }}
                    required
                     />
                </div>
              )}
              {!otpSent && (
                <div className="login-button col-lg-8 mx-auto">
                  <button onClick={handleResendOtp}>
                    Send OTP <i className="bi bi-arrow-right"></i>
                  </button>
                </div>
              )}
              {otpSent && (
                <div className="login-button col-lg-8 mx-auto">
                  <button>Continue</button>
                </div>

              )}
              <p className="account col-lg-8 mx-auto">
                Remembered your password ?{" "}
                <Link to="/login" className="text-decoration-none">
                  <span>Login</span>
                </Link>
              </p>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default RegVerification;
