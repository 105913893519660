import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useAuth } from "../AuthContext";
import axios from "axios";
import { format } from "date-fns";

const Page15 = () => {
  const { calendarId, shippingId } = useParams();
  const [startMonth, setStartMonth] = useState("");
  const [endMonth, setEndMonth] = useState("");
  const { apipath } = useAuth();

  useEffect(() => {
    const fetchCalendarData = async () => {
      try {
          
        const response = await axios.get(apipath + `/calendar/details/${calendarId}`);
        const calendarData = response.data.calendar;

        // Extract the startYearMonth and ensure it's a valid Date object
        const startYearMonth = new Date(calendarData.startYearMonth);
        if (isNaN(startYearMonth)) {
          console.error("Invalid date format for startYearMonth:", calendarData.startYearMonth);
          return;
        }

        // Format the start month to the desired month name
        const formattedMonthStart = format(startYearMonth, "MMMM"); // e.g., 'January', 'February'
        setStartMonth(formattedMonthStart.toLowerCase());

        const endYearMonth = new Date(startYearMonth);
        endYearMonth.setMonth(endYearMonth.getMonth() + 11); // Add 11 months to the start date
  
        // Format the end month to the desired month name
        const formattedMonthEnd = format(endYearMonth, "MMMM");
        setEndMonth(formattedMonthEnd.toLowerCase());
  
      } catch (error) {
        console.error("Error fetching calendar data:", error);
      }
    };
  
    if (calendarId && apipath) {
      fetchCalendarData();
    }
  }, [calendarId, apipath]);
  

  return (
    <>
      <div className="container preview">
        <div className="top-buttons">
          <Link to={`/${endMonth}/${calendarId}/${shippingId}`}>
            <button className="pre-button">
              {" "}
              <i className="bi bi-chevron-left"></i> Previous
            </button>
          </Link>
          <Link to={`/preview16/${calendarId}/${shippingId}`}>
            <button className="next-button">
              {" "}
              Next <i className="bi bi-chevron-right"></i>
            </button>
          </Link>
        </div>
      </div>
      {/* <div className="container prev-img">
        <img src={`${process.env.PUBLIC_URL}/imgs-calendar/layout31.png`} alt="" style={{ height: '75vh', border:'2px solid gray', width:'85vw' }} />
      </div> */}
      <div className="container prev-img notepad">
        <div className="notepad-container">
          <div className="header">
            <h2>Notes</h2>
          </div>
          <div className="notepad-body"></div>
        </div>
      </div>
    </>
  );
};

export default Page15;
