// import React, { useEffect, useState } from "react";
// import Navbar from "../components/Navbar";
// import Footer from "../components/Footer";
// import { Link, useNavigate, useParams } from "react-router-dom";
// import iziToast from "izitoast";
// import axios from "axios";
// import { useAuth } from "../AuthContext";

// // const Events = () => {
// //   const [activeTab, setActiveTab] = useState("jan");
// //   const [startYearMonth, setStartYearMonth] = useState(null);
// //   const [holidays, setHolidays] = useState([]);
// //   const [year, setYear] = useState("");
// //   const [month, setMonth] = useState("");
// //   const [daysInMonth, setDaysInMonth] = useState([]);

// //   const openTab = (tabName) => {
// //     setActiveTab(tabName);
// //   };

// //   useEffect(() => {
// //     window.scrollTo({
// //       top: 0,
// //       left: 0,
// //       behavior: "auto",
// //     });
// //   }, []);

// //   const { calendarId } = useParams();
// //   const navigate = useNavigate();
//   // const [formData, setFormData] = useState({

//   //   januaryEvent1Date: "",
//   //   januaryEvent1Caption: "",
//   //   januaryEvent1Color: "",
//   //   januaryEvent2Date: "",
//   //   januaryEvent2Caption: "",
//   //   januaryEvent2Color: "",
//   //   januaryEvent3Date: "",
//   //   januaryEvent3Caption: "",
//   //   januaryEvent3Color: "",
//   //   januaryEvent4Date: "",
//   //   januaryEvent4Caption: "",
//   //   januaryEvent4Color: "",
//   //   februaryEvent1Date: "",
//   //   februaryEvent1Caption: "",
//   //   februaryEvent1Color: "",
//   //   februaryEvent2Date: "",
//   //   februaryEvent2Caption: "",
//   //   februaryEvent2Color: "",
//   //   februaryEvent3Date: "",
//   //   februaryEvent3Caption: "",
//   //   februaryEvent3Color: "",
//   //   februaryEvent4Date: "",
//   //   februaryEvent4Caption: "",
//   //   februaryEvent4Color: "",
//   //   marchEvent1Date: "",
//   //   marchEvent1Caption: "",
//   //   marchEvent1Color: "",
//   //   marchEvent2Date: "",
//   //   marchEvent2Caption: "",
//   //   marchEvent2Color: "",
//   //   marchEvent3Date: "",
//   //   marchEvent3Caption: "",
//   //   marchEvent3Color: "",
//   //   marchEvent4Date: "",
//   //   marchEvent4Caption: "",
//   //   marchEvent4Color: "",
//   //   aprilEvent1Date: "",
//   //   aprilEvent1Caption: "",
//   //   aprilEvent1Color: "",
//   //   aprilEvent2Date: "",
//   //   aprilEvent2Caption: "",
//   //   aprilEvent2Color: "",
//   //   aprilEvent3Date: "",
//   //   aprilEvent3Caption: "",
//   //   aprilEvent3Color: "",
//   //   aprilEvent4Date: "",
//   //   aprilEvent4Caption: "",
//   //   aprilEvent4Color: "",
//   //   mayEvent1Date: "",
//   //   mayEvent1Caption: "",
//   //   mayEvent1Color: "",
//   //   mayEvent2Date: "",
//   //   mayEvent2Caption: "",
//   //   mayEvent2Color: "",
//   //   mayEvent3Date: "",
//   //   mayEvent3Caption: "",
//   //   mayEvent3Color: "",
//   //   mayEvent4Date: "",
//   //   mayEvent4Caption: "",
//   //   mayEvent4Color: "",
//   //   juneEvent1Date: "",
//   //   juneEvent1Caption: "",
//   //   juneEvent1Color: "",
//   //   juneEvent2Date: "",
//   //   juneEvent2Caption: "",
//   //   juneEvent2Color: "",
//   //   juneEvent3Date: "",
//   //   juneEvent3Caption: "",
//   //   juneEvent3Color: "",
//   //   juneEvent4Date: "",
//   //   juneEvent4Caption: "",
//   //   juneEvent4Color: "",
//   //   julyEvent1Date: "",
//   //   julyEvent1Caption: "",
//   //   julyEvent1Color: "",
//   //   julyEvent2Date: "",
//   //   julyEvent2Caption: "",
//   //   julyEvent2Color: "",
//   //   julyEvent3Date: "",
//   //   julyEvent3Caption: "",
//   //   julyEvent3Color: "",
//   //   julyEvent4Date: "",
//   //   julyEvent4Caption: "",
//   //   julyEvent4Color: "",
//   //   augustEvent1Date: "",
//   //   augustEvent1Caption: "",
//   //   augustEvent1Color: "",
//   //   augustEvent2Date: "",
//   //   augustEvent2Caption: "",
//   //   augustEvent2Color: "",
//   //   augustEvent3Date: "",
//   //   augustEvent3Caption: "",
//   //   augustEvent3Color: "",
//   //   augustEvent4Date: "",
//   //   augustEvent4Caption: "",
//   //   augustEvent4Color: "",
//   //   septemberEvent1Date: "",
//   //   septemberEvent1Caption: "",
//   //   septemberEvent1Color: "",
//   //   septemberEvent2Date: "",
//   //   septemberEvent2Caption: "",
//   //   septemberEvent2Color: "",
//   //   septemberEvent3Date: "",
//   //   septemberEvent3Caption: "",
//   //   septemberEvent3Color: "",
//   //   septemberEvent4Date: "",
//   //   septemberEvent4Caption: "",
//   //   septemberEvent4Color: "",
//   //   octoberEvent1Date: "",
//   //   octoberEvent1Caption: "",
//   //   octoberEvent1Color: "",
//   //   octoberEvent2Date: "",
//   //   octoberEvent2Caption: "",
//   //   octoberEvent2Color: "",
//   //   octoberEvent3Date: "",
//   //   octoberEvent3Caption: "",
//   //   octoberEvent3Color: "",
//   //   octoberEvent4Date: "",
//   //   octoberEvent4Caption: "",
//   //   octoberEvent4Color: "",
//   //   novemberEvent1Date: "",
//   //   novemberEvent1Caption: "",
//   //   novemberEvent1Color: "",
//   //   novemberEvent2Date: "",
//   //   novemberEvent2Caption: "",
//   //   novemberEvent2Color: "",
//   //   novemberEvent3Date: "",
//   //   novemberEvent3Caption: "",
//   //   novemberEvent3Color: "",
//   //   novemberEvent4Date: "",
//   //   novemberEvent4Caption: "",
//   //   novemberEvent4Color: "",
//   //   decemberEvent1Date: "",
//   //   decemberEvent1Caption: "",
//   //   decemberEvent1Color: "",
//   //   decemberEvent2Date: "",
//   //   decemberEvent2Caption: "",
//   //   decemberEvent2Color: "",
//   //   decemberEvent3Date: "",
//   //   decemberEvent3Caption: "",
//   //   decemberEvent3Color: "",
//   //   decemberEvent4Date: "",
//   //   decemberEvent4Caption: "",
//   //   decemberEvent4Color: "",

//   // });

// //   const token = localStorage.getItem('token');

// //   const handleChange = (e) => {
// //     const { name, value } = e.target;
// //     setFormData({
// //       ...formData,
// //       [name]: value,
// //     });
// //   };

// //   useEffect(() => {
// //     fetchCalendarEdit();
// //   }, []);

// //   const fetchCalendarEdit = async () => {
// //     try {
// //       const response = await axios.get(`https://giftonly4u.com:4000/api/v1/calendar/details/${calendarId}`);
// //       const calendarData = response.data.calendar;
// //       setFormData(calendarData);
// //       setStartYearMonth(new Date(calendarData.startYearMonth));
// //       const startYearMonth = new Date(calendarData.startYearMonth);
// //       setYear(startYearMonth.getFullYear());
// //       setMonth(startYearMonth.getMonth() + 1); // getMonth() returns 0-11
// //     } catch (error) {
// //       console.error("Error fetching calendar Details:", error);
// //     }
// //   };

// //   useEffect(() => {
// //     if (year && month) {
// //       const days = new Date(year, month, 0).getDate();
// //       setDaysInMonth(Array.from({ length: days }, (_, i) => i + 1));
// //     }
// //   }, [year, month]);

// //   const handleUpdate = async (e) => {
// //     e.preventDefault();
// //     try {
// //       const response = await axios.put(
// //         `https://giftonly4u.com:4000/api/v1/calendar/details/${calendarId}`,
// //         formData,
// //         {
// //           headers: {
// //             'Authorization': `Bearer ${token}`,
// //           },
// //         }
// //       );

// //       if (response.status === 200) {
// //         iziToast.success({
// //           message: "Event update successful",
// //           position: "topCenter",
// //         });
// //       } else {
// //         iziToast.error({
// //           message: "Event update failed",
// //           position: "topCenter",
// //         });
// //       }
// //     } catch (error) {
// //       console.error("Error updating event details:", error);
// //       iziToast.error({
// //         message: "An error occurred",
// //         position: "topCenter",
// //       });
// //     }
// //   };

//   // const getDaysInMonth = (year, month) => {
//   //   return new Array(31).fill('').map((_, index) => new Date(year, month, index + 1))
//   //     .filter(date => date.getMonth() === month)
//   //     .map(date => date.getDate());
//   // };

//   // const renderDateOptions = (monthOffset) => {
//   //   if (!startYearMonth) return null;
//   //   const newDate = new Date(startYearMonth);
//   //   newDate.setMonth(startYearMonth.getMonth() + monthOffset);
//   //   const year = newDate.getFullYear();
//   //   const month = newDate.getMonth();
//   //   const days = getDaysInMonth(year, month);

//   //   return days.map(day => (
//   //     <option key={day} value={day}>{day}</option>
//   //   ));
//   // };

// //   const renderHolidayOptions = () => {
// //     if (!startYearMonth) return null;
// //     const year = startYearMonth.getFullYear();
// //     const month = startYearMonth.getMonth();

// //     return holidays
// //       .filter(holiday => holiday.date.getFullYear() === year && holiday.date.getMonth() === month)
// //       .map((holiday, index) => (
// //         <option key={index} value={holiday.date.getDate()}>{`${holiday.date.getDate()} - ${holiday.summary}`}</option>
// //       ));
// //   };

// //   const fetchHolidays = async () => {
// //     try {
// //       const response = await axios.get(`https://www.googleapis.com/calendar/v3/calendars/en.indian%23holiday%40group.v.calendar.google.com/events?key=AIzaSyAjfBYZVOqpBtwp6Ty55hL9-eXAVD6rKUs`);
// //       const events = response.data.items;
// //       const holidays = events.map(event => ({
// //         summary: event.summary,
// //         date: new Date(event.start.date)
// //       }));
// //       setHolidays(holidays);
// //     } catch (error) {
// //       console.error('Error fetching holidays:', error);
// //     }
// //   };

// //   useEffect(() => {
// //     fetchHolidays();
// //   }, []);

//   // // Function to handle the Remove button click
//   // const handleRemove = (e) => {
//   //   e.preventDefault();
//   //   setFormData({
//   //     ...formData,
//   //     januaryEvent1Date: "",
//   //     januaryEvent1Caption: "",
//   //     januaryEvent1Color: "",
//   //     januaryEvent2Date: "",
//   //     januaryEvent2Caption: "",
//   //     januaryEvent2Color: "",
//   //     januaryEvent3Date: "",
//   //     januaryEvent3Caption: "",
//   //     januaryEvent3Color: "",
//   //     januaryEvent4Date: "",
//   //     januaryEvent4Caption: "",
//   //     januaryEvent4Color: "",
//   //     februaryEvent1Date: "",
//   //     februaryEvent1Caption: "",
//   //     februaryEvent1Color: "",
//   //     februaryEvent2Date: "",
//   //     februaryEvent2Caption: "",
//   //     februaryEvent2Color: "",
//   //     februaryEvent3Date: "",
//   //     februaryEvent3Caption: "",
//   //     februaryEvent3Color: "",
//   //     februaryEvent4Date: "",
//   //     februaryEvent4Caption: "",
//   //     februaryEvent4Color: "",
//   //     marchEvent1Date: "",
//   //     marchEvent1Caption: "",
//   //     marchEvent1Color: "",
//   //     marchEvent2Date: "",
//   //     marchEvent2Caption: "",
//   //     marchEvent2Color: "",
//   //     marchEvent3Date: "",
//   //     marchEvent3Caption: "",
//   //     marchEvent3Color: "",
//   //     marchEvent4Date: "",
//   //     marchEvent4Caption: "",
//   //     marchEvent4Color: "",
//   //     aprilEvent1Date: "",
//   //     aprilEvent1Caption: "",
//   //     aprilEvent1Color: "",
//   //     aprilEvent2Date: "",
//   //     aprilEvent2Caption: "",
//   //     aprilEvent2Color: "",
//   //     aprilEvent3Date: "",
//   //     aprilEvent3Caption: "",
//   //     aprilEvent3Color: "",
//   //     aprilEvent4Date: "",
//   //     aprilEvent4Caption: "",
//   //     aprilEvent4Color: "",
//   //     mayEvent1Date: "",
//   //     mayEvent1Caption: "",
//   //     mayEvent1Color: "",
//   //     mayEvent2Date: "",
//   //     mayEvent2Caption: "",
//   //     mayEvent2Color: "",
//   //     mayEvent3Date: "",
//   //     mayEvent3Caption: "",
//   //     mayEvent3Color: "",
//   //     mayEvent4Date: "",
//   //     mayEvent4Caption: "",
//   //     mayEvent4Color: "",
//   //     juneEvent1Date: "",
//   //     juneEvent1Caption: "",
//   //     juneEvent1Color: "",
//   //     juneEvent2Date: "",
//   //     juneEvent2Caption: "",
//   //     juneEvent2Color: "",
//   //     juneEvent3Date: "",
//   //     juneEvent3Caption: "",
//   //     juneEvent3Color: "",
//   //     juneEvent4Date: "",
//   //     juneEvent4Caption: "",
//   //     juneEvent4Color: "",
//   //     julyEvent1Date: "",
//   //     julyEvent1Caption: "",
//   //     julyEvent1Color: "",
//   //     julyEvent2Date: "",
//   //     julyEvent2Caption: "",
//   //     julyEvent2Color: "",
//   //     julyEvent3Date: "",
//   //     julyEvent3Caption: "",
//   //     julyEvent3Color: "",
//   //     julyEvent4Date: "",
//   //     julyEvent4Caption: "",
//   //     julyEvent4Color: "",
//   //     augustEvent1Date: "",
//   //     augustEvent1Caption: "",
//   //     augustEvent1Color: "",
//   //     augustEvent2Date: "",
//   //     augustEvent2Caption: "",
//   //     augustEvent2Color: "",
//   //     augustEvent3Date: "",
//   //     augustEvent3Caption: "",
//   //     augustEvent3Color: "",
//   //     augustEvent4Date: "",
//   //     augustEvent4Caption: "",
//   //     augustEvent4Color: "",
//   //     septemberEvent1Date: "",
//   //     septemberEvent1Caption: "",
//   //     septemberEvent1Color: "",
//   //     septemberEvent2Date: "",
//   //     septemberEvent2Caption: "",
//   //     septemberEvent2Color: "",
//   //     septemberEvent3Date: "",
//   //     septemberEvent3Caption: "",
//   //     septemberEvent3Color: "",
//   //     septemberEvent4Date: "",
//   //     septemberEvent4Caption: "",
//   //     septemberEvent4Color: "",
//   //     octoberEvent1Date: "",
//   //     octoberEvent1Caption: "",
//   //     octoberEvent1Color: "",
//   //     octoberEvent2Date: "",
//   //     octoberEvent2Caption: "",
//   //     octoberEvent2Color: "",
//   //     octoberEvent3Date: "",
//   //     octoberEvent3Caption: "",
//   //     octoberEvent3Color: "",
//   //     octoberEvent4Date: "",
//   //     octoberEvent4Caption: "",
//   //     octoberEvent4Color: "",
//   //     novemberEvent1Date: "",
//   //     novemberEvent1Caption: "",
//   //     novemberEvent1Color: "",
//   //     novemberEvent2Date: "",
//   //     novemberEvent2Caption: "",
//   //     novemberEvent2Color: "",
//   //     novemberEvent3Date: "",
//   //     novemberEvent3Caption: "",
//   //     novemberEvent3Color: "",
//   //     novemberEvent4Date: "",
//   //     novemberEvent4Caption: "",
//   //     novemberEvent4Color: "",
//   //     decemberEvent1Date: "",
//   //     decemberEvent1Caption: "",
//   //     decemberEvent1Color: "",
//   //     decemberEvent2Date: "",
//   //     decemberEvent2Caption: "",
//   //     decemberEvent2Color: "",
//   //     decemberEvent3Date: "",
//   //     decemberEvent3Caption: "",
//   //     decemberEvent3Color: "",
//   //     decemberEvent4Date: "",
//   //     decemberEvent4Caption: "",
//   //     decemberEvent4Color: "",
//   //   });
//   // };

// //   return (
// //     <>
// //       <Navbar />

//       // <div className="container">
//       //   <div className="col-lg-6 mx-auto image-header">
//       //     <h1>Let’s Make your own personal Calendar</h1>
//       //     <div class="progress-container">
//       //       <div class="progress-line"></div>
//       //       <div class="circle" id="step1">
//       //         {/* <Link to="/image-upload">1</Link> */}
//       //         1
//       //       </div>
//       //       <div class="circle active" id="step2">
//       //         {/* <Link to="/events">2</Link> */}
//       //         2
//       //       </div>
//       //       <div class="circle" id="step3">
//       //         {/* <Link to="/preview">3</Link> */}
//       //         3
//       //       </div>
//       //     </div>
//       //   </div>
// //         <div className="image-content">
// //           <h3>Add Events in your Calendar :</h3>
// //           <div className="row image-row py-3">
// //             <div className="col-lg-3">
// //               <div className="col-lg-8">
// //                 <div className="tab">
// //                   <button
// //                     className={`tablinks  ${activeTab === "jan" && "active"}`}
// //                     onClick={() => openTab("jan")}
// //                     id="jan-button"
// //                   >
// //                     January
// //                   </button>
// //                   <button
// //                     className={`tablinks ${activeTab === "feb" && "active"}`}
// //                     onClick={() => openTab("feb")}
// //                     id="feb-button"
// //                   >
// //                     February
// //                   </button>
// //                   <button
// //                     className={`tablinks ${activeTab === "march" && "active"}`}
// //                     onClick={() => openTab("march")}
// //                     id="march-button"
// //                   >
// //                     March
// //                   </button>
// //                   <button
// //                     className={`tablinks ${activeTab === "april" && "active"}`}
// //                     onClick={() => openTab("april")}
// //                     id="april-button"
// //                   >
// //                     April
// //                   </button>
// //                   <button
// //                     className={`tablinks ${activeTab === "may" && "active"}`}
// //                     onClick={() => openTab("may")}
// //                     id="may-button"
// //                   >
// //                     May
// //                   </button>
// //                   <button
// //                     className={`tablinks ${activeTab === "june" && "active"}`}
// //                     onClick={() => openTab("june")}
// //                     id="june-button"
// //                   >
// //                     June
// //                   </button>
// //                   <button
// //                     className={`tablinks ${activeTab === "july" && "active"}`}
// //                     onClick={() => openTab("july")}
// //                     id="july-button"
// //                   >
// //                     July
// //                   </button>
// //                   <button
// //                     className={`tablinks ${activeTab === "aug" && "active"}`}
// //                     onClick={() => openTab("aug")}
// //                     id="aug-button"
// //                   >
// //                     August
// //                   </button>
// //                   <button
// //                     className={`tablinks ${activeTab === "sept" && "active"}`}
// //                     onClick={() => openTab("sept")}
// //                     id="sept-button"
// //                   >
// //                     September
// //                   </button>
// //                   <button
// //                     className={`tablinks ${activeTab === "oct" && "active"}`}
// //                     onClick={() => openTab("oct")}
// //                     id="oct-button"
// //                   >
// //                     October
// //                   </button>
// //                   <button
// //                     className={`tablinks ${activeTab === "nov" && "active"}`}
// //                     onClick={() => openTab("nov")}
// //                     id="nov-button"
// //                   >
// //                     November
// //                   </button>
// //                   <button
// //                     className={`tablinks ${activeTab === "dec" && "active"}`}
// //                     onClick={() => openTab("dec")}
// //                     id="dec-button"
// //                   >
// //                     December
// //                   </button>
// //                 </div>
// //               </div>
// //             </div>
// //             <div className="col-lg-9">
// //               {/* <div className="holiday">
// //                 <h2>Public Holidays This month : </h2>
// //                 <div className="holiday-list d-flex">
// //                   <div className="holiday-1">
// //                     <li>
// //                       <span className="c1"></span> New Year: January 1{" "}
// //                     </li>
// //                     <li>
// //                       <span className="c2"></span> New Year: January 1{" "}
// //                     </li>
// //                     <li>
// //                       <span className="c3"></span> New Year: January 1{" "}
// //                     </li>
// //                   </div>
// //                   <div className="holiday-1 mx-auto">
// //                     <li>
// //                       <span className="c1"></span> New Year: January 1{" "}
// //                     </li>
// //                     <li>
// //                       <span className="c2"></span> New Year: January 1{" "}
// //                     </li>
// //                      <li>
// //                       <span className="c3"></span> New Year: January 1{" "}
// //                     </li>
// //                   </div>
// //                 </div>
// //               </div> */}
// //               <div className="holiday">
// //                 <h2>Public Holidays This month : </h2>
// //                 <div className="holiday-list d-flex">
// //                   <div className="holiday-1">
// //                   <li>{" "}
// //       {renderHolidayOptions()}</li>
// //       </div>
// //       </div>
// //     </div>
//               // <div
//               //   id="jan"
//               //   className={`events ${activeTab === "jan" ? "active" : ""}`}
//               // >
// //                 <h4>January</h4>
// //                 <div className="event-card">
// //                   <h2>Your Event 1 : </h2>
// //                   <form onSubmit={handleUpdate}>
// //                     <div className="row">
// //                       <div className="col-lg-5">
// //                         <div className="event-item">
// //                           <label htmlFor="">Add Date : </label>
// //                           <select
// //                             className="form-control"
// //                             name="januaryEvent1Date"
// //                             value={formData.januaryEvent1Date}
// //                             onChange={handleChange}
// //                           >
// //                             <option value="">Select Date</option>
// //                             {renderDateOptions(0)}
// //                           </select>
// //                         </div>
// //                       </div>
// //                       <div className="col-lg-5">
// //                         <div className="event-item">
// //                           <label htmlFor="">Select Color : </label>
// //                           <input
// //                             type="color"
// //                             name="januaryEvent1Color"
// //                             id="januaryEvent1Color"
// //                             value={formData.januaryEvent1Color}
// //                             onChange={handleChange}
// //                           />
// //                         </div>
// //                       </div>
// //                       <div className="col-lg-12">
// //                         <div className="event-item">
// //                           <label htmlFor="">Caption : </label>
// //                           <input
// //                             type="text"
// //                             placeholder="Enter Caption (max 20 characters)"
// //                             name="januaryEvent1Caption"
// //                             id="januaryEvent1Caption"
// //                             value={formData.januaryEvent1Caption}
// //                             onChange={handleChange}
// //                             maxLength={20}
// //                           />
// //                         </div>
// //                       </div>
// //                     </div>
// //                     <div className="event-buttons">
// //                       <button className="b1" onClick={(e) => handleRemove(e)}>
// //                         Remove
// //                       </button>
// //                       <button className="b2" type="submit">
// //                         Add
// //                       </button>
// //                     </div>
// //                   </form>
// //                 </div>
// //                 <div className="event-card">
// //                   <h2>Your Event 2 : </h2>
// //                   <form onSubmit={handleUpdate}>
// //                     <div className="row">
// //                       <div className="col-lg-5">
// //                         <div className="event-item">
// //                           <label htmlFor="">Add Date : </label>

// //                           <select
// //                           className="form-control"
// //                   name="januaryEvent2Date"
// //                   id="januaryEvent2Date"
// //                   value={formData.januaryEvent2Date}
// //                   onChange={handleChange}
// //                 >
// //                   <option value="">Select Date</option>
// //                   {renderDateOptions(0)}
// //                 </select>
// //                         </div>
// //                       </div>
// //                       <div className="col-lg-5">
// //                         <div className="event-item">
// //                           <label htmlFor="">Select Color : </label>
// //                           <input
// //                             type="color"
// //                             name="januaryEvent2Color"
// //                             id="januaryEvent2Color"
// //                             value={formData.januaryEvent2Color}
// //                             onChange={handleChange}
// //                           />
// //                         </div>
// //                       </div>
// //                       <div className="col-lg-12">
// //                         <div className="event-item">
// //                           <label htmlFor="">Caption : </label>
// //                           <input
// //                             type="text"
// //                             placeholder="Enter Caption (max 20 characters)"
// //                             name="januaryEvent2Caption"
// //                             id="januaryEvent2Caption"
// //                             value={formData.januaryEvent2Caption}
// //                             onChange={handleChange}
// //                             maxLength={20}
// //                           />
// //                         </div>
// //                       </div>
// //                     </div>
// //                     <div className="event-buttons">
// //                       <button className="b1" onClick={(e) => handleRemove(e)}>
// //                         Remove
// //                       </button>
// //                       <button className="b2" type="submit">
// //                         Add
// //                       </button>
// //                     </div>
// //                   </form>
// //                 </div>
// //                 <div className="event-card">
// //                   <h2>Your Event 3 : </h2>
// //                   <form onSubmit={handleUpdate}>
// //                     <div className="row">
// //                       <div className="col-lg-5">
// //                         <div className="event-item">
// //                           <label htmlFor="">Add Date : </label>
// //                           <select
// //                           className="form-control"
// //                   name="januaryEvent3Date"
// //                   id="januaryEvent3Date"
// //                   value={formData.januaryEvent2Date}
// //                   onChange={handleChange}
// //                 >
// //                   <option value="">Select Date</option>
// //                   {renderDateOptions(0)}
// //                 </select>
// //                         </div>
// //                       </div>
// //                       <div className="col-lg-5">
// //                         <div className="event-item">
// //                           <label htmlFor="">Select Color : </label>
// //                           <input
// //                             type="color"
// //                             name="januaryEvent3Color"
// //                             id="januaryEvent3Color"
// //                             value={formData.januaryEvent3Color}
// //                             onChange={handleChange}
// //                           />
// //                         </div>
// //                       </div>
// //                       <div className="col-lg-12">
// //                         <div className="event-item">
// //                           <label htmlFor="">Caption : </label>
// //                           <input
// //                             type="text"
// //                             placeholder="Enter Caption (max 20 characters)"
// //                             name="januaryEvent3Caption"
// //                             id="januaryEvent3Caption"
// //                             value={formData.januaryEvent3Caption}
// //                             onChange={handleChange}
// //                             maxLength={20}
// //                           />
// //                         </div>
// //                       </div>
// //                     </div>
// //                     <div className="event-buttons">
// //                       <button className="b1" onClick={(e) => handleRemove(e)}>
// //                         Remove
// //                       </button>
// //                       <button className="b2" type="submit">
// //                         Add
// //                       </button>
// //                     </div>
// //                   </form>
// //                 </div>
// //                 <div className="event-card">
// //                   <h2>Your Event 4 : </h2>
// //                   <form onSubmit={handleUpdate}>
// //                     <div className="row">
// //                       <div className="col-lg-5">
// //                         <div className="event-item">
// //                           <label htmlFor="">Add Date : </label>                         
// //                           <select
// //                           className="form-control"
// //                   name="januaryEvent4Date"
// //                   id="januaryEvent4Date"
// //                   value={formData.januaryEvent4Date}
// //                   onChange={handleChange}
// //                 >
// //                   <option value="">Select Date</option>
// //                   {renderDateOptions(0)}
// //                 </select>
// //                         </div>
// //                       </div>
// //                       <div className="col-lg-5">
// //                         <div className="event-item">
// //                           <label htmlFor="">Select Color : </label>
// //                           <input
// //                             type="color"
// //                             name="januaryEvent4Color"
// //                             id="januaryEvent4Color"
// //                             value={formData.januaryEvent4Color}
// //                             onChange={handleChange}
// //                           />
// //                         </div>
// //                       </div>
// //                       <div className="col-lg-12">
// //                         <div className="event-item">
// //                           <label htmlFor="">Caption : </label>
// //                           <input
// //                             type="text"
// //                             placeholder="Enter Caption (max 20 characters)"
// //                             name="januaryEvent4Caption"
// //                             id="januaryEvent4Caption"
// //                             value={formData.januaryEvent4Caption}
// //                             onChange={handleChange}
// //                             maxLength={20}
// //                           />
// //                         </div>
// //                       </div>
// //                     </div>
// //                     <div className="event-buttons">
// //                       <button className="b1" onClick={(e) => handleRemove(e)}>
// //                         Remove
// //                       </button>
// //                       <button className="b2" type="submit">
// //                         Add
// //                       </button>
// //                     </div>
// //                   </form>
// //                 </div>
// //               </div>
// //               <div
// //                 id="feb"
// //                 className={`events ${activeTab === "feb" ? "active" : ""}`}
// //               >
// //                 <h4>February</h4>
// //                 <div className="event-card">
// //                   <h2>Your Event 1 : </h2>
// //                   <form onSubmit={handleUpdate}>
// //                     <div className="row">
// //                       <div className="col-lg-5">
// //                         <div className="event-item">
// //                           <label htmlFor="">Add Date : </label>
// //                            <select
// //                            className="form-control"
// //                   name="februaryEvent1Date"
// //                   id="februaryEvent1Date"
// //                   value={formData.februaryEvent1Date}
// //                   onChange={handleChange}
// //                 >
// //                   <option value="">Select Date</option>
// //                   {renderDateOptions(1)}
// //                 </select>
// //                         </div>
// //                       </div>
// //                       <div className="col-lg-5">
// //                         <div className="event-item">
// //                           <label htmlFor="">Select Color : </label>
// //                           <input
// //                             type="color"
// //                             name="februaryEvent1Color"
// //                             id="februaryEvent1Color"
// //                             value={formData.februaryEvent1Color}
// //                             onChange={handleChange}
// //                           />
// //                         </div>
// //                       </div>
// //                       <div className="col-lg-12">
// //                         <div className="event-item">
// //                           <label htmlFor="">Caption : </label>
// //                           <input
// //                             type="text"
// //                             placeholder="Enter Caption (max 20 characters)"
// //                             name="februaryEvent1Caption"
// //                             id="februaryEvent1Caption"
// //                             value={formData.februaryEvent1Caption}
// //                             onChange={handleChange}
// //                             maxLength={20}
// //                           />
// //                         </div>
// //                       </div>
// //                     </div>
// //                     <div className="event-buttons">
// //                       <button className="b1" onClick={(e) => handleRemove(e)}>
// //                         Remove
// //                       </button>
// //                       <button className="b2" type="submit">
// //                         Add
// //                       </button>
// //                     </div>
// //                   </form>
// //                 </div>
// //                 <div className="event-card">
// //                   <h2>Your Event 2 : </h2>
// //                   <form onSubmit={handleUpdate}>
// //                     <div className="row">
// //                       <div className="col-lg-5">
// //                         <div className="event-item">
// //                           <label htmlFor="">Add Date : </label>
// //                           <select 
// //                           className="form-control"
// //                   name="februaryEvent2Date"
// //                   id="februaryEvent2Date"
// //                   value={formData.februaryEvent2Date}
// //                   onChange={handleChange}
// //                 >
// //                   <option value="">Select Date</option>
// //                   {renderDateOptions(1)}
// //                 </select>
// //                         </div>
// //                       </div>
// //                       <div className="col-lg-5">
// //                         <div className="event-item">
// //                           <label htmlFor="">Select Color : </label>
// //                           <input
// //                             type="color"
// //                             name="februaryEvent2Color"
// //                             id="februaryEvent2Color"
// //                             value={formData.februaryEvent2Color}
// //                             onChange={handleChange}
// //                           />
// //                         </div>
// //                       </div>
// //                       <div className="col-lg-12">
// //                         <div className="event-item">
// //                           <label htmlFor="">Caption : </label>
// //                           <input
// //                             type="text"
// //                             placeholder="Enter Caption (max 20 characters)"
// //                             name="februaryEvent2Caption"
// //                             id="februaryEvent2Caption"
// //                             value={formData.februaryEvent2Caption}
// //                             onChange={handleChange}
// //                             maxLength={20}
// //                           />
// //                         </div>
// //                       </div>
// //                     </div>
// //                     <div className="event-buttons">
// //                       <button className="b1" onClick={(e) => handleRemove(e)}>
// //                         Remove
// //                       </button>
// //                       <button className="b2" type="submit">
// //                         Add
// //                       </button>
// //                     </div>
// //                   </form>
// //                 </div>
// //                 <div className="event-card">
// //                   <h2>Your Event 3 : </h2>
// //                   <form onSubmit={handleUpdate}>
// //                     <div className="row">
// //                       <div className="col-lg-5">
// //                         <div className="event-item">
// //                           <label htmlFor="">Add Date : </label>
// //                           <select
// //                           className="form-control"
// //                   name="februaryEvent3Date"
// //                   id="februaryEvent3Date"
// //                   value={formData.februaryEvent3Date}
// //                   onChange={handleChange}
// //                 >
// //                   <option value="">Select Date</option>
// //                   {renderDateOptions(1)}
// //                 </select>
// //                         </div>
// //                       </div>
// //                       <div className="col-lg-5">
// //                         <div className="event-item">
// //                           <label htmlFor="">Select Color : </label>
// //                           <input
// //                             type="color"
// //                             name="februaryEvent3Color"
// //                             id="februaryEvent3Color"
// //                             value={formData.februaryEvent3Color}
// //                             onChange={handleChange}
// //                           />
// //                         </div>
// //                       </div>
// //                       <div className="col-lg-12">
// //                         <div className="event-item">
// //                           <label htmlFor="">Caption : </label>
// //                           <input
// //                             type="text"
// //                             placeholder="Enter Caption (max 20 characters)"
// //                             name="februaryEvent3Caption"
// //                             id="februaryEvent3Caption"
// //                             value={formData.februaryEvent3Caption}
// //                             onChange={handleChange}
// //                             maxLength={20}
// //                           />
// //                         </div>
// //                       </div>
// //                     </div>
// //                     <div className="event-buttons">
// //                       <button className="b1" onClick={(e) => handleRemove(e)}>
// //                         Remove
// //                       </button>
// //                       <button className="b2" type="submit">
// //                         Add
// //                       </button>
// //                     </div>
// //                   </form>
// //                 </div>
// //                 <div className="event-card">
// //                   <h2>Your Event 4 : </h2>
// //                   <form onSubmit={handleUpdate}>
// //                     <div className="row">
// //                       <div className="col-lg-5">
// //                         <div className="event-item">
// //                           <label htmlFor="">Add Date : </label>
// //                           <select
// //                           className="form-control"
// //                   name="februaryEvent4Date"
// //                   id="februaryEvent4Date"
// //                   value={formData.februaryEvent4Date}
// //                   onChange={handleChange}
// //                 >
// //                   <option value="">Select Date</option>
// //                   {renderDateOptions(1)}
// //                 </select>
// //                         </div>
// //                       </div>
// //                       <div className="col-lg-5">
// //                         <div className="event-item">
// //                           <label htmlFor="">Select Color : </label>
// //                           <input
// //                             type="color"
// //                             name="februaryEvent4Color"
// //                             id="februaryEvent4Color"
// //                             value={formData.februaryEvent4Color}
// //                             onChange={handleChange}
// //                           />
// //                         </div>
// //                       </div>
// //                       <div className="col-lg-12">
// //                         <div className="event-item">
// //                           <label htmlFor="">Caption : </label>
// //                           <input
// //                             type="text"
// //                             placeholder="Enter Caption (max 20 characters)"
// //                             name="februaryEvent4Caption"
// //                             id="februaryEvent4Caption"
// //                             value={formData.februaryEvent4Caption}
// //                             onChange={handleChange}
// //                             maxLength={20}
// //                           />
// //                         </div>
// //                       </div>
// //                     </div>
// //                     <div className="event-buttons">
// //                       <button className="b1" onClick={(e) => handleRemove(e)}>
// //                         Remove
// //                       </button>
// //                       <button className="b2" type="submit">
// //                         Add
// //                       </button>
// //                     </div>
// //                   </form>
// //                 </div>
// //               </div>
// //               <div
// //                 id="march"
// //                 className={`events ${activeTab === "march" ? "active" : ""}`}
// //               >
// //                 <h4>March</h4>
// //                 <div className="event-card">
// //                   <h2>Your Event 1 : </h2>
// //                   <form onSubmit={handleUpdate}>
// //                     <div className="row">
// //                       <div className="col-lg-5">
// //                         <div className="event-item">
// //                           <label htmlFor="">Add Date : </label>
// //                           <select
// //                           className="form-control"
// //                   name="marchEvent1Date"
// //                   id="marchEvent1Date"
// //                   value={formData.marchEvent1Date}
// //                   onChange={handleChange}
// //                 >
// //                   <option value="">Select Date</option>
// //                   {renderDateOptions(2)}
// //                 </select>
// //                         </div>
// //                       </div>
// //                       <div className="col-lg-5">
// //                         <div className="event-item">
// //                           <label htmlFor="">Select Color : </label>
// //                           <input
// //                             type="color"
// //                             name="marchEvent1Color"
// //                             id="marchEvent1Color"
// //                             value={formData.marchEvent1Color}
// //                             onChange={handleChange}
// //                           />
// //                         </div>
// //                       </div>
// //                       <div className="col-lg-12">
// //                         <div className="event-item">
// //                           <label htmlFor="">Caption : </label>
// //                           <input
// //                             type="text"
// //                             placeholder="Enter Caption (max 20 characters)"
// //                             name="marchEvent1Caption"
// //                             id="marchEvent1Caption"
// //                             value={formData.marchEvent1Caption}
// //                             onChange={handleChange}
// //                             maxLength={20}
// //                           />
// //                         </div>
// //                       </div>
// //                     </div>
// //                     <div className="event-buttons">
// //                       <button className="b1" onClick={(e) => handleRemove(e)}>
// //                         Remove
// //                       </button>
// //                       <button className="b2" type="submit">
// //                         Add
// //                       </button>
// //                     </div>
// //                   </form>
// //                 </div>
// //                 <div className="event-card">
// //                   <h2>Your Event 2 : </h2>
// //                   <form onSubmit={handleUpdate}>
// //                     <div className="row">
// //                       <div className="col-lg-5">
// //                         <div className="event-item">
// //                           <label htmlFor="">Add Date : </label>
// //                           <select
// //                           className="form-control"
// //                   name="marchEvent2Date"
// //                   id="marchEvent2Date"
// //                   value={formData.marchEvent2Date}
// //                   onChange={handleChange}
// //                 >
// //                   <option value="">Select Date</option>
// //                   {renderDateOptions(2)}
// //                 </select>
// //                         </div>
// //                       </div>
// //                       <div className="col-lg-5">
// //                         <div className="event-item">
// //                           <label htmlFor="">Select Color : </label>
// //                           <input
// //                             type="color"
// //                             name="marchEvent2Color"
// //                             id="marchEvent2Color"
// //                             value={formData.marchEvent2Color}
// //                             onChange={handleChange}
// //                           />
// //                         </div>
// //                       </div>
// //                       <div className="col-lg-12">
// //                         <div className="event-item">
// //                           <label htmlFor="">Caption : </label>
// //                           <input
// //                             type="text"
// //                             placeholder="Enter Caption (max 20 characters)"
// //                             name="marchEvent2Caption"
// //                             id="marchEvent2Caption"
// //                             value={formData.marchEvent2Caption}
// //                             onChange={handleChange}
// //                             maxLength={20}
// //                           />
// //                         </div>
// //                       </div>
// //                     </div>
// //                     <div className="event-buttons">
// //                       <button className="b1" onClick={(e) => handleRemove(e)}>
// //                         Remove
// //                       </button>
// //                       <button className="b2" type="submit">
// //                         Add
// //                       </button>
// //                     </div>
// //                   </form>
// //                 </div>
// //                 <div className="event-card">
// //                   <h2>Your Event 3 : </h2>
// //                   <form onSubmit={handleUpdate}>
// //                     <div className="row">
// //                       <div className="col-lg-5">
// //                         <div className="event-item">
// //                           <label htmlFor="">Add Date : </label>
// //                           <select
// //                           className="form-control"
// //                   name="marchEvent3Date"
// //                   id="marchEvent3Date"
// //                   value={formData.marchEvent3Date}
// //                   onChange={handleChange}
// //                 >
// //                   <option value="">Select Date</option>
// //                   {renderDateOptions(2)}
// //                 </select>
// //                         </div>
// //                       </div>
// //                       <div className="col-lg-5">
// //                         <div className="event-item">
// //                           <label htmlFor="">Select Color : </label>
// //                           <input
// //                             type="color"
// //                             name="marchEvent3Color"
// //                             id="marchEvent3Color"
// //                             value={formData.marchEvent3Color}
// //                             onChange={handleChange}
// //                           />
// //                         </div>
// //                       </div>
// //                       <div className="col-lg-12">
// //                         <div className="event-item">
// //                           <label htmlFor="">Caption : </label>
// //                           <input
// //                             type="text"
// //                             placeholder="Enter Caption (max 20 characters)"
// //                             name="marchEvent3Caption"
// //                             id="marchEvent3Caption"
// //                             value={formData.marchEvent3Caption}
// //                             onChange={handleChange}
// //                             maxLength={20}
// //                           />
// //                         </div>
// //                       </div>
// //                     </div>
// //                     <div className="event-buttons">
// //                       <button className="b1" onClick={(e) => handleRemove(e)}>
// //                         Remove
// //                       </button>
// //                       <button className="b2" type="submit">
// //                         Add
// //                       </button>
// //                     </div>
// //                   </form>
// //                 </div>
// //                 <div className="event-card">
// //                   <h2>Your Event 4 : </h2>
// //                   <form onSubmit={handleUpdate}>
// //                     <div className="row">
// //                       <div className="col-lg-5">
// //                         <div className="event-item">
// //                           <label htmlFor="">Add Date : </label>
// //                           <select
// //                           className="form-control"
// //                   name="marchEvent4Date"
// //                   id="marchEvent4Date"
// //                   value={formData.marchEvent4Date}
// //                   onChange={handleChange}
// //                 >
// //                   <option value="">Select Date</option>
// //                   {renderDateOptions(2)}
// //                 </select>
// //                         </div>
// //                       </div>
// //                       <div className="col-lg-5">
// //                         <div className="event-item">
// //                           <label htmlFor="">Select Color : </label>
// //                           <input
// //                             type="color"
// //                             name="marchEvent4Color"
// //                             id="marchEvent4Color"
// //                             value={formData.marchEvent4Color}
// //                             onChange={handleChange}
// //                           />
// //                         </div>
// //                       </div>
// //                       <div className="col-lg-12">
// //                         <div className="event-item">
// //                           <label htmlFor="">Caption : </label>
// //                           <input
// //                             type="text"
// //                             placeholder="Enter Caption (max 20 characters)"
// //                             name="marchEvent4Caption"
// //                             id="marchEvent4Caption"
// //                             value={formData.marchEvent4Caption}
// //                             onChange={handleChange}
// //                             maxLength={20}
// //                           />
// //                         </div>
// //                       </div>
// //                     </div>
// //                     <div className="event-buttons">
// //                       <button className="b1" onClick={(e) => handleRemove(e)}>
// //                         Remove
// //                       </button>
// //                       <button className="b2" type="submit">
// //                         Add
// //                       </button>
// //                     </div>
// //                   </form>
// //                 </div>
// //               </div>
// //               <div
// //                 id="april"
// //                 className={`events ${activeTab === "april" ? "active" : ""}`}
// //               >
// //                 <h4>April</h4>
// //                 <div className="event-card">
// //                   <h2>Your Event 1 : </h2>
// //                   <form onSubmit={handleUpdate}>
// //                     <div className="row">
// //                       <div className="col-lg-5">
// //                         <div className="event-item">
// //                           <label htmlFor="">Add Date : </label>
// //                           <select
// //                           className="form-control"
// //                   name="aprilEvent1Date"
// //                   id="aprilEvent1Date"
// //                   value={formData.aprilEvent1Date}
// //                   onChange={handleChange}
// //                 >
// //                   <option value="">Select Date</option>
// //                   {renderDateOptions(3)}
// //                 </select>
// //                         </div>
// //                       </div>
// //                       <div className="col-lg-5">
// //                         <div className="event-item">
// //                           <label htmlFor="">Select Color : </label>
// //                           <input
// //                             type="color"
// //                             name="aprilEvent1Color"
// //                             id="aprilEvent1Color"
// //                             value={formData.aprilEvent1Color}
// //                             onChange={handleChange}
// //                           />
// //                         </div>
// //                       </div>
// //                       <div className="col-lg-12">
// //                         <div className="event-item">
// //                           <label htmlFor="">Caption : </label>
// //                           <input
// //                             type="text"
// //                             placeholder="Enter Caption (max 20 characters)"
// //                             name="aprilEvent1Caption"
// //                             id="aprilEvent1Caption"
// //                             value={formData.aprilEvent1Caption}
// //                             onChange={handleChange}
// //                             maxLength={20}
// //                           />
// //                         </div>
// //                       </div>
// //                     </div>
// //                     <div className="event-buttons">
// //                       <button className="b1" onClick={(e) => handleRemove(e)}>
// //                         Remove
// //                       </button>
// //                       <button className="b2" type="submit">
// //                         Add
// //                       </button>
// //                     </div>
// //                   </form>
// //                 </div>
// //                 <div className="event-card">
// //                   <h2>Your Event 2 : </h2>
// //                   <form onSubmit={handleUpdate}>
// //                     <div className="row">
// //                       <div className="col-lg-5">
// //                         <div className="event-item">
// //                           <label htmlFor="">Add Date : </label>
// //                           <select
// //                           className="form-control"
// //                   name="aprilEvent2Date"
// //                   id="aprilEvent2Date"
// //                   value={formData.aprilEvent2Date}
// //                   onChange={handleChange}
// //                 >
// //                   <option value="">Select Date</option>
// //                   {renderDateOptions(3)}
// //                 </select>
// //                         </div>
// //                       </div>
// //                       <div className="col-lg-5">
// //                         <div className="event-item">
// //                           <label htmlFor="">Select Color : </label>
// //                           <input
// //                             type="color"
// //                             name="aprilEvent2Color"
// //                             id="aprilEvent2Color"
// //                             value={formData.aprilEvent2Color}
// //                             onChange={handleChange}
// //                           />
// //                         </div>
// //                       </div>
// //                       <div className="col-lg-12">
// //                         <div className="event-item">
// //                           <label htmlFor="">Caption : </label>
// //                           <input
// //                             type="text"
// //                             placeholder="Enter Caption (max 20 characters)"
// //                             name="aprilEvent2Caption"
// //                             id="aprilEvent2Caption"
// //                             value={formData.aprilEvent2Caption}
// //                             onChange={handleChange}
// //                             maxLength={20}
// //                           />
// //                         </div>
// //                       </div>
// //                     </div>
// //                     <div className="event-buttons">
// //                       <button className="b1" onClick={(e) => handleRemove(e)}>
// //                         Remove
// //                       </button>
// //                       <button className="b2" type="submit">
// //                         Add
// //                       </button>
// //                     </div>
// //                   </form>
// //                 </div>
// //                 <div className="event-card">
// //                   <h2>Your Event 3 : </h2>
// //                   <form onSubmit={handleUpdate}>
// //                     <div className="row">
// //                       <div className="col-lg-5">
// //                         <div className="event-item">
// //                           <label htmlFor="">Add Date : </label>
// //                           <select
// //                           className="form-control"
// //                   name="aprilEvent3Date"
// //                   id="aprilEvent3Date"
// //                   value={formData.aprilEvent3Date}
// //                   onChange={handleChange}
// //                 >
// //                   <option value="">Select Date</option>
// //                   {renderDateOptions(3)}
// //                 </select>
// //                         </div>
// //                       </div>
// //                       <div className="col-lg-5">
// //                         <div className="event-item">
// //                           <label htmlFor="">Select Color : </label>
// //                           <input
// //                             type="color"
// //                             name="aprilEvent3Color"
// //                             id="aprilEvent3Color"
// //                             value={formData.aprilEvent3Color}
// //                             onChange={handleChange}
// //                           />
// //                         </div>
// //                       </div>
// //                       <div className="col-lg-12">
// //                         <div className="event-item">
// //                           <label htmlFor="">Caption : </label>
// //                           <input
// //                             type="text"
// //                             placeholder="Enter Caption (max 20 characters)"
// //                             name="aprilEvent3Caption"
// //                             id="aprilEvent3Caption"
// //                             value={formData.aprilEvent3Caption}
// //                             onChange={handleChange}
// //                             maxLength={20}
// //                           />
// //                         </div>
// //                       </div>
// //                     </div>
// //                     <div className="event-buttons">
// //                       <button className="b1" onClick={(e) => handleRemove(e)}>
// //                         Remove
// //                       </button>
// //                       <button className="b2" type="submit">
// //                         Add
// //                       </button>
// //                     </div>
// //                   </form>
// //                 </div>
// //                 <div className="event-card">
// //                   <h2>Your Event 4 : </h2>
// //                   <form onSubmit={handleUpdate}>
// //                     <div className="row">
// //                       <div className="col-lg-5">
// //                         <div className="event-item">
// //                           <label htmlFor="">Add Date : </label>
// //                           <select
// //                           className="form-control"
// //                   name="aprilEvent4Date"
// //                   id="aprilEvent4Date"
// //                   value={formData.aprilEvent4Date}
// //                   onChange={handleChange}
// //                 >
// //                   <option value="">Select Date</option>
// //                   {renderDateOptions(3)}
// //                 </select>
// //                         </div>
// //                       </div>
// //                       <div className="col-lg-5">
// //                         <div className="event-item">
// //                           <label htmlFor="">Select Color : </label>
// //                           <input
// //                             type="color"
// //                             name="aprilEvent4Color"
// //                             id="aprilEvent4Color"
// //                             value={formData.aprilEvent4Color}
// //                             onChange={handleChange}
// //                           />
// //                         </div>
// //                       </div>
// //                       <div className="col-lg-12">
// //                         <div className="event-item">
// //                           <label htmlFor="">Caption : </label>
// //                           <input
// //                             type="text"
// //                             placeholder="Enter Caption (max 20 characters)"
// //                             name="aprilEvent4Caption"
// //                             id="aprilEvent4Caption"
// //                             value={formData.aprilEvent4Caption}
// //                             onChange={handleChange}
// //                             maxLength={20}
// //                           />
// //                         </div>
// //                       </div>
// //                     </div>
// //                     <div className="event-buttons">
// //                       <button className="b1" onClick={(e) => handleRemove(e)}>
// //                         Remove
// //                       </button>
// //                       <button className="b2" type="submit">
// //                         Add
// //                       </button>
// //                     </div>
// //                   </form>
// //                 </div>
// //               </div>
// //               <div
// //                 id="may"
// //                 className={`events ${activeTab === "may" ? "active" : ""}`}
// //               >
// //                 <h4>May</h4>
// //                 <div className="event-card">
// //                   <h2>Your Event 1 : </h2>
// //                   <form onSubmit={handleUpdate}>
// //                     <div className="row">
// //                       <div className="col-lg-5">
// //                         <div className="event-item">
// //                           <label htmlFor="">Add Date : </label>
// //                            <select
// //                            className="form-control"
// //                   name="mayEvent1Date"
// //                   id="mayEvent1Date"
// //                   value={formData.mayEvent1Date}
// //                   onChange={handleChange}
// //                 >
// //                   <option value="">Select Date</option>
// //                   {renderDateOptions(4)}
// //                 </select>
// //                         </div>
// //                       </div>
// //                       <div className="col-lg-5">
// //                         <div className="event-item">
// //                           <label htmlFor="">Select Color : </label>
// //                           <input
// //                             type="color"
// //                             name="mayEvent1Color"
// //                             id="mayEvent1Color"
// //                             value={formData.mayEvent1Color}
// //                             onChange={handleChange}
// //                           />
// //                         </div>
// //                       </div>
// //                       <div className="col-lg-12">
// //                         <div className="event-item">
// //                           <label htmlFor="">Caption : </label>
// //                           <input
// //                             type="text"
// //                             placeholder="Enter Caption (max 20 characters)"
// //                             name="mayEvent1Caption"
// //                             id="mayEvent1Caption"
// //                             value={formData.mayEvent1Caption}
// //                             onChange={handleChange}
// //                             maxLength={20}
// //                           />
// //                         </div>
// //                       </div>
// //                     </div>
// //                     <div className="event-buttons">
// //                       <button className="b1" onClick={(e) => handleRemove(e)}>
// //                         Remove
// //                       </button>
// //                       <button className="b2" type="submit">
// //                         Add
// //                       </button>
// //                     </div>
// //                   </form>
// //                 </div>
// //                 <div className="event-card">
// //                   <h2>Your Event 2 : </h2>
// //                   <form onSubmit={handleUpdate}>
// //                     <div className="row">
// //                       <div className="col-lg-5">
// //                         <div className="event-item">
// //                           <label htmlFor="">Add Date : </label>
// //                           <select
// //                           className="form-control"
// //                   name="mayEvent2Date"
// //                   id="mayEvent2Date"
// //                   value={formData.mayEvent2Date}
// //                   onChange={handleChange}
// //                 >
// //                   <option value="">Select Date</option>
// //                   {renderDateOptions(4)}
// //                 </select>
// //                         </div>
// //                       </div>
// //                       <div className="col-lg-5">
// //                         <div className="event-item">
// //                           <label htmlFor="">Select Color : </label>
// //                           <input
// //                             type="color"
// //                             name="mayEvent2Color"
// //                             id="mayEvent2Color"
// //                             value={formData.mayEvent2Color}
// //                             onChange={handleChange}
// //                           />
// //                         </div>
// //                       </div>
// //                       <div className="col-lg-12">
// //                         <div className="event-item">
// //                           <label htmlFor="">Caption : </label>
// //                           <input
// //                             type="text"
// //                             placeholder="Enter Caption (max 20 characters)"
// //                             name="mayEvent2Caption"
// //                             id="mayEvent2Caption"
// //                             value={formData.mayEvent2Caption}
// //                             onChange={handleChange}
// //                             maxLength={20}
// //                           />
// //                         </div>
// //                       </div>
// //                     </div>
// //                     <div className="event-buttons">
// //                       <button className="b1" onClick={(e) => handleRemove(e)}>
// //                         Remove
// //                       </button>
// //                       <button className="b2" type="submit">
// //                         Add
// //                       </button>
// //                     </div>
// //                   </form>
// //                 </div>
// //                 <div className="event-card">
// //                   <h2>Your Event 3 : </h2>
// //                   <form onSubmit={handleUpdate}>
// //                     <div className="row">
// //                       <div className="col-lg-5">
// //                         <div className="event-item">
// //                           <label htmlFor="">Add Date : </label>
// //                           <select
// //                           className="form-control"
// //                   name="mayEvent3Date"
// //                   id="mayEvent3Date"
// //                   value={formData.mayEvent3Date}
// //                   onChange={handleChange}
// //                 >
// //                   <option value="">Select Date</option>
// //                   {renderDateOptions(4)}
// //                 </select>
// //                         </div>
// //                       </div>
// //                       <div className="col-lg-5">
// //                         <div className="event-item">
// //                           <label htmlFor="">Select Color : </label>
// //                           <input
// //                             type="color"
// //                             name="mayEvent3Color"
// //                             id="mayEvent3Color"
// //                             value={formData.mayEvent3Color}
// //                             onChange={handleChange}
// //                           />
// //                         </div>
// //                       </div>
// //                       <div className="col-lg-12">
// //                         <div className="event-item">
// //                           <label htmlFor="">Caption : </label>
// //                           <input
// //                             type="text"
// //                             placeholder="Enter Caption (max 20 characters)"
// //                             name="mayEvent3Caption"
// //                             id="mayEvent3Caption"
// //                             value={formData.mayEvent3Caption}
// //                             onChange={handleChange}
// //                             maxLength={20}
// //                           />
// //                         </div>
// //                       </div>
// //                     </div>
// //                     <div className="event-buttons">
// //                       <button className="b1" onClick={(e) => handleRemove(e)}>
// //                         Remove
// //                       </button>
// //                       <button className="b2" type="submit">
// //                         Add
// //                       </button>
// //                     </div>
// //                   </form>
// //                 </div>
// //                 <div className="event-card">
// //                   <h2>Your Event 4 : </h2>
// //                   <form onSubmit={handleUpdate}>
// //                     <div className="row">
// //                       <div className="col-lg-5">
// //                         <div className="event-item">
// //                           <label htmlFor="">Add Date : </label>
// //                           <select
// //                           className="form-control"
// //                   name="mayEvent4Date"
// //                   id="mayEvent4Date"
// //                   value={formData.mayEvent4Date}
// //                   onChange={handleChange}
// //                 >
// //                   <option value="">Select Date</option>
// //                   {renderDateOptions(4)}
// //                 </select>
// //                         </div>
// //                       </div>
// //                       <div className="col-lg-5">
// //                         <div className="event-item">
// //                           <label htmlFor="">Select Color : </label>
// //                           <input
// //                             type="color"
// //                             name="mayEvent4Color"
// //                             id="mayEvent4Color"
// //                             value={formData.mayEvent4Color}
// //                             onChange={handleChange}
// //                           />
// //                         </div>
// //                       </div>
// //                       <div className="col-lg-12">
// //                         <div className="event-item">
// //                           <label htmlFor="">Caption : </label>
// //                           <input
// //                             type="text"
// //                             placeholder="Enter Caption (max 20 characters)"
// //                             name="mayEvent4Caption"
// //                             id="mayEvent4Caption"
// //                             value={formData.mayEvent4Caption}
// //                             onChange={handleChange}
// //                             maxLength={20}
// //                           />
// //                         </div>
// //                       </div>
// //                     </div>
// //                     <div className="event-buttons">
// //                       <button className="b1" onClick={(e) => handleRemove(e)}>
// //                         Remove
// //                       </button>
// //                       <button className="b2" type="submit">
// //                         Add
// //                       </button>
// //                     </div>
// //                   </form>
// //                 </div>
// //               </div>
// //               <div
// //                 id="june"
// //                 className={`events ${activeTab === "june" ? "active" : ""}`}
// //               >
// //                 <h4>June</h4>
// //                 <div className="event-card">
// //                   <h2>Your Event 1 : </h2>
// //                   <form onSubmit={handleUpdate}>
// //                     <div className="row">
// //                       <div className="col-lg-5">
// //                         <div className="event-item">
// //                           <label htmlFor="">Add Date : </label>
// //                           <select
// //                           className="form-control"
// //                   name="juneEvent1Date"
// //                   id="juneEvent1Date"
// //                   value={formData.juneEvent1Date}
// //                   onChange={handleChange}
// //                 >
// //                   <option value="">Select Date</option>
// //                   {renderDateOptions(5)}
// //                 </select>
// //                         </div>
// //                       </div>
// //                       <div className="col-lg-5">
// //                         <div className="event-item">
// //                           <label htmlFor="">Select Color : </label>
// //                           <input
// //                             type="color"
// //                             name="juneEvent1Color"
// //                             id="juneEvent1Color"
// //                             value={formData.juneEvent1Color}
// //                             onChange={handleChange}
// //                           />
// //                         </div>
// //                       </div>
// //                       <div className="col-lg-12">
// //                         <div className="event-item">
// //                           <label htmlFor="">Caption : </label>
// //                           <input
// //                             type="text"
// //                             placeholder="Enter Caption (max 20 characters)"
// //                             name="juneEvent1Caption"
// //                             id="juneEvent1Caption"
// //                             value={formData.juneEvent1Caption}
// //                             onChange={handleChange}
// //                             maxLength={20}
// //                           />
// //                         </div>
// //                       </div>
// //                     </div>
// //                     <div className="event-buttons">
// //                       <button className="b1" onClick={(e) => handleRemove(e)}>
// //                         Remove
// //                       </button>
// //                       <button className="b2" type="submit">
// //                         Add
// //                       </button>
// //                     </div>
// //                   </form>
// //                 </div>
// //                 <div className="event-card">
// //                   <h2>Your Event 2 : </h2>
// //                   <form onSubmit={handleUpdate}>
// //                     <div className="row">
// //                       <div className="col-lg-5">
// //                         <div className="event-item">
// //                           <label htmlFor="">Add Date : </label>
// //                           <select
// //                           className="form-control"
// //                   name="juneEvent2Date"
// //                   id="juneEvent2Date"
// //                   value={formData.juneEvent2Date}
// //                   onChange={handleChange}
// //                 >
// //                   <option value="">Select Date</option>
// //                   {renderDateOptions(5)}
// //                 </select>
// //                         </div>
// //                       </div>
// //                       <div className="col-lg-5">
// //                         <div className="event-item">
// //                           <label htmlFor="">Select Color : </label>
// //                           <input
// //                             type="color"
// //                             name="juneEvent2Color"
// //                             id="juneEvent2Color"
// //                             value={formData.juneEvent2Color}
// //                             onChange={handleChange}
// //                           />
// //                         </div>
// //                       </div>
// //                       <div className="col-lg-12">
// //                         <div className="event-item">
// //                           <label htmlFor="">Caption : </label>
// //                           <input
// //                             type="text"
// //                             placeholder="Enter Caption (max 20 characters)"
// //                             name="juneEvent2Caption"
// //                             id="juneEvent2Caption"
// //                             value={formData.juneEvent2Caption}
// //                             onChange={handleChange}
// //                             maxLength={20}
// //                           />
// //                         </div>
// //                       </div>
// //                     </div>
// //                     <div className="event-buttons">
// //                       <button className="b1" onClick={(e) => handleRemove(e)}>
// //                         Remove
// //                       </button>
// //                       <button className="b2" type="submit">
// //                         Add
// //                       </button>
// //                     </div>
// //                   </form>
// //                 </div>
// //                 <div className="event-card">
// //                   <h2>Your Event 3 : </h2>
// //                   <form onSubmit={handleUpdate}>
// //                     <div className="row">
// //                       <div className="col-lg-5">
// //                         <div className="event-item">
// //                           <label htmlFor="">Add Date : </label>
// //                           <select
// //                           className="form-control"
// //                   name="juneEvent3Date"
// //                   id="juneEvent3Date"
// //                   value={formData.juneEvent3Date}
// //                   onChange={handleChange}
// //                 >
// //                   <option value="">Select Date</option>
// //                   {renderDateOptions(5)}
// //                 </select>
// //                         </div>
// //                       </div>
// //                       <div className="col-lg-5">
// //                         <div className="event-item">
// //                           <label htmlFor="">Select Color : </label>
// //                           <input
// //                             type="color"
// //                             name="juneEvent3Color"
// //                             id="juneEvent3Color"
// //                             value={formData.juneEvent3Color}
// //                             onChange={handleChange}
// //                           />
// //                         </div>
// //                       </div>
// //                       <div className="col-lg-12">
// //                         <div className="event-item">
// //                           <label htmlFor="">Caption : </label>
// //                           <input
// //                             type="text"
// //                             placeholder="Enter Caption (max 20 characters)"
// //                             name="juneEvent3Caption"
// //                             id="juneEvent3Caption"
// //                             value={formData.juneEvent3Caption}
// //                             onChange={handleChange}
// //                             maxLength={20}
// //                           />
// //                         </div>
// //                       </div>
// //                     </div>
// //                     <div className="event-buttons">
// //                       <button className="b1" onClick={(e) => handleRemove(e)}>
// //                         Remove
// //                       </button>
// //                       <button className="b2" type="submit">
// //                         Add
// //                       </button>
// //                     </div>
// //                   </form>
// //                 </div>
// //                 <div className="event-card">
// //                   <h2>Your Event 4 : </h2>
// //                   <form onSubmit={handleUpdate}>
// //                     <div className="row">
// //                       <div className="col-lg-5">
// //                         <div className="event-item">
// //                           <label htmlFor="">Add Date : </label>
// //                           <select
// //                           className="form-control"
// //                   name="juneEvent4Date"
// //                   id="juneEvent4Date"
// //                   value={formData.juneEvent4Date}
// //                   onChange={handleChange}
// //                 >
// //                   <option value="">Select Date</option>
// //                   {renderDateOptions(5)}
// //                 </select>
// //                         </div>
// //                       </div>
// //                       <div className="col-lg-5">
// //                         <div className="event-item">
// //                           <label htmlFor="">Select Color : </label>
// //                           <input
// //                             type="color"
// //                             name="juneEvent4Color"
// //                             id="juneEvent4Color"
// //                             value={formData.juneEvent4Color}
// //                             onChange={handleChange}
// //                           />
// //                         </div>
// //                       </div>
// //                       <div className="col-lg-12">
// //                         <div className="event-item">
// //                           <label htmlFor="">Caption : </label>
// //                           <input
// //                             type="text"
// //                             placeholder="Enter Caption (max 20 characters)"
// //                             name="juneEvent4Caption"
// //                             id="juneEvent4Caption"
// //                             value={formData.juneEvent4Caption}
// //                             onChange={handleChange}
// //                             maxLength={20}
// //                           />
// //                         </div>
// //                       </div>
// //                     </div>
// //                     <div className="event-buttons">
// //                       <button className="b1" onClick={(e) => handleRemove(e)}>
// //                         Remove
// //                       </button>
// //                       <button className="b2" type="submit">
// //                         Add
// //                       </button>
// //                     </div>
// //                   </form>
// //                 </div>
// //               </div>
// //               <div
// //                 id="july"
// //                 className={`events ${activeTab === "july" ? "active" : ""}`}
// //               >
// //                 <h4>July</h4>
// //                 <div className="event-card">
// //                   <h2>Your Event 1 : </h2>
// //                   <form onSubmit={handleUpdate}>
// //                     <div className="row">
// //                       <div className="col-lg-5">
// //                         <div className="event-item">
// //                           <label htmlFor="">Add Date : </label>
// //                           <select
// //                           className="form-control"
// //                   name="julyEvent1Date"
// //                   id="julyEvent1Date"
// //                   value={formData.julyEvent1Date}
// //                   onChange={handleChange}
// //                 >
// //                   <option value="">Select Date</option>
// //                   {renderDateOptions(6)}
// //                 </select>
// //                         </div>
// //                       </div>
// //                       <div className="col-lg-5">
// //                         <div className="event-item">
// //                           <label htmlFor="">Select Color : </label>
// //                           <input
// //                             type="color"
// //                             name="julyEvent1Color"
// //                             id="julyEvent1Color"
// //                             value={formData.julyEvent1Color}
// //                             onChange={handleChange}
// //                           />
// //                         </div>
// //                       </div>
// //                       <div className="col-lg-12">
// //                         <div className="event-item">
// //                           <label htmlFor="">Caption : </label>
// //                           <input
// //                             type="text"
// //                             placeholder="Enter Caption (max 20 characters)"
// //                             name="julyEvent1Caption"
// //                             id="julyEvent1Caption"
// //                             value={formData.julyEvent1Caption}
// //                             onChange={handleChange}
// //                             maxLength={20}
// //                           />
// //                         </div>
// //                       </div>
// //                     </div>
// //                     <div className="event-buttons">
// //                       <button className="b1" onClick={(e) => handleRemove(e)}>
// //                         Remove
// //                       </button>
// //                       <button className="b2" type="submit">
// //                         Add
// //                       </button>
// //                     </div>
// //                   </form>
// //                 </div>
// //                 <div className="event-card">
// //                   <h2>Your Event 2 : </h2>
// //                   <form onSubmit={handleUpdate}>
// //                     <div className="row">
// //                       <div className="col-lg-5">
// //                         <div className="event-item">
// //                           <label htmlFor="">Add Date : </label>
// //                           <select
// //                           className="form-control"
// //                   name="julyEvent2Date"
// //                   id="julyEvent2Date"
// //                   value={formData.julyEvent2Date}
// //                   onChange={handleChange}
// //                 >
// //                   <option value="">Select Date</option>
// //                   {renderDateOptions(6)}
// //                 </select>
// //                         </div>
// //                       </div>
// //                       <div className="col-lg-5">
// //                         <div className="event-item">
// //                           <label htmlFor="">Select Color : </label>
// //                           <input
// //                             type="color"
// //                             name="julyEvent2Color"
// //                             id="julyEvent2Color"
// //                             value={formData.julyEvent2Color}
// //                             onChange={handleChange}
// //                           />
// //                         </div>
// //                       </div>
// //                       <div className="col-lg-12">
// //                         <div className="event-item">
// //                           <label htmlFor="">Caption : </label>
// //                           <input
// //                             type="text"
// //                             placeholder="Enter Caption (max 20 characters)"
// //                             name="julyEvent2Caption"
// //                             id="julyEvent2Caption"
// //                             value={formData.julyEvent2Caption}
// //                             onChange={handleChange}
// //                             maxLength={20}
// //                           />
// //                         </div>
// //                       </div>
// //                     </div>
// //                     <div className="event-buttons">
// //                       <button className="b1" onClick={(e) => handleRemove(e)}>
// //                         Remove
// //                       </button>
// //                       <button className="b2" type="submit">
// //                         Add
// //                       </button>
// //                     </div>
// //                   </form>
// //                 </div>
// //                 <div className="event-card">
// //                   <h2>Your Event 3 : </h2>
// //                   <form onSubmit={handleUpdate}>
// //                     <div className="row">
// //                       <div className="col-lg-5">
// //                         <div className="event-item">
// //                           <label htmlFor="">Add Date : </label>
// //                           <select
// //                           className="form-control"
// //                   name="julyEvent3Date"
// //                   id="julyEvent3Date"
// //                   value={formData.julyEvent3Date}
// //                   onChange={handleChange}
// //                 >
// //                   <option value="">Select Date</option>
// //                   {renderDateOptions(6)}
// //                 </select>
// //                         </div>
// //                       </div>
// //                       <div className="col-lg-5">
// //                         <div className="event-item">
// //                           <label htmlFor="">Select Color : </label>
// //                           <input
// //                             type="color"
// //                             name="julyEvent3Color"
// //                             id="julyEvent3Color"
// //                             value={formData.julyEvent3Color}
// //                             onChange={handleChange}
// //                           />
// //                         </div>
// //                       </div>
// //                       <div className="col-lg-12">
// //                         <div className="event-item">
// //                           <label htmlFor="">Caption : </label>
// //                           <input
// //                             type="text"
// //                             placeholder="Enter Caption (max 20 characters)"
// //                             name="julyEvent3Caption"
// //                             id="julyEvent3Caption"
// //                             value={formData.julyEvent3Caption}
// //                             onChange={handleChange}
// //                             maxLength={20}
// //                           />
// //                         </div>
// //                       </div>
// //                     </div>
// //                     <div className="event-buttons">
// //                       <button className="b1" onClick={(e) => handleRemove(e)}>
// //                         Remove
// //                       </button>
// //                       <button className="b2" type="submit">
// //                         Add
// //                       </button>
// //                     </div>
// //                   </form>
// //                 </div>
// //                 <div className="event-card">
// //                   <h2>Your Event 4 : </h2>
// //                   <form onSubmit={handleUpdate}>
// //                     <div className="row">
// //                       <div className="col-lg-5">
// //                         <div className="event-item">
// //                           <label htmlFor="">Add Date : </label>
// //                           <select
// //                           className="form-control"
// //                   name="julyEvent4Date"
// //                   id="julyEvent4Date"
// //                   value={formData.julyEvent4Date}
// //                   onChange={handleChange}
// //                 >
// //                   <option value="">Select Date</option>
// //                   {renderDateOptions(6)}
// //                 </select>
// //                         </div>
// //                       </div>
// //                       <div className="col-lg-5">
// //                         <div className="event-item">
// //                           <label htmlFor="">Select Color : </label>
// //                           <input
// //                             type="color"
// //                             name="julyEvent4Color"
// //                             id="julyEvent4Color"
// //                             value={formData.julyEvent4Color}
// //                             onChange={handleChange}
// //                           />
// //                         </div>
// //                       </div>
// //                       <div className="col-lg-12">
// //                         <div className="event-item">
// //                           <label htmlFor="">Caption : </label>
// //                           <input
// //                             type="text"
// //                             placeholder="Enter Caption (max 20 characters)"
// //                             name="julyEvent4Caption"
// //                             id="julyEvent4Caption"
// //                             value={formData.julyEvent4Caption}
// //                             onChange={handleChange}
// //                             maxLength={20}
// //                           />
// //                         </div>
// //                       </div>
// //                     </div>
// //                     <div className="event-buttons">
// //                       <button className="b1" onClick={(e) => handleRemove(e)}>
// //                         Remove
// //                       </button>
// //                       <button className="b2" type="submit">
// //                         Add
// //                       </button>
// //                     </div>
// //                   </form>
// //                 </div>
// //               </div>
// //               <div
// //                 id="aug"
// //                 className={`events ${activeTab === "aug" ? "active" : ""}`}
// //               >
// //                 <h4>August</h4>
// //                 <div className="event-card">
// //                   <h2>Your Event 1 : </h2>
// //                   <form onSubmit={handleUpdate}>
// //                     <div className="row">
// //                       <div className="col-lg-5">
// //                         <div className="event-item">
// //                           <label htmlFor="">Add Date : </label>
// //                           <select
// //                           className="form-control"
// //                   name="augustEvent1Date"
// //                   id="augustEvent1Date"
// //                   value={formData.augustEvent1Date}
// //                   onChange={handleChange}
// //                 >
// //                   <option value="">Select Date</option>
// //                   {renderDateOptions(7)}
// //                 </select>
// //                         </div>
// //                       </div>
// //                       <div className="col-lg-5">
// //                         <div className="event-item">
// //                           <label htmlFor="">Select Color : </label>
// //                           <input
// //                             type="color"
// //                             name="augustEvent1Color"
// //                             id="augustEvent1Color"
// //                             value={formData.augustEvent1Color}
// //                             onChange={handleChange}
// //                           />
// //                         </div>
// //                       </div>
// //                       <div className="col-lg-12">
// //                         <div className="event-item">
// //                           <label htmlFor="">Caption : </label>
// //                           <input
// //                             type="text"
// //                             placeholder="Enter Caption (max 20 characters)"
// //                             name="augustEvent1Caption"
// //                             id="augustEvent1Caption"
// //                             value={formData.augustEvent1Caption}
// //                             onChange={handleChange}
// //                             maxLength={20}
// //                           />
// //                         </div>
// //                       </div>
// //                     </div>
// //                     <div className="event-buttons">
// //                       <button className="b1" onClick={(e) => handleRemove(e)}>
// //                         Remove
// //                       </button>
// //                       <button className="b2" type="submit">
// //                         Add
// //                       </button>
// //                     </div>
// //                   </form>
// //                 </div>
// //                 <div className="event-card">
// //                   <h2>Your Event 2 : </h2>
// //                   <form onSubmit={handleUpdate}>
// //                     <div className="row">
// //                       <div className="col-lg-5">
// //                         <div className="event-item">
// //                           <label htmlFor="">Add Date : </label>
// //                           <select
// //                           className="form-control"
// //                   name="augustEvent2Date"
// //                   id="augustEvent2Date"
// //                   value={formData.augustEvent2Date}
// //                   onChange={handleChange}
// //                 >
// //                   <option value="">Select Date</option>
// //                   {renderDateOptions(7)}
// //                 </select>
// //                         </div>
// //                       </div>
// //                       <div className="col-lg-5">
// //                         <div className="event-item">
// //                           <label htmlFor="">Select Color : </label>
// //                           <input
// //                             type="color"
// //                             name="augustEvent2Color"
// //                             id="augustEvent2Color"
// //                             value={formData.augustEvent2Color}
// //                             onChange={handleChange}
// //                           />
// //                         </div>
// //                       </div>
// //                       <div className="col-lg-12">
// //                         <div className="event-item">
// //                           <label htmlFor="">Caption : </label>
// //                           <input
// //                             type="text"
// //                             placeholder="Enter Caption (max 20 characters)"
// //                             name="augustEvent2Caption"
// //                             id="augustEvent2Caption"
// //                             value={formData.augustEvent2Caption}
// //                             onChange={handleChange}
// //                             maxLength={20}
// //                           />
// //                         </div>
// //                       </div>
// //                     </div>
// //                     <div className="event-buttons">
// //                       <button className="b1" onClick={(e) => handleRemove(e)}>
// //                         Remove
// //                       </button>
// //                       <button className="b2" type="submit">
// //                         Add
// //                       </button>
// //                     </div>
// //                   </form>
// //                 </div>
// //                 <div className="event-card">
// //                   <h2>Your Event 3 : </h2>
// //                   <form onSubmit={handleUpdate}>
// //                     <div className="row">
// //                       <div className="col-lg-5">
// //                         <div className="event-item">
// //                           <label htmlFor="">Add Date : </label>
// //                           <select
// //                           className="form-control"
// //                   name="augustEvent3Date"
// //                   id="augustEvent3Date"
// //                   value={formData.augustEvent3Date}
// //                   onChange={handleChange}
// //                 >
// //                   <option value="">Select Date</option>
// //                   {renderDateOptions(7)}
// //                 </select>
// //                         </div>
// //                       </div>
// //                       <div className="col-lg-5">
// //                         <div className="event-item">
// //                           <label htmlFor="">Select Color : </label>
// //                           <input
// //                             type="color"
// //                             name="augustEvent3Color"
// //                             id="augustEvent3Color"
// //                             value={formData.augustEvent3Color}
// //                             onChange={handleChange}
// //                           />
// //                         </div>
// //                       </div>
// //                       <div className="col-lg-12">
// //                         <div className="event-item">
// //                           <label htmlFor="">Caption : </label>
// //                           <input
// //                             type="text"
// //                             placeholder="Enter Caption (max 20 characters)"
// //                             name="augustEvent3Caption"
// //                             id="augustEvent3Caption"
// //                             value={formData.augustEvent3Caption}
// //                             onChange={handleChange}
// //                             maxLength={20}
// //                           />
// //                         </div>
// //                       </div>
// //                     </div>
// //                     <div className="event-buttons">
// //                       <button className="b1" onClick={(e) => handleRemove(e)}>
// //                         Remove
// //                       </button>
// //                       <button className="b2" type="submit">
// //                         Add
// //                       </button>
// //                     </div>
// //                   </form>
// //                 </div>
// //                 <div className="event-card">
// //                   <h2>Your Event 4 : </h2>
// //                   <form onSubmit={handleUpdate}>
// //                     <div className="row">
// //                       <div className="col-lg-5">
// //                         <div className="event-item">
// //                           <label htmlFor="">Add Date : </label>
// //                           <select
// //                           className="form-control"
// //                   name="augustEvent4Date"
// //                   id="augustEvent4Date"
// //                   value={formData.augustEvent4Date}
// //                   onChange={handleChange}
// //                 >
// //                   <option value="">Select Date</option>
// //                   {renderDateOptions(7)}
// //                 </select>
// //                         </div>
// //                       </div>
// //                       <div className="col-lg-5">
// //                         <div className="event-item">
// //                           <label htmlFor="">Select Color : </label>
// //                           <input
// //                             type="color"
// //                             name="augustEvent4Color"
// //                             id="augustEvent4Color"
// //                             value={formData.augustEvent4Color}
// //                             onChange={handleChange}
// //                           />
// //                         </div>
// //                       </div>
// //                       <div className="col-lg-12">
// //                         <div className="event-item">
// //                           <label htmlFor="">Caption : </label>
// //                           <input
// //                             type="text"
// //                             placeholder="Enter Caption (max 20 characters)"
// //                             name="augustEvent4Caption"
// //                             id="augustEvent4Caption"
// //                             value={formData.augustEvent4Caption}
// //                             onChange={handleChange}
// //                             maxLength={20}
// //                           />
// //                         </div>
// //                       </div>
// //                     </div>
// //                     <div className="event-buttons">
// //                       <button className="b1" onClick={(e) => handleRemove(e)}>
// //                         Remove
// //                       </button>
// //                       <button className="b2" type="submit">
// //                         Add
// //                       </button>
// //                     </div>
// //                   </form>
// //                 </div>
// //               </div>
// //               <div
// //                 id="sept"
// //                 className={`events ${activeTab === "sept" ? "active" : ""}`}
// //               >
// //                 <h4>September</h4>
// //                 <div className="event-card">
// //                   <h2>Your Event 1 : </h2>
// //                   <form onSubmit={handleUpdate}>
// //                     <div className="row">
// //                       <div className="col-lg-5">
// //                         <div className="event-item">
// //                           <label htmlFor="">Add Date : </label>
// //                           <select
// //                           className="form-control"
// //                   name="septemberEvent1Date"
// //                   id="septemberEvent1Date"
// //                   value={formData.septemberEvent1Date}
// //                   onChange={handleChange}
// //                 >
// //                   <option value="">Select Date</option>
// //                   {renderDateOptions(8)}
// //                 </select>
// //                         </div>
// //                       </div>
// //                       <div className="col-lg-5">
// //                         <div className="event-item">
// //                           <label htmlFor="">Select Color : </label>
// //                           <input
// //                             type="color"
// //                             name="septemberEvent1Color"
// //                             id="septemberEvent1Color"
// //                             value={formData.septemberEvent1Color}
// //                             onChange={handleChange}
// //                           />
// //                         </div>
// //                       </div>
// //                       <div className="col-lg-12">
// //                         <div className="event-item">
// //                           <label htmlFor="">Caption : </label>
// //                           <input
// //                             type="text"
// //                             placeholder="Enter Caption (max 20 characters)"
// //                             name="septemberEvent1Caption"
// //                             id="septemberEvent1Caption"
// //                             value={formData.septemberEvent1Caption}
// //                             onChange={handleChange}
// //                             maxLength={20}
// //                           />
// //                         </div>
// //                       </div>
// //                     </div>
// //                     <div className="event-buttons">
// //                       <button className="b1" onClick={(e) => handleRemove(e)}>
// //                         Remove
// //                       </button>
// //                       <button className="b2" type="submit">
// //                         Add
// //                       </button>
// //                     </div>
// //                   </form>
// //                 </div>
// //                 <div className="event-card">
// //                   <h2>Your Event 2 : </h2>
// //                   <form onSubmit={handleUpdate}>
// //                     <div className="row">
// //                       <div className="col-lg-5">
// //                         <div className="event-item">
// //                           <label htmlFor="">Add Date : </label>
// //                           <select
// //                           className="form-control"
// //                   name="septemberEvent2Date"
// //                   id="septemberEvent2Date"
// //                   value={formData.septemberEvent2Date}
// //                   onChange={handleChange}
// //                 >
// //                   <option value="">Select Date</option>
// //                   {renderDateOptions(8)}
// //                 </select>
// //                         </div>
// //                       </div>
// //                       <div className="col-lg-5">
// //                         <div className="event-item">
// //                           <label htmlFor="">Select Color : </label>
// //                           <input
// //                             type="color"
// //                             name="septemberEvent2Color"
// //                             id="septemberEvent2Color"
// //                             value={formData.septemberEvent2Color}
// //                             onChange={handleChange}
// //                           />
// //                         </div>
// //                       </div>
// //                       <div className="col-lg-12">
// //                         <div className="event-item">
// //                           <label htmlFor="">Caption : </label>
// //                           <input
// //                             type="text"
// //                             placeholder="Enter Caption (max 20 characters)"
// //                             name="septemberEvent2Caption"
// //                             id="septemberEvent2Caption"
// //                             value={formData.septemberEvent2Caption}
// //                             onChange={handleChange}
// //                             maxLength={20}
// //                           />
// //                         </div>
// //                       </div>
// //                     </div>
// //                     <div className="event-buttons">
// //                       <button className="b1" onClick={(e) => handleRemove(e)}>
// //                         Remove
// //                       </button>
// //                       <button className="b2" type="submit">
// //                         Add
// //                       </button>
// //                     </div>
// //                   </form>
// //                 </div>
// //                 <div className="event-card">
// //                   <h2>Your Event 3 : </h2>
// //                   <form onSubmit={handleUpdate}>
// //                     <div className="row">
// //                       <div className="col-lg-5">
// //                         <div className="event-item">
// //                           <label htmlFor="">Add Date : </label>
// //                           <select
// //                           className="form-control"
// //                   name="septemberEvent3Date"
// //                   id="septemberEvent3Date"
// //                   value={formData.septemberEvent3Date}
// //                   onChange={handleChange}
// //                 >
// //                   <option value="">Select Date</option>
// //                   {renderDateOptions(8)}
// //                 </select>
// //                         </div>
// //                       </div>
// //                       <div className="col-lg-5">
// //                         <div className="event-item">
// //                           <label htmlFor="">Select Color : </label>
// //                           <input
// //                             type="color"
// //                             name="septemberEvent3Color"
// //                             id="septemberEvent3Color"
// //                             value={formData.septemberEvent3Color}
// //                             onChange={handleChange}
// //                           />
// //                         </div>
// //                       </div>
// //                       <div className="col-lg-12">
// //                         <div className="event-item">
// //                           <label htmlFor="">Caption : </label>
// //                           <input
// //                             type="text"
// //                             placeholder="Enter Caption (max 20 characters)"
// //                             name="septemberEvent3Caption"
// //                             id="septemberEvent3Caption"
// //                             value={formData.septemberEvent3Caption}
// //                             onChange={handleChange}
// //                             maxLength={20}
// //                           />
// //                         </div>
// //                       </div>
// //                     </div>
// //                     <div className="event-buttons">
// //                       <button className="b1" onClick={(e) => handleRemove(e)}>
// //                         Remove
// //                       </button>
// //                       <button className="b2" type="submit">
// //                         Add
// //                       </button>
// //                     </div>
// //                   </form>
// //                 </div>
// //                 <div className="event-card">
// //                   <h2>Your Event 4 : </h2>
// //                   <form onSubmit={handleUpdate}>
// //                     <div className="row">
// //                       <div className="col-lg-5">
// //                         <div className="event-item">
// //                           <label htmlFor="">Add Date : </label>
// //                           <select
// //                           className="form-control"
// //                   name="septemberEvent4Date"
// //                   id="septemberEvent4Date"
// //                   value={formData.septemberEvent4Date}
// //                   onChange={handleChange}
// //                 >
// //                   <option value="">Select Date</option>
// //                   {renderDateOptions(8)}
// //                 </select>
// //                         </div>
// //                       </div>
// //                       <div className="col-lg-5">
// //                         <div className="event-item">
// //                           <label htmlFor="">Select Color : </label>
// //                           <input
// //                             type="color"
// //                             name="septemberEvent4Color"
// //                             id="septemberEvent4Color"
// //                             value={formData.septemberEvent4Color}
// //                             onChange={handleChange}
// //                           />
// //                         </div>
// //                       </div>
// //                       <div className="col-lg-12">
// //                         <div className="event-item">
// //                           <label htmlFor="">Caption : </label>
// //                           <input
// //                             type="text"
// //                             placeholder="Enter Caption (max 20 characters)"
// //                             name="septemberEvent4Caption"
// //                             id="septemberEvent4Caption"
// //                             value={formData.septemberEvent4Caption}
// //                             onChange={handleChange}
// //                             maxLength={20}
// //                           />
// //                         </div>
// //                       </div>
// //                     </div>
// //                     <div className="event-buttons">
// //                       <button className="b1" onClick={(e) => handleRemove(e)}>
// //                         Remove
// //                       </button>
// //                       <button className="b2" type="submit">
// //                         Add
// //                       </button>
// //                     </div>
// //                   </form>
// //                 </div>
// //               </div>
// //               <div
// //                 id="oct"
// //                 className={`events ${activeTab === "oct" ? "active" : ""}`}
// //               >
// //                 <h4>October</h4>
// //                 <div className="event-card">
// //                   <h2>Your Event 1 : </h2>
// //                   <form onSubmit={handleUpdate}>
// //                     <div className="row">
// //                       <div className="col-lg-5">
// //                         <div className="event-item">
// //                           <label htmlFor="">Add Date : </label>
// //                           <select
// //                           className="form-control"
// //                   name="octoberEvent1Date"
// //                   id="octoberEvent1Date"
// //                   value={formData.octoberEvent1Date}
// //                   onChange={handleChange}
// //                 >
// //                   <option value="">Select Date</option>
// //                   {renderDateOptions(9)}
// //                 </select>
// //                         </div>
// //                       </div>
// //                       <div className="col-lg-5">
// //                         <div className="event-item">
// //                           <label htmlFor="">Select Color : </label>
// //                           <input
// //                             type="color"
// //                             name="octoberEvent1Color"
// //                             id="octoberEvent1Color"
// //                             value={formData.octoberEvent1Color}
// //                             onChange={handleChange}
// //                           />
// //                         </div>
// //                       </div>
// //                       <div className="col-lg-12">
// //                         <div className="event-item">
// //                           <label htmlFor="">Caption : </label>
// //                           <input
// //                             type="text"
// //                             placeholder="Enter Caption (max 20 characters)"
// //                             name="octoberEvent1Caption"
// //                             id="octoberEvent1Caption"
// //                             value={formData.octoberEvent1Caption}
// //                             onChange={handleChange}
// //                             maxLength={20}
// //                           />
// //                         </div>
// //                       </div>
// //                     </div>
// //                     <div className="event-buttons">
// //                       <button className="b1" onClick={(e) => handleRemove(e)}>
// //                         Remove
// //                       </button>
// //                       <button className="b2" type="submit">
// //                         Add
// //                       </button>
// //                     </div>
// //                   </form>
// //                 </div>
// //                 <div className="event-card">
// //                   <h2>Your Event 2 : </h2>
// //                   <form onSubmit={handleUpdate}>
// //                     <div className="row">
// //                       <div className="col-lg-5">
// //                         <div className="event-item">
// //                           <label htmlFor="">Add Date : </label>
// //                           <select
// //                           className="form-control"
// //                   name="octoberEvent2Date"
// //                   id="octoberEvent2Date"
// //                   value={formData.octoberEvent2Date}
// //                   onChange={handleChange}
// //                 >
// //                   <option value="">Select Date</option>
// //                   {renderDateOptions(9)}
// //                 </select>
// //                         </div>
// //                       </div>
// //                       <div className="col-lg-5">
// //                         <div className="event-item">
// //                           <label htmlFor="">Select Color : </label>
// //                           <input
// //                             type="color"
// //                             name="octoberEvent2Color"
// //                             id="octoberEvent2Color"
// //                             value={formData.octoberEvent2Color}
// //                             onChange={handleChange}
// //                           />
// //                         </div>
// //                       </div>
// //                       <div className="col-lg-12">
// //                         <div className="event-item">
// //                           <label htmlFor="">Caption : </label>
// //                           <input
// //                             type="text"
// //                             placeholder="Enter Caption (max 20 characters)"
// //                             name="octoberEvent2Caption"
// //                             id="octoberEvent2Caption"
// //                             value={formData.octoberEvent2Caption}
// //                             onChange={handleChange}
// //                             maxLength={20}
// //                           />
// //                         </div>
// //                       </div>
// //                     </div>
// //                     <div className="event-buttons">
// //                       <button className="b1" onClick={(e) => handleRemove(e)}>
// //                         Remove
// //                       </button>
// //                       <button className="b2" type="submit">
// //                         Add
// //                       </button>
// //                     </div>
// //                   </form>
// //                 </div>
// //                 <div className="event-card">
// //                   <h2>Your Event 3 : </h2>
// //                   <form onSubmit={handleUpdate}>
// //                     <div className="row">
// //                       <div className="col-lg-5">
// //                         <div className="event-item">
// //                           <label htmlFor="">Add Date : </label>
// //                           <select
// //                           className="form-control"
// //                   name="octoberEvent3Date"
// //                   id="octoberEvent3Date"
// //                   value={formData.octoberEvent3Date}
// //                   onChange={handleChange}
// //                 >
// //                   <option value="">Select Date</option>
// //                   {renderDateOptions(9)}
// //                 </select>
// //                         </div>
// //                       </div>
// //                       <div className="col-lg-5">
// //                         <div className="event-item">
// //                           <label htmlFor="">Select Color : </label>
// //                           <input
// //                             type="color"
// //                             name="octoberEvent3Color"
// //                             id="octoberEvent3Color"
// //                             value={formData.octoberEvent3Color}
// //                             onChange={handleChange}
// //                           />
// //                         </div>
// //                       </div>
// //                       <div className="col-lg-12">
// //                         <div className="event-item">
// //                           <label htmlFor="">Caption : </label>
// //                           <input
// //                             type="text"
// //                             placeholder="Enter Caption (max 20 characters)"
// //                             name="octoberEvent3Caption"
// //                             id="octoberEvent3Caption"
// //                             value={formData.octoberEvent3Caption}
// //                             onChange={handleChange}
// //                             maxLength={20}
// //                           />
// //                         </div>
// //                       </div>
// //                     </div>
// //                     <div className="event-buttons">
// //                       <button className="b1" onClick={(e) => handleRemove(e)}>
// //                         Remove
// //                       </button>
// //                       <button className="b2" type="submit">
// //                         Add
// //                       </button>
// //                     </div>
// //                   </form>
// //                 </div>
// //                 <div className="event-card">
// //                   <h2>Your Event 4 : </h2>
// //                   <form onSubmit={handleUpdate}>
// //                     <div className="row">
// //                       <div className="col-lg-5">
// //                         <div className="event-item">
// //                           <label htmlFor="">Add Date : </label>
// //                           <select
// //                           className="form-control"
// //                   name="octoberEvent4Date"
// //                   id="octoberEvent4Date"
// //                   value={formData.octoberEvent4Date}
// //                   onChange={handleChange}
// //                 >
// //                   <option value="">Select Date</option>
// //                   {renderDateOptions(9)}
// //                 </select>
// //                         </div>
// //                       </div>
// //                       <div className="col-lg-5">
// //                         <div className="event-item">
// //                           <label htmlFor="">Select Color : </label>
// //                           <input
// //                             type="color"
// //                             name="octoberEvent4Color"
// //                             id="octoberEvent4Color"
// //                             value={formData.octoberEvent4Color}
// //                             onChange={handleChange}
// //                           />
// //                         </div>
// //                       </div>
// //                       <div className="col-lg-12">
// //                         <div className="event-item">
// //                           <label htmlFor="">Caption : </label>
// //                           <input
// //                             type="text"
// //                             placeholder="Enter Caption (max 20 characters)"
// //                             name="octoberEvent4Caption"
// //                             id="octoberEvent4Caption"
// //                             value={formData.octoberEvent4Caption}
// //                             onChange={handleChange}
// //                             maxLength={20}
// //                           />
// //                         </div>
// //                       </div>
// //                     </div>
// //                     <div className="event-buttons">
// //                       <button className="b1" onClick={(e) => handleRemove(e)}>
// //                         Remove
// //                       </button>
// //                       <button className="b2" type="submit">
// //                         Add
// //                       </button>
// //                     </div>
// //                   </form>
// //                 </div>
// //               </div>
// //               <div
// //                 id="nov"
// //                 className={`events ${activeTab === "nov" ? "active" : ""}`}
// //               >
// //                 <h4>November</h4>
// //                 <div className="event-card">
// //                   <h2>Your Event 1 : </h2>
// //                   <form onSubmit={handleUpdate}>
// //                     <div className="row">
// //                       <div className="col-lg-5">
// //                         <div className="event-item">
// //                           <label htmlFor="">Add Date : </label>
// //                           <select
// //                           className="form-control"
// //                   name="novemberEvent1Date"
// //                   id="novemberEvent1Date"
// //                   value={formData.novemberEvent1Date}
// //                   onChange={handleChange}
// //                 >
// //                   <option value="">Select Date</option>
// //                   {renderDateOptions(10)}
// //                 </select>
// //                         </div>
// //                       </div>
// //                       <div className="col-lg-5">
// //                         <div className="event-item">
// //                           <label htmlFor="">Select Color : </label>
// //                           <input
// //                             type="color"
// //                             name="novemberEvent1Color"
// //                             id="novemberEvent1Color"
// //                             value={formData.novemberEvent1Color}
// //                             onChange={handleChange}
// //                           />
// //                         </div>
// //                       </div>
// //                       <div className="col-lg-12">
// //                         <div className="event-item">
// //                           <label htmlFor="">Caption : </label>
// //                           <input
// //                             type="text"
// //                             placeholder="Enter Caption (max 20 characters)"
// //                             name="novemberEvent1Caption"
// //                             id="novemberEvent1Caption"
// //                             value={formData.novemberEvent1Caption}
// //                             onChange={handleChange}
// //                             maxLength={20}
// //                           />
// //                         </div>
// //                       </div>
// //                     </div>
// //                     <div className="event-buttons">
// //                       <button className="b1" onClick={(e) => handleRemove(e)}>
// //                         Remove
// //                       </button>
// //                       <button className="b2" type="submit">
// //                         Add
// //                       </button>
// //                     </div>
// //                   </form>
// //                 </div>
// //                 <div className="event-card">
// //                   <h2>Your Event 2 : </h2>
// //                   <form onSubmit={handleUpdate}>
// //                     <div className="row">
// //                       <div className="col-lg-5">
// //                         <div className="event-item">
// //                           <label htmlFor="">Add Date : </label>
// //                           <select
// //                           className="form-control"
// //                   name="novemberEvent2Date"
// //                   id="novemberEvent2Date"
// //                   value={formData.novemberEvent2Date}
// //                   onChange={handleChange}
// //                 >
// //                   <option value="">Select Date</option>
// //                   {renderDateOptions(10)}
// //                 </select>
// //                         </div>
// //                       </div>
// //                       <div className="col-lg-5">
// //                         <div className="event-item">
// //                           <label htmlFor="">Select Color : </label>
// //                           <input
// //                             type="color"
// //                             name="novemberEvent2Color"
// //                             id="novemberEvent2Color"
// //                             value={formData.novemberEvent2Color}
// //                             onChange={handleChange}
// //                           />
// //                         </div>
// //                       </div>
// //                       <div className="col-lg-12">
// //                         <div className="event-item">
// //                           <label htmlFor="">Caption : </label>
// //                           <input
// //                             type="text"
// //                             placeholder="Enter Caption (max 20 characters)"
// //                             name="novemberEvent2Caption"
// //                             id="novemberEvent2Caption"
// //                             value={formData.novemberEvent2Caption}
// //                             onChange={handleChange}
// //                             maxLength={20}
// //                           />
// //                         </div>
// //                       </div>
// //                     </div>
// //                     <div className="event-buttons">
// //                       <button className="b1" onClick={(e) => handleRemove(e)}>
// //                         Remove
// //                       </button>
// //                       <button className="b2" type="submit">
// //                         Add
// //                       </button>
// //                     </div>
// //                   </form>
// //                 </div>
// //                 <div className="event-card">
// //                   <h2>Your Event 3 : </h2>
// //                   <form onSubmit={handleUpdate}>
// //                     <div className="row">
// //                       <div className="col-lg-5">
// //                         <div className="event-item">
// //                           <label htmlFor="">Add Date : </label>
// //                           <select
// //                           className="form-control"
// //                   name="novemberEvent3Date"
// //                   id="novemberEvent3Date"
// //                   value={formData.novemberEvent3Date}
// //                   onChange={handleChange}
// //                 >
// //                   <option value="">Select Date</option>
// //                   {renderDateOptions(10)}
// //                 </select>
// //                         </div>
// //                       </div>
// //                       <div className="col-lg-5">
// //                         <div className="event-item">
// //                           <label htmlFor="">Select Color : </label>
// //                           <input
// //                             type="color"
// //                             name="novemberEvent3Color"
// //                             id="novemberEvent3Color"
// //                             value={formData.novemberEvent3Color}
// //                             onChange={handleChange}
// //                           />
// //                         </div>
// //                       </div>
// //                       <div className="col-lg-12">
// //                         <div className="event-item">
// //                           <label htmlFor="">Caption : </label>
// //                           <input
// //                             type="text"
// //                             placeholder="Enter Caption (max 20 characters)"
// //                             name="novemberEvent3Caption"
// //                             id="novemberEvent3Caption"
// //                             value={formData.novemberEvent3Caption}
// //                             onChange={handleChange}
// //                             maxLength={20}
// //                           />
// //                         </div>
// //                       </div>
// //                     </div>
// //                     <div className="event-buttons">
// //                       <button className="b1" onClick={(e) => handleRemove(e)}>
// //                         Remove
// //                       </button>
// //                       <button className="b2" type="submit">
// //                         Add
// //                       </button>
// //                     </div>
// //                   </form>
// //                 </div>
// //                 <div className="event-card">
// //                   <h2>Your Event 4 : </h2>
// //                   <form onSubmit={handleUpdate}>
// //                     <div className="row">
// //                       <div className="col-lg-5">
// //                         <div className="event-item">
// //                           <label htmlFor="">Add Date : </label>
// //                           <select
// //                           className="form-control"
// //                   name="novemberEvent4Date"
// //                   id="novemberEvent4Date"
// //                   value={formData.novemberEvent4Date}
// //                   onChange={handleChange}
// //                 >
// //                   <option value="">Select Date</option>
// //                   {renderDateOptions(10)}
// //                 </select>
// //                         </div>
// //                       </div>
// //                       <div className="col-lg-5">
// //                         <div className="event-item">
// //                           <label htmlFor="">Select Color : </label>
// //                           <input
// //                             type="color"
// //                             name="novemberEvent4Color"
// //                             id="novemberEvent4Color"
// //                             value={formData.novemberEvent4Color}
// //                             onChange={handleChange}
// //                           />
// //                         </div>
// //                       </div>
// //                       <div className="col-lg-12">
// //                         <div className="event-item">
// //                           <label htmlFor="">Caption : </label>
// //                           <input
// //                             type="text"
// //                             placeholder="Enter Caption (max 20 characters)"
// //                             name="novemberEvent4Caption"
// //                             id="novemberEvent4Caption"
// //                             value={formData.novemberEvent4Caption}
// //                             onChange={handleChange}
// //                             maxLength={20}
// //                           />
// //                         </div>
// //                       </div>
// //                     </div>
// //                     <div className="event-buttons">
// //                       <button className="b1" onClick={(e) => handleRemove(e)}>
// //                         Remove
// //                       </button>
// //                       <button className="b2" type="submit">
// //                         Add
// //                       </button>
// //                     </div>
// //                   </form>
// //                 </div>
// //               </div>
// //               <div
// //                 id="dec"
// //                 className={`events ${activeTab === "dec" ? "active" : ""}`}
// //               >
// //                 <h4>December</h4>
// //                 <div className="event-card">
// //                   <h2>Your Event 1 : </h2>
// //                   <form onSubmit={handleUpdate}>
// //                     <div className="row">
// //                       <div className="col-lg-5">
// //                         <div className="event-item">
// //                           <label htmlFor="">Add Date : </label>
// //                           <select
// //                           className="form-control"
// //                   name="decemberEvent1Date"
// //                   id="decemberEvent1Date"
// //                   value={formData.decemberEvent1Date}
// //                   onChange={handleChange}
// //                 >
// //                   <option value="">Select Date</option>
// //                   {renderDateOptions(11)}
// //                 </select>
// //                         </div>
// //                       </div>
// //                       <div className="col-lg-5">
// //                         <div className="event-item">
// //                           <label htmlFor="">Select Color : </label>
// //                           <input
// //                             type="color"
// //                             name="decemberEvent1Color"
// //                             id="decemberEvent1Color"
// //                             value={formData.decemberEvent1Color}
// //                             onChange={handleChange}
// //                           />
// //                         </div>
// //                       </div>
// //                       <div className="col-lg-12">
// //                         <div className="event-item">
// //                           <label htmlFor="">Caption : </label>
// //                           <input
// //                             type="text"
// //                             placeholder="Enter Caption (max 20 characters)"
// //                             name="decemberEvent1Caption"
// //                             id="decemberEvent1Caption"
// //                             value={formData.decemberEvent1Caption}
// //                             onChange={handleChange}
// //                             maxLength={20}
// //                           />
// //                         </div>
// //                       </div>
// //                     </div>
// //                     <div className="event-buttons">
// //                       <button className="b1" onClick={(e) => handleRemove(e)}>
// //                         Remove
// //                       </button>
// //                       <button className="b2" type="submit">
// //                         Add
// //                       </button>
// //                     </div>
// //                   </form>
// //                 </div>
// //                 <div className="event-card">
// //                   <h2>Your Event 2 : </h2>
// //                   <form onSubmit={handleUpdate}>
// //                     <div className="row">
// //                       <div className="col-lg-5">
// //                         <div className="event-item">
// //                           <label htmlFor="">Add Date : </label>
// //                           <select
// //                           className="form-control"
// //                   name="decemberEvent2Date"
// //                   id="decemberEvent2Date"
// //                   value={formData.decemberEvent2Date}
// //                   onChange={handleChange}
// //                 >
// //                   <option value="">Select Date</option>
// //                   {renderDateOptions(11)}
// //                 </select>
// //                         </div>
// //                       </div>
// //                       <div className="col-lg-5">
// //                         <div className="event-item">
// //                           <label htmlFor="">Select Color : </label>
// //                           <input
// //                             type="color"
// //                             name="decemberEvent2Color"
// //                             id="decemberEvent2Color"
// //                             value={formData.decemberEvent2Color}
// //                             onChange={handleChange}
// //                           />
// //                         </div>
// //                       </div>
// //                       <div className="col-lg-12">
// //                         <div className="event-item">
// //                           <label htmlFor="">Caption : </label>
// //                           <input
// //                             type="text"
// //                             placeholder="Enter Caption (max 20 characters)"
// //                             name="decemberEvent2Caption"
// //                             id="decemberEvent2Caption"
// //                             value={formData.decemberEvent2Caption}
// //                             onChange={handleChange}
// //                             maxLength={20}
// //                           />
// //                         </div>
// //                       </div>
// //                     </div>
// //                     <div className="event-buttons">
// //                       <button className="b1" onClick={(e) => handleRemove(e)}>
// //                         Remove
// //                       </button>
// //                       <button className="b2" type="submit">
// //                         Add
// //                       </button>
// //                     </div>
// //                   </form>
// //                 </div>
// //                 <div className="event-card">
// //                   <h2>Your Event 3 : </h2>
// //                   <form onSubmit={handleUpdate}>
// //                     <div className="row">
// //                       <div className="col-lg-5">
// //                         <div className="event-item">
// //                           <label htmlFor="">Add Date : </label>
// //                           <select
// //                           className="form-control"
// //                   name="decemberEvent3Date"
// //                   id="decemberEvent3Date"
// //                   value={formData.decemberEvent3Date}
// //                   onChange={handleChange}
// //                 >
// //                   <option value="">Select Date</option>
// //                   {renderDateOptions(11)}
// //                 </select>
// //                         </div>
// //                       </div>
// //                       <div className="col-lg-5">
// //                         <div className="event-item">
// //                           <label htmlFor="">Select Color : </label>
// //                           <input
// //                             type="color"
// //                             name="decemberEvent3Color"
// //                             id="decemberEvent3Color"
// //                             value={formData.decemberEvent3Color}
// //                             onChange={handleChange}
// //                           />
// //                         </div>
// //                       </div>
// //                       <div className="col-lg-12">
// //                         <div className="event-item">
// //                           <label htmlFor="">Caption : </label>
// //                           <input
// //                             type="text"
// //                             placeholder="Enter Caption (max 20 characters)"
// //                             name="decemberEvent3Caption"
// //                             id="decemberEvent3Caption"
// //                             value={formData.decemberEvent3Caption}
// //                             onChange={handleChange}
// //                             maxLength={20}
// //                           />
// //                         </div>
// //                       </div>
// //                     </div>
// //                     <div className="event-buttons">
// //                       <button className="b1" onClick={(e) => handleRemove(e)}>
// //                         Remove
// //                       </button>
// //                       <button className="b2" type="submit">
// //                         Add
// //                       </button>
// //                     </div>
// //                   </form>
// //                 </div>
// //                 <div className="event-card">
// //                   <h2>Your Event 4 : </h2>
// //                   <form onSubmit={handleUpdate}>
// //                     <div className="row">
// //                       <div className="col-lg-5">
// //                         <div className="event-item">
// //                           <label htmlFor="">Add Date : </label>
// //                           <select
// //                           className="form-control"
// //                   name="decemberEvent4Date"
// //                   id="decemberEvent4Date"
// //                   value={formData.decemberEvent4Date}
// //                   onChange={handleChange}
// //                 >
// //                   <option value="">Select Date</option>
// //                   {renderDateOptions(11)}
// //                 </select>
// //                         </div>
// //                       </div>
// //                       <div className="col-lg-5">
// //                         <div className="event-item">
// //                           <label htmlFor="">Select Color : </label>
// //                           <input
// //                             type="color"
// //                             name="decemberEvent4Color"
// //                             id="decemberEvent4Color"
// //                             value={formData.decemberEvent4Color}
// //                             onChange={handleChange}
// //                           />
// //                         </div>
// //                       </div>
// //                       <div className="col-lg-12">
// //                         <div className="event-item">
// //                           <label htmlFor="">Caption : </label>
// //                           <input
// //                             type="text"
// //                             placeholder="Enter Caption (max 20 characters)"
// //                             name="decemberEvent4Caption"
// //                             id="decemberEvent4Caption"
// //                             value={formData.decemberEvent4Caption}
// //                             onChange={handleChange}
// //                             maxLength={20}
// //                           />
// //                         </div>
// //                       </div>
// //                     </div>
// //                     <div className="event-buttons">
// //                       <button className="b1" onClick={(e) => handleRemove(e)}>
// //                         Remove
// //                       </button>
// //                       <button className="b2" type="submit">
// //                         Add
// //                       </button>
// //                     </div>
// //                   </form>
// //                 </div>
// //               </div>
// //             </div>
// //           </div>
// //         </div>
// //         <div className="image-button">
// //           {/* <Link to={`/preview/${calendarId}`}><button>Continue to Next Step</button></Link> */}
// //           <Link to={`/preview1/${calendarId}`}><button>Continue to Next Step</button></Link>
// //         </div>
// //       </div>

// //       <Footer />
// //     </>
// //   );
// // };

// // export default Events;


// // // import React, { useEffect, useState } from "react";
// // // import Navbar from "../components/Navbar";
// // // import Footer from "../components/Footer";
// // // import { Link, useParams } from "react-router-dom";
// // // import iziToast from "izitoast";
// // // import axios from "axios";

// // // const Events = () => {
// // //   const [activeTab, setActiveTab] = useState("jan");
// //   // const [startYearMonth, setStartYearMonth] = useState(null);
// //   // const [holidays, setHolidays] = useState([]);
// // //   const { calendarId } = useParams();
// // //   const token = localStorage.getItem('token');
// // //   const [formData, setFormData] = useState({

// // //     januaryEvent1Date: "",
// // //     januaryEvent1Caption: "",
// // //     januaryEvent1Color: "",
// // //     januaryEvent2Date: "",
// // //     januaryEvent2Caption: "",
// // //     januaryEvent2Color: "",
// // //     januaryEvent3Date: "",
// // //     januaryEvent3Caption: "",
// // //     januaryEvent3Color: "",
// // //     januaryEvent4Date: "",
// // //     januaryEvent4Caption: "",
// // //     januaryEvent4Color: "",
// // //     februaryEvent1Date: "",
// // //     februaryEvent1Caption: "",
// // //     februaryEvent1Color: "",
// // //     februaryEvent2Date: "",
// // //     februaryEvent2Caption: "",
// // //     februaryEvent2Color: "",
// // //     februaryEvent3Date: "",
// // //     februaryEvent3Caption: "",
// // //     februaryEvent3Color: "",
// // //     februaryEvent4Date: "",
// // //     februaryEvent4Caption: "",
// // //     februaryEvent4Color: "",
// // //     marchEvent1Date: "",
// // //     marchEvent1Caption: "",
// // //     marchEvent1Color: "",
// // //     marchEvent2Date: "",
// // //     marchEvent2Caption: "",
// // //     marchEvent2Color: "",
// // //     marchEvent3Date: "",
// // //     marchEvent3Caption: "",
// // //     marchEvent3Color: "",
// // //     marchEvent4Date: "",
// // //     marchEvent4Caption: "",
// // //     marchEvent4Color: "",
// // //     aprilEvent1Date: "",
// // //     aprilEvent1Caption: "",
// // //     aprilEvent1Color: "",
// // //     aprilEvent2Date: "",
// // //     aprilEvent2Caption: "",
// // //     aprilEvent2Color: "",
// // //     aprilEvent3Date: "",
// // //     aprilEvent3Caption: "",
// // //     aprilEvent3Color: "",
// // //     aprilEvent4Date: "",
// // //     aprilEvent4Caption: "",
// // //     aprilEvent4Color: "",
// // //     mayEvent1Date: "",
// // //     mayEvent1Caption: "",
// // //     mayEvent1Color: "",
// // //     mayEvent2Date: "",
// // //     mayEvent2Caption: "",
// // //     mayEvent2Color: "",
// // //     mayEvent3Date: "",
// // //     mayEvent3Caption: "",
// // //     mayEvent3Color: "",
// // //     mayEvent4Date: "",
// // //     mayEvent4Caption: "",
// // //     mayEvent4Color: "",
// // //     juneEvent1Date: "",
// // //     juneEvent1Caption: "",
// // //     juneEvent1Color: "",
// // //     juneEvent2Date: "",
// // //     juneEvent2Caption: "",
// // //     juneEvent2Color: "",
// // //     juneEvent3Date: "",
// // //     juneEvent3Caption: "",
// // //     juneEvent3Color: "",
// // //     juneEvent4Date: "",
// // //     juneEvent4Caption: "",
// // //     juneEvent4Color: "",
// // //     julyEvent1Date: "",
// // //     julyEvent1Caption: "",
// // //     julyEvent1Color: "",
// // //     julyEvent2Date: "",
// // //     julyEvent2Caption: "",
// // //     julyEvent2Color: "",
// // //     julyEvent3Date: "",
// // //     julyEvent3Caption: "",
// // //     julyEvent3Color: "",
// // //     julyEvent4Date: "",
// // //     julyEvent4Caption: "",
// // //     julyEvent4Color: "",
// // //     augustEvent1Date: "",
// // //     augustEvent1Caption: "",
// // //     augustEvent1Color: "",
// // //     augustEvent2Date: "",
// // //     augustEvent2Caption: "",
// // //     augustEvent2Color: "",
// // //     augustEvent3Date: "",
// // //     augustEvent3Caption: "",
// // //     augustEvent3Color: "",
// // //     augustEvent4Date: "",
// // //     augustEvent4Caption: "",
// // //     augustEvent4Color: "",
// // //     septemberEvent1Date: "",
// // //     septemberEvent1Caption: "",
// // //     septemberEvent1Color: "",
// // //     septemberEvent2Date: "",
// // //     septemberEvent2Caption: "",
// // //     septemberEvent2Color: "",
// // //     septemberEvent3Date: "",
// // //     septemberEvent3Caption: "",
// // //     septemberEvent3Color: "",
// // //     septemberEvent4Date: "",
// // //     septemberEvent4Caption: "",
// // //     septemberEvent4Color: "",
// // //     octoberEvent1Date: "",
// // //     octoberEvent1Caption: "",
// // //     octoberEvent1Color: "",
// // //     octoberEvent2Date: "",
// // //     octoberEvent2Caption: "",
// // //     octoberEvent2Color: "",
// // //     octoberEvent3Date: "",
// // //     octoberEvent3Caption: "",
// // //     octoberEvent3Color: "",
// // //     octoberEvent4Date: "",
// // //     octoberEvent4Caption: "",
// // //     octoberEvent4Color: "",
// // //     novemberEvent1Date: "",
// // //     novemberEvent1Caption: "",
// // //     novemberEvent1Color: "",
// // //     novemberEvent2Date: "",
// // //     novemberEvent2Caption: "",
// // //     novemberEvent2Color: "",
// // //     novemberEvent3Date: "",
// // //     novemberEvent3Caption: "",
// // //     novemberEvent3Color: "",
// // //     novemberEvent4Date: "",
// // //     novemberEvent4Caption: "",
// // //     novemberEvent4Color: "",
// // //     decemberEvent1Date: "",
// // //     decemberEvent1Caption: "",
// // //     decemberEvent1Color: "",
// // //     decemberEvent2Date: "",
// // //     decemberEvent2Caption: "",
// // //     decemberEvent2Color: "",
// // //     decemberEvent3Date: "",
// // //     decemberEvent3Caption: "",
// // //     decemberEvent3Color: "",
// // //     decemberEvent4Date: "",
// // //     decemberEvent4Caption: "",
// // //     decemberEvent4Color: "",

// // //   });

// // //   useEffect(() => {
// // //     window.scrollTo({ top: 0, left: 0, behavior: "auto" });
// // //     fetchCalendarEdit();
// // //   }, []);

// // //   useEffect(() => {
// // //     if (startYearMonth) {
// // //       fetchHolidays();
// // //     }
// // //   }, [activeTab, startYearMonth]);

// //   // const fetchCalendarEdit = async () => {
// //   //   try {
// //   //     const response = await axios.get(`https://giftonly4u.com:4000/api/v1/calendar/details/${calendarId}`);
// //   //     const calendarData = response.data.calendar;
// //   //     setFormData(calendarData);
// //   //     setStartYearMonth(new Date(calendarData.startYearMonth));
// //   //   } catch (error) {
// //   //     console.error("Error fetching calendar Details:", error);
// //   //   }
// //   // };

// // //   const fetchHolidays = async () => {
// // //     if (!startYearMonth) return;

// // //     const year = startYearMonth.getFullYear();
// // //     const month = startYearMonth.getMonth() + months.indexOf(activeTab);
// // //     const startDate = new Date(year, month, 1).toISOString();
// // //     const endDate = new Date(year, month + 1, 0).toISOString();
// // //     const apiKey = 'AIzaSyAjfBYZVOqpBtwp6Ty55hL9-eXAVD6rKUs'; // Replace with your actual API key
// // //     const calendarId = 'shubhammcmt01@gmail.com'; // Replace with your Google Calendar ID
// // //     const calendarApiUrl = `https://www.googleapis.com/calendar/v3/calendars/${calendarId}/events?key=${apiKey}&timeMin=${startDate}&timeMax=${endDate}&singleEvents=true&orderBy=startTime`;

// // //     try {
// // //       const response = await axios.get(calendarApiUrl);
// // //       const events = response.data.items;
// // //       const holidays = events
// // //         .filter(event => event.summary && event.summary.toLowerCase().includes("holiday"))
// // //         .map(holiday => ({
// // //           date: new Date(holiday.start.date || holiday.start.dateTime),
// // //           summary: holiday.summary
// // //         }));
// // //       setHolidays(holidays);
// // //     } catch (error) {
// // //       console.error("Error fetching holidays:", error);
// // //     }
// // //   };

// // //   const openTab = (tabName) => setActiveTab(tabName);

// // //   const handleChange = (e) => {
// // //     const { name, value } = e.target;
// // //     setFormData({ ...formData, [name]: value });
// // //   };

// // //   const handleUpdate = async (e) => {
// // //     e.preventDefault();
// // //     try {
// // //       const response = await axios.put(
// // //         `https://giftonly4u.com:4000/api/v1/calendar/details/${calendarId}`,
// // //         formData,
// // //         { headers: { 'Authorization': `Bearer ${token}` } }
// // //       );

// // //       if (response.status === 200) {
// // //         iziToast.success({ message: "Event add successful", position: "topCenter" });
// // //       } else {
// // //         iziToast.error({ message: "Event add failed", position: "topCenter" });
// // //       }
// // //     } catch (error) {
// // //       console.error("Error adding event details:", error);
// // //       iziToast.error({ message: "An error occurred", position: "topCenter" });
// // //     }
// // //   };

// // //   // Function to handle the Remove button click
// // //   const handleRemove = (e) => {
// // //     e.preventDefault();
// // //     setFormData({
// // //       ...formData,
// // //       januaryEvent1Date: "",
// // //       januaryEvent1Caption: "",
// // //       januaryEvent1Color: "",
// // //       januaryEvent2Date: "",
// // //       januaryEvent2Caption: "",
// // //       januaryEvent2Color: "",
// // //       januaryEvent3Date: "",
// // //       januaryEvent3Caption: "",
// // //       januaryEvent3Color: "",
// // //       januaryEvent4Date: "",
// // //       januaryEvent4Caption: "",
// // //       januaryEvent4Color: "",
// // //       februaryEvent1Date: "",
// // //       februaryEvent1Caption: "",
// // //       februaryEvent1Color: "",
// // //       februaryEvent2Date: "",
// // //       februaryEvent2Caption: "",
// // //       februaryEvent2Color: "",
// // //       februaryEvent3Date: "",
// // //       februaryEvent3Caption: "",
// // //       februaryEvent3Color: "",
// // //       februaryEvent4Date: "",
// // //       februaryEvent4Caption: "",
// // //       februaryEvent4Color: "",
// // //       marchEvent1Date: "",
// // //       marchEvent1Caption: "",
// // //       marchEvent1Color: "",
// // //       marchEvent2Date: "",
// // //       marchEvent2Caption: "",
// // //       marchEvent2Color: "",
// // //       marchEvent3Date: "",
// // //       marchEvent3Caption: "",
// // //       marchEvent3Color: "",
// // //       marchEvent4Date: "",
// // //       marchEvent4Caption: "",
// // //       marchEvent4Color: "",
// // //       aprilEvent1Date: "",
// // //       aprilEvent1Caption: "",
// // //       aprilEvent1Color: "",
// // //       aprilEvent2Date: "",
// // //       aprilEvent2Caption: "",
// // //       aprilEvent2Color: "",
// // //       aprilEvent3Date: "",
// // //       aprilEvent3Caption: "",
// // //       aprilEvent3Color: "",
// // //       aprilEvent4Date: "",
// // //       aprilEvent4Caption: "",
// // //       aprilEvent4Color: "",
// // //       mayEvent1Date: "",
// // //       mayEvent1Caption: "",
// // //       mayEvent1Color: "",
// // //       mayEvent2Date: "",
// // //       mayEvent2Caption: "",
// // //       mayEvent2Color: "",
// // //       mayEvent3Date: "",
// // //       mayEvent3Caption: "",
// // //       mayEvent3Color: "",
// // //       mayEvent4Date: "",
// // //       mayEvent4Caption: "",
// // //       mayEvent4Color: "",
// // //       juneEvent1Date: "",
// // //       juneEvent1Caption: "",
// // //       juneEvent1Color: "",
// // //       juneEvent2Date: "",
// // //       juneEvent2Caption: "",
// // //       juneEvent2Color: "",
// // //       juneEvent3Date: "",
// // //       juneEvent3Caption: "",
// // //       juneEvent3Color: "",
// // //       juneEvent4Date: "",
// // //       juneEvent4Caption: "",
// // //       juneEvent4Color: "",
// // //       julyEvent1Date: "",
// // //       julyEvent1Caption: "",
// // //       julyEvent1Color: "",
// // //       julyEvent2Date: "",
// // //       julyEvent2Caption: "",
// // //       julyEvent2Color: "",
// // //       julyEvent3Date: "",
// // //       julyEvent3Caption: "",
// // //       julyEvent3Color: "",
// // //       julyEvent4Date: "",
// // //       julyEvent4Caption: "",
// // //       julyEvent4Color: "",
// // //       augustEvent1Date: "",
// // //       augustEvent1Caption: "",
// // //       augustEvent1Color: "",
// // //       augustEvent2Date: "",
// // //       augustEvent2Caption: "",
// // //       augustEvent2Color: "",
// // //       augustEvent3Date: "",
// // //       augustEvent3Caption: "",
// // //       augustEvent3Color: "",
// // //       augustEvent4Date: "",
// // //       augustEvent4Caption: "",
// // //       augustEvent4Color: "",
// // //       septemberEvent1Date: "",
// // //       septemberEvent1Caption: "",
// // //       septemberEvent1Color: "",
// // //       septemberEvent2Date: "",
// // //       septemberEvent2Caption: "",
// // //       septemberEvent2Color: "",
// // //       septemberEvent3Date: "",
// // //       septemberEvent3Caption: "",
// // //       septemberEvent3Color: "",
// // //       septemberEvent4Date: "",
// // //       septemberEvent4Caption: "",
// // //       septemberEvent4Color: "",
// // //       octoberEvent1Date: "",
// // //       octoberEvent1Caption: "",
// // //       octoberEvent1Color: "",
// // //       octoberEvent2Date: "",
// // //       octoberEvent2Caption: "",
// // //       octoberEvent2Color: "",
// // //       octoberEvent3Date: "",
// // //       octoberEvent3Caption: "",
// // //       octoberEvent3Color: "",
// // //       octoberEvent4Date: "",
// // //       octoberEvent4Caption: "",
// // //       octoberEvent4Color: "",
// // //       novemberEvent1Date: "",
// // //       novemberEvent1Caption: "",
// // //       novemberEvent1Color: "",
// // //       novemberEvent2Date: "",
// // //       novemberEvent2Caption: "",
// // //       novemberEvent2Color: "",
// // //       novemberEvent3Date: "",
// // //       novemberEvent3Caption: "",
// // //       novemberEvent3Color: "",
// // //       novemberEvent4Date: "",
// // //       novemberEvent4Caption: "",
// // //       novemberEvent4Color: "",
// // //       decemberEvent1Date: "",
// // //       decemberEvent1Caption: "",
// // //       decemberEvent1Color: "",
// // //       decemberEvent2Date: "",
// // //       decemberEvent2Caption: "",
// // //       decemberEvent2Color: "",
// // //       decemberEvent3Date: "",
// // //       decemberEvent3Caption: "",
// // //       decemberEvent3Color: "",
// // //       decemberEvent4Date: "",
// // //       decemberEvent4Caption: "",
// // //       decemberEvent4Color: "",
// // //     });
// // //   };

// //   // const getDaysInMonth = (year, month) => {
// //   //   return new Array(31).fill('').map((_, index) => new Date(year, month, index + 1))
// //   //     .filter(date => date.getMonth() === month)
// //   //     .map(date => date.getDate());
// //   // };

// //   // const renderDateOptions = (monthOffset) => {
// //   //   if (!startYearMonth) return null;
// //   //   const year = startYearMonth.getFullYear();
// //   //   const month = startYearMonth.getMonth() + monthOffset;
// //   //   const days = getDaysInMonth(year, month);

// //   //   return days.map(day => (
// //   //     <option key={day} value={day}>{day}</option>
// //   //   ));
// //   // };

// //   // const renderHolidayOptions = () => {
// //   //   if (!startYearMonth) return null;
// //   //   const year = startYearMonth.getFullYear();
// //   //   const month = startYearMonth.getMonth() + months.indexOf(activeTab);

// //   //   return holidays
// //   //     .filter(holiday => holiday.date.getFullYear() === year && holiday.date.getMonth() === month)
// //   //     .map((holiday, index) => (
// //   //       <option key={index} value={holiday.date.getDate()}>{`${holiday.date.getDate()} - ${holiday.summary}`}</option>
// //   //     ));
// //   // };

// // //   const renderEventForm = (month, eventIndex) => {
// // //     const monthName = month.charAt(0).toUpperCase() + month.slice(1);
// // //     const eventDateField = `${month}Event${eventIndex}Date`;
// // //     const eventCaptionField = `${month}Event${eventIndex}Caption`;
// // //     const eventColorField = `${month}Event${eventIndex}Color`;

// // //     return (
// // //       // <div className="event-card" key={`${month}-event-${eventIndex}`}>
// // //       <div className="event-card">
// // //         <h2>Your Event {eventIndex} : </h2>
// // //         <form onSubmit={handleUpdate}>
// // //           <div className="row">
// // //             <div className="col-lg-5">
// // //               <div className="event-item">
// // //                 <label htmlFor="">Add Date : </label>
// // //                 <select
// // //                   name="januaryEvent1Date"
// // //                   id="januaryEvent1Date"
// // //                   value={formData.januaryEvent1Date}
// // //                   onChange={handleChange}
// // //                 >
// // //                   <option value="">Select Date</option>
// // //                   {renderDateOptions(months.indexOf(month))}
// // //                   {renderHolidayOptions()}
// // //                 </select>
// // //               </div>
// // //             </div>
// // //             <div className="col-lg-5">
// // //               <div className="event-item">
// // //                 <label htmlFor="">Select Color : </label>
// // //                 <input
// // //                   type="color"
// // //                   name="januaryEvent1Color"
// // //                   id="januaryEvent1Color"
// // //                   value={formData.januaryEvent1Color}
// // //                   onChange={handleChange}
// // //                 />
// // //               </div>
// // //             </div>
// // //             <div className="col-lg-12">
// // //               <div className="event-item">
// // //                 <label htmlFor="">Caption : </label>
// // //                 <input
// // //                   type="text"
// // //                   placeholder="Enter Caption (max 20 characters)"
// // //                   name="januaryEvent1Caption"
// // //                   id="januaryEvent1Caption"
// // //                   value={formData.januaryEvent1Caption}
// // //                   onChange={handleChange}
// // //                   maxLength={20}
// // //                 />
// // //               </div>
// // //             </div>
// // //           </div>
// // //           <div className="event-buttons">
// // //             <button className="b1" onClick={(e) => handleRemove(e, month)}>
// // //               Remove
// // //             </button>
// // //             <button className="b2" type="submit">
// // //               Add
// // //             </button>
// // //           </div>
// // //         </form>
// // //       </div>

// // //     );
// // //   };

// // //   const months = [
// // //     "jan", "feb", "march", "april", "may", "june",
// // //     "july", "aug", "sept", "oct", "nov", "dec"
// // //   ];

// // //   return (
// // //     <>
// // //       <Navbar />
// // //       <div className="container">
// // //         <div className="col-lg-6 mx-auto image-header">
// // //           <h1>Let’s Make your own personal Calendar</h1>
// // //           <div className="progress-container">
// // //             <div className="progress-line"></div>
// // //             <div className="circle" id="step1">1</div>
// // //             <div className="circle active" id="step2">2</div>
// // //             <div className="circle" id="step3">3</div>
// // //           </div>
// // //         </div>
// // //         <div className="image-content">
// // //           <h3>Add Events in your Calendar :</h3>
// // //           <div className="row image-row py-3">
// // //             <div className="col-lg-3">
// // //               <div className="col-lg-8">
// // //                 <div className="tab">
// // //                   {months.map(month => (
// // //                     <button
// // //                       key={month}
// // //                       className={`tablinks ${activeTab === month && "active"}`}
// // //                       onClick={() => openTab(month)}
// // //                       id={`${month}-button`}
// // //                     >
// // //                       {month.charAt(0).toUpperCase() + month.slice(1)}
// // //                     </button>
// // //                   ))}
// // //                 </div>
// // //               </div>
// // //             </div>
// // //             <div className="col-lg-9">
// // //               <div className="holiday">
// // //                 <h2>Public Holidays This month :</h2>
// // //                 <div className="holiday-list">
// // //                   {holidays.length > 0 ? (
// // //                     holidays.map((holiday, index) => (
// // //                       <li key={index}>
// // //                         <span className="c1"></span> {holiday.summary}: {holiday.date.toLocaleDateString()}
// // //                       </li>
// // //                     ))
// // //                   ) : (
// // //                     <p>No holidays this month.</p>
// // //                   )}
// // //                 </div>
// // //               </div>
// // //               {months.map(month => (
// // //                 <div
// // //                   key={month}
// // //                   id={month}
// // //                   className={`events ${activeTab === month ? "active" : ""}`}
// // //                 >
// // //                   <h4>{month.charAt(0).toUpperCase() + month.slice(1)}</h4>
// // //                   {[1, 2, 3, 4].map(eventIndex => renderEventForm(month, eventIndex))}
// // //                 </div>
// // //               ))}
// // //             </div>
// // //           </div>
// // //           <div className="image-button">
// // //             <Link to={`/preview1/${calendarId}`}>
// // //               <button>Continue to Next Step</button>
// // //             </Link>
// // //           </div>
// // //         </div>
// // //       </div>
// // //       <Footer />
// // //     </>
// // //   );
// // // };

// // // export default Events;


// const Events = () => {
//   const [activeTab, setActiveTab] = useState("jan");
//   const [startYearMonth, setStartYearMonth] = useState(null);
//   const [holidays, setHolidays] = useState([]);
//   const [year, setYear] = useState("");
//   const [month, setMonth] = useState("");
//   const [daysInMonth, setDaysInMonth] = useState([]);
//   const [holidayMap, setHolidayMap] = useState({}); // Store holidays as a map

//   const [isLoggedIn, setIsLoggedIn] = useState(false);

//   // Function to check if user is logged in
//   const checkLoginStatus = () => {
//    const token = localStorage.getItem('token');
//    setIsLoggedIn(!!token); // Set login status based on token presence
// };

// useEffect(() => {
//    checkLoginStatus(); // Check login status on component mount
// }, []);

//   const openTab = (tabName) => {
//     setActiveTab(tabName);
//     // Update the month and year based on the selected tab
//     const monthMap = {
//       jan: 0,
//       feb: 1,
//       march: 2,
//       april: 3,
//       may: 4,
//       june: 5,
//       july: 6,
//       aug: 7,
//       sept: 8,
//       oct: 9,
//       nov: 10,
//       dec: 11,
//     };
//     setMonth(monthMap[tabName] + 1); // +1 to convert from 0-based index
//   };

//   useEffect(() => {
//     window.scrollTo({
//       top: 0,
//       left: 0,
//       behavior: "auto",
//     });
//   }, []);

//   const { calendarId } = useParams();  
//   const { apipath } = useAuth();
//   const navigate = useNavigate();
//   const [formData, setFormData] = useState({

//     januaryEvent1Date: "",
//     januaryEvent1Caption: "",
//     januaryEvent1Color: "",
//     januaryEvent2Date: "",
//     januaryEvent2Caption: "",
//     januaryEvent2Color: "",
//     januaryEvent3Date: "",
//     januaryEvent3Caption: "",
//     januaryEvent3Color: "",
//     januaryEvent4Date: "",
//     januaryEvent4Caption: "",
//     januaryEvent4Color: "",
//     februaryEvent1Date: "",
//     februaryEvent1Caption: "",
//     februaryEvent1Color: "",
//     februaryEvent2Date: "",
//     februaryEvent2Caption: "",
//     februaryEvent2Color: "",
//     februaryEvent3Date: "",
//     februaryEvent3Caption: "",
//     februaryEvent3Color: "",
//     februaryEvent4Date: "",
//     februaryEvent4Caption: "",
//     februaryEvent4Color: "",
//     marchEvent1Date: "",
//     marchEvent1Caption: "",
//     marchEvent1Color: "",
//     marchEvent2Date: "",
//     marchEvent2Caption: "",
//     marchEvent2Color: "",
//     marchEvent3Date: "",
//     marchEvent3Caption: "",
//     marchEvent3Color: "",
//     marchEvent4Date: "",
//     marchEvent4Caption: "",
//     marchEvent4Color: "",
//     aprilEvent1Date: "",
//     aprilEvent1Caption: "",
//     aprilEvent1Color: "",
//     aprilEvent2Date: "",
//     aprilEvent2Caption: "",
//     aprilEvent2Color: "",
//     aprilEvent3Date: "",
//     aprilEvent3Caption: "",
//     aprilEvent3Color: "",
//     aprilEvent4Date: "",
//     aprilEvent4Caption: "",
//     aprilEvent4Color: "",
//     mayEvent1Date: "",
//     mayEvent1Caption: "",
//     mayEvent1Color: "",
//     mayEvent2Date: "",
//     mayEvent2Caption: "",
//     mayEvent2Color: "",
//     mayEvent3Date: "",
//     mayEvent3Caption: "",
//     mayEvent3Color: "",
//     mayEvent4Date: "",
//     mayEvent4Caption: "",
//     mayEvent4Color: "",
//     juneEvent1Date: "",
//     juneEvent1Caption: "",
//     juneEvent1Color: "",
//     juneEvent2Date: "",
//     juneEvent2Caption: "",
//     juneEvent2Color: "",
//     juneEvent3Date: "",
//     juneEvent3Caption: "",
//     juneEvent3Color: "",
//     juneEvent4Date: "",
//     juneEvent4Caption: "",
//     juneEvent4Color: "",
//     julyEvent1Date: "",
//     julyEvent1Caption: "",
//     julyEvent1Color: "",
//     julyEvent2Date: "",
//     julyEvent2Caption: "",
//     julyEvent2Color: "",
//     julyEvent3Date: "",
//     julyEvent3Caption: "",
//     julyEvent3Color: "",
//     julyEvent4Date: "",
//     julyEvent4Caption: "",
//     julyEvent4Color: "",
//     augustEvent1Date: "",
//     augustEvent1Caption: "",
//     augustEvent1Color: "",
//     augustEvent2Date: "",
//     augustEvent2Caption: "",
//     augustEvent2Color: "",
//     augustEvent3Date: "",
//     augustEvent3Caption: "",
//     augustEvent3Color: "",
//     augustEvent4Date: "",
//     augustEvent4Caption: "",
//     augustEvent4Color: "",
//     septemberEvent1Date: "",
//     septemberEvent1Caption: "",
//     septemberEvent1Color: "",
//     septemberEvent2Date: "",
//     septemberEvent2Caption: "",
//     septemberEvent2Color: "",
//     septemberEvent3Date: "",
//     septemberEvent3Caption: "",
//     septemberEvent3Color: "",
//     septemberEvent4Date: "",
//     septemberEvent4Caption: "",
//     septemberEvent4Color: "",
//     octoberEvent1Date: "",
//     octoberEvent1Caption: "",
//     octoberEvent1Color: "",
//     octoberEvent2Date: "",
//     octoberEvent2Caption: "",
//     octoberEvent2Color: "",
//     octoberEvent3Date: "",
//     octoberEvent3Caption: "",
//     octoberEvent3Color: "",
//     octoberEvent4Date: "",
//     octoberEvent4Caption: "",
//     octoberEvent4Color: "",
//     novemberEvent1Date: "",
//     novemberEvent1Caption: "",
//     novemberEvent1Color: "",
//     novemberEvent2Date: "",
//     novemberEvent2Caption: "",
//     novemberEvent2Color: "",
//     novemberEvent3Date: "",
//     novemberEvent3Caption: "",
//     novemberEvent3Color: "",
//     novemberEvent4Date: "",
//     novemberEvent4Caption: "",
//     novemberEvent4Color: "",
//     decemberEvent1Date: "",
//     decemberEvent1Caption: "",
//     decemberEvent1Color: "",
//     decemberEvent2Date: "",
//     decemberEvent2Caption: "",
//     decemberEvent2Color: "",
//     decemberEvent3Date: "",
//     decemberEvent3Caption: "",
//     decemberEvent3Color: "",
//     decemberEvent4Date: "",
//     decemberEvent4Caption: "",
//     decemberEvent4Color: "",

//   });

//   const token = localStorage.getItem('token');

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({
//       ...formData,
//       [name]: value,
//     });
//   };

//   useEffect(() => {
//     fetchCalendarEdit();
//   }, []);

//   const fetchCalendarEdit = async () => {
//     try {
//       const response = await axios.get(apipath + `/calendar/details/${calendarId}`);
//       const calendarData = response.data.calendar;
//       setFormData(calendarData);
//       setStartYearMonth(new Date(calendarData.startYearMonth));
//       const startYearMonth = new Date(calendarData.startYearMonth);
//       setYear(startYearMonth.getFullYear());
//       setMonth(startYearMonth.getMonth() + 1); // getMonth() returns 0-11
//     } catch (error) {
//       console.error("Error fetching calendar Details:", error);
//     }
//   };

// useEffect(() => {
//   if (year && month) {
//     const days = new Date(year, month, 0).getDate();
//     setDaysInMonth(Array.from({ length: days }, (_, i) => i + 1));
//     fetchHolidays(year, month);
//   }
// }, [year, month]);

//   const handleUpdate = async (e) => {
//     e.preventDefault();
//     try {
//       const response = await axios.put(
//         apipath + `/calendar/details/${calendarId}`,
//         formData,
//         {
//           headers: {
//             'Authorization': `Bearer ${token}`,
//           },
//         }
//       );

//       if (response.status === 200) {
//         iziToast.success({
//           message: "Event update successful",
//           position: "topCenter",
//         });
//       } else {
//         iziToast.error({
//           message: "Event update failed",
//           position: "topCenter",
//         });
//       }
//     } catch (error) {
//       console.error("Error updating event details:", error);
//       iziToast.error({
//         message: "An error occurred",
//         position: "topCenter",
//       });
//     }
//   };

//   const getDaysInMonth = (year, month) => {
//     return new Array(31).fill('').map((_, index) => new Date(year, month, index + 1))
//       .filter(date => date.getMonth() === month)
//       .map(date => date.getDate());
//   };

// const renderDateOptions = (monthOffset) => {
//   if (!startYearMonth) return null;
//   const newDate = new Date(startYearMonth);
//   newDate.setMonth(startYearMonth.getMonth() + monthOffset);
//   const year = newDate.getFullYear();
//   const month = newDate.getMonth();
//   const days = getDaysInMonth(year, month);

//   return days.map(day => (
//     <option key={day} value={day}> {day} {holidayMap[day] ? `- ${holidayMap[day].join(', ')}` : ''}</option>
//   ));
// };

// const fetchHolidays = async (year, month) => {
//   try {
//     const response = await axios.get(`https://www.googleapis.com/calendar/v3/calendars/en.indian%23holiday%40group.v.calendar.google.com/events?key=AIzaSyAjfBYZVOqpBtwp6Ty55hL9-eXAVD6rKUs`);
//     const events = response.data.items;
//     const holidays = events
//       .map(event => ({
//         summary: event.summary,
//         date: new Date(event.start.date),
//       }))
//       .filter(holiday => holiday.date.getFullYear() === year && holiday.date.getMonth() === month - 1); // Adjust month for 0-based index

//     setHolidayMap(holidays.reduce((map, holiday) => {
//       const day = holiday.date.getDate();
//       if (!map[day]) {
//         map[day] = [];
//       }
//       map[day].push(holiday.summary);
//       return map;
//     }, {}));
//     setHolidays(holidays);
//   } catch (error) {
//     console.error('Error fetching holidays:', error);
//   }
// };

//   const renderHolidayOptions = () => {
//     const rowColors = ["c1", "c2", "c3"];
//     let rows = [];
//     for (let i = 0; i < holidays.length; i += 2) {
//       const colorClass = rowColors[Math.floor(i / 2) % rowColors.length];
//       rows.push(
//         <div className="holiday-row" key={i}>
//           <div className="holiday-item">
//             <span className={`holiday-color ${colorClass}`}></span>
//             <span>{`${holidays[i].date.getDate()} - ${holidays[i].summary}`}</span>
//           </div>
//           {holidays[i + 1] && (
//             <div className="holiday-item" key={i + 1}>
//               <span className={`holiday-color ${colorClass}`}></span>
//               <span>{`${holidays[i + 1].date.getDate()} - ${holidays[i + 1].summary}`}</span>
//             </div>
//           )}
//         </div>
//       );
//     }
//     return rows;
//   };

//     // Function to handle the Remove button click
//     const handleRemove = (e) => {
//       e.preventDefault();
//       setFormData({
//         ...formData,
//         januaryEvent1Date: "",
//         januaryEvent1Caption: "",
//         januaryEvent1Color: "",
//         januaryEvent2Date: "",
//         januaryEvent2Caption: "",
//         januaryEvent2Color: "",
//         januaryEvent3Date: "",
//         januaryEvent3Caption: "",
//         januaryEvent3Color: "",
//         januaryEvent4Date: "",
//         januaryEvent4Caption: "",
//         januaryEvent4Color: "",
//         februaryEvent1Date: "",
//         februaryEvent1Caption: "",
//         februaryEvent1Color: "",
//         februaryEvent2Date: "",
//         februaryEvent2Caption: "",
//         februaryEvent2Color: "",
//         februaryEvent3Date: "",
//         februaryEvent3Caption: "",
//         februaryEvent3Color: "",
//         februaryEvent4Date: "",
//         februaryEvent4Caption: "",
//         februaryEvent4Color: "",
//         marchEvent1Date: "",
//         marchEvent1Caption: "",
//         marchEvent1Color: "",
//         marchEvent2Date: "",
//         marchEvent2Caption: "",
//         marchEvent2Color: "",
//         marchEvent3Date: "",
//         marchEvent3Caption: "",
//         marchEvent3Color: "",
//         marchEvent4Date: "",
//         marchEvent4Caption: "",
//         marchEvent4Color: "",
//         aprilEvent1Date: "",
//         aprilEvent1Caption: "",
//         aprilEvent1Color: "",
//         aprilEvent2Date: "",
//         aprilEvent2Caption: "",
//         aprilEvent2Color: "",
//         aprilEvent3Date: "",
//         aprilEvent3Caption: "",
//         aprilEvent3Color: "",
//         aprilEvent4Date: "",
//         aprilEvent4Caption: "",
//         aprilEvent4Color: "",
//         mayEvent1Date: "",
//         mayEvent1Caption: "",
//         mayEvent1Color: "",
//         mayEvent2Date: "",
//         mayEvent2Caption: "",
//         mayEvent2Color: "",
//         mayEvent3Date: "",
//         mayEvent3Caption: "",
//         mayEvent3Color: "",
//         mayEvent4Date: "",
//         mayEvent4Caption: "",
//         mayEvent4Color: "",
//         juneEvent1Date: "",
//         juneEvent1Caption: "",
//         juneEvent1Color: "",
//         juneEvent2Date: "",
//         juneEvent2Caption: "",
//         juneEvent2Color: "",
//         juneEvent3Date: "",
//         juneEvent3Caption: "",
//         juneEvent3Color: "",
//         juneEvent4Date: "",
//         juneEvent4Caption: "",
//         juneEvent4Color: "",
//         julyEvent1Date: "",
//         julyEvent1Caption: "",
//         julyEvent1Color: "",
//         julyEvent2Date: "",
//         julyEvent2Caption: "",
//         julyEvent2Color: "",
//         julyEvent3Date: "",
//         julyEvent3Caption: "",
//         julyEvent3Color: "",
//         julyEvent4Date: "",
//         julyEvent4Caption: "",
//         julyEvent4Color: "",
//         augustEvent1Date: "",
//         augustEvent1Caption: "",
//         augustEvent1Color: "",
//         augustEvent2Date: "",
//         augustEvent2Caption: "",
//         augustEvent2Color: "",
//         augustEvent3Date: "",
//         augustEvent3Caption: "",
//         augustEvent3Color: "",
//         augustEvent4Date: "",
//         augustEvent4Caption: "",
//         augustEvent4Color: "",
//         septemberEvent1Date: "",
//         septemberEvent1Caption: "",
//         septemberEvent1Color: "",
//         septemberEvent2Date: "",
//         septemberEvent2Caption: "",
//         septemberEvent2Color: "",
//         septemberEvent3Date: "",
//         septemberEvent3Caption: "",
//         septemberEvent3Color: "",
//         septemberEvent4Date: "",
//         septemberEvent4Caption: "",
//         septemberEvent4Color: "",
//         octoberEvent1Date: "",
//         octoberEvent1Caption: "",
//         octoberEvent1Color: "",
//         octoberEvent2Date: "",
//         octoberEvent2Caption: "",
//         octoberEvent2Color: "",
//         octoberEvent3Date: "",
//         octoberEvent3Caption: "",
//         octoberEvent3Color: "",
//         octoberEvent4Date: "",
//         octoberEvent4Caption: "",
//         octoberEvent4Color: "",
//         novemberEvent1Date: "",
//         novemberEvent1Caption: "",
//         novemberEvent1Color: "",
//         novemberEvent2Date: "",
//         novemberEvent2Caption: "",
//         novemberEvent2Color: "",
//         novemberEvent3Date: "",
//         novemberEvent3Caption: "",
//         novemberEvent3Color: "",
//         novemberEvent4Date: "",
//         novemberEvent4Caption: "",
//         novemberEvent4Color: "",
//         decemberEvent1Date: "",
//         decemberEvent1Caption: "",
//         decemberEvent1Color: "",
//         decemberEvent2Date: "",
//         decemberEvent2Caption: "",
//         decemberEvent2Color: "",
//         decemberEvent3Date: "",
//         decemberEvent3Caption: "",
//         decemberEvent3Color: "",
//         decemberEvent4Date: "",
//         decemberEvent4Caption: "",
//         decemberEvent4Color: "",
//       });
//     };

//   return (
//     <>
//       <Navbar isLoggedIn={isLoggedIn}/>
//       <div className="container">
//         <div className="col-lg-6 mx-auto image-header">
//           <h1>Let’s make your own personal calendar</h1>
//           <div class="progress-container">
//             <div class="progress-line"></div>
//             <div class="circle" id="step1">
//               1
//             </div>
//             <div class="circle" id="step2">
//               2
//             </div>
//             <div class="circle active" id="step3">
//               3
//             </div>
//           </div>
//         </div>
//       <div className="image-content">
//         <h3>Add events in your calendar :</h3>
//         <div className="row image-row py-3">
//           <div className="col-lg-3">
//             <div className="col-lg-8">
//               <div className="tab">
//                 <button
//                   className={`tablinks ${activeTab === "jan" && "active"}`}
//                   onClick={() => openTab("jan")}
//                   id="jan-button"
//                 >
//                   January
//                 </button>
//                 <button
//                   className={`tablinks ${activeTab === "feb" && "active"}`}
//                   onClick={() => openTab("feb")}
//                   id="feb-button"
//                 >
//                   February
//                 </button>
//                 <button
//                   className={`tablinks ${activeTab === "march" && "active"}`}
//                   onClick={() => openTab("march")}
//                   id="march-button"
//                 >
//                   March
//                 </button>
//                 <button
//                   className={`tablinks ${activeTab === "april" && "active"}`}
//                   onClick={() => openTab("april")}
//                   id="april-button"
//                 >
//                   April
//                 </button>
//                 <button
//                   className={`tablinks ${activeTab === "may" && "active"}`}
//                   onClick={() => openTab("may")}
//                   id="may-button"
//                 >
//                   May
//                 </button>
//                 <button
//                   className={`tablinks ${activeTab === "june" && "active"}`}
//                   onClick={() => openTab("june")}
//                   id="june-button"
//                 >
//                   June
//                 </button>
//                 <button
//                   className={`tablinks ${activeTab === "july" && "active"}`}
//                   onClick={() => openTab("july")}
//                   id="july-button"
//                 >
//                   July
//                 </button>
//                 <button
//                   className={`tablinks ${activeTab === "aug" && "active"}`}
//                   onClick={() => openTab("aug")}
//                   id="aug-button"
//                 >
//                   August
//                 </button>
//                 <button
//                   className={`tablinks ${activeTab === "sept" && "active"}`}
//                   onClick={() => openTab("sept")}
//                   id="sept-button"
//                 >
//                   September
//                 </button>
//                 <button
//                   className={`tablinks ${activeTab === "oct" && "active"}`}
//                   onClick={() => openTab("oct")}
//                   id="oct-button"
//                 >
//                   October
//                 </button>
//                 <button
//                   className={`tablinks ${activeTab === "nov" && "active"}`}
//                   onClick={() => openTab("nov")}
//                   id="nov-button"
//                 >
//                   November
//                 </button>
//                 <button
//                   className={`tablinks ${activeTab === "dec" && "active"}`}
//                   onClick={() => openTab("dec")}
//                   id="dec-button"
//                 >
//                   December
//                 </button>
//               </div>
//             </div>
//           </div>
//           <div className="col-lg-9">
//             <div className="holiday">
//               <h2>Public holidays this month:</h2>
//             <div className="holiday-list d-flex">
//               <div className="holiday-1">
//                 {renderHolidayOptions()}
//               </div>
//             </div>
//             </div>
//           <div
//                 id="jan"
//                 className={`events ${activeTab === "jan" ? "active" : ""}`}
//               >
//                 <h4>January</h4>
//                 <div className="event-card">
//                   <h2>Your Event 1 : </h2>
//                   <form onSubmit={handleUpdate}>
//                     <div className="row">
//                       <div className="col-lg-5">
//                         <div className="event-item">
//                           <label htmlFor="">Add Date : </label>
//                           <select
//                             className="form-control"
//                             name="januaryEvent1Date"
//                             value={formData.januaryEvent1Date}
//                             onChange={handleChange}
//                           >
//                             <option value="">Select Date</option>
//                             {renderDateOptions(0)}
//                           </select>
//                         </div>
//                       </div>
//                       <div className="col-lg-5">
//                         <div className="event-item">
//                           <label htmlFor="">Select Color : </label>
//                           <input
//                             type="color"
//                             name="januaryEvent1Color"
//                             id="januaryEvent1Color"
//                             value={formData.januaryEvent1Color}
//                             onChange={handleChange}
//                           />
//                         </div>
//                       </div>
//                       <div className="col-lg-12">
//                         <div className="event-item">
//                           <label htmlFor="">Caption : </label>
//                           <input
//                             type="text"
//                             placeholder="Enter Caption (max 20 characters)"
//                             name="januaryEvent1Caption"
//                             id="januaryEvent1Caption"
//                             value={formData.januaryEvent1Caption}
//                             onChange={handleChange}
//                             maxLength={20}
//                           />
//                         </div>
//                       </div>
//                     </div>
//                     <div className="event-buttons">
//                       <button className="b1" onClick={(e) => handleRemove(e)}>
//                         Remove
//                       </button>
//                       <button className="b2" type="submit">
//                         Add
//                       </button>
//                     </div>
//                   </form>
//                 </div>
//                 <div className="event-card">
//                   <h2>Your Event 2 : </h2>
//                   <form onSubmit={handleUpdate}>
//                     <div className="row">
//                       <div className="col-lg-5">
//                         <div className="event-item">
//                           <label htmlFor="">Add Date : </label>

//                           <select
//                           className="form-control"
//                   name="januaryEvent2Date"
//                   id="januaryEvent2Date"
//                   value={formData.januaryEvent2Date}
//                   onChange={handleChange}
//                 >
//                   <option value="">Select Date</option>
//                   {renderDateOptions(0)}
//                 </select>
//                         </div>
//                       </div>
//                       <div className="col-lg-5">
//                         <div className="event-item">
//                           <label htmlFor="">Select Color : </label>
//                           <input
//                             type="color"
//                             name="januaryEvent2Color"
//                             id="januaryEvent2Color"
//                             value={formData.januaryEvent2Color}
//                             onChange={handleChange}
//                           />
//                         </div>
//                       </div>
//                       <div className="col-lg-12">
//                         <div className="event-item">
//                           <label htmlFor="">Caption : </label>
//                           <input
//                             type="text"
//                             placeholder="Enter Caption (max 20 characters)"
//                             name="januaryEvent2Caption"
//                             id="januaryEvent2Caption"
//                             value={formData.januaryEvent2Caption}
//                             onChange={handleChange}
//                             maxLength={20}
//                           />
//                         </div>
//                       </div>
//                     </div>
//                     <div className="event-buttons">
//                       <button className="b1" onClick={(e) => handleRemove(e)}>
//                         Remove
//                       </button>
//                       <button className="b2" type="submit">
//                         Add
//                       </button>
//                     </div>
//                   </form>
//                 </div>
//                 <div className="event-card">
//                   <h2>Your Event 3 : </h2>
//                   <form onSubmit={handleUpdate}>
//                     <div className="row">
//                       <div className="col-lg-5">
//                         <div className="event-item">
//                           <label htmlFor="">Add Date : </label>
//                           <select
//                           className="form-control"
//                   name="januaryEvent3Date"
//                   id="januaryEvent3Date"
//                   value={formData.januaryEvent3Date}
//                   onChange={handleChange}
//                 >
//                   <option value="">Select Date</option>
//                   {renderDateOptions(0)}
//                 </select>
//                         </div>
//                       </div>
//                       <div className="col-lg-5">
//                         <div className="event-item">
//                           <label htmlFor="">Select Color : </label>
//                           <input
//                             type="color"
//                             name="januaryEvent3Color"
//                             id="januaryEvent3Color"
//                             value={formData.januaryEvent3Color}
//                             onChange={handleChange}
//                           />
//                         </div>
//                       </div>
//                       <div className="col-lg-12">
//                         <div className="event-item">
//                           <label htmlFor="">Caption : </label>
//                           <input
//                             type="text"
//                             placeholder="Enter Caption (max 20 characters)"
//                             name="januaryEvent3Caption"
//                             id="januaryEvent3Caption"
//                             value={formData.januaryEvent3Caption}
//                             onChange={handleChange}
//                             maxLength={20}
//                           />
//                         </div>
//                       </div>
//                     </div>
//                     <div className="event-buttons">
//                       <button className="b1" onClick={(e) => handleRemove(e)}>
//                         Remove
//                       </button>
//                       <button className="b2" type="submit">
//                         Add
//                       </button>
//                     </div>
//                   </form>
//                 </div>
//                 <div className="event-card">
//                   <h2>Your Event 4 : </h2>
//                   <form onSubmit={handleUpdate}>
//                     <div className="row">
//                       <div className="col-lg-5">
//                         <div className="event-item">
//                           <label htmlFor="">Add Date : </label>                         
//                           <select
//                           className="form-control"
//                   name="januaryEvent4Date"
//                   id="januaryEvent4Date"
//                   value={formData.januaryEvent4Date}
//                   onChange={handleChange}
//                 >
//                   <option value="">Select Date</option>
//                   {renderDateOptions(0)}
//                 </select>
//                         </div>
//                       </div>
//                       <div className="col-lg-5">
//                         <div className="event-item">
//                           <label htmlFor="">Select Color : </label>
//                           <input
//                             type="color"
//                             name="januaryEvent4Color"
//                             id="januaryEvent4Color"
//                             value={formData.januaryEvent4Color}
//                             onChange={handleChange}
//                           />
//                         </div>
//                       </div>
//                       <div className="col-lg-12">
//                         <div className="event-item">
//                           <label htmlFor="">Caption : </label>
//                           <input
//                             type="text"
//                             placeholder="Enter Caption (max 20 characters)"
//                             name="januaryEvent4Caption"
//                             id="januaryEvent4Caption"
//                             value={formData.januaryEvent4Caption}
//                             onChange={handleChange}
//                             maxLength={20}
//                           />
//                         </div>
//                       </div>
//                     </div>
//                     <div className="event-buttons">
//                       <button className="b1" onClick={(e) => handleRemove(e)}>
//                         Remove
//                       </button>
//                       <button className="b2" type="submit">
//                         Add
//                       </button>
//                     </div>
//                   </form>
//                 </div>
//               </div>
//               <div
//                 id="feb"
//                 className={`events ${activeTab === "feb" ? "active" : ""}`}
//               >
//                 <h4>February</h4>
//                 <div className="event-card">
//                   <h2>Your Event 1 : </h2>
//                   <form onSubmit={handleUpdate}>
//                     <div className="row">
//                       <div className="col-lg-5">
//                         <div className="event-item">
//                           <label htmlFor="">Add Date : </label>
//                            <select
//                            className="form-control"
//                   name="februaryEvent1Date"
//                   id="februaryEvent1Date"
//                   value={formData.februaryEvent1Date}
//                   onChange={handleChange}
//                 >
//                   <option value="">Select Date</option>
//                   {renderDateOptions(1)}
//                 </select>
//                         </div>
//                       </div>
//                       <div className="col-lg-5">
//                         <div className="event-item">
//                           <label htmlFor="">Select Color : </label>
//                           <input
//                             type="color"
//                             name="februaryEvent1Color"
//                             id="februaryEvent1Color"
//                             value={formData.februaryEvent1Color}
//                             onChange={handleChange}
//                           />
//                         </div>
//                       </div>
//                       <div className="col-lg-12">
//                         <div className="event-item">
//                           <label htmlFor="">Caption : </label>
//                           <input
//                             type="text"
//                             placeholder="Enter Caption (max 20 characters)"
//                             name="februaryEvent1Caption"
//                             id="februaryEvent1Caption"
//                             value={formData.februaryEvent1Caption}
//                             onChange={handleChange}
//                             maxLength={20}
//                           />
//                         </div>
//                       </div>
//                     </div>
//                     <div className="event-buttons">
//                       <button className="b1" onClick={(e) => handleRemove(e)}>
//                         Remove
//                       </button>
//                       <button className="b2" type="submit">
//                         Add
//                       </button>
//                     </div>
//                   </form>
//                 </div>
//                 <div className="event-card">
//                   <h2>Your Event 2 : </h2>
//                   <form onSubmit={handleUpdate}>
//                     <div className="row">
//                       <div className="col-lg-5">
//                         <div className="event-item">
//                           <label htmlFor="">Add Date : </label>
//                           <select 
//                           className="form-control"
//                   name="februaryEvent2Date"
//                   id="februaryEvent2Date"
//                   value={formData.februaryEvent2Date}
//                   onChange={handleChange}
//                 >
//                   <option value="">Select Date</option>
//                   {renderDateOptions(1)}
//                 </select>
//                         </div>
//                       </div>
//                       <div className="col-lg-5">
//                         <div className="event-item">
//                           <label htmlFor="">Select Color : </label>
//                           <input
//                             type="color"
//                             name="februaryEvent2Color"
//                             id="februaryEvent2Color"
//                             value={formData.februaryEvent2Color}
//                             onChange={handleChange}
//                           />
//                         </div>
//                       </div>
//                       <div className="col-lg-12">
//                         <div className="event-item">
//                           <label htmlFor="">Caption : </label>
//                           <input
//                             type="text"
//                             placeholder="Enter Caption (max 20 characters)"
//                             name="februaryEvent2Caption"
//                             id="februaryEvent2Caption"
//                             value={formData.februaryEvent2Caption}
//                             onChange={handleChange}
//                             maxLength={20}
//                           />
//                         </div>
//                       </div>
//                     </div>
//                     <div className="event-buttons">
//                       <button className="b1" onClick={(e) => handleRemove(e)}>
//                         Remove
//                       </button>
//                       <button className="b2" type="submit">
//                         Add
//                       </button>
//                     </div>
//                   </form>
//                 </div>
//                 <div className="event-card">
//                   <h2>Your Event 3 : </h2>
//                   <form onSubmit={handleUpdate}>
//                     <div className="row">
//                       <div className="col-lg-5">
//                         <div className="event-item">
//                           <label htmlFor="">Add Date : </label>
//                           <select
//                           className="form-control"
//                   name="februaryEvent3Date"
//                   id="februaryEvent3Date"
//                   value={formData.februaryEvent3Date}
//                   onChange={handleChange}
//                 >
//                   <option value="">Select Date</option>
//                   {renderDateOptions(1)}
//                 </select>
//                         </div>
//                       </div>
//                       <div className="col-lg-5">
//                         <div className="event-item">
//                           <label htmlFor="">Select Color : </label>
//                           <input
//                             type="color"
//                             name="februaryEvent3Color"
//                             id="februaryEvent3Color"
//                             value={formData.februaryEvent3Color}
//                             onChange={handleChange}
//                           />
//                         </div>
//                       </div>
//                       <div className="col-lg-12">
//                         <div className="event-item">
//                           <label htmlFor="">Caption : </label>
//                           <input
//                             type="text"
//                             placeholder="Enter Caption (max 20 characters)"
//                             name="februaryEvent3Caption"
//                             id="februaryEvent3Caption"
//                             value={formData.februaryEvent3Caption}
//                             onChange={handleChange}
//                             maxLength={20}
//                           />
//                         </div>
//                       </div>
//                     </div>
//                     <div className="event-buttons">
//                       <button className="b1" onClick={(e) => handleRemove(e)}>
//                         Remove
//                       </button>
//                       <button className="b2" type="submit">
//                         Add
//                       </button>
//                     </div>
//                   </form>
//                 </div>
//                 <div className="event-card">
//                   <h2>Your Event 4 : </h2>
//                   <form onSubmit={handleUpdate}>
//                     <div className="row">
//                       <div className="col-lg-5">
//                         <div className="event-item">
//                           <label htmlFor="">Add Date : </label>
//                           <select
//                           className="form-control"
//                   name="februaryEvent4Date"
//                   id="februaryEvent4Date"
//                   value={formData.februaryEvent4Date}
//                   onChange={handleChange}
//                 >
//                   <option value="">Select Date</option>
//                   {renderDateOptions(1)}
//                 </select>
//                         </div>
//                       </div>
//                       <div className="col-lg-5">
//                         <div className="event-item">
//                           <label htmlFor="">Select Color : </label>
//                           <input
//                             type="color"
//                             name="februaryEvent4Color"
//                             id="februaryEvent4Color"
//                             value={formData.februaryEvent4Color}
//                             onChange={handleChange}
//                           />
//                         </div>
//                       </div>
//                       <div className="col-lg-12">
//                         <div className="event-item">
//                           <label htmlFor="">Caption : </label>
//                           <input
//                             type="text"
//                             placeholder="Enter Caption (max 20 characters)"
//                             name="februaryEvent4Caption"
//                             id="februaryEvent4Caption"
//                             value={formData.februaryEvent4Caption}
//                             onChange={handleChange}
//                             maxLength={20}
//                           />
//                         </div>
//                       </div>
//                     </div>
//                     <div className="event-buttons">
//                       <button className="b1" onClick={(e) => handleRemove(e)}>
//                         Remove
//                       </button>
//                       <button className="b2" type="submit">
//                         Add
//                       </button>
//                     </div>
//                   </form>
//                 </div>
//               </div>
//               <div
//                 id="march"
//                 className={`events ${activeTab === "march" ? "active" : ""}`}
//               >
//                 <h4>March</h4>
//                 <div className="event-card">
//                   <h2>Your Event 1 : </h2>
//                   <form onSubmit={handleUpdate}>
//                     <div className="row">
//                       <div className="col-lg-5">
//                         <div className="event-item">
//                           <label htmlFor="">Add Date : </label>
//                           <select
//                           className="form-control"
//                   name="marchEvent1Date"
//                   id="marchEvent1Date"
//                   value={formData.marchEvent1Date}
//                   onChange={handleChange}
//                 >
//                   <option value="">Select Date</option>
//                   {renderDateOptions(2)}
//                 </select>
//                         </div>
//                       </div>
//                       <div className="col-lg-5">
//                         <div className="event-item">
//                           <label htmlFor="">Select Color : </label>
//                           <input
//                             type="color"
//                             name="marchEvent1Color"
//                             id="marchEvent1Color"
//                             value={formData.marchEvent1Color}
//                             onChange={handleChange}
//                           />
//                         </div>
//                       </div>
//                       <div className="col-lg-12">
//                         <div className="event-item">
//                           <label htmlFor="">Caption : </label>
//                           <input
//                             type="text"
//                             placeholder="Enter Caption (max 20 characters)"
//                             name="marchEvent1Caption"
//                             id="marchEvent1Caption"
//                             value={formData.marchEvent1Caption}
//                             onChange={handleChange}
//                             maxLength={20}
//                           />
//                         </div>
//                       </div>
//                     </div>
//                     <div className="event-buttons">
//                       <button className="b1" onClick={(e) => handleRemove(e)}>
//                         Remove
//                       </button>
//                       <button className="b2" type="submit">
//                         Add
//                       </button>
//                     </div>
//                   </form>
//                 </div>
//                 <div className="event-card">
//                   <h2>Your Event 2 : </h2>
//                   <form onSubmit={handleUpdate}>
//                     <div className="row">
//                       <div className="col-lg-5">
//                         <div className="event-item">
//                           <label htmlFor="">Add Date : </label>
//                           <select
//                           className="form-control"
//                   name="marchEvent2Date"
//                   id="marchEvent2Date"
//                   value={formData.marchEvent2Date}
//                   onChange={handleChange}
//                 >
//                   <option value="">Select Date</option>
//                   {renderDateOptions(2)}
//                 </select>
//                         </div>
//                       </div>
//                       <div className="col-lg-5">
//                         <div className="event-item">
//                           <label htmlFor="">Select Color : </label>
//                           <input
//                             type="color"
//                             name="marchEvent2Color"
//                             id="marchEvent2Color"
//                             value={formData.marchEvent2Color}
//                             onChange={handleChange}
//                           />
//                         </div>
//                       </div>
//                       <div className="col-lg-12">
//                         <div className="event-item">
//                           <label htmlFor="">Caption : </label>
//                           <input
//                             type="text"
//                             placeholder="Enter Caption (max 20 characters)"
//                             name="marchEvent2Caption"
//                             id="marchEvent2Caption"
//                             value={formData.marchEvent2Caption}
//                             onChange={handleChange}
//                             maxLength={20}
//                           />
//                         </div>
//                       </div>
//                     </div>
//                     <div className="event-buttons">
//                       <button className="b1" onClick={(e) => handleRemove(e)}>
//                         Remove
//                       </button>
//                       <button className="b2" type="submit">
//                         Add
//                       </button>
//                     </div>
//                   </form>
//                 </div>
//                 <div className="event-card">
//                   <h2>Your Event 3 : </h2>
//                   <form onSubmit={handleUpdate}>
//                     <div className="row">
//                       <div className="col-lg-5">
//                         <div className="event-item">
//                           <label htmlFor="">Add Date : </label>
//                           <select
//                           className="form-control"
//                   name="marchEvent3Date"
//                   id="marchEvent3Date"
//                   value={formData.marchEvent3Date}
//                   onChange={handleChange}
//                 >
//                   <option value="">Select Date</option>
//                   {renderDateOptions(2)}
//                 </select>
//                         </div>
//                       </div>
//                       <div className="col-lg-5">
//                         <div className="event-item">
//                           <label htmlFor="">Select Color : </label>
//                           <input
//                             type="color"
//                             name="marchEvent3Color"
//                             id="marchEvent3Color"
//                             value={formData.marchEvent3Color}
//                             onChange={handleChange}
//                           />
//                         </div>
//                       </div>
//                       <div className="col-lg-12">
//                         <div className="event-item">
//                           <label htmlFor="">Caption : </label>
//                           <input
//                             type="text"
//                             placeholder="Enter Caption (max 20 characters)"
//                             name="marchEvent3Caption"
//                             id="marchEvent3Caption"
//                             value={formData.marchEvent3Caption}
//                             onChange={handleChange}
//                             maxLength={20}
//                           />
//                         </div>
//                       </div>
//                     </div>
//                     <div className="event-buttons">
//                       <button className="b1" onClick={(e) => handleRemove(e)}>
//                         Remove
//                       </button>
//                       <button className="b2" type="submit">
//                         Add
//                       </button>
//                     </div>
//                   </form>
//                 </div>
//                 <div className="event-card">
//                   <h2>Your Event 4 : </h2>
//                   <form onSubmit={handleUpdate}>
//                     <div className="row">
//                       <div className="col-lg-5">
//                         <div className="event-item">
//                           <label htmlFor="">Add Date : </label>
//                           <select
//                           className="form-control"
//                   name="marchEvent4Date"
//                   id="marchEvent4Date"
//                   value={formData.marchEvent4Date}
//                   onChange={handleChange}
//                 >
//                   <option value="">Select Date</option>
//                   {renderDateOptions(2)}
//                 </select>
//                         </div>
//                       </div>
//                       <div className="col-lg-5">
//                         <div className="event-item">
//                           <label htmlFor="">Select Color : </label>
//                           <input
//                             type="color"
//                             name="marchEvent4Color"
//                             id="marchEvent4Color"
//                             value={formData.marchEvent4Color}
//                             onChange={handleChange}
//                           />
//                         </div>
//                       </div>
//                       <div className="col-lg-12">
//                         <div className="event-item">
//                           <label htmlFor="">Caption : </label>
//                           <input
//                             type="text"
//                             placeholder="Enter Caption (max 20 characters)"
//                             name="marchEvent4Caption"
//                             id="marchEvent4Caption"
//                             value={formData.marchEvent4Caption}
//                             onChange={handleChange}
//                             maxLength={20}
//                           />
//                         </div>
//                       </div>
//                     </div>
//                     <div className="event-buttons">
//                       <button className="b1" onClick={(e) => handleRemove(e)}>
//                         Remove
//                       </button>
//                       <button className="b2" type="submit">
//                         Add
//                       </button>
//                     </div>
//                   </form>
//                 </div>
//               </div>
//               <div
//                 id="april"
//                 className={`events ${activeTab === "april" ? "active" : ""}`}
//               >
//                 <h4>April</h4>
//                 <div className="event-card">
//                   <h2>Your Event 1 : </h2>
//                   <form onSubmit={handleUpdate}>
//                     <div className="row">
//                       <div className="col-lg-5">
//                         <div className="event-item">
//                           <label htmlFor="">Add Date : </label>
//                           <select
//                           className="form-control"
//                   name="aprilEvent1Date"
//                   id="aprilEvent1Date"
//                   value={formData.aprilEvent1Date}
//                   onChange={handleChange}
//                 >
//                   <option value="">Select Date</option>
//                   {renderDateOptions(3)}
//                 </select>
//                         </div>
//                       </div>
//                       <div className="col-lg-5">
//                         <div className="event-item">
//                           <label htmlFor="">Select Color : </label>
//                           <input
//                             type="color"
//                             name="aprilEvent1Color"
//                             id="aprilEvent1Color"
//                             value={formData.aprilEvent1Color}
//                             onChange={handleChange}
//                           />
//                         </div>
//                       </div>
//                       <div className="col-lg-12">
//                         <div className="event-item">
//                           <label htmlFor="">Caption : </label>
//                           <input
//                             type="text"
//                             placeholder="Enter Caption (max 20 characters)"
//                             name="aprilEvent1Caption"
//                             id="aprilEvent1Caption"
//                             value={formData.aprilEvent1Caption}
//                             onChange={handleChange}
//                             maxLength={20}
//                           />
//                         </div>
//                       </div>
//                     </div>
//                     <div className="event-buttons">
//                       <button className="b1" onClick={(e) => handleRemove(e)}>
//                         Remove
//                       </button>
//                       <button className="b2" type="submit">
//                         Add
//                       </button>
//                     </div>
//                   </form>
//                 </div>
//                 <div className="event-card">
//                   <h2>Your Event 2 : </h2>
//                   <form onSubmit={handleUpdate}>
//                     <div className="row">
//                       <div className="col-lg-5">
//                         <div className="event-item">
//                           <label htmlFor="">Add Date : </label>
//                           <select
//                           className="form-control"
//                   name="aprilEvent2Date"
//                   id="aprilEvent2Date"
//                   value={formData.aprilEvent2Date}
//                   onChange={handleChange}
//                 >
//                   <option value="">Select Date</option>
//                   {renderDateOptions(3)}
//                 </select>
//                         </div>
//                       </div>
//                       <div className="col-lg-5">
//                         <div className="event-item">
//                           <label htmlFor="">Select Color : </label>
//                           <input
//                             type="color"
//                             name="aprilEvent2Color"
//                             id="aprilEvent2Color"
//                             value={formData.aprilEvent2Color}
//                             onChange={handleChange}
//                           />
//                         </div>
//                       </div>
//                       <div className="col-lg-12">
//                         <div className="event-item">
//                           <label htmlFor="">Caption : </label>
//                           <input
//                             type="text"
//                             placeholder="Enter Caption (max 20 characters)"
//                             name="aprilEvent2Caption"
//                             id="aprilEvent2Caption"
//                             value={formData.aprilEvent2Caption}
//                             onChange={handleChange}
//                             maxLength={20}
//                           />
//                         </div>
//                       </div>
//                     </div>
//                     <div className="event-buttons">
//                       <button className="b1" onClick={(e) => handleRemove(e)}>
//                         Remove
//                       </button>
//                       <button className="b2" type="submit">
//                         Add
//                       </button>
//                     </div>
//                   </form>
//                 </div>
//                 <div className="event-card">
//                   <h2>Your Event 3 : </h2>
//                   <form onSubmit={handleUpdate}>
//                     <div className="row">
//                       <div className="col-lg-5">
//                         <div className="event-item">
//                           <label htmlFor="">Add Date : </label>
//                           <select
//                           className="form-control"
//                   name="aprilEvent3Date"
//                   id="aprilEvent3Date"
//                   value={formData.aprilEvent3Date}
//                   onChange={handleChange}
//                 >
//                   <option value="">Select Date</option>
//                   {renderDateOptions(3)}
//                 </select>
//                         </div>
//                       </div>
//                       <div className="col-lg-5">
//                         <div className="event-item">
//                           <label htmlFor="">Select Color : </label>
//                           <input
//                             type="color"
//                             name="aprilEvent3Color"
//                             id="aprilEvent3Color"
//                             value={formData.aprilEvent3Color}
//                             onChange={handleChange}
//                           />
//                         </div>
//                       </div>
//                       <div className="col-lg-12">
//                         <div className="event-item">
//                           <label htmlFor="">Caption : </label>
//                           <input
//                             type="text"
//                             placeholder="Enter Caption (max 20 characters)"
//                             name="aprilEvent3Caption"
//                             id="aprilEvent3Caption"
//                             value={formData.aprilEvent3Caption}
//                             onChange={handleChange}
//                             maxLength={20}
//                           />
//                         </div>
//                       </div>
//                     </div>
//                     <div className="event-buttons">
//                       <button className="b1" onClick={(e) => handleRemove(e)}>
//                         Remove
//                       </button>
//                       <button className="b2" type="submit">
//                         Add
//                       </button>
//                     </div>
//                   </form>
//                 </div>
//                 <div className="event-card">
//                   <h2>Your Event 4 : </h2>
//                   <form onSubmit={handleUpdate}>
//                     <div className="row">
//                       <div className="col-lg-5">
//                         <div className="event-item">
//                           <label htmlFor="">Add Date : </label>
//                           <select
//                           className="form-control"
//                   name="aprilEvent4Date"
//                   id="aprilEvent4Date"
//                   value={formData.aprilEvent4Date}
//                   onChange={handleChange}
//                 >
//                   <option value="">Select Date</option>
//                   {renderDateOptions(3)}
//                 </select>
//                         </div>
//                       </div>
//                       <div className="col-lg-5">
//                         <div className="event-item">
//                           <label htmlFor="">Select Color : </label>
//                           <input
//                             type="color"
//                             name="aprilEvent4Color"
//                             id="aprilEvent4Color"
//                             value={formData.aprilEvent4Color}
//                             onChange={handleChange}
//                           />
//                         </div>
//                       </div>
//                       <div className="col-lg-12">
//                         <div className="event-item">
//                           <label htmlFor="">Caption : </label>
//                           <input
//                             type="text"
//                             placeholder="Enter Caption (max 20 characters)"
//                             name="aprilEvent4Caption"
//                             id="aprilEvent4Caption"
//                             value={formData.aprilEvent4Caption}
//                             onChange={handleChange}
//                             maxLength={20}
//                           />
//                         </div>
//                       </div>
//                     </div>
//                     <div className="event-buttons">
//                       <button className="b1" onClick={(e) => handleRemove(e)}>
//                         Remove
//                       </button>
//                       <button className="b2" type="submit">
//                         Add
//                       </button>
//                     </div>
//                   </form>
//                 </div>
//               </div>
//               <div
//                 id="may"
//                 className={`events ${activeTab === "may" ? "active" : ""}`}
//               >
//                 <h4>May</h4>
//                 <div className="event-card">
//                   <h2>Your Event 1 : </h2>
//                   <form onSubmit={handleUpdate}>
//                     <div className="row">
//                       <div className="col-lg-5">
//                         <div className="event-item">
//                           <label htmlFor="">Add Date : </label>
//                            <select
//                            className="form-control"
//                   name="mayEvent1Date"
//                   id="mayEvent1Date"
//                   value={formData.mayEvent1Date}
//                   onChange={handleChange}
//                 >
//                   <option value="">Select Date</option>
//                   {renderDateOptions(4)}
//                 </select>
//                         </div>
//                       </div>
//                       <div className="col-lg-5">
//                         <div className="event-item">
//                           <label htmlFor="">Select Color : </label>
//                           <input
//                             type="color"
//                             name="mayEvent1Color"
//                             id="mayEvent1Color"
//                             value={formData.mayEvent1Color}
//                             onChange={handleChange}
//                           />
//                         </div>
//                       </div>
//                       <div className="col-lg-12">
//                         <div className="event-item">
//                           <label htmlFor="">Caption : </label>
//                           <input
//                             type="text"
//                             placeholder="Enter Caption (max 20 characters)"
//                             name="mayEvent1Caption"
//                             id="mayEvent1Caption"
//                             value={formData.mayEvent1Caption}
//                             onChange={handleChange}
//                             maxLength={20}
//                           />
//                         </div>
//                       </div>
//                     </div>
//                     <div className="event-buttons">
//                       <button className="b1" onClick={(e) => handleRemove(e)}>
//                         Remove
//                       </button>
//                       <button className="b2" type="submit">
//                         Add
//                       </button>
//                     </div>
//                   </form>
//                 </div>
//                 <div className="event-card">
//                   <h2>Your Event 2 : </h2>
//                   <form onSubmit={handleUpdate}>
//                     <div className="row">
//                       <div className="col-lg-5">
//                         <div className="event-item">
//                           <label htmlFor="">Add Date : </label>
//                           <select
//                           className="form-control"
//                   name="mayEvent2Date"
//                   id="mayEvent2Date"
//                   value={formData.mayEvent2Date}
//                   onChange={handleChange}
//                 >
//                   <option value="">Select Date</option>
//                   {renderDateOptions(4)}
//                 </select>
//                         </div>
//                       </div>
//                       <div className="col-lg-5">
//                         <div className="event-item">
//                           <label htmlFor="">Select Color : </label>
//                           <input
//                             type="color"
//                             name="mayEvent2Color"
//                             id="mayEvent2Color"
//                             value={formData.mayEvent2Color}
//                             onChange={handleChange}
//                           />
//                         </div>
//                       </div>
//                       <div className="col-lg-12">
//                         <div className="event-item">
//                           <label htmlFor="">Caption : </label>
//                           <input
//                             type="text"
//                             placeholder="Enter Caption (max 20 characters)"
//                             name="mayEvent2Caption"
//                             id="mayEvent2Caption"
//                             value={formData.mayEvent2Caption}
//                             onChange={handleChange}
//                             maxLength={20}
//                           />
//                         </div>
//                       </div>
//                     </div>
//                     <div className="event-buttons">
//                       <button className="b1" onClick={(e) => handleRemove(e)}>
//                         Remove
//                       </button>
//                       <button className="b2" type="submit">
//                         Add
//                       </button>
//                     </div>
//                   </form>
//                 </div>
//                 <div className="event-card">
//                   <h2>Your Event 3 : </h2>
//                   <form onSubmit={handleUpdate}>
//                     <div className="row">
//                       <div className="col-lg-5">
//                         <div className="event-item">
//                           <label htmlFor="">Add Date : </label>
//                           <select
//                           className="form-control"
//                   name="mayEvent3Date"
//                   id="mayEvent3Date"
//                   value={formData.mayEvent3Date}
//                   onChange={handleChange}
//                 >
//                   <option value="">Select Date</option>
//                   {renderDateOptions(4)}
//                 </select>
//                         </div>
//                       </div>
//                       <div className="col-lg-5">
//                         <div className="event-item">
//                           <label htmlFor="">Select Color : </label>
//                           <input
//                             type="color"
//                             name="mayEvent3Color"
//                             id="mayEvent3Color"
//                             value={formData.mayEvent3Color}
//                             onChange={handleChange}
//                           />
//                         </div>
//                       </div>
//                       <div className="col-lg-12">
//                         <div className="event-item">
//                           <label htmlFor="">Caption : </label>
//                           <input
//                             type="text"
//                             placeholder="Enter Caption (max 20 characters)"
//                             name="mayEvent3Caption"
//                             id="mayEvent3Caption"
//                             value={formData.mayEvent3Caption}
//                             onChange={handleChange}
//                             maxLength={20}
//                           />
//                         </div>
//                       </div>
//                     </div>
//                     <div className="event-buttons">
//                       <button className="b1" onClick={(e) => handleRemove(e)}>
//                         Remove
//                       </button>
//                       <button className="b2" type="submit">
//                         Add
//                       </button>
//                     </div>
//                   </form>
//                 </div>
//                 <div className="event-card">
//                   <h2>Your Event 4 : </h2>
//                   <form onSubmit={handleUpdate}>
//                     <div className="row">
//                       <div className="col-lg-5">
//                         <div className="event-item">
//                           <label htmlFor="">Add Date : </label>
//                           <select
//                           className="form-control"
//                   name="mayEvent4Date"
//                   id="mayEvent4Date"
//                   value={formData.mayEvent4Date}
//                   onChange={handleChange}
//                 >
//                   <option value="">Select Date</option>
//                   {renderDateOptions(4)}
//                 </select>
//                         </div>
//                       </div>
//                       <div className="col-lg-5">
//                         <div className="event-item">
//                           <label htmlFor="">Select Color : </label>
//                           <input
//                             type="color"
//                             name="mayEvent4Color"
//                             id="mayEvent4Color"
//                             value={formData.mayEvent4Color}
//                             onChange={handleChange}
//                           />
//                         </div>
//                       </div>
//                       <div className="col-lg-12">
//                         <div className="event-item">
//                           <label htmlFor="">Caption : </label>
//                           <input
//                             type="text"
//                             placeholder="Enter Caption (max 20 characters)"
//                             name="mayEvent4Caption"
//                             id="mayEvent4Caption"
//                             value={formData.mayEvent4Caption}
//                             onChange={handleChange}
//                             maxLength={20}
//                           />
//                         </div>
//                       </div>
//                     </div>
//                     <div className="event-buttons">
//                       <button className="b1" onClick={(e) => handleRemove(e)}>
//                         Remove
//                       </button>
//                       <button className="b2" type="submit">
//                         Add
//                       </button>
//                     </div>
//                   </form>
//                 </div>
//               </div>
//               <div
//                 id="june"
//                 className={`events ${activeTab === "june" ? "active" : ""}`}
//               >
//                 <h4>June</h4>
//                 <div className="event-card">
//                   <h2>Your Event 1 : </h2>
//                   <form onSubmit={handleUpdate}>
//                     <div className="row">
//                       <div className="col-lg-5">
//                         <div className="event-item">
//                           <label htmlFor="">Add Date : </label>
//                           <select
//                           className="form-control"
//                   name="juneEvent1Date"
//                   id="juneEvent1Date"
//                   value={formData.juneEvent1Date}
//                   onChange={handleChange}
//                 >
//                   <option value="">Select Date</option>
//                   {renderDateOptions(5)}
//                 </select>
//                         </div>
//                       </div>
//                       <div className="col-lg-5">
//                         <div className="event-item">
//                           <label htmlFor="">Select Color : </label>
//                           <input
//                             type="color"
//                             name="juneEvent1Color"
//                             id="juneEvent1Color"
//                             value={formData.juneEvent1Color}
//                             onChange={handleChange}
//                           />
//                         </div>
//                       </div>
//                       <div className="col-lg-12">
//                         <div className="event-item">
//                           <label htmlFor="">Caption : </label>
//                           <input
//                             type="text"
//                             placeholder="Enter Caption (max 20 characters)"
//                             name="juneEvent1Caption"
//                             id="juneEvent1Caption"
//                             value={formData.juneEvent1Caption}
//                             onChange={handleChange}
//                             maxLength={20}
//                           />
//                         </div>
//                       </div>
//                     </div>
//                     <div className="event-buttons">
//                       <button className="b1" onClick={(e) => handleRemove(e)}>
//                         Remove
//                       </button>
//                       <button className="b2" type="submit">
//                         Add
//                       </button>
//                     </div>
//                   </form>
//                 </div>
//                 <div className="event-card">
//                   <h2>Your Event 2 : </h2>
//                   <form onSubmit={handleUpdate}>
//                     <div className="row">
//                       <div className="col-lg-5">
//                         <div className="event-item">
//                           <label htmlFor="">Add Date : </label>
//                           <select
//                           className="form-control"
//                   name="juneEvent2Date"
//                   id="juneEvent2Date"
//                   value={formData.juneEvent2Date}
//                   onChange={handleChange}
//                 >
//                   <option value="">Select Date</option>
//                   {renderDateOptions(5)}
//                 </select>
//                         </div>
//                       </div>
//                       <div className="col-lg-5">
//                         <div className="event-item">
//                           <label htmlFor="">Select Color : </label>
//                           <input
//                             type="color"
//                             name="juneEvent2Color"
//                             id="juneEvent2Color"
//                             value={formData.juneEvent2Color}
//                             onChange={handleChange}
//                           />
//                         </div>
//                       </div>
//                       <div className="col-lg-12">
//                         <div className="event-item">
//                           <label htmlFor="">Caption : </label>
//                           <input
//                             type="text"
//                             placeholder="Enter Caption (max 20 characters)"
//                             name="juneEvent2Caption"
//                             id="juneEvent2Caption"
//                             value={formData.juneEvent2Caption}
//                             onChange={handleChange}
//                             maxLength={20}
//                           />
//                         </div>
//                       </div>
//                     </div>
//                     <div className="event-buttons">
//                       <button className="b1" onClick={(e) => handleRemove(e)}>
//                         Remove
//                       </button>
//                       <button className="b2" type="submit">
//                         Add
//                       </button>
//                     </div>
//                   </form>
//                 </div>
//                 <div className="event-card">
//                   <h2>Your Event 3 : </h2>
//                   <form onSubmit={handleUpdate}>
//                     <div className="row">
//                       <div className="col-lg-5">
//                         <div className="event-item">
//                           <label htmlFor="">Add Date : </label>
//                           <select
//                           className="form-control"
//                   name="juneEvent3Date"
//                   id="juneEvent3Date"
//                   value={formData.juneEvent3Date}
//                   onChange={handleChange}
//                 >
//                   <option value="">Select Date</option>
//                   {renderDateOptions(5)}
//                 </select>
//                         </div>
//                       </div>
//                       <div className="col-lg-5">
//                         <div className="event-item">
//                           <label htmlFor="">Select Color : </label>
//                           <input
//                             type="color"
//                             name="juneEvent3Color"
//                             id="juneEvent3Color"
//                             value={formData.juneEvent3Color}
//                             onChange={handleChange}
//                           />
//                         </div>
//                       </div>
//                       <div className="col-lg-12">
//                         <div className="event-item">
//                           <label htmlFor="">Caption : </label>
//                           <input
//                             type="text"
//                             placeholder="Enter Caption (max 20 characters)"
//                             name="juneEvent3Caption"
//                             id="juneEvent3Caption"
//                             value={formData.juneEvent3Caption}
//                             onChange={handleChange}
//                             maxLength={20}
//                           />
//                         </div>
//                       </div>
//                     </div>
//                     <div className="event-buttons">
//                       <button className="b1" onClick={(e) => handleRemove(e)}>
//                         Remove
//                       </button>
//                       <button className="b2" type="submit">
//                         Add
//                       </button>
//                     </div>
//                   </form>
//                 </div>
//                 <div className="event-card">
//                   <h2>Your Event 4 : </h2>
//                   <form onSubmit={handleUpdate}>
//                     <div className="row">
//                       <div className="col-lg-5">
//                         <div className="event-item">
//                           <label htmlFor="">Add Date : </label>
//                           <select
//                           className="form-control"
//                   name="juneEvent4Date"
//                   id="juneEvent4Date"
//                   value={formData.juneEvent4Date}
//                   onChange={handleChange}
//                 >
//                   <option value="">Select Date</option>
//                   {renderDateOptions(5)}
//                 </select>
//                         </div>
//                       </div>
//                       <div className="col-lg-5">
//                         <div className="event-item">
//                           <label htmlFor="">Select Color : </label>
//                           <input
//                             type="color"
//                             name="juneEvent4Color"
//                             id="juneEvent4Color"
//                             value={formData.juneEvent4Color}
//                             onChange={handleChange}
//                           />
//                         </div>
//                       </div>
//                       <div className="col-lg-12">
//                         <div className="event-item">
//                           <label htmlFor="">Caption : </label>
//                           <input
//                             type="text"
//                             placeholder="Enter Caption (max 20 characters)"
//                             name="juneEvent4Caption"
//                             id="juneEvent4Caption"
//                             value={formData.juneEvent4Caption}
//                             onChange={handleChange}
//                             maxLength={20}
//                           />
//                         </div>
//                       </div>
//                     </div>
//                     <div className="event-buttons">
//                       <button className="b1" onClick={(e) => handleRemove(e)}>
//                         Remove
//                       </button>
//                       <button className="b2" type="submit">
//                         Add
//                       </button>
//                     </div>
//                   </form>
//                 </div>
//               </div>
//               <div
//                 id="july"
//                 className={`events ${activeTab === "july" ? "active" : ""}`}
//               >
//                 <h4>July</h4>
//                 <div className="event-card">
//                   <h2>Your Event 1 : </h2>
//                   <form onSubmit={handleUpdate}>
//                     <div className="row">
//                       <div className="col-lg-5">
//                         <div className="event-item">
//                           <label htmlFor="">Add Date : </label>
//                           <select
//                           className="form-control"
//                   name="julyEvent1Date"
//                   id="julyEvent1Date"
//                   value={formData.julyEvent1Date}
//                   onChange={handleChange}
//                 >
//                   <option value="">Select Date</option>
//                   {renderDateOptions(6)}
//                 </select>
//                         </div>
//                       </div>
//                       <div className="col-lg-5">
//                         <div className="event-item">
//                           <label htmlFor="">Select Color : </label>
//                           <input
//                             type="color"
//                             name="julyEvent1Color"
//                             id="julyEvent1Color"
//                             value={formData.julyEvent1Color}
//                             onChange={handleChange}
//                           />
//                         </div>
//                       </div>
//                       <div className="col-lg-12">
//                         <div className="event-item">
//                           <label htmlFor="">Caption : </label>
//                           <input
//                             type="text"
//                             placeholder="Enter Caption (max 20 characters)"
//                             name="julyEvent1Caption"
//                             id="julyEvent1Caption"
//                             value={formData.julyEvent1Caption}
//                             onChange={handleChange}
//                             maxLength={20}
//                           />
//                         </div>
//                       </div>
//                     </div>
//                     <div className="event-buttons">
//                       <button className="b1" onClick={(e) => handleRemove(e)}>
//                         Remove
//                       </button>
//                       <button className="b2" type="submit">
//                         Add
//                       </button>
//                     </div>
//                   </form>
//                 </div>
//                 <div className="event-card">
//                   <h2>Your Event 2 : </h2>
//                   <form onSubmit={handleUpdate}>
//                     <div className="row">
//                       <div className="col-lg-5">
//                         <div className="event-item">
//                           <label htmlFor="">Add Date : </label>
//                           <select
//                           className="form-control"
//                   name="julyEvent2Date"
//                   id="julyEvent2Date"
//                   value={formData.julyEvent2Date}
//                   onChange={handleChange}
//                 >
//                   <option value="">Select Date</option>
//                   {renderDateOptions(6)}
//                 </select>
//                         </div>
//                       </div>
//                       <div className="col-lg-5">
//                         <div className="event-item">
//                           <label htmlFor="">Select Color : </label>
//                           <input
//                             type="color"
//                             name="julyEvent2Color"
//                             id="julyEvent2Color"
//                             value={formData.julyEvent2Color}
//                             onChange={handleChange}
//                           />
//                         </div>
//                       </div>
//                       <div className="col-lg-12">
//                         <div className="event-item">
//                           <label htmlFor="">Caption : </label>
//                           <input
//                             type="text"
//                             placeholder="Enter Caption (max 20 characters)"
//                             name="julyEvent2Caption"
//                             id="julyEvent2Caption"
//                             value={formData.julyEvent2Caption}
//                             onChange={handleChange}
//                             maxLength={20}
//                           />
//                         </div>
//                       </div>
//                     </div>
//                     <div className="event-buttons">
//                       <button className="b1" onClick={(e) => handleRemove(e)}>
//                         Remove
//                       </button>
//                       <button className="b2" type="submit">
//                         Add
//                       </button>
//                     </div>
//                   </form>
//                 </div>
//                 <div className="event-card">
//                   <h2>Your Event 3 : </h2>
//                   <form onSubmit={handleUpdate}>
//                     <div className="row">
//                       <div className="col-lg-5">
//                         <div className="event-item">
//                           <label htmlFor="">Add Date : </label>
//                           <select
//                           className="form-control"
//                   name="julyEvent3Date"
//                   id="julyEvent3Date"
//                   value={formData.julyEvent3Date}
//                   onChange={handleChange}
//                 >
//                   <option value="">Select Date</option>
//                   {renderDateOptions(6)}
//                 </select>
//                         </div>
//                       </div>
//                       <div className="col-lg-5">
//                         <div className="event-item">
//                           <label htmlFor="">Select Color : </label>
//                           <input
//                             type="color"
//                             name="julyEvent3Color"
//                             id="julyEvent3Color"
//                             value={formData.julyEvent3Color}
//                             onChange={handleChange}
//                           />
//                         </div>
//                       </div>
//                       <div className="col-lg-12">
//                         <div className="event-item">
//                           <label htmlFor="">Caption : </label>
//                           <input
//                             type="text"
//                             placeholder="Enter Caption (max 20 characters)"
//                             name="julyEvent3Caption"
//                             id="julyEvent3Caption"
//                             value={formData.julyEvent3Caption}
//                             onChange={handleChange}
//                             maxLength={20}
//                           />
//                         </div>
//                       </div>
//                     </div>
//                     <div className="event-buttons">
//                       <button className="b1" onClick={(e) => handleRemove(e)}>
//                         Remove
//                       </button>
//                       <button className="b2" type="submit">
//                         Add
//                       </button>
//                     </div>
//                   </form>
//                 </div>
//                 <div className="event-card">
//                   <h2>Your Event 4 : </h2>
//                   <form onSubmit={handleUpdate}>
//                     <div className="row">
//                       <div className="col-lg-5">
//                         <div className="event-item">
//                           <label htmlFor="">Add Date : </label>
//                           <select
//                           className="form-control"
//                   name="julyEvent4Date"
//                   id="julyEvent4Date"
//                   value={formData.julyEvent4Date}
//                   onChange={handleChange}
//                 >
//                   <option value="">Select Date</option>
//                   {renderDateOptions(6)}
//                 </select>
//                         </div>
//                       </div>
//                       <div className="col-lg-5">
//                         <div className="event-item">
//                           <label htmlFor="">Select Color : </label>
//                           <input
//                             type="color"
//                             name="julyEvent4Color"
//                             id="julyEvent4Color"
//                             value={formData.julyEvent4Color}
//                             onChange={handleChange}
//                           />
//                         </div>
//                       </div>
//                       <div className="col-lg-12">
//                         <div className="event-item">
//                           <label htmlFor="">Caption : </label>
//                           <input
//                             type="text"
//                             placeholder="Enter Caption (max 20 characters)"
//                             name="julyEvent4Caption"
//                             id="julyEvent4Caption"
//                             value={formData.julyEvent4Caption}
//                             onChange={handleChange}
//                             maxLength={20}
//                           />
//                         </div>
//                       </div>
//                     </div>
//                     <div className="event-buttons">
//                       <button className="b1" onClick={(e) => handleRemove(e)}>
//                         Remove
//                       </button>
//                       <button className="b2" type="submit">
//                         Add
//                       </button>
//                     </div>
//                   </form>
//                 </div>
//               </div>
//               <div
//                 id="aug"
//                 className={`events ${activeTab === "aug" ? "active" : ""}`}
//               >
//                 <h4>August</h4>
//                 <div className="event-card">
//                   <h2>Your Event 1 : </h2>
//                   <form onSubmit={handleUpdate}>
//                     <div className="row">
//                       <div className="col-lg-5">
//                         <div className="event-item">
//                           <label htmlFor="">Add Date : </label>
//                           <select
//                           className="form-control"
//                   name="augustEvent1Date"
//                   id="augustEvent1Date"
//                   value={formData.augustEvent1Date}
//                   onChange={handleChange}
//                 >
//                   <option value="">Select Date</option>
//                   {renderDateOptions(7)}
//                 </select>
//                         </div>
//                       </div>
//                       <div className="col-lg-5">
//                         <div className="event-item">
//                           <label htmlFor="">Select Color : </label>
//                           <input
//                             type="color"
//                             name="augustEvent1Color"
//                             id="augustEvent1Color"
//                             value={formData.augustEvent1Color}
//                             onChange={handleChange}
//                           />
//                         </div>
//                       </div>
//                       <div className="col-lg-12">
//                         <div className="event-item">
//                           <label htmlFor="">Caption : </label>
//                           <input
//                             type="text"
//                             placeholder="Enter Caption (max 20 characters)"
//                             name="augustEvent1Caption"
//                             id="augustEvent1Caption"
//                             value={formData.augustEvent1Caption}
//                             onChange={handleChange}
//                             maxLength={20}
//                           />
//                         </div>
//                       </div>
//                     </div>
//                     <div className="event-buttons">
//                       <button className="b1" onClick={(e) => handleRemove(e)}>
//                         Remove
//                       </button>
//                       <button className="b2" type="submit">
//                         Add
//                       </button>
//                     </div>
//                   </form>
//                 </div>
//                 <div className="event-card">
//                   <h2>Your Event 2 : </h2>
//                   <form onSubmit={handleUpdate}>
//                     <div className="row">
//                       <div className="col-lg-5">
//                         <div className="event-item">
//                           <label htmlFor="">Add Date : </label>
//                           <select
//                           className="form-control"
//                   name="augustEvent2Date"
//                   id="augustEvent2Date"
//                   value={formData.augustEvent2Date}
//                   onChange={handleChange}
//                 >
//                   <option value="">Select Date</option>
//                   {renderDateOptions(7)}
//                 </select>
//                         </div>
//                       </div>
//                       <div className="col-lg-5">
//                         <div className="event-item">
//                           <label htmlFor="">Select Color : </label>
//                           <input
//                             type="color"
//                             name="augustEvent2Color"
//                             id="augustEvent2Color"
//                             value={formData.augustEvent2Color}
//                             onChange={handleChange}
//                           />
//                         </div>
//                       </div>
//                       <div className="col-lg-12">
//                         <div className="event-item">
//                           <label htmlFor="">Caption : </label>
//                           <input
//                             type="text"
//                             placeholder="Enter Caption (max 20 characters)"
//                             name="augustEvent2Caption"
//                             id="augustEvent2Caption"
//                             value={formData.augustEvent2Caption}
//                             onChange={handleChange}
//                             maxLength={20}
//                           />
//                         </div>
//                       </div>
//                     </div>
//                     <div className="event-buttons">
//                       <button className="b1" onClick={(e) => handleRemove(e)}>
//                         Remove
//                       </button>
//                       <button className="b2" type="submit">
//                         Add
//                       </button>
//                     </div>
//                   </form>
//                 </div>
//                 <div className="event-card">
//                   <h2>Your Event 3 : </h2>
//                   <form onSubmit={handleUpdate}>
//                     <div className="row">
//                       <div className="col-lg-5">
//                         <div className="event-item">
//                           <label htmlFor="">Add Date : </label>
//                           <select
//                           className="form-control"
//                   name="augustEvent3Date"
//                   id="augustEvent3Date"
//                   value={formData.augustEvent3Date}
//                   onChange={handleChange}
//                 >
//                   <option value="">Select Date</option>
//                   {renderDateOptions(7)}
//                 </select>
//                         </div>
//                       </div>
//                       <div className="col-lg-5">
//                         <div className="event-item">
//                           <label htmlFor="">Select Color : </label>
//                           <input
//                             type="color"
//                             name="augustEvent3Color"
//                             id="augustEvent3Color"
//                             value={formData.augustEvent3Color}
//                             onChange={handleChange}
//                           />
//                         </div>
//                       </div>
//                       <div className="col-lg-12">
//                         <div className="event-item">
//                           <label htmlFor="">Caption : </label>
//                           <input
//                             type="text"
//                             placeholder="Enter Caption (max 20 characters)"
//                             name="augustEvent3Caption"
//                             id="augustEvent3Caption"
//                             value={formData.augustEvent3Caption}
//                             onChange={handleChange}
//                             maxLength={20}
//                           />
//                         </div>
//                       </div>
//                     </div>
//                     <div className="event-buttons">
//                       <button className="b1" onClick={(e) => handleRemove(e)}>
//                         Remove
//                       </button>
//                       <button className="b2" type="submit">
//                         Add
//                       </button>
//                     </div>
//                   </form>
//                 </div>
//                 <div className="event-card">
//                   <h2>Your Event 4 : </h2>
//                   <form onSubmit={handleUpdate}>
//                     <div className="row">
//                       <div className="col-lg-5">
//                         <div className="event-item">
//                           <label htmlFor="">Add Date : </label>
//                           <select
//                           className="form-control"
//                   name="augustEvent4Date"
//                   id="augustEvent4Date"
//                   value={formData.augustEvent4Date}
//                   onChange={handleChange}
//                 >
//                   <option value="">Select Date</option>
//                   {renderDateOptions(7)}
//                 </select>
//                         </div>
//                       </div>
//                       <div className="col-lg-5">
//                         <div className="event-item">
//                           <label htmlFor="">Select Color : </label>
//                           <input
//                             type="color"
//                             name="augustEvent4Color"
//                             id="augustEvent4Color"
//                             value={formData.augustEvent4Color}
//                             onChange={handleChange}
//                           />
//                         </div>
//                       </div>
//                       <div className="col-lg-12">
//                         <div className="event-item">
//                           <label htmlFor="">Caption : </label>
//                           <input
//                             type="text"
//                             placeholder="Enter Caption (max 20 characters)"
//                             name="augustEvent4Caption"
//                             id="augustEvent4Caption"
//                             value={formData.augustEvent4Caption}
//                             onChange={handleChange}
//                             maxLength={20}
//                           />
//                         </div>
//                       </div>
//                     </div>
//                     <div className="event-buttons">
//                       <button className="b1" onClick={(e) => handleRemove(e)}>
//                         Remove
//                       </button>
//                       <button className="b2" type="submit">
//                         Add
//                       </button>
//                     </div>
//                   </form>
//                 </div>
//               </div>
//               <div
//                 id="sept"
//                 className={`events ${activeTab === "sept" ? "active" : ""}`}
//               >
//                 <h4>September</h4>
//                 <div className="event-card">
//                   <h2>Your Event 1 : </h2>
//                   <form onSubmit={handleUpdate}>
//                     <div className="row">
//                       <div className="col-lg-5">
//                         <div className="event-item">
//                           <label htmlFor="">Add Date : </label>
//                           <select
//                           className="form-control"
//                   name="septemberEvent1Date"
//                   id="septemberEvent1Date"
//                   value={formData.septemberEvent1Date}
//                   onChange={handleChange}
//                 >
//                   <option value="">Select Date</option>
//                   {renderDateOptions(8)}
//                 </select>
//                         </div>
//                       </div>
//                       <div className="col-lg-5">
//                         <div className="event-item">
//                           <label htmlFor="">Select Color : </label>
//                           <input
//                             type="color"
//                             name="septemberEvent1Color"
//                             id="septemberEvent1Color"
//                             value={formData.septemberEvent1Color}
//                             onChange={handleChange}
//                           />
//                         </div>
//                       </div>
//                       <div className="col-lg-12">
//                         <div className="event-item">
//                           <label htmlFor="">Caption : </label>
//                           <input
//                             type="text"
//                             placeholder="Enter Caption (max 20 characters)"
//                             name="septemberEvent1Caption"
//                             id="septemberEvent1Caption"
//                             value={formData.septemberEvent1Caption}
//                             onChange={handleChange}
//                             maxLength={20}
//                           />
//                         </div>
//                       </div>
//                     </div>
//                     <div className="event-buttons">
//                       <button className="b1" onClick={(e) => handleRemove(e)}>
//                         Remove
//                       </button>
//                       <button className="b2" type="submit">
//                         Add
//                       </button>
//                     </div>
//                   </form>
//                 </div>
//                 <div className="event-card">
//                   <h2>Your Event 2 : </h2>
//                   <form onSubmit={handleUpdate}>
//                     <div className="row">
//                       <div className="col-lg-5">
//                         <div className="event-item">
//                           <label htmlFor="">Add Date : </label>
//                           <select
//                           className="form-control"
//                   name="septemberEvent2Date"
//                   id="septemberEvent2Date"
//                   value={formData.septemberEvent2Date}
//                   onChange={handleChange}
//                 >
//                   <option value="">Select Date</option>
//                   {renderDateOptions(8)}
//                 </select>
//                         </div>
//                       </div>
//                       <div className="col-lg-5">
//                         <div className="event-item">
//                           <label htmlFor="">Select Color : </label>
//                           <input
//                             type="color"
//                             name="septemberEvent2Color"
//                             id="septemberEvent2Color"
//                             value={formData.septemberEvent2Color}
//                             onChange={handleChange}
//                           />
//                         </div>
//                       </div>
//                       <div className="col-lg-12">
//                         <div className="event-item">
//                           <label htmlFor="">Caption : </label>
//                           <input
//                             type="text"
//                             placeholder="Enter Caption (max 20 characters)"
//                             name="septemberEvent2Caption"
//                             id="septemberEvent2Caption"
//                             value={formData.septemberEvent2Caption}
//                             onChange={handleChange}
//                             maxLength={20}
//                           />
//                         </div>
//                       </div>
//                     </div>
//                     <div className="event-buttons">
//                       <button className="b1" onClick={(e) => handleRemove(e)}>
//                         Remove
//                       </button>
//                       <button className="b2" type="submit">
//                         Add
//                       </button>
//                     </div>
//                   </form>
//                 </div>
//                 <div className="event-card">
//                   <h2>Your Event 3 : </h2>
//                   <form onSubmit={handleUpdate}>
//                     <div className="row">
//                       <div className="col-lg-5">
//                         <div className="event-item">
//                           <label htmlFor="">Add Date : </label>
//                           <select
//                           className="form-control"
//                   name="septemberEvent3Date"
//                   id="septemberEvent3Date"
//                   value={formData.septemberEvent3Date}
//                   onChange={handleChange}
//                 >
//                   <option value="">Select Date</option>
//                   {renderDateOptions(8)}
//                 </select>
//                         </div>
//                       </div>
//                       <div className="col-lg-5">
//                         <div className="event-item">
//                           <label htmlFor="">Select Color : </label>
//                           <input
//                             type="color"
//                             name="septemberEvent3Color"
//                             id="septemberEvent3Color"
//                             value={formData.septemberEvent3Color}
//                             onChange={handleChange}
//                           />
//                         </div>
//                       </div>
//                       <div className="col-lg-12">
//                         <div className="event-item">
//                           <label htmlFor="">Caption : </label>
//                           <input
//                             type="text"
//                             placeholder="Enter Caption (max 20 characters)"
//                             name="septemberEvent3Caption"
//                             id="septemberEvent3Caption"
//                             value={formData.septemberEvent3Caption}
//                             onChange={handleChange}
//                             maxLength={20}
//                           />
//                         </div>
//                       </div>
//                     </div>
//                     <div className="event-buttons">
//                       <button className="b1" onClick={(e) => handleRemove(e)}>
//                         Remove
//                       </button>
//                       <button className="b2" type="submit">
//                         Add
//                       </button>
//                     </div>
//                   </form>
//                 </div>
//                 <div className="event-card">
//                   <h2>Your Event 4 : </h2>
//                   <form onSubmit={handleUpdate}>
//                     <div className="row">
//                       <div className="col-lg-5">
//                         <div className="event-item">
//                           <label htmlFor="">Add Date : </label>
//                           <select
//                           className="form-control"
//                   name="septemberEvent4Date"
//                   id="septemberEvent4Date"
//                   value={formData.septemberEvent4Date}
//                   onChange={handleChange}
//                 >
//                   <option value="">Select Date</option>
//                   {renderDateOptions(8)}
//                 </select>
//                         </div>
//                       </div>
//                       <div className="col-lg-5">
//                         <div className="event-item">
//                           <label htmlFor="">Select Color : </label>
//                           <input
//                             type="color"
//                             name="septemberEvent4Color"
//                             id="septemberEvent4Color"
//                             value={formData.septemberEvent4Color}
//                             onChange={handleChange}
//                           />
//                         </div>
//                       </div>
//                       <div className="col-lg-12">
//                         <div className="event-item">
//                           <label htmlFor="">Caption : </label>
//                           <input
//                             type="text"
//                             placeholder="Enter Caption (max 20 characters)"
//                             name="septemberEvent4Caption"
//                             id="septemberEvent4Caption"
//                             value={formData.septemberEvent4Caption}
//                             onChange={handleChange}
//                             maxLength={20}
//                           />
//                         </div>
//                       </div>
//                     </div>
//                     <div className="event-buttons">
//                       <button className="b1" onClick={(e) => handleRemove(e)}>
//                         Remove
//                       </button>
//                       <button className="b2" type="submit">
//                         Add
//                       </button>
//                     </div>
//                   </form>
//                 </div>
//               </div>
//               <div
//                 id="oct"
//                 className={`events ${activeTab === "oct" ? "active" : ""}`}
//               >
//                 <h4>October</h4>
//                 <div className="event-card">
//                   <h2>Your Event 1 : </h2>
//                   <form onSubmit={handleUpdate}>
//                     <div className="row">
//                       <div className="col-lg-5">
//                         <div className="event-item">
//                           <label htmlFor="">Add Date : </label>
//                           <select
//                           className="form-control"
//                   name="octoberEvent1Date"
//                   id="octoberEvent1Date"
//                   value={formData.octoberEvent1Date}
//                   onChange={handleChange}
//                 >
//                   <option value="">Select Date</option>
//                   {renderDateOptions(9)}
//                 </select>
//                         </div>
//                       </div>
//                       <div className="col-lg-5">
//                         <div className="event-item">
//                           <label htmlFor="">Select Color : </label>
//                           <input
//                             type="color"
//                             name="octoberEvent1Color"
//                             id="octoberEvent1Color"
//                             value={formData.octoberEvent1Color}
//                             onChange={handleChange}
//                           />
//                         </div>
//                       </div>
//                       <div className="col-lg-12">
//                         <div className="event-item">
//                           <label htmlFor="">Caption : </label>
//                           <input
//                             type="text"
//                             placeholder="Enter Caption (max 20 characters)"
//                             name="octoberEvent1Caption"
//                             id="octoberEvent1Caption"
//                             value={formData.octoberEvent1Caption}
//                             onChange={handleChange}
//                             maxLength={20}
//                           />
//                         </div>
//                       </div>
//                     </div>
//                     <div className="event-buttons">
//                       <button className="b1" onClick={(e) => handleRemove(e)}>
//                         Remove
//                       </button>
//                       <button className="b2" type="submit">
//                         Add
//                       </button>
//                     </div>
//                   </form>
//                 </div>
//                 <div className="event-card">
//                   <h2>Your Event 2 : </h2>
//                   <form onSubmit={handleUpdate}>
//                     <div className="row">
//                       <div className="col-lg-5">
//                         <div className="event-item">
//                           <label htmlFor="">Add Date : </label>
//                           <select
//                           className="form-control"
//                   name="octoberEvent2Date"
//                   id="octoberEvent2Date"
//                   value={formData.octoberEvent2Date}
//                   onChange={handleChange}
//                 >
//                   <option value="">Select Date</option>
//                   {renderDateOptions(9)}
//                 </select>
//                         </div>
//                       </div>
//                       <div className="col-lg-5">
//                         <div className="event-item">
//                           <label htmlFor="">Select Color : </label>
//                           <input
//                             type="color"
//                             name="octoberEvent2Color"
//                             id="octoberEvent2Color"
//                             value={formData.octoberEvent2Color}
//                             onChange={handleChange}
//                           />
//                         </div>
//                       </div>
//                       <div className="col-lg-12">
//                         <div className="event-item">
//                           <label htmlFor="">Caption : </label>
//                           <input
//                             type="text"
//                             placeholder="Enter Caption (max 20 characters)"
//                             name="octoberEvent2Caption"
//                             id="octoberEvent2Caption"
//                             value={formData.octoberEvent2Caption}
//                             onChange={handleChange}
//                             maxLength={20}
//                           />
//                         </div>
//                       </div>
//                     </div>
//                     <div className="event-buttons">
//                       <button className="b1" onClick={(e) => handleRemove(e)}>
//                         Remove
//                       </button>
//                       <button className="b2" type="submit">
//                         Add
//                       </button>
//                     </div>
//                   </form>
//                 </div>
//                 <div className="event-card">
//                   <h2>Your Event 3 : </h2>
//                   <form onSubmit={handleUpdate}>
//                     <div className="row">
//                       <div className="col-lg-5">
//                         <div className="event-item">
//                           <label htmlFor="">Add Date : </label>
//                           <select
//                           className="form-control"
//                   name="octoberEvent3Date"
//                   id="octoberEvent3Date"
//                   value={formData.octoberEvent3Date}
//                   onChange={handleChange}
//                 >
//                   <option value="">Select Date</option>
//                   {renderDateOptions(9)}
//                 </select>
//                         </div>
//                       </div>
//                       <div className="col-lg-5">
//                         <div className="event-item">
//                           <label htmlFor="">Select Color : </label>
//                           <input
//                             type="color"
//                             name="octoberEvent3Color"
//                             id="octoberEvent3Color"
//                             value={formData.octoberEvent3Color}
//                             onChange={handleChange}
//                           />
//                         </div>
//                       </div>
//                       <div className="col-lg-12">
//                         <div className="event-item">
//                           <label htmlFor="">Caption : </label>
//                           <input
//                             type="text"
//                             placeholder="Enter Caption (max 20 characters)"
//                             name="octoberEvent3Caption"
//                             id="octoberEvent3Caption"
//                             value={formData.octoberEvent3Caption}
//                             onChange={handleChange}
//                             maxLength={20}
//                           />
//                         </div>
//                       </div>
//                     </div>
//                     <div className="event-buttons">
//                       <button className="b1" onClick={(e) => handleRemove(e)}>
//                         Remove
//                       </button>
//                       <button className="b2" type="submit">
//                         Add
//                       </button>
//                     </div>
//                   </form>
//                 </div>
//                 <div className="event-card">
//                   <h2>Your Event 4 : </h2>
//                   <form onSubmit={handleUpdate}>
//                     <div className="row">
//                       <div className="col-lg-5">
//                         <div className="event-item">
//                           <label htmlFor="">Add Date : </label>
//                           <select
//                           className="form-control"
//                   name="octoberEvent4Date"
//                   id="octoberEvent4Date"
//                   value={formData.octoberEvent4Date}
//                   onChange={handleChange}
//                 >
//                   <option value="">Select Date</option>
//                   {renderDateOptions(9)}
//                 </select>
//                         </div>
//                       </div>
//                       <div className="col-lg-5">
//                         <div className="event-item">
//                           <label htmlFor="">Select Color : </label>
//                           <input
//                             type="color"
//                             name="octoberEvent4Color"
//                             id="octoberEvent4Color"
//                             value={formData.octoberEvent4Color}
//                             onChange={handleChange}
//                           />
//                         </div>
//                       </div>
//                       <div className="col-lg-12">
//                         <div className="event-item">
//                           <label htmlFor="">Caption : </label>
//                           <input
//                             type="text"
//                             placeholder="Enter Caption (max 20 characters)"
//                             name="octoberEvent4Caption"
//                             id="octoberEvent4Caption"
//                             value={formData.octoberEvent4Caption}
//                             onChange={handleChange}
//                             maxLength={20}
//                           />
//                         </div>
//                       </div>
//                     </div>
//                     <div className="event-buttons">
//                       <button className="b1" onClick={(e) => handleRemove(e)}>
//                         Remove
//                       </button>
//                       <button className="b2" type="submit">
//                         Add
//                       </button>
//                     </div>
//                   </form>
//                 </div>
//               </div>
//               <div
//                 id="nov"
//                 className={`events ${activeTab === "nov" ? "active" : ""}`}
//               >
//                 <h4>November</h4>
//                 <div className="event-card">
//                   <h2>Your Event 1 : </h2>
//                   <form onSubmit={handleUpdate}>
//                     <div className="row">
//                       <div className="col-lg-5">
//                         <div className="event-item">
//                           <label htmlFor="">Add Date : </label>
//                           <select
//                           className="form-control"
//                   name="novemberEvent1Date"
//                   id="novemberEvent1Date"
//                   value={formData.novemberEvent1Date}
//                   onChange={handleChange}
//                 >
//                   <option value="">Select Date</option>
//                   {renderDateOptions(10)}
//                 </select>
//                         </div>
//                       </div>
//                       <div className="col-lg-5">
//                         <div className="event-item">
//                           <label htmlFor="">Select Color : </label>
//                           <input
//                             type="color"
//                             name="novemberEvent1Color"
//                             id="novemberEvent1Color"
//                             value={formData.novemberEvent1Color}
//                             onChange={handleChange}
//                           />
//                         </div>
//                       </div>
//                       <div className="col-lg-12">
//                         <div className="event-item">
//                           <label htmlFor="">Caption : </label>
//                           <input
//                             type="text"
//                             placeholder="Enter Caption (max 20 characters)"
//                             name="novemberEvent1Caption"
//                             id="novemberEvent1Caption"
//                             value={formData.novemberEvent1Caption}
//                             onChange={handleChange}
//                             maxLength={20}
//                           />
//                         </div>
//                       </div>
//                     </div>
//                     <div className="event-buttons">
//                       <button className="b1" onClick={(e) => handleRemove(e)}>
//                         Remove
//                       </button>
//                       <button className="b2" type="submit">
//                         Add
//                       </button>
//                     </div>
//                   </form>
//                 </div>
//                 <div className="event-card">
//                   <h2>Your Event 2 : </h2>
//                   <form onSubmit={handleUpdate}>
//                     <div className="row">
//                       <div className="col-lg-5">
//                         <div className="event-item">
//                           <label htmlFor="">Add Date : </label>
//                           <select
//                           className="form-control"
//                   name="novemberEvent2Date"
//                   id="novemberEvent2Date"
//                   value={formData.novemberEvent2Date}
//                   onChange={handleChange}
//                 >
//                   <option value="">Select Date</option>
//                   {renderDateOptions(10)}
//                 </select>
//                         </div>
//                       </div>
//                       <div className="col-lg-5">
//                         <div className="event-item">
//                           <label htmlFor="">Select Color : </label>
//                           <input
//                             type="color"
//                             name="novemberEvent2Color"
//                             id="novemberEvent2Color"
//                             value={formData.novemberEvent2Color}
//                             onChange={handleChange}
//                           />
//                         </div>
//                       </div>
//                       <div className="col-lg-12">
//                         <div className="event-item">
//                           <label htmlFor="">Caption : </label>
//                           <input
//                             type="text"
//                             placeholder="Enter Caption (max 20 characters)"
//                             name="novemberEvent2Caption"
//                             id="novemberEvent2Caption"
//                             value={formData.novemberEvent2Caption}
//                             onChange={handleChange}
//                             maxLength={20}
//                           />
//                         </div>
//                       </div>
//                     </div>
//                     <div className="event-buttons">
//                       <button className="b1" onClick={(e) => handleRemove(e)}>
//                         Remove
//                       </button>
//                       <button className="b2" type="submit">
//                         Add
//                       </button>
//                     </div>
//                   </form>
//                 </div>
//                 <div className="event-card">
//                   <h2>Your Event 3 : </h2>
//                   <form onSubmit={handleUpdate}>
//                     <div className="row">
//                       <div className="col-lg-5">
//                         <div className="event-item">
//                           <label htmlFor="">Add Date : </label>
//                           <select
//                           className="form-control"
//                   name="novemberEvent3Date"
//                   id="novemberEvent3Date"
//                   value={formData.novemberEvent3Date}
//                   onChange={handleChange}
//                 >
//                   <option value="">Select Date</option>
//                   {renderDateOptions(10)}
//                 </select>
//                         </div>
//                       </div>
//                       <div className="col-lg-5">
//                         <div className="event-item">
//                           <label htmlFor="">Select Color : </label>
//                           <input
//                             type="color"
//                             name="novemberEvent3Color"
//                             id="novemberEvent3Color"
//                             value={formData.novemberEvent3Color}
//                             onChange={handleChange}
//                           />
//                         </div>
//                       </div>
//                       <div className="col-lg-12">
//                         <div className="event-item">
//                           <label htmlFor="">Caption : </label>
//                           <input
//                             type="text"
//                             placeholder="Enter Caption (max 20 characters)"
//                             name="novemberEvent3Caption"
//                             id="novemberEvent3Caption"
//                             value={formData.novemberEvent3Caption}
//                             onChange={handleChange}
//                             maxLength={20}
//                           />
//                         </div>
//                       </div>
//                     </div>
//                     <div className="event-buttons">
//                       <button className="b1" onClick={(e) => handleRemove(e)}>
//                         Remove
//                       </button>
//                       <button className="b2" type="submit">
//                         Add
//                       </button>
//                     </div>
//                   </form>
//                 </div>
//                 <div className="event-card">
//                   <h2>Your Event 4 : </h2>
//                   <form onSubmit={handleUpdate}>
//                     <div className="row">
//                       <div className="col-lg-5">
//                         <div className="event-item">
//                           <label htmlFor="">Add Date : </label>
//                           <select
//                           className="form-control"
//                   name="novemberEvent4Date"
//                   id="novemberEvent4Date"
//                   value={formData.novemberEvent4Date}
//                   onChange={handleChange}
//                 >
//                   <option value="">Select Date</option>
//                   {renderDateOptions(10)}
//                 </select>
//                         </div>
//                       </div>
//                       <div className="col-lg-5">
//                         <div className="event-item">
//                           <label htmlFor="">Select Color : </label>
//                           <input
//                             type="color"
//                             name="novemberEvent4Color"
//                             id="novemberEvent4Color"
//                             value={formData.novemberEvent4Color}
//                             onChange={handleChange}
//                           />
//                         </div>
//                       </div>
//                       <div className="col-lg-12">
//                         <div className="event-item">
//                           <label htmlFor="">Caption : </label>
//                           <input
//                             type="text"
//                             placeholder="Enter Caption (max 20 characters)"
//                             name="novemberEvent4Caption"
//                             id="novemberEvent4Caption"
//                             value={formData.novemberEvent4Caption}
//                             onChange={handleChange}
//                             maxLength={20}
//                           />
//                         </div>
//                       </div>
//                     </div>
//                     <div className="event-buttons">
//                       <button className="b1" onClick={(e) => handleRemove(e)}>
//                         Remove
//                       </button>
//                       <button className="b2" type="submit">
//                         Add
//                       </button>
//                     </div>
//                   </form>
//                 </div>
//               </div>
//               <div
//                 id="dec"
//                 className={`events ${activeTab === "dec" ? "active" : ""}`}
//               >
//                 <h4>December</h4>
//                 <div className="event-card">
//                   <h2>Your Event 1 : </h2>
//                   <form onSubmit={handleUpdate}>
//                     <div className="row">
//                       <div className="col-lg-5">
//                         <div className="event-item">
//                           <label htmlFor="">Add Date : </label>
//                           <select
//                           className="form-control"
//                   name="decemberEvent1Date"
//                   id="decemberEvent1Date"
//                   value={formData.decemberEvent1Date}
//                   onChange={handleChange}
//                 >
//                   <option value="">Select Date</option>
//                   {renderDateOptions(11)}
//                 </select>
//                         </div>
//                       </div>
//                       <div className="col-lg-5">
//                         <div className="event-item">
//                           <label htmlFor="">Select Color : </label>
//                           <input
//                             type="color"
//                             name="decemberEvent1Color"
//                             id="decemberEvent1Color"
//                             value={formData.decemberEvent1Color}
//                             onChange={handleChange}
//                           />
//                         </div>
//                       </div>
//                       <div className="col-lg-12">
//                         <div className="event-item">
//                           <label htmlFor="">Caption : </label>
//                           <input
//                             type="text"
//                             placeholder="Enter Caption (max 20 characters)"
//                             name="decemberEvent1Caption"
//                             id="decemberEvent1Caption"
//                             value={formData.decemberEvent1Caption}
//                             onChange={handleChange}
//                             maxLength={20}
//                           />
//                         </div>
//                       </div>
//                     </div>
//                     <div className="event-buttons">
//                       <button className="b1" onClick={(e) => handleRemove(e)}>
//                         Remove
//                       </button>
//                       <button className="b2" type="submit">
//                         Add
//                       </button>
//                     </div>
//                   </form>
//                 </div>
//                 <div className="event-card">
//                   <h2>Your Event 2 : </h2>
//                   <form onSubmit={handleUpdate}>
//                     <div className="row">
//                       <div className="col-lg-5">
//                         <div className="event-item">
//                           <label htmlFor="">Add Date : </label>
//                           <select
//                           className="form-control"
//                   name="decemberEvent2Date"
//                   id="decemberEvent2Date"
//                   value={formData.decemberEvent2Date}
//                   onChange={handleChange}
//                 >
//                   <option value="">Select Date</option>
//                   {renderDateOptions(11)}
//                 </select>
//                         </div>
//                       </div>
//                       <div className="col-lg-5">
//                         <div className="event-item">
//                           <label htmlFor="">Select Color : </label>
//                           <input
//                             type="color"
//                             name="decemberEvent2Color"
//                             id="decemberEvent2Color"
//                             value={formData.decemberEvent2Color}
//                             onChange={handleChange}
//                           />
//                         </div>
//                       </div>
//                       <div className="col-lg-12">
//                         <div className="event-item">
//                           <label htmlFor="">Caption : </label>
//                           <input
//                             type="text"
//                             placeholder="Enter Caption (max 20 characters)"
//                             name="decemberEvent2Caption"
//                             id="decemberEvent2Caption"
//                             value={formData.decemberEvent2Caption}
//                             onChange={handleChange}
//                             maxLength={20}
//                           />
//                         </div>
//                       </div>
//                     </div>
//                     <div className="event-buttons">
//                       <button className="b1" onClick={(e) => handleRemove(e)}>
//                         Remove
//                       </button>
//                       <button className="b2" type="submit">
//                         Add
//                       </button>
//                     </div>
//                   </form>
//                 </div>
//                 <div className="event-card">
//                   <h2>Your Event 3 : </h2>
//                   <form onSubmit={handleUpdate}>
//                     <div className="row">
//                       <div className="col-lg-5">
//                         <div className="event-item">
//                           <label htmlFor="">Add Date : </label>
//                           <select
//                           className="form-control"
//                   name="decemberEvent3Date"
//                   id="decemberEvent3Date"
//                   value={formData.decemberEvent3Date}
//                   onChange={handleChange}
//                 >
//                   <option value="">Select Date</option>
//                   {renderDateOptions(11)}
//                 </select>
//                         </div>
//                       </div>
//                       <div className="col-lg-5">
//                         <div className="event-item">
//                           <label htmlFor="">Select Color : </label>
//                           <input
//                             type="color"
//                             name="decemberEvent3Color"
//                             id="decemberEvent3Color"
//                             value={formData.decemberEvent3Color}
//                             onChange={handleChange}
//                           />
//                         </div>
//                       </div>
//                       <div className="col-lg-12">
//                         <div className="event-item">
//                           <label htmlFor="">Caption : </label>
//                           <input
//                             type="text"
//                             placeholder="Enter Caption (max 20 characters)"
//                             name="decemberEvent3Caption"
//                             id="decemberEvent3Caption"
//                             value={formData.decemberEvent3Caption}
//                             onChange={handleChange}
//                             maxLength={20}
//                           />
//                         </div>
//                       </div>
//                     </div>
//                     <div className="event-buttons">
//                       <button className="b1" onClick={(e) => handleRemove(e)}>
//                         Remove
//                       </button>
//                       <button className="b2" type="submit">
//                         Add
//                       </button>
//                     </div>
//                   </form>
//                 </div>
//                 <div className="event-card">
//                   <h2>Your Event 4 : </h2>
//                   <form onSubmit={handleUpdate}>
//                     <div className="row">
//                       <div className="col-lg-5">
//                         <div className="event-item">
//                           <label htmlFor="">Add Date : </label>
//                           <select
//                           className="form-control"
//                   name="decemberEvent4Date"
//                   id="decemberEvent4Date"
//                   value={formData.decemberEvent4Date}
//                   onChange={handleChange}
//                 >
//                   <option value="">Select Date</option>
//                   {renderDateOptions(11)}
//                 </select>
//                         </div>
//                       </div>
//                       <div className="col-lg-5">
//                         <div className="event-item">
//                           <label htmlFor="">Select Color : </label>
//                           <input
//                             type="color"
//                             name="decemberEvent4Color"
//                             id="decemberEvent4Color"
//                             value={formData.decemberEvent4Color}
//                             onChange={handleChange}
//                           />
//                         </div>
//                       </div>
//                       <div className="col-lg-12">
//                         <div className="event-item">
//                           <label htmlFor="">Caption : </label>
//                           <input
//                             type="text"
//                             placeholder="Enter Caption (max 20 characters)"
//                             name="decemberEvent4Caption"
//                             id="decemberEvent4Caption"
//                             value={formData.decemberEvent4Caption}
//                             onChange={handleChange}
//                             maxLength={20}
//                           />
//                         </div>
//                       </div>
//                     </div>
//                     <div className="event-buttons">
//                       <button className="b1" onClick={(e) => handleRemove(e)}>
//                         Remove
//                       </button>
//                       <button className="b2" type="submit">
//                         Add
//                       </button>
//                     </div>
//                   </form>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//         <div className="image-button">
//           {/* <Link to={`/preview/${calendarId}`}><button>Continue to Next Step</button></Link> */}
//           {/* <Link to={`/preview1/${calendarId}`}><button>Continue to Next Step</button></Link> */}
//           <Link to={`/shipping-billing/${calendarId}`}><button>Continue to Next Step</button></Link>
//         </div>
//       </div>

//       <Footer />
//     </>
//   );
// };

// export default Events;


// import React, { useEffect, useState } from "react";
// import Navbar from "../components/Navbar";
// import { Link, useNavigate, useParams } from "react-router-dom";
// import iziToast from "izitoast";
// import axios from "axios";
// import { useAuth } from "../AuthContext";
// import { Modal } from "react-bootstrap";
// const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

// const Events = () => {
//   const [show, setShow] = useState(false);
//   const [startYearMonth, setStartYearMonth] = useState(null);
//   const [holidays, setHolidays] = useState([]);
//   const [holidayMap, setHolidayMap] = useState({});
//   const [isLoggedIn, setIsLoggedIn] = useState(false);
//   const [daysInMonth, setDaysInMonth] = useState([]);
//   const [eventCalendarId, setEventCalendarId] = useState(null);

//   const [months, setMonths] = useState([]);
//   const [activeTab, setActiveTab] = useState('');
//   const [year, setYear] = useState(new Date().getFullYear());
//   const [month, setMonth] = useState(new Date().getMonth() + 1);
  
//   const [isLoading, setIsLoading] = useState(false);

//   const { calendarId } = useParams();
//   const { apipath } = useAuth();
//   const navigate = useNavigate();  
//   const [formData, setFormData] = useState({
//     januaryEvent1Date: "",
//     januaryEvent1Caption: "",
//     januaryEvent1Color: "",
//     januaryEvent2Date: "",
//     januaryEvent2Caption: "",
//     januaryEvent2Color: "",
//     januaryEvent3Date: "",
//     januaryEvent3Caption: "",
//     januaryEvent3Color: "",
//     januaryEvent4Date: "",
//     januaryEvent4Caption: "",
//     januaryEvent4Color: "",
//     februaryEvent1Date: "",
//     februaryEvent1Caption: "",
//     februaryEvent1Color: "",
//     februaryEvent2Date: "",
//     februaryEvent2Caption: "",
//     februaryEvent2Color: "",
//     februaryEvent3Date: "",
//     februaryEvent3Caption: "",
//     februaryEvent3Color: "",
//     februaryEvent4Date: "",
//     februaryEvent4Caption: "",
//     februaryEvent4Color: "",
//     marchEvent1Date: "",
//     marchEvent1Caption: "",
//     marchEvent1Color: "",
//     marchEvent2Date: "",
//     marchEvent2Caption: "",
//     marchEvent2Color: "",
//     marchEvent3Date: "",
//     marchEvent3Caption: "",
//     marchEvent3Color: "",
//     marchEvent4Date: "",
//     marchEvent4Caption: "",
//     marchEvent4Color: "",
//     aprilEvent1Date: "",
//     aprilEvent1Caption: "",
//     aprilEvent1Color: "",
//     aprilEvent2Date: "",
//     aprilEvent2Caption: "",
//     aprilEvent2Color: "",
//     aprilEvent3Date: "",
//     aprilEvent3Caption: "",
//     aprilEvent3Color: "",
//     aprilEvent4Date: "",
//     aprilEvent4Caption: "",
//     aprilEvent4Color: "",
//     mayEvent1Date: "",
//     mayEvent1Caption: "",
//     mayEvent1Color: "",
//     mayEvent2Date: "",
//     mayEvent2Caption: "",
//     mayEvent2Color: "",
//     mayEvent3Date: "",
//     mayEvent3Caption: "",
//     mayEvent3Color: "",
//     mayEvent4Date: "",
//     mayEvent4Caption: "",
//     mayEvent4Color: "",
//     juneEvent1Date: "",
//     juneEvent1Caption: "",
//     juneEvent1Color: "",
//     juneEvent2Date: "",
//     juneEvent2Caption: "",
//     juneEvent2Color: "",
//     juneEvent3Date: "",
//     juneEvent3Caption: "",
//     juneEvent3Color: "",
//     juneEvent4Date: "",
//     juneEvent4Caption: "",
//     juneEvent4Color: "",
//     julyEvent1Date: "",
//     julyEvent1Caption: "",
//     julyEvent1Color: "",
//     julyEvent2Date: "",
//     julyEvent2Caption: "",
//     julyEvent2Color: "",
//     julyEvent3Date: "",
//     julyEvent3Caption: "",
//     julyEvent3Color: "",
//     julyEvent4Date: "",
//     julyEvent4Caption: "",
//     julyEvent4Color: "",
//     augustEvent1Date: "",
//     augustEvent1Caption: "",
//     augustEvent1Color: "",
//     augustEvent2Date: "",
//     augustEvent2Caption: "",
//     augustEvent2Color: "",
//     augustEvent3Date: "",
//     augustEvent3Caption: "",
//     augustEvent3Color: "",
//     augustEvent4Date: "",
//     augustEvent4Caption: "",
//     augustEvent4Color: "",
//     septemberEvent1Date: "",
//     septemberEvent1Caption: "",
//     septemberEvent1Color: "",
//     septemberEvent2Date: "",
//     septemberEvent2Caption: "",
//     septemberEvent2Color: "",
//     septemberEvent3Date: "",
//     septemberEvent3Caption: "",
//     septemberEvent3Color: "",
//     septemberEvent4Date: "",
//     septemberEvent4Caption: "",
//     septemberEvent4Color: "",
//     octoberEvent1Date: "",
//     octoberEvent1Caption: "",
//     octoberEvent1Color: "",
//     octoberEvent2Date: "",
//     octoberEvent2Caption: "",
//     octoberEvent2Color: "",
//     octoberEvent3Date: "",
//     octoberEvent3Caption: "",
//     octoberEvent3Color: "",
//     octoberEvent4Date: "",
//     octoberEvent4Caption: "",
//     octoberEvent4Color: "",
//     novemberEvent1Date: "",
//     novemberEvent1Caption: "",
//     novemberEvent1Color: "",
//     novemberEvent2Date: "",
//     novemberEvent2Caption: "",
//     novemberEvent2Color: "",
//     novemberEvent3Date: "",
//     novemberEvent3Caption: "",
//     novemberEvent3Color: "",
//     novemberEvent4Date: "",
//     novemberEvent4Caption: "",
//     novemberEvent4Color: "",
//     decemberEvent1Date: "",
//     decemberEvent1Caption: "",
//     decemberEvent1Color: "",
//     decemberEvent2Date: "",
//     decemberEvent2Caption: "",
//     decemberEvent2Color: "",
//     decemberEvent3Date: "",
//     decemberEvent3Caption: "",
//     decemberEvent3Color: "",
//     decemberEvent4Date: "",
//     decemberEvent4Caption: "",
//     decemberEvent4Color: "",

//   });

//   const handleShow = (calendarId) => {
//     setEventCalendarId(calendarId);
//     setShow(true);
// };

// const handleClose = () => {
//     setEventCalendarId(null);
//     setShow(false);
// };

// const handleConfirm = () => {
//     if (eventCalendarId) {
//       navigate(eventCalendarId); // Navigate to the desired page
//     }
// };

//   const handleRemove = async (month, eventNumber) => {
//     // Update form data to clear the event details
//     setFormData({
//       ...formData,
//       [`${month}Event${eventNumber}Date`]: "",
//       [`${month}Event${eventNumber}Caption`]: "",
//       [`${month}Event${eventNumber}Color`]: ""
//     });
//     // await handleUpdate();
//   };

//   const token = localStorage.getItem('token');

//   const checkLoginStatus = () => {
//     setIsLoggedIn(!!token);
//   };

//   useEffect(() => {
//     checkLoginStatus();
//     window.scrollTo({
//       top: 0,
//       left: 0,
//       behavior: "auto",
//     });
//     fetchCalendarEdit();
//   }, []);


//   useEffect(() => {
//     checkLoginStatus();
//     fetchCalendarEdit();
//   }, []);

//   useEffect(() => {
//     if (months.length > 0) {
//       const defaultMonth = months[0];
//       fetchHolidays(defaultMonth.year, defaultMonth.month);
//     }
//   }, [months]);

//   useEffect(() => {
//     if (year && month) {
//       const days = new Date(year, month, 0).getDate();
//       setDaysInMonth(Array.from({ length: days }, (_, i) => i + 1));
//       fetchHolidays(year, month);
//     }
//   }, [year, month]);
//   useEffect(() => {
//     if (startYearMonth) {
//       const { year, month } = startYearMonth;
//       fetchHolidays(year, month);
//     }
//   }, [startYearMonth]);
  

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({ ...formData, [name]: value });
//   };

//   const handleUpdate = async (e) => {
//     e.preventDefault();
//     try {
//       const response = await axios.put(
//         `${apipath}/calendar/details/${calendarId}`,
//         formData,
//         {
//           headers: {
//             'Authorization': `Bearer ${token}`,
//           },
//         }
//       );

//       if (response.status === 200) {
//         iziToast.success({
//           message: "Event update successful",
//           position: "topCenter",
//         });
//       } else {
//         iziToast.error({
//           message: "Event update failed",
//           position: "topCenter",
//         });
//       }
//     } catch (error) {
//       console.error("Error updating event details:", error);
//       iziToast.error({
//         message: "An error occurred",
//         position: "topCenter",
//       });
//     }
//   };

//   const fetchCalendarEdit = async () => {
//     try {
//       const response = await axios.get(`${apipath}/calendar/details/${calendarId}`);
//       const calendarData = response.data.calendar;
//       setFormData(calendarData);
//       const startYearMonth = new Date(calendarData.startYearMonth);
//       setStartYearMonth(startYearMonth);
//       generateMonths(startYearMonth);
//       setYear(startYearMonth.getFullYear());
//       setMonth(startYearMonth.getMonth() + 1); // getMonth() returns 0-11

//     } catch (error) {
//       console.error("Error fetching calendar details:", error);
//     }
//   };


//   const generateMonths = (startDate) => {
//     const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
//     let generatedMonths = [];
//     for (let i = 0; i < 12; i++) {
//       const month = (startDate.getMonth() + i) % 12;
//       const year = startDate.getFullYear() + Math.floor((startDate.getMonth() + i) / 12);
//       generatedMonths.push({ month: monthNames[month], year });
//     }
//     setMonths(generatedMonths);
//     setActiveTab(monthNames[startDate.getMonth()].toLowerCase());
//   };


// const fetchHolidays = async (year, month) => {
//   try {
//     const response = await axios.get(
//       `https://www.googleapis.com/calendar/v3/calendars/en.indian%23holiday%40group.v.calendar.google.com/events?key=AIzaSyAjfBYZVOqpBtwp6Ty55hL9-eXAVD6rKUs`
//     );
//     const events = response.data.items;

//     // Filter holidays based on the selected year and month
//     const holidays = events
//       .map(event => ({
//         summary: event.summary,
//         date: new Date(event.start.date),
//       }))
//       .filter(holiday => holiday.date.getFullYear() === year && holiday.date.getMonth() === month - 1); // Adjust month for 0-based index

//     // Update holidayMap
//     setHolidayMap(holidays.reduce((map, holiday) => {
//       const day = holiday.date.getDate();
//       if (!map[day]) {
//         map[day] = [];
//       }
//       map[day].push(holiday.summary);
//       return map;
//     }, {}));
//     setHolidays(holidays);
//   } catch (error) {
//     console.error('Error fetching holidays:', error);
//   }
// };

// useEffect(() => {
//   const currentYear = new Date().getFullYear();
//   const currentMonth = new Date().getMonth(); // 0-based index

//   // Set the default month and year in state
//   setYear(currentYear);
//   setMonth(currentMonth + 1); // Convert to 1-based index
//   setActiveTab(monthNames[currentMonth].toUpperCase()); // Set the default active tab
//   console.log("monthNames[currentMonth].toUpperCase() : ",monthNames[currentMonth]);
  
//   // Generate months starting from the current month and year
//   generateMonths(new Date(currentYear, currentMonth));
// }, []);


// const monthToNumber = (monthName) => {
//   const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
//   return monthNames.indexOf(monthName) + 1;
// };

// const openTab = (tabName, monthIndex) => {  
//   setActiveTab(tabName);
//   const selectedMonth = months[monthIndex];
//   fetchHolidays(selectedMonth.year, monthToNumber(selectedMonth.month));
//   setYear(selectedMonth.year);
//   setMonth(monthToNumber(selectedMonth.month)); // Store as a number for consistency
//   console.log("selectedMonth : ",selectedMonth);
//   console.log("monthToNumber(selectedMonth.month) : ",monthToNumber(selectedMonth.month));
// };


//   const renderDateOptions = (monthOffset) => {
//     if (!startYearMonth) return null;

//     const newDate = new Date(startYearMonth);
//     newDate.setMonth(startYearMonth.getMonth() + monthOffset);
//     const year = newDate.getFullYear();
//     const month = newDate.getMonth();

//     const days = getDaysInMonth(year, month);

//     return days.map(day => (
//       <option key={day} value={day}>
//         {day} {holidayMap[day] ? `- ${holidayMap[day].join(', ')}` : ''}
//       </option>
//     ));
//   };


//   const getDaysInMonth = (year, month) => {
//     return new Array(31).fill('').map((_, index) => new Date(year, month, index + 1))
//       .filter(date => date.getMonth() === month)
//       .map(date => date.getDate());
//   };

//   const renderHolidayOptions = () => {
//     const rowColors = ["c1", "c2", "c3"];
//     let rows = [];
//     for (let i = 0; i < holidays.length; i += 2) {
//       const colorClass = rowColors[Math.floor(i / 2) % rowColors.length];
//       rows.push(
//         <div className="holiday-row" key={i}>
//           <div className="holiday-item">
//             <span className={`holiday-color ${colorClass}`}></span>
//             <span>{`${holidays[i].date.getDate()} - ${holidays[i].summary}`}</span>
//           </div>
//           {holidays[i + 1] && (
//             <div className="holiday-item" key={i + 1}>
//               <span className={`holiday-color ${colorClass}`}></span>
//               <span>{`${holidays[i + 1].date.getDate()} - ${holidays[i + 1].summary}`}</span>
//             </div>
//           )}
//         </div>
//       );
//     }
//     return rows;
//   };

//   return (
//     <>
//       <Navbar isLoggedIn={isLoggedIn} />
//       <div className="container">
//         <div className="col-lg-6 mx-auto image-header">
//           <h1>Let’s make your own personal calendar</h1>
//           <div className="progress-container">
//             <div className="progress-line"></div>
//             <div className="circle" id="step1">1</div>
//             <div className="circle" id="step2">2</div>
//             <div className="circle active" id="step3">3</div>
//           </div>
//         </div>

//         <Modal show={show} onHide={handleClose} centered>
//     <Modal.Header closeButton>
//         <Modal.Title>Calendar Events</Modal.Title>
//     </Modal.Header>
//     <Modal.Body>
//         <div className="modal-content1">
//             <p>I hereby confirm to move to proceed with enter events?</p>
//         </div>
//     </Modal.Body>
//     <Modal.Footer>
//         <button className="modal-footer-btn" onClick={handleConfirm} disabled={isLoading}>
//             {isLoading ? "Confirm..." : "Confirm"}
//         </button>
//         <button className="modal-footer-btn" onClick={handleClose}>
//             Cancel
//         </button>
//     </Modal.Footer>
// </Modal>

//         <div className="image-content">
//           <h3>Add events in your calendar :</h3>
//           <div className="row image-row py-3">
//             <div className="col-lg-3">
//               <div className="col-lg-8">
//                 <div className="tab">
//                   {months.map((month, index) => (
//                     <button
//                       key={index}
//                       className={`tablinks ${activeTab === month.month.toLowerCase() ? "active" : ""}`}
//                       onClick={() => openTab(month.month.toLowerCase(), index)}
//                     >
//                       {month.month}
//                       {/* {month.year} */}
//                     </button>
//                   ))}
//                 </div>
//               </div>
//             </div>

//             <div className="col-lg-9">
//               <div className="holiday">
//                 <h2>Public holidays this month:</h2>
//                 <div className="holiday-list d-flex">
//                   <div className="holiday-1">
//                     {renderHolidayOptions()}
//                   </div>
//                 </div>
//               </div>
//               {months.map((month, index) => (
//                 <div
//                   key={month.month.toLowerCase()}
//                   id={month.month.toLowerCase()}
//                   className={`events ${activeTab === month.month.toLowerCase() ? "active" : "d-none"}`}
//                 >
//                   <h4>{month.month}</h4>
//                   {[1, 2, 3, 4].map((eventNumber) => (
//                     <div key={eventNumber} className="event-card">
//                       <h2>Your Event {eventNumber + 0}:</h2>
//                       <form onSubmit={handleUpdate}>
//                         <div className="row">
//                           <div className="col-lg-5">
//                             <div className="event-item">
//                               <label htmlFor={`${month.month.toLowerCase()}Event${eventNumber}Date`}>
//                                 Event Date:
//                               </label>
//                               <select
//                                 className="form-control"
//                                 id={`${month.month.toLowerCase()}Event${eventNumber}Date`}
//                                 name={`${month.month.toLowerCase()}Event${eventNumber}Date`}
//                                 value={formData[`${month.month.toLowerCase()}Event${eventNumber}Date`] || ""}
//                                 onChange={handleChange}
//                               >
//                                 <option value="">Select Date</option>
//                                 {renderDateOptions(index)}
//                               </select>
//                             </div>
//                           </div>
//                           <div className="col-lg-5">
//                             <div className="event-item">
//                               <label htmlFor={`${month.month.toLowerCase()}Event${eventNumber}Color`}>
//                                 Select Color:
//                               </label>
//                               <input
//                                 type="color"
//                                 name={`${month.month.toLowerCase()}Event${eventNumber}Color`}
//                                 id={`event${eventNumber}Color`}
//                                 value={formData[`${month.month.toLowerCase()}Event${eventNumber}Color`] || ""}
//                                 onChange={handleChange}
//                               />
//                             </div>
//                           </div>
//                           <div className="col-lg-12">
//                             <div className="event-item">
//                               <label htmlFor={`${month.month.toLowerCase()}Event${eventNumber}Caption`}>
//                                 Event Caption:
//                               </label>
//                               <input
//                                 type="text"
//                                 className="form-control"
//                                 id={`${month.month.toLowerCase()}Event${eventNumber}Caption`}
//                                 name={`${month.month.toLowerCase()}Event${eventNumber}Caption`}
//                                 value={formData[`${month.month.toLowerCase()}Event${eventNumber}Caption`] || ""}
//                                 onChange={handleChange}
//                               />
//                             </div>
//                           </div>

//                         </div>
//                         {/* <div className="event-buttons">
// //                       <button className="b1" onClick={(e) => handleRemove(e)}>
// //                         Remove
// //                       </button>
// //                       <button className="b2" type="submit">
// //                         Add
// //                       </button> */}

//                         <div className="event-buttons">
//                           <button type="button" className="btn btn-danger b1" onClick={() => handleRemove(month.month.toLowerCase(), eventNumber)}>Remove <i class="bi bi-x-lg"></i></button>
//                           <button className="b2" type="submit">Added <i class="bi bi-check2"></i></button>
//                         </div>
//                       </form>
//                     </div>
//                   ))}
//                 </div>
//               ))}
//               <div className="image-button">
//     <Link onClick={() => handleShow(`/shipping-billing/${calendarId}`)}>
//         <button>Continue to Next Step</button>
//     </Link>
// </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default Events;

import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import { Link, useNavigate, useParams } from "react-router-dom";
import iziToast from "izitoast";
import axios from "axios";
import { useAuth } from "../AuthContext";
import { Modal } from "react-bootstrap";

const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

const Events = () => {
  const [show, setShow] = useState(false);
  const [startYearMonth, setStartYearMonth] = useState(null);
  const [holidays, setHolidays] = useState([]);
  const [holidayMap, setHolidayMap] = useState({});
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [daysInMonth, setDaysInMonth] = useState([]);
  const [eventCalendarId, setEventCalendarId] = useState(null);
  const [months, setMonths] = useState([]);
  const [activeTab, setActiveTab] = useState('');
  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState(new Date().getMonth() + 1);
  const [isLoading, setIsLoading] = useState(false);

  const { calendarId } = useParams();
  const { apipath } = useAuth();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    // Add your event form fields here, e.g.
    januaryEvent1Date: "",
    januaryEvent1Caption: "",
    januaryEvent1Color: "",
    //... other months and events
  });

  const handleShow = (calendarId) => {
    setEventCalendarId(calendarId);
    setShow(true);
  };

  const handleClose = () => {
    setEventCalendarId(null);
    setShow(false);
  };

  const handleConfirm = () => {
    if (eventCalendarId) {
      navigate(`/shipping-billing/${eventCalendarId}`);
    }
  };

  const handleRemove = (month, eventNumber) => {
    setFormData(prevState => ({
      ...prevState,
      [`${month}Event${eventNumber}Date`]: "",
      [`${month}Event${eventNumber}Caption`]: "",
      [`${month}Event${eventNumber}Color`]: ""
    }));
  };

  const checkLoginStatus = () => {
    const token = localStorage.getItem('token');
    setIsLoggedIn(!!token);
  };

  useEffect(() => {
    checkLoginStatus();
    fetchCalendarEdit();
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (months.length > 0) {
      const defaultMonth = months[0];
      fetchHolidays(defaultMonth.year, defaultMonth.month);
    }
  }, [months]);

  useEffect(() => {
    if (year && month) {
      const days = new Date(year, month, 0).getDate();
      setDaysInMonth(Array.from({ length: days }, (_, i) => i + 1));
      fetchHolidays(year, month);
    }
  }, [year, month]);

  useEffect(() => {
    if (startYearMonth) {
      fetchHolidays(startYearMonth.getFullYear(), startYearMonth.getMonth() + 1);
    }
  }, [startYearMonth]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({ ...prevState, [name]: value }));
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('token');
      const response = await axios.put(
        `${apipath}/calendar/details/${calendarId}`,
        formData,
        {
          headers: { 'Authorization': `Bearer ${token}` }
        }
      );

      if (response.status === 200) {
        iziToast.success({ message: "Event update successful", position: "topCenter" });
      } else {
        iziToast.error({ message: "Event update failed", position: "topCenter" });
      }
    } catch (error) {
      console.error("Error updating event details:", error);
      iziToast.error({ message: "An error occurred", position: "topCenter" });
    }
  };

  const fetchCalendarEdit = async () => {
    try {
      const response = await axios.get(`${apipath}/calendar/details/${calendarId}`);
      const calendarData = response.data.calendar;
      setFormData(calendarData);
      const startYearMonth = new Date(calendarData.startYearMonth);
      setStartYearMonth(startYearMonth);
      generateMonths(startYearMonth);
      setYear(startYearMonth.getFullYear());
      setMonth(startYearMonth.getMonth() + 1);
    } catch (error) {
      console.error("Error fetching calendar details:", error);
    }
  };

  const generateMonths = (startDate) => {
    const generatedMonths = [];
    for (let i = 0; i < 12; i++) {
      const month = (startDate.getMonth() + i) % 12;
      const year = startDate.getFullYear() + Math.floor((startDate.getMonth() + i) / 12);
      generatedMonths.push({ month: monthNames[month], year });
    }
    setMonths(generatedMonths);
    setActiveTab(monthNames[startDate.getMonth()].toLowerCase());
  };

  // const fetchHolidays = async (year, month) => {
  //   try {
  //     const response = await axios.get(
  //       `https://www.googleapis.com/calendar/v3/calendars/en.indian%23holiday%40group.v.calendar.google.com/events?key=AIzaSyAjfBYZVOqpBtwp6Ty55hL9-eXAVD6rKUs`
  //     );
  //     const events = response.data.items;

  //     const holidays = events
  //       .map(event => ({
  //         summary: event.summary,
  //         date: new Date(event.start.date),
  //       }))
  //       .filter(holiday => holiday.date.getFullYear() === year && holiday.date.getMonth() === month - 1);

  //     setHolidayMap(holidays.reduce((map, holiday) => {
  //       const day = holiday.date.getDate();
  //       if (!map[day]) {
  //         map[day] = [];
  //       }
  //       map[day].push(holiday.summary);
  //       return map;
  //     }, {}));
  //     setHolidays(holidays);
  //   } catch (error) {
  //     console.error('Error fetching holidays:', error);
  //   }
  // };

  const fetchHolidays = async (year, month) => {
    try {
      const response = await axios.get(
        `https://www.googleapis.com/calendar/v3/calendars/en.indian%23holiday%40group.v.calendar.google.com/events?key=AIzaSyAjfBYZVOqpBtwp6Ty55hL9-eXAVD6rKUs`
      );
      const events = response.data.items;
  
      // Map and filter holidays based on the correct year and month
      const holidays = events
        .map(event => {
          const date = event.start.date
            ? new Date(event.start.date)
            : new Date(event.start.dateTime); // Handle both date and dateTime
          return {
            summary: event.summary,
            date: date,
          };
        })
        .filter(holiday => holiday.date.getFullYear() === year && holiday.date.getMonth() === month - 1);
  
      // Build a map of holidays by day
      const holidayMap = holidays.reduce((map, holiday) => {
        const day = holiday.date.getDate();
        if (!map[day]) {
          map[day] = [];
        }
        map[day].push(holiday.summary);
        return map;
      }, {});
  
      // Update the state with the filtered holidays and holiday map
      setHolidayMap(holidayMap);
      setHolidays(holidays);
  
      console.log(`Holidays for ${month}/${year}:`, holidays);
    } catch (error) {
      console.error('Error fetching holidays:', error);
    }
  };
  
  useEffect(() => {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();

    setYear(currentYear);
    setMonth(currentMonth + 1);
    setActiveTab(monthNames[currentMonth].toLowerCase());
    generateMonths(new Date(currentYear, currentMonth));
  }, []);

  const monthToNumber = (monthName) => {
    return monthNames.indexOf(monthName) + 1;
  };

  const openTab = (tabName, monthIndex) => {
    setActiveTab(tabName);
    const selectedMonth = months[monthIndex];
    fetchHolidays(selectedMonth.year, monthToNumber(selectedMonth.month));
    setYear(selectedMonth.year);
    setMonth(monthToNumber(selectedMonth.month));
  };

  const renderDateOptions = (monthOffset) => {
    if (!startYearMonth) return null;

    const newDate = new Date(startYearMonth);
    newDate.setMonth(startYearMonth.getMonth() + monthOffset);
    const year = newDate.getFullYear();
    const month = newDate.getMonth();

    const days = getDaysInMonth(year, month);

    return days.map(day => (
      <option key={day} value={day}>
        {day} {holidayMap[day] ? `- ${holidayMap[day].join(', ')}` : ''}
      </option>
    ));
  };

  const getDaysInMonth = (year, month) => {
    return new Array(31).fill('').map((_, index) => new Date(year, month, index + 1))
      .filter(date => date.getMonth() === month)
      .map(date => date.getDate());
  };

  const renderHolidayOptions = () => {
    const rowColors = ["c1", "c2", "c3"];
    let rows = [];
    for (let i = 0; i < holidays.length; i += 2) {
      const colorClass = rowColors[Math.floor(i / 2) % rowColors.length];
      rows.push(
        <div className="holiday-row" key={i}>
          <div className="holiday-item">
            <span className={`holiday-color ${colorClass}`}></span>
            <span>{`${holidays[i].date.getDate()} - ${holidays[i].summary}`}</span>
          </div>
          {holidays[i + 1] && (
            <div className="holiday-item" key={i + 1}>
              <span className={`holiday-color ${colorClass}`}></span>
              <span>{`${holidays[i + 1].date.getDate()} - ${holidays[i + 1].summary}`}</span>
            </div>
          )}
        </div>
      );
    }
    return rows;
  };

  return (
       <>
      <Navbar isLoggedIn={isLoggedIn} />
      <div className="container">
        <div className="col-lg-6 mx-auto image-header">
          <h1>Let’s make your own personal calendar</h1>
          <div className="progress-container">
            <div className="progress-line"></div>
            <div className="circle" id="step1">1</div>
            <div className="circle" id="step2">2</div>
            <div className="circle active" id="step3">3</div>
          </div>
        </div>

        <Modal show={show} onHide={handleClose} centered>
    <Modal.Header closeButton>
        <Modal.Title>Calendar Events</Modal.Title>
    </Modal.Header>
    <Modal.Body>
        <div className="modal-content1">
            {/* <p>I hereby confirm to move to proceed with enter events?</p> */}
            <p>Please make sure that you have added the personalised events (max 4 in each month) for ALL OF THE 12 MONTHS</p>
        </div>
    </Modal.Body>
    <Modal.Footer>
        <button className="modal-footer-btn" onClick={handleConfirm} disabled={isLoading}>
            {isLoading ? "Confirm..." : "Confirm"}
        </button>
        <button className="modal-footer-btn" onClick={handleClose}>
            Cancel
        </button>
    </Modal.Footer>
</Modal>

        <div className="image-content">
          <h3>Add events in your calendar :</h3>
          <div className="row image-row py-3">
            <div className="col-lg-3">
              <div className="col-lg-8">
                <div className="tab">
                  {months.map((month, index) => (
                    <button
                      key={index}
                      className={`tablinks ${activeTab === month.month.toLowerCase() ? "active" : ""}`}
                      onClick={() => openTab(month.month.toLowerCase(), index)}
                    >
                      {month.month}
                      {/* {month.year} */}
                    </button>
                  ))}
                </div>
              </div>
            </div>

            <div className="col-lg-9">
              <div className="holiday">
                <h2>Public holidays this month:</h2>
                <div className="holiday-list d-flex">
                  <div className="holiday-1">
                    {renderHolidayOptions()}
                  </div>
                </div>
              </div>
              {months.map((month, index) => (
                <div
                  key={month.month.toLowerCase()}
                  id={month.month.toLowerCase()}
                  className={`events ${activeTab === month.month.toLowerCase() ? "active" : "d-none"}`}
                >
                  <h4>{month.month}</h4>
                  {[1, 2, 3, 4].map((eventNumber) => (
                    <div key={eventNumber} className="event-card">
                      <h2>Your Event {eventNumber + 0}:</h2>
                      <form onSubmit={handleUpdate}>
                        <div className="row">
                          <div className="col-lg-5">
                            <div className="event-item">
                              <label htmlFor={`${month.month.toLowerCase()}Event${eventNumber}Date`}>
                                Event Date:
                              </label>
                              <select
                                className="form-control"
                                id={`${month.month.toLowerCase()}Event${eventNumber}Date`}
                                name={`${month.month.toLowerCase()}Event${eventNumber}Date`}
                                value={formData[`${month.month.toLowerCase()}Event${eventNumber}Date`] || ""}
                                onChange={handleChange}
                              >
                                <option value="">Select Date</option>
                                {renderDateOptions(index)}
                              </select>
                            </div>
                          </div>
                          <div className="col-lg-5">
                            <div className="event-item">
                              <label htmlFor={`${month.month.toLowerCase()}Event${eventNumber}Color`}>
                                Select Color:
                              </label>
                              <input
                                type="color"
                                name={`${month.month.toLowerCase()}Event${eventNumber}Color`}
                                id={`event${eventNumber}Color`}
                                value={formData[`${month.month.toLowerCase()}Event${eventNumber}Color`] || ""}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="event-item">
                              <label htmlFor={`${month.month.toLowerCase()}Event${eventNumber}Caption`}>
                                Event Caption:
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id={`${month.month.toLowerCase()}Event${eventNumber}Caption`}
                                name={`${month.month.toLowerCase()}Event${eventNumber}Caption`}
                                value={formData[`${month.month.toLowerCase()}Event${eventNumber}Caption`] || ""}
                                onChange={handleChange}
                                maxLength={20}
                              />
                            </div>
                          </div>

                        </div>

                        <div className="event-buttons">
                          <button type="button" className="btn btn-danger b1" onClick={() => handleRemove(month.month.toLowerCase(), eventNumber)}>Remove <i class="bi bi-x-lg"></i></button>
                          <button className="b2" type="submit">Add <i class="bi bi-check2"></i></button>
                        </div>
                      </form>
                    </div>
                  ))}
                </div>
              ))}
              <div className="image-button">
  <button onClick={() => handleShow(calendarId)}>Continue to Next Step</button>
</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Events;
