import React, { useEffect } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";

const Terms = () => {

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "auto",
    });
  }, []);

  return (
    <>
      <Navbar />

      <div className="container privacy">
        <h1>TERMS OF USE</h1>
        <p>By using our service, (herein referred to as "Service") you are deemed to have read and accepted the terms and conditions set forth, hence forming a legal binding agreement between PRESSIdeas Publishing Pvt Ltd., (the "OWNER of" <a href="https://www.giftonly4u.com" className="line-remove" target="_blank" rel="noopener noreferrer">www.giftonly4u.com</a>) pursuant to which you agree to comply with the Terms of Service, and use this website only in a manner consistent with Terms of Service. Should you not agree with any part of this Terms of Service, you are advised not to access this website.</p>

        <h1>Detailed Terms & Conditions</h1>

        <h5>1. Legal Age Requirement</h5>
        <p>There is no legal minimum age requirement to access and browse this website; however, we do advise that anyone below the age of 18 should obtain parental consent in using our services, and for the submission of credit card information when placing an order. We strongly recommend that parents and guardians supervise their children's use of the Website.</p>

        <h5>2. Software License, Copyright & Ownership of Content</h5>
        <p>All Software material, information and content furnished by <a href="https://www.giftonly4u.com" className="line-remove" target="_blank" rel="noopener noreferrer">
  www.giftonly4u.com
</a> are protected by copyright and other applicable and relevant laws. You agree that you will not reproduce, modify, decompile, disassemble or derive the source code for the Software. You will have rights to use this Software under the condition that you do not change the materials or remove any copyright or other proprietary rights notices in the materials. You may not distribute or copy such material without the written permission of the copyright owner.</p>

        <h5>3. Third Party Links</h5>
        <p>We may provide links to third party sites that may interest you. We do not have control over the content or availability of these said sites and are not responsible for any content in the said third party sites. It is your responsibility to read and understand each site's privacy and other policies and terms and conditions of their service. if you have any questions or concerns regarding these third party sites, please contact the respective site's webmaster or site administrator.</p>

        <h5>4. About your Content</h5>
        <p>You may design your Calendar through our Online Designer, using JPG or PNG or any other file format that meets our specifications and necessary requirements. Context of design includes without limitation, photographic images and texts, files, works of authorship, illustration and drawings as part of your use of the Service.</p>
        <p>Under these Terms of Service, you warrant that you will not use any of the services for any fraudulent or illegal purpose, and you may NOT place any of the following material using the Service:</p>

        <p>a. Any material that could infringe on the rights of publicity, privacy or copyrights without the approval and consent of the owner of these rights;</p>
        <p>b. Any material that could infringe any materials that is unlawful, obscene, pornographic, indecent, defamatory, offensive, abusive, threatening, or otherwise inappropriate and harmful to children in any way, or could give rise to any civil or criminal liability under applicable law;</p>
        <p>c. Any material that contains viruses, worms, corrupt files, Trojan horse or other sort of corrupted codes.</p>
        <p>d. Any material that is offensive or promote racism, bigotry or hatred against any group or individual</p>
        <p>While <a href="https://www.giftonly4u.com" className="line-remove" target="_blank" rel="noopener noreferrer">www.giftonly4u.com</a> does not and cannot review all submissions, and is not responsible for their content, <a href="https://www.giftonly4u.com" className="line-remove" target="_blank" rel="noopener noreferrer">www.giftonly4u.com</a> reserves the right to move, refuse to print and delete submissions that, in its sole discretion, is believed to be as described in paragraphs (a) through (d) above, or otherwise unacceptable. You acknowledge and agree that any submission may be published, copied, customized or removed for the purposes of delivering the Services. In addition, you undertake that you will be liable for any action taken against <a href="https://www.giftonly4u.com" className="line-remove" target="_blank" rel="noopener noreferrer">www.giftonly4u.com</a> in relation to such activities and will fully indemnify <a href="https://www.giftonly4u.com" className="line-remove" target="_blank" rel="noopener noreferrer">www.giftonly4u.com</a> against all actions, claims, demands or costs which may arise as a result.</p>
        <p>We do not store your order information such as delivery, payment and billing data, and storage for uploaded files are optional. We recommend that before submitting files to us, you retain a copy for yourself to use for future re-ordering, or in the unlikely event of your digital images being corrupted or lost. You should always retain your own copies of all files uploaded or otherwise supplied to us. <a href="https://www.giftonly4u.com" className="line-remove" target="_blank" rel="noopener noreferrer">www.giftonly4u.com</a> or it's owners has no ownership in any of your calendar content.</p>

        <h5>5. Order Policy</h5>
        <p>Receipt of an electronic order or other form of order confirmation by no means signify <a href="https://www.giftonly4u.com" className="line-remove" target="_blank" rel="noopener noreferrer">www.giftonly4u.com</a>'s acceptance of your order, nor does it constitute confirmation of our offer to sell. <a href="https://www.giftonly4u.com" className="line-remove" target="_blank" rel="noopener noreferrer">www.giftonly4u.com</a> reserves the exclusive right at any time after receipt of your order, to accept or decline your order for any reason. <a href="https://www.giftonly4u.com" className="line-remove" target="_blank" rel="noopener noreferrer">www.giftonly4u.com</a> also reserves the right at any time after receipt of your order and without prior notice to you, to supply less than the quantity you order for any time. Your order is deemed accepted by <a href="https://www.giftonly4u.com" className="line-remove" target="_blank" rel="noopener noreferrer">www.giftonly4u.com</a> upon our delivery of products that you have ordered. We may require additional verifications or information before accepting any order. </p>

        <h5>6. Fee and Payment Terms</h5>
        <p>All prices are in their respective local currencies and payment must accompany each order. There is no charge for browsing or accessing the Site. Prices, products and services offered are subject to change without prior notice from time to time at our discretion upon posting by <a href="https://www.giftonly4u.com" className="line-remove" target="_blank" rel="noopener noreferrer">www.giftonly4u.com</a> on its website(s).  </p>
        <p>You hereby agree to pay all fees and charges incurred in connection with your orders which include without limitation taxes, foreign transaction fee (if applicable), duty imports (if applicable), value-added taxes and customs clearance (if applicable). Payment terms will be via online credit/debit card transactions, PhonePe our official merchant of payments, or unless otherwise stated. We reserve the right to refer to your credit card provider. www.GifyOnly4U.com or it's owners will not be held liable for any losses you may suffer as a result of unauthorized use of your credit card by any other party.</p>
        <p>Failure to submit a valid payment may result in refusal to process the requested service(s). In the case that an order with a paid deposit is processed, we will hold the order for 60 days, or unless otherwise stated. During this period and until the balance amount is paid in full, www.GifyOnly4U.com may send a notice to the customer's email address to inform of their balance due. If the balance due is not paid within the said period, www.GifyOnly4U.com may exercise its right to charge a handling fee and discard any unpaid property. Prices and specifications are subject to change without notice in any circumstances other than where an order has been accepted by us.</p>
        <p>In cases of disputes, you must alert us by writing to our Support Team within 7 days after receiving your credit card statement, if you dispute any of our charges on that statement. If www.GifyOnly4U.com does not receive payment from your credit card issuer, you are obliged to pay all amounts due upon demand by <a href="https://www.giftonly4u.com" className="line-remove" target="_blank" rel="noopener noreferrer">www.giftonly4u.com</a>.</p>

        <h5>7. Delivery of Products</h5>
        <p>All products or prints will be delivered to the delivery address supplied by you during the order process. Time for delivery is an estimate only and it is not a condition of this agreement. Delayed delivery is not a basis for cancellation of the order or a request for compensation. Orders may be refused if we believe that there is any infringement to this Terms of Service, and entirely at the discretion of www.GifyOnly4U.com.</p>

        <h5>8. Force Majeure</h5>
        <p>A party will not be liable for non-performance or delay in performance caused by any extraordinary event or circumstance beyond the control of such party including, without limitation, wars, strikes, riots, hostilities, revolutions, national emergency, explosion, civil commotion, embargo, fire, flood, earthquake, volcanic eruption, and force of nature or any "acts of God".</p>

        <h5>9. Satisfaction Guarantee & Product Warranty</h5>
        <p>www.GifyOnly4U.com is proud of the outstanding quality and craftsmanship of our products, and provides 100% Satisfaction Guarantee* against any manufacturing defects. Refunds and cancellation of order is generally not provided except for cases of process or product malfunction (i.e. workmanship defect, calendar arrives damaged, do not match ordered items) www.GifyOnly4U.com reserves the right at any time to substitute/ change brand as well as material used in production of your book without prior notice. In cases of material shortage, we will substitute by using the next best alternative material of the closest quality and specification.</p>
        <p>As the calendars are assembled by hand, the end result may differ from our website images. www.GifyOnly4U.com also reserves the right to change or alter any production methods without prior notice. These may or may not result in a slight difference in the final outlook or slight color shifts, while maintaining the same quality and standards.</p>
        <p>IMPORTANT: We do NOT accept any amendments or changes to be made to the file ONCE the file is already uploaded and approved. Therefore, it is solely the responsibility of the customer to check thoroughly before ordering their project(s).</p>
        <p>www.GifyOnly4U.com is not responsible for customer-generated mistakes, errors or defects including spelling, typographical or grammar errors, poor image quality derived from low-resolution images, order quantity or other ordering errors. Please be sure to preview your books carefully before placing your order. Images and text will be printed as they appear in the Preview. We do not proofread for, or correct, any such customer generated mistakes.</p>
        <p>To file a complaint, kindly contact our customer service by writing to us together with picture of problem/areas of dissatisfaction. We will assess the condition of the item(s), and at our sole discretion to either repair or replace the product(s) that does not meet the warranty or condition. All complaints on defective product(s) must be submitted within 30 days upon receiving your product(s).</p>

        <h5>10. Acceptance of Agreement</h5>
        <p>YOU ACKNOWLEDGE THAT YOU HAVE READ THIS AGREEMENT AND AGREE TO ALL ITS TERMS AND CONDITIONS. www.GifyOnly4U.com reserves the right, at our sole discretion, to update or make modifications to this Terms of Service from time to time without prior notice. The date of modification will be reflected at the top of this page. If you have a question regarding our Terms of Service, please contact us through our Support page.</p>

        <h5>11. Copyright Information</h5>
        <p>Copyright © 2024. All Rights Reserved. The logos and trademarks displayed on the Website and/or products are the sole property of www.GifyOnly4U.com. Usage of these marks is strictly prohibited.</p>

      </div>
      <Footer />
    </>
  );
};

export default Terms;
