import React, { useEffect, useState } from 'react'
import AdminSidebar from './AdminSidebar'
import iziToast from "izitoast";
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../AuthContext';

const FAQsEdit = () => {

    const { faqsId } = useParams();
    const navigate = useNavigate();   
     const { apipath } = useAuth();
    const [faqsEdit, setFAQsEdit] = useState({
        heading: "",
        text: "",
    });

    useEffect(() => {
        fetchFAQsEdit();
    }, []);

    const fetchFAQsEdit = async () => {
        try {
            const response = await axios.get(
                apipath + `/faqs/details/${faqsId}`
            );
            setFAQsEdit(response.data.faqs);
        } catch (error) {
            console.error("Error fetching Navbar Details:", error);
        }
    };

    const handleUpdate = async (e) => {
        e.preventDefault();
        try {
            await axios.put(
                apipath + `/faqs/details/${faqsId}`,
                faqsEdit
            );
            iziToast.success({
                message: "FAQs update successful",
                position: "topCenter",
            });
            navigate("/faqsDetails");
        } catch (error) {
            console.error("Error updating Footer Details details:", error);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFAQsEdit((prevDetails) => ({
            ...prevDetails,
            [name]: value,
        }));
    };

    return (
        <>
            <AdminSidebar />
            <div className="home">
                <div class="toggle-sidebar" style={{ display: "none" }}>
                    <i class="bi bi-menu"></i>
                </div>
                <div className="dashboard">
                    <div className="dashboard-header">
                        <h1> Add FAQs</h1>
                    </div>
                    <div className="container-fluid mt-3">
                        <form className="row login-form" onSubmit={handleUpdate}>
                            <div className="mb-3 col-lg-8">
                                <label htmlFor="faqsHeading" className='mb-2'>FAQs Heading</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter FAQs Heading"
                                    name="heading"
                                    value={faqsEdit.heading}
                                    onChange={handleChange}
                                    maxLength="100"
                                    required
                                />
                            </div>
                            <div className="mb-3 col-lg-8">
                                <label htmlFor="faqsText" className='mb-2'>FAQs Key</label>
                                <textarea
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter FAQs Text"
                                    name="text"
                                    value={faqsEdit.text}
                                    onChange={handleChange}
                                    maxLength="1000"
                                    required
                                />
                            </div>
                            <div className="submit-box pt-4">
                                <button className="btn btn-primary" type="submit">Update FAQs</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FAQsEdit;