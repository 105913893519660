

// import React, { useEffect, useState } from "react";
// import Navbar from "../components/Navbar";
// import Footer from "../components/Footer";
// import FileUpload from "../components/FileUpload";
// import { useNavigate, useParams } from "react-router-dom";
// import iziToast from "izitoast";
// import axios from "axios";
// import { useAuth } from "../AuthContext";

// const months = [
//   "january", "february", "march", "april", "may", "june",
//   "july", "august", "september", "october", "november", "december"
// ];

// const initialState = months.reduce((state, month) => {
//   state[month] = month.charAt(0).toUpperCase() + month.slice(1);
//   state[`${month}Pic`] = null;
//   for (let i = 1; i <= 4; i++) {
//     state[`${month}Event${i}Date`] = "";
//     state[`${month}Event${i}Caption`] = "";
//     state[`${month}Event${i}Color`] = "";
//   }
//   return state;
// }, { startYearMonth: "" });

// const ImageEdit = () => {
//   const navigate = useNavigate();
//   const [isLoggedIn, setIsLoggedIn] = useState(false);
//   const [loader, setLoader] = useState(false);
  
//   // Function to check if user is logged in
//   const checkLoginStatus = () => {
//    const token = localStorage.getItem('token');
//    setIsLoggedIn(!!token); // Set login status based on token presence
// };

// useEffect(() => {
//    checkLoginStatus(); // Check login status on component mount
// }, []);

//   const { filepath,apipath } = useAuth();
//   useEffect(() => {
//     window.scrollTo({
//       top: 0,
//       left: 0,
//       behavior: "auto",
//     });
//   }, []);

//   const [formData, setFormData] = useState({
//     january: 'January',
//     february: 'February',
//     march: 'March',
//     april: 'April',
//     may: 'May',
//     june: 'June',
//     july: 'July',
//     august: 'August',
//     september: 'September',
//     october: 'October',
//     november: 'November',
//     december: 'December',
//     januaryPic: null,
//     februaryPic: null,
//     marchPic: null,
//     aprilPic: null,
//     mayPic: null,
//     junePic: null,
//     julyPic: null,
//     augustPic: null,
//     septemberPic: null,
//     octoberPic: null,
//     novemberPic: null,
//     decemberPic: null,
//     januaryEvent1Date: "",
//     januaryEvent1Caption: "",
//     januaryEvent1Color: "",
//     januaryEvent2Date: "",
//     januaryEvent2Caption: "",
//     januaryEvent2Color: "",
//     januaryEvent3Date: "",
//     januaryEvent3Caption: "",
//     januaryEvent3Color: "",
//     januaryEvent4Date: "",
//     januaryEvent4Caption: "",
//     januaryEvent4Color: "",
//     februaryEvent1Date: "",
//     februaryEvent1Caption: "",
//     februaryEvent1Color: "",
//     februaryEvent2Date: "",
//     februaryEvent2Caption: "",
//     februaryEvent2Color: "",
//     februaryEvent3Caption: "",
//     februaryEvent3Color: "",
//     februaryEvent3Date: "",
//     februaryEvent4Caption: "",
//     februaryEvent4Color: "",
//     februaryEvent4Date: "",
//     marchEvent1Date:"",
//     marchEvent1Caption: "",
//     marchEvent1Color: "",
//     marchEvent2Date:"",
//     marchEvent2Caption: "",
//     marchEvent2Color: "",
//     marchEvent3Date:"",
//     marchEvent3Caption: "",
//     marchEvent3Color: "",
//     marchEvent4Date:"",
//     marchEvent4Caption: "",
//     marchEvent4Color: "",
//     aprilEvent1Date: "",
//     aprilEvent1Caption: "",
//     aprilEvent1Color: "",
//     aprilEvent2Date: "",
//     aprilEvent2Caption: "",
//     aprilEvent2Color: "",
//     aprilEvent3Date: "",
//     aprilEvent3Caption: "",
//     aprilEvent3Color: "",
//     aprilEvent4Date: "",
//     aprilEvent4Caption: "",
//     aprilEvent4Color: "",
//     mayEvent1Date: "",
//     mayEvent1Caption: "",
//     mayEvent1Color: "",
//     mayEvent2Date: "",
//     mayEvent2Caption: "",
//     mayEvent2Color: "",
//     mayEvent3Date: "",
//     mayEvent3Caption: "",
//     mayEvent3Color: "",
//     mayEvent4Date: "",
//     mayEvent4Caption: "",
//     mayEvent4Color: "",
//     juneEvent1Date: "",
//     juneEvent1Caption: "",
//     juneEvent1Color: "",
//     juneEvent2Date: "",
//     juneEvent2Caption: "",
//     juneEvent2Color: "",
//     juneEvent3Date: "",
//     juneEvent3Caption: "",
//     juneEvent3Color: "",
//     juneEvent4Date: "",
//     juneEvent4Caption: "",
//     juneEvent4Color: "",
//     julyEvent1Date: "",
//     julyEvent1Caption: "",
//     julyEvent1Color: "",
//     julyEvent2Date: "",
//     julyEvent2Caption: "",
//     julyEvent2Color: "",
//     julyEvent3Date: "",
//     julyEvent3Caption: "",
//     julyEvent3Color: "",
//     julyEvent4Date: "",
//     julyEvent4Caption: "",
//     julyEvent4Color: "",
//     augustEvent1Date: "",
//     augustEvent1Caption: "",
//     augustEvent1Color: "",
//     augustEvent2Date: "",
//     augustEvent2Caption: "",
//     augustEvent2Color: "",
//     augustEvent3Date: "",
//     augustEvent3Caption: "",
//     augustEvent3Color: "",
//     augustEvent4Date: "",
//     augustEvent4Caption: "",
//     augustEvent4Color: "",
//     septemberEvent1Date: "",
//     septemberEvent1Caption: "",
//     septemberEvent1Color: "",
//     septemberEvent2Date: "",
//     septemberEvent2Caption: "",
//     septemberEvent2Color: "",
//     septemberEvent3Date: "",
//     septemberEvent3Caption: "",
//     septemberEvent3Color: "",
//     septemberEvent4Date: "",
//     septemberEvent4Caption: "",
//     septemberEvent4Color: "",
//     octoberEvent1Date: "",
//     octoberEvent1Caption: "",
//     octoberEvent1Color: "",
//     octoberEvent2Date: "",
//     octoberEvent2Caption: "",
//     octoberEvent2Color: "",
//     octoberEvent3Date: "",
//     octoberEvent3Caption: "",
//     octoberEvent3Color: "",
//     octoberEvent4Date: "",
//     octoberEvent4Caption: "",
//     octoberEvent4Color: "",
//     novemberEvent1Date: "",
//     novemberEvent1Caption: "",
//     novemberEvent1Color: "",
//     novemberEvent2Date: "",
//     novemberEvent2Caption: "",
//     novemberEvent2Color: "",
//     novemberEvent3Date: "",
//     novemberEvent3Caption: "",
//     novemberEvent3Color: "",
//     novemberEvent4Date: "",
//     novemberEvent4Caption: "",
//     novemberEvent4Color: "",
//     decemberEvent1Date: "",
//     decemberEvent1Caption: "",
//     decemberEvent1Color: "",
//     decemberEvent2Date: "",
//     decemberEvent2Caption: "",
//     decemberEvent2Color: "",
//     decemberEvent3Date: "",
//     decemberEvent3Caption: "",
//     decemberEvent3Color: "",
//     decemberEvent4Date: "",
//     decemberEvent4Caption: "",
//     decemberEvent4Color: "",
//     startYearMonth: "",
//     januaryViewType: "",
//     februaryViewType: "",
//     marchViewType: "",
//     aprilViewType: "",
//     mayViewType: "",
//     juneViewType: "",
//     julyViewType: "",
//     augustViewType: "",
//     septemberViewType: "",
//     octoberViewType: "",
//     novemberViewType: "",
//     decemberViewType: ""
    
//   });

//   const token = localStorage.getItem("token");
//   // const [formData, setFormData] = useState(initialState);
//   // const { filepath, apipath } = useAuth();
//   const { calendarId } = useParams();

//   useEffect(() => {
//     setIsLoggedIn(!!token);
//   }, [token]);

//   useEffect(() => {
//     window.scrollTo({ top: 0, left: 0, behavior: "auto" });
//     fetchCalendarEdit();
//   }, []);

//   // const handleFileSelect = (month, file) => {
//   //   setFormData(prevState => ({ ...prevState, [`${month}Pic`]: file }));
//   // };

//   const handleFileSelect = (month, file) => {
//     // Check if the file is an image and validate its size
//     const allowedTypes = ['image/jpeg', 'image/png', 'image/gif'];
//     if (!allowedTypes.includes(file.type)) {
//       iziToast.error({
//         message: 'Please upload a valid image file (JPG, PNG, GIF)',
//         position: 'topCenter',
//       });
//       return;
//     }
//     if (file.size > 5 * 1024 * 1024) { // 5MB
//       iziToast.error({
//         message: 'File size should not exceed 5 MB',
//         position: 'topCenter',
//       });
//       return;
//     }

//     const reader = new FileReader();
//     reader.readAsDataURL(file);
//     reader.onload = (event) => {
//       const img = new window.Image(); // Explicitly reference window.Image
//       img.src = event.target.result;
//       img.onload = () => {
//         const width = img.width;
//         const height = img.height;
//         let viewType = "";

//         if (width > height) {
//           viewType = "Horizontal";
//         } else if (width < height) {
//           viewType = "Vertical";
//         } else {
//           viewType = "Square";
//         }

//         setFormData((prevState) => ({
//           ...prevState,
//           [`${month}Pic`]: file,
//           [`${month}ViewType`]: viewType,
//         }));
//       };
//     };
//   };

//   const fetchCalendarEdit = async () => {
//     try {
//       const response = await axios.get(`${apipath}/calendar/details/${calendarId}`);
//       setFormData(response.data.calendar);
//     } catch (error) {
//       console.error("Error fetching calendar details:", error);
//     }
//   };

//   const handleUpdate = async (e) => {
//     e.preventDefault();
//     const formdata = new FormData();
//     Object.keys(formData).forEach(key => formdata.append(key, formData[key]));

//     try {
//       const response = await axios.put(`${apipath}/calendar/details/${calendarId}`, formdata, {
//         headers: { Authorization: `Bearer ${token}` }
//       });
//       if (response.status === 200) {
//         iziToast.success({ message: "Image update successful", position: "topCenter" });
//         navigate(`/preview/${calendarId}`);
//       } else {
//         iziToast.error({ message: "Image update failed", position: "topCenter" });
//       }
//     } catch (error) {
//       console.error("Error updating image details:", error);
//       iziToast.error({ message: "An error occurred", position: "topCenter" });
//     }
//   };

//   return (
//     <>
//       <Navbar isLoggedIn={isLoggedIn} />
//       <div className="container">
//         <div className="col-lg-6 mx-auto image-header">
//           <h1>Let’s Make your own personal Calendar</h1>
//           <div className="progress-container">
//             <div className="progress-line"></div>
//             <div className="circle active" id="step1">1</div>
//             <div className="circle" id="step2">2</div>
//             <div className="circle" id="step3">3</div>
//           </div>
//         </div>
//         <div className="image-content">
//           <h3>Upload Your Images :</h3>
//           <p className="note">(Ideal image size for landscape : 1920*450px and for portrait : 650*450px)</p>
//           <form onSubmit={handleUpdate} className="row image-row py-3">
//             {months.map(month => (
//               <div className="col-lg-3" key={month}>
//                 <div className="img-input">
//                   <FileUpload
//                     onFileSelect={file => handleFileSelect(month, file)}
//                     currentImage={
//                       formData[`${month}Pic`]
//                         ? typeof formData[`${month}Pic`] === "string"
//                           ? filepath + formData[`${month}Pic`]
//                           : URL.createObjectURL(formData[`${month}Pic`])
//                         : null
//                     }
//                   />
//                   <h6>{month.charAt(0).toUpperCase() + month.slice(1)}</h6>
//                 </div>
//               </div>
//             ))}
//             <div className="image-button col-lg-12">
//               <button type="submit" onClick={() => setLoader(!loader)}>
//                 Continue to Next Step
//               </button>
//             </div>
//             {loader && (
//               <div className="col-lg-12 loader-c">
//                 <div className="loader"></div>
//               </div>
//             )}
//           </form>
//         </div>
//       </div>
//       <Footer />
//     </>
//   );
// };

// export default ImageEdit;


import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import FileUpload from "../components/FileUpload";
import { useNavigate, useParams } from "react-router-dom";
import iziToast from "izitoast";
import axios from "axios";
import { useAuth } from "../AuthContext";

const months = [
  "january", "february", "march", "april", "may", "june",
  "july", "august", "september", "october", "november", "december"
];

const initialState = months.reduce((state, month) => {
  state[month] = month.charAt(0).toUpperCase() + month.slice(1);
  state[`${month}Pic`] = null;
  for (let i = 1; i <= 4; i++) {
    state[`${month}Event${i}Date`] = "";
    state[`${month}Event${i}Caption`] = "";
    state[`${month}Event${i}Color`] = "";
  }
  state.errors = {};
  return state;
}, { startYearMonth: "" });

const ImageEdit = () => {
   const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loader, setLoader] = useState(false);

  // Function to check if user is logged in
  const checkLoginStatus = () => {
    const token = localStorage.getItem('token');
    setIsLoggedIn(!!token); // Set login status based on token presence
  };

  useEffect(() => {
    checkLoginStatus(); // Check login status on component mount
  }, []);

  const { filepath, apipath } = useAuth();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "auto",
    });
  }, []);

  const [formData, setFormData] = useState(initialState);
  const { calendarId } = useParams();

  useEffect(() => {
    setIsLoggedIn(!!token);
  }, [token]);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
    fetchCalendarEdit();
  }, []);

  const handleFileSelect = (month, file) => {
    // Check if the file is an image and validate its size
    const allowedTypes = ['image/jpeg', 'image/png', 'image/gif'];
    if (!allowedTypes.includes(file.type)) {
      iziToast.error({
        message: 'Please upload a valid image file (JPG, PNG, GIF)',
        position: 'topCenter',
      });
      return;
    }
    if (file.size > 10 * 1024 * 1024) { // 10MB
      iziToast.error({
        message: 'File size should not exceed 10 MB',
        position: 'topCenter',
      });
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event) => {
      const img = new window.Image(); // Explicitly reference window.Image
      img.src = event.target.result;
      img.onload = () => {
        const width = img.width;
        const height = img.height;
        let viewType = "";

        if (width > height) {
          viewType = "Horizontal";
        } else if (width < height) {
          viewType = "Vertical";
        } else {
          viewType = "Square";
        }

        setFormData((prevState) => ({
          ...prevState,
          [`${month}Pic`]: file,
          [`${month}ViewType`]: viewType,
          errors: { ...prevState.errors, [month]: false }, // Clear error if valid
        }));
      };
    };
  };

  const removeFile = (month) => {
    setFormData((prevState) => ({
      ...prevState,
      [`${month}Pic`]: null,
      [`${month}ViewType`]: "",
      errors: { ...prevState.errors, [month]: true }, // Set error state
    }));
  };
  const fetchCalendarEdit = async () => {
    try {
      const response = await axios.get(`${apipath}/calendar/details/${calendarId}`);
      setFormData((prevState) => ({
        ...response.data.calendar,
        errors: prevState.errors // Preserve error state
      }));
    } catch (error) {
      console.error("Error fetching calendar details:", error);
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();

    // Validate that all image fields are filled
    for (let month of months) {
      if (!formData[`${month}Pic`]) {
        iziToast.error({
          message: `Please upload an image for ${month.charAt(0).toUpperCase() + month.slice(1)}`,
          position: 'topCenter',
        });
        return;
      }
    }

    const formdata = new FormData();
    Object.keys(formData).forEach(key => formdata.append(key, formData[key]));

    try {
      const response = await axios.put(`${apipath}/calendar/details/${calendarId}`, formdata, {
        headers: { Authorization: `Bearer ${token}` }
      });
      if (response.status === 200) {
        iziToast.success({ message: "Image update successful", position: "topCenter" });
        navigate(`/preview/${calendarId}`);
      } else {
        iziToast.error({ message: "Image update failed", position: "topCenter" });
      }
    } catch (error) {
      console.error("Error updating image details:", error);
      iziToast.error({ message: "An error occurred", position: "topCenter" });
    }
  };

  return (
    <>
      <Navbar isLoggedIn={isLoggedIn} />
      <div className="container">
        <div className="col-lg-6 mx-auto image-header">
          <h1>Let’s Make your own personal Calendar</h1>
          <div className="progress-container">
            <div className="progress-line"></div>
            <div className="circle active" id="step1">1</div>
            <div className="circle" id="step2">2</div>
            <div className="circle" id="step3">3</div>
          </div>
        </div>
        <div className="image-content">
          <h3>Upload Your Images :</h3>
          <p className="note">(Ideal image size for landscape : 1920*450px and for portrait : 650*450px)</p>
          <form onSubmit={handleUpdate} className="row image-row py-3">
            {months.map(month => (
              <div className={`col-sm-6 col-md-3 col-lg-3 ${formData.errors[month] ? "error" : ""}`} key={month}>
                <div className="img-input">
                  <FileUpload
                    onFileSelect={file => handleFileSelect(month, file)}
                    currentImage={
                      formData[`${month}Pic`]
                        ? typeof formData[`${month}Pic`] === "string"
                          ? filepath + formData[`${month}Pic`]
                          : URL.createObjectURL(formData[`${month}Pic`])
                        : null
                    }
                  />
                  <h6>{month.charAt(0).toUpperCase() + month.slice(1)}</h6>
                </div>
              </div>
            ))}
            <div className="image-button col-lg-12">
              <button type="submit" onClick={() => setLoader(!loader)}>
                Continue to Next Step
              </button>
            </div>
            {loader && (
              <div className="col-lg-12 loader-c">
                <div className="loader"></div>
              </div>
            )}
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ImageEdit;

