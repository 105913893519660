import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";

const Otp = () => {
  const [otp, setOtp] = useState(Array(6).fill(""));
  const [otpComplete, setOtpComplete] = useState(false);
  const inputRefs = useRef([]);

  const handleKeyUp = (event, index) => {
    const { key, keyCode } = event;
    const newOtp = [...otp];
    const isNumeric = "0123456789".includes(key);

    if (isNumeric) {
      newOtp[index] = key;
      setOtp(newOtp);

      if (index < 5) {
        inputRefs.current[index + 1].focus();
      }
    } else if (keyCode === 8) {
      // Backspace key
      newOtp[index] = "";
      setOtp(newOtp);

      if (index > 0) {
        inputRefs.current[index - 1].focus();
      }
    }

    const otpString = newOtp.join("");
    setOtpComplete(otpString.length === 6);
  };

  console.log(otpComplete);

  return (
    <>
      <div className="container-fluid login">
        <div className="row login-row">
          <div className="col-lg-6">
            <div className="img-login">
              <img src="imgs-calendar/password.png" alt="" />
            </div>
          </div>
          <div className="col-lg-6 login-right">
            <h1>Login using SMS</h1>
            <p>Please enter OTP </p>
            <form action="/" className="pt-4">
              <div className="login-item col-lg-8 mx-auto">
                <label htmlFor="name">Enter OTP </label>
                <div className="otp-input-fields">
                  {otp.map((value, index) => (
                    <input
                      key={index}
                      type="number"
                      className={`otp__digit otp__field__${index + 1}`}
                      value={value}
                      maxLength={1}
                      onKeyUp={(e) => handleKeyUp(e, index)}
                      ref={(el) => (inputRefs.current[index] = el)}
                    />
                  ))}
                </div>
                {/* <div className="result">
                  <p id="_otp" className={otpComplete ? "_ok" : "_notok"}>
                    {otp.join("")}
                  </p>
                </div> */}
              </div>

              <div className="login-button col-lg-8 mx-auto">
                <Link to="/">
                  <button>Verify OTP</button>
                </Link>
              </div>
              <p className="account col-lg-8 mx-auto">
                Don't Have an Account ?{" "}
                <Link to="/register" className="text-decoration-none">
                  <span>Register</span>
                </Link>
              </p>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Otp;
