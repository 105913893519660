import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import FileUpload from "../components/FileUpload";
import { Link, useNavigate, useParams } from "react-router-dom";
import iziToast from "izitoast";
import { useAuth } from "../AuthContext";

const CalendarTypes = () => {
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loader, setLoader] = useState(false);
  const { apipath } = useAuth();

  return (
    <>
      <Navbar isLoggedIn={isLoggedIn} />
      <div className="container">
        <div className="col-lg-6 mx-auto image-header">
          <h1>Let’s start creating a calendar</h1>
        </div>
        <div className="image-content ">
          <div className="image-button col-lg-12 gap-5">
            <Link to="/image-upload" className="text-decoration-none">
              <button className="btn btn-primary mt-3">
                Create your Calendar
              </button>
              <p className="calendar-inches">Calendar Size: 6 x 4.5 inches</p>
            </Link>

            <Link to="/redeem-coupon" className="text-decoration-none">
              <button className="btn btn-primary mt-3">
                Redeem Calendar with COUPON CODE
              </button>
              <p className="calendar-inches">Calendar Size: 9 x 6 inches</p>
            </Link>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default CalendarTypes;
