import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import iziToast from 'izitoast';
import { useAuth } from '../AuthContext';
import $ from 'jquery';
import { Link } from 'react-router-dom';
import SubAdminSidebar from './SubAdminSidebar';

const CompleteCalendarPrint = () => {
  const [completeOrders, setCompleteOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { apipath } = useAuth();
  const [userDetails, setUserDetails] = useState({});
  const [addressDetails, setAddressDetails] = useState(null);
  const [isAddressReady, setIsAddressReady] = useState(false);
  const dataTableRef = useRef();

  const subadminId = localStorage.getItem('subadminId');

  useEffect(() => {
    fetchCompleteOrders();
  }, []);

  useEffect(() => {
    if (!isLoading && completeOrders.length > 0) {
      $(dataTableRef.current).DataTable();
    }
  }, [isLoading, completeOrders]);

  const fetchCompleteOrders = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(apipath + '/order/details');
      const completedOrders = response.data.order.filter(order => order.orderStatus === "Shipping" && order.assignPrinterId === subadminId);

      const userPromises = completedOrders.map(async (order) => {
        const userResponse = await axios.get(`${apipath}/users/details/${order.user_id}`);
        return {
          orderId: order._id,
          userName: userResponse.data.user.name,
          userNumber: userResponse.data.user.phone,
          userId: userResponse.data.user._id,
          userEmail: userResponse.data.user.email
        };
      });

      const users = await Promise.all(userPromises);
      const userDetailsMap = users.reduce((acc, user) => {
        acc[user.orderId] = user;
        return acc;
      }, {});

      setUserDetails(userDetailsMap);
      setCompleteOrders(completedOrders);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching complete orders:', error);
      setIsLoading(false);
    }
  };

  const fetchShippingAndBillingDetails = async (calendarId) => {
    try {
      const calendarResponse = await axios.get(`${apipath}/calendar/details/${calendarId}`);
      const { shipping_id, billing_id } = calendarResponse.data.calendar;

      const [shippingResponse, billingResponse] = await Promise.all([
        axios.get(`${apipath}/shipping/details/${shipping_id}`),
        axios.get(`${apipath}/billing/details/${billing_id}`)
      ]);

      setAddressDetails({
        shippingDetails: shippingResponse.data.shippingInformation,
        billingDetails: billingResponse.data.billingInformation,
        shipmentId: calendarResponse.data.calendar.shipping_id
      });
      setIsAddressReady(true);
    } catch (error) {
      console.error('Error fetching shipping and billing details:', error);
      // iziToast.error({
      //   message: "Failed to load shipping details",
      //   position: "topCenter",
      // });
    }
  };

  const handleShipNow = async (calendarId, order) => {
    setIsLoading(true);
    await fetchShippingAndBillingDetails(calendarId);

    if (!isAddressReady || !addressDetails) {
      // iziToast.error({
      //   message: "Shipping details not ready. Please try again.",
      //   position: "topCenter",
      // });
      console.error("Shipping details not ready. Please try again.")
      setIsLoading(false);
      return;
    }

    try {
      const orderData = {
        order_id: order.orderId,
        order_date: order.orderDate,
        pickup_location: "Primary",
        billing_customer_name: addressDetails.billingDetails.firstName,
        billing_last_name: addressDetails.billingDetails.lastName || "",
        billing_address: addressDetails.billingDetails.address,
        billing_city: addressDetails.billingDetails.city,
        billing_pincode: addressDetails.billingDetails.pinCode,
        billing_state: addressDetails.billingDetails.state,
        billing_country: addressDetails.billingDetails.country,
        billing_email: addressDetails.billingDetails.email,
        billing_phone: addressDetails.billingDetails.phone,
        shipping_is_billing: false,
        shipping_customer_name: addressDetails.shippingDetails.firstName,
        shipping_last_name: addressDetails.shippingDetails.lastName || "",
        shipping_address: addressDetails.shippingDetails.address,
        shipping_city: addressDetails.shippingDetails.city,
        shipping_pincode: addressDetails.shippingDetails.pinCode,
        shipping_state: addressDetails.shippingDetails.state,
        shipping_country: addressDetails.shippingDetails.country,
        shipping_email: addressDetails.shippingDetails.email,
        shipping_phone: addressDetails.shippingDetails.phone,
        order_items: [
          {
            name: "Calendar",
            sku: "SKU001",
            units: 1,
            selling_price: order.totalPrice,
            discount: 0,
            tax: 0,
            hsn: 0
          }
        ],
        payment_method: "Prepaid",
        sub_total: 1,
        length: 1,
        breadth: 1,
        height: 1,
        weight: 0.5,
        shipmentId: addressDetails.shipmentId
      };

      const response = await axios.post(apipath + '/tracking/create-order', orderData);
      if (response.status === 200) {
        iziToast.success({
          message: "Order placed successfully",
          position: "topCenter",
        });

        await axios.put(`${apipath}/order/details/${order._id}`, {
          orderStatus: "Dispatched",
          dispatched: true,
          OntheWay: true
        });
  
        await fetchCompleteOrders();
      }
    } catch (error) {
      console.error('Error creating order:', error);
      iziToast.error({
        message: "Failed to create order",
        position: "topCenter",
      });
    }
    setIsLoading(false);
  };

  return (
    <>
      <SubAdminSidebar />
      <div className="home">
        <div className="toggle-sidebar" style={{ display: "none" }}>
          <i className="bi bi-menu"></i>
        </div>
        <div className="dashboard">
          <div className="dashboard-header">
            <h1>Completed Orders Management</h1>
          </div>

          <div className="container-fluid">
            <div className="row foot-tablerow">
              <div className="col-lg-11 maintable-column mx-auto">
                <div className="container mt-4 overflow-auto">
                  <table id="datatable" className="table" ref={dataTableRef}>
                    <thead>
                      <tr className="tr1">
                        <th>Order S.No.</th>
                        <th>Order Id</th>
                        <th>Date</th>
                        <th>Name</th>
                        <th>Number</th>
                        <th>Email</th>
                        <th>Status</th>
                        <th>Download</th>
                        <th>Shipping</th>
                      </tr>
                    </thead>
                    <tbody>
                      {completeOrders.map((order, index) => {
                        const user = userDetails[order._id] || {};
                        return (
                          <tr key={order._id}>
                            <td className='text-center'>{index + 1}</td>
                            <td>{order.orderId}</td>
                            <td>{new Date(order.orderDate).toLocaleDateString()}</td>
                            <td>{user.userName || 'N/A'}</td>
                            <td>{user.userNumber}</td>
                            <td>{user.userEmail}</td>
                            <td className="success"><span>{order.orderStatus}</span></td>
                            <td>
                              {order.orderType === "user" ? (
                                <Link to={`/adminPrintCalendar/${order.calendar_id}`}>
                                  <i className="bi bi-download"></i>
                                </Link>
                              ) : order.orderType === "corporate" ? (
                                <Link to={`/corporate-calendar-view-download/${order.calendar_id}`}>
                                  <i className="bi bi-download"></i>
                                </Link>
                              ) : null}
                            </td>
                            <td>
                              <button onClick={() => handleShipNow(order.calendar_id, order)}>Ship Now</button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CompleteCalendarPrint;
