import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();

  const userId = localStorage.getItem("userId");

  useEffect(() => {
    if (!userId) {
      console.log("User ID not found. Redirecting to login...");
    }
  }, [userId]);

  const handleProductClick = () => {
    const token = localStorage.getItem("token");

    if (token) {
      navigate(`/calendar-types`);
    } else {
      localStorage.setItem("redirectAfterLogin", `/calendar-types`);
      navigate("/login");
    }
  };

  
  const handleOrderClick = () => {
    const token = localStorage.getItem("token");
    console.log("userId : ", userId);
    if (token && userId) {
      navigate(`/orders/${userId}`);
    } else {
      localStorage.setItem("redirectAfterLogin", `/orders/${userId || ''}`);
      navigate("/login");
    }
  };

  return (
    <>
      <div className="container-fluid footer">
        <div className="container">
          <div className="row footer-row">
            <div className="col-lg-4">
              <div className="footer-content">
                <div className="footer-logo">
                  <img src={`${process.env.PUBLIC_URL}/imgs-calendar/logo1.png`} alt="" />
                </div>
                <p>
                  Treasure good times with a tailored photo calendar{" "}
                </p>

              </div>
            </div>
            <div className="col-lg-2">
              <div className="footer-list">
                <h3>Quick Links</h3>
                <Link to="/" className="text-decoration-none">
  <li>Home</li>
</Link>
<button
  onClick={() => handleProductClick()}
  className="custom-button"
>
  Events
</button>
                <Link to="/calendarPrice" className="text-decoration-none"><li>Pricing</li></Link>
                
              {/* <button
  onClick={() => handleOrderClick()}
  className="custom-button"
>
  Orders
</button> */}
              </div>
            </div>
            <div className="col-lg-3">
              <div className="footer-list">
                <h3>Contact Us</h3>
                {/* <li>
                  <i className="bi bi-geo-alt-fill"></i> 123 Main Street,
                  Delhi
                </li> */}
                <li>
                  <a href="https://www.google.com/maps/search/?api=1&query=Plot+No.442,+Industrial+Area,+Phase+2,+Chandigarh+160002"
                    target="_blank" rel="noopener noreferrer" className="line-removes">
                    <i className="bi bi-geo-alt-fill"></i> Plot No.442, Industrial Area, Phase 2, Chandigarh 160002
                  </a>
                </li>
                <li>
                  <a href="tel:+919216722442" className="line-removes">
                    <i className="bi bi-telephone" ></i> &nbsp; +91-9216722442
                  </a>
                </li>
                <div className="footer-social">
                <Link
                  to="https://www.facebook.com/profile.php?id=61562782207043"
                  target="_blank"
                ><i className="bi bi-facebook"></i></Link>
                  <Link
                  to="https://x.com/GiftOnly4U"
                  target="_blank"
                ><i className="bi bi-twitter"></i></Link>
                  <Link
                  to="https://www.instagram.com/giftonly4u/"
                  target="_blank"
                > <i className="bi bi-instagram"></i></Link>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="footer-list">
                <h3>Policies</h3>
                <Link to="/privacy" className="text-decoration-none"><li>Privacy Policy</li></Link>
                <Link to="/refund" className="text-decoration-none"><li>Refund Policy</li></Link>
                <Link to="/terms" className="text-decoration-none"><li>Terms & Conditions</li></Link>
                <Link to="/shoppingPolicy" className="text-decoration-none"><li>Shopping Policy</li></Link>


              </div>
            </div>
          </div>
          <div className="row footer-row2">
            <div className="col-lg-12">
              <p>Copyrights © 2024 <b>PRESSIdeas, All Right Reserved</b></p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
