import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Link, useParams } from "react-router-dom";
import iziToast from "izitoast";
import axios from "axios";
import { useAuth } from "../AuthContext";

const Preview = () => {
  const { apipath } = useAuth();
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "auto",
    });
  }, []);

  const { calendarId } = useParams();
  const [formData, setFormData] = useState({
    startYearMonth: "",
  });
  const [updateSuccessful, setUpdateSuccessful] = useState(false);

  const token = localStorage.getItem('token');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  useEffect(() => {
    fetchCalendarEdit();
  }, []);

  const fetchCalendarEdit = async () => {
    try {
      const response = await axios.get(
        apipath + `/calendar/details/${calendarId}`
      );
      setFormData({
        ...response.data.calendar,
        startYearMonth: response.data.calendar.startYearMonth ? response.data.calendar.startYearMonth.split('T')[0] : "",
      });
    } catch (error) {
      console.error("Error fetching calendar details:", error);
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    if (!formData.startYearMonth) {
      iziToast.error({
        message: "Please select a start month.",
        position: "topCenter",
      });
      return;
    }
    try {
      const response = await axios.put(
        apipath + `/calendar/details/${calendarId}`,
        formData,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        iziToast.success({
          message: "Year & month add successful",
          position: "topCenter",
        });
        setUpdateSuccessful(true); // Update the state variable to true
      } else {
        iziToast.error({
          message: "Year & month add failed",
          position: "topCenter",
        });
      }
    } catch (error) {
      console.error("Error adding year & month details:", error);
      iziToast.error({
        message: "An error occurred",
        position: "topCenter",
      });
    }
  };

  return (
    <>
      <Navbar />

      <div className="container">
        <div className="col-lg-6 mx-auto image-header">
          <h1>Let’s make your own personal calendar</h1>
          <div className="progress-container">
            <div className="progress-line"></div>
            <div className="circle" id="step1">
              <Link to="/image-upload">1</Link>
            </div>
            <div className="circle active" id="step2">
              <Link to="/events">2</Link>
            </div>
            <div className="circle" id="step3">
              <Link to="/preview">3</Link>
            </div>
          </div>
        </div>
        <div className="image-content">
          <div className="row image-row py-3">
            <div className="col-lg-9 mx-auto">
              <h3>Please select the Month & Year you would like the Calendar to start from :</h3>
              <div className="event-card">
                <form onSubmit={handleUpdate}>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="event-item">
                        <label htmlFor="startYearMonth">Start Month: </label>
                        <input
                          type="month"
                          name="startYearMonth"
                          id="startYearMonth"
                          value={formData.startYearMonth}
                          onChange={handleChange}
                          placeholder="Select the starting month"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="event-buttons">
                    <button className="b2" type="submit">Add</button>
                  </div>
                </form>

              </div>
            </div>
          </div>
        </div>
        <div className="image-button">
          {/* {updateSuccessful && (
            <Link to={`/events/${calendarId}`}>
              <button>Check Preview</button>
            </Link>
          )} */}
          {updateSuccessful && (
            <Link to={`/shipping-billing/${calendarId}`}>
              <button>Check Preview</button>
            </Link>
          )}
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Preview;
