import React, { useEffect, useState } from "react";
import { useAuth } from "../AuthContext";
import axios from "axios";
import SubAdminSidebar from "./SubAdminSidebar";

const SubAdminDashboard = () => {
  const [users, setUsers] = useState([]); 
  const [corporates, setCorporates] = useState([]);
  const [corporateCount, setCorporateCount] = useState(0);
  const [completeOrders, setCompleteOrders] = useState([]);
  const [completedOrderCount, setCompletedOrderCount] = useState(0);
  const [pendingOrderCount, setPendingOrderCount] = useState(0);
  const [shippingOrderCount, setShippingOrderCount] = useState(0);
  const { apipath } = useAuth();
  const subadminId = localStorage.getItem('subadminId');
  
  useEffect(() => {
    fetchUsers();
    fetchCompleteOrders();
    fetchCorporates();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await axios.get(apipath + '/user/details');
      setUsers(response.user);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };
  const fetchCorporates = async () => {
    try {
      const response = await axios.get(apipath + '/corporate/details');
      const coupons  = response.data.corporate.filter(corporate => corporate.assignPrinterId === subadminId);
      // setCorporates(response.data.corporate);
      console.log(response.data.corporate)
      setCorporateCount(coupons.length);
    } catch (error) {
      console.error('Error fetching corporate:', error);
    }
  };

  const fetchCompleteOrders = async () => {
    try {
      const response = await axios.get(apipath + '/order/details');
      const orders  = response.data.order.filter(order => order.orderStatus === "Shipping" && order.assignPrinterId === subadminId);
      // const orders = response.data.order;

      setCompleteOrders(orders);

      // Calculate completed and pending orders
      // const completedOrders = orders.filter(order => order.orderStatus === 'Completed');
      // const pendingOrders = orders.filter(order => order.orderStatus === 'Pending');
      // const shippingOrders = orders.filter(order => order.orderStatus === 'Shipping');

      // setCompletedOrderCount(completedOrders.length);
      // setPendingOrderCount(pendingOrders.length);
      setShippingOrderCount(orders.length);
    } catch (error) {
      console.error('Error fetching orders:', error);
    }
  };

  return (
    <>
      <SubAdminSidebar />

      <div className="home">
        <div className="toggle-sidebar" style={{ display: "none" }}>
          <i className="bi bi-menu"></i>
        </div>
        <div className="dashboard">
          <div className="dashboard-header">
            <h1>Dashboard</h1>
          </div>

          <div className="row dash-row">
            <div className="col-lg-4">
              <div className="dash-card">
                <div className="dash-1">
                  <h3>Corporate coupon Listed</h3>
                </div>
                <div className="dash-2">
                  <div>
                    <h2>{corporateCount}</h2>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="dash-card">
                <div className="dash-1">
                  <h3>Total Shipping Orders</h3>
                </div>
                <div className="dash-2">
                  <div>
                    <h2>{shippingOrderCount}</h2>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="col-lg-4">
              <div className="dash-card">
                <div className="dash-1">
                  <h3>Print User Listed</h3>
                </div>
                <div className="dash-2">
                  <div>
                    <h2>{users.subAdminCount}</h2>
                  </div>
                </div>
              </div>
            </div> */}

            {/* <div className="col-lg-4">
              <div className="dash-card">
                <div className="dash-1">
                  <h3>Total Orders</h3>
                </div>
                <div className="dash-2">
                  <div>
                    <h2>{completeOrders.length}</h2>
                  </div>
                </div>
              </div>
            </div>  */}
            {/* <div className="col-lg-4">
              <div className="dash-card">
                <div className="dash-1">
                  <h3>Total Completed Orders</h3>
                </div>
                <div className="dash-2">
                  <div>
                    <h2>{completedOrderCount}</h2>
                  </div>
                </div>
              </div>
            </div>            */}
          </div>

          <div className="row dash-row2">
          
            {/* <div className="col-lg-4">
              <div className="dash-card">
                <div className="dash-1">
                  <h3>Total Pending Orders</h3>
                </div>
                <div className="dash-2">
                  <div>
                    <h2>{pendingOrderCount}</h2>
                  </div>
                </div>
              </div>
            </div> */}
            
          </div>
        </div>
      </div>
    </>
  );
};

export default SubAdminDashboard;
