import React, { useState } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { useNavigate } from "react-router-dom";
import iziToast from "izitoast";
import { useAuth } from "../AuthContext";
import axios from "axios";

const RedeemCoupon = () => {
  const navigate = useNavigate();
  const { apipath } = useAuth(); // Assuming apipath provides base API URL
  const userId = localStorage.getItem("userId"); // Retrieve user ID from local storage

  const [userDetails, setUserDetails] = useState({
    useCouponCode: "", // Coupon code input
  });
  const [loading, setLoading] = useState(false); // Loader state for form submission

  // Function to handle input field changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserDetails({
      ...userDetails,
      [name]: value,
    });
  };

  // Function to handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true); // Set loading to true while processing

    try {
      // Make a PUT request to apply the coupon code
      const response = await axios.put(
        `${apipath}/users/details/${userId}`, // API URL for user update
        { useCouponCode: userDetails.useCouponCode }, // Send coupon code in request body
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        // Show success message on successful coupon application
        iziToast.success({
          title: "Success",
          message: "Coupon code applied successfully!",
          position: "topCenter",
          timeout: 2000, // 2 seconds
        });
        // Navigate to the coupon image upload page after success
        navigate("/coupon-Image-upload");
      } else {
        // Show error message for any non-200 responses
        throw new Error("Failed to apply coupon code");
      }
    } catch (error) {
      // Handle errors from the API request
      iziToast.error({
        title: "Error",
        message:
          error.response?.data?.message ||
          "Failed to apply coupon code. Please try again.",
        position: "topCenter",
        timeout: 3000, // 3 seconds
      });
    } finally {
      // Set loading to false when request completes
      setLoading(false);
    }
  };

  return (
    <>
      <Navbar /> {/* Navigation bar */}
      <div className="container">
        <div className="col-lg-6 mx-auto image-header">
          <h1>Redeem your coupon code</h1>
        </div>

        <div className="image-content coupon-div">
          <h3>Please enter your coupon code:</h3>
          <form onSubmit={handleSubmit}>
            <div className="row py-3">
              {/* <div className="col-lg-12"> */}
              <div className="col-lg-12">
                <div className="bill-item coupon-field">
                  {/* Input field for coupon code */}
                  <input
                    type="text"
                    placeholder="Coupon code"
                    name="useCouponCode"
                    value={userDetails.useCouponCode}
                    onChange={handleInputChange}
                    maxLength="25"
                    required
                  />
                </div>
              </div>
              {/* </div> */}
            </div>
            <div className="image-button">
              {/* Submit button */}
              <button type="submit" disabled={loading}>
                {loading ? "Applying..." : "Add"} {/* Show loader text */}
              </button>
            </div>
          </form>
        </div>
      </div>
      <Footer /> {/* Footer component */}
    </>
  );
};

export default RedeemCoupon;
