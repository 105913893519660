import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useAuth } from "../AuthContext";
import iziToast from "izitoast";

const PopupForm = ({ isOpen, onClose, selectedDate, monthName }) => {
  const { apipath } = useAuth();
  const [formData, setFormData] = useState({
    Date: selectedDate || "",
    Caption: "",
  });
  const [isExistingEvent, setIsExistingEvent] = useState(false);

  const { calendarId } = useParams();

  // Update form data when selectedDate changes
  useEffect(() => {
    if (selectedDate) {
      setFormData((prev) => ({ ...prev, Date: selectedDate }));
      fetchEventDetails(selectedDate); // Fetch event details if the date is selected
    }
  }, [selectedDate]);

  // Function to fetch existing event details for the selected date
  const fetchEventDetails = async (selectedDate) => {
    try {
      const token = localStorage.getItem("token");

      // Fetch calendar data
      const response = await axios.get(
        `${apipath}/calendar/details/${calendarId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      const calendar = response.data.calendar;
      let eventFound = false;

      // Loop through 4 possible event slots and check if any matches the selected date
      for (let i = 1; i <= 4; i++) {
        const eventDateKey = `${monthName}Event${i}Date`;
        const eventCaptionKey = `${monthName}Event${i}Caption`;

        if (calendar[eventDateKey] === selectedDate) {
          // If an event is found for the selected date, populate the form
          setFormData({
            Date: calendar[eventDateKey],
            Caption: calendar[eventCaptionKey],
          });
          setIsExistingEvent(true); // Mark that this is an existing event
          eventFound = true;
          break;
        }
      }

      // If no event found, reset the form for a new event
      if (!eventFound) {
        setFormData({
          Date: selectedDate,
          Caption: "",
        });
        setIsExistingEvent(false);
      }
    } catch (error) {
      console.error("Error fetching calendar events:", error);
      iziToast.error({
        title: "Error",
        message: "Failed to fetch event details. Please try again.",
      });
    }
  };

  const handleRemove = async () => {
    try {
      const token = localStorage.getItem("token");

      // Fetch the latest calendar data
      const response = await axios.get(
        `${apipath}/calendar/details/${calendarId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      let calendar = response.data.calendar;
      let eventSlotFound = false;

      // Loop through the 4 possible event slots to find and remove the event for the selected date
      for (let i = 1; i <= 4; i++) {
        const eventDateKey = `${monthName}Event${i}Date`;
        const eventCaptionKey = `${monthName}Event${i}Caption`;

        if (calendar[eventDateKey] === formData.Date) {
          // Clear the event data for the selected date
          calendar[eventDateKey] = "";
          calendar[eventCaptionKey] = "";

          // Save the updated data to the backend
          await axios.put(
            `${apipath}/calendar/details/${calendarId}`,
            calendar,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );

          iziToast.success({
            title: "Event Removed",
            message: "Event removed successfully!",
            position: "topCenter",
          });

          eventSlotFound = true;
          break;
        }
      }
      setTimeout(() => {
        window.location.reload();
      }, 1500);
      if (!eventSlotFound) {
        iziToast.error({
          title: "Error",
          message: "Event not found for this date.",
          position: "topCenter",
        });
      }

      // Reset form fields
      setFormData({
        Date: " ",
        Caption: "",
      });

      // Close the modal after removal
      onClose();
    } catch (error) {
      console.error("Error removing event:", error);
      iziToast.error({
        title: "Error",
        message: "Failed to remove event. Please try again.",
        position: "topCenter",
      });
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleUpdate = async (e) => {
    e.preventDefault();

    if (!formData.Date || !formData.Caption) {
      iziToast.error({
        title: "Error",
        message: "Please fill out all fields!",
        position: "topCenter",
      });
      return;
    }

    try {
      const token = localStorage.getItem("token");

      // Fetch the latest calendar data
      const response = await axios.get(
        `${apipath}/calendar/details/${calendarId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      let calendar = response.data.calendar;
      let eventSlotFound = false;

      // Loop through the 4 possible event slots
      for (let i = 1; i <= 4; i++) {
        const eventDateKey = `${monthName}Event${i}Date`;
        const eventCaptionKey = `${monthName}Event${i}Caption`;

        // If the selected date is the same as an existing event, update it
        if (
          calendar[eventDateKey] === formData.Date ||
          (calendar[eventDateKey] === "" && calendar[eventCaptionKey] === "")
        ) {
          calendar[eventDateKey] = formData.Date;
          calendar[eventCaptionKey] = formData.Caption;

          // Save the updated data to the backend
          await axios.put(
            `${apipath}/calendar/details/${calendarId}`,
            calendar,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );

          iziToast.success({
            title: "Success",
            message: isExistingEvent
              ? "Event updated successfully!"
              : "Event added successfully!",
            position: "topCenter",
          });
          setTimeout(() => {
            window.location.reload();
          }, 1500);

          eventSlotFound = true;
          break;
        }
      }

      // If no slot was found
      if (!eventSlotFound) {
        iziToast.warning({
          title: "Limit Reached",
          message: "You can only add up to 4 events per month.",
          position: "topCenter",
        });
      }

      // Close the modal
      onClose();
    } catch (error) {
      console.error("Error updating calendar events:", error);
      iziToast.error({
        title: "Error",
        message: "Failed to update calendar events. Please try again.",
        position: "topCenter",
      });
    }
  };

  if (!isOpen) return null;

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <div className="iv">
          <h3>
            {isExistingEvent ? "Edit Event" : "Add Event"} for {monthName}
          </h3>
          <i class="bi bi-x-square" onClick={onClose}></i>
        </div>
        {/* <form onSubmit={handleUpdate}> */}
        <div className="form-group">
          <label>Select Event Date</label>
          <input
            type="text"
            name="Date"
            value={formData.Date}
            onChange={handleInputChange}
            required
            disabled
          />
        </div>

        <div className="form-group">
          <label>Event Caption</label>
          <input
            type="text"
            name="Caption"
            value={formData.Caption}
            onChange={handleInputChange}
            maxLength={22}
            required
          />
        </div>
        <div className="bt-text">
          <button className="btn-secondary" onClick={handleUpdate}>
            {isExistingEvent ? "Update Event" : "Add Event"}
          </button>
          <button className="btn-secondary" onClick={handleRemove}>
            Remove
          </button>
        </div>
        {/* </form> */}
      </div>
    </div>
  );
};

export default PopupForm;
