// // // // // import React, { useEffect, useState } from "react";
// // // // // import Navbar from "../components/Navbar";
// // // // // import Footer from "../components/Footer";
// // // // // import { useNavigate, useParams } from "react-router-dom";
// // // // // import iziToast from "izitoast";

// // // // // const Billing = () => {
// // // // //   const { calendarId } = useParams();
// // // // //   const { savedOrderId } = useParams();

// // // // //   const navigate = useNavigate();

// // // // //   const [formData, setFormData] = useState({
// // // // //     firstName: "",
// // // // //     lastName: "",
// // // // //     phone: "",
// // // // //     email: "",
// // // // //     address: "",
// // // // //     city: "",
// // // // //     state: "",
// // // // //     country: "",
// // // // //     pinCode: "",
// // // // //     paymentMethod: "UPI",
// // // // //     additionalInformation: "",
// // // // //     calendar_id: calendarId || "",
// // // // //   });

// // // // //   const [orderData, setOrderData] = useState({
// // // // //     qty: 1,
// // // // //     orderStatus: "Pending",
// // // // //     paymentStatus: "Pending",
// // // // //     orderAmount: 500,
// // // // //     shippingPrice: 100,
// // // // //     totalPrice: 600,
// // // // //     orderId: "",
// // // // //     orderConfirmed: false,
// // // // //     dispatched: false,
// // // // //     OntheWay: false,
// // // // //     pickup: false,
// // // // //     calendar_id: calendarId || "",
// // // // //   });

// // // // //   useEffect(() => {
// // // // //     window.scrollTo({
// // // // //       top: 0,
// // // // //       left: 0,
// // // // //       behavior: "auto",
// // // // //     });
// // // // //   }, []);

// // // // //   const token = localStorage.getItem('token');

// // // // //   const handleInputChange = (e) => {
// // // // //     const { name, value } = e.target;
// // // // //     setFormData({
// // // // //       ...formData,
// // // // //       [name]: value,
// // // // //     });
// // // // //   };

// // // // //   const handleRegister = async (e) => {
// // // // //     e.preventDefault();
// // // // //     try {
// // // // //       const billingResponse = await fetch('https://giftonly4u.com:4000/api/v1/billing/add', {
// // // // //         method: 'POST',
// // // // //         headers: {
// // // // //           'Content-Type': 'application/json',
// // // // //           'Authorization': `Bearer ${token}`,
// // // // //         },
// // // // //         body: JSON.stringify(formData),
// // // // //       });

// // // // //       if (billingResponse.status === 201) {
// // // // //         iziToast.success({
// // // // //           message: 'Billing information added successfully',
// // // // //           position: 'topCenter',
// // // // //         });
// // // // //         await handleOrderAdd(); // Call handleOrderAdd after successful billing info addition
// // // // //         navigate(`/bill-summary/${calendarId}/${savedOrderId}`);
// // // // //       } else {
// // // // //         iziToast.error({
// // // // //           message: 'Billing information addition failed',
// // // // //           position: 'topCenter',
// // // // //         });
// // // // //       }
// // // // //     } catch (error) {
// // // // //       console.error('Error adding billing information:', error);
// // // // //       iziToast.error({
// // // // //         message: 'An error occurred',
// // // // //         position: 'topCenter',
// // // // //       });
// // // // //     }
// // // // //   };

// // // // //   const handleOrderAdd = async () => {
// // // // //     try {
// // // // //       const response = await fetch('https://giftonly4u.com:4000/api/v1/order/add', {
// // // // //         method: 'POST',
// // // // //         headers: {
// // // // //           'Content-Type': 'application/json',
// // // // //           'Authorization': `Bearer ${token}`,
// // // // //         },
// // // // //         body: JSON.stringify(orderData),
// // // // //       });

// // // // //       const responseData = await response.json();
// // // // //         const savedOrderId = responseData.order._id;  // Extract calendarId from the response
// // // // //         console.log("savedOrderId: ",savedOrderId);


// // // // //       if (response.status !== 201) {
// // // // //         iziToast.error({
// // // // //           message: 'Order addition failed',
// // // // //           position: 'topCenter',
// // // // //         });
// // // // //       }
// // // // //     } catch (error) {
// // // // //       console.error('Error adding order:', error);
// // // // //     }
// // // // //   };

// // // // //   return (
// // // // //     <>
// // // // //       <Navbar />
// // // // //       <div className="container">
// // // // //         <div className="col-lg-6 mx-auto image-header">
// // // // //           <h1>Billing Information</h1>
// // // // //         </div>
// // // // //         <div className="image-content">
// // // // //           <h3>Please enter your details :</h3>
// // // // //           <form onSubmit={handleRegister}>
// // // // //             <div className="row bill-row py-3">
// // // // //               <div className="col-lg-6">
// // // // //                 <div className="bill-item">
// // // // //                   <label htmlFor="">First Name</label>
// // // // //                   <input
// // // // //                     type="text"
// // // // //                     placeholder="First Name"
// // // // //                     name="firstName"
// // // // //                     value={formData.firstName}
// // // // //                     onChange={handleInputChange}
// // // // //                     maxLength="100"
// // // // //                     required
// // // // //                   />
// // // // //                 </div>
// // // // //               </div>
// // // // //               <div className="col-lg-6">
// // // // //                 <div className="bill-item">
// // // // //                   <label htmlFor="">Last Name</label>
// // // // //                   <input
// // // // //                     type="text"
// // // // //                     placeholder="Last Name"
// // // // //                     name="lastName"
// // // // //                     value={formData.lastName}
// // // // //                     onChange={handleInputChange}
// // // // //                     maxLength="100"
// // // // //                     required
// // // // //                   />
// // // // //                 </div>
// // // // //               </div>
// // // // //               <div className="col-lg-6">
// // // // //                 <div className="bill-item">
// // // // //                   <label htmlFor="">Email</label>
// // // // //                   <input
// // // // //                     type="email"
// // // // //                     placeholder="Enter Email"
// // // // //                     name="email"
// // // // //                     value={formData.email}
// // // // //                     onChange={handleInputChange}
// // // // //                     maxLength="100"
// // // // //                     required
// // // // //                   />
// // // // //                 </div>
// // // // //               </div>
// // // // //               <div className="col-lg-6">
// // // // //                 <div className="bill-item">
// // // // //                   <label htmlFor="">Mobile No.</label>
// // // // //                   <input
// // // // //                     type="text"
// // // // //                     placeholder="Enter Phone Number"
// // // // //                     name="phone"
// // // // //                     value={formData.phone}
// // // // //                     onChange={handleInputChange}
// // // // //                     maxLength="15"
// // // // //                     required
// // // // //                   />
// // // // //                 </div>
// // // // //               </div>
// // // // //               <div className="col-lg-6">
// // // // //                 <div className="bill-item">
// // // // //                   <label htmlFor="">Address</label>
// // // // //                   <input
// // // // //                     type="text"
// // // // //                     placeholder="Address"
// // // // //                     name="address"
// // // // //                     value={formData.address}
// // // // //                     onChange={handleInputChange}
// // // // //                     maxLength="100"
// // // // //                     required
// // // // //                   />
// // // // //                 </div>
// // // // //               </div>
// // // // //               <div className="col-lg-6">
// // // // //                 <div className="bill-item">
// // // // //                   <label htmlFor="">City</label>
// // // // //                   <input
// // // // //                     type="text"
// // // // //                     placeholder="City"
// // // // //                     name="city"
// // // // //                     value={formData.city}
// // // // //                     onChange={handleInputChange}
// // // // //                     maxLength="100"
// // // // //                     required
// // // // //                   />
// // // // //                 </div>
// // // // //               </div>
// // // // //               <div className="col-lg-6">
// // // // //                 <div className="bill-item">
// // // // //                   <label htmlFor="">State</label>
// // // // //                   <input
// // // // //                     type="text"
// // // // //                     placeholder="State"
// // // // //                     name="state"
// // // // //                     value={formData.state}
// // // // //                     onChange={handleInputChange}
// // // // //                     maxLength="100"
// // // // //                     required
// // // // //                   />
// // // // //                 </div>
// // // // //               </div>
// // // // //               <div className="col-lg-6">
// // // // //                 <div className="bill-item">
// // // // //                   <label htmlFor="">Country</label>
// // // // //                   <input
// // // // //                     type="text"
// // // // //                     placeholder="Country"
// // // // //                     name="country"
// // // // //                     value={formData.country}
// // // // //                     onChange={handleInputChange}
// // // // //                     maxLength="100"
// // // // //                     required
// // // // //                   />
// // // // //                 </div>
// // // // //               </div>
// // // // //               <div className="col-lg-6">
// // // // //                 <div className="bill-item">
// // // // //                   <label htmlFor="">Pincode</label>
// // // // //                   <input
// // // // //                     type="text"
// // // // //                     placeholder="Enter your pin code"
// // // // //                     name="pinCode"
// // // // //                     value={formData.pinCode}
// // // // //                     onChange={handleInputChange}
// // // // //                     maxLength="100"
// // // // //                     required
// // // // //                   />
// // // // //                 </div>
// // // // //               </div>
// // // // //               <div className="col-lg-6">
// // // // //                 <div className="bill-item">
// // // // //                   <label htmlFor="">Payment Method</label>
// // // // //                   <input
// // // // //                     type="text"
// // // // //                     placeholder="UPI"
// // // // //                     name="paymentMethod"
// // // // //                     value={formData.paymentMethod}
// // // // //                     onChange={handleInputChange}
// // // // //                     maxLength="100"
// // // // //                     readOnly
// // // // //                   />
// // // // //                 </div>
// // // // //               </div>
// // // // //               <div className="col-lg-12">
// // // // //                 <div className="bill-item">
// // // // //                   <label htmlFor="">Additional Information</label>
// // // // //                   <textarea
// // // // //                     type="text"
// // // // //                     placeholder="Any additional Info"
// // // // //                     name="additionalInformation"
// // // // //                     value={formData.additionalInformation}
// // // // //                     onChange={handleInputChange}
// // // // //                     maxLength="200"
// // // // //                     required
// // // // //                   />
// // // // //                 </div>
// // // // //               </div>
// // // // //               <div className="image-button">
// // // // //                 <button>Continue To Payment</button>
// // // // //               </div>
// // // // //             </div>
// // // // //           </form>
// // // // //         </div>
// // // // //       </div>
// // // // //       <Footer />
// // // // //     </>
// // // // //   );
// // // // // };

// // // // // export default Billing;


// // // // import React, { useEffect, useState } from "react";
// // // // import Navbar from "../components/Navbar";
// // // // import Footer from "../components/Footer";
// // // // import { useNavigate, useParams } from "react-router-dom";
// // // // import iziToast from "izitoast";
// // // // import { useAuth } from "../AuthContext";
// // // // import axios from "axios";

// // // // const Billing = () => {
// // // //   const { calendarId ,billingId} = useParams();
// // // //   const navigate = useNavigate();
// // // //   const { apipath } = useAuth();
// // // //   const [savedOrderId, setSavedOrderId] = useState(null);
// // // //   const [isLoggedIn, setIsLoggedIn] = useState(false);
// // // //    // Function to check if user is logged in
// // // //    const checkLoginStatus = () => {
// // // //     const token = localStorage.getItem('token');
// // // //     setIsLoggedIn(!!token); // Set login status based on token presence
// // // // };

// // // // useEffect(() => {
// // // //     checkLoginStatus(); // Check login status on component mount
// // // // }, []);

// // // //   const [formData, setFormData] = useState({
// // // //     firstName: "",
// // // //     lastName: "",
// // // //     phone: "",
// // // //     email: "",
// // // //     address: "",
// // // //     city: "",
// // // //     state: "",
// // // //     country: "",
// // // //     pinCode: "",
// // // //     paymentMethod: "UPI",
// // // //     additionalInformation: "",
// // // //     calendar_id: calendarId || "",
// // // //   });

// // // //   const [orderData, setOrderData] = useState({
// // // //     qty: 1,
// // // //     orderStatus: "Pending",
// // // //     paymentStatus: "Pending",
// // // //     orderAmount: 0,
// // // //     shippingPrice: 0,
// // // //     totalPrice: 295,
// // // //     orderId: "",
// // // //     orderConfirmed: false,
// // // //     dispatched: false,
// // // //     OntheWay: false,
// // // //     pickup: false,
// // // //     calendar_id: calendarId || "",
// // // //   });

// // // //   useEffect(() => {
// // // //     window.scrollTo({
// // // //       top: 0,
// // // //       left: 0,
// // // //       behavior: "auto",
// // // //     });
// // // //     fetchBilling();
// // // //   }, []);

// // // //   const token = localStorage.getItem('token');

// // // //   const handleInputChange = (e) => {
// // // //     const { name, value } = e.target;
// // // //     setFormData({
// // // //       ...formData,
// // // //       [name]: value,
// // // //     });
// // // //   };


// // // //     const fetchBilling = async () => {
// // // //       try {
// // // //         const response = await axios.get(apipath + `/billing/details/${billingId}`);
// // // //         setFormData(response.data.billingInformation);
// // // //       } catch (error) {
// // // //         console.error('Error fetching courses:', error);
// // // //       }
// // // //     }; 

// // // //   const handleUpdate = async (e) => {
// // // //     e.preventDefault();
// // // //     try {
// // // //       const billingResponse = await fetch(apipath + `/billing/details/${billingId}`, {
// // // //         method: 'PUT',
// // // //         headers: {
// // // //           'Content-Type': 'application/json',
// // // //           'Authorization': `Bearer ${token}`,
// // // //         },
// // // //         body: JSON.stringify(formData),
// // // //       });

// // // //       if (billingResponse.status === 201) {
// // // //         iziToast.success({
// // // //           message: 'Billing information updated successfully',
// // // //           position: 'topCenter',
// // // //         });
// // // //         await handleOrderAdd(); // Call handleOrderAdd after successful billing info addition
// // // //       } else {
// // // //         iziToast.error({
// // // //           message: 'Billing information updating failed',
// // // //           position: 'topCenter',
// // // //         });
// // // //       }
// // // //     } catch (error) {
// // // //       console.error('Error updating billing information:', error);
// // // //       iziToast.error({
// // // //         message: 'An error occurred',
// // // //         position: 'topCenter',
// // // //       });
// // // //     }
// // // //   };

// // // //   const handleOrderAdd = async () => {
// // // //     try {
// // // //       const response = await fetch(apipath + '/order/add', {
// // // //         method: 'POST',
// // // //         headers: {
// // // //           'Content-Type': 'application/json',
// // // //           'Authorization': `Bearer ${token}`,
// // // //         },
// // // //         body: JSON.stringify(orderData),
// // // //       });

// // // //       const responseData = await response.json();
// // // //       if (response.status === 201) {
// // // //         const savedOrderId = responseData.order._id;  // Extract orderId from the response
// // // //         setSavedOrderId(savedOrderId);
// // // //         navigate(`/bill-summary/${calendarId}/${savedOrderId}`);
// // // //       } else {
// // // //         iziToast.error({
// // // //           message: 'Order addition failed',
// // // //           position: 'topCenter',
// // // //         });
// // // //       }
// // // //     } catch (error) {
// // // //       console.error('Error adding order:', error);
// // // //       iziToast.error({
// // // //         message: 'An error occurred',
// // // //         position: 'topCenter',
// // // //       });
// // // //     }
// // // //   };

// // // //   return (
// // // //     <>
// // // //       <Navbar isLoggedIn={isLoggedIn}/>
// // // //       <div className="container">
// // // //         <div className="col-lg-6 mx-auto image-header">
// // // //           <h1>Billing & Shipping Information</h1>
// // // //         </div>
// // // //         <div className="image-content">
// // // //           <h3>Please enter your details : </h3>
// // // //           <form onSubmit={handleUpdate}>
// // // //             <div className="row bill-row py-3">
// // // //               <div className="col-lg-6">
// // // //                 <h4>Billing Information :</h4>
// // // //               <div className="col-lg-12">
// // // //                 <div className="bill-item">
// // // //                   <label htmlFor="">First Name</label>
// // // //                   <input
// // // //                     type="text"
// // // //                     placeholder="First Name"
// // // //                     name="firstName"
// // // //                     value={formData.firstName}
// // // //                     onChange={handleInputChange}
// // // //                     maxLength="100"
// // // //                     required
// // // //                   />
// // // //                 </div>
// // // //               </div>
// // // //               <div className="col-lg-12">
// // // //                 <div className="bill-item">
// // // //                   <label htmlFor="">Last Name</label>
// // // //                   <input
// // // //                     type="text"
// // // //                     placeholder="Last Name"
// // // //                     name="lastName"
// // // //                     value={formData.lastName}
// // // //                     onChange={handleInputChange}
// // // //                     maxLength="100"
// // // //                     required
// // // //                   />
// // // //                 </div>
// // // //               </div>
// // // //               <div className="col-lg-12">
// // // //                 <div className="bill-item">
// // // //                   <label htmlFor="">Email</label>
// // // //                   <input
// // // //                     type="email"
// // // //                     placeholder="Enter Email"
// // // //                     name="email"
// // // //                     value={formData.email}
// // // //                     onChange={handleInputChange}
// // // //                     maxLength="100"
// // // //                     required
// // // //                   />
// // // //                 </div>
// // // //               </div>
// // // //               <div className="col-lg-12">
// // // //                 <div className="bill-item">
// // // //                   <label htmlFor="">Mobile No.</label>
// // // //                   <input
// // // //                     type="text"
// // // //                     placeholder="Enter Phone Number"
// // // //                     name="phone"
// // // //                     value={formData.phone}
// // // //                     onChange={handleInputChange}
// // // //                     maxLength="15"
// // // //                     required
// // // //                   />
// // // //                 </div>
// // // //               </div>
// // // //               <div className="col-lg-12">
// // // //                 <div className="bill-item">
// // // //                   <label htmlFor="">Address</label>
// // // //                   <input
// // // //                     type="text"
// // // //                     placeholder="Address"
// // // //                     name="address"
// // // //                     value={formData.address}
// // // //                     onChange={handleInputChange}
// // // //                     maxLength="100"
// // // //                     required
// // // //                   />
// // // //                 </div>
// // // //               </div>
// // // //               <div className="col-lg-12">
// // // //                 <div className="bill-item">
// // // //                   <label htmlFor="">City</label>
// // // //                   <input
// // // //                     type="text"
// // // //                     placeholder="City"
// // // //                     name="city"
// // // //                     value={formData.city}
// // // //                     onChange={handleInputChange}
// // // //                     maxLength="100"
// // // //                     required
// // // //                   />
// // // //                 </div>
// // // //               </div>
// // // //               <div className="col-lg-12">
// // // //                 <div className="bill-item">
// // // //                   <label htmlFor="">State</label>
// // // //                   <input
// // // //                     type="text"
// // // //                     placeholder="State"
// // // //                     name="state"
// // // //                     value={formData.state}
// // // //                     onChange={handleInputChange}
// // // //                     maxLength="100"
// // // //                     required
// // // //                   />
// // // //                 </div>
// // // //               </div>
// // // //               {/* <div className="col-lg-12">
// // // //                 <div className="bill-item">
// // // //                   <label htmlFor="">Country</label>
// // // //                   <input
// // // //                     type="text"
// // // //                     placeholder="Country"
// // // //                     name="country"
// // // //                     value={formData.country}
// // // //                     onChange={handleInputChange}
// // // //                     maxLength="100"
// // // //                     required
// // // //                   />
// // // //                 </div>
// // // //               </div> */}
// // // //               <div className="col-lg-12">
// // // //                 <div className="bill-item">
// // // //                   <label htmlFor="">Pincode</label>
// // // //                   <input
// // // //                     type="text"
// // // //                     placeholder="Enter your pin code"
// // // //                     name="pinCode"
// // // //                     value={formData.pinCode}
// // // //                     onChange={handleInputChange}
// // // //                     maxLength="100"
// // // //                     required
// // // //                   />
// // // //                 </div>
// // // //               </div>
// // // //               {/* <div className="col-lg-12">
// // // //                 <div className="bill-item">
// // // //                   <label htmlFor="">Payment Method</label>
// // // //                   <input
// // // //                     type="text"
// // // //                     placeholder="UPI"
// // // //                     name="paymentMethod"
// // // //                     value={formData.paymentMethod}
// // // //                     onChange={handleInputChange}
// // // //                     maxLength="100"
// // // //                     readOnly
// // // //                   />
// // // //                 </div>
// // // //               </div> */}
// // // //               <div className="col-lg-12">
// // // //                 <div className="bill-item">
// // // //                   <label htmlFor="">Additional Information</label>
// // // //                   <textarea
// // // //                     type="text"
// // // //                     placeholder="Any additional Info"
// // // //                     name="additionalInformation"
// // // //                     value={formData.additionalInformation}
// // // //                     onChange={handleInputChange}
// // // //                     maxLength="200"
// // // //                     required
// // // //                   />
// // // //                 </div>
// // // //               </div>
// // // //               </div>
// // // //               <div className="col-lg-6">
// // // //                 <h4><input type="checkbox" /> Shipping address same as billing address</h4>
// // // //               <div className="col-lg-12">
// // // //                 <div className="bill-item">
// // // //                   <label htmlFor="">First Name</label>
// // // //                   <input
// // // //                     type="text"
// // // //                     placeholder="First Name"
// // // //                     name="firstName"
// // // //                     value={formData.firstName}
// // // //                     onChange={handleInputChange}
// // // //                     maxLength="100"
// // // //                     required
// // // //                   />
// // // //                 </div>
// // // //               </div>
// // // //               <div className="col-lg-12">
// // // //                 <div className="bill-item">
// // // //                   <label htmlFor="">Last Name</label>
// // // //                   <input
// // // //                     type="text"
// // // //                     placeholder="Last Name"
// // // //                     name="lastName"
// // // //                     value={formData.lastName}
// // // //                     onChange={handleInputChange}
// // // //                     maxLength="100"
// // // //                     required
// // // //                   />
// // // //                 </div>
// // // //               </div>
// // // //               {/* <div className="col-lg-12">
// // // //                 <div className="bill-item">
// // // //                   <label htmlFor="">Email</label>
// // // //                   <input
// // // //                     type="email"
// // // //                     placeholder="Enter Email"
// // // //                     name="email"
// // // //                     value={formData.email}
// // // //                     onChange={handleInputChange}
// // // //                     maxLength="100"
// // // //                     required
// // // //                   />
// // // //                 </div>
// // // //               </div> */}

// // // //               <div className="col-lg-12">
// // // //                 <div className="bill-item">
// // // //                   <label htmlFor="">Mobile No.</label>
// // // //                   <input
// // // //                     type="text"
// // // //                     placeholder="Enter Phone Number"
// // // //                     name="phone"
// // // //                     value={formData.phone}
// // // //                     onChange={handleInputChange}
// // // //                     maxLength="15"
// // // //                     required
// // // //                   />
// // // //                 </div>
// // // //               </div>
// // // //               <div className="col-lg-12">
// // // //                 <div className="bill-item">
// // // //                   <label htmlFor="">Alternate Phone No.</label>
// // // //                   <input
// // // //                     type="text"
// // // //                     placeholder="Enter Alternate no."
// // // //                     name="alternate"
// // // //                     // value={formData.email}
// // // //                     // onChange={handleInputChange}
// // // //                     maxLength="100"
// // // //                     required
// // // //                   />
// // // //                 </div>
// // // //               </div>
// // // //               <div className="col-lg-12">
// // // //                 <div className="bill-item">
// // // //                   <label htmlFor="">Address</label>
// // // //                   <input
// // // //                     type="text"
// // // //                     placeholder="Address"
// // // //                     name="address"
// // // //                     value={formData.address}
// // // //                     onChange={handleInputChange}
// // // //                     maxLength="100"
// // // //                     required
// // // //                   />
// // // //                 </div>
// // // //               </div>
// // // //               <div className="col-lg-12">
// // // //                 <div className="bill-item">
// // // //                   <label htmlFor="">City</label>
// // // //                   <input
// // // //                     type="text"
// // // //                     placeholder="City"
// // // //                     name="city"
// // // //                     value={formData.city}
// // // //                     onChange={handleInputChange}
// // // //                     maxLength="100"
// // // //                     required
// // // //                   />
// // // //                 </div>
// // // //               </div>
// // // //               <div className="col-lg-12">
// // // //                 <div className="bill-item">
// // // //                   <label htmlFor="">State</label>
// // // //                   <input
// // // //                     type="text"
// // // //                     placeholder="State"
// // // //                     name="state"
// // // //                     value={formData.state}
// // // //                     onChange={handleInputChange}
// // // //                     maxLength="100"
// // // //                     required
// // // //                   />
// // // //                 </div>
// // // //               </div>
// // // //               {/* <div className="col-lg-12">
// // // //                 <div className="bill-item">
// // // //                   <label htmlFor="">Country</label>
// // // //                   <input
// // // //                     type="text"
// // // //                     placeholder="Country"
// // // //                     name="country"
// // // //                     value={formData.country}
// // // //                     onChange={handleInputChange}
// // // //                     maxLength="100"
// // // //                     required
// // // //                   />
// // // //                 </div>
// // // //               </div> */}
// // // //               <div className="col-lg-12">
// // // //                 <div className="bill-item">
// // // //                   <label htmlFor="">Pincode</label>
// // // //                   <input
// // // //                     type="text"
// // // //                     placeholder="Enter your pin code"
// // // //                     name="pinCode"
// // // //                     value={formData.pinCode}
// // // //                     onChange={handleInputChange}
// // // //                     maxLength="100"
// // // //                     required
// // // //                   />
// // // //                 </div>
// // // //               </div>
// // // //               {/* <div className="col-lg-12">
// // // //                 <div className="bill-item">
// // // //                   <label htmlFor="">Payment Method</label>
// // // //                   <input
// // // //                     type="text"
// // // //                     placeholder="UPI"
// // // //                     name="paymentMethod"
// // // //                     value={formData.paymentMethod}
// // // //                     onChange={handleInputChange}
// // // //                     maxLength="100"
// // // //                     readOnly
// // // //                   />
// // // //                 </div>
// // // //               </div> */}
// // // //               <div className="col-lg-12">
// // // //                 <div className="bill-item">
// // // //                   <label htmlFor="">Additional Information</label>
// // // //                   <textarea
// // // //                     type="text"
// // // //                     placeholder="Any additional Info"
// // // //                     name="additionalInformation"
// // // //                     value={formData.additionalInformation}
// // // //                     onChange={handleInputChange}
// // // //                     maxLength="200"
// // // //                     required
// // // //                   />
// // // //                 </div>
// // // //               </div>
// // // //               </div>
// // // //               <div className="image-button">
// // // //                 <button>Update & Continue</button>
// // // //               </div>
// // // //             </div>
// // // //           </form>
// // // //         </div>
// // // //       </div>
// // // //       <Footer />
// // // //     </>
// // // //   );
// // // // };

// // // // export default Billing;


// // // import React, { useEffect, useState } from "react";
// // // import Navbar from "../components/Navbar";
// // // import Footer from "../components/Footer";
// // // import { useNavigate, useParams } from "react-router-dom";
// // // import iziToast from "izitoast";
// // // import { useAuth } from "../AuthContext";
// // // import axios from "axios";

// // // const Billing = () => {
// // //   const { calendarId, billingId,shippingId } = useParams();
// // //   const navigate = useNavigate();
// // //   const { apipath } = useAuth();
// // //   const [isLoggedIn, setIsLoggedIn] = useState(false);
// // //   const token = localStorage.getItem('token');

// // //   useEffect(() => {
// // //     checkLoginStatus();
// // //     fetchBilling();
// // //     fetchshipping();
// // //   }, []);

// // //   const checkLoginStatus = () => {
// // //     setIsLoggedIn(!!token);
// // //   };

// // //   const [formData, setFormData] = useState({
// // //     firstName: "",
// // //     lastName: "",
// // //     phone: "",
// // //     email: "",
// // //     address: "",
// // //     city: "",
// // //     state: "",
// // //     pinCode: "",
// // //     paymentMethod: "UPI",
// // //     additionalInformation: "",
// // //     calendar_id: calendarId || "",
// // //   });

// // //     const [orderData, setOrderData] = useState({
// // //     qty: 1,
// // //     orderStatus: "Pending",
// // //     paymentStatus: "Pending",
// // //     orderAmount: 0,
// // //     shippingPrice: 0,
// // //     totalPrice: 295,
// // //     orderId: "",
// // //     orderConfirmed: false,
// // //     dispatched: false,
// // //     OntheWay: false,
// // //     pickup: false,
// // //     calendar_id: calendarId || "",
// // //   });

// // //   const [shippingData, setShippingData] = useState({
// // //     firstName: "",
// // //     lastName: "",
// // //     phone: "",
// // //     alternatePhone: "",
// // //     address: "",
// // //     city: "",
// // //     state: "",
// // //     country: "",
// // //     pinCode: "",
// // //     paymentMethod: "UPI",
// // //     additionalInformation: "",
// // //     calendar_id: calendarId || "",
// // //   });

// // //   const handleInputChange = (e) => {
// // //     const { name, value } = e.target;
// // //     setFormData({
// // //       ...formData,
// // //       [name]: value,
// // //     });
// // //   };

// // //   const fetchBilling = async () => {
// // //     try {
// // //       const response = await axios.get(`${apipath}/billing/details/${billingId}`, {
// // //         headers: {
// // //           'Authorization': `Bearer ${token}`,
// // //         }
// // //       });
// // //       setFormData(response.data.billingInformation);
// // //     } catch (error) {
// // //       console.error('Error fetching billing details:', error);
// // //       iziToast.error({
// // //         message: 'Failed to fetch billing details',
// // //         position: 'topCenter',
// // //       });
// // //     }
// // //   };

// // //   const fetchshipping = async () => {
// // //     try {
// // //       const response = await axios.get(`${apipath}/shipping/details/${shippingId}`, {
// // //         headers: {
// // //           'Authorization': `Bearer ${token}`,
// // //         }
// // //       });
// // //       setFormData(response.data.shippingInformation);
// // //     } catch (error) {
// // //       console.error('Error fetching shipping details:', error);
// // //       iziToast.error({
// // //         message: 'Failed to fetch shipping details',
// // //         position: 'topCenter',
// // //       });
// // //     }
// // //   };

// // //   const handleUpdateShipping = async (e) => {
// // //     e.preventDefault();
// // //     try {
// // //       const response = await fetch(`${apipath}/shipping/details/${billingId}`, {
// // //         method: 'PUT',
// // //         headers: {
// // //           'Content-Type': 'application/json',
// // //           'Authorization': `Bearer ${token}`,
// // //         },
// // //         body: JSON.stringify(formData),
// // //       });
// // //     } catch (error) {
// // //       console.error('Error updating shipping information:', error);
// // //     }
// // //   };

// // //   const handleUpdate = async (e) => {
// // //     e.preventDefault();
// // //     try {
// // //       const response = await fetch(`${apipath}/billing/details/${billingId}`, {
// // //         method: 'PUT',
// // //         headers: {
// // //           'Content-Type': 'application/json',
// // //           'Authorization': `Bearer ${token}`,
// // //         },
// // //         body: JSON.stringify(formData),
// // //       });

// // //       const responseData = await response.json();

// // //       if (response.status === 200) {
// // //         iziToast.success({
// // //           message: 'Billing information updated successfully',
// // //           position: 'topCenter',
// // //         });
// // //         await handleOrderAdd(); // Call handleOrderAdd after successful billing info update
// // //     await handleUpdateShipping();
// // //       } else {
// // //         iziToast.error({
// // //           message: 'Billing information updating failed',
// // //           position: 'topCenter',
// // //         });
// // //       }
// // //     } catch (error) {
// // //       console.error('Error updating billing information:', error);
// // //       iziToast.error({
// // //         message: 'An error occurred',
// // //         position: 'topCenter',
// // //       });
// // //     }
// // //   };

// // //   const handleOrderAdd = async () => {
// // //     try {
// // //       const response = await fetch(`${apipath}/order/add`, {
// // //         method: 'POST',
// // //         headers: {
// // //           'Content-Type': 'application/json',
// // //           'Authorization': `Bearer ${token}`,
// // //         },
// // //         body: JSON.stringify(orderData),
// // //       });

// // //       const responseData = await response.json();
// // //       if (response.status === 201) {
// // //         const savedOrderId = responseData.order._id;  // Extract orderId from the response
// // //         navigate(`/bill-summary/${calendarId}/${savedOrderId}`);
// // //       } else {
// // //         iziToast.error({
// // //           message: 'Order addition failed',
// // //           position: 'topCenter',
// // //         });
// // //       }
// // //     } catch (error) {
// // //       console.error('Error adding order:', error);
// // //       iziToast.error({
// // //         message: 'An error occurred',
// // //         position: 'topCenter',
// // //       });
// // //     }
// // //   };

// // //   return (
// // //     <>
// // //       <Navbar isLoggedIn={isLoggedIn} />
// // //       <div className="container">
// // //         <div className="col-lg-6 mx-auto image-header">
// // //           <h1>Billing & Shipping Information</h1>
// // //         </div>
// // //         <div className="image-content">
// // //           <h3>Please enter your details : </h3>
// // //           <form onSubmit={handleUpdate}>
// // //             <div className="row bill-row py-3">
// // //               <div className="col-lg-6">
// // //                 <h4>Billing Information :</h4>
// // //                 <div className="col-lg-12">
// // //                   <div className="bill-item">
// // //                     <label htmlFor="">First Name</label>
// // //                     <input
// // //                       type="text"
// // //                       placeholder="First Name"
// // //                       name="firstName"
// // //                       value={formData.firstName}
// // //                       onChange={handleInputChange}
// // //                       maxLength="100"
// // //                       required
// // //                     />
// // //                   </div>
// // //                 </div>
// // //                 <div className="col-lg-12">
// // //                   <div className="bill-item">
// // //                     <label htmlFor="">Last Name</label>
// // //                     <input
// // //                       type="text"
// // //                       placeholder="Last Name"
// // //                       name="lastName"
// // //                       value={formData.lastName}
// // //                       onChange={handleInputChange}
// // //                       maxLength="100"
// // //                       required
// // //                     />
// // //                   </div>
// // //                 </div>
// // //                 <div className="col-lg-12">
// // //                   <div className="bill-item">
// // //                     <label htmlFor="">Email</label>
// // //                     <input
// // //                       type="email"
// // //                       placeholder="Enter Email"
// // //                       name="email"
// // //                       value={formData.email}
// // //                       onChange={handleInputChange}
// // //                       maxLength="100"
// // //                       required
// // //                     />
// // //                   </div>
// // //                 </div>
// // //                 <div className="col-lg-12">
// // //                   <div className="bill-item">
// // //                     <label htmlFor="">Mobile No.</label>
// // //                     <input
// // //                       type="text"
// // //                       placeholder="Enter Phone Number"
// // //                       name="phone"
// // //                       value={formData.phone}
// // //                       onChange={handleInputChange}
// // //                       maxLength="15"
// // //                       required
// // //                     />
// // //                   </div>
// // //                 </div>
// // //                 <div className="col-lg-12">
// // //                   <div className="bill-item">
// // //                     <label htmlFor="">Address</label>
// // //                     <input
// // //                       type="text"
// // //                       placeholder="Address"
// // //                       name="address"
// // //                       value={formData.address}
// // //                       onChange={handleInputChange}
// // //                       maxLength="100"
// // //                       required
// // //                     />
// // //                   </div>
// // //                 </div>
// // //                 <div className="col-lg-12">
// // //                   <div className="bill-item">
// // //                     <label htmlFor="">City</label>
// // //                     <input
// // //                       type="text"
// // //                       placeholder="City"
// // //                       name="city"
// // //                       value={formData.city}
// // //                       onChange={handleInputChange}
// // //                       maxLength="100"
// // //                       required
// // //                     />
// // //                   </div>
// // //                 </div>
// // //                 <div className="col-lg-12">
// // //                   <div className="bill-item">
// // //                     <label htmlFor="">State</label>
// // //                     <input
// // //                       type="text"
// // //                       placeholder="State"
// // //                       name="state"
// // //                       value={formData.state}
// // //                       onChange={handleInputChange}
// // //                       maxLength="100"
// // //                       required
// // //                     />
// // //                   </div>
// // //                 </div>
// // //                 <div className="col-lg-12">
// // //                   <div className="bill-item">
// // //                     <label htmlFor="">Pincode</label>
// // //                     <input
// // //                       type="text"
// // //                       placeholder="Enter your pin code"
// // //                       name="pinCode"
// // //                       value={formData.pinCode}
// // //                       onChange={handleInputChange}
// // //                       maxLength="100"
// // //                       required
// // //                     />
// // //                   </div>
// // //                 </div>
// // //                 <div className="col-lg-12">
// // //                   <div className="bill-item">
// // //                     <label htmlFor="">Additional Information</label>
// // //                     <textarea
// // //                       type="text"
// // //                       placeholder="Any additional Info"
// // //                       name="additionalInformation"
// // //                       value={formData.additionalInformation}
// // //                       onChange={handleInputChange}
// // //                       maxLength="200"
// // //                       required
// // //                     />
// // //                   </div>
// // //                 </div>
// // //               </div>
// // //               <div className="col-lg-6">
// // //                 <h4><input type="checkbox" /> Shipping address same as billing address</h4>
// // //                 <div className="col-lg-12">
// // //                   <div className="bill-item">
// // //                     <label htmlFor="">First Name</label>
// // //                     <input
// // //                       type="text"
// // //                       placeholder="First Name"
// // //                       name="firstName"
// // //                       value={formData.firstName}
// // //                       onChange={handleInputChange}
// // //                       maxLength="100"
// // //                       required
// // //                     />
// // //                   </div>
// // //                 </div>
// // //                 <div className="col-lg-12">
// // //                   <div className="bill-item">
// // //                     <label htmlFor="">Last Name</label>
// // //                     <input
// // //                       type="text"
// // //                       placeholder="Last Name"
// // //                       name="lastName"
// // //                       value={formData.lastName}
// // //                       onChange={handleInputChange}
// // //                       maxLength="100"
// // //                       required
// // //                     />
// // //                   </div>
// // //                 </div>
// // //                 <div className="col-lg-12">
// // //                   <div className="bill-item">
// // //                     <label htmlFor="">Mobile No.</label>
// // //                     <input
// // //                       type="text"
// // //                       placeholder="Enter Phone Number"
// // //                       name="phone"
// // //                       value={formData.phone}
// // //                       onChange={handleInputChange}
// // //                       maxLength="15"
// // //                       required
// // //                     />
// // //                   </div>
// // //                 </div>
// // //                 <div className="col-lg-12">
// // //                   <div className="bill-item">
// // //                     <label htmlFor="">Alternate Phone no.</label>
// // //                     <input
// // //                       type="text"
// // //                       placeholder="Enter Number"
// // //                       name="alternate"
// // //                       // value={formData.email}
// // //                       onChange={handleInputChange}
// // //                       maxLength="15"
// // //                       required
// // //                     />
// // //                   </div>
// // //                 </div>
// // //                 <div className="col-lg-12">
// // //                   <div className="bill-item">
// // //                     <label htmlFor="">Address</label>
// // //                     <input
// // //                       type="text"
// // //                       placeholder="Address"
// // //                       name="address"
// // //                       value={formData.address}
// // //                       onChange={handleInputChange}
// // //                       maxLength="100"
// // //                       required
// // //                     />
// // //                   </div>
// // //                 </div>
// // //                 <div className="col-lg-12">
// // //                   <div className="bill-item">
// // //                     <label htmlFor="">City</label>
// // //                     <input
// // //                       type="text"
// // //                       placeholder="City"
// // //                       name="city"
// // //                       value={formData.city}
// // //                       onChange={handleInputChange}
// // //                       maxLength="100"
// // //                       required
// // //                     />
// // //                   </div>
// // //                 </div>
// // //                 <div className="col-lg-12">
// // //                   <div className="bill-item">
// // //                     <label htmlFor="">State</label>
// // //                     <input
// // //                       type="text"
// // //                       placeholder="State"
// // //                       name="state"
// // //                       value={formData.state}
// // //                       onChange={handleInputChange}
// // //                       maxLength="100"
// // //                       required
// // //                     />
// // //                   </div>
// // //                 </div>
// // //                 <div className="col-lg-12">
// // //                   <div className="bill-item">
// // //                     <label htmlFor="">Pincode</label>
// // //                     <input
// // //                       type="text"
// // //                       placeholder="Enter your pin code"
// // //                       name="pinCode"
// // //                       value={formData.pinCode}
// // //                       onChange={handleInputChange}
// // //                       maxLength="100"
// // //                       required
// // //                     />
// // //                   </div>
// // //                 </div>
// // //                 <div className="col-lg-12">
// // //                   <div className="bill-item">
// // //                     <label htmlFor="">Additional Information</label>
// // //                     <textarea
// // //                       type="text"
// // //                       placeholder="Any additional Info"
// // //                       name="additionalInformation"
// // //                       value={formData.additionalInformation}
// // //                       onChange={handleInputChange}
// // //                       maxLength="200"
// // //                       required
// // //                     />
// // //                   </div>
// // //                 </div>
// // //               </div>
// // //             </div>
// // //             {/* <div className="form-group col-lg-12 d-flex justify-content-center py-5">
// // //               <button type="submit" className="btn btn-danger" style={{ width: "fit-content" }}>
// // //                 Proceed to Payment
// // //               </button>
// // //             </div> */}
// // //             <div className="image-button">
// // //                 <button>Update & Continue</button>
// // //            </div>
// // //           </form>
// // //         </div>
// // //       </div>
// // //       <Footer />
// // //     </>
// // //   );
// // // };

// // // export default Billing;

// import React, { useEffect, useState } from "react";
// import Navbar from "../components/Navbar";
// import Footer from "../components/Footer";
// import { useNavigate, useParams } from "react-router-dom";
// import iziToast from "izitoast";
// import { useAuth } from "../AuthContext";
// import axios from "axios";

// const Billing = () => {
//   const { calendarId, billingId, shippingId } = useParams();
//   const navigate = useNavigate();
//   const { apipath } = useAuth();
//   const [isLoggedIn, setIsLoggedIn] = useState(false);
//   const token = localStorage.getItem('token');

//   useEffect(() => {
//     checkLoginStatus();
//     fetchBilling();
//     fetchShipping();
//   }, []);

//   const checkLoginStatus = () => {
//     setIsLoggedIn(!!token);
//   };

//   const [isSameAddress, setIsSameAddress] = useState(false);

//   const [formData, setFormData] = useState({
//     firstName: "",
//     lastName: "",
//     phone: "",
//     email: "",
//     address: "",
//     city: "",
//     state: "",
//     pinCode: "",
//     paymentMethod: "UPI",
//     additionalInformation: "",
//     calendar_id: calendarId || "",
//   });

//   const [orderData, setOrderData] = useState({
//     qty: 1,
//     orderStatus: "Pending",
//     paymentStatus: "Pending",
//     orderAmount: 0,
//     shippingPrice: 0,
//     totalPrice: 295,
//     orderId: "",
//     orderConfirmed: false,
//     dispatched: false,
//     OntheWay: false,
//     pickup: false,
//     calendar_id: calendarId || "",
//   });

//   const [shippingData, setShippingData] = useState({
//     firstName: "",
//     lastName: "",
//     phone: "",
//     alternatePhone: "",
//     address: "",
//     city: "",
//     state: "",
//     country: "",
//     pinCode: "",
//     paymentMethod: "UPI",
//     additionalInformation: "",
//     calendar_id: calendarId || "",
//   });

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({
//       ...formData,
//       [name]: value,
//     });
//   };

//   const handleShippingInputChange = (e) => {
//     const { name, value } = e.target;
//     setShippingData({
//       ...shippingData,
//       [name]: value,
//     });
//   };

//   const handleCheckboxChange = (e) => {
//     setIsSameAddress(e.target.checked);
//     if (e.target.checked) {
//       setShippingData({ ...formData });
//     } else {
//       setShippingData({
//         firstName: "",
//         lastName: "",
//         phone: "",
//         alternatePhone: "",
//         address: "",
//         city: "",
//         state: "",
//         country: "",
//         pinCode: "",
//         paymentMethod: "UPI",
//         additionalInformation: "",
//         calendar_id: calendarId || "",
//       });
//     }
//   };

//   const fetchBilling = async () => {
//     try {
//       const response = await axios.get(`${apipath}/billing/details/${billingId}`, {
//         headers: {
//           'Authorization': `Bearer ${token}`,
//         }
//       });
//       setFormData(response.data.billingInformation);
//     } catch (error) {
//       console.error('Error fetching billing details:', error);
//       iziToast.error({
//         message: 'Failed to fetch billing details',
//         position: 'topCenter',
//       });
//     }
//   };

//   const fetchShipping = async () => {
//     try {
//       const response = await axios.get(`${apipath}/shipping/details/${shippingId}`, {
//         headers: {
//           'Authorization': `Bearer ${token}`,
//         }
//       });
//       setShippingData(response.data.shippingInformation);
//     } catch (error) {
//       console.error('Error fetching shipping details:', error);
//       // iziToast.error({
//       //   message: 'Failed to fetch shipping details',
//       //   position: 'topCenter',
//       // });
//     }
//   };

//   const handleUpdate = async (e) => {
//     e.preventDefault();
//     try {
//       // Update billing information
//       const billingResponse = await fetch(`${apipath}/billing/details/${billingId}`, {
//         method: 'PUT',
//         headers: {
//           'Content-Type': 'application/json',
//           'Authorization': `Bearer ${token}`,
//         },
//         body: JSON.stringify(formData),
//       });

//       if (billingResponse.status === 200) {
//         iziToast.success({
//           message: 'Billing information updated successfully',
//           position: 'topCenter',
//         });

//         if (isSameAddress) {
//           // Update shipping information if checkbox is checked
//           const shippingResponse = await fetch(`${apipath}/shipping/details/${shippingId}`, {
//             method: 'PUT',
//             headers: {
//               'Content-Type': 'application/json',
//               'Authorization': `Bearer ${token}`,
//             },
//             body: JSON.stringify(shippingData),
//           });

//           // if (shippingResponse.status === 200) {
//           //   iziToast.success({
//           //     message: 'Shipping information updated successfully',
//           //     position: 'topCenter',
//           //   });
//           // } else {
//           //   iziToast.error({
//           //     message: 'Shipping information updating failed',
//           //     position: 'topCenter',
//           //   });
//           // }
//         }

//         await handleOrderAdd(); // Call handleOrderAdd after successful updates
//       } else {
//         iziToast.error({
//           message: 'Billing information updating failed',
//           position: 'topCenter',
//         });
//       }
//     } catch (error) {
//       console.error('Error updating billing information:', error);
//       iziToast.error({
//         message: 'An error occurred',
//         position: 'topCenter',
//       });
//     }
//   };

//   const handleOrderAdd = async () => {
//     try {
//       const response = await fetch(`${apipath}/order/add`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//           'Authorization': `Bearer ${token}`,
//         },
//         body: JSON.stringify(orderData),
//       });

//       const responseData = await response.json();
//       if (response.status === 201) {
//         const savedOrderId = responseData.order._id;  // Extract orderId from the response
//         navigate(`/bill-summary/${calendarId}/${savedOrderId}`);
//       } else {
//         iziToast.error({
//           message: 'Order addition failed',
//           position: 'topCenter',
//         });
//       }
//     } catch (error) {
//       console.error('Error adding order:', error);
//       iziToast.error({
//         message: 'An error occurred',
//         position: 'topCenter',
//       });
//     }
//   };

//   return (
//     <>
//       <Navbar isLoggedIn={isLoggedIn} />
//       <div className="container">
//         <div className="col-lg-6 mx-auto image-header">
//           <h1>Billing & Shipping Information</h1>
//         </div>
//         <div className="image-content">
//           <h3>Please enter your details:</h3>
//           <form onSubmit={handleUpdate}>
//             <div className="row bill-row py-3">
//               <div className="col-lg-6">
//                 <h4>Billing Information:</h4>
//                 <div className="col-lg-12">
//                   <div className="bill-item">
//                     <label htmlFor="">First Name</label>
//                     <input
//                       type="text"
//                       placeholder="First Name"
//                       name="firstName"
//                       value={formData.firstName}
//                       onChange={handleInputChange}
//                       maxLength="25"
//                       required
//                     />
//                   </div>
//                 </div>
//                 <div className="col-lg-12">
//                   <div className="bill-item">
//                     <label htmlFor="">Last Name</label>
//                     <input
//                       type="text"
//                       placeholder="Last Name"
//                       name="lastName"
//                       value={formData.lastName}
//                       onChange={handleInputChange}
//                       maxLength="25"
//                       required
//                     />
//                   </div>
//                 </div>
//                 <div className="col-lg-12">
//                   <div className="bill-item">
//                     <label htmlFor="">Email</label>
//                     <input
//                       type="email"
//                       placeholder="Enter Email"
//                       name="email"
//                       value={formData.email}
//                       onChange={handleInputChange}
//                       maxLength="50"
//                       required
//                     />
//                   </div>
//                 </div>
//                 <div className="col-lg-12">
//                   <div className="bill-item">
//                     <label htmlFor="">Mobile No.</label>
//                     <input
//                       type="text"
//                       placeholder="Enter Phone Number"
//                       name="phone"
//                       value={formData.phone}
//                       onChange={handleInputChange}
//                       maxLength="10"
//                       required
//                     />
//                   </div>
//                 </div>
//                 <div className="col-lg-12">
//                   <div className="bill-item">
//                     <label htmlFor="">Address</label>
//                     <input
//                       type="text"
//                       placeholder="Address"
//                       name="address"
//                       value={formData.address}
//                       onChange={handleInputChange}
//                       maxLength="50"
//                       required
//                     />
//                   </div>
//                 </div>
//                 <div className="col-lg-12">
//                   <div className="bill-item">
//                     <label htmlFor="">City</label>
//                     <input
//                       type="text"
//                       placeholder="City"
//                       name="city"
//                       value={formData.city}
//                       onChange={handleInputChange}
//                       maxLength="50"
//                       required
//                     />
//                   </div>
//                 </div>
//                 <div className="col-lg-12">
//                   <div className="bill-item">
//                     <label htmlFor="">State</label>
//                     <input
//                       type="text"
//                       placeholder="State"
//                       name="state"
//                       value={formData.state}
//                       onChange={handleInputChange}
//                       maxLength="50"
//                       required
//                     />
//                   </div>
//                 </div>
//                 <div className="col-lg-12">
//                   <div className="bill-item">
//                     <label htmlFor="">Pincode</label>
//                     <input
//                       type="text"
//                       placeholder="Enter your pin code"
//                       name="pinCode"
//                       value={formData.pinCode}
//                       onChange={handleInputChange}
//                       maxLength="6"
//                       required
//                     />
//                   </div>
//                 </div>
//                 <div className="col-lg-12">
//                   <div className="bill-item">
//                     <label htmlFor="">Additional Information</label>
//                     <textarea
//                       type="text"
//                       placeholder="Any additional Info"
//                       name="additionalInformation"
//                       value={formData.additionalInformation}
//                       onChange={handleInputChange}
//                       maxLength="100"
//                       // required
//                     />
//                   </div>
//                 </div>
//               </div>
//               <div className="col-lg-6">
//                 <h4>
//                   <input 
//                     type="checkbox" 
//                     checked={isSameAddress}
//                     onChange={handleCheckboxChange} 
//                   /> Shipping address same as billing address
//                 </h4>
//                 <div className="col-lg-12">
//                   <div className="bill-item">
//                     <label htmlFor="">First Name</label>
//                     <input
//                       type="text"
//                       placeholder="First Name"
//                       name="firstName"
//                       value={formData.firstName}
//                       onChange={handleShippingInputChange}
//                       maxLength="25"
//                       required
//                       disabled={isSameAddress}
//                     />
//                   </div>
//                 </div>
//                 <div className="col-lg-12">
//                   <div className="bill-item">
//                     <label htmlFor="">Last Name</label>
//                     <input
//                       type="text"
//                       placeholder="Last Name"
//                       name="lastName"
//                       value={formData.lastName}
//                       onChange={handleShippingInputChange}
//                       maxLength="25"
//                       required
//                       disabled={isSameAddress}
//                     />
//                   </div>
//                 </div>
//                 <div className="col-lg-12">
//                   <div className="bill-item">
//                     <label htmlFor="">Mobile No.</label>
//                     <input
//                       type="text"
//                       placeholder="Enter Phone Number"
//                       name="phone"
//                       value={formData.phone}
//                       onChange={handleShippingInputChange}
//                       maxLength="10"
//                       required
//                       disabled={isSameAddress}
//                     />
//                   </div>
//                 </div>
//                 <div className="col-lg-12">
//                   <div className="bill-item">
//                     <label htmlFor="">Alternate Phone no.</label>
//                     <input
//                       type="text"
//                       placeholder="Enter Number"
//                       name="alternatePhone"
//                       value={formData.alternatePhone}
//                       onChange={handleShippingInputChange}
//                       maxLength="10"
//                       disabled={isSameAddress}
//                     />
//                   </div>
//                 </div>
//                 <div className="col-lg-12">
//                   <div className="bill-item">
//                     <label htmlFor="">Address</label>
//                     <input
//                       type="text"
//                       placeholder="Address"
//                       name="address"
//                       value={formData.address}
//                       onChange={handleShippingInputChange}
//                       maxLength="50"
//                       required
//                       disabled={isSameAddress}
//                     />
//                   </div>
//                 </div>
//                 <div className="col-lg-12">
//                   <div className="bill-item">
//                     <label htmlFor="">City</label>
//                     <input
//                       type="text"
//                       placeholder="City"
//                       name="city"
//                       value={formData.city}
//                       onChange={handleShippingInputChange}
//                       maxLength="50"
//                       required
//                       disabled={isSameAddress}
//                     />
//                   </div>
//                 </div>
//                 <div className="col-lg-12">
//                   <div className="bill-item">
//                     <label htmlFor="">State</label>
//                     <input
//                       type="text"
//                       placeholder="State"
//                       name="state"
//                       value={formData.state}
//                       onChange={handleShippingInputChange}
//                       maxLength="50"
//                       required
//                       disabled={isSameAddress}
//                     />
//                   </div>
//                 </div>
//                 <div className="col-lg-12">
//                   <div className="bill-item">
//                     <label htmlFor="">Pincode</label>
//                     <input
//                       type="text"
//                       placeholder="Enter your pin code"
//                       name="pinCode"
//                       value={formData.pinCode}
//                       onChange={handleShippingInputChange}
//                       maxLength="6"
//                       required
//                       disabled={isSameAddress}
//                     />
//                   </div>
//                 </div>
//                 <div className="col-lg-12">
//                   <div className="bill-item">
//                     <label htmlFor="">Additional Information</label>
//                     <textarea
//                       type="text"
//                       placeholder="Any additional Info"
//                       name="additionalInformation"
//                       value={formData.additionalInformation}
//                       onChange={handleShippingInputChange}
//                       maxLength="100"
//                       // required
//                       disabled={isSameAddress}
//                     />
//                   </div>
//                 </div>
//               </div>
//             </div>
//             <div className="image-button">
//                 <button>Update & Continue</button>
//            </div>
//           </form>
//         </div>
//       </div>
//       <Footer />
//     </>
//   );
// };

// export default Billing;

import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { useNavigate, useParams } from "react-router-dom";
import iziToast from "izitoast";
import { useAuth } from "../AuthContext";
import axios from "axios";
import Select from 'react-select';
import { Country, State, City } from "country-state-city";

const Billing = () => {
  const { calendarId, billingId } = useParams();
  const navigate = useNavigate();
  const { apipath } = useAuth();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const token = localStorage.getItem('token');

  const [shippingId, setShippingId] = useState("");
  const [isSameAddress, setIsSameAddress] = useState(false);

  const [calendarData, setCalendarData] = useState({
    order_id: "",
  });

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    address: "",
    city: "",
    state: "",
    pinCode: "",
    paymentMethod: "UPI",
    additionalInformation: "",
    calendar_id: calendarId || "",
    flat: "",
    houseNo: "",
    building: "",
    company: "",
    apartment: "",
    area: "",
    street: "",
    sector: "",
    landmark: "",
    country: "",
  });

  const [orderData, setOrderData] = useState({
    qty: 1,
    orderStatus: "Pending",
    paymentStatus: "Pending",
    orderAmount: 0,
    shippingPrice: 0,
    totalPrice: 295,
    orderId: "",
    orderConfirmed: false,
    dispatched: false,
    OntheWay: false,
    pickup: false,
    calendar_id: calendarId || "",
    flat: "",
    houseNo: "",
    building: "",
    company: "",
    apartment: "",
    area: "",
    street: "",
    sector: "",
    landmark: "",
    country: "",
  });

  const [shippingData, setShippingData] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    alternatePhone: "",
    address: "",
    city: "",
    state: "",
    country: "",
    pinCode: "",
    paymentMethod: "UPI",
    additionalInformation: "",
    calendar_id: calendarId || "",
    flat: "",
    houseNo: "",
    building: "",
    company: "",
    apartment: "",
    area: "",
    street: "",
    sector: "",
    landmark: "",
    country: "",
  });



  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);

  const [selectedShippingCountry, setSelectedShippingCountry] = useState(null);
  const [selectedShippingState, setSelectedShippingState] = useState(null);
  const [selectedShippingCity, setSelectedShippingCity] = useState(null);

  // Reset state and city when country changes
  useEffect(() => {
    if (selectedCountry) {
      setSelectedState(null);
      setSelectedCity(null);
    }
  }, [selectedCountry]);

  useEffect(() => {
    if (selectedState) {
      setSelectedCity(null);
    }
  }, [selectedState]);

  // Reset shipping state and city when shipping country changes
  useEffect(() => {
    if (selectedShippingCountry) {
      setSelectedShippingState(null);
      setSelectedShippingCity(null);
    }
  }, [selectedShippingCountry]);

  useEffect(() => {
    if (selectedShippingState) {
      setSelectedShippingCity(null);
    }
  }, [selectedShippingState]);

  useEffect(() => {
    // Log selected country and state
    console.log('Selected Country:', selectedCountry);
    console.log('Selected State:', selectedState);
    console.log('Selected City:', selectedCity);
  }, [selectedCountry, selectedState, selectedCity]);

  const handleSelectChange = (name, value, isShipping = false) => {
    if (isShipping) {
      setShippingData((prevState) => ({
        ...prevState,
        [name]: value?.name || "",
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value?.name || "",
      }));
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    if (isSameAddress) {
      setShippingData({
        ...shippingData,
        [name]: value,
      });
    }
  };

  const handleShippingInputChange = (e) => {
    const { name, value } = e.target;
    setShippingData({
      ...shippingData,
      [name]: value,
    });
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCheckboxChange = (e) => {
    setIsSameAddress(e.target.checked);
    if (e.target.checked) {
      // Copy formData (billing) to shippingData, including selected country, state, and city
      setShippingData(formData);
      
      // Copy selected country, state, and city to shipping fields
      setSelectedShippingCountry(selectedCountry);
      setSelectedShippingState(selectedState);
      setSelectedShippingCity(selectedCity);
    } else {
      // Reset shipping data when the checkbox is unchecked
      setShippingData({
        firstName: "",
        lastName: "",
        phone: "",
        alternatePhone: "",
        address: "",
        city: "",
        state: "",
        country: "",
        pinCode: "",
        paymentMethod: "UPI",
        additionalInformation: "",
        calendar_id: calendarId || "",
        flat: "",
        houseNo: "",
        building: "",
        company: "",
        apartment: "",
        area: "",
        street: "",
        sector: "",
        landmark: "",
      });
  
      // Reset shipping country, state, and city selections
      setSelectedShippingCountry(null);
      setSelectedShippingState(null);
      setSelectedShippingCity(null);
    }
  };

  const [calendarDetails, setCalendarDetails] = useState([]);

  useEffect(() => {
    checkLoginStatus();
    fetchCalendarEdit();
  }, []);

  useEffect(() => {
    if (shippingId) {
      fetchShipping();
      fetchBilling();
    }
  }, [shippingId]);

  const checkLoginStatus = () => {
    setIsLoggedIn(!!token);
  };

  const fetchCalendarEdit = async () => {
    try {
      const response = await axios.get(
        apipath + `/calendar/details/${calendarId}`
      );
      setCalendarDetails(response.data.calendar);
      setShippingId(response.data.calendar.shipping_id);
      console.log("response.data.calendar shipping_id: ", response.data.calendar.shipping_id);
    } catch (error) {
      console.error("Error fetching calendar details:", error);
    }
  };

  const fetchBilling = async () => {
    try {
      const response = await axios.get(apipath + `/billing/details/${billingId}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      });
      setFormData(response.data.billingInformation);
    } catch (error) {
      console.error('Error fetching billing details:', error);
      iziToast.error({
        message: 'Failed to fetch billing details',
        position: 'topCenter',
      });
    }
  };

  const fetchShipping = async () => {
    try {
      const response = await axios.get(apipath + `/shipping/details/${shippingId}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      });
      setShippingData(response.data.shippingInformation);
    } catch (error) {
      console.error('Error fetching shipping details:', error);
      iziToast.error({
        message: 'Failed to fetch shipping details',
        position: 'topCenter',
      });
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      // Update billing information
      const billingResponse = await fetch(apipath + `/billing/details/${billingId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(formData),
      });

      if (billingResponse.status === 200) {
        iziToast.success({
          message: 'Billing information updated successfully',
          position: 'topCenter',
        });

        if (isSameAddress) {
          // Update shipping information if checkbox is checked
          const shippingResponse = await fetch(apipath + `/shipping/details/${shippingId}`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify(shippingData),
          });

          // if (shippingResponse.status === 200) {
          //   iziToast.success({
          //     message: 'Shipping information updated successfully',
          //     position: 'topCenter',
          //   });
          // } else {
          //   iziToast.error({
          //     message: 'Shipping information updating failed',
          //     position: 'topCenter',
          //   });
          // }
        }

        await handleOrderAdd(); // Call handleOrderAdd after successful updates
      } else {
        iziToast.error({
          message: 'Billing information updating failed',
          position: 'topCenter',
        });
      }
    } catch (error) {
      console.error('Error updating billing information:', error);
      iziToast.error({
        message: 'An error occurred',
        position: 'topCenter',
      });
    }
  };

  const handleOrderAdd = async () => {
    try {
      const response = await fetch(apipath + `/order/add`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(orderData),
      });

      const responseData = await response.json();

      // Log the entire responseData to check its structure
      console.log("responseData:", responseData);

      if (response.status === 201) {
        // Ensure responseData.order exists
        if (responseData.order && responseData.order._id) {
          const savedOrderId = responseData.order._id;

          console.log("savedOrderId:", savedOrderId);

          // Update the calendar data with the order ID
          setCalendarData((prevState) => ({
            ...prevState,
            order_id: savedOrderId,
          }));

          // Update the calendar with the new order ID
          await handleCalendarUpdate(savedOrderId);

          // Navigate to the bill summary page
          navigate(`/bill-summary/${calendarId}/${savedOrderId}`);

          return savedOrderId; // Returning after all operations
        } else {
          console.error('Error: responseData.order or _id is undefined');
          iziToast.error({
            message: 'Order addition failed: Invalid response structure',
            position: 'topCenter',
          });
        }
      } else {
        iziToast.error({
          message: 'Order addition failed',
          position: 'topCenter',
        });
      }
    } catch (error) {
      console.error('Error adding order:', error);
      iziToast.error({
        message: 'An error occurred',
        position: 'topCenter',
      });
    }
  };


  const handleCalendarUpdate = async (orderId) => {
    try {
      await axios.put(
        apipath + `/calendar/details/${calendarId}`,
        { order_id: orderId },
        {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        }
      );

      iziToast.success({
        message: 'Calendar updated successfully',
        position: 'topCenter',
      });
    } catch (error) {
      console.error("Error updating calendar:", error);
      iziToast.error({
        message: 'Error updating calendar',
        position: 'topCenter',
      });
    }
  };

  return (
    <>
      <Navbar isLoggedIn={isLoggedIn} />
      <div className="container">
        <div className="col-lg-6 mx-auto image-header">
          <h1>Billing & Shipping Information</h1>
        </div>
        <div className="image-content">
          <h3>Please enter your details:</h3>
          <form onSubmit={handleUpdate}>
            <div className="row bill-row py-3">
              <div className="col-lg-6">
                <h4>Billing Information:</h4>
                <div className="col-lg-12">
                  <div className="bill-item">
                    <label htmlFor="">First Name</label>
                    <input
                      type="text"
                      placeholder="First Name"
                      name="firstName"
                      value={formData.firstName}
                      onChange={handleInputChange}
                      maxLength="25"
                      required
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="bill-item">
                    <label htmlFor="">Last Name</label>
                    <input
                      type="text"
                      placeholder="Last Name"
                      name="lastName"
                      value={formData.lastName}
                      onChange={handleInputChange}
                      maxLength="25"
                      required
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="bill-item">
                    <label htmlFor="">Email</label>
                    <input
                      type="email"
                      placeholder="Enter Email"
                      name="email"
                      value={formData.email}
                      onChange={handleInputChange}
                      maxLength="50"
                      required
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="bill-item">
                    <label htmlFor="">Mobile No.</label>
                    <input
                      type="tel"
                      placeholder="Mobile No."
                      name="phone"
                      value={formData.phone}
                      onChange={handleInputChange}
                      maxLength="10"
                      autoComplete="off"
                      onKeyPress={(event) => {
                        if (event.charCode < 48 || event.charCode > 57 || event.target.value.length >= 15) {
                          event.preventDefault();
                        }
                      }}
                      required
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="bill-item">
                    <label htmlFor="">Flat</label>
                    <input
                      type="text"
                      placeholder="Flat"
                      name="flat"
                      value={formData.flat}
                      onChange={handleInputChange}
                      maxLength="100"
                      required
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="bill-item">
                    <label htmlFor="">House No.</label>
                    <input
                      type="text"
                      placeholder="House No."
                      name="houseNo"
                      value={formData.houseNo}
                      onChange={handleInputChange}
                      maxLength="100"
                      required
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="bill-item">
                    <label htmlFor="">Building Name </label>
                    <input
                      type="text"
                      placeholder="Building Name"
                      name="building"
                      value={formData.building}
                      onChange={handleInputChange}
                      maxLength="100"
                      required
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="bill-item">
                    <label htmlFor="">Company Name</label>
                    <input
                      type="text"
                      placeholder="Company Name"
                      name="company"
                      value={formData.company}
                      onChange={handleInputChange}
                      maxLength="100"
                      required
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="bill-item">
                    <label htmlFor="">Apartment</label>
                    <input
                      type="text"
                      placeholder="apartment"
                      name="apartment"
                      value={formData.apartment}
                      onChange={handleInputChange}
                      maxLength="100"
                      required
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="bill-item">
                    <label htmlFor="">Area</label>
                    <input
                      type="text"
                      placeholder="Area"
                      name="area"
                      value={formData.area}
                      onChange={handleInputChange}
                      maxLength="100"
                      required
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="bill-item">
                    <label htmlFor="">Street</label>
                    <input
                      type="text"
                      placeholder="Street"
                      name="street"
                      value={formData.street}
                      onChange={handleInputChange}
                      maxLength="100"
                      required
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="bill-item">
                    <label htmlFor="">Sector</label>
                    <input
                      type="text"
                      placeholder="Sector"
                      name="sector"
                      value={formData.sector}
                      onChange={handleInputChange}
                      maxLength="100"
                      required
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="bill-item">
                    <label htmlFor="">Address</label>
                    <input
                      type="text"
                      placeholder="Address"
                      name="address"
                      value={formData.address}
                      onChange={handleInputChange}
                      maxLength="100"
                      required
                    />
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className="bill-item">
                    <label htmlFor="">Landmark</label>
                    <input
                      type="text"
                      placeholder="Landmark"
                      name="landmark"
                      value={formData.landmark}
                      onChange={handleInputChange}
                      maxLength="100"
                      required
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="bill-item">
                    <label htmlFor="">Country</label>
                    <Select
                      id="country"
                      options={Country.getAllCountries()}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.isoCode}
                      value={selectedCountry}
                      onChange={(item) => {
                        setSelectedCountry(item);
                        handleSelectChange('country', item);
                      }}
                      // required
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="bill-item">
                    <label htmlFor="">State</label>
                    <Select
                      id="state"
                      options={State.getStatesOfCountry(selectedCountry?.isoCode || '')}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.isoCode}
                      value={selectedState}
                      onChange={(item) => {
                        setSelectedState(item);
                        handleSelectChange('state', item);
                      }}
                      // required
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="bill-item">
                    <label htmlFor="">City</label>
                    <Select
                      id="city"
                      options={City.getCitiesOfState(selectedState?.countryCode || '', selectedState?.isoCode || '')}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.name}
                      value={selectedCity}
                      onChange={(item) => {
                        setSelectedCity(item);
                        handleSelectChange('city', item);
                      }} 
                      // required
                    /></div>
                </div>
                <div className="col-lg-12">
                  <div className="bill-item">
                    <label htmlFor="">Pin Code</label>
                    <input
                      type="text"
                      placeholder="Enter your pin code"
                      name="pinCode"
                      value={formData.pinCode}
                      onChange={handleInputChange}
                      maxLength="6"
                      autoComplete="off"
                      onKeyPress={(event) => {
                        if (event.charCode < 48 || event.charCode > 57 || event.target.value.length >= 15) {
                          event.preventDefault();
                        }
                      }}
                      required
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="bill-item">
                    <label htmlFor="">Additional Information</label>
                    <textarea
                      type="text"
                      placeholder="Any additional Info"
                      name="additionalInformation"
                      value={formData.additionalInformation}
                      onChange={handleInputChange}
                      maxLength="100"
                    // required
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <h4>
                  <input
                    type="checkbox"
                    checked={isSameAddress}
                    onChange={handleCheckboxChange}
                  /> Shipping address same as billing address
                </h4>
                <div className="col-lg-12">
                  <div className="bill-item">
                    <label htmlFor="">First Name</label>
                    <input
                      type="text"
                      placeholder="First Name"
                      name="firstName"
                      value={shippingData.firstName}
                      onChange={handleShippingInputChange}
                      maxLength="25"
                      required
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="bill-item">
                    <label htmlFor="">Last Name</label>
                    <input
                      type="text"
                      placeholder="Last Name"
                      name="lastName"
                      value={shippingData.lastName}
                      onChange={handleShippingInputChange}
                      maxLength="25"
                      required
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="bill-item">
                    <label htmlFor="">Mobile No.</label>
                    <input
                      type="tel"
                      placeholder="Mobile No."
                      name="phone"
                      value={shippingData.phone}
                      onChange={handleShippingInputChange}
                      maxLength="10"
                      autoComplete="off"
                      onKeyPress={(event) => {
                        if (event.charCode < 48 || event.charCode > 57 || event.target.value.length >= 15) {
                          event.preventDefault();
                        }
                      }}
                      required
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="bill-item">
                    <label htmlFor="">Alternate Phone no.</label>
                    <input
                      type="text"
                      placeholder="Enter Number"
                      name="alternatePhone"
                      value={shippingData.alternatePhone}
                      onChange={handleShippingInputChange}
                      maxLength="10"
                      autoComplete="off"
                      onKeyPress={(event) => {
                        if (event.charCode < 48 || event.charCode > 57 || event.target.value.length >= 15) {
                          event.preventDefault();
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="bill-item">
                    <label htmlFor="">Flat</label>
                    <input
                      type="text"
                      placeholder="Flat"
                      name="flat"
                      value={shippingData.flat}
                      onChange={handleInputChange}
                      maxLength="100"
                      required
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="bill-item">
                    <label htmlFor="">House No.</label>
                    <input
                      type="text"
                      placeholder="House No."
                      name="houseNo"
                      value={shippingData.houseNo}
                      onChange={handleInputChange}
                      maxLength="100"
                      required
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="bill-item">
                    <label htmlFor="">Building Name </label>
                    <input
                      type="text"
                      placeholder="Building Name"
                      name="building"
                      value={shippingData.building}
                      onChange={handleInputChange}
                      maxLength="100"
                      required
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="bill-item">
                    <label htmlFor="">Company Name</label>
                    <input
                      type="text"
                      placeholder="Company Name"
                      name="company"
                      value={shippingData.company}
                      onChange={handleInputChange}
                      maxLength="100"
                      required
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="bill-item">
                    <label htmlFor="">Apartment</label>
                    <input
                      type="text"
                      placeholder="apartment"
                      name="apartment"
                      value={shippingData.apartment}
                      onChange={handleInputChange}
                      maxLength="100"
                      required
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="bill-item">
                    <label htmlFor="">Area</label>
                    <input
                      type="text"
                      placeholder="Area"
                      name="area"
                      value={shippingData.area}
                      onChange={handleInputChange}
                      maxLength="100"
                      required
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="bill-item">
                    <label htmlFor="">Street</label>
                    <input
                      type="text"
                      placeholder="Street"
                      name="street"
                      value={shippingData.street}
                      onChange={handleInputChange}
                      maxLength="100"
                      required
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="bill-item">
                    <label htmlFor="">Sector</label>
                    <input
                      type="text"
                      placeholder="Sector"
                      name="sector"
                      value={shippingData.sector}
                      onChange={handleInputChange}
                      maxLength="100"
                      required
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="bill-item">
                    <label htmlFor="">Address</label>
                    <input
                      type="text"
                      placeholder="Address"
                      name="address"
                      value={shippingData.address}
                      onChange={handleInputChange}
                      maxLength="100"
                      required
                    />
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className="bill-item">
                    <label htmlFor="">Landmark</label>
                    <input
                      type="text"
                      placeholder="Landmark"
                      name="landmark"
                      value={shippingData.landmark}
                      onChange={handleInputChange}
                      maxLength="100"
                      required
                    />
                  </div>
                </div>
                
                <div className="col-lg-12">
                  <div className="bill-item">
                    <label htmlFor="">Country</label>
                    <Select
                      id="shipping-country"
                      options={Country.getAllCountries()}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.isoCode}
                      value={selectedShippingCountry}
                      onChange={(item) => {
                        setSelectedShippingCountry(item);
                        handleSelectChange("country", item, true);
                      }}
                      // required
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="bill-item">
                    <label htmlFor="">State</label>
                    <Select
                      id="shipping-state"
                      options={State.getStatesOfCountry(selectedShippingCountry?.isoCode || "")}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.isoCode}
                      value={selectedShippingState}
                      onChange={(item) => {
                        setSelectedShippingState(item);
                        handleSelectChange("state", item, true);
                      }}
                      // required
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="bill-item">
                    <label htmlFor="">City</label>
                    <Select
                      id="shipping-city"
                      options={City.getCitiesOfState(selectedShippingState?.countryCode || "", selectedShippingState?.isoCode || "")}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.name}
                      value={selectedShippingCity}
                      onChange={(item) => {
                        setSelectedShippingCity(item);
                        handleSelectChange("city", item, true);
                      }}
                      // required
                    />
                  </div>
                </div>
                
                <div className="col-lg-12">
                  <div className="bill-item">
                    <label htmlFor="">Pin Code</label>
                    <input
                      type="text"
                      placeholder="Enter your pin code"
                      name="pinCode"
                      value={shippingData.pinCode}
                      onChange={handleInputChange}
                      maxLength="6"
                      autoComplete="off"
                      onKeyPress={(event) => {
                        if (event.charCode < 48 || event.charCode > 57 || event.target.value.length >= 15) {
                          event.preventDefault();
                        }
                      }}
                      required
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="bill-item">
                    <label htmlFor="">Additional Information</label>
                    <textarea
                      type="text"
                      placeholder="Any additional Info"
                      name="additionalInformation"
                      value={shippingData.additionalInformation}
                      onChange={handleShippingInputChange}
                      maxLength="100"
                      // required
                      disabled={isSameAddress}
                    />
                  </div>
                </div>
              </div>
              <div className="image-button">
                <button type="submit">Update & Continue</button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Billing;
