// // import React, { useState } from "react";
// // import { Calendar, momentLocalizer } from "react-big-calendar";
// // import moment from "moment";
// // import "react-big-calendar/lib/css/react-big-calendar.css";
// // import events from "../utils/events";
// // import NewCalendar from 'react-calendar';
// // import 'react-calendar/dist/Calendar.css';

// // moment.locale("en-GB");
// // const localizer = momentLocalizer(moment);

// // export default function ReactBigCalendar({ type }) {
// //   const [eventsData, setEventsData] = useState(events);

// // //   const handleSelect = ({ start, end }) => {
// // //     console.log(start);
// // //     console.log(end);
// // //     const title = window.prompt("New Event name");
// // //     if (title)
// // //       setEventsData([
// // //         ...eventsData,
// // //         {
// // //           start,
// // //           end,
// // //           title
// // //         }
// // //       ]);
// // //   };
// // const tileClassName = ({ date, view }) => {
// //   if (view === 'month') {
// //     const day = date.getDay();
// //     if (day === 6) {
// //       return 'saturday';
// //     }
// //     if (day === 0) {
// //       return 'sunday';
// //     }
// //   }
// //   return null;
// // };

// // const formatShortWeekday = (locale, date) => {
// //   return date.toLocaleDateString(locale, { weekday: 'short' }).charAt(0).toUpperCase();
// // };

// //   const dayPropGetter = (date) => {
// //     const isSaturday = moment(date).day() === 6;
// //     const isSunday = moment(date).day() === 0;
// //     const hasEvent = eventsData.some((event) =>
// //       moment(event.start).isSame(date, 'day') ||
// //       moment(event.end).isSame(date, 'day') ||
// //       (moment(event.start).isBefore(date) && moment(event.end).isAfter(date)) // Event spans the date
// //     );

// //     let classes = '';
// //     if (isSaturday) {
// //       classes += ' weekend-date saturday';
// //     }
// //     if (isSunday) {
// //       classes += ' weekend-date sunday';
// //     }
// //     if (hasEvent) {
// //       classes += 'date-with-event';
// //     }
// //     return { className: classes };
// //   };

// //   const formats = {
// //     weekdayFormat: (date) => moment(date).format('dd').charAt(0),
// //   };

// //   return (
// //     <div className="calendar">
// //       {/* <div className="calendar-container" style={type === 1 ? { height: "50vh" } : { height: "30vh" }}>

// //     </div> */}
// //     {/* <NewCalendar
// //         tileClassName={tileClassName}
// //         formatShortWeekday={formatShortWeekday}
// //         style={type === 1 ? { height: "50vh" } : { height: "30vh" }}
// //       /> */}
// //       <Calendar
// //         views={["month"]}
// //         selectable
// //         localizer={localizer}
// //         defaultDate={new Date()}
// //         defaultView="month"
// //         // events={eventsData}
// //         style={type === 1 ? { height: "50vh" } : { height: "30vh" }}
// //         // onSelectEvent={(event) => alert(event.title)}
// //         // onSelectSlot={handleSelect}
// //         toolbar={false}
// //         formats={formats}
// //         dayPropGetter={dayPropGetter}
// //       />
// //     </div>
// //   );
// // }

// // // // import React, { useEffect, useState } from "react";
// // // // import { Calendar, momentLocalizer } from "react-big-calendar";
// // // // import moment from "moment";
// // // // import "react-big-calendar/lib/css/react-big-calendar.css";

// // // // moment.locale("en-GB");
// // // // const localizer = momentLocalizer(moment);

// // // // export default function ReactBigCalendar({ type, year, month, holidays }) {
// // // //   const [eventsData, setEventsData] = useState([]);

// // // //   useEffect(() => {
// // // //     // Convert holidays to events format used by the calendar
// // // //     const events = holidays.map((holiday) => ({
// // // //       title: holiday.summary,
// // // //       start: new Date(holiday.start.date || holiday.start.dateTime),
// // // //       end: new Date(holiday.end.date || holiday.end.dateTime || holiday.start.date || holiday.start.dateTime),
// // // //     }));
// // // //     setEventsData(events);
// // // //   }, [holidays]);

// // // //   const dayPropGetter = (date) => {
// // // //     const hasEvent = eventsData.some((event) =>
// // // //       moment(event.start).isSame(date, 'day') ||
// // // //       moment(event.end).isSame(date, 'day') ||
// // // //       (moment(event.start).isBefore(date) && moment(event.end).isAfter(date)) // Event spans the date
// // // //     );

// // // //     const classes = hasEvent ? 'date-with-event' : '';
// // // //     return { className: classes };
// // // //   };

// // // //   const formats = {
// // // //     weekdayFormat: (date) => moment(date).format('dd').charAt(0),
// // // //   };

// // // //   return (
// // // //     <div className="calendar">
// // // //       <Calendar
// // // //         views={["month"]}
// // // //         selectable
// // // //         localizer={localizer}
// // // //         defaultDate={new Date(year, month - 1)}
// // // //         defaultView="month"
// // // //         events={eventsData}
// // // //         style={type === 1 ? { height: "60vh" } : { height: "40vh" }}
// // // //         toolbar={false}
// // // //         formats={formats}
// // // //         dayPropGetter={dayPropGetter}
// // // //       />
// // // //     </div>
// // // //   );
// // // // }

// // // // // // //   return (
// // // // // // //     <div className="calendar">
// // // // // // //       <Calendar
// // // // // // //         views={["month"]}
// // // // // // //         selectable
// // // // // // //         localizer={localizer}
// // // // // // //         defaultDate={new Date()}
// // // // // // //         defaultView="month"
// // // // // // //         // events={eventsData}
// // // // // // //         style={type === 1 ? { height: "60vh" } : { height: "40vh" }}
// // // // // // //         // onSelectEvent={(event) => alert(event.title)}
// // // // // // //         // onSelectSlot={handleSelect}
// // // // // // //         toolbar={false}
// // // // // // //         formats={formats}
// // // // // // //         dayPropGetter={dayPropGetter}
// // // // // // //       />
// // // // // // //     </div>
// // // // // // //   );
// // // // // // // }

// // // // // // // // import React from "react";
// // // // // // // // import { Calendar, momentLocalizer } from "react-big-calendar";
// // // // // // // // import moment from "moment";
// // // // // // // // import "react-big-calendar/lib/css/react-big-calendar.css";

// // // // // // // // moment.locale("en-GB");
// // // // // // // // const localizer = momentLocalizer(moment);

// // // // // // // // export default function ReactBigCalendar({ type, events }) {
// // // // // // // //   const dayPropGetter = (date) => {
// // // // // // // //     const hasEvent = events.some((event) =>
// // // // // // // //       moment(event.start).isSame(date, 'day') ||
// // // // // // // //       moment(event.end).isSame(date, 'day') ||
// // // // // // // //       (moment(event.start).isBefore(date) && moment(event.end).isAfter(date)) // Event spans the date
// // // // // // // //     );

// // // // // // // //     const classes = hasEvent ? 'date-with-event' : '';
// // // // // // // //     return { className: classes };
// // // // // // // //   };

// // // // // // // //   const formats = {
// // // // // // // //     weekdayFormat: (date) => moment(date).format('dd').charAt(0),
// // // // // // // //   };

// // // // // // // //   return (
// // // // // // // //     <div className="calendar">
// // // // // // // //       <Calendar
// // // // // // // //         views={["month"]}
// // // // // // // //         selectable
// // // // // // // //         localizer={localizer}
// // // // // // // //         defaultDate={new Date()}
// // // // // // // //         defaultView="month"
// // // // // // // //         events={events}
// // // // // // // //         style={type === 1 ? { height: "60vh" } : { height: "40vh" }}
// // // // // // // //         toolbar={false}
// // // // // // // //         formats={formats}
// // // // // // // //         dayPropGetter={dayPropGetter}
// // // // // // // //       />
// // // // // // // //     </div>
// // // // // // // //   );
// // // // // // // // }

// // // // // // import React from "react";
// // // // // // import { Calendar, momentLocalizer } from "react-big-calendar";
// // // // // // import moment from "moment";
// // // // // // import "react-big-calendar/lib/css/react-big-calendar.css";

// // // // // // moment.locale("en-GB");
// // // // // // const localizer = momentLocalizer(moment);

// // // // // // export default function ReactBigCalendar({ type, events }) {
// // // // // //   const dayPropGetter = (date) => {
// // // // // //     const hasEvent = events.some((event) =>
// // // // // //       moment(event.start).isSame(date, 'day') ||
// // // // // //       moment(event.end).isSame(date, 'day') ||
// // // // // //       (moment(event.start).isBefore(date) && moment(event.end).isAfter(date)) // Event spans the date
// // // // // //     );

// // // // // //     const classes = hasEvent ? 'date-with-event' : '';
// // // // // //     return { className: classes };
// // // // // //   };

// // // // // //   const formats = {
// // // // // //     weekdayFormat: (date) => moment(date).format('dd').charAt(0),
// // // // // //   };

// // // // // //   return (
// // // // // //     <div className="calendar">
// // // // // //       <Calendar
// // // // // //         views={["month"]}
// // // // // //         selectable
// // // // // //         localizer={localizer}
// // // // // //         defaultDate={new Date()}
// // // // // //         defaultView="month"
// // // // // //         events={events}
// // // // // //         style={type === 1 ? { height: "60vh" } : { height: "40vh" }}
// // // // // //         toolbar={false}
// // // // // //         formats={formats}
// // // // // //         dayPropGetter={dayPropGetter}
// // // // // //       />
// // // // // //     </div>
// // // // // //   );
// // // // // // }

// // // // // import React from "react";
// // // // // import { Calendar, momentLocalizer } from "react-big-calendar";
// // // // // import moment from "moment";
// // // // // import "react-big-calendar/lib/css/react-big-calendar.css";

// // // // // moment.locale("en-GB");
// // // // // const localizer = momentLocalizer(moment);

// // // // // export default function ReactBigCalendar({ type, events }) {
// // // // //   const dayPropGetter = (date) => {
// // // // //     const hasEvent = events.some((event) =>
// // // // //       moment(event.start).isSame(date, 'day') ||
// // // // //       moment(event.end).isSame(date, 'day') ||
// // // // //       (moment(event.start).isBefore(date) && moment(event.end).isAfter(date)) // Event spans the date
// // // // //     );

// // // // //     const classes = hasEvent ? 'date-with-event' : '';
// // // // //     return { className: classes };
// // // // //   };

// // // // //   const formats = {
// // // // //     weekdayFormat: (date) => moment(date).format('dd').charAt(0),
// // // // //   };

// // // // //   return (
// // // // //     <div className="calendar">
// // // // //       <Calendar
// // // // //         views={["month"]}
// // // // //         selectable
// // // // //         localizer={localizer}
// // // // //         defaultDate={new Date()}
// // // // //         defaultView="month"
// // // // //         events={events}
// // // // //         style={type === 1 ? { height: "60vh" } : { height: "40vh" }}
// // // // //         toolbar={false}
// // // // //         formats={formats}
// // // // //         dayPropGetter={dayPropGetter}
// // // // //       />
// // // // //     </div>
// // // // //   );
// // // // // }

// // // // import React from "react";
// // // // import { Calendar, momentLocalizer } from "react-big-calendar";
// // // // import moment from "moment";
// // // // import "react-big-calendar/lib/css/react-big-calendar.css";

// // // // moment.locale("en-GB");
// // // // const localizer = momentLocalizer(moment);

// // // // export default function ReactBigCalendar({ type, events }) {
// // // //   const dayPropGetter = (date) => {
// // // //     const hasEvent = events.some((event) =>
// // // //       moment(event.start).isSame(date, "day") ||
// // // //       moment(event.end).isSame(date, "day") ||
// // // //       (moment(event.start).isBefore(date) && moment(event.end).isAfter(date)) // Event spans the date
// // // //     );

// // // //     const classes = hasEvent ? "date-with-event" : "";
// // // //     return { className: classes };
// // // //   };

// // // //   const formats = {
// // // //     weekdayFormat: (date) => moment(date).format("dd").charAt(0),
// // // //   };

// // // //   return (
// // // //     <div className="calendar">
// // // //       <Calendar
// // // //         views={["month"]}
// // // //         selectable
// // // //         localizer={localizer}
// // // //         defaultDate={new Date()}
// // // //         defaultView="month"
// // // //         events={events}
// // // //         style={type === 1 ? { height: "60vh" } : { height: "40vh" }}
// // // //         toolbar={false}
// // // //         formats={formats}
// // // //         dayPropGetter={dayPropGetter}
// // // //       />
// // // //     </div>
// // // //   );
// // // // }

// // // import React from "react";
// // // import { Calendar, momentLocalizer } from "react-big-calendar";
// // // import moment from "moment";
// // // import "react-big-calendar/lib/css/react-big-calendar.css";

// // // // moment.locale("en-GB");
// // // // const localizer = momentLocalizer(moment);
// // // export default function ReactBigCalendar({ type, events }) {
// // //   const dayPropGetter = (date) => {
// // //     const hasEvent = events.some(
// // //       (event) =>
// // //         moment(event.start).isSame(date, "day") ||
// // //         moment(event.end).isSame(date, "day") ||
// // //         (moment(event.start).isBefore(date) && moment(event.end).isAfter(date)) // Event spans the date
// // //     );

// // // //     const classes = hasEvent ? "date-with-event" : "";
// // // //     return { className: classes };
// // // //   };

// // // //   const formats = {
// // // //     weekdayFormat: (date) => moment(date).format("dd").charAt(0),
// // // //   };

// // //   const calendarId = "shubhammcmt01@gmail.com";
// // //   const apiKey = "AIzaSyAjfBYZVOqpBtwp6Ty55hL9-eXAVD6rKUs";
// // //   const timeMin = new Date().toISOString();
// // //   const timeMax = new Date(
// // //     new Date().setMonth(new Date().getMonth() + 1)
// // //   ).toISOString();

// // //   // const calendarSrc = "https://calendar.google.com/calendar/embed";

// // // <<<<<<< HEAD
// // // //   return (
// // // //     <div className="calendar">
// // // // <iframe
// // // //         src={calendarSrc}
// // // //         views={["month"]}
// // // //         selectable
// // // //         localizer={localizer}
// // // //         defaultDate={new Date()}
// // // //         defaultView="month"
// // // //         events={events}
// // // //         style={type === 1 ? { height: "60vh" } : { height: "40vh" }}
// // // //         toolbar={false}
// // // //         formats={formats}
// // // //         dayPropGetter={dayPropGetter}
// // // //       ></iframe>
// // // //     </div>
// // // //   );
// // // // }
// // //   return (
// // //     <div className="calendar">
// // //       <iframe
// // //         title=" "
// // //         src={calendarSrc}
// // //         views={["month"]}
// // //         selectable
// // //         localizer={localizer}
// // //         defaultDate={new Date()}
// // //         defaultView="month"
// // //         events={events}
// // //         style={type === 1 ? { height: "30vh", width: "45%" } : { height: "30vh", width: "65%" }}
// // //         toolbar={false}
// // //         formats={formats}
// // //         dayPropGetter={dayPropGetter}
// // //       ></iframe>
// // //     </div>
// // //   );
// // // }

// import React from "react";
// import moment from "moment";
// import "react-big-calendar/lib/css/react-big-calendar.css";

// const ReactBigCalendar = ({ year, month, googleCalendarEvents, view }) => {
//   const startDate = moment([year, month]);
//   const endDate = startDate.clone().endOf("month");

//   // Generate days for the calendar grid
//   const generateCalendarDays = () => {
//     const days = [];
//     let currentDay = startDate.clone().startOf("week");

//     while (currentDay.isBefore(endDate.clone().endOf("week"))) {
//       days.push(currentDay.clone());
//       currentDay.add(1, "day");
//     }

//     return days;
//   };

//   const calendarDays = generateCalendarDays();

//   // Group events by date
//   const eventsByDate = googleCalendarEvents.reduce((acc, event) => {
//     const date = moment(event.start).format("YYYY-MM-DD");
//     if (!acc[date]) {
//       acc[date] = [];
//     }
//     acc[date].push(event);
//     return acc;
//   }, {});

//   // Render table headers
//   const renderTableHeaders = () => (
//     <tr className="calendar-head">
//       {["S", "M", "T", "W", "T", "F", "S"].map((day) => (
//         <th key={day}>{day}</th>
//       ))}
//     </tr>
//   );

//   // Render table cells
//   const renderTableCells = () => {
//     const weeks = [];
//     let days = [];

//     calendarDays.forEach((day, index) => {
//       if (index % 7 === 0 && days.length > 0) {
//         weeks.push(days);
//         days = [];
//       }

//       const date = day.format("YYYY-MM-DD");
//       days.push(
//         <td
//           key={date}
//           className={day.month() !== month ? "inactive-day" : "calendar-day"}
//         >
//           <div className="date">{day.date()}</div>
//           {eventsByDate[date] &&
//             eventsByDate[date].map((event, idx) => (
//               <div key={idx} className="event">
//                 {/* {event.title} */}
//               </div>
//             ))}
//         </td>
//       );
//     });

//     weeks.push(days); // push the last week

//     return weeks.map((week, idx) => <tr key={idx}>{week}</tr>);
//   };

//   return (
//     <div
//       className={
//         view === "Vertical" ? "portrait-calendar" : "landscape-calendar"
//       }
//     >
//       <table className="calendar-table">
//         <thead>{renderTableHeaders()}</thead>
//         <tbody>{renderTableCells()}</tbody>
//       </table>
//     </div>
//   );
// };

// export default ReactBigCalendar;

import React from "react";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";

const ReactBigCalendar = ({ year, month, googleCalendarEvents, view, calendarData }) => {
  const startDate = moment([year, month]);
  const endDate = startDate.clone().endOf("month");

  // Generate days for the calendar grid
  const generateCalendarDays = () => {
    const days = [];
    let currentDay = startDate.clone().startOf("week");

    while (currentDay.isBefore(endDate.clone().endOf("week"))) {
      days.push(currentDay.clone());
      currentDay.add(1, "day");
    }

    return days;
  };

  const calendarDays = generateCalendarDays();

  // Group events by date
  const eventsByDate = googleCalendarEvents.reduce((acc, event) => {
    const date = moment(event.start).format("YYYY-MM-DD");
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(event);
    return acc;
  }, {});

  // Function to get the color for a date based on specific events in January
  const getDateColor = (day) => {
    const dayOfMonth = day.date();
    const monthName = moment.months()[month].toLowerCase();

    for (let i = 1; i <= 4; i++) {
      if (calendarData[`${monthName}Event${i}Date`] === dayOfMonth) {
        return calendarData[`${monthName}Event${i}Color`] || "#000000"; // Default to black if no color is defined
      }
    }

    return ; // Default to black if no events
  };

  // Render table headers
  const renderTableHeaders = () => (
    <tr className={view === "landscape" ? "calendar-head" : "calendar-head-portrait"}>
      {["S", "M", "T", "W", "T", "F", "S"].map((day) => (
        <th key={day}>{day}</th>
      ))}
    </tr>
  );

  const getClassName = (day, month, view) => {
    if (day.month() !== month) {
      return view === "vertical" ? "inactive-day-portrait" : "inactive-day";
    } else {
      return view === "vertical" ? "calendar-day-portrait" : "calendar-day";
    }
  };

  // Render table cells
  const renderTableCells = () => {
    const weeks = [];
    let days = [];

    calendarDays.forEach((day, index) => {
      if (index % 7 === 0 && days.length > 0) {
        weeks.push(days);
        days = [];
      }

      const date = day.format("YYYY-MM-DD");
      days.push(
        <td
          key={date}
          className={getClassName(day, month, view)}
        >
          <div className="date" style={{ color: getDateColor(day) }}>{day.date()}</div>
          {eventsByDate[date] &&
            eventsByDate[date].map((event, idx) => (
              <div key={idx} className="event">
                {/* {event.title} */}
              </div>
            ))}
        </td>
      );
    });

    weeks.push(days); // push the last week

    return weeks.map((week, idx) => <tr key={idx}>{week}</tr>);
  };

  return (
    <div
      className={
        view === "Vertical" ? "portrait-calendar" : "landscape-calendar"
      }
    >
      <table className="calendar-table">
        <thead>{renderTableHeaders()}</thead>
        <tbody>{renderTableCells()}</tbody>
      </table>
    </div>
  );
};

export default ReactBigCalendar;
