import React, { useEffect, useRef, useState } from 'react'
import axios from 'axios';
import AdminSidebar from './AdminSidebar';
import { useAuth } from '../AuthContext';
import $ from 'jquery';
import { Modal } from "react-bootstrap";

const CustomerDetails = () => {

  const [users, setUsers] = useState([]);
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { apipath } = useAuth();
  const dataTableRef = useRef();
  const [loading, setLoading] = useState(true);
  const [userIdToDelete, setUserIdToDelete] = useState(null);

  const handleShow = (id) => {
    setUserIdToDelete(id);
    setShow(true);
  };
  const handleClose = () => setShow(false);

  useEffect(() => {
    fetchUsers();
    if (!loading && users.length > 0) {
      $(dataTableRef.current).DataTable();
    }
  }, [loading, users]);

  // const fetchUsers = async () => {
  //   try {
  //     const response = await axios.get(apipath + '/users/details');
  //     setUsers(response.data.user);
  //     setLoading(false);
  //   } catch (error) {
  //     console.error('Error fetching courses:', error);
  //     setLoading(false);
  //   }
  // };

  const fetchUsers = async () => {
    try {
      const response = await axios.get(apipath + '/users/details');
  
      // Filter users with the role 'user'
      const filteredUsers = response.data.user.filter(user => user.role === 'user');
  
      setUsers(filteredUsers);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching users:', error);
      setLoading(false);
    }
  };

  
  const handleDelete = async () => {
    try {
      setIsLoading(true);
      await axios.delete(apipath + `/users/details/${userIdToDelete}`);
      await fetchUsers(); // Refresh courses after deletion
      setShow(false);
      setIsLoading(false);
    } catch (error) {
      console.error('Error deleting course:', error);
      setIsLoading(false);
      // setLoading(false);
    }
  };

  return (
    <>
      <AdminSidebar />
      <div className="home">
        <div class="toggle-sidebar" style={{ display: "none" }}>
          <i class="bi bi-menu"></i>
        </div>

        <Modal show={show} onHide={handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>Delete User</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="modal-content1">
              <p>Are you sure you want to delete this user?</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="modal-footer-delete-btn" onClick={handleDelete}>
              Delete
            </button>
          </Modal.Footer>
        </Modal>

        <div className="dashboard">
          <div className="dashboard-header">
            <h1>Customer Management</h1>
          </div>

          <div className="container-fluid">
            <div className="row foot-tablerow">
              <div className="col-lg-11 maintable-column mx-auto">
                <div className="container mt-4">
                  <table id="datatable" className="table" ref={dataTableRef}>
                    <thead>
                      <tr className="tr1">
                        <th>Customer S.No.</th>
                        <th>Customer Id</th>
                        <th>Customer Name</th>
                        <th>Mobile Number</th>
                        <th>Email</th>
                        <th>Status</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {users.map((user, index) => (
                        <tr key={user._id}>
                          <td className='text-center'>{index + 1}</td>
                          <td>{user._id}</td>
                          <td>{user.name}</td>
                          <td>{user.phone}</td>
                          <td>{user.email}</td>
                          <td className={user.status ? "success" : "pending"}>
                            {user.status ? (
                              <span>
                                <i className="fa-solid fa-check"></i> Active
                              </span>
                            ) : (
                              <span>
                                <i className="fa-solid fa-spinner"></i> Inactive
                              </span>
                            )}
                          </td>

                          <td>
                            {/* <i className="fa-regular fa-pen-to-square"></i> {" "} */}
                            <i
                              className="fa-solid fa-trash"
                              // onClick={() => handleDelete(user._id)}
                              onClick={() => handleShow(user._id)}
                              style={{ cursor: 'pointer' }}
                            ></i>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CustomerDetails