import React from "react";
import { Link } from "react-router-dom";

const Sms = () => {


  return (
    <>
      <div className="container-fluid login">
        <div className="row login-row">
          <div className="col-lg-6">
            <div className="img-login">
              <img src="imgs-calendar/password.png" alt="" />
            </div>
          </div>
          <div className="col-lg-6 login-right">
            <h1>Login using SMS</h1>
            <p>Please enter your Mobile No </p>
            <form action="/" className="pt-4">
              <div className="login-item col-lg-8 mx-auto">
                <label htmlFor="name">Enter Mobile No. </label>
                <input type="text" placeholder="Enter Mobile No." />
              </div>
              
              <div className="login-button col-lg-8 mx-auto">
                <Link to="/otp-verify">
                  <button>Send OTP</button>
                </Link>
              </div>
              <p className="account col-lg-8 mx-auto">
                Don't Have an Account ?{" "}
                <Link to="/register" className="text-decoration-none">
                  <span>Register</span>
                </Link>
              </p>
             
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sms;
