import React from "react";
import { Link, useParams } from "react-router-dom";

const Page4= () => {
  
  const { calendarId } = useParams();
  return (
    <>
      <div className="container preview">
        <div className="top-buttons">
          <Link to={`/preview3/${calendarId}`}>
            <button>
              {" "}
              <i className="bi bi-chevron-left"></i> Previous
            </button>
          </Link>
          <Link to={`/preview15/${calendarId}`}>
            <button>
              {" "}
              Next <i className="bi bi-chevron-right"></i>
            </button>
          </Link>
        </div>
        
      </div>
      <div className="container-fluid prev-img">
        <img src={`${process.env.PUBLIC_URL}/imgs-calendar/portrait.png`} alt="" />
      </div>
    </>
  );
};

export default Page4;
