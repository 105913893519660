import React, { useState, useEffect } from 'react';
import AdminSidebar from './AdminSidebar';
import iziToast from "izitoast";
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import validator from "validator";
import axios from 'axios';

const CorporateEdit = () => {
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();
    const { apipath, filepath } = useAuth();
    const { corporateId } = useParams();  // Get corporateId from route params
    const [logoImageUrl, setLogoImageUrl] = useState(null);

    const [formData, setFormData] = useState({
        companyName: '',
        name: '',
        phone: '',
        email: '',
        city: '',
        state: '',
        country: '',
        address: '',
        pinCode: '',
        logoImage: null,
        price: '',
        couponQuantity: '', 
        corporateWebSiteName: '',
    });

    useEffect(() => {
        fetchCorporateDetails();
    }, []);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const previewUrl = URL.createObjectURL(file);
            setFormData((prevDetails) => ({
                ...prevDetails,
                logoImage: file,
            }));
            setLogoImageUrl(previewUrl);
        }
    };

    // const fetchCorporateDetails = async () => {
    //     try {
    //         const response = await axios.get(
    //             apipath + `/corporate/details/${corporateId}`
    //         );
    //         const corporateData = response.data.corporate;
    //         // Handle couponQuantity array
    //         const lastCouponQuantity = Array.isArray(corporateData.couponQuantity) 
    //         ? corporateData.couponQuantity[corporateData.couponQuantity.length - 1] 
    //         : corporateData.couponQuantity;

    //         setFormData({
    //         ...corporateData,
    //         couponQuantity: lastCouponQuantity // Set the last value from array
    //         });

    //         if (corporateData.logoImage) {
    //             setLogoImageUrl(filepath + '/' + corporateData.logoImage);
    //         }
    //     } catch (error) {
    //         console.error("Error fetching corporate Details:", error);
    //     }
    // };

    // const handleUpdate = async (e) => {
    //     e.preventDefault();

    //     const validationErrors = {};
    //     if (!formData.companyName) validationErrors.companyName = 'Company Name is required';
    //     if (!formData.name) validationErrors.name = 'Name is required';
    //     if (!formData.email) validationErrors.email = 'Email is required';
    //     else if (!validator.isEmail(formData.email)) validationErrors.email = 'Invalid email address';
    //     if (!formData.phone) validationErrors.phone = 'Phone number is required';
    //     if (!formData.city) validationErrors.city = 'City is required';
    //     if (!formData.state) validationErrors.state = 'State is required';
    //     if (!formData.country) validationErrors.country = 'Country is required';
    //     if (!formData.address) validationErrors.address = 'Address is required';
    //     if (!formData.price) validationErrors.price = 'Price are required';
    //     if (!formData.couponQuantity) validationErrors.couponQuantity = 'Coupon Quantity is required';
    //     if (!formData.corporateWebSiteName) validationErrors.corporateWebSiteName = 'Corporate WebSite Name is required';
    //     if (!formData.pinCode) validationErrors.pinCode = 'Pin Code is required';

    //     setErrors(validationErrors);
    //     if (Object.keys(validationErrors).length > 0) return;

    //     // Create form data for the API call
    //     const updateFormData = new FormData();
    //     const couponQuantityArray = [parseInt(formData.couponQuantity)];
    //    // Add all other form fields
    //    for (const key in formData) {
    //     if (key === 'couponQuantity') {
    //         updateFormData.append('couponQuantity', JSON.stringify(couponQuantityArray));
    //     } else {
    //         updateFormData.append(key, formData[key]);
    //     }
    // }
    //     try {
    //         const response = await fetch(`${apipath}/corporate/details/${corporateId}`, {
    //             method: 'PUT',
    //             body: updateFormData,  // Send formData
    //         });
    //         if (response.status === 200) {
    //             iziToast.success({
    //                 title: 'Success',
    //                 message: 'Corporate details updated successfully',
    //                 position: 'topCenter',
    //                 timeout: 2000,
    //             });
    //             navigate('/corporate');
    //         } else {
    //             iziToast.error({
    //                 title: 'Error',
    //                 message: 'Update failed. Please try again later.',
    //                 position: 'topCenter',
    //             });
    //         }
    //     } catch (error) {
    //         console.error("Corporate update error:", error);
    //         iziToast.error({
    //             title: 'Error',
    //             message: 'Update failed. Please try again later.',
    //             position: 'topCenter',
    //         });
    //     }
    // };

    // Inside fetchCorporateDetails function
    const fetchCorporateDetails = async () => {
        try {
            const response = await axios.get(`${apipath}/corporate/details/${corporateId}`);
            const corporateData = response.data.corporate;
    
            // Ensure couponQuantity is an integer, not an array
            const lastCouponQuantity = Array.isArray(corporateData.couponQuantity)
                ? corporateData.couponQuantity[corporateData.couponQuantity.length - 1]
                : corporateData.couponQuantity;
    
            setFormData({
                ...corporateData,
                couponQuantity: Number(lastCouponQuantity) || 0, // Ensure it's a number (integer)
            });
    
            if (corporateData.logoImage) {
                setLogoImageUrl(`${filepath}/${corporateData.logoImage}`);
            }
        } catch (error) {
            console.error("Error fetching corporate details:", error);
        }
    };
    

// Inside handleUpdate function
const handleUpdate = async (e) => {
    e.preventDefault();
    const validationErrors = {};
    if (!formData.companyName) validationErrors.companyName = 'Company Name is required';
    if (!formData.name) validationErrors.name = 'Name is required';
    if (!formData.email) validationErrors.email = 'Email is required';
    else if (!validator.isEmail(formData.email)) validationErrors.email = 'Invalid email address';
    if (!formData.phone) validationErrors.phone = 'Phone number is required';
    if (!formData.city) validationErrors.city = 'City is required';
    if (!formData.state) validationErrors.state = 'State is required';
    if (!formData.country) validationErrors.country = 'Country is required';
    if (!formData.address) validationErrors.address = 'Address is required';
    if (!formData.price) validationErrors.price = 'Price are required';
    if (!formData.couponQuantity) validationErrors.couponQuantity = 'Coupon Quantity is required';
    if (!formData.corporateWebSiteName) validationErrors.corporateWebSiteName = 'Corporate WebSite Name is required';
    if (!formData.pinCode) validationErrors.pinCode = 'Pin Code is required';

    setErrors(validationErrors);
    if (Object.keys(validationErrors).length > 0) return;

    // Prepare form data
    const updateFormData = new FormData();

    // Ensure couponQuantity is set correctly as a single integer value
    updateFormData.append('couponQuantity', parseInt(formData.couponQuantity, 10));

    for (const key in formData) {
        if (key !== 'logoImage' || formData[key]) {
            updateFormData.append(key, formData[key]);
        }
    }

    try {
        const response = await axios.put(`${apipath}/corporate/details/${corporateId}`, updateFormData);
        if (response.status === 200) {
            iziToast.success({
                title: 'Success',
                message: 'Corporate details updated successfully',
                position: 'topCenter',
                timeout: 2000,
            });
            navigate('/corporate');
        } else {
            iziToast.error({
                title: 'Error',
                message: 'Update failed. Please try again later.',
                position: 'topCenter',
            });
        }
    } catch (error) {
        console.error("Corporate update error:", error);
        iziToast.error({
            title: 'Error',
            message: 'Update failed. Please try again later.',
            position: 'topCenter',
        });
    }
};

const handleChange = (e) => {
    const { name, value, type, checked, files } = e.target;
    setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: type === 'checkbox' ? checked : type === 'file' ? files[0] : value,
    }));

    if (errors[name]) {
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: '',
        }));
    }
};

    return (
        <>
            <AdminSidebar />
            <div className="home">
                <div className="dashboard">
                    <div className="dashboard-header">
                        <h1>Edit Corporate</h1>
                    </div>
                    <div className="container-fluid mt-3">
                        <form className="row login-form" onSubmit={handleUpdate}>

                            <div className="mb-3 col-lg-6">
                                <label htmlFor="companyName" className='mb-2'>Company Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Company name"
                                    name="companyName"
                                    value={formData.companyName}
                                    onChange={handleChange}
                                    maxLength="100"
                                // required
                                />
                                {errors.companyName && <div className="text-danger">{errors.companyName}</div>}
                            </div>

                            <div className="mb-3 col-lg-6">
                                <label htmlFor="name" className='mb-2'>Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter name"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                    maxLength="100"
                                // required
                                />
                                {errors.name && <div className="text-danger">{errors.name}</div>}
                            </div>

                            <div className="mb-3 col-lg-6">
                                <label htmlFor="phone" className='mb-2'>Phone Number</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter phone number"
                                    name="phone"
                                    value={formData.phone}
                                    onChange={handleChange}
                                    maxLength="10"
                                    autoComplete="off"
                                    onKeyPress={(event) => {
                                        if (event.charCode < 48 || event.charCode > 57 || event.target.value.length >= 15) {
                                            event.preventDefault();
                                        }
                                    }}
                                // required
                                />
                                {errors.phone && <div className="text-danger">{errors.phone}</div>}
                            </div>

                            <div className="mb-3 col-lg-6">
                                <label htmlFor="email" className='mb-2'>Email</label>
                                <input
                                    type="email"
                                    className="form-control"
                                    placeholder="Enter email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    maxLength="100"
                                // required
                                />
                                {errors.email && <div className="text-danger">{errors.email}</div>}
                            </div>

                            <div className="mb-3 col-lg-6">
                                <label htmlFor="city" className='mb-2'>City</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter city"
                                    name="city"
                                    value={formData.city}
                                    onChange={handleChange}
                                    maxLength="50"
                                // required
                                />
                                {errors.city && <div className="text-danger">{errors.city}</div>}
                            </div>
                            <div className="mb-3 col-lg-6">
                                <label htmlFor="state" className='mb-2'>State</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter state"
                                    name="state"
                                    value={formData.state}
                                    onChange={handleChange}
                                    maxLength="50"
                                // required
                                />
                                {errors.state && <div className="text-danger">{errors.state}</div>}
                            </div>
                            <div className="mb-3 col-lg-6">
                                <label htmlFor="country" className='mb-2'>Country</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter country"
                                    name="country"
                                    value={formData.country}
                                    onChange={handleChange}
                                    maxLength="50"
                                // required
                                />
                                {errors.country && <div className="text-danger">{errors.country}</div>}
                            </div>
                            <div className="mb-3 col-lg-6">
                                <label htmlFor="address" className='mb-2'>Address</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter address"
                                    name="address"
                                    value={formData.address}
                                    onChange={handleChange}
                                    maxLength="100"
                                // required
                                />
                                {errors.address && <div className="text-danger">{errors.address}</div>}
                            </div>

                            <div className="mb-3 col-lg-6">
                                <label htmlFor="pinCode" className='mb-2'>Pin Code</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter pin code"
                                    name="pinCode"
                                    value={formData.pinCode}
                                    onChange={handleChange}  
                                    maxLength="6"
                                    autoComplete="off"
                                    onKeyPress={(event) => {
                                        if (event.charCode < 48 || event.charCode > 57 || event.target.value.length >= 15) {
                                            event.preventDefault();
                                        }
                                    }}
                                // required
                                />
                                {errors.pinCode && <div className="text-danger">{errors.pinCode}</div>}
                            </div>

                            <div className="mb-3 col-lg-6">
                                <label htmlFor="price" className='mb-2'>Price</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter price"
                                    name="price"
                                    value={formData.price}
                                    onChange={handleChange}
                                    maxLength="5"
                                    autoComplete="off"
                                    onKeyPress={(event) => {
                                        if (event.charCode < 48 || event.charCode > 57 || event.target.value.length >= 15) {
                                            event.preventDefault();
                                        }
                                    }}
                                // required
                                />
                                {errors.price && <div className="text-danger">{errors.price}</div>}
                            </div>

                            <div className="mb-3 col-lg-6">
                                <label htmlFor="couponQuantity" className='mb-2'>Coupon Quantity</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter coupon quantity"
                                    name="couponQuantity"
                                    value={formData.couponQuantity}
                                    onChange={handleChange}
                                    maxLength="5"
                                    autoComplete="off"
                                    onKeyPress={(event) => {
                                        if (event.charCode < 48 || event.charCode > 57 || event.target.value.length >= 15) {
                                            event.preventDefault();
                                        }
                                    }}
                                />
                                {errors.couponQuantity && <div className="text-danger">{errors.couponQuantity}</div>}
                            </div>

                            <div className="mb-3 col-lg-6">
                                <label htmlFor="corporateWebSiteName" className='mb-2'>Corporate WebSite Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter corporate WebSite Name"
                                    name="corporateWebSiteName"
                                    value={formData.corporateWebSiteName}
                                    onChange={handleChange}
                                    maxLength="100"
                                />
                                {errors.corporateWebSiteName && <div className="text-danger">{errors.corporateWebSiteName}</div>}
                            </div>
                            <div className="change-profile pt-4 pb-4">
                                {logoImageUrl && (
                                    <img
                                        src={logoImageUrl || `${process.env.PUBLIC_URL}/imgs-calendar/images.png`}
                                        width={150}
                                        height={100}
                                        style={{ borderRadius: '35px' }}
                                        alt="profile-img"
                                        onClick={() => document.getElementById('logoImageInput').click()}
                                    />
                                )}
                                <h6>Choose Corporate Logo</h6>
                                <input
                                    className="form-control mt-4"
                                    type="file"
                                    id="logoImageInput"
                                    name="logoImage"
                                    onChange={(e) => handleFileChange(e, 'logoImage')}
                                    style={{ display: 'none' }}
                                />
                            </div>

                            <div className="mb-3 col-lg-12">
                                <button type="submit" className="btn btn-primary">Update Corporate</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CorporateEdit;

// import React, { useState, useEffect } from 'react';
// import AdminSidebar from './AdminSidebar';
// import iziToast from "izitoast";
// import { useNavigate, useParams } from 'react-router-dom';
// import { useAuth } from '../AuthContext';
// import validator from "validator";
// import axios from 'axios';

// const CorporateEdit = () => {
//     const [errors, setErrors] = useState({});
//     const navigate = useNavigate();
//     const { apipath, filepath } = useAuth();
//     const { corporateId } = useParams();
//     const [logoImageUrl, setLogoImageUrl] = useState(null);

//     const [formData, setFormData] = useState({
//         companyName: '',
//         name: '',
//         phone: '',
//         email: '',
//         city: '',
//         state: '',
//         country: '',
//         address: '',
//         pinCode: '',
//         logoImage: null,
//         price: '',
//         couponQuantity: '', 
//         corporateWebSiteName: '',
//     });

//     useEffect(() => {
//         fetchCorporateDetails();
//     }, []);

//     const handleFileChange = (event) => {
//         const file = event.target.files[0];
//         if (file) {
//             setFormData((prevData) => ({ ...prevData, logoImage: file }));
//             setLogoImageUrl(URL.createObjectURL(file));
//         }
//     };

// // Inside fetchCorporateDetails function
// const fetchCorporateDetails = async () => {
//     try {
//         const response = await axios.get(`${apipath}/corporate/details/${corporateId}`);
//         const corporateData = response.data.corporate;

//         // Ensure couponQuantity is an integer, not an array
//         const lastCouponQuantity = Array.isArray(corporateData.couponQuantity)
//             ? corporateData.couponQuantity[corporateData.couponQuantity.length - 1] 
//             : corporateData.couponQuantity;
                
//         setFormData({
//             ...corporateData,
//             couponQuantity: lastCouponQuantity || 0, // Set a default value if undefined
//         });

//         if (corporateData.logoImage) {
//             setLogoImageUrl(`${filepath}/${corporateData.logoImage}`);
//         }
//     } catch (error) {
//         console.error("Error fetching corporate details:", error);
//     }
// };

// // Inside handleUpdate function
// const handleUpdate = async (e) => {
//     e.preventDefault();
//     const validationErrors = {};

//     // Validation logic...
//     if (!formData.companyName) validationErrors.companyName = 'Company Name is required';
//     // (other validations here)
//     if (!formData.pinCode) validationErrors.pinCode = 'Pin Code is required';

//     setErrors(validationErrors);
//     if (Object.keys(validationErrors).length > 0) return;

//     // Prepare form data
//     const updateFormData = new FormData();

//     // Ensure couponQuantity is set correctly as a single integer value, not an array
//     updateFormData.append('couponQuantity', parseInt(formData.couponQuantity));

//     for (const key in formData) {
//         if (key !== 'logoImage' || formData[key]) {
//             updateFormData.append(key, formData[key]);
//         }
//     }

//     try {
//         const response = await axios.put(`${apipath}/corporate/details/${corporateId}`, updateFormData);
//         if (response.status === 200) {
//             iziToast.success({
//                 title: 'Success',
//                 message: 'Corporate details updated successfully',
//                 position: 'topCenter',
//                 timeout: 2000,
//             });
//             navigate('/corporate');
//         } else {
//             iziToast.error({
//                 title: 'Error',
//                 message: 'Update failed. Please try again later.',
//                 position: 'topCenter',
//             });
//         }
//     } catch (error) {
//         console.error("Corporate update error:", error);
//         iziToast.error({
//             title: 'Error',
//             message: 'Update failed. Please try again later.',
//             position: 'topCenter',
//         });
//     }
// };


//     const handleChange = (e) => {
//         const { name, value } = e.target;
//         setFormData((prevData) => ({
//             ...prevData,
//             [name]: value
//         }));

//         if (errors[name]) {
//             setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
//         }
//     };
//     return (
//         <>
//             <AdminSidebar />
//             <div className="home">
//                 <div className="dashboard">
//                     <h1>Edit Corporate</h1>
//                     <form className="row login-form" onSubmit={handleUpdate}>
//                         {/* Fields */}
//                         <div className="mb-3 col-lg-6">
//                             <label htmlFor="companyName">Company Name</label>
//                             <input type="text" name="companyName" value={formData.companyName} onChange={handleChange} />
//                             {errors.companyName && <div className="text-danger">{errors.companyName}</div>}
//                         </div>
//                         <div className="mb-3 col-lg-6">
//                                 <label htmlFor="name" className='mb-2'>Name</label>
//                                 <input
//                                     type="text"
//                                     className="form-control"
//                                     placeholder="Enter name"
//                                     name="name"
//                                     value={formData.name}
//                                     onChange={handleChange}
//                                     maxLength="100"
//                                 // required
//                                 />
//                                 {errors.name && <div className="text-danger">{errors.name}</div>}
//                             </div>

//                             <div className="mb-3 col-lg-6">
//                                 <label htmlFor="phone" className='mb-2'>Phone Number</label>
//                                 <input
//                                     type="text"
//                                     className="form-control"
//                                     placeholder="Enter phone number"
//                                     name="phone"
//                                     value={formData.phone}
//                                     onChange={handleChange}
//                                     maxLength="10"
//                                     autoComplete="off"
//                                     onKeyPress={(event) => {
//                                         if (event.charCode < 48 || event.charCode > 57 || event.target.value.length >= 15) {
//                                             event.preventDefault();
//                                         }
//                                     }}
//                                 // required
//                                 />
//                                 {errors.phone && <div className="text-danger">{errors.phone}</div>}
//                             </div>

//                             <div className="mb-3 col-lg-6">
//                                 <label htmlFor="email" className='mb-2'>Email</label>
//                                 <input
//                                     type="email"
//                                     className="form-control"
//                                     placeholder="Enter email"
//                                     name="email"
//                                     value={formData.email}
//                                     onChange={handleChange}
//                                     maxLength="100"
//                                 // required
//                                 />
//                                 {errors.email && <div className="text-danger">{errors.email}</div>}
//                             </div>

//                             <div className="mb-3 col-lg-6">
//                                 <label htmlFor="city" className='mb-2'>City</label>
//                                 <input
//                                     type="text"
//                                     className="form-control"
//                                     placeholder="Enter city"
//                                     name="city"
//                                     value={formData.city}
//                                     onChange={handleChange}
//                                     maxLength="50"
//                                 // required
//                                 />
//                                 {errors.city && <div className="text-danger">{errors.city}</div>}
//                             </div>
//                             <div className="mb-3 col-lg-6">
//                                 <label htmlFor="state" className='mb-2'>State</label>
//                                 <input
//                                     type="text"
//                                     className="form-control"
//                                     placeholder="Enter state"
//                                     name="state"
//                                     value={formData.state}
//                                     onChange={handleChange}
//                                     maxLength="50"
//                                 // required
//                                 />
//                                 {errors.state && <div className="text-danger">{errors.state}</div>}
//                             </div>
//                             <div className="mb-3 col-lg-6">
//                                 <label htmlFor="country" className='mb-2'>Country</label>
//                                 <input
//                                     type="text"
//                                     className="form-control"
//                                     placeholder="Enter country"
//                                     name="country"
//                                     value={formData.country}
//                                     onChange={handleChange}
//                                     maxLength="50"
//                                 // required
//                                 />
//                                 {errors.country && <div className="text-danger">{errors.country}</div>}
//                             </div>
//                             <div className="mb-3 col-lg-6">
//                                 <label htmlFor="address" className='mb-2'>Address</label>
//                                 <input
//                                     type="text"
//                                     className="form-control"
//                                     placeholder="Enter address"
//                                     name="address"
//                                     value={formData.address}
//                                     onChange={handleChange}
//                                     maxLength="100"
//                                 // required
//                                 />
//                                 {errors.address && <div className="text-danger">{errors.address}</div>}
//                             </div>

//                             <div className="mb-3 col-lg-6">
//                                 <label htmlFor="pinCode" className='mb-2'>Pin Code</label>
//                                 <input
//                                     type="text"
//                                     className="form-control"
//                                     placeholder="Enter pin code"
//                                     name="pinCode"
//                                     value={formData.pinCode}
//                                     onChange={handleChange}  
//                                     maxLength="6"
//                                     autoComplete="off"
//                                     onKeyPress={(event) => {
//                                         if (event.charCode < 48 || event.charCode > 57 || event.target.value.length >= 15) {
//                                             event.preventDefault();
//                                         }
//                                     }}
//                                 // required
//                                 />
//                                 {errors.pinCode && <div className="text-danger">{errors.pinCode}</div>}
//                             </div>

//                             <div className="mb-3 col-lg-6">
//                                 <label htmlFor="price" className='mb-2'>Price</label>
//                                 <input
//                                     type="text"
//                                     className="form-control"
//                                     placeholder="Enter price"
//                                     name="price"
//                                     value={formData.price}
//                                     onChange={handleChange}
//                                     maxLength="5"
//                                     autoComplete="off"
//                                     onKeyPress={(event) => {
//                                         if (event.charCode < 48 || event.charCode > 57 || event.target.value.length >= 15) {
//                                             event.preventDefault();
//                                         }
//                                     }}
//                                 // required
//                                 />
//                                 {errors.price && <div className="text-danger">{errors.price}</div>}
//                             </div>

//                             <div className="mb-3 col-lg-6">
//                                 <label htmlFor="couponQuantity" className='mb-2'>Coupon Quantity</label>
//                                 <input
//                                     type="number"
//                                     className="form-control"
//                                     placeholder="Enter coupon quantity"
//                                     name="couponQuantity"
//                                     value={formData.couponQuantity}
//                                     onChange={handleChange}
//                                     maxLength="5"
//                                     autoComplete="off"
//                                     onKeyPress={(event) => {
//                                         if (event.charCode < 48 || event.charCode > 57 || event.target.value.length >= 15) {
//                                             event.preventDefault();
//                                         }
//                                     }}
//                                 />
//                                 {errors.couponQuantity && <div className="text-danger">{errors.couponQuantity}</div>}
//                             </div>

//                             <div className="mb-3 col-lg-6">
//                                 <label htmlFor="corporateWebSiteName" className='mb-2'>Corporate WebSite Name</label>
//                                 <input
//                                     type="text"
//                                     className="form-control"
//                                     placeholder="Enter corporate WebSite Name"
//                                     name="corporateWebSiteName"
//                                     value={formData.corporateWebSiteName}
//                                     onChange={handleChange}
//                                     maxLength="100"
//                                 />
//                                 {errors.corporateWebSiteName && <div className="text-danger">{errors.corporateWebSiteName}</div>}
//                             </div>
//                         <div className="mb-3 col-lg-6">
//                             <label htmlFor="logoImage">Logo</label>
//                             <input type="file" onChange={handleFileChange} />
//                             {logoImageUrl && <img src={logoImageUrl} alt="Logo Preview" />}
//                         </div>
//                         <button type="submit">Update</button>
//                     </form>
//                 </div>
//             </div>
//         </>
//     );
// };

// export default CorporateEdit;
