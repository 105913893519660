// import React, { useEffect, useRef, useState } from "react";
// import html2canvas from "html2canvas";
// import jsPDF from "jspdf";

// import { Link, useParams } from "react-router-dom";
// import axios from "axios";
// import { useAuth } from "../AuthContext";

// function CouponViewDownload() {
//   const [corporates, setCorporates] = useState([]);
//   const [corporateCode, setCorporateCode] = useState([]);
//   const [couponQuantity, setCouponQuantity] = useState(0); // State for coupon quantity
//   const couponRefs = useRef([]); // Array to hold references to each coupon div
//   const { filepath, apipath } = useAuth();
//   const { corporateId } = useParams();
//   const [isLoading, setIsLoading] = useState(false); // Loader state

//   useEffect(() => {
//     fetchCorporates();
//   }, []);

//   const fetchCorporates = async () => {
//     try {
//       const response = await axios.get(
//         apipath + `/corporate/details/${corporateId}`
//       );
//       const corporateData = response.data.corporate;
//       setCorporates(corporateData);
//       setCorporateCode(corporateData.couponCodes);
//       setCouponQuantity(corporateData.couponQuantity || 1); // Set coupon quantity, default 1 if undefined
//     } catch (error) {
//       console.error("Error fetching corporate:", error);
//     }
//   };

//   const handleDownloadPdf = async () => {
//     setIsLoading(true);
//     const pdf = new jsPDF({
//       orientation: "landscape",
//       unit: "mm",
//       format: [98, 60], // Size in mm
//     });
//     try {
//     for (let i = 0; i < couponQuantity; i++) {
//       const element = couponRefs.current[i];
//       const canvas = await html2canvas(element);
//       const data = canvas.toDataURL("image/png");

//       const imgWidth = 88.9; // Image width in mm
//       const imgHeight = 50.8; // Image height in mm
//       const xMargin = (98 - imgWidth) / 2; // Horizontal margin
//       const yMargin = (60 - imgHeight) / 2; // Vertical margin

//       // Add the image to the PDF
//       pdf.addImage(data, "PNG", xMargin, yMargin, imgWidth, imgHeight);

//       // If it's not the last page, add a new page
//       if (i < couponQuantity - 1) {
//         pdf.addPage();
//       }
//     }

//     // pdf.save("coupon-design.pdf");
//     pdf.save(`${corporateCode}.pdf`);
//   } catch (error) {
//     console.error("Error generating PDF:", error);
//   } finally {
//     setIsLoading(false); // End loading
//   }
//   };

//   return (
//     <div className="container-coupon">
//       {/* Download Button */}
//       <div className="container custom-container sticky-top">
//       {/* <div>
//       <button className="pre-button">
//             <Link
//               to={`/corporate-coupon`}
//               className="back-link"
//             >
//               {" "}
//               Back{" "}
//             </Link>
//           </button>
//           </div> */}
//       <div>
//             <button onClick={handleDownloadPdf} disabled={isLoading}>
//               Download PDF {isLoading && <span className="loader"></span>}
//             </button>
//       </div>
//       </div>
//       {Array.from({ length: couponQuantity }).map((_, index) => (
//         <div
//           key={index}
//           className="calendar-grid"
//           ref={(el) => (couponRefs.current[index] = el)} // Assign each div to the couponRefs array
//         >
//           <div className="left-section">
//             <h3 className="free">Free</h3>
//             <h2 className="head">Personalized Desk Calendar</h2>
//             <div className="calendar-image">
//               <img
//                 src={`${process.env.PUBLIC_URL}/imgs-calendar/calendar-coupon.png`}
//                 alt="Logo"
//               />

//             </div>
//             <Link to={""} className="link-visit">
//               Visit www.GiftOnly4U.com
//             </Link>
//             <div className="bottom-section">
//               <img
//                   src={filepath + corporates.logoImage}
//                   alt="company-logo"
//                   className="logo-img"
//                 />
//               <div>
//                 <div className="coupon-box">
//                   <h3 className="coupon">Coupon Code</h3>
//                   <p className="code">{corporateCode}</p>
//                 </div>
//               </div>
//             </div>
//           </div>
//           <div className="right-section">
//             <h3>Simple Process</h3>
//             <ul className="process-left">
//               <li className="li-terms">Register</li>
//               <li className="website">
//                 <Link className="link-at">at www.GiftOnly4U.com</Link>
//               </li>
//               <li className="li-terms">Redeem</li>
//               <li className="website"> with redemption code</li>
//               <li className="li-terms">Create</li>
//               <li className="website"> calendar online</li>
//               <li className="li-terms">Receive</li>
//               <li className="website"> calendar by courier</li>
//             </ul>
//             <div className="right-section-circle">
//               <div className="circle-container">
//                 <div className="circle-small"></div>
//                 <div className="circle-small"></div>
//                 <div className="circle-small"></div>
//                 <div className="circle-small"></div>
//                 <div className="circle-small"></div>
//                 <div className="circle-small"></div>
//                 <div className="circle-small"></div>
//                 <div className="circle-small"></div>
//                 <div className="circle-small"></div>
//               </div>
//             </div>
//           </div>
//         </div>
//       ))}

//     </div>
//   );
// }

// export default CouponViewDownload;

import React, { useEffect, useRef, useState } from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { useAuth } from "../AuthContext";

function CouponViewDownload() {
  const [corporates, setCorporates] = useState([]);
  const [groupedCoupons, setGroupedCoupons] = useState([['']]);
  const [corporateCode, setCorporateCode] = useState([]); // Array for coupon codes
  const [couponQuantity, setCouponQuantity] = useState(0); // State for coupon quantity
  const couponRefs = useRef([]); // Array to hold references to each coupon div
  const { filepath, apipath } = useAuth();
  const { corporateId, index } = useParams();
  const [isLoading, setIsLoading] = useState(false); // Loader state
  const parsedIndex = parseInt(index, 10);

  useEffect(() => {
    fetchCorporates();
  }, []);

  const fetchCorporates = async () => {
    try {
      const response = await axios.get(
        apipath + `/corporate/details/${corporateId}`
      );
      const corporateData = response.data.corporate;
      setGroupedCoupons(response.data.groupedCoupons || [])
      setCorporates(corporateData);
      setCorporateCode(corporateData.couponCodes); // Set the coupon codes array
      setCouponQuantity(corporateData.couponQuantity || 1); // Set coupon quantity, default 1 if undefined
    } catch (error) {
      console.error("Error fetching corporate:", error);
    }
  };

  const handleDownloadPdf = async () => {
    setIsLoading(true);
    const pdf = new jsPDF({
      orientation: "landscape",
      unit: "mm",
      format: [98, 60], // Size in mm
    });
    try {
      for (let i = 0; i < couponQuantity; i++) {
        const element = couponRefs.current[i];
        const canvas = await html2canvas(element);
        const data = canvas.toDataURL("image/png");

        const imgWidth = 88.9; // Image width in mm
        const imgHeight = 50.8; // Image height in mm
        const xMargin = (98 - imgWidth) / 2; // Horizontal margin
        const yMargin = (60 - imgHeight) / 2; // Vertical margin

        // Add the image to the PDF
        pdf.addImage(data, "PNG", xMargin, yMargin, imgWidth, imgHeight);

        // If it's not the last page, add a new page
        if (i < couponQuantity - 1) {
          pdf.addPage();
        }
      }

      pdf.save(`${corporates.name}-coupons.pdf`);
    } catch (error) {
      console.error("Error generating PDF:", error);
    } finally {
      setIsLoading(false); // End loading
    }
  };
console.log(groupedCoupons)
  return (
    <div className="container-coupon">
      {/* Download Button */}
      <div className="container custom-container sticky-top">
        <div>
          <button onClick={handleDownloadPdf} disabled={isLoading}>
            Download PDF {isLoading && <span className="loader"></span>}
          </button>
        </div>
      </div>

      {/* Dynamically generate coupon cards */}
      {Array.from({ length: couponQuantity[parsedIndex] }).map((_, index) => (
        <div
          key={index}
          className="calendar-grid"
          ref={(el) => (couponRefs.current[index] = el)} // Assign each div to the couponRefs array
        >
          <div className="left-section">
            {/* <h3 className="free">Free</h3> */}
            <h2 className="head">Personalized Desk Calendar</h2>
            <div className="calendar-image">
              <img
                src={`${process.env.PUBLIC_URL}/imgs-calendar/calendar-coupon.png`}
                alt="Logo"
              />
            </div>
            <Link to={""} className="link-visit">
              Visit www.GiftOnly4U.com
            </Link>
            <div className="bottom-section">
              <img
                src={filepath + corporates.logoImage}
                alt="company-logo"
                className="logo-img"
              />
              <div>
                <div className="coupon-box">
                  <h3 className="coupon">Coupon Code</h3>
                  <p className="code">{groupedCoupons[parsedIndex][index]}</p>{" "}
                  {/* Display unique coupon code */}
                </div>
              </div>
            </div>
          </div>
          <div className="right-section">
            <h3>Simple Process</h3>
            <ul className="process-left">
              <li className="li-terms">Register</li>
              <li className="website">
                <Link className="link-at">at www.GiftOnly4U.com</Link>
              </li>
              <li className="li-terms">Redeem</li>
              <li className="website"> with redemption code</li>
              <li className="li-terms">Create</li>
              <li className="website"> calendar online</li>
              <li className="li-terms">Receive</li>
              <li className="website"> calendar by courier</li>
            </ul>
            <div className="right-section-circle">
              <div className="circle-container">
                <div className="circle-small"></div>
                <div className="circle-small"></div>
                <div className="circle-small"></div>
                <div className="circle-small"></div>
                <div className="circle-small"></div>
                <div className="circle-small"></div>
                <div className="circle-small"></div>
                <div className="circle-small"></div>
                <div className="circle-small"></div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default CouponViewDownload;
