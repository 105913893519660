import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import AdminSidebar from './AdminSidebar';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import $ from 'jquery';
import { Modal } from "react-bootstrap";

const CorporateDetails = () => {
    const navigate = useNavigate();
    const [corporates, setCorporates] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const { apipath } = useAuth();
    const dataTableRef = useRef();
    const [show, setShow] = useState(false);
    const [corporateIdToDelete, setCorporateIdToDelete] = useState(null);
    const [subAdmins, setSubAdmins] = useState([]);
    const [loading, setLoading] = useState(true);

    const handleShow = (id) => {
      setCorporateIdToDelete(id);
      setShow(true);
    };
    const handleClose = () => setShow(false);

    useEffect(() => {
      fetchCorporates();
      fetchSubAdmins();
    }, []);

    useEffect(() => {
      if (!loading && corporates.length > 0) {
        $(dataTableRef.current).DataTable();
      }
    }, [loading, corporates]);

    const fetchCorporates = async () => {
      try {
        const response = await axios.get(`${apipath}/corporate/details`); 
        setCorporates(response.data.corporate); 
        setLoading(false);
      } catch (error) {
        console.error('Error fetching corporate:', error);
        setLoading(false);
      }
    };

    const handleDelete = async () => {
      try {
        setIsLoading(true);
        await axios.delete(`${apipath}/corporate/details/${corporateIdToDelete}`);
        await fetchCorporates();
        setShow(false);
        setIsLoading(false);
      } catch (error) {
        console.error('Error deleting corporate:', error);
        setIsLoading(false);
      }
    };

    const fetchSubAdmins = async () => {
      try {
        const response = await axios.get(`${apipath}/users/details`);
        const subAdminsData = response.data.user.filter(user => user.role === 'subadmin');
        setSubAdmins(subAdminsData);
      } catch (error) {
        console.error('Error fetching subadmins:', error);
      }
    };

    const handleUpdate = (id) => {
      navigate(`/edit-corporate/${id}`);
    };

    const handleAssignPrinter = async (corporateId, subadminId) => {
      try {
        await axios.put(`${apipath}/corporate/details/${corporateId}`, { assignPrinterId: subadminId });
        fetchCorporates();
      } catch (error) {
        console.error('Error assigning printer:', error);
      }
    };

    return (
      <>
        <AdminSidebar />
        <div className="home">
          <div className="toggle-sidebar" style={{ display: "none" }}>
            <i className="bi bi-menu"></i>
          </div>
          <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
              <Modal.Title>Delete Corporate</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="modal-content1">
                <p>Are you sure you want to delete this corporate?</p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button className="modal-footer-delete-btn" onClick={handleDelete}>
                Delete
              </button>
            </Modal.Footer>
          </Modal>
          <div className="dashboard">
            <div className="dashboard-header">
              <h1>Corporate Management</h1>
            </div>
            <div className="button-right">
              <Link to="/add-corporate">
                <button className="btn btn-primary">Add Corporate</button>
              </Link>
            </div>
            <div className="container-fluid">
              <div className="row foot-tablerow">
                <div className="col-lg-11 maintable-column mx-auto">
                  <div className="container mt-4 overflow-auto">
                    <table id="datatable" className="table" ref={dataTableRef}>
                      <thead>
                        <tr className="tr1">
                          <th>S.No.</th>
                          <th>Company Name</th>
                          <th>Contact Person</th>
                          <th>Mobile</th>
                          <th>City</th>
                          <th>Email</th>
                          <th>Price</th>
                          <th>Coupon Qty</th>
                          <th>Total Coupon Qty</th>
                          <th>Assign Printer</th>
                          <th>Download Coupon</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {corporates.map((corporate, index) => (
                          <tr key={corporate._id}>
                            <td className='text-center'>{index + 1}</td>
                            <td>{corporate.companyName}</td>
                            <td>{corporate.name}</td>
                            <td>{corporate.phone}</td>
                            <td>{corporate.city}</td>
                            <td>{corporate.email}</td>
                            <td>{corporate.price}</td>
                            <td>{corporate.couponQuantity[corporate.couponQuantity.length - 1]}</td>
                            <td>{corporate.totalCouponQuantity}</td>
                            <td>
                              <select
                                onChange={(e) => handleAssignPrinter(corporate._id, e.target.value)}
                                value={corporate.assignPrinterId || ""}
                              >
                                <option value="">Select Subadmin</option>
                                {subAdmins.map(subadmin => (
                                  <option key={subadmin._id} value={subadmin._id}>
                                    {subadmin.name}
                                  </option>
                                ))}
                              </select>
                            </td>
                            <td>
                              <Link to={`/coupon-view-download/${corporate._id}/${corporate.couponQuantity.length - 1}`}>
                                <i className="bi bi-download"></i>
                              </Link>
                            </td>
                            <td>
                              <i className="fa-regular fa-pen-to-square" onClick={() => handleUpdate(corporate._id)}></i>{" "}
                              <i
                                className="fa-solid fa-trash"
                                onClick={() => handleShow(corporate._id)}
                                style={{ cursor: 'pointer' }}
                              ></i>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };
  
  export default CorporateDetails;
