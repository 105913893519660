import React, { useState } from "react";
import moment from "moment";
import CouponPopupForm from "./CouponPopupForm";

const CouponPreviewPCalendar = ({
  year,
  month,
  googleCalendarEvents,
  view,
  calendarData,
}) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null); // State to store the clicked date
  const [currentEvents, setCurrentEvents] = useState([]); // Events for selected date

  const startDate = moment([year, month]);
  const endDate = startDate.clone().endOf("month");

  // Generate days for the calendar grid
  const generateCalendarDays = () => {
    const days = [];
    let currentDay = startDate.clone().startOf("week");

    while (currentDay.isBefore(endDate.clone().endOf("week"))) {
      days.push(currentDay.clone());
      currentDay.add(1, "day");
    }

    return days;
  };

  const calendarDays = generateCalendarDays();

  // Group events by date
  const eventsByDate = googleCalendarEvents.reduce((acc, event) => {
    const date = moment(event.start).format("YYYY-MM-DD");
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(event);
    return acc;
  }, {});

  const getDateColor = (day) => {
    if (day.month() !== month) {
      // Return null for dates outside of the current month (previous/next month's dates)
      return null;
    }

    const dayOfMonth = day.date();
    const monthName = moment.months()[month].toLowerCase();

    // Check if the day is a public holiday
    const dateString = day.format("YYYY-MM-DD");
    if (eventsByDate[dateString]) {
      return "blue"; // Set color for public holidays
    }

    // Check for other specific events
    for (let i = 1; i <= 4; i++) {
      if (parseInt(calendarData[`${monthName}Event${i}Date`]) === dayOfMonth) {
        return calendarData[`${monthName}Event${i}Color`] || "#000000"; // Default to black if no color is defined
      }
    }

    return null; // No color for this day
  };

  // Handle the click on a calendar date
  const handleDateClick = (day) => {
    // Only allow clicks on dates in the current month
    if (day.month() !== month) {
      return; // Exit early if the date is outside of the current month
    }
    const selectedDateString = day.format("DD");
    setSelectedDate(selectedDateString); // Set the selected date

    // Get the events for the selected date
    const eventsForSelectedDate = eventsByDate[selectedDateString] || [];
    setCurrentEvents(eventsForSelectedDate); // Update the state with current events

    setIsPopupOpen(true); // Open the popup
  };

  // Render table headers
  const renderTableHeaders = () => (
    <tr className="calendar-header">
      {["S", "M", "T", "W", "T", "F", "S"].map((day) => (
        <th
          key={day}
          className="important"
          style={{
            color: "#1a1818",
            backgroundColor: "#afcb06",
            textTransform: "uppercase",
            fontWeight: "bold",
            fontFamily: '"Inter", sans-serif',
            fontSize: "12px",
          }}
        >
          {day}
        </th>
      ))}
    </tr>
  );

  // Render table cells
  const renderTableCells = () => {
    const weeks = [];
    let days = [];

    calendarDays.forEach((day, index) => {
      if (index % 7 === 0 && days.length > 0) {
        weeks.push(days);
        days = [];
      }

      const date = day.format("YYYY-MM-DD");
      days.push(
        <td
          key={date}
          className={`important-td ${
            day.month() !== month ? "td-inactive" : "td-active"
          }`}
          onClick={() => handleDateClick(day)} // Add click event handler here
        >
          <div className="date" style={{ color: getDateColor(day) }}>
            {day.date()}
          </div>

          {eventsByDate[date] &&
            eventsByDate[date].map((event, idx) => (
              <div key={idx} className="event">
                {/* {event.title} */}
              </div>
            ))}
        </td>
      );
    });

    weeks.push(days); // push the last week

    return weeks.map((week, idx) => <tr key={idx}>{week}</tr>);
  };

  return (
    <div style={{ width: "100%" }}>
      <style>
        {`
          .important {
            background-color: #afcb06 !important; 
            text-align: center !important;
            padding: 5px 0 !important;
          }
          .td-inactive {
            color : #b9b9b9 !important;
          }
          .td-active {
            color : #1a1818;
          }
          .important-td {
            font-weight: 600;
            font-family: "Inter", sans-serif;
            font-size: 13px;
            padding: 5px 5px !important;
            text-align: center !important;
            border: 1px solid #D9d9D9;
          }
          .calendar-header th:nth-child(7n + 1) {
            color: #e3000e !important;
          }
          .calendar-header th:nth-child(7n + 7) {
            color: #ef7c00 !important;
          }
          .important-td:nth-child(7n + 1) {
            color: #e3000e;
          }
          .important-td:nth-child(7n + 7) {
            color: #ef7c00;
          }
        `}
      </style>
      <table style={{ width: "100%" }}>
        <thead>{renderTableHeaders()}</thead>
        <tbody>{renderTableCells()}</tbody>
      </table>

      {/* Popup Form */}
      <CouponPopupForm
        isOpen={isPopupOpen}
        onClose={() => setIsPopupOpen(false)}
        monthName={moment.months()[month].toLowerCase()}
        currentEvents={currentEvents} // Pass the current events
        selectedDate={selectedDate}
      />
    </div>
  );
};

export default CouponPreviewPCalendar;
