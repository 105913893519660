import React, { useEffect, useState } from "react";
import { Link, useLocation,useNavigate } from "react-router-dom";
import { useAuth } from "../AuthContext";
import iziToast from "izitoast";
import axios from "axios";

const SubAdminSidebar = () => {
  const [isActive, setIsActive] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [subAdminDetails, setSubAdminDetails] = useState({});
  const { apipath } = useAuth();

  const toggleSidebar = () => {
    setIsActive(!isActive);
  };
 
  const subadminId = localStorage.getItem('subadminId');
  const subadminname = localStorage.getItem('subadminname');
  const subadminemail = localStorage.getItem('subadminemail');
  const subadmintoken = localStorage.getItem('subadmintoken');
  if(subadmintoken === null || subadmintoken === ""){
    navigate("/");
  }

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('name');
    localStorage.removeItem('userId');
    localStorage.removeItem('email');
    localStorage.removeItem('admintoken');
    localStorage.removeItem('adminname');
    localStorage.removeItem('adminemail');
    localStorage.removeItem('adminId');
    localStorage.removeItem('subadmintoken');
    localStorage.removeItem('subadminname');
    localStorage.removeItem('subadminemail');
    localStorage.removeItem('subadminId');
    
    iziToast.success({
      message: "Logout successful",
      position: "topCenter",
    });
    window.location.href = "/login";
  };

  useEffect(() => {
    // Fetch user details on component mount
    const fetchSubAdminDetails = async () => {
      try {
        const response = await axios.get(`${apipath}/users/details/${subadminId}`)
        setSubAdminDetails(response.data.user);
      } catch (error) {
        iziToast.error({
          message: "Failed to fetch user details",
          position: "topCenter",
        });
        console.error("Error fetching user details:", error);
      }
    };

    fetchSubAdminDetails();
  }, [subadminId]);

  const isTabActive = (path) => {
    return location.pathname === path ? 'activeTab' : '';
  };

  return (
    <>
      <div className={`sidebar ${isActive ? "close" : ""}`}>
        <Link className="logo-box text-decoration-none" onClick={toggleSidebar}>
          <img src={`${process.env.PUBLIC_URL}/imgs-calendar/logo1.png`} alt="" />
        </Link>

        <ul className="sidebar-list">
          <li>
            <div className="title">
              <Link to="/sub-admin-dashboard" className={`link ${isTabActive('/dashboard')}`}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1em"
                  height="1em"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                    d="M8.557 2.75H4.682A1.932 1.932 0 0 0 2.75 4.682v3.875a1.942 1.942 0 0 0 1.932 1.942h3.875a1.942 1.942 0 0 0 1.942-1.942V4.682A1.942 1.942 0 0 0 8.557 2.75m10.761 0h-3.875a1.942 1.942 0 0 0-1.942 1.932v3.875a1.943 1.943 0 0 0 1.942 1.942h3.875a1.942 1.942 0 0 0 1.932-1.942V4.682a1.932 1.932 0 0 0-1.932-1.932M8.557 13.5H4.682a1.943 1.943 0 0 0-1.932 1.943v3.875a1.932 1.932 0 0 0 1.932 1.932h3.875a1.942 1.942 0 0 0 1.942-1.932v-3.875a1.942 1.942 0 0 0-1.942-1.942m8.818-.001a3.875 3.875 0 1 0 0 7.75a3.875 3.875 0 0 0 0-7.75"
                  />
                </svg>
                <span className="name">Dashboard</span>
              </Link>
            </div>
          </li>
          {/* <li>
            <div className="title">
              <Link to="/customerDetails" className={`link ${isTabActive('/product')}`}>
              <i class="bi bi-people"></i>

                <span className="name">Customer Management</span>
              </Link>
            </div>
          </li> */}
          {/* <li>
            <div className="title">
              <Link to="/pendingOrder" className={`link ${isTabActive('/product')}`}>
              <i class="bi bi-truck"></i>
                <span className="name">Pending Order Management</span>
              </Link>
            </div>
          </li>
          <li>
            <div className="title">
              <Link to="/billingInformation" className={`link ${isTabActive('/product')}`}>
              <i class="bi bi-cash"></i>
                <span className="name">Billing Management</span>
              </Link>
            </div>
          </li> */}
          
          <li>
            <div className="title">
              <Link to="/complete-calendar-print" className={`link ${isTabActive('/calendar')}`}>
              <i class="bi bi-basket2"></i>
                <span className="name">Completed Calendar</span>
              </Link>
            </div>
          </li>
          <li>
            <div className="title">
              <Link to="/corporate-coupon" className={`link ${isTabActive('/coupon')}`}>
              <i class="bi bi-building-fill"></i>
                <span className="name">Corporate Coupon</span>
              </Link>
            </div>
          </li>
          <hr/>
          <li onClick={handleLogout}>
            <div className="title">
              <Link  className="link">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1em"
                  height="1em"
                  viewBox="0 0 24 24"
                  onClick={handleLogout} >
                  <path
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="m19 12l-4-4m4 4l-4 4m4-4H9m5 9a9 9 0 1 1 0-18"
                  />
                </svg>
                <span className="name" onClick={handleLogout}>Logout</span>
              </Link>
            </div>
          </li>
          <li className="side-profile d-flex">
            <div className="profile-img">
                <img src="assets/profile.png" alt="" />
            </div>
            <div className="side-name">
                <h3>{subAdminDetails.companyName}</h3>
                <h5>{subAdminDetails.email}</h5>
            </div>
          </li>
        </ul>
      </div>
    </>
  );
};

export default SubAdminSidebar;