import React, { useState } from 'react'
import AdminSidebar from './AdminSidebar'
import iziToast from "izitoast";
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext';

const FAQsAdd = () => {

    const navigate = useNavigate();    
    const { apipath } = useAuth();

    const [formData, setFormData] = useState({
        heading: '',
        text: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleRegister = async (e) => {
        e.preventDefault();

        const response = await fetch(apipath + '/faqs/add', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
        });

        if (response.status === 201) {
            iziToast.success({
                message: "FAQs add successful",
                position: "topCenter"
            });
            navigate("/faqsDetails");
        } else {
            iziToast.success({
                message: "FAQs add failed",
                position: "topCenter"
            });
        }
    };

    return (
        <>
            <AdminSidebar />
            <div className="home">
                <div class="toggle-sidebar" style={{ display: "none" }}>
                    <i class="bi bi-menu"></i>
                </div>
                <div className="dashboard">
                    <div className="dashboard-header">
                        <h1> Add FAQs</h1>
                    </div>
                    <div className="container-fluid mt-3">
                        <form className="row login-form" onSubmit={handleRegister}>
                            <div className="mb-3 col-lg-8">
                                <label htmlFor="faqsHeading" className='mb-2'>FAQs Heading</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter FAQs Heading"
                                    name="heading"
                                    value={formData.heading}
                                    onChange={handleChange}
                                    maxLength="100"
                                    required
                                />
                            </div>
                            <div className="mb-3 col-lg-8">
                                <label htmlFor="faqsText" className='mb-2'>FAQs Key</label>
                                <textarea
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter FAQs Text"
                                    name="text"
                                    value={formData.text}
                                    onChange={handleChange}
                                    maxLength="1000"
                                    required
                                />
                            </div>
                            <div className="submit-box pt-4">
                                <button className="btn btn-primary" type="submit">Add FAQs</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FAQsAdd