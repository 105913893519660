// import React, { useEffect, useState } from "react";
// import { Link, useParams } from "react-router-dom";
// import ReactBigCalendar from "./ReactBigCalendar";
// import { useAuth } from "../AuthContext";

// const Portrait = () => {
//     const { calendarId } = useParams();
//     const { filepath } = useAuth();
//     const [calendarData, setCalendarData] = useState(null);

//     useEffect(() => {
//         const fetchCalendarData = async () => {
//             try {
//                 const response = await fetch(`https://giftonly4u.com:4000/api/v1/calendar/details/${calendarId}`);
//                 const data = await response.json();
//                 setCalendarData(data.calendar);
//             } catch (error) {
//                 console.error("Error fetching calendar data:", error);
//             }
//         };

//         fetchCalendarData();
//     }, [calendarId]);

//     if (!calendarData) {
//         return <div>Loading...</div>;
//     }

//     return (
//         <div className="container preview">
//             <div className="top-buttons">
//                 <Link to={`/landscape/${calendarId}`}>
//                     <button>
//                         {" "}
//                         <i className="bi bi-chevron-left"></i> Previous
//                     </button>
//                 </Link>
//                 {/* <Link to={`/preview3/${calendarId}`}> */}
//                 <Link to={`/portrait/${calendarId}`}>
//                     <button>
//                         {" "}
//                         Next <i className="bi bi-chevron-right"></i>
//                     </button>
//                 </Link>
//             </div>
//             <div className="container-fluid portrait">
//                 <div className="row">
//                     <div className="col-lg-6">
//                         <img src={calendarData.januaryPic !== '' && calendarData.januaryPic !== null ? filepath + calendarData.januaryPic : `${process.env.PUBLIC_URL}/imgs-calendar/l.png`} alt="January" />
//                     </div>
//                     <div className="col-lg-6 pt-2">
//                         <h1>{calendarData.january} 2024</h1>
//                         <ReactBigCalendar type={1} />
//                         <div className='public-holiday your-events'>
//                             <div><li style={{ color: calendarData.januaryEvent1Color }}>{calendarData.januaryEvent1Date} - {calendarData.januaryEvent1Caption}</li>
//                                 <li style={{ color: calendarData.januaryEvent2Color }}>{calendarData.januaryEvent2Date} - {calendarData.januaryEvent2Caption}</li></div>
//                             <div><li style={{ color: calendarData.januaryEvent3Color }}>{calendarData.januaryEvent3Date} - {calendarData.januaryEvent3Caption}</li>
//                                 <li style={{ color: calendarData.januaryEvent4Color }}>{calendarData.januaryEvent4Date} - {calendarData.januaryEvent4Caption}</li></div>
//                         </div>
//                         <div className='public-holiday'>
//                             <div>
//                                 <li>01, New Year</li>
//                                 <li>01, New Year</li>
//                                 <li>01, New Year</li>
//                             </div>
//                             <div>
//                                 <li>01, New Year</li>
//                                 <li>01, New Year</li>
//                                 <li>01, New Year</li>
//                             </div>
//                         </div>

//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default Portrait;


// import React, { useEffect, useState } from "react";
// import { Link, useParams } from "react-router-dom";
// import ReactBigCalendar from "./ReactBigCalendar";
// import { useAuth } from "../AuthContext";
// import moment from "moment";
// import { generateYearMonths } from "./generateYearMonths";

// const Portrait = () => {
//   const { calendarId } = useParams();
//   const { filepath,apipath } = useAuth(); 
//   const [calendarData, setCalendarData] = useState(null);
//   const startDate = "2024-08-01T00:00:00.000Z"; // Replace with your desired start date

//   useEffect(() => {
//     const fetchCalendarData = async () => {
//       try {
//         const response = await fetch(apipath + `/calendar/details/${calendarId}`);
//         const data = await response.json();
//         setCalendarData(data.calendar);
//         console.log("Calendar details : ", data.calendar);
//       } catch (error) {
//         console.error("Error fetching calendar data:", error);
//       }
//     };

//     fetchCalendarData();
//   }, [calendarId]);

//   if (!calendarData) {
//     return <div>Loading...</div>;
//   }

//   const months = generateYearMonths(startDate);

//   return (
//     <div className="container preview">
//       <div className="top-buttons">
//         <Link to={`/landscape/${calendarId}`}>
//           <button>
//             {" "}
//             <i className="bi bi-chevron-left"></i> Previous
//           </button>
//         </Link>
//         <Link to={`/portrait/${calendarId}`}>
//           <button>
//             {" "}
//             Next <i className="bi bi-chevron-right"></i>
//           </button>
//         </Link>
//       </div>
//       <div className="container-fluid portrait">
//         {months.map((month, index) => (
//           <div key={index} className="row">
//             <div className="col-lg-6">
//               <img
//                 src={
//                   calendarData[`${month.format("MMMM").toLowerCase()}Pic`] !== "" &&
//                   calendarData[`${month.format("MMMM").toLowerCase()}Pic`] !== null
//                     ? filepath + calendarData[`${month.format("MMMM").toLowerCase()}Pic`]
//                     : `${process.env.PUBLIC_URL}/imgs-calendar/l.png`
//                 }
//                 alt={month.format("MMMM")}
//               />
//             </div>
//             <div className="col-lg-6 pt-2">
//               <h1>{month.format("MMMM")} 2024</h1>
//               <ReactBigCalendar
//                 type={1}
//                 events={[
//                   {
//                     start: new Date(2024, month.month(), 10),
//                     end: new Date(2024, month.month(), 10),
//                     title: calendarData[`${month.format("MMMM").toLowerCase()}Event1Caption`],
//                   },
//                   {
//                     start: new Date(2024, month.month(), 16),
//                     end: new Date(2024, month.month(), 16),
//                     title: calendarData[`${month.format("MMMM").toLowerCase()}Event2Caption`],
//                   },
//                   {
//                     start: new Date(2024, month.month(), 22),
//                     end: new Date(2024, month.month(), 22),
//                     title: calendarData[`${month.format("MMMM").toLowerCase()}Event3Caption`],
//                   },
//                   {
//                     start: new Date(2024, month.month(), 25),
//                     end: new Date(2024, month.month(), 25),
//                     title: calendarData[`${month.format("MMMM").toLowerCase()}Event4Caption`],
//                   },
//                 ]}
//               />
//               <div className='public-holiday your-events'>
//                 <div>
//                   <li style={{ color: calendarData[`${month.format("MMMM").toLowerCase()}Event1Color`] }}>
//                     {calendarData[`${month.format("MMMM").toLowerCase()}Event1Date`]} - {calendarData[`${month.format("MMMM").toLowerCase()}Event1Caption`]}
//                   </li>
//                   <li style={{ color: calendarData[`${month.format("MMMM").toLowerCase()}Event2Color`] }}>
//                     {calendarData[`${month.format("MMMM").toLowerCase()}Event2Date`]} - {calendarData[`${month.format("MMMM").toLowerCase()}Event2Caption`]}
//                   </li>
//                 </div>
//                 <div>
//                   <li style={{ color: calendarData[`${month.format("MMMM").toLowerCase()}Event3Color`] }}>
//                     {calendarData[`${month.format("MMMM").toLowerCase()}Event3Date`]} - {calendarData[`${month.format("MMMM").toLowerCase()}Event3Caption`]}
//                   </li>
//                   <li style={{ color: calendarData[`${month.format("MMMM").toLowerCase()}Event4Color`] }}>
//                     {calendarData[`${month.format("MMMM").toLowerCase()}Event4Date`]} - {calendarData[`${month.format("MMMM").toLowerCase()}Event4Caption`]}
//                   </li>
//                 </div>
//               </div>
//               <div className='public-holiday'>
//                 <div>
//                   <li>01, New Year</li>
//                   <li>01, New Year</li>
//                   <li>01, New Year</li>
//                 </div>
//                 <div>
//                   <li>01, New Year</li>
//                   <li>01, New Year</li>
//                   <li>01, New Year</li>
//                 </div>
//               </div>
//             </div>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default Portrait;

import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import ReactBigCalendar from "./ReactBigCalendar";
import { useAuth } from "../AuthContext";
import moment from "moment";
import { generateYearMonths } from "./generateYearMonths";
import { toPng } from 'html-to-image';
import jsPDF from 'jspdf';

const Portrait = () => {
  const { calendarId } = useParams();
  const { filepath, apipath } = useAuth();
  const [calendarData, setCalendarData] = useState(null);
  const startDate = "2024-08-01T00:00:00.000Z"; // Replace with your desired start date

  useEffect(() => {
    const fetchCalendarData = async () => {
      try {
        const response = await fetch(apipath + `/calendar/details/${calendarId}`);
        const data = await response.json();
        setCalendarData(data.calendar);
        console.log("Calendar details : ", data.calendar);
      } catch (error) {
        console.error("Error fetching calendar data:", error);
      }
    };

    fetchCalendarData();
  }, [calendarId]);

  const handleDownload = async () => {
    const doc = new jsPDF();
    const months = generateYearMonths(startDate);

    for (let i = 0; i < months.length; i++) {
      const month = months[i];
      const node = document.getElementById(`capture-${i}`);

      try {
        const dataUrl = await toPng(node);
        doc.addImage(dataUrl, 'PNG', 10, 10, 190, 0);
        if (i !== months.length - 1) {
          doc.addPage();
        }
      } catch (error) {
        console.error('Error capturing the image:', error);
      }
    }

    doc.save('calendar-view.pdf');
  };

  if (!calendarData) {
    return <div>Loading...</div>;
  }

  const months = generateYearMonths(startDate);

  return (
    <div className="container preview">
      <div className="top-buttons">
        <Link to={`/landscape/${calendarId}`}>
          <button>
            {" "}
            <i className="bi bi-chevron-left"></i> Previous
          </button>
        </Link>
        <Link to={`/portrait/${calendarId}`}>
          <button>
            {" "}
            Next <i className="bi bi-chevron-right"></i>
          </button>
        </Link>
      </div>
      <div className="container-fluid portrait">
        {months.map((month, index) => (
          <div key={index} id={`capture-${index}`} className="row">
            <div className="col-lg-6">
              <img
                src={
                  calendarData[`${month.format("MMMM").toLowerCase()}Pic`] !== "" &&
                  calendarData[`${month.format("MMMM").toLowerCase()}Pic`] !== null
                    ? filepath + calendarData[`${month.format("MMMM").toLowerCase()}Pic`]
                    : `${process.env.PUBLIC_URL}/imgs-calendar/l.png`
                }
                alt={month.format("MMMM")}
              />
            </div>
            <div className="col-lg-6 pt-2">
              <h1>{month.format("MMMM")} 2024</h1>
              <ReactBigCalendar
                type={1}
                events={[
                  {
                    start: new Date(2024, month.month(), 10),
                    end: new Date(2024, month.month(), 10),
                    title: calendarData[`${month.format("MMMM").toLowerCase()}Event1Caption`],
                  },
                  {
                    start: new Date(2024, month.month(), 16),
                    end: new Date(2024, month.month(), 16),
                    title: calendarData[`${month.format("MMMM").toLowerCase()}Event2Caption`],
                  },
                  {
                    start: new Date(2024, month.month(), 22),
                    end: new Date(2024, month.month(), 22),
                    title: calendarData[`${month.format("MMMM").toLowerCase()}Event3Caption`],
                  },
                  {
                    start: new Date(2024, month.month(), 25),
                    end: new Date(2024, month.month(), 25),
                    title: calendarData[`${month.format("MMMM").toLowerCase()}Event4Caption`],
                  },
                ]}
              />
              <div className='public-holiday your-events'>
                <div>
                  <li style={{ color: calendarData[`${month.format("MMMM").toLowerCase()}Event1Color`] }}>
                    {calendarData[`${month.format("MMMM").toLowerCase()}Event1Date`]} - {calendarData[`${month.format("MMMM").toLowerCase()}Event1Caption`]}
                  </li>
                  <li style={{ color: calendarData[`${month.format("MMMM").toLowerCase()}Event2Color`] }}>
                    {calendarData[`${month.format("MMMM").toLowerCase()}Event2Date`]} - {calendarData[`${month.format("MMMM").toLowerCase()}Event2Caption`]}
                  </li>
                </div>
                <div>
                  <li style={{ color: calendarData[`${month.format("MMMM").toLowerCase()}Event3Color`] }}>
                    {calendarData[`${month.format("MMMM").toLowerCase()}Event3Date`]} - {calendarData[`${month.format("MMMM").toLowerCase()}Event3Caption`]}
                  </li>
                  <li style={{ color: calendarData[`${month.format("MMMM").toLowerCase()}Event4Color`] }}>
                    {calendarData[`${month.format("MMMM").toLowerCase()}Event4Date`]} - {calendarData[`${month.format("MMMM").toLowerCase()}Event4Caption`]}
                  </li>
                </div>
              </div>
              <div className='public-holiday'>
                <div>
                  <li>01, New Year</li>
                  <li>01, New Year</li>
                  <li>01, New Year</li>
                </div>
                <div>
                  <li>01, New Year</li>
                  <li>01, New Year</li>
                  <li>01, New Year</li>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <button onClick={handleDownload}>Download as PDF</button>
    </div>
  );
};

export default Portrait;
