import React, { useEffect } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";

const ShoppingPolicy = () => {

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "auto",
    });
  }, []);

  return (
    <>
      <Navbar />

      <div className="container privacy">
        <h1>SHIPPING POLICY</h1>
        <p>You are entitled for one time FREE shipping on all orders that are placed on our website. The valid amount is the final sum. All dispatches would be made by standard courier service. We will do everything to rush it out! Average production + delivery timeframe will take between 3 to 5 business days (depending on location and subject to peak season). Tracking is included but on the website of the courier company only. Not deliverable to PO Boxes. COmplete address along with pin code is essential. Usually fulfilled by reputed courier services.</p>
      </div>
      <Footer />
    </>
  );
};

export default ShoppingPolicy;
