import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import FileUpload from "../components/FileUpload";
import { Link, useNavigate, useParams } from "react-router-dom";
import iziToast from "izitoast";
import { useAuth } from "../AuthContext";
import axios from "axios";

const CouponCalendarImage = () => {
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loader, setLoader] = useState(false);
  const { apipath } = useAuth();
  const [lastCouponCode, setLastCouponCode] = useState('');
  const [logoImage, setLogoImage] = useState('');

  const [formData, setFormData] = useState({
    months: {
      january: { name: "January", image: null },
      february: { name: "February", image: null },
      march: { name: "March", image: null },
      april: { name: "April", image: null },
      may: { name: "May", image: null },
      june: { name: "June", image: null },
      july: { name: "July", image: null },
      august: { name: "August", image: null },
      september: { name: "September", image: null },
      october: { name: "October", image: null },
      november: { name: "November", image: null },
      december: { name: "December", image: null },
    },
    januaryPic: null,
    februaryPic: null,
    marchPic: null,
    aprilPic: null,
    mayPic: null,
    junePic: null,
    julyPic: null,
    augustPic: null,
    septemberPic: null,
    octoberPic: null,
    novemberPic: null,
    decemberPic: null,
    januaryEvent1Date: "",
    januaryEvent1Caption: "",
    januaryEvent1Color: "",
    januaryEvent2Date: "",
    januaryEvent2Caption: "",
    januaryEvent2Color: "",
    januaryEvent3Date: "",
    januaryEvent3Caption: "",
    januaryEvent3Color: "",
    januaryEvent4Date: "",
    januaryEvent4Caption: "",
    januaryEvent4Color: "",
    februaryEvent1Date: "",
    februaryEvent1Caption: "",
    februaryEvent1Color: "",
    februaryEvent2Date: "",
    februaryEvent2Caption: "",
    februaryEvent2Color: "",
    februaryEvent3Caption: "",
    februaryEvent3Color: "",
    februaryEvent3Date: "",
    februaryEvent4Caption: "",
    februaryEvent4Color: "",
    februaryEvent4Date: "",
    marchEvent1Date: "",
    marchEvent1Caption: "",
    marchEvent1Color: "",
    marchEvent2Date: "",
    marchEvent2Caption: "",
    marchEvent2Color: "",
    marchEvent3Date: "",
    marchEvent3Caption: "",
    marchEvent3Color: "",
    marchEvent4Date: "",
    marchEvent4Caption: "",
    marchEvent4Color: "",
    aprilEvent1Date: "",
    aprilEvent1Caption: "",
    aprilEvent1Color: "",
    aprilEvent2Date: "",
    aprilEvent2Caption: "",
    aprilEvent2Color: "",
    aprilEvent3Date: "",
    aprilEvent3Caption: "",
    aprilEvent3Color: "",
    aprilEvent4Date: "",
    aprilEvent4Caption: "",
    aprilEvent4Color: "",
    mayEvent1Date: "",
    mayEvent1Caption: "",
    mayEvent1Color: "",
    mayEvent2Date: "",
    mayEvent2Caption: "",
    mayEvent2Color: "",
    mayEvent3Date: "",
    mayEvent3Caption: "",
    mayEvent3Color: "",
    mayEvent4Date: "",
    mayEvent4Caption: "",
    mayEvent4Color: "",
    juneEvent1Date: "",
    juneEvent1Caption: "",
    juneEvent1Color: "",
    juneEvent2Date: "",
    juneEvent2Caption: "",
    juneEvent2Color: "",
    juneEvent3Date: "",
    juneEvent3Caption: "",
    juneEvent3Color: "",
    juneEvent4Date: "",
    juneEvent4Caption: "",
    juneEvent4Color: "",
    julyEvent1Date: "",
    julyEvent1Caption: "",
    julyEvent1Color: "",
    julyEvent2Date: "",
    julyEvent2Caption: "",
    julyEvent2Color: "",
    julyEvent3Date: "",
    julyEvent3Caption: "",
    julyEvent3Color: "",
    julyEvent4Date: "",
    julyEvent4Caption: "",
    julyEvent4Color: "",
    augustEvent1Date: "",
    augustEvent1Caption: "",
    augustEvent1Color: "",
    augustEvent2Date: "",
    augustEvent2Caption: "",
    augustEvent2Color: "",
    augustEvent3Date: "",
    augustEvent3Caption: "",
    augustEvent3Color: "",
    augustEvent4Date: "",
    augustEvent4Caption: "",
    augustEvent4Color: "",
    septemberEvent1Date: "",
    septemberEvent1Caption: "",
    septemberEvent1Color: "",
    septemberEvent2Date: "",
    septemberEvent2Caption: "",
    septemberEvent2Color: "",
    septemberEvent3Date: "",
    septemberEvent3Caption: "",
    septemberEvent3Color: "",
    septemberEvent4Date: "",
    septemberEvent4Caption: "",
    septemberEvent4Color: "",
    octoberEvent1Date: "",
    octoberEvent1Caption: "",
    octoberEvent1Color: "",
    octoberEvent2Date: "",
    octoberEvent2Caption: "",
    octoberEvent2Color: "",
    octoberEvent3Date: "",
    octoberEvent3Caption: "",
    octoberEvent3Color: "",
    octoberEvent4Date: "",
    octoberEvent4Caption: "",
    octoberEvent4Color: "",
    novemberEvent1Date: "",
    novemberEvent1Caption: "",
    novemberEvent1Color: "",
    novemberEvent2Date: "",
    novemberEvent2Caption: "",
    novemberEvent2Color: "",
    novemberEvent3Date: "",
    novemberEvent3Caption: "",
    novemberEvent3Color: "",
    novemberEvent4Date: "",
    novemberEvent4Caption: "",
    novemberEvent4Color: "",
    decemberEvent1Date: "",
    decemberEvent1Caption: "",
    decemberEvent1Color: "",
    decemberEvent2Date: "",
    decemberEvent2Caption: "",
    decemberEvent2Color: "",
    decemberEvent3Date: "",
    decemberEvent3Caption: "",
    decemberEvent3Color: "",
    decemberEvent4Date: "",
    decemberEvent4Caption: "",
    decemberEvent4Color: "",
    startYearMonth: "",
    januaryViewType: "",
    februaryViewType: "",
    marchViewType: "",
    aprilViewType: "",
    mayViewType: "",
    juneViewType: "",
    julyViewType: "",
    augustViewType: "",
    septemberViewType: "",
    octoberViewType: "",
    novemberViewType: "",
    decemberViewType: "",
    corporate_code: "",
    corporateLogoPic: "",
    errors: {
      january: false,
      february: false,
      march: false,
      april: false,
      may: false,
      june: false,
      july: false,
      august: false,
      september: false,
      october: false,
      november: false,
      december: false,
    },
  });

  const maxImages = 12;
  const token = localStorage.getItem("token");
  const userId = localStorage.getItem('userId');

  const [userData, setUserData] = useState({});
  const [corporateData, setCorporateData] = useState({});

  // Fetch user details and corporate details based on the last coupon code
useEffect(() => {
  fetchUserDetails();
}, []);

// Fetch user details
const fetchUserDetails = async () => {
  if (!userId) {
    console.error("User ID is not defined");
    return;
  }

  try {
    const response = await axios.get(`${apipath}/users/details/${userId}`);
    const usersData = response.data.user;

    if (!usersData) {
      throw new Error("User data not found in the response.");
    }

    setUserData(usersData);

    // Check if user has coupon codes, and get the last one
    if (Array.isArray(usersData.useCouponCode) && usersData.useCouponCode.length > 0) {
      const lastCouponCode = usersData.useCouponCode.slice(-1)[0]; // Define lastCouponCode
      // Fetch corporate details using last coupon code
      fetchCorporateDetails(lastCouponCode);
      
    }

  } catch (error) {
    console.error("Error fetching user details:", error);
  }
};


  

  const handleImageUpload = (files) => {
    if (!files.length) return;

    const newFiles = Array.from(files);
    const totalUploadedImages = Object.values(formData.months).filter(
      (month) => month.image !== null
    ).length;

    if (newFiles.length + totalUploadedImages > maxImages) {
      iziToast.error({
        message: `You can only upload up to ${maxImages} images.`,
        position: "topCenter",
      });
      return;
    }

    const updatedMonths = { ...formData.months };
    const updatedViewTypes = { ...formData };

    newFiles.forEach((file, index) => {
      if (totalUploadedImages + index >= maxImages) return;

      const allowedTypes = ["image/jpeg", "image/png", "image/jpg"];
      const isValidType = allowedTypes.includes(file.type);
      const isValidSize = file.size <= 20 * 1024 * 1024; // 20MB limit

      if (!isValidType) {
        iziToast.error({
          message: `${file.name} is not a valid image file (JPG, PNG, JPEG)`,
          position: "topCenter",
        });
        return;
      }

      if (!isValidSize) {
        iziToast.error({
          message: `${file.name} exceeds the 20MB size limit`,
          position: "topCenter",
        });
        return;
      }

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event) => {
        const img = new window.Image();
        img.src = event.target.result;
        img.onload = () => {
          let viewType = "";
          if (img.width > img.height) {
            viewType = "Horizontal";
          } else if (img.width < img.height) {
            viewType = "Vertical";
          } else {
            viewType = "Square";
          }

          const monthKeys = Object.keys(updatedMonths);
          const nextAvailableMonthIndex = totalUploadedImages + index;

          if (nextAvailableMonthIndex < monthKeys.length) {
            const month = monthKeys[nextAvailableMonthIndex];
            updatedMonths[month].image = file;
            updatedViewTypes[`${month}ViewType`] = viewType;
            setFormData((prevState) => ({
              ...prevState,
              months: updatedMonths,
              ...updatedViewTypes,
            }));
          }
        };
      };
    });
  };

  const handleSingleImageUpload = (month, file) => {
    if (!file) return;
    if (file.length > 1) {
      iziToast.error({
        message: `You can only upload one image for ${month}.`,
        position: "topCenter",
      });
      return;
    }

    const allowedTypes = ["image/jpeg", "image/png", "image/jpg", 'image/TIFF', 'image/BMP'];
    const isValidType = allowedTypes.includes(file.type);
    const isValidSize = file.size <= 20 * 1024 * 1024; // 20MB limit

    if (!isValidType) {
      iziToast.error({
        message: `${file.name} is not a valid image file (JPG, PNG, JPEG,TIFF,BMP)`,
        position: "topCenter",
      });
      return;
    }

    if (!isValidSize) {
      iziToast.error({
        message: `${file.name} exceeds the 20MB size limit`,
        position: "topCenter",
      });
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event) => {
      const img = new window.Image();
      img.src = event.target.result;
      img.onload = () => {
        let viewType = "";
        if (img.width > img.height) {
          viewType = "Horizontal";
        } else if (img.width < img.height) {
          viewType = "Vertical";
        } else {
          viewType = "Square";
        }

        // updatedViewTypes[`${month}ViewType`] = viewType;
        setFormData((prevState) => ({
          ...prevState,
          // ...updatedViewTypes,
          months: {
            ...prevState.months,
            [month]: { ...prevState.months[month], image: file },
          },
          [`${month}ViewType`]: viewType,
        }));
      };
    };
  };

  // Helper to check if all images are uploaded
  const allImagesUploaded = Object.values(formData.months).every(
    (month) => month.image !== null
  );

  // const handleRegister = async (e) => {
  //   e.preventDefault();

  //   // Validation: Ensure all months have an image uploaded
  //   const incompleteMonths = Object.entries(formData.months).filter(
  //     ([month, { image }]) => !image
  //   );

  //   if (!allImagesUploaded) {
  //     iziToast.error({
  //       message: "Please upload images for all months before proceeding.",
  //       position: "topCenter",
  //     });
  //     return;
  //   }

  //   if (incompleteMonths.length > 0) {
  //     iziToast.error({
  //       message: `Please upload images for all months before proceeding.`,
  //       position: "topCenter",
  //     });
  //     return;
  //   }

  //   const formdata = new FormData();
  //   Object.entries(formData.months).forEach(([month, { image }]) => {
  //     if (image) {
  //       formdata.append(`${month}Pic`, image);
  //       formdata.append(`${month}ViewType`, formData[`${month}ViewType`]); // Add viewType to FormData
  //       formdata.append(`${month}`, formData.months[month].name);
  //     }
  //   });

  //   try {
  //     const response = await fetch(apipath + "/calendar/add", {
  //       method: "POST",
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //       body: formdata,
  //     });

  //     if (response.status === 201) {
  //       const responseData = await response.json();
  //       const savedCalendarId = responseData.calendar._id;

  //       iziToast.success({
  //         message: "Images added successfully",
  //         position: "topCenter",
  //       });
  //       navigate(`/coupon-calendar-date/${savedCalendarId}`);
  //     } else {
  //       iziToast.error({
  //         message: "Image addition failed",
  //         position: "topCenter",
  //       });
  //     }
  //   } catch (error) {
  //     console.error("Error adding images:", error);
  //     iziToast.error({
  //       message: "An error occurred",
  //       position: "topCenter",
  //     });
  //   }
  // };

 // Register function to handle form submission and save calendar details
// const handleRegister = async (e) => {
//   e.preventDefault();

//   // Validation: Ensure all months have an image uploaded
//   const incompleteMonths = Object.entries(formData.months).filter(
//     ([month, { image }]) => !image
//   );

//       if (!allImagesUploaded) {
//       iziToast.error({
//         message: "Please upload images for all months before proceeding.",
//         position: "topCenter",
//       });
//       return;
//     }

//   if (incompleteMonths.length > 0) {
//     iziToast.error({
//       message: `Please upload images for all months before proceeding.`,
//       position: "topCenter",
//     });
//     return;
//   }

//   try {
//     // Prepare form data for submission
//     const formdata = new FormData();

//     // Append month images and details
//     Object.entries(formData.months).forEach(([month, { image }]) => {
//       if (image) {
//         formdata.append(`${month}Pic`, image);
//         formdata.append(`${month}ViewType`, formData[`${month}ViewType`]); // Add viewType to FormData
//         formdata.append(`${month}`, formData.months[month].name);
//       }
//     });

//     // Append corporate data (corporateLogoPic and corporate_code)
//     if (corporateData) {
//       formdata.append('corporate_code', corporateData.couponCodes || "");
//       formdata.append('corporateLogoPic', corporateData.logoImage || "");
//     } else {
//       formdata.append('corporate_code', ""); // Default empty if not available
//       formdata.append('corporateLogoPic', ""); // Default empty if not available
//     }

//     // Save the calendar data
//     const response = await fetch(apipath + "/calendar/add", {
//       method: "POST",
//       headers: {
//         Authorization: `Bearer ${token}`,
//       },
//       body: formdata,
//     });

//     if (response.status === 201) {
//       const responseData = await response.json();
//       const savedCalendarId = responseData.calendar._id;

//       iziToast.success({
//         message: "Images added successfully",
//         position: "topCenter",
//       });
//       navigate(`/coupon-calendar-date/${savedCalendarId}`);
//     } else {
//       iziToast.error({
//         message: "Image addition failed",
//         position: "topCenter",
//       });
//     }
//   } catch (error) {
//     console.error("Error adding images:", error);
//     iziToast.error({
//       message: "An error occurred",
//       position: "topCenter",
//     });
//   }
// };

// Fetch corporate details based on last coupon code
// const fetchCorporateDetails = async (lastCouponCode) => {
//   try {
//     const response = await axios.get(`${apipath}/corporate/couponCode/details/${lastCouponCode}`);
//     const corporatesData = response.data.corporate;

//     if (!corporatesData) {
//       throw new Error("Corporate data not found in the response.");
//     }

//     // Set the corporate data in state (if needed)
//     setCorporateData(corporatesData);

//     if (corporatesData.logoImage) {
//       console.log("logoImage: ", corporatesData.logoImage);
//     }

//     // Return the corporate data for further use
//     return corporatesData; 
//   } catch (error) {
//     console.error("Error fetching corporate details:", error);
//     return null; // Return null on error
//   }
// };

// Fetch corporate details based on last coupon code
const fetchCorporateDetails = async (lastCouponCode) => {
  try {
    console.log("lastCouponCode ",lastCouponCode);
    
    const response = await axios.get(`${apipath}/corporate/couponCode/details/${lastCouponCode}`);
    const corporatesData = response.data.corporate;

    if (!corporatesData) {
      throw new Error("Corporate data not found in the response.");
    }
    setLastCouponCode(lastCouponCode);
    setLogoImage(corporatesData.logoImage);
    setCorporateData(corporatesData);

    if (corporatesData.logoImage) {
      console.log("logoImage: ", corporatesData.logoImage);
    }
  } catch (error) {
    console.error("Error fetching corporate details:", error);
  }
};

const handleRegister = async (e) => {
  e.preventDefault();

  // Log the complete form data
  console.log("Form Data:", formData);

  // Validation: Ensure all months have an image uploaded
  const incompleteMonths = Object.entries(formData.months).filter(
    ([month, { image }]) => !image
  );

  if (incompleteMonths.length > 0) {
    iziToast.error({
      message: "Please upload images for all months before proceeding.",
      position: "topCenter",
    });
    return;
  }

  try {
    // Prepare form data for submission
    const formdata = new FormData();

    // Append month images and details
    Object.entries(formData.months).forEach(([month, { image }]) => {
      if (image) {
        formdata.append(`${month}Pic`, image);
        formdata.append(`${month}ViewType`, formData[`${month}ViewType`]); // Add viewType to FormData
        formdata.append(`${month}`, formData.months[month].name);
      }
    });

    
    // Append corporate data to formdata
    formdata.append('corporate_code', lastCouponCode); // Append last coupon code
    formdata.append('corporateLogoPic', logoImage); // Append corporate logo image

    // Log FormData for verification
    for (let pair of formdata.entries()) {
      console.log(`${pair[0]}: ${pair[1]}`);
    }

    // Save the calendar data
    const response = await fetch(apipath + "/calendar/add", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formdata,
    });

    if (response.status === 201) {
      const responseData = await response.json();
      const savedCalendarId = responseData.calendar._id;

      iziToast.success({
        message: "Images added successfully",
        position: "topCenter",
      });
      navigate(`/coupon-calendar-date/${savedCalendarId}`);
    } else {
      iziToast.error({
        message: "Image addition failed",
        position: "topCenter",
      });
    }
  } catch (error) {
    console.error("Error adding images:", error);
    iziToast.error({
      message: "An error occurred",
      position: "topCenter",
    });
  }
};


  return (
    <>
      <Navbar isLoggedIn={isLoggedIn} />
      <div className="container">
        <div className="col-lg-6 mx-auto image-header">
          <h1>Let’s make your own personal calendar</h1>
          <div className="progress-container">
            <div className="progress-line"></div>
            <div className="circle active" id="step1">1</div>
            <div className="circle" id="step2">2</div>
            <div className="circle" id="step3">3</div>
          </div>
        </div>
        <div className="image-content">
          <h3>Upload your images (upto 12 pictures, 1 for each month):</h3>
          <div className="col-12 mb-3">
            <input
              type="file"
              multiple
              accept="image/jpeg,image/png,image/jpg,image/TIFF,image/BMP"
              onChange={(e) => handleImageUpload(e.target.files)}
            />
            <p className="note">
              (Ideal image size for landscape:1920x450px and for portrait 650x450px. Use .jpeg or .jpg or .tiff or .png or .TIFF or .BMP)
            </p>
            <p className="note">If after placing pictures in each month, your are unable to see the thumbnails, it means the pictures are not of approved specs.
              You may drop n drag each picture into each month OR Choose 12 files by clicking the Choose File button.</p>
          </div>
          <form onSubmit={handleRegister} className="row image-row py-3">
            {Object.keys(formData.months).map((month) => (
              <div
                className={`col-sm-6 col-md-3 col-lg-3 ${formData.errors[month] ? "error" : ""}`}
                key={month}
              >
                <div className="img-input">
                  <h6>{formData.months[month].name}</h6>
                  <FileUpload
                    onFileSelect={(file) => handleSingleImageUpload(month, file)}
                    accept={['image/jpeg', 'image/png', 'image/jpg', 'image/jpg', 'image/TIFF', 'image/BMP']}
                    maxSize={20 * 1024 * 1024} // 20 MB
                    multiple={false}
                    currentImage={
                      formData.months[month].image
                        ? URL.createObjectURL(formData.months[month].image)
                        : null
                    }
                  />
                </div>
              </div>
            ))}

            <div className="image-button col-lg-12">
              {allImagesUploaded && (
                <button
                  className="btn btn-primary mt-3"
                  type="submit"
                  onClick={() => setLoader(!loader)}
                >
                  Continue to the Next Step
                </button>
              )}
            </div>
            {loader && (
              <div className="col-lg-12 img-upload-loader">
                <div className="loader-img"></div>
              </div>
            )}
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default CouponCalendarImage;