import React, { useEffect } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";

const Refund = () => {

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "auto",
    });
  }, []);

  return (
    <>
      <Navbar />
      
      <div className="container privacy">
        <h1>Refund & Return Policy</h1>
        <p>In the event that you need to contact us regarding a book defect, please contact our Customer Support team with the necessary details WITHIN 1 WEEK of receiving your order. If the defect is due to production errors or damaged during shipping, we will have a replacement order made without any question asked. Just send an email to us with (1) Photos of the defect taken from several angles, (2) Include your full name, order number and a description of the defect. We shall sort it out</p>

        <h1>Refund</h1>
        <p>If an order is placed on our website and your payment is deducted, but your order is cancelled for any reason (Personal Request, Design Issue, etc.,) we will reimburse your money within 7-10 working days. However, if you cancel your order to place a reorder with different job, then we will transfer your money as "Re-Order Token" to your account, which you may use to place future order with us.</p>
    
    <h1>Return</h1>
    <p>Only if the package is received Damaged, RETURN is acceptable and the same has to be mentioned to the carrier with a noting on the POD while accepting the product clearly mentioning "Damaged Product being received". A picture of the damaged product may be sent to <a href="mailto:calendar.GiftOnly4U@gmail.com" className="line-remove">calendar.GiftOnly4U@gmail.com</a> immediately with the order reference number as is mentioned on your invoice. The return should be made within 1-2 days of receipt.</p>
      <p>If the product received has the exterior box/cover tampered with, DO NOT ACCEPT IT, return the package immediately. Instead, snap a picture and share it with us at <a href="mailto:calendar.GiftOnly4U@gmail.com" className="line-remove">calendar.GiftOnly4U@gmail.com</a> while rejecting it, which may help us place a fresh order before the damaged one is returned to us for inquiry. </p>
      <p>If the product is received in fine condition but the inside product is damaged, we will assist you in managing it; however, if the opening video is available, we will assist you in sending the replacement order without question. The return should be made within 3-5 days of receipt.</p>
      
      <h1>What if the status says delivered but the product hasn't arrived?</h1>
      <p>If you haven't received your shipment but the status shows Delivered, don't worry; we can assist you in determining who has collected the goods by requesting Proof of Delivery (POD) or Delivery Receipt Sip (DRS Copy) from the Courier Company. It will take 4-5 business days for the courier company to respond with the POD or DRS; once received, we will share the same with you via your email; please allow us additional time to handle this. Meanwhile, please verify with reception/security/neighbours to see whether someone on your behalf has received the shipment. If not, please let us know and we will inquire.</p>
      </div>
      <Footer />
    </>
  );
};

export default Refund;
